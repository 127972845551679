/**
 * 课件下载 - 下载详情
 * Create by liaokai on 2020-05-25 18:39:01
 */
import * as React from 'react';
import {Component} from "react";
import {FlexJustifyEnd} from "../../../../../../../components/style/flex/flex-justify-end";
import {Flex} from "../../../../../../../components/style/flex/flex";
import {Button, Table, Input, Modal} from "antd";
import {Air} from "../../../../../../../components/air/Air";
import {FlexJustifyBetween} from "../../../../../../../components/style/flex/flex-justify-between";

const {Search} = Input

interface IProps{
    show? : boolean
    onCancel? : () => void
}

interface IState{
    columns : any[]
    dataSource : any[],
    currentPage : number,
    total : number,
    pageSize: number,
    loading : boolean,
}

export class LiveDocDownloadDetail extends Component<IProps,IState>{
    readonly state : IState = {
        columns : [
            {
                title: '用户',
                dataIndex: '',
                key: '',
                render: (text : boolean) => text,
            },
            {
                title: '下载时间',
                dataIndex: '',
                key: '',
                render: (text: string) => text,
            },
            {
                title: '消耗积分',
                dataIndex: '',
                key: '',
                render: (text: string) => text,
            }
        ],
        dataSource : [{}],
        pageSize : 5,
        total : 0,
        currentPage : 1,
        loading : false
    };

    reqTableData(page : number){
        // apis.emailSmsTemplateList({meetid : parseInt((match as any)?.params?.meeting_id), page : page}).then(res => {
        //     this.setState({
        //         pageSize : res.per_page,
        //         total : res.total,
        //         listData : []
        //     },()=>{
        //         if(!this.state?.listData?.length){
        //             if(this.state.current > 1){
        //                 this.reqTableData(1)
        //                 this.setState({current : 1})
        //             }
        //         }
        //     })
        // })
    }

    componentDidMount(): void {
        this.reqTableData(1)
    }

    render() {
        let {show ,onCancel} = this.props;
        let {columns, dataSource, pageSize, total, currentPage, loading} = this.state;
        return (
            <Modal
                visible={show}
                title={"下载详情"} onCancel={()=>{
                    onCancel?.();
                }}
                cancelText={"返回"}
                okButtonProps={{style: {display : "none"}}}
            >
                <FlexJustifyBetween>
                    <Button>导出</Button>
                    <div>
                        <Search placeholder="搜索内容" onSearch={value => {}} enterButton />
                    </div>
                </FlexJustifyBetween>
                <Air vertical={5}/>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={dataSource}
                    emptyText={"暂无内容"}
                    pagination={{
                        defaultCurrent : 1,
                        current : currentPage,
                        pageSize : pageSize,
                        total : total,
                        onChange : page => {
                            this.reqTableData(page);
                            this.setState({currentPage : page})
                        }
                    }}
                />
            </Modal>

        );
    }
}
