/**
 * 编辑直播 - 路由
 * Create by liaokai on 2020-05-23 17:54:26
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {SurfaceRouter, tSurfaceRouteItem} from "../../../../../../components/surface-router/surface-router";
import {LiveEdit} from "./edit";

interface IProps extends RouteComponentProps{

}

interface IState{

}

const routerData : tSurfaceRouteItem = {
    breadTitle : "直播编辑",
    routeName : "",
    component : LiveEdit,
    children : [
        {
            breadTitle : "新建直播",
            routeName : "new",
            component : LiveEdit,
            children : []
        }
    ]
}

export class LiveEditRouter extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={routerData}/>
        );
    }
}
