/**
 * 专题更新
 * Create by liaokai on 2020-06-15 10:51:00
 */
import {toPost} from "../../http-utils";

export const subjectUpdate = toPost<
    {
        id: string  //专题id
        title : string
        synopsis : string
        poster : string
        subject_dir : {
            "live_id":string,
            "sort": number
        }   //json数据
    },
    {

    }
>("/merchant/live/subjectUpdate");
