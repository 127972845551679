/**
 * 观看限制设置
 * Create by liaokai on 2020-06-11 17:49:17
 */
import {toPost} from "../../http-utils";

export const downLiveRecordUrl = toPost<
    {
        live_record_id : number,
    },
    {
        url:string
    }
>("/merchant/live/downLiveRecordUrl");
