import { toPost } from '../../../../../../../common/apis/http-utils';
import { FetchField } from '../types';
import { merge } from 'lodash';

export const formatListResult = (result: any) => {
  const response = merge([], result);
  return {
    list: response || [],
    total: response ? response.length : 0,
  };
};

export const meetingShowList = toPost<FetchField.Add, any[]>(`/merchant/meeting/meetingShowList`);

// 开关详情
export const meetingShowSetInfo = toPost<{ meetid: any, type: number }, any>(`/merchant/meeting/meetingShowSetInfo`);

// 开关设置
export const meetingShowSet = toPost<{ meetid: any; type: number; is_show: boolean }, any>(`/merchant/meeting/meetingShowSet`);

// 最后提交时间设置
export const setLastTime = toPost<{ meetid: any; type: number; last_time: string }, any>(`/merchant/meeting/setLastTime`);

// 最后提交时间详情
export const getLastTime = toPost<{ meetid: any; type: number }, any>(`/merchant/meeting/getLastTime`);

export const hotelStore = toPost<FetchField.Add, any[]>(`/merchant/meeting/hotelStore`);

export const hotelUpdate = toPost<FetchField.Update, any[]>(`/merchant/meeting/hotelUpdate`);

export const hotelInfo = toPost<FetchField.Update, any[]>(`/merchant/meeting/hotelInfo`);

// 酒店信息
// export const getHotelUserList = toPost<{ meetid: number; paginate: number; page: number }, any[]>(`/merchant/meeting/getHotelUserList`);

// 酒店列表
export const hotelList = toPost<{ meetid: number }, any[]>(`/merchant/meeting/hotelList`);

// 酒店发布/取消发布
export const hotelPublish = toPost<{ hotel_id: number; publish_status: 1 | 2 }, any[]>(`/merchant/meeting/hotelPublish`);

// 房型列表
export const hotelRoomList = toPost<{ hotel_id: number }, any>(`/merchant/meeting/hotelRoomList`);

// 批量添加房型/修改房型
export const hotelRoomBatchStore = toPost<
  { hotel_id: number; hotel_room: any[] },
  any
>(`/merchant/meeting/hotelRoomBatchStore`);

export const hotelRoomListSelectOptions = [{
  value: 1,
  label: '大床房',
}, {
  value: 2,
  label: '标准房',
}, {
  value: 3,
  label: '其他',
}];

export const getHotelList = toPost<any, any>(`/merchant/meeting/getHotelList`);
export const getHotelUserList = toPost<any, any>(`/merchant/meeting/getHotelUserList`);
export const hotelUserDel = toPost<any, any>(`/merchant/meeting/hotelUserDel`);
export const hotelUserUpdate = toPost<any, any>(`/merchant/meeting/hotelUserUpdate`);
export const getHotelRoomList = toPost<any, any>(`/merchant/meeting/getHotelRoomList`);
export const hotelUserInfo = toPost<any, any>(`/merchant/meeting/hotelUserInfo`);





