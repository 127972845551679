/**
 * 文档
 * Create by liaokai on 2020-05-23 18:22:41
 */
import * as React from 'react';
import {Component} from "react";
import {LiveDocList} from "./component/table";
import {RouteComponentProps} from "react-router";

interface IProps{

}

interface IState{

}

export class LiveDocView extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <div>
                <LiveDocList/>
            </div>
        );
    }
}
