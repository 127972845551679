/**
 * 面包屑
 * Create by liaokai on 2020-04-15 18:56:45
 */
import * as React from 'react';
import {Component} from "react";
import {Breadcrumb} from "antd";
import {Link} from "react-router-dom";



interface IProps{
    data : {
        title : string | JSX.Element,
        link : string
    }[]
}

interface IState{
}

export class BreadcrumbCustomStateless extends Component<IProps,IState>{
    readonly state : IState = {
    };

    render() {
        let {data} = this.props;
        let {} = this.state;
        return (
            <Breadcrumb separator={<span style={{fontSize : 21}}>></span>}>
                {data.map(value => {
                    return <Breadcrumb.Item key={value.link}>
                        {
                            value.link ?
                                <Link style={{fontSize : 21}} to={value.link}>{value.title}</Link>
                                :
                                <div style={{fontSize : 21}}>{value.title}</div>
                        }
                    </Breadcrumb.Item>
                })}
            </Breadcrumb>
        );
    }
}
