// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DarkBox_DarkBox__1pWeu{width:100%;height:100%;position:fixed;top:0px;left:0px;background:rgba(0,0,0,0.6);z-index:999;opacity:0;-webkit-animation:DarkBox_ScaleIn__rli7d 0.5s forwards;animation:DarkBox_ScaleIn__rli7d 0.5s forwards}@-webkit-keyframes DarkBox_ScaleIn__rli7d{to{opacity:1}}@keyframes DarkBox_ScaleIn__rli7d{to{opacity:1}}\n", ""]);
// Exports
exports.locals = {
	"DarkBox": "DarkBox_DarkBox__1pWeu",
	"ScaleIn": "DarkBox_ScaleIn__rli7d"
};
module.exports = exports;
