/**
 * 编辑回看视频信息
 * Create by liaokai on 2020-06-19 16:08:17
 */
import * as React from 'react';
import {Component} from "react";
import {Input, message, Modal} from "antd";
import {emailAddressBookUpdate} from "../../../../../../common/apis/apis/email/emailAddressBookUpdate";
import {emailAddressBookStore} from "../../../../../../common/apis/apis/email/emailAddressBookStore";
import {TitleContentTable} from "../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import {emailAddressStore} from "../../../../../../common/apis/apis/email/emailAddressStore";
interface IEmailAddressItem {
    "email_address_book_id": number,
    "name": string,
    "email": string,
}
const defaultItem : IEmailAddressItem = {
    "email_address_book_id": 0,
    "name": "",
    "email": "",
}

interface IProps {
    visible : boolean
    addressBookId : number
    onCancel? : () => void
    onSubmit? : () => void
    storeItem?: IEmailAddressItem
}

interface IState{
    storeItem : IEmailAddressItem
}

export class EmailAddAddress extends Component<IProps,IState>{
    readonly state : IState = {
        storeItem : defaultItem,
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if(JSON.stringify(this.props.storeItem) !== JSON.stringify(prevProps.storeItem) ){
            this.setState({storeItem : this.props.storeItem ?? defaultItem})
        }
    }
    render() {
        let {storeItem} = this.state;
        let {onCancel,onSubmit,visible,addressBookId} = this.props;
        return (
            <Modal
                visible={visible}
                onCancel={()=>{
                    onCancel?.();
                }}
                onOk={()=>{
                    emailAddressStore({name:this.state.storeItem.name,email:this.state.storeItem.email,email_address_book_id:addressBookId}).then(()=>{
                        onSubmit?.()
                        onCancel?.()
                    }).catch(e => {
                        message.error("保存失败 " + e.data?.message)
                    })
                }}
                cancelText={"取消"}
                okText={"保存"}
                title={"添加"}
            >
                <TitleContentTable colGap={10} rowGap={10}>
                    <div>姓名</div>
                    <Input placeholder={"请输入姓名"}   onChange={e => {
                        storeItem.name = e.target.value
                        this.setState({storeItem : storeItem})
                    }}/>
                    <div>邮箱</div>
                    <Input placeholder={"请输入邮箱"}   onChange={e => {
                        storeItem.email = e.target.value
                        this.setState({storeItem : storeItem})
                    }}/>
                </TitleContentTable>
            </Modal>
        );
    }
}
