/**
 * 调研问卷用户回答详情
 * Create by liaokai on 2020-06-17 16:22:14
 */
import {toPost} from "../../http-utils";

export interface IliveSurveyQuestionnaireByUserAnswerInfo {
    "id":number,
    "username":string,
    "commit_time":string,
    "content":{
        "id":number,
        "title":string,
        "content":{
            "title":string,
            "type":string,
            "content":{
                "title":string,
                "value":string,
                "isAnswer":boolean,
                "isChecked":boolean
            }[]
        }[]
    }
}

export const liveSurveyQuestionnaireByUserAnswerInfo = toPost<
    {
        id : string
    },
    IliveSurveyQuestionnaireByUserAnswerInfo
>("/merchant/live/liveSurveyQuestionnaireByUserAnswerInfo");

