import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Form, Table, Button, message, Input, Modal, Popconfirm, Select, Radio, Checkbox, Cascader, AutoComplete } from 'antd';
import {
  merchantUserIndex,
  merchantUserDelUsers,
  merchantUserGetHeaderFields,
  merchantUserGetMeetingTag,
  merchantUserMakeTag,
  merchantUserSetMeetingTag,
  merchantUserDleMeetingTag,
  formatListResult,
  merchantUserTagStatistics,
  getArea,
  getEducation,
  getSubjectSpecialty,
  getHospital,
  getTechnical,
} from "./constants";
import { api } from '../../../../../../../common/app';
import { isObject } from 'lodash';

const Container = (props: any) => {
  const { item, ...rest } = props;
  return (
    <Form.Item name={item.en_name} label={item.zh_name} required={item.is_required} {...rest}>
      {props.children}
    </Form.Item>
  )
}

export default function (props: any) {
  const { id, title, onCancel, visible, editUser, callback } = props;
  const [editForm] = Form.useForm();
  const [tagForm] = Form.useForm();

  const isEdit = !!editUser && !!editUser.id;
  // edit 表单项
  const [editFormItems, setEditFormItems] = useState([] as any[]);

  // label
  const [labels, setLabels] = useState([] as any[]);

  // area
  const [areaData, setAreaData] = useState([] as any[]);

  const [editVisible, setEditVisible] = useState(false);

  // edu
  const [education, setEducation] = useState([] as any[]);

  // subject
  const [subject, setSubject] = useState([] as any[]);

  // 医院相关
  const [selectedHospitalAddress, setSelectedHospitalAddress] = useState([] as any[]);
  // 
  const [hospitalList, setHospitalList] = useState([] as any);

  const [technical, setTechnical] = useState([] as any);

  useEffect(() => {

    if (visible) {
      (api as any).getMeetingLabel(id).then(
        (res: any) => {
          if (res.code === 200) {
            setLabels(res.result);
          } else {
            message.error(res.message);
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
    }
  }, [visible]);

  // 获取编辑用户表单信息
  useEffect(() => {
    (api as any).getMeetingFormField(id).then(
      (res: any) => {
        console.log(res);
        if (res.code === 200) {
          setEditFormItems(res.result);
        } else {
          message.error(res.message);
        }
      },
      (err: any) => {
        console.log(err);
      }
    );

  }, []);

  // 获取编辑用户数据
  useEffect(() => {
    if (visible === true && !!editUser && !!editUser.id) {
      (api as any).getMeetingGuestInfo(editUser.id).then(
          (res: any) => {
              console.log(res);
              if (res.code === 200) {
                  // setEditFormValues(res.result.data);
                  Object.keys(res.result.data).forEach((key) => {
                      if (key === "education") {
                          editForm.setFieldsValue({
                              [key]: Number(res.result.data[key]),
                          });
                      } else if (key === "hospital") {
                          const hospital_area = [
                              res.result.data.type_hospital_province_code,
                              res.result.data.type_hospital_city_code,
                              res.result.data.type_hospital_area_code,
                          ];
                          editForm.setFieldsValue({
                              hospital: res.result.data.hospital || "",
                              hospital_area: hospital_area.filter((i) => !!i),
                          });
                      } else if (key === "technical") {
                          editForm.setFieldsValue({
                              [key]: Number(res.result.data[key]),
                          });
                      } else if (key === "subject") {
                          editForm.setFieldsValue({
                              [key]: res.result.data[key]
                                  ? res.result.data[key].map((i: any) =>
                                        Number(i)
                                    )
                                  : [],
                          });
                      } else {
                          editForm.setFieldsValue({
                              [key]: res.result.data[key],
                          });
                      }
                  });

                  const areaCode = [
                      res.result.data.type_area_province_code,
                      res.result.data.type_area_city_code,
                      res.result.data.type_area_area_code,
                  ];

                  editForm.setFieldsValue({
                      Cascader: areaCode.filter((i) => !!i),
                  });

                  if (res.result.tag_id) {
                      editForm.setFieldsValue({
                          label: res.result.tag_id,
                      });
                  }
              } else {
                  message.error(res.message);
              }
          },
          (err: any) => {
              console.log(err);
          }
      );
    }
  }, [editUser, visible]);

  useEffect(() => {
    getArea({})
      .then((result) => {
        setAreaData(result);
      })

    getEducation({}).then((result) => {
      setEducation(result);
    });

    getSubjectSpecialty({}).then((result) => {
      setSubject(result);
    });

    getTechnical({}).then((result) => {
      setTechnical(result);
    });

  }, []);

  useEffect(() => {
    if (selectedHospitalAddress[2]) {
      getHospital({ area_code: selectedHospitalAddress[2] })
        .then((result) => {
          console.log('result', result);
          setHospitalList(result);
        });
    }

  }, [selectedHospitalAddress]);

  const onAddTag = () => {
    setEditVisible(true)
  }

  const onSubmit = async () => {
    try {
      const payload = await editForm.validateFields();

      // 如果有地区则拼出地区地址
      const pro = payload.Cascader && areaData.find(a => a.value === payload.Cascader[0])
      const city = pro && pro.children.find((p: any) => p.value === payload.Cascader[1]);
      const area = city && city.children.find((c: any) => c.value === payload.Cascader[2]);
      const areaInfo = [pro, city, area].filter(i => !!i).map((item) => item.label).join('');

      // 如果有学科则拼出学科名字
      const sub = payload.subject && subject.find((s) => s.value === payload.subject[0]);
      const subName = sub && sub.children.find((n: any) => n.value === payload.subject[1]);
      const subjectInfo = [sub, subName].filter(i => !!i).map((item) => item.label).join('');

      // 如果有医院地址则拼出医院地址 
      const hos = payload.hospital_area && areaData.find(a => a.value === payload.hospital_area[0]);
      const hoscity = hos && hos.children.find((p: any) => p.value === payload.hospital_area[1]);
      const hosarea = hoscity && hoscity.children.find((c: any) => c.value === payload.hospital_area[2]);
      const hospitalInfo = [hos, hoscity, hosarea].filter(i => !!i).map((item) => item.label).join('');
      // console.log('hospitalInfo', hospitalInfo);

      const values: any = {
        ...payload,
        ...!!payload.area
          ? { area: typeof payload.area === 'string' ? payload.area : payload.Cascader.join('') }
          : {},
        ...payload.Cascader
          ? {
            // 如果有地区
            type_area_province_code: payload.Cascader[0] || '',
            type_area_city_code: payload.Cascader[1] || '',
            type_area_area_code: payload.Cascader[2] || '',
            type_area_area_info: areaInfo,
          }
          : {},
        // ...payload.subject
        //   ? {
        //     // type_subject_province_code: payload.Cascader[0] || '',
        //     // type_subject_city_code: payload.Cascader[1] || '',
        //     type_subject_info: subjectInfo
        //   }
        //   : {},
        ...payload.hospital_area
          ? {
            type_hospital_area_info: hospitalInfo,
            type_hospital_province_code: payload.hospital_area[0],
            type_hospital_city_code: payload.hospital_area[1],
            type_hospital_area_code: payload.hospital_area[2],
          }
          : {},
      }
      delete values.label;
      delete values.Cascader;
      delete values.hospital_area;
      console.log('values', values);
      // return;
      console.log(JSON.stringify(
        {
          uid: isEdit ? editUser.id : '',
          meetid: id,
          ...values,
          tagid: payload.label ? payload.label.map((item: any) => Number(item)) : [],
        }
      ));
      // return;
      (api as any).setGuestInfo(
        isEdit ? editUser.id : '',
        id,
        values,
        payload.label ? payload.label.map((item: any) => Number(item)) : [],
      )
        .then(
          (res: any) => {
            console.log(res);
            if (res.code === 200) {
              message.success(res.message);
              onCancel();

              if (callback) {
                  callback();
              }
            } else {
              message.error(res.message);
            }
          },
          (err: any) => {
            console.log(err);
          }
        )
    } catch (error) {
      error.message && message.warn(error.message);
    }
  }

  const onEditCate = () => {
    const { callback } = props;
    const values = tagForm.getFieldsValue();
    const fetchUrl = merchantUserSetMeetingTag;

    const payload: any = {
      meetid: id,
      name: values.name,
    };
    fetchUrl(payload)
      .then((response) => {
        message.success(`新增成功！`);
        (api as any).getMeetingLabel(id).then(
          (res: any) => {
            if (res.code === 200) {
              setLabels(res.result);
            } else {
              message.error(res.message);
            }
          },
          (err: any) => {
            console.log(err);
          }
        );
        setEditVisible(false);
        callback && callback();
      })
      .catch((error) => {
        message.error(error?.data?.message);
      })
  };

  const options = [
    {
      value: 'zhejiang',
      label: 'Zhejiang',
      children: [
        {
          value: 'hangzhou',
          label: 'Hangzhou',
          children: [
            {
              value: 'xihu',
              label: 'West Lake',
            },
          ],
        },
      ],
    },
    {
      value: 'jiangsu',
      label: 'Jiangsu',
      children: [
        {
          value: 'nanjing',
          label: 'Nanjing',
          children: [
            {
              value: 'zhonghuamen',
              label: 'Zhong Hua Men',
            },
          ],
        },
      ],
    },
  ]

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      okText='确定'
      cancelText='取消'
      centered
      onOk={() => onSubmit()}
    >
      <Form
        form={editForm}
        initialValues={{ Cascader: [] }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
      >
        {editFormItems.map((item) => {
          switch (item.type) {
            case 'input': {
              return (
                <Container item={item}>
                  <Input />
                </Container>
              )
            }

            case 'select': {
              return (
                <Container item={item}>
                  <Select style={{ width: "100%" }} >
                    {item.check?.map((opt: string) => {
                      return (
                        <Select.Option value={opt}>{opt}</Select.Option>
                      )
                    })}
                  </Select>
                </Container>
              )
            }

            case 'radio': {
              return (
                <Container item={item}>
                  <Radio.Group>
                    {item.check?.map((opt: string) => {
                      return (
                        <Radio value={opt}>{opt}</Radio>
                      )
                    })}
                  </Radio.Group>
                </Container>
              )
            }

            case "textArea": {
              return (
                <Container item={item}>
                  <Input.TextArea />
                </Container>
              )
            }
            case "checkBox":
              return (
                <Container item={item}>
                  <Checkbox.Group
                    options={item.check?.map((opt: any) => { return { label: opt, value: opt } })}
                  />
                </Container>
              )


            case 'hospital':
              return (
                <Form.Item label='医院' required={item.is_required}>
                  <Form.Item name='hospital_area'>
                    <Cascader
                      options={areaData}
                      placeholder='请选择医院地址'
                      onChange={(value) => {
                        setSelectedHospitalAddress(value);
                      }}
                    />
                  </Form.Item>
                  {/* <Container item={item}>
                    <Input />
                  </Container> */}
                  <Form.Item name='hospital' style={{ marginBottom: 0 }}>
                    <AutoComplete
                      showSearch
                      placeholder="请选择医院"
                      optionFilterProp="children"
                      // value={value}
                      onChange={(key: any) => {
                        // onChange(item, key);
                      }}
                      filterOption={(input: any, option: any) => {
                        return option.children.indexOf(input) >= 0;
                      }}
                    >
                      {hospitalList &&
                        hospitalList.map((hitem: any) => {
                          return (
                            <AutoComplete.Option
                              value={hitem.label}
                              key={hitem.value}
                            >
                              {hitem.label}
                            </AutoComplete.Option>
                          );
                        })}
                    </AutoComplete>
                  </Form.Item>
                </Form.Item>
              );
            case 'area':
              return (
                // <Form.Item name='area' label=''>
                //   <Cascader options={options} placeholder='请选择城市' />
                // </Form.Item>

                <Form.Item name='Cascader' label='省市区' required={item.is_required} >
                  <Cascader options={areaData} placeholder='请选择城市' />
                </Form.Item>
              );

            case 'subject':
              return (
                <Container item={item}>
                  <Cascader options={subject} placeholder='请选择学科' />
                </Container>
              );

            case 'technical':
              return (
                <Container item={item}>
                  <Select options={technical} placeholder='请选择职称' />
                </Container>
              );
            case 'education':
              return (
                <Container item={item}>
                  <Select options={education} />
                </Container>
              );
            case 'position':
              return (
                <Container item={item}>
                  <Input />
                </Container>
              );
            default: {
              return <div />
            }
          }
        })}

        <Form.Item name='label' label='选择标签' required={true}>
          <Select mode='multiple'>
            {labels && labels.filter((i) => i.name !== '全部').map((item) => {
              return (
                <Select.Option value={item.id}>{item.name}</Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item label='新建标签'>
          <Button onClick={onAddTag}>新增</Button>
        </Form.Item>
      </Form>


      <Modal
        okText="确定"
        cancelText="取消"
        onCancel={() => setEditVisible(false)}
        onOk={() => onEditCate()}
        title={'新增标签'}
        visible={editVisible}
      >
        <Form form={tagForm}>
          <Form.Item
            label="标签标题"
            name="name"
            rules={[{ required: true, message: '请输入标签标题' }]}
          >
            <Input maxLength={8} />
          </Form.Item>
        </Form>
      </Modal>
    </Modal >
  )
}