/**
 * 新建直播
 * Create by liaokai on 2020-05-25 17:01:08
 */
import * as React from 'react';
import {Component} from "react";
import {Responsive} from "../../../../components/common/responsive";
import {LiveEdit} from "../live/menu/edit/edit";
import {RouteComponentProps} from "react-router";
import {Air} from "../../../../components/air/Air";

interface IProps extends RouteComponentProps{

}

interface IState{

}

export class NewLive extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <Responsive>
                <Air vertical={20}/>
                <LiveEdit {...this.props}/>
            </Responsive>
        );
    }
}
