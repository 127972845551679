import React, { useEffect, useState } from 'react';
import { Form, Table, Switch, Button, message, Spin, Modal, Select, Space, DatePicker, Input, TimePicker, Cascader, Popconfirm } from 'antd';
import { useAntdTable, useBoolean, useMount } from 'ahooks';
import {
  journeyList,
  journeyDel,
  journeyInfo,
  journeyUpdate,
  formatListResult,
  getTrain,
  getPlain
} from './constants';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { Air } from '../../../../../../components/air/Air';
import { FlexJustifyBetween } from '../../../../../../components/style/flex/flex-justify-between';
import { Flex } from '../../../../../../components/style/flex/flex';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { meetingShowSetInfo, meetingShowSet, setLastTime, getLastTime } from '../hotel/constants';
import CostomModal from '../plain/component/modal';
import { givePlainUsersList } from '../plain/constants';
import { getAreaCity } from '../guest/guest/constants';

const { Search } = Input;

const Trip = (props: any) => {
  const meetid = props.match.params.meeting_id;
  const history = useHistory();
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [timeForm] = Form.useForm();
  const [tableEnable, { toggle }] = useBoolean(true);
  
  // area
  const [areaData, setAreaData] = useState([] as any[]);
  // 设置最晚提交时间
  const [timeVisible, setTimeVisible] = useState(false);
  // 修改接送机modal
  const [plainVisible, setPlainVisible] = useState(false);
  // 用户是否需要接送
  const [needSelected, setNeedSelected] = useState(0);
  // 表单属性根据needSelected变化
  const [formCommonProps, setFormCommonProps] = useState({} as any);
  // 修改接送机数据
  const [editData, setEditData] = useState({} as any);
  const [lastTime1, setLastTime1] = useState("");

  const [editGoaddressPickerData, setEditGoAddressPickerData] = useState([] as any[]);
  const [editBackaddressPickerData, setEditBackAddressPickerData] = useState([] as any[]);

  useMount(() => {
    meetingShowSetInfo({
      meetid,
      type: 5,
    }).then((res) => {
      toggle(res.is_show);
    });

    getAreaCity({})
      .then((result) => {
        setAreaData(result);
      })
  });

  useEffect(() => {
    let formFields: any = {};
    if (needSelected === 0) {
      formFields = {
        disabled: true,
        required: false
      };
    } else {
      formFields = {
        disabled: false,
        required: true
      };
    }
    // setFormCommonProps(formFields);
  }, [needSelected]);

  // 关闭modal时清空edit数据
  useEffect(() => {
    if (plainVisible === false) {
      setEditData({});
    }
  }, [setPlainVisible]);

  const changeShowSet = () => {
    meetingShowSet({
      meetid,
      type: 5,
      is_show: !!tableEnable ? 2 : 1,
    } as any)
      .then((res) => {
        toggle(res.is_show);
      })
      .catch((e) => {
        message.error("设置失败 " + e.data?.message);
      });
  };

  const getLastTimeHandle = () => {
    getLastTime({ meetid, type: 3 })
      .then(response => {
        if (response.last_time !== 0) {
          setLastTime1(moment(response.last_time * 1000).format("YYYY-MM-DD"));
          const time = moment(response.last_time * 1000);
          timeForm.setFieldsValue({ last_time: time });
        } else {
          setLastTime1('');
          timeForm.setFieldsValue({ last_time: '' });
        }
      })
      .catch((e) => {
        message.error(e.data?.message);
      });
  }

  // 请求最后提交时间设置
  useEffect(() => {
    getLastTimeHandle();
  }, []);

  // 设置最晚提交时间
  const onSetTime = async () => {
    try {
      const values = await timeForm.validateFields();
      const payload = {
        meetid,
        type: 3,
        last_time: values.last_time ? moment(values.last_time).unix() as any : 0
      };
      setLastTime(payload)
        .then(() => {
          message.success('设置成功！');
        })
        .catch((error) => {
          message.warn(error?.data?.message);
        })
        .finally(() => {
          setTimeVisible(false);
          getLastTimeHandle();
        });
    } catch (error) {
      message.warn(error?.errorFields[0]?.errors[0] || ' ');
    }
  }

  const { tableProps, search } = useAntdTable(
    (paginatedParams: any, tableProps: any) => journeyList({
      meetid: meetid,
      // page: paginatedParams.current,
      // paginate: paginatedParams.pageSize
    }),
    { form, formatResult: formatListResult }
  );
  const { submit, reset } = search;

  // 新增、修改
  const onSetPlain = async () => {
    try {
      const values = await editForm.validateFields();
      // 是否是更改
      console.log('editData', editData);
      console.log('values', values);
      // return;
      const isEdit = !!editData.id;
      // 请求url
      const fetchFunction = journeyUpdate;

      const currentGoPostion = editGoaddressPickerData.find(
          (i) => i.value === values.go_train_id
      );
       const currentBackPostion = editBackaddressPickerData.find(
          (i) => i.value === values.back_train_id
      );
      
      // const currentBackProvince: any = areaData?.find((p) => p.value === values.back_city_code[0]);
      // const currentBackCity = currentBackProvince?.children?.find((c: any) => c.value === values.back_city_code[1])

      // const currentDepartProvince: any = areaData?.find((p) => p.value === values.depart_city_code[0]);
      // const currentDepartCity = currentDepartProvince?.children?.find((c: any) => c.value === values.depart_city_code[1])

      // 请求报文
      const payload: any = {
          meetid: meetid,
          ...(isEdit ? { id: editData.id } : {}),
          train_number: values.train_number,
          train_time: values.train_time?.valueOf() / 1000,
          go_train_id: values.go_train_id,
          go_train_name: currentGoPostion?.label || "",
          back_train_id: values.back_train_id,
          back_train_name: currentBackPostion?.label || "",
          depart_time: values.depart_time?.valueOf() / 1000,
          city_code: editData.train_type === 2 ? values.depart_city_code[1] : values.back_city_code[1],
          // ...values,
          // back_city_code: values.back_city_code[1],
          // back_province_code: values.back_city_code[0],
          // back_city: currentBackCity?.label || '',
          // depart_city_code: values.depart_city_code[1],
          // depart_province_code: values.depart_city_code[0],
          // depart_city: currentDepartCity?.label || '',
          // city_code: editData.train_type === 2 ? values.depart_city_code[1] : values.back_city_code[1],
          // depart_time: values.depart_time?.valueOf() / 1000,
          // train_time: values.train_time?.valueOf() / 1000,
          // go_train_name: currentGoPostion?.label || "",
          // back_train_name: currentBackPostion?.label || "",
          // ...(isEdit ? { id: editData.id } : {}),
      };
      console.log('payload', payload);
      console.log(JSON.stringify(payload));
      // return;
      fetchFunction(payload)
        .then(response => {
          message.success('设置成功');
          setEditData({});
          editForm.resetFields();
          setPlainVisible(false);
          submit();
        })
        .catch((e) => {
          message.error(e.data?.message);
        });
    } catch (error) {
      message.warn(error?.errorFields[0]?.errors[0] || ' ');
    }
  }

  const onDelete = (item: any) => {
    journeyDel({ meetid, id: item.id })
      .then((result: any) => {
        message.success('删除成功！');
        submit();
      })
      .catch((error: any) => {
        message.warn(error.message);
      })
  }

  const onEditOpen = (item: any) => {
    journeyInfo({ meetid, id: item.id })
      .then((result: any) => {
        setEditData(result);

        const fetchUrl = result.type === 1 ? getTrain : getPlain;
        fetchUrl({ city_code: result.depart_city_code }).then(
            (response: any) => {
                const pickerData =
                    response.length > 0
                        ? response.map((item: any) => {
                              return {
                                  label: item.name,
                                  value: item.id,
                              };
                          })
                        : [];
                setEditGoAddressPickerData(pickerData);
            }
        );
        
        fetchUrl({ city_code: result.back_city_code }).then((response: any) => {
            const pickerData =
                response.length > 0
                    ? response.map((item: any) => {
                          return {
                              label: item.name,
                              value: item.id,
                          };
                      })
                    : [];
            setEditBackAddressPickerData(pickerData);
        });


        editForm.setFieldsValue({
            back_city_code: [result.back_province_code, result.back_city_code],
            depart_city_code: [result.depart_province_code, result.depart_city_code],
            depart_time: moment(result.depart_time * 1000),
            train_time: moment(result.train_time * 1000),
            identity: result.identity,
            is_need: result.is_need,
            mobile: result.mobile,
            name: result.name,
            train_number: result.train_number,
            train_type: result.train_type,
            type: result.type,
            back_train_id: result.back_train_id || '',
            go_train_id: result.go_train_id || '',
        });

        setPlainVisible(true);
      })
      .catch((error: any) => {
        message.warn(error.message);
      })
  }

  const columns: any[] = [
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '类型',
      dataIndex: 'train_type_str',
    },
    {
      title: '行程类型',
      dataIndex: 'type_str',
    },
    {
      title: '日期',
      dataIndex: 'depart_time_str',
    },
    {
      title: '车次/航班号',
      dataIndex: 'train_number'
    },
    {
      title: '出发城市',
      dataIndex: 'depart_city'
    },
    {
      title: '到达城市',
      dataIndex: 'back_city'
    },
    {
      title: '操作',
      render: (item: any) => {
        return (
          <div>
            <Button
              type={'link'}
              disabled={item.is_publish}
              style={{ paddingLeft: 0 }}
              onClick={() => onEditOpen(item)}
            >
              编辑
            </Button>
            {`  `}
            <Popconfirm
                title="删除后无法恢复，确认删除该信息吗?"
                onConfirm={() => {
                  onDelete(item);
                }}
                okText="确定"
                cancelText="取消"
            >
              <Button
                  type={'link'}
                  style={{ paddingLeft: 0 }}
                >
                  删除
                </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ].map((item, index) => {
    return {
      ...item,
      key: item.dataIndex || `${index}`
    }
  });

  const onChangeCity = (value: string[], type: string) => {
    console.log('onchange', value);
    const fetchUrl = editData.type === 1 ? getTrain : getPlain;
     fetchUrl({ city_code: value[1] }).then(
        (response: any) => {
            const pickerData =
                response.length > 0
                    ? response.map((item: any) => {
                          return {
                              label: item.name,
                              value: item.id,
                          };
                      })
             : [];
         
         if (type === 'depart') {
           editForm.setFieldsValue({
              go_train_id: []
            })
            setEditGoAddressPickerData(pickerData);
         } else {
           editForm.setFieldsValue({
              back_train_id: []
            })
            setEditBackAddressPickerData(pickerData);
          }
        }
    );
  }

  // console.log('userResult', userResult);
  return (
    <div>
      <FlexJustifyBetween style={{ marginBottom: 12 }}>
        <Flex style={{ alignItems: "center" }}>
          <Switch checked={tableEnable} onChange={changeShowSet} />
          <Air horizontal={3} />
          <div>启用行程</div>

          <Air horizontal={5} />
          <Form form={form}>
            <Form.Item name="word" style={{ marginBottom: 0 }}>
              <Search
                onSearch={() => submit()}
                enterButton="搜索"
                placeholder="请输入搜索内容"
              />
            </Form.Item>
          </Form>
        </Flex>
        <Flex>
          <Space>
            <Button
              disabled={!tableEnable}
              onClick={() => setTimeVisible(true)}
            >
              {lastTime1 || "最晚提交时间 "}
              <span
                style={{
                  textDecoration: "underline",
                  marginLeft: 6,
                }}
              >{` 设置`}</span>
            </Button>
          </Space>
        </Flex>
      </FlexJustifyBetween>
      <Spin indicator={<div />} tip="尚未开启行程" spinning={!tableEnable}>
        <Table
          rowKey="id"
          columns={columns}
          {...tableProps}
          pagination={false}
        />
      </Spin>

      <CostomModal
        visible={timeVisible}
        isTimeLayout={true}
        onCancel={() => {
          timeForm.resetFields();
          setTimeVisible(false);
        }}
        onOk={() => onSetTime()}
        title={"设置最后提交时间"}
        form={timeForm}
        formItems={[
          {
            render: () => {
              return (
                <Form.Item
                  label={"最后提交&修改时间"}
                  name="last_time"
                >
                  <DatePicker
                    style={{ width: 200 }}
                    locale={locale}
                    placeholder={"设置最晚提交时间"}
                  />
                </Form.Item>
              );
            },
          },
        ]}
      />

      <CostomModal
        visible={plainVisible}
        onCancel={() => {
          editForm.resetFields();
          setPlainVisible(false);
        }}
        onOk={() => onSetPlain()}
        title={"修改行程"}
        form={editForm}
        formItems={[
          {
            render: () => {
              return (
                <Form.Item
                  label="姓名"
                  name="name"
                  rules={[
                    { required: true, message: "请输入姓名" },
                  ]}
                >
                  <Input disabled={true} />
                </Form.Item>
              );
            },
          },
          {
            label: "手机号",
            name: "mobile",
            disabled: true,
            rules: [{ required: true, message: "请输入手机号" }],
          },
          {
            label: "身份证号",
            name: "identity",
            disabled: true,
            rules: [{ required: true, message: "请输入身份证号" }],
          },
          {
            render: () => {
              return (
                <Form.Item
                  label="行程类型"
                  name="type"
                  rules={[
                    { required: true, message: "请选择类型" },
                  ]}
                >
                  <Select
                    disabled={formCommonProps.disabled}
                    onChange={(value) => {
                      const citys = editForm.getFieldsValue(['depart_city_code', 'back_city_code'])
                      console.log('citys', citys);
                      const { depart_city_code, back_city_code } = citys;
                      const fetchUrl = value === 1 ? getTrain : getPlain;
                      fetchUrl({ city_code: depart_city_code[1] }).then(
                        (response: any) => {
                          const pickerData =
                            response.length > 0
                              ? response.map((item: any) => {
                                return {
                                  label: item.name,
                                  value: item.id,
                                };
                              })
                              : [];
                          editForm.setFieldsValue({
                            go_train_id: []
                          })
                          setEditGoAddressPickerData(pickerData);
                        });

                      fetchUrl({ city_code: back_city_code[1] }).then(
                        (response: any) => {
                          const pickerData =
                            response.length > 0
                              ? response.map((item: any) => {
                                return {
                                  label: item.name,
                                  value: item.id,
                                };
                              })
                              : [];
                          editForm.setFieldsValue({
                            back_train_id: []
                          });
                          setEditBackAddressPickerData(pickerData);
                        });
                  }}
                  >
                    <Select.Option value={1}>
                      火车
                    </Select.Option>
                    <Select.Option value={2}>
                      飞机
                    </Select.Option>
                  </Select>
                </Form.Item>
              );
            },
          },
          {
            render: () => {
              return (
                <Form.Item
                  label="出发城市"
                  name="depart_city_code"
                  rules={[{ required: true, message: "请选择出发城市" }]}
                >
                  <Cascader onChange={(value) => onChangeCity(value, 'depart')} options={areaData} disabled={editData.train_type === 2} />
                </Form.Item>
              )
            }
          },
          {
            render: () => {
              return (
                <Form.Item
                  label="到达城市"
                  name="back_city_code"
                  rules={[{ required: true, message: "请选择到达城市" }]}
                >
                  <Cascader onChange={(value) => onChangeCity(value, 'back')} options={areaData} disabled={editData.train_type === 1} />
                </Form.Item>
              )
            }
          },
          {
            label: "航班车次",
            name: "train_number",
            disabled: formCommonProps.disabled,
            rules: [{ required: true, message: "请输入车次/航班号" }],
          },
          {
            render: () => {
              return (
                  <Form.Item
                      label="出发站点"
                      name="go_train_id"
                      rules={[{ required: true, message: "请选择出发站点" }]}
                  >
                      <Select
                          options={editGoaddressPickerData}
                      />
                  </Form.Item>
              );
            },
          },
          {
            render: () => {
              return (
                  <Form.Item
                      label="到达站点"
                      name="back_train_id"
                      rules={[{ required: true, message: "请选择到达站点" }]}
                  >
                      <Select
                          options={editBackaddressPickerData}
                      />
                  </Form.Item>
              );
            },
          },
          {
            render: () => {
              return (
                <Form.Item
                  label="行程"
                  name="depart_time"
                  rules={[
                    { required: true, message: "请选择行程" },
                  ]}
                >
                  <DatePicker
                    // showTime
                    disabled={formCommonProps.disabled}
                    style={{ width: "100%" }}
                    locale={locale}
                    placeholder={"设置行程"}
                  />
                </Form.Item>
              );
            },
          },
          {
            render: () => {
              return (
                <Form.Item
                  label="日期"
                  name="train_time"
                  rules={[
                    { required: true, message: "请选择日期" },
                  ]}
                >
                  <TimePicker
                    // showTime
                    disabled={formCommonProps.disabled}
                    style={{ width: "100%" }}
                    locale={locale}
                    placeholder={"设置日期"}
                  />
                </Form.Item>
              );
            },
          },
        ]}
      />
    </div>
  );
};


export { Trip }