/**
 * 上传单个文件
 * Create by liaokai on 2020-05-28 16:48:03
 */
import * as React from 'react';
import {Component} from "react";
import {message} from "antd";

interface IProps{
    accept? : string,
    maxFileSize? : number  //kb
    onLoadFile? : (file : File) => void
}

interface IState{

}

export class LoadingSingleFile extends Component<IProps,IState>{
    readonly state : IState = {

    };

    fileInput : any = React.createRef()

    render() {
        let {children, accept} = this.props;
        let {} = this.state;
        return (
            <div onClick={()=>{
                this.fileInput?.current?.click?.();
            }}>
                {children}
                <input type="file" accept={accept ?? ""} ref={this.fileInput} onChange={event => {
                    let {maxFileSize, onLoadFile} = this.props
                    const maxFileSize_ = maxFileSize ?? Number.MAX_VALUE
                    let file = event?.target?.files?.[0] as File;
                    if(file?.size > (maxFileSize ?? maxFileSize_)){
                        message.error(`请确保文件不大于 ${maxFileSize_ / 1024 / 1024}MB`);
                    }else{
                        onLoadFile?.(file)
                    }
                }} style={{display: 'none'}}/>
            </div>
        );
    }
}
