/**
 * 单次直播
 * Create by liaokai on 2020-05-26 10:54:41
 */
import * as React from 'react';
import {Component} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {LiveListView} from "../index";
import {LiveMenu} from "./menu/menu";
import {RouteComponentProps} from "react-router";
import {Live_room} from "./live_room/live_room";
import {liveStore} from "./menu/live.store";
import {apis} from "../../../../common/apis/apis";
import {liveShow} from "../../../../common/apis/apis/live/liveShow";
import {presentStore} from "./menu/present-store";
import {liveIconIndex} from "../../../../common/apis/apis/live/liveIconIndex";

interface IProps extends RouteComponentProps<{live_id : string}>{

}

interface IState{

}

export class Live extends Component<IProps,IState>{
    readonly state : IState = {

    };

    componentWillMount() {
        liveStore.setLiveId(this.props.match.params.live_id);
    }

    componentDidMount(): void {
        liveStore.setReload(()=>{
            liveShow({id : this.props.match.params.live_id ?? ""}).then(res => {
                liveStore.setLiveInfo(res);
            })
        })
        liveStore.reload();
        presentStore.setReload(()=>{
            liveIconIndex({
                live_id : this.props.match.params.live_id
            }).then(res => {
                presentStore.setPresentInfo(res)
            })
        })
        presentStore.reload();
    }

    render() {
        let {match : {path, url}, location: {pathname}} = this.props;
        let {} = this.state;
        return (
            <Switch>
                <Route path={`${path}/menu`} component={LiveMenu} />
                <Route path={`${path}/live_room`} component={Live_room} />
                <Redirect path={path} to={`${path}/menu`} />
            </Switch>
        );
    }
}
