/**
 * 短信取消发送
 * Create by liaokai on 2020-05-21 14:09:26
 */
import {toPost} from "../../http-utils";

export const smsTemplateLogCancel = toPost<
    {
        id : number
    },
    {

    }
    >("/merchant/Lcm/smsTemplateLogCancel");
