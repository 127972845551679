/**
 * 互动问答
 * Create by liaokai on 2020-05-23 18:15:25
 */
import * as React from 'react';
import {Component} from "react";
import {LiveQaList} from "./component/qa-table";
import {LiveQaDetailList} from "./component/qa-detail-table";
import {Air} from "../../../../../../components/air/Air";

interface IProps{

}

interface IState{

}

export class LiveQaView extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <div>
                <LiveQaList/>
                {/*<Air vertical={10}/>*/}
                {/*<LiveQaDetailList/>*/}
            </div>
        );
    }
}
