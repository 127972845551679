/**
 * 邮件模板列表
 * Create by liaokai on 2020-04-23 17:52:00
 */
import {toPost} from "../../http-utils";

interface IEmailAddressBookListItem {
    "id": number,
    "name": string,
    "count": number
    "operation": undefined
}

export const emailAddressBookList = toPost<
    {
        // id : number,
        page: number
        paginate: number
    },
    {
        "total": number,
        "per_page": number,
        "current_page": number,
        "last_page": number,
        "list": IEmailAddressBookListItem[]
    }
    >("/merchant/Email/emailAddressBookList");
