import Login from "../../routes/Login"
import React, {useState} from "react";
import chromeImg from "../../assets/chrome-logo.svg"
import {Button} from "antd";
import {BorderHorizontalOutlined, CloseOutlined} from "@ant-design/icons/lib";
import {common} from "../../common/common";

export function IEBlockLogin() {
    const [close , setClose] = useState(!common.isIE);

    return <div style={{width: "100%", height: "100%"}}>
        <Login/>
        <div style={{display: close ? "none" : "flex", zIndex : 9999 ,  justifyContent: "center", alignItems: "center",position: "fixed", top : 0, bottom : 0, left : 0, right : 0, background : "rgba(0,0,0,0.5)"}}>
            <div style={{background : "#fff", padding : 50, borderRadius : 10, boxShadow : "10px #fff"}}>
                <div style={{display : "flex", flexDirection: "column", alignItems : "center"}}>
                    <div style={{alignSelf : "flex-end", cursor : "pointer"}} onClick={()=>{setClose(true)}}>
                        <CloseOutlined style={{fontSize : 30}} />
                    </div>
                    <img style={{width : 100, height: 100}} src={chromeImg} alt=""/>
                    <div style={{color : "#666", margin : "30px 0px 20px"}}>
                        为更好支持网站功能特性，推荐您使用先进的Chrome浏览器
                    </div>
                    <Button size={"large"} type={"primary"} onClick={event => {
                        window.location.href = "https://www.google.cn/chrome/"
                    }}>
                        下载 Chrome
                    </Button>
                </div>
            </div>
        </div>
    </div>
}
