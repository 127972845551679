import React, { Component } from 'react'
import style from './PageBurster.scss'
  
export class PageBurster extends Component {
constructor(props) {
  super(props);
  this.state = {
    listpage:null,
    totalpage:null,
  };
  this.refreshProps = this.refreshProps.bind(this);
  this.HandleListPage = this.HandleListPage.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
}
refreshProps(props) {
  this.state.listpage = parseInt(props.nowpage)?parseInt(props.nowpage):this.state.listpage;
  this.state.totalpage = parseInt(props.max)==0?parseInt(props.max):parseInt(props.max)?parseInt(props.max):this.state.totalpage;
  this.setState(this.state);
  this.createPageListArray();
}
HandleListPage(page){
    if (page<1||page>this.state.totalpage||page===this.state.listpage) return;
    this.state.listpage = page;
    this.setState(this.state);
    this.props.onPageChange(page);
}
createPageListArray(){
    if (!this.state.listpage&&!this.state.totalpage) return;
    let nowgroup = parseInt(this.state.listpage / 5.001);
    let totalgroup = Math.ceil(this.state.totalpage / 5);
    // console.log(totalgroup);
    let result = [];    
    for (let index = nowgroup * 5; index < (nowgroup*5)+5; index++) {
        if (index>=this.state.totalpage){

        }else{
            result.push(
                <div key={'page'+ (index+1)} onClick={this.HandleListPage.bind(this,(index+1))} className={[style.PagerNum,this.state.listpage===(index+1)?style.Act_PagerNum:'','childcenter'].join(' ')}>
                    {index+1}
                </div>
            )
        }
        
    }
    return result;
}
render() {
  return (
    <div className={[style.PagerHandleBox,'childcenter childcontentend'].join(' ')}>
        <div className={[style.Pager,'childcenter'].join(' ')}>
            <div className={[style.PagerNum,'childcenter'].join(' ')} onClick={this.HandleListPage.bind(this,(this.state.listpage-1))}>
                <div className={[style.ArrowIcon,style.arrowLeft].join(' ')}></div>
            </div>
            {this.createPageListArray()}
            {/* <div className={[style.PagerNum,this.state.listpage===1?style.Act_PagerNum:'','childcenter'].join(' ')}>
                1
            </div>
            <div className={[style.PagerNum,this.state.listpage===2?style.Act_PagerNum:'','childcenter'].join(' ')}>
                2
            </div>
            <div className={[style.PagerNum,this.state.listpage===3?style.Act_PagerNum:'','childcenter'].join(' ')}>
                3
            </div>
            <div className={[style.PagerNum,this.state.listpage===4?style.Act_PagerNum:'','childcenter'].join(' ')}>
                4
            </div>
            <div className={[style.PagerNum,this.state.listpage===5?style.Act_PagerNum:'','childcenter'].join(' ')}>
                5
            </div> */}
            <div className={[style.PagerNum,'childcenter'].join(' ')} onClick={this.HandleListPage.bind(this,(this.state.listpage+1))}>
                <div className={[style.ArrowIcon,style.arrowRight].join(' ')}></div>
            </div>
        </div>
    </div>
   )
   }
}
export default PageBurster