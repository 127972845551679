/**
 * 短信包类型
 * Create by liaokai on 2020-05-09 16:30:25
 */
import * as React from 'react';
import {Component} from "react";
import {common} from "../../../../../../../../common/common";
import {Air} from "../../../../../../../../components/air/Air";
import {Button} from "antd";
import style from "./sms-package-item.scss"

export interface ISmsPackageItem {
    badge? : string,
    smsValue? : number,
    price? : number,
    originalPrice? : number,
    smsId? : string  //短信包id
    titleSuffix? : string
}

interface IProps extends ISmsPackageItem{
    selected?: boolean,
    onClick? : ()=> void
}

interface IState{

}



export class SmsPackageItem extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {selected, onClick, badge, smsValue, price, originalPrice,titleSuffix} = this.props;
        let {} = this.state;
        return (
            <div className={style.container} style={selected ? {background : "linear-gradient(to right, #ffedd4 , #ffcf89)"} : {}} onClick={()=>{onClick?.()}}>
                <div className={style.badge}>
                    {badge ?? "优惠"}
                </div>
                <div className={style.title}>{smsValue ?? 0}{titleSuffix ?? "条短信"}</div>
                <div className={style.priceBox}>
                    <div className={style.price}>
                        <span className={style.rmb}>¥</span>{(price ?? 0)?.toFixed(2)}
                    </div>
                    <Air horizontal={5}/>
                    <s className={style.originalPrice}>¥{(originalPrice ?? 0)?.toFixed(2)}元</s>
                </div>
                <div className={style.tail}>
                    立省{(originalPrice ?? 0) - (price ?? 0)}元
                </div>
            </div>
        );
    }
}
