import React, { useState, useRef } from "react";
import { Form, Table, Switch, Button, message, Spin, Input, Modal } from "antd";
import { useAntdTable, useBoolean, useMount } from "ahooks";
import {
    askQuestionList,
    formatListResult,
    exportAskQuestionListUrl,
    askQuestionCheck,
    askQuestionReplyList,
    askQuestionReply,
} from "./constants";
import { liveStore } from "../live.store";
import { Air } from "../../../../../../components/air/Air";
import { enableShowSet } from "../../../../../../common/apis/apis/live/enableShowSet";
import { FlexJustifyBetween } from "../../../../../../components/style/flex/flex-justify-between";
import { Flex } from "../../../../../../components/style/flex/flex";
import { enableShowInfo } from "../../../../../../common/apis/apis/live/enableShowInfo";
// import Screen from "./component/screen";
import { Link } from "react-router-dom";
import moment from "moment";

const { TextArea } = Input;
const { Search } = Input;

function Answer() {
    const [form] = Form.useForm();
    const [tableEnable, { toggle }] = useBoolean(false);
    const [screenVisible, { toggle: toggleScreen }] = useBoolean(false);
    const [currentQuestion, setCurrentQuestion] = useState({} as any);
    const [modalVisible, { toggle: toggleModal }] = useBoolean(false);
    const [value, setValue] = useState("");

    useMount(() => {
        enableShowInfo({
            live_id: liveStore.live_id,
            type: 26,
        }).then((res) => {
            toggle(res.is_show);
        });
    });

    const changeShowSet = () => {
        enableShowSet({
            live_id: liveStore.live_id,
            type: 26,
        })
            .then((res) => {
                toggle(res.is_show);
            })
            .catch((e) => {
                message.error("设置失败 " + e.data?.message);
            });
    };
    const exportList = () => {
        exportAskQuestionListUrl({
            live_id: Number(liveStore.live_id),
        }).then((response: any) => {
            const href = response.url;
            window.open(href, "_blank");
            message.success("导出成功");
        });
    };

    const { tableProps, search } = useAntdTable(
        (paginatedParams: any, tableProps: any) => {
            console.log("paginatedParams", paginatedParams);
            return askQuestionList({
                live_id: liveStore.live_id,
                page: paginatedParams.current,
                paginate: 15,
                ...tableProps,
            }).then((response) => {
                console.log("response", response);
                return response;
            });
        },
        { form, formatResult: formatListResult }
    );
    const { submit, reset } = search;

    const onCheck = async (item: any) => {
        try {
            const payload = { ask_question_id: item.id };
            askQuestionCheck(payload).then((response) => {
                console.log("response", response);
                message.success("审核成功");
                submit();
            });
        } catch (error) {
            message.info(error.message);
        }
    };

    const onModalSubmit = () => {
        console.log("onModalSubmit");
        const payload = {
            ask_question_id: currentQuestion?.question?.id,
            reply: value,
        };
        askQuestionReply(payload)
            .then((response) => {
                console.log("askQuestionReply", askQuestionReply);
                message.success("回复成功！");
                toggleModal(false);
                setValue("");
                submit();
            })
            .catch(() => {
                message.info("回复失败");
            });
    };

    const columns = [
        {
            title: "问答内容",
            width: 600,
            dataIndex: "ask_question",
        },
        {
            title: "发布时间",
            dataIndex: "create_time",
        },
        {
            title: "发布人",
            dataIndex: "name",
        },
        {
            title: "编辑",
            render: (key: any) => {
                // "is_reply": 1 //1未回复2已恢复
                // "check_type": 1, // 1未审核2已审核通过3未审核通过
                const { check_type, is_reply } = key;
                return (
                    <div>
                        <Button
                            type={"link"}
                            style={{ paddingLeft: 0 }}
                            onClick={async () => {
                                toggleModal(true);
                                askQuestionReplyList({
                                    ask_question_id: key.id,
                                }).then((response) => {
                                    console.log("response", response);
                                    setCurrentQuestion(response);
                                });
                            }}
                        >
                            {is_reply === 1 ? "待回复" : "继续回复"}
                        </Button>

                        {`  `}
                        <Button
                            onClick={() => onCheck(key)}
                            type={"link"}
                            style={{ paddingLeft: 0 }}
                        >
                            {check_type === 1
                                ? "未审核"
                                : check_type == 2
                                ? "已审核"
                                : "未通过"}
                        </Button>
                    </div>
                );
            },
        },
    ];
    return (
        <div>
            <Form form={form}>
                <FlexJustifyBetween
                    style={{ marginBottom: 12, alignItems: "center" }}
                >
                    <Flex style={{ alignItems: "center" }}>
                        <Switch
                            checked={tableEnable}
                            onChange={changeShowSet}
                        />
                        <Air horizontal={3} />
                        <div>开启问答</div>
                        <Air horizontal={5} />

                        <Flex style={{ marginLeft: 12 }}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                name="search"
                            >
                                <Search
                                    enterButton
                                    placeholder="请输入问答内容"
                                    onSearch={submit}
                                />
                            </Form.Item>
                        </Flex>
                    </Flex>
                    <Flex>
                        <Button
                            onClick={exportList}
                            type={"primary"}
                            disabled={!tableEnable}
                        >
                            导出
                        </Button>
                    </Flex>
                </FlexJustifyBetween>
            </Form>

            <Spin
                indicator={<div />}
                tip="尚未开启问答"
                spinning={!tableEnable}
            >
                <Table rowKey="id" columns={columns} {...tableProps} />
            </Spin>

            <Modal
                title="回复明细"
                visible={modalVisible}
                okText="提交"
                cancelText="取消"
                onCancel={() => toggleModal(false)}
                onOk={onModalSubmit}
            >
                {currentQuestion && currentQuestion.question && (
                    <div
                        style={{ marginBottom: 20, fontWeight: "bold" }}
                    >{`${currentQuestion.question.name}：${currentQuestion.question.question_content}`}</div>
                )}
                {currentQuestion &&
                    currentQuestion.reply &&
                    currentQuestion.reply.length > 0 && (
                        <div
                            style={{
                                maxHeight: "200px",
                                overflow: "auto",
                                width: "100%",
                            }}
                        >
                            {currentQuestion.reply.map((item: any) => {
                                return (
                                    <div
                                        style={{
                                            marginBottom: 6,
                                            position: "relative",
                                            paddingRight: 150,
                                        }}
                                        key={item.id}
                                    >
                                        <span
                                            style={{
                                                color: "#999",
                                            }}
                                        >
                                            {item.type === 2
                                                ? "回复："
                                                : `${item.name}：`}
                                        </span>
                                        <span>{item.reply_content}</span>
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                right: 0,
                                                color: "#999",
                                                transform: "translateY(-50%)",
                                            }}
                                        >
                                            {item.create_time
                                                ? item.create_time
                                                : moment().format(
                                                      "YYYY-MM-DD HH:mm:ss"
                                                  )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                <div>
                    <TextArea
                        placeholder="请在此处进行回复"
                        rows={6}
                        value={value}
                        maxLength={100}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </div>
            </Modal>
        </div>
    );
}

export { Answer };
