import { action, observable } from "mobx";
import { ILiveShow } from "../../../../../common/apis/apis/live/liveShow";
import moment from "moment"
import { common } from "../../../../../common/common";

class LiveStore {
    @observable
    liveInfo: ILiveShow = {
        "title": "",   //标题
        "live_time": moment(moment.min(), common.formatString).format(common.formatString), //直播时间
        "live_status": "", //直播状态
        "astrict": "", //观看限制
        "apply_form": "", //报名表单
        "poster": "", //海报地址
        "show_address": "", //前端连接地址
        "qrcode": "",//二维码地址
        "concurrenceNum": 0, //并发数
        "heat_value": 0, //热力值
        "background_img": '', //并发数
        "leijiLive": 0, //累计观看直播数量
        "leijiPlayback": 0, //累计观看回放数量
        "audienceVisit": 0, //累计观众人数 访问总数
        "audienceLive": 0,//累计观众人数 直播
        "audiencePlayback": 0,//累计观众人数 回放
        "synopsis": "",
        "push_info": [],
        "look_address": "",
        "pc_address": "",
        "is_heat": false, // 是否显示观看次数
        "is_show_count": false, // 是否显示观看次数
        "is_show_online": false, // 是否显示在线人数
        "give_like": false, // 是否显示在线人数
    };

    @observable
    live_id: string = ""

    @observable
    role: number = 0

    reload = () => { }

    @action.bound
    public setLiveInfo(ref: ILiveShow): void {
        this.liveInfo = ref;
    }

    @action.bound
    public setReload(fun: () => void): void {
        this.reload = fun
    }

    @action.bound
    public setLiveId(live_id: string): void {
        this.live_id = live_id
    }
    @action.bound
    public setRole(role: number): void {
        this.role = role
    }
}

export const liveStore = new LiveStore();
