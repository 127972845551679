/**
 * 广告 - 路由
 * Create by gaohan on 2020-08-20
 */
import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import {
  SurfaceRouter,
  tSurfaceRouteItem,
} from "../../../../../../components/surface-router/surface-router";
import { Message } from "./index";
import Edit from './edit'

interface IProps extends RouteComponentProps { }

interface IState { }

const routerData: tSurfaceRouteItem = {
  breadTitle: "直播信息",
  routeName: "",
  component: Message,
  children: [
    {
      breadTitle: "编辑",
      routeName: "edit/edit/:editId",
      component: Edit,
      children: []
    },
    {
      breadTitle: "新增",
      routeName: "edit/new",
      component: Edit,
      children: []
    }
  ],
};

export class MessageRouter extends Component<IProps, IState> {
  readonly state: IState = {};
  render() {
    let { } = this.props;
    let { } = this.state;
    return <SurfaceRouter {...this.props} entryRoute={routerData} />;
  }
}
