// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".overview_ViewBox__3SDEi{width:calc(100%);margin:0 auto}.overview_ViewTitle__2a2ja{width:100%;height:76px}.overview_ViewTitle__2a2ja span{font-size:21px;font-weight:400;color:#000;margin-left:0px}.overview_container__W-FE0{margin-bottom:30px}.overview_container__W-FE0 .overview_primary__hv00c{background-color:#ffffff}.overview_container__W-FE0 .overview_primary__hv00c .overview_form__1zP-j{display:flex}.overview_container__W-FE0 .overview_primary__hv00c .overview_form__1zP-j .overview_description__1b5rh{flex:1 1}.overview_sizeSelect__3jzgl{display:flex}.overview_img__1X8eC{max-width:22vw}.overview_meetingShare__1VU-0{margin-top:30px}.overview_meetingShare__1VU-0 .overview_card__2DRS1{display:flex;flex-direction:column;align-items:center}.overview_meetingShare__1VU-0 .overview_card__2DRS1 .overview_imgBtns__1cYYc{display:flex;margin-top:15px}.overview_meetingShare__1VU-0 .overview_card__2DRS1 .overview_imgBtns__1cYYc .overview_copeBtn__1GkEE{margin-left:10px}.overview_meetingData__30Y3C{display:flex}.overview_meetingData__30Y3C>*{margin-right:20px}\n", ""]);
// Exports
exports.locals = {
	"ViewBox": "overview_ViewBox__3SDEi",
	"ViewTitle": "overview_ViewTitle__2a2ja",
	"container": "overview_container__W-FE0",
	"primary": "overview_primary__hv00c",
	"form": "overview_form__1zP-j",
	"description": "overview_description__1b5rh",
	"sizeSelect": "overview_sizeSelect__3jzgl",
	"img": "overview_img__1X8eC",
	"meetingShare": "overview_meetingShare__1VU-0",
	"card": "overview_card__2DRS1",
	"imgBtns": "overview_imgBtns__1cYYc",
	"copeBtn": "overview_copeBtn__1GkEE",
	"meetingData": "overview_meetingData__30Y3C"
};
module.exports = exports;
