import React, { Component } from 'react'
import BraftEditor from 'braft-editor'
import style from './RichTextBox.scss'
import {api} from 'common/app'

import HellView from 'components/AlertBox/BaseAlert'

export class RichTextBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StringContent:null,
      editorState:null,
    };
    this.refreshProps = this.refreshProps.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }
  componentWillReceiveProps(nextprops) {
    this.refreshProps(nextprops);
  }
  componentDidMount() {
    this.refreshProps(this.props);
  }
  refreshProps(props) {
      let content = props.content;
      if (content !== this.state.StringContent) {
        this.setState({
          StringContent:content,
          editorState:BraftEditor.createEditorState(content)
        })
      }
  }
  handleEditorChange(editorState){
    this.setState({
      editorState:editorState
    });
    this.props.onContentChange(editorState.toHTML());
  }

  setContent(content){
    this.setState({editorState:BraftEditor.createEditorState(content)})
  }


  uploadFile(param) {
    let formdata = new FormData();
    formdata.append('file', param.file);
    formdata.append('type', 'img');
    api.UploadFile(formdata, (progressEvent) => {
      let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
      param.progress(complete);
    }).then(res => {
      if (res.code === 200) {
        param.success({
          url: res.result,
          meta: {
            // id: res.title,
            // title: res.title,
            // alt: res.title,
          }
        });
      } else {
        param.error({
          msg: res.message
        });
        HellView.error({
          message: res.message
        });
      }
    }, err => {
      console.log(err);
    })
  }

  render() {
    return (
        <div className={style.RichTextBox}>
          <BraftEditor
              value={this.state.editorState}
              onChange={this.handleEditorChange}
              placeholder='点击此处编辑项目介绍内容'
              media={{
                uploadFn: this.uploadFile
              }}
          />
        </div>
    )
  }

}
export default RichTextBox
