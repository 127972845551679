/**
 * 关联直播
 * Create by liaokai on 2020-06-15 10:19:53
 */
import * as React from 'react';
import {Component} from "react";
import {questionIndex} from "../../../../../../common/apis/apis/live/questionIndex";
import {Button, message, Modal, Spin, Switch, Table} from "antd";
import {common} from "../../../../../../common/common";
import {liveStore} from "../live.store";
import {FlexJustifyBetween} from "../../../../../../components/style/flex/flex-justify-between";
import {Flex} from "../../../../../../components/style/flex/flex";
import {Air} from "../../../../../../components/air/Air";
import {LiveSelectRelations} from "./component/select-relations";
import {bindLiveIndex, IBindLiveIndexItem} from "../../../../../../common/apis/apis/live/bindLiveIndex";
import {cancelBindLive} from "../../../../../../common/apis/apis/live/cancelBindLive";
import {bindLive} from "../../../../../../common/apis/apis/live/bindLive";
import {liveIconSet} from "../../../../../../common/apis/apis/live/liveIconSet";
import {liveShowStatusSet} from "../../../../../../common/apis/apis/live/liveShowStatusSet";
import {liveShowStatus} from "../../../../../../common/apis/apis/live/liveShowStatus";
import {observer} from "mobx-react";
import {enableShowSet} from "../../../../../../common/apis/apis/live/enableShowSet";
import {enableShowInfo} from "../../../../../../common/apis/apis/live/enableShowInfo";

interface IProps{

}

interface IState{
    columns : any[]
    dataSource : IBindLiveIndexItem[],
    currentPage : number,
    total : number,
    pageSize: number,
    loading : boolean,
    showAddModal: boolean,
    isEnableLiveReleation: boolean
}

@observer
export class LiveRelationsView extends Component<IProps,IState>{
    readonly state : IState = {
        columns : [
            {
                title: '封面',
                dataIndex: 'poster',
                key: 'poster',
                render: (text : string) => <img src={text} style={{width : 120}} alt=""/>,
            },
            {
                title: '直播标题',
                dataIndex: 'title',
                key: 'title',
                render: (text: string) => text,
            },
            {
                title: '直播时间',
                dataIndex: 'live_time',
                key: 'live_time',
                render: (text: string) => text,
            },
            {
                title: '操作',
                dataIndex: 'live_id',
                key: 'live_id',
                render: (text: string, record : IBindLiveIndexItem, index : number) => <div>
                    <Button style={{paddingLeft : 0}} type={"link"} onClick={()=>{
                        cancelBindLive({
                            current_live_id : liveStore.live_id,
                            cancel_bind_live_id : text
                        }).then(res => {
                            this.refreshPage();
                        }).catch(e => {
                            message.error("解除绑定失败 "+ e.data?.message)
                        })
                    }}>取消关联</Button>
                </div>,
            }
        ],
        dataSource : [],
        pageSize : common.defaultPaginate,
        total : 0,
        currentPage : 1,
        loading : false,
        showAddModal: false,
        isEnableLiveReleation: false
    };

    refreshPage = ()=>{
        this.reqTableData(this.state.currentPage)
    }

    reqTableData(page : number){
        this.setState({loading : true})
        bindLiveIndex({
            live_id : liveStore.live_id,
            page,
            paginate : this.state.pageSize
        }).then(res => {
            this.setState({
                dataSource : res.data,
                total : res.total,
                currentPage : page
            })
        }).finally(()=>{
            this.setState({loading : false})
        })
    }

    componentDidMount(): void {
        this.reqTableData(1)
        // liveShowStatus({
        //     live_id : liveStore.live_id
        // }).then(res => {
        //     this.setState({
        //         isEnableLiveReleation: res.status === 1
        //     });
        // })
        enableShowInfo({
            live_id : liveStore.live_id,
            type:8
        }).then(res => {
            this.setState({
                isEnableLiveReleation: res.is_show
            });
        })
    }

    render() {
        let {} = this.props;
        let { columns, dataSource, pageSize, total, currentPage, loading, showAddModal, isEnableLiveReleation } = this.state;
        return (
            <div>
                <FlexJustifyBetween>
                    <Flex>
                        <Switch checked={isEnableLiveReleation} onChange={v => {
                            // this.setState({
                            //     isEnableLiveReleation: v
                            // });
                            enableShowSet({
                                live_id : liveStore.live_id,
                                type : 8
                            }).then(res=>{
                                this.setState({
                                    isEnableLiveReleation:res.is_show
                                })
                            }).catch(e => {
                                message.error("设置失败 " + e.data?.message);
                            })
                        }}/>
                        <Air horizontal={3}/>
                        <div>开启关联直播</div>
                    </Flex>
                    <Flex>
                        <Button disabled={!isEnableLiveReleation} type={"primary"} onClick={()=>{
                            this.setState({showAddModal : true})
                        }}>新增关联</Button>
                    </Flex>
                </FlexJustifyBetween>
                <Air vertical={5}/>
                <Spin indicator={<div/>} tip="尚未开启关联直播" spinning={!isEnableLiveReleation}>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={dataSource}
                        // emptyText={"暂无内容"}
                        pagination={{
                            defaultCurrent : 1,
                            current : currentPage,
                            pageSize : pageSize,
                            total : total,
                            onChange : page => {
                                this.reqTableData(page);
                                this.setState({currentPage : page})
                            }
                        }}
                    />
                </Spin>
                <LiveSelectRelations visible={showAddModal} onCancel={()=>{
                    this.setState({showAddModal : false})
                }} onSelect={item => {
                    bindLive({
                        current_live_id : liveStore.live_id,
                        bind_live_id : [item.live_id.toString()]
                    }).then(res => {
                        this.refreshPage()
                    }).catch(e => {
                        message.error("绑定失败 "+ e.data?.message)
                    })
                }}/>
            </div>
        );
    }
}
