/**
 * 支付方式-单项
 * Create by liaokai on 2020-05-08 16:12:44
 */
import * as React from 'react';
import {Component} from "react";
import {WechatFilled} from "@ant-design/icons/lib";
import {Air} from "../../../../../components/air/Air";

interface IProps{
    icon? : JSX.Element
    text? : string,
}

interface IState{

}

export class PayMethodItem extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {icon, text} = this.props;
        let {} = this.state;
        return (
            <div style={{display: "flex", alignItems: "center", cursor : "pointer"}}>
                {icon}
                {/*<WechatFilled style={{color: "#00b546", fontSize: 38}} />*/}
                <Air horizontal={5}/>
                {text ?? ""}
            </div>
        );
    }
}
