/**
 * 互动问答类型列表
 * Create by liaokai on 2020-05-28 10:12:59
 */
import {toPost} from "../../http-utils";

export interface IQuestionIndexItem {
    "title": string,
    "update_time": string
    id : string,
    is_publish : boolean
    publish_type : boolean  //是否置灰
    is_edit : boolean   //是否可编辑
}

export const questionIndex = toPost<
    {
        live_id : string,
        page : number,
        paginate? : number
    },
    {
        "current_page": number,
        "total": number,
        "last_page": number,
        "data": IQuestionIndexItem[]
    }
>("/merchant/live/questionIndex");

export const questionShow = toPost<{id: string}, {question: any, option: any[]}>(`/merchant/live/questionShow`);