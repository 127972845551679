import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Select, Button, Modal, message } from "antd";
import { useBoolean } from "ahooks";
import invariant from "invariant";
import { FetchField, VenueCate, LiveItem } from "../types";
import { liveStore } from "../../live.store";
import {
    liveVenueCateIndex,
    liveVenueLiveList,
    liveVenueStore,
    liveVenueShow,
    liveVenueUpdate,
} from "../constants";

const { Option } = Select;

interface State {}

function Detail(props: any) {
    const [form] = Form.useForm();
    const [value, setValue] = useState("");
    const history = useHistory();
    const [selectCate, setSelectCate] = useState([] as VenueCate[]);
    const [liveList, setLiveList] = useState([] as LiveItem[]);
    const [selectedLive, setSelectedLive] = useState({} as LiveItem);
    const [currentVenue, setCurrentVenue] = useState({} as any);

    useEffect(() => {
        liveVenueCateIndex({
            live_id: liveStore.live_id,
        }).then((response) => {
            console.log("response", response);
            setSelectCate(response);
        });

        liveVenueLiveList({}).then((liveResponse) => {
            console.log("liveResponse", liveResponse);
            setLiveList(liveResponse);
        });
    }, []);

    useEffect(() => {
        if (!!props.match.params.id) {
            liveVenueShow({ live_venue_id: props.match.params.id }).then(
                (response) => {
                    console.log("liveVenueShow", response);
                    form.setFieldsValue({
                        title: response.title,
                        sort: response.sort,
                        poster: response.poster,
                        live_venue_cate_id: response.live_venue_cate_id,
                        select_live_id: response.live_id,
                    });
                    const currentList = liveList.find(
                        (l) => l.id === response.live_id
                    );
                    setSelectedLive(currentList as any);
                    setCurrentVenue(response);
                }
            );
        }
    }, [liveList]);

    const onFinish = (values: any) => {
        const text = !!currentVenue.id ? "修改" : "新增";
        const fetchUrl: any = !!currentVenue.id
            ? liveVenueUpdate
            : liveVenueStore;
        const payload: any = !!currentVenue.id
            ? {
                  id: currentVenue.id,
                  live_venue_cate_id: values.live_venue_cate_id,
                  select_live_id: values.select_live_id,
                  title: values.title,
                  sort: values.sort,
              }
            : {
                  live_venue_cate_id: values.live_venue_cate_id,
                  select_live_id: values.select_live_id,
                  title: values.title,
                  sort: values.sort,
              };

        fetchUrl(payload)
            .then((response: any) => {
                message.success(`${text}成功`);
                history.goBack();
            }).catch((e: any) => {
                message.error(`${text}失败 `+e.data.message);
            });
    };

    const isEdit = !!props.match.params.id;
    return (
        <div>
            <Form
                form={form}
                onFinish={onFinish}
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 16 }}
            >
                <Form.Item
                    label="直播标题"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "请输入直播标题!",
                        },
                    ]}
                >
                    <Input
                        style={{ width: 400 }}
                        onChange={(e) => setValue(e.target.value)}
                        maxLength={8}
                        suffix={
                            <div
                                style={{ color: "#dddddd" }}
                            >{`(${value.length}/8)`}</div>
                        }
                    />
                </Form.Item>

                <Form.Item
                    label="分类"
                    name="live_venue_cate_id"
                    rules={[
                        {
                            required: true,
                            message: "请选择分类!",
                        },
                    ]}
                >
                    <Select
                        placeholder="请选择分类"
                        style={{ width: 400 }}
                        // disabled={isEdit}
                        onChange={(value) => {
                            console.log("value", value);
                            // setAdvertisementType(Number(value));
                        }}
                    >
                        {selectCate &&
                            selectCate.length > 0 &&
                            selectCate.map((item) => {
                                return (
                                    <Option value={item.id}>
                                        {item.title}
                                    </Option>
                                );
                            })}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="排序"
                    name="sort"
                    rules={[
                        {
                            required: true,
                            message: "请输入排序!",
                        },
                    ]}
                >
                    <Input style={{ width: 400 }} />
                </Form.Item>

                <Form.Item
                    label="直播"
                    name="select_live_id"
                    rules={[
                        {
                            required: true,
                            message: "请选择直播!",
                        },
                    ]}
                >
                    <Select
                        placeholder="请选择直播"
                        style={{ width: 400 }}
                        // disabled={isEdit}
                        onChange={(value) => {
                            console.log("value", value);
                            const currentLive = liveList.find(
                                (l) => l.id === value
                            );
                            setSelectedLive(currentLive as any);
                        }}
                    >
                        {liveList &&
                            liveList.length > 0 &&
                            liveList.map((item) => {
                                return (
                                    <Option value={item.id}>
                                        {item.title}
                                    </Option>
                                );
                            })}
                    </Select>
                </Form.Item>

                <Form.Item label="封面图预览" name="poster">
                    {!!selectedLive && selectedLive.poster ? (
                        <div
                            style={{
                                backgroundImage: `url(${selectedLive.poster})`,
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                width: 256,
                                height: 143,
                                borderRadius: 5,
                                border: "1px solid #e3e3e3",
                            }}
                        />
                    ) : (
                        <div
                            style={{
                                border: "1px solid #e3e3e3",
                                width: 256,
                                height: 143,
                                borderRadius: 5,
                            }}
                        />
                    )}
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 3 }}>
                    <Button type="primary" htmlType="submit">
                        保存
                    </Button>
                    <Button
                        onClick={() => history.goBack()}
                        style={{ marginLeft: 24 }}
                    >
                        取消
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
export { Detail };
