/**
 * 提交详情
 * Create by liaokai on 2020-06-17 16:13:54
 */
import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Texts } from "../../../../../../../components/text/Texts";
import { Air } from "../../../../../../../components/air/Air";
import { TitleContentTable } from "../../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import { Affix, Button, Card, Input, message, Radio, Space } from "antd";
import { FlexJustifyBetween } from "../../../../../../../components/style/flex/flex-justify-between";
import { PlusOutlined } from "@ant-design/icons/lib";
import { Flex } from "../../../../../../../components/style/flex/flex";
import { FlexJustifyCenter } from "../../../../../../../components/style/flex/flex-justify-center";
import { liveSurveyQuestionnaireUpdate } from "../../../../../../../common/apis/apis/live/liveSurveyQuestionnaireUpdate";
import { liveStore } from "../../live.store";
import { liveSurveyQuestionnaireStore } from "../../../../../../../common/apis/apis/live/liveSurveyQuestionnaireStore";
import {
    IliveSurveyQuestionnaireByUserAnswerInfo,
    liveSurveyQuestionnaireByUserAnswerInfo,
} from "../../../../../../../common/apis/apis/live/liveSurveyQuestionnaireByUserAnswerInfo";

interface IProps
    extends RouteComponentProps<{
        live_id: string;
        question_id: string;
        user_id: string;
    }> {}

interface IState {
    data: {
        id: number;
        title: string;
        username: string;
        commit_time: string;
        content: {
            type: string;
            title: string;
            content: {
                isAnswer: boolean;
                isChecked: boolean;
                title: string;
                value: string;
            }[];
        }[];
    };
}

export class LiveQuestionCommitDetail extends Component<IProps, IState> {
    readonly state: IState = {
        data: {
            id: 0,
            title: "",
            username: "-",
            commit_time: "-",
            content: [],
        },
    };

    componentDidMount() {
        liveSurveyQuestionnaireByUserAnswerInfo({
            id: this.props.match.params.user_id,
        }).then((res: any) => {
            console.log("res", res);
            this.setState({ data: res });
        });
    }

    render() {
        let {} = this.props;
        let {
            data: { id, username, commit_time, content, title },
        } = this.state;
        console.log("content", content);
        return (
            <div>
                <Texts.ChapterTitle>问卷信息</Texts.ChapterTitle>
                <Air vertical={5} />
                <TitleContentTable colGap={10} rowGap={10}>
                    <div>问卷名称</div>
                    <div>{title}</div>
                </TitleContentTable>
                <Air vertical={15} />
                <FlexJustifyBetween>
                    <Texts.ChapterTitle>问卷题目</Texts.ChapterTitle>
                    <div></div>
                </FlexJustifyBetween>
                <Air vertical={5} />
                <div>
                    {content.map((item, index) => {
                        return (
                            <>
                                <Card title={item.title}>
                                    <TitleContentTable rowGap={10} colGap={20}>
                                        <Texts.TableTitle>
                                            类型:
                                        </Texts.TableTitle>
                                        <div>
                                            {item.type === "single"
                                                ? "单选"
                                                : item.type === "multi"
                                                ? "多选"
                                                : "问答"}
                                        </div>
                                        {item.type !== "qa" ? (
                                            <>
                                                <Texts.TableTitle
                                                    style={{
                                                        alignSelf: "flex-start",
                                                    }}
                                                >
                                                    选项:
                                                </Texts.TableTitle>
                                                <div>
                                                    {item.content.map(
                                                        (value1) => {
                                                            return (
                                                                <Flex>
                                                                    <Radio
                                                                        checked={value1.isChecked}
                                                                    />
                                                                    <div>{value1.title}</div>
                                                                </Flex>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <Texts.TableTitle
                                                    style={{
                                                        alignSelf: "flex-start",
                                                    }}
                                                >
                                                    回答
                                                </Texts.TableTitle>
                                                <div>
                                                    {item.content.map(
                                                        (value1) => {
                                                            return (
                                                                <Flex>
                                                                    <div>
                                                                        {value1}
                                                                    </div>
                                                                </Flex>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </TitleContentTable>
                                </Card>
                                <Air vertical={5} />
                            </>
                        );
                    })}
                </div>
            </div>
        );
    }
}
