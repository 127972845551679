
import React from 'react';
import { Modal, Form, Row, Col, Input } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { placeHolders } from '../tab'
import { common } from '../../../../../../../../common/common';

export default (props: any) => {
  const { form, name, onOk, visible, onCancel } = props;
  return (
    <Modal width={600} onOk={onOk} title='设置人群及收费金额' visible={visible} onCancel={onCancel} okText='确定' cancelText='取消' >
      <Form form={form} name={name} initialValues={{ items: [{ people_type: '', fee: '' }] }} >
        <Form.List name='items'>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => {
                  return (
                    <Row gutter={24} key={index} >
                      <Col span={10}>
                        <Form.Item name={[field.name, 'people_type']} label='设置人群' rules={[{ required: true, message: '请设置人群' }]}>
                          <Input placeholder={placeHolders.people} maxLength={10} />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item name={[field.name, 'fee']} label='收费金额' rules={[{ required: true, message: '请输入收费金额' }]}>
                          <Input placeholder={placeHolders.money} suffix='元' />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <PlusCircleOutlined
                          onClick={add}
                          style={{ marginTop: 6, fontSize: 16, color: common.color.primary }}
                        />
                        {index !== 0 && <MinusCircleOutlined onClick={() => remove(index)} style={{ marginTop: 6, marginLeft: 12, fontSize: 16, color: common.color.primary }} />}
                      </Col>
                    </Row>
                  )
                })}
              </>
            )
          }}
        </Form.List>
        {name === 'modalForm2' && (
          <Form.Item name='index' style={{ margin: 0 }}>
            <div />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}