/**
 * 直播-聊天-历史列表
 * Create by liaokai on 2020-05-25 17:15:22
 */
import * as React from "react";
import { Component } from "react";
import { Button, message, Table, Switch, Spin, Modal } from "antd";
import { apis } from "../../../../../../../common/apis/apis";
import { IChargeManagement } from "../../../../../../../common/apis/apis/chargeManagement";
import { FlexJustifyEnd } from "../../../../../../../components/style/flex/flex-justify-end";
import { Input, DatePicker } from "antd";
import locale from "antd/lib/date-picker/locale/zh_CN";
import { Flex } from "../../../../../../../components/style/flex/flex";
import { Air } from "../../../../../../../components/air/Air";
import { Link } from "react-router-dom";
import { FlexJustifyBetween } from "../../../../../../../components/style/flex/flex-justify-between";
import {
    IMessageIndexItem,
    messageIndex,
} from "../../../../../../../common/apis/apis/live/messageIndex";
import { enableShowSet } from "../../../../../../../common/apis/apis/live/enableShowSet";
import { enableShowInfo } from "../../../../../../../common/apis/apis/live/enableShowInfo";
import { liveStore } from "../../live.store";
import { common } from "../../../../../../../common/common";
import { messageDestroy } from "../../../../../../../common/apis/apis/live/messageDestroy";
import { DestroyModul } from "../../../../../../../components/destroy-modal";
import { messageStick } from "../../../../../../../common/apis/apis/live/messageStick";
import { exportUserListCreditUrl } from "../../../../../../../common/apis/apis/live/exportUserListCreditUrl";
import { exportMessageExportIndexUrl } from "../../../../../../../common/apis/apis/live/exportMessageExportIndexUrl";
import copy from "copy-to-clipboard";
import { devDomain, prodDomain } from "../../../../../../../common/apis/http";

const { Search } = Input;
const { RangePicker } = DatePicker;

interface IProps {}

interface IState {
    columns: any[];
    dataSource: IMessageIndexItem[];
    currentPage: number;
    total: number;
    pageSize: number;
    loading: boolean;
    destroyShow: boolean;
    stickShow: boolean;
    searchText: string;
    isEnableChat: boolean;
    dateStart: string;
    dateEnd: string;
    currentItem?: IMessageIndexItem;
    liveAddress: string;
    messageType: boolean;
    messageVisible: boolean;
}
const exportChat = async () => {
    exportMessageExportIndexUrl({
        live_id: Number(liveStore.live_id),
    }).then((response) => {
        const href = response.url;
        window.open(href, "_blank");
        message.success("导出成功");
    });
};

export class LiveChatList extends Component<IProps, IState> {
    readonly state: IState = {
        columns: [
            {
                title: "聊天内容",
                dataIndex: "content",
                width: 600,
                key: "content",
                render: (text: boolean) => text,
            },
            {
                title: "发布时间",
                dataIndex: "create_time",
                key: "create_time",
                render: (text: string) => text,
            },
            {
                title: "发布人",
                dataIndex: "name",
                key: "name",
                render: (text: string) => text,
            },
            {
                title: "状态",
                dataIndex: "stick_type",
                key: "stick_type",
                render: (stick_type: number) =>
                    stick_type === 2 ? "已置顶" : "未置顶",
            },
            {
                title: "操作",
                dataIndex: "id",
                key: "id",
                render: (text: string, record: IMessageIndexItem) => (
                    <div>
                        <Button
                            type={"link"}
                            style={{ paddingLeft: 0 }}
                            onClick={() => {
                                // message.info("API对接中")

                                this.setState({
                                    destroyShow: true,
                                    currentItem: record,
                                });
                            }}
                        >
                            删除
                        </Button>
                        <Button
                            type={"link"}
                            style={{ paddingLeft: 0 }}
                            onClick={() => {
                                this.setState({
                                    stickShow: true,
                                    currentItem: record,
                                });
                            }}
                        >
                            {record.stick_type === 1 ? "设为置顶" : "取消置顶"}
                        </Button>
                    </div>
                ),
            },
        ],
        dataSource: [],
        pageSize: 15,
        total: 0,
        currentPage: 1,
        loading: false,
        searchText: "",
        isEnableChat: false,
        dateStart: "",
        dateEnd: "",
        destroyShow: false,
        stickShow: false,
        // liveAddress:  process.env.REACT_APP_ENV === "production" ? prodDomain+"/api/api/liveMessageAddress?live_id="+liveStore.live_id : devDomain+"/api/api/liveMessageAddress?live_id="+liveStore.live_id,
        // https://huibaifen.rup-china.com/html/#/index/live_list/live/85/menu/chat
        liveAddress:
            process.env.REACT_APP_ENV === "production"
                ? prodDomain +
                  `/html/#/index/live_list/live/${liveStore.live_id}/menu/chat/bullet`
                : devDomain +
                  `/html/#/index/live_list/live/${liveStore.live_id}/menu/chat/bullet`,
        messageType: true,
        messageVisible: false,
    };

    reqTableData(page: number = 1) {
        this.setState({ loading: true });
        const { pageSize, searchText, dateStart, dateEnd } = this.state;
        messageIndex({
            live_id: liveStore.live_id,
            page,
            paginate: pageSize,
            content: searchText,
            dateStart,
            dateEnd,
        })
            .then((res) => {
                this.setState({
                    dataSource: res.data,
                    currentPage: page,
                    total: res.total,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                    // liveAddress: process.env.REACT_APP_ENV === "production" ? prodDomain+"/api/api/liveLookAddress?live_id="+liveStore.live_id : devDomain+"/api/api/liveLookAddress?live_id="+liveStore.live_id,
                });
            });
    }

    componentDidMount(): void {
        this.reqTableData(1);
        this.setState({ loading: true });
        enableShowInfo({
            live_id: liveStore.live_id,
            type: 4,
        })
            .then((res) => {
                const { is_show } = res;
                this.setState({
                    isEnableChat: is_show,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    showMessage() {
        console.log("showMessage");
        this.toggleVisible(true);
    }

    toggleVisible = (visible?: boolean) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                messageVisible:
                    typeof visible === "boolean"
                        ? visible
                        : !prevState.messageVisible,
            };
        });
    };

    render() {
        let {} = this.props;
        let {
            columns,
            currentItem,
            dataSource,
            pageSize,
            total,
            currentPage,
            destroyShow,
            stickShow,
            loading,
            isEnableChat,
            dateStart,
            dateEnd,
            messageType,
            liveAddress,
        } = this.state;

        const copySuffix =
            messageType === true ? "/chat/bullet" : "/chat/bullet-list";
        const copyValue =
            process.env.REACT_APP_ENV === "production"
                ? prodDomain +
                  `/html/#/index/live_list/live/${liveStore.live_id}/menu${copySuffix}`
                : devDomain +
                    `/html/#/index/live_list/live/${liveStore.live_id}/menu${copySuffix}`;
        console.log("copyValue", copyValue);
        return (
            <div>
                <FlexJustifyBetween style={{ minWidth: 1000 }}>
                    <Flex>
                        <Flex style={{ alignItems: "center" }}>
                            <Switch
                                checked={isEnableChat}
                                onChange={(value) => {
                                    this.setState({
                                        isEnableChat: value,
                                    });
                                    this.setState({ loading: true });
                                    enableShowSet({
                                        live_id: liveStore.live_id,
                                        type: 4,
                                    })
                                        .then((res) => {
                                            if (!value) {
                                                message.success("已关闭");
                                            } else {
                                                message.success("已开启");
                                            }
                                        })
                                        .finally(() => {
                                            this.setState({ loading: false });
                                        });
                                }}
                            />
                            <Air horizontal={5} />
                            <span>启用聊天</span>
                        </Flex>
                        <Flex>
                            <Air horizontal={15} />
                            <RangePicker
                                locale={locale}
                                style={{ width: 150 }}
                                disabled={!isEnableChat}
                                onChange={(date, dateString) => {
                                    const [dateStart, dateEnd] = dateString;
                                    this.setState({
                                        dateStart,
                                        dateEnd,
                                    });
                                }}
                            />
                            <Air horizontal={5} />
                            <Search
                                placeholder="请输入聊天内容"
                                style={{ width: "150px" }}
                                disabled={!isEnableChat}
                                onChange={(event) => {
                                    this.setState({
                                        searchText: event.target.value,
                                    });
                                }}
                                onSearch={(value) => {
                                    this.reqTableData(1);
                                }}
                                enterButton
                            />
                        </Flex>
                    </Flex>

                    <Flex>
                        <Flex style={{ alignItems: "center" }}>
                            <Input
                                style={{ width: 150 }}
                                value={copyValue}
                                suffix={
                                    <a
                                        onClick={() => {
                                            const result = copy(copyValue);
                                            if (result) {
                                                message.success("复制成功");
                                            } else {
                                                message.warn("复制失败");
                                            }
                                        }}
                                    >
                                        复制链接
                                    </a>
                                }
                            />
                        </Flex>
                        <Flex style={{ alignItems: "center" }}>
                            <Air horizontal={5} />
                            <Switch
                                checked={messageType}
                                onChange={(value) => {
                                    this.setState({
                                        messageType: value,
                                    });
                                }}
                            />
                            <Air horizontal={5} />
                            <span>
                                <Link
                                    to={
                                        messageType === true
                                            ? `chat/bullet`
                                            : "chat/bullet-list"
                                    }
                                >
                                    {messageType === true ? "弹幕型" : "列表"}
                                </Link>
                            </span>
                        </Flex>
                    </Flex>

                    <Flex>
                        <Button type={"primary"} disabled={!isEnableChat}>
                            <Link to={"chat/keyword_filter"}>关键词过滤</Link>
                        </Button>
                        <Air horizontal={5} />
                        <Button type={"primary"} disabled={!isEnableChat}>
                            <Link to={"chat/filter"}>手动过滤</Link>
                        </Button>
                        <Air horizontal={5} />
                        <Button
                            type={"primary"}
                            disabled={!isEnableChat}
                            onClick={exportChat}
                        >
                            导出
                        </Button>
                    </Flex>
                </FlexJustifyBetween>
                <Air vertical={5} />
                <Spin
                    indicator={<div />}
                    tip="尚未开启聊天"
                    spinning={!isEnableChat}
                >
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={dataSource}
                        // emptyText={"暂无内容"}
                        pagination={{
                            defaultCurrent: 1,
                            current: currentPage,
                            pageSize: pageSize,
                            total: total,
                            onChange: (page) => {
                                this.reqTableData(page);
                                this.setState({ currentPage: page });
                            },
                        }}
                    />
                </Spin>
                <DestroyModul
                    visible={destroyShow}
                    title={"删除"}
                    titleOk={"删除"}
                    titleCancel={"取消"}
                    titleContent={"您确定要删除么？"}
                    onSubmit={() => {
                        messageDestroy({ id: currentItem?.id ?? 0 })
                            .then(message.success("删除成功"))
                            .catch((e) => {
                                message.error(e.message);
                            })
                            .finally(() => {
                                this.reqTableData(1);
                                this.setState({ destroyShow: false });
                            });
                    }}
                    onCancel={() => {
                        this.setState({ destroyShow: false });
                    }}
                />
                <DestroyModul
                    visible={stickShow}
                    title={currentItem?.stick_type === 2 ? "取消置顶" : "置顶"}
                    titleOk={"确认"}
                    titleCancel={"取消"}
                    titleContent={`您确定要${
                        currentItem?.stick_type === 2 ? "取消置顶" : "置顶"
                    }么？`}
                    onSubmit={() => {
                        messageStick({ id: currentItem?.id ?? 0 })
                            .then((res) => {
                                message.success(
                                    `${
                                        currentItem?.stick_type === 2
                                            ? "取消置顶"
                                            : "置顶"
                                    }成功`
                                );
                                this.reqTableData(1);
                                this.setState({ stickShow: false });
                            })
                            .catch((e) => {
                                message.error(e.data.message);
                            });
                    }}
                    onCancel={() => {
                        this.setState({ stickShow: false });
                    }}
                />
            </div>
        );
    }
}
