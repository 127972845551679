/**
 * 获取账户余额
 * Create by liaokai on 2020-05-09 18:28:06
 */
import {toPost} from "../http-utils";

export const getAccMoney = toPost<
    {

    },
    {
        "sms_balance": number,//运营账户余额
        "balance": number //收益账户余额
    }
>("/merchant/Lcm/merchantBalance");
