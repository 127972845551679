/**
 * 金额选择
 * Create by liaokai on 2020-05-09 11:33:08
 */
import * as React from 'react';
import {Component} from "react";
import style from "./prepaid-money.scss";
import {Radio} from "../../../../../components/common/radio";
import {Card, InputNumber, Statistic} from "antd";
import {common} from "../../../../../common/common";
import {Air} from "../../../../../components/air/Air";

interface IProps{
    onChange? : (value : number) => void
}

interface IState{
    money : number,
    customMoney : any
}

const payPrices = [50,100,200,500,1000,2000, -1]
const defaultPriceIndex = 0
const maxValue = common.maxPrice //一亿元

export class PriceChoose extends Component<IProps,IState>{
    readonly state : IState = {
        money : 0,
        customMoney : 0
    };

    componentDidMount() {
        this.props.onChange?.(payPrices[defaultPriceIndex]);
    }

    filter = (value : number) => {
        if(value > common.maxPrice){
            this.setState({customMoney : common.maxPrice})
            return common.maxPrice
        }
        if(value <= 0){
            this.setState({customMoney : 50})
            return 50
        }
        return value
    }

    onChange = (value : number) =>{
        this.props.onChange?.(this.filter(value))
    }

    render() {
        let {} = this.props;
        let {customMoney, money} = this.state;
        let onChange = this.onChange
        return (
            <div className={style.payItems}>
                <Radio
                    data={payPrices}
                    initSelectIndex={defaultPriceIndex}
                    onSelect={(item, index) => {
                        this.setState({money : item}, ()=>{
                            if(item === -1){
                                onChange?.(customMoney);
                            }else{
                                onChange?.(item);
                            }
                        })
                    }}
                    renderSelectedItem={item => {
                        if(item === -1){
                            return <Card style={{border : `1px solid ${"#ff6e4b"}`,display: "flex",flexDirection: "column", justifyContent: "center"}}>
                                <div style={{color : common.color.unactivated}}>请输入充值金额</div>
                                <Air vertical={3}/>
                                <div style={{display: "flex", alignItems : "flex-end", width : "50%"}}>
                                    <InputNumber
                                        size={"large"}
                                        style={{flex : 1}}
                                        min={0}
                                        max={maxValue}
                                        onChange={(value)=>{
                                            this.setState({customMoney: !!value ? value : 0},()=>{
                                                onChange?.(this.state.customMoney);
                                            })
                                        }}
                                        value={customMoney}
                                        step={50}
                                    />
                                    <Air horizontal={5}/>
                                    <div>元</div>
                                </div>
                            </Card>
                        }else{
                            return <Card style={{display: "flex", justifyContent: "center", alignItems : "center" ,border : `1px solid ${"#ff6e4b"}`, cursor : "pointer", height : "100%"}}>
                                <Statistic prefix={"¥"} value={item} suffix={"元"}/>
                            </Card>
                        }
                    }}
                    renderUnSelectItem={item => {
                        if(item === -1){
                            return <Card style={{display: "flex",flexDirection: "column", justifyContent: "center"}}>
                                <div style={{color : common.color.unactivated}}>请输入充值金额</div>
                                <Air vertical={3}/>
                                <div style={{display: "flex", alignItems : "flex-end", width : "50%"}}>
                                    <InputNumber
                                        size={"large"}
                                        style={{flex : 1}}
                                        value={customMoney}
                                    />
                                    <Air horizontal={5}/>
                                    <div>元</div>
                                </div>
                            </Card>
                        }else{
                            return <Card style={{display: "flex", justifyContent: "center", alignItems : "center", cursor : "pointer", height : "100%"}}>
                                <Statistic prefix={"¥"} value={item} suffix={"元"}/>
                            </Card>
                        }
                    }}
                />
            </div>
        );
    }
}
