// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sms-package-item_container__3pKwK{cursor:pointer;display:flex;flex-direction:column;align-items:center;position:relative;padding:20px;width:250px;margin-top:10px;border:1px solid #dcdcdc}.sms-package-item_container__3pKwK .sms-package-item_badge__3R-0d{position:absolute;left:0px;top:-7px;background:linear-gradient(to right, #ff9849, #ff3e4e);color:#fff;padding:0px 10px;font-size:12px;border-radius:0px 5px 0px 0px}.sms-package-item_container__3pKwK .sms-package-item_title__2Sa4p{color:#582f1d;font-size:18px}.sms-package-item_container__3pKwK .sms-package-item_priceBox__18UY1{display:flex;align-items:center}.sms-package-item_container__3pKwK .sms-package-item_priceBox__18UY1 .sms-package-item_price__36SoQ{font-size:28px;color:#582f1d;font-weight:800}.sms-package-item_container__3pKwK .sms-package-item_priceBox__18UY1 .sms-package-item_price__36SoQ .sms-package-item_rmb__11vdE{font-size:16px}.sms-package-item_container__3pKwK .sms-package-item_originalPrice__SN87u{color:rgba(0,0,0,0.3);margin-top:5px}.sms-package-item_container__3pKwK .sms-package-item_tail__2MmST{color:#f57835;border:1px solid #f57835;border-radius:5px;padding:0px 10px;margin-top:10px}.sms-package-item_container__3pKwK:hover{background:linear-gradient(to right, #ffedd4, #ffcf89)}\n", ""]);
// Exports
exports.locals = {
	"container": "sms-package-item_container__3pKwK",
	"badge": "sms-package-item_badge__3R-0d",
	"title": "sms-package-item_title__2Sa4p",
	"priceBox": "sms-package-item_priceBox__18UY1",
	"price": "sms-package-item_price__36SoQ",
	"rmb": "sms-package-item_rmb__11vdE",
	"originalPrice": "sms-package-item_originalPrice__SN87u",
	"tail": "sms-package-item_tail__2MmST"
};
module.exports = exports;
