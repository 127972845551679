/**
 * 邮件群发
 * Create by liaokai on 2020-04-21 18:09:31
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {IphoneSimulator} from "../../../../../../../components/iphone-simulator/iphone-simulator";
import style from "./email-send.scss"
import {
    Button,
    Card,
    DatePicker,
    Descriptions,
    Dropdown,
    Input,
    message,
    Modal,
    Popover,
    Select,
    Typography
} from "antd";
import { Texts } from '../../../../../../../components/text/Texts';
import {Air} from "../../../../../../../components/air/Air";
import {Link} from "react-router-dom";
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import {common} from "../../../../../../../common/common";
import {apis} from "../../../../../../../common/apis/apis";
import {observer} from "mobx-react";
import {emailRouterStore} from "../email-router.store";
import {
    getModuleListAndTargetGroup, IModuleListItem,
    ITargetLitItem
} from "../../../../../../../common/apis/apis/getModuleListAndTargetGroup";
import utility from "../../../../../../../common/utils/tools";
import {ISendAffirm, sendAffirm} from "../../../../../../../common/apis/apis/sendAffirm";
import {ITagItem} from "../../sms-multi-send/sms-template/editor/sms-template-editor";
import {TitleContentTable} from "../../lecturer/preview/title-content-table/title-content-table";
import {emailSend} from "../../../../../../../common/apis/apis/email/emailSend";
import {IEmailSendAffirm} from "../../../../../../../common/apis/apis/email/emailSendAffirm";
import {RichTextBoxV2} from "../../../../../../../components/RichTextBox/RichTextBoxV2";
import BraftEditor from "braft-editor";
import {emailTemplateListAll} from "../../../../../../../common/apis/apis/email/emailTemplateListAll";

const { Option, OptGroup } = Select;

interface IProps extends RouteComponentProps<{module_id : string, meeting_id : string}>{

}
interface IEmailAddressBookList{
    id:number,
    name:string,
    count:number
}
interface IEmailTemplateList{
    id:number,
    title:string,
    text:string,
}
interface IState{
    showModal : boolean,
    emailNick : string, //发信人昵称
    moduleList : IModuleListItem[]
    targetList : ITargetLitItem[]
    emailTemplateList : IEmailTemplateList[],
    emailTemplateSelected :number,
    moduleIDSelected? : string,
    targetIDSelected? : string
    dateTime : string,
    confirmData : IEmailSendAffirm,
    templateContent : string,
    templateTitle : string,
    templateId : number,
    emailAddressBookList : IEmailAddressBookList[]

}
@observer
export class EmailSend extends Component<IProps,IState>{
    readonly state : IState = {
        showModal : false,
        emailNick:"",
        templateTitle : '' ,
        emailTemplateSelected:0,
        templateId : 0,
        moduleList : [],
        targetList : [],
        emailTemplateList : [],
        moduleIDSelected : this.props.match.params.module_id,
        dateTime : "",
        emailAddressBookList:[],
        confirmData: {
            "template_title": "",
            "template_content": [],
            "send_obj": "",
            "send_sum": -1, //发送总数
            "sms_balance": -1, //余额 单位元
            "type": "即时",
            "residue": -1, //邮件包剩余数量
            "deductNum": -1, //消耗邮件包数量
            "deductPrice": -1, //消耗多少余额
            "able_send": false //是否可以发送 true 为是 false 为否
        },
        templateContent : ''
    };

    componentDidMount() {
        apis.emailAddressBookSelect({}).then(
            res=>{
                this.setState({emailAddressBookList : res.map(value => ({
                        id:value.id,
                        name:value.name,
                        count:value.count,
                    }))})

            }
        )

        emailTemplateListAll({}).then(

            res=>{
                this.setState({emailTemplateList: res.map(value => ({
                        id:value.id,
                        title:value.title,
                        text : value.text,
                    }))})
            }

        )

        // apis.emailTemplateShow({id : parseInt(this.props.match.params.module_id)}).then(res => {
        //     this.setState({templateContent : res.text,templateTitle:res.title,templateId:res.id})
        // })
    }

    render() {
        let {history} = this.props;
        let {showModal,emailTemplateList, emailNick,emailTemplateSelected, emailAddressBookList, targetIDSelected, dateTime, confirmData, templateContent} = this.state;
        return (
            <div className={style.container}>
                <div className={style.left}>
                    <div className={style.item}>
                        <Texts.ItemTitle>
                            邮件模版
                        </Texts.ItemTitle>
                        <Air horizontal={8}/>
                        <Select style={{flex: 1}} placeholder={"请选择邮件模板"} onChange={value => {
                            this.setState({emailTemplateSelected : Number(value)})
                            emailTemplateList.map(item => {
                                if(item.id === value)
                                {
                                    this.setState({templateContent:item.text})
                                }
                            })
                        }}>
                            {
                                emailTemplateList.map(value => <Option value={value.id}> {value.title}</Option>)
                            }
                        </Select>
                    </div>
                    <div className={style.item}>
                        <Texts.ItemTitle>
                            目标群体
                        </Texts.ItemTitle>
                        <Air horizontal={8}/>
                        <Select style={{flex: 1}} placeholder={"请选择目标群体"} onChange={value =>{
                            this.setState({targetIDSelected : value.toString()})
                        }}>
                            {
                                emailAddressBookList.map(value1 => <Option value={value1.id}>{value1.name}</Option>)
                            }
                        </Select>
                    </div>
                    <div className={style.item}>
                        <Texts.ItemTitle>
                            发信昵称
                        </Texts.ItemTitle>
                        <Air horizontal={8}/>
                       <Input style={{flex: 1}} placeholder={"请输入发信人昵称"} defaultValue={""} value={emailNick} onChange={event => {
                           if(event.target.value.length > 8)
                           {
                               message.error('昵称过长')
                               return false
                           }else{
                               this.setState({emailNick:event.target.value})
                           }
                       }}/>
                    </div>
                    <div className={style.item}>
                        <Texts.ItemTitle>
                            发送时间
                        </Texts.ItemTitle>
                        <Air horizontal={8}/>
                        <DatePicker
                            style={{flex: 1}}
                            locale={locale}
                            showTime
                            allowClear
                            placeholder={"默认即时发送"}
                            onChange={value => {
                                this.setState({dateTime : value?.format("YYYY-MM-DD HH:mm:ss") ?? ""})
                            }}
                        />
                    </div>
                    <Air vertical={5}/>
                    <div className={style.templateContent}>

                        <Card type="inner" title="邮件模版内容">
                            <div className={style.moduleText}
                                 dangerouslySetInnerHTML={{ __html: templateContent}}
                            />
                            <Air vertical={20}/>
                                {
                                    emailTemplateSelected !== 0
                                    ?
                                    <div
                                        className={style.editText}>
                                        <Link to={`/index/email/overview/edit_email_template/${emailTemplateSelected}`}>去编辑</Link>
                                    </div>
                                        :
                                        ""
                                }
                        </Card>
                    </div>
                    <Air vertical={8}/>
                    <div className={style.sendBtns}>
                        <Button onClick={()=>{
                            history.goBack();
                        }}>取消</Button>
                        <Air horizontal={8}/>
                        <Button type={"primary"} onClick={()=>{
                             if(emailTemplateSelected===0){
                                message.error("请选择邮件模板")
                            }else if(emailNick===""){
                                 message.error('发信昵称不能为空')
                             }else if(new Date(dateTime) < new Date()){
                                message.error("发送时间请选择大于当前时间")
                            }else if(!targetIDSelected?.length){
                                message.error("请选择目标群体")
                            }else{
                                apis.emailSendAffirm({
                                    id : targetIDSelected as any,
                                    meetid : parseInt(this.props?.match?.params?.meeting_id),
                                    template_id : emailTemplateSelected as any ,
                                    type : !dateTime ? 1 : 2,
                                    send_time : dateTime,
                                    email_nick : emailNick,
                                }).then(res => {
                                    this.setState({confirmData : res},()=>{
                                        this.setState({showModal : true})
                                    })
                                })
                            }
                        }}>发送</Button>
                    </div>
                </div>
                {/*<div className={style.right}>*/}
                {/*    <IphoneSimulator width={280}>*/}
                {/*        <div className={style.smsPreview} style={{padding : "20px 10px"}}>*/}
                {/*            /!*<div style={{fontSize : 10, color: common.color.unactivated, marginBottom: 3}}>01-01 上午</div>*!/*/}
                {/*            <div style={{background : "#e8e8e8", borderRadius: 10, padding: 10}}>*/}
                {/*                {templateContent}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </IphoneSimulator>*/}
                {/*</div>*/}
                <Modal
                    title="确认发送"
                    visible={showModal}
                    onOk={()=>{
                        if(confirmData.residue < confirmData.deductNum){
                            message.error("邮件包余量不足")
                            return;
                        }
                        if(confirmData.sms_balance < confirmData.deductPrice){
                            message.error("余额不足")
                            return;
                        }
                        apis.emailSend({
                            id : targetIDSelected as any,
                            meetid : parseInt(this.props?.match?.params?.meeting_id),
                            template_id : emailTemplateSelected ,
                            type : !dateTime ? 1 : 2,
                            send_time : dateTime,
                            email_nick : emailNick,
                        }).then(res => {
                            message.success("发送内容已创建，请在列表中查阅发送状态")
                            history.goBack();
                        }).catch(e=>{
                            message.error(e.data.message)
                        })

                    }}
                    onCancel={()=>{
                        this.setState({showModal : false})
                    }}
                    centered={true}
                    okText={"发送"}
                    cancelText={"取消"}
                >
                    <Descriptions bordered column={1}>
                        <Descriptions.Item label="邮件模版">{confirmData?.template_title}</Descriptions.Item>
                        <Descriptions.Item label="目标群体">{confirmData?.send_obj}</Descriptions.Item>
                        <Descriptions.Item label="发送时间">{confirmData?.type === "即时" ? confirmData?.type : confirmData?.type+" "+dateTime}</Descriptions.Item>
                        <Descriptions.Item label="邮件费用">
                            <div>
                                <TitleContentTable>
                                    <div className={style.rightSide}>发送总数:</div>
                                    <div>{confirmData.send_sum} 条</div>
                                    <div className={style.rightSide}>邮件包余量:</div>
                                    <div>{confirmData.residue}条</div>
                                    <div className={style.rightSide}>邮件包扣除:</div>
                                    <div>{confirmData.deductNum} 条 <span style={{color : "red"}}>{confirmData.residue < confirmData.deductNum ? "(邮件包余量不足)" : ""}</span></div>
                                    <div className={style.rightSide}>余额:</div>
                                    <div>{confirmData.sms_balance} 元</div>
                                    <div className={style.rightSide}>余额扣除:</div>
                                    <div>{confirmData.deductPrice} 元 <span style={{color : "red"}}>{confirmData.sms_balance < confirmData.deductPrice ? "(余额不足)" : ""}</span></div>
                                </TitleContentTable>
                            </div>
                        </Descriptions.Item>
                    </Descriptions>
                </Modal>
            </div>
        );
    }
}
