/**
 * 互动问答表格
 * todo 拆分编辑、添加为单个页面
 * Create by liaokai on 2020-05-25 18:55:45
 */
import * as React from "react";
import {Button, Input, message, Modal, Radio, Spin, Switch, Table} from "antd";
import {TitleContentTable} from "../../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import {replyKeywords} from "../../../../../../../common/apis/apis/live/replyKeywordsList";

interface IProps {
    data:replyKeywords,
    onChange?:(data:replyKeywords)=>void
}

interface IState {
}
export function ReplyKeywordsStoreView(props:IProps) {

    const onChangeForm = (item: any) => {
        let { data, onChange } = props;
        onChange?.({
            ...data,
            ...item,
        });
    };

        return (
            <TitleContentTable rowGap={12} colGap={12}>
                <div>关键词</div>
                <Input
                    placeholder={"请输入关键词"}
                    // value={"keywords"}
                    onChange={(e) => {
                        onChangeForm({ keywords: e.target.value });
                    }}
                />
                <div>回复内容</div>
                <Input
                    placeholder={"请输入回复内容"}
                    // value={}
                    onChange={(e) => {
                        onChangeForm({ reply_content: e.target.value });
                    }}
                />
            </TitleContentTable>
        );
}
