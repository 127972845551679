
export type FilterMessage = {
    id: string;
    name: string;
    message: string;
    create_time: string;
    status: number; //1代表未审核
};

export type MessageUser = {
    id: number;
    name: string;
    forbid_message: number;
};

export function isMessage(data: FilterMessage | MessageUser): data is FilterMessage {
    return !!(<FilterMessage>data).message;
}