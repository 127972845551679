/**
 * 布局-左侧边-右内容
 * Create by liaokai on 2020-04-14 16:06:04
 */
import * as React from 'react';
import {Component} from "react";
import style from "./layout-sider-content.scss";

interface IProps{
    sider? : string | JSX.Element,
    content? : string | JSX.Element,
}

interface IState{

}

export class LayoutSiderContent extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {

    };
    render() {
        let {sider, content} = this.props;
        let {} = this.state;
        return (
            <div className={style.responsive}>
                <div className={style.left}>
                    {sider}
                </div>

                <div className={style.right}>
                    {content}
                </div>
            </div>
        );
    }
}
