/**
 * 根页 /#
 * Create by liaokai on 2020-04-13 14:59:10
 */
import React, { Component } from 'react';
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import Refund from '../../src/routes/Login/Refund'
import Register from '../../src/routes/Register'
import SignInView from '../../src/routes/SignInView'
import {IEBlockLogin} from "../components/ie-block-login/ie-block-login";
import {Index} from "./index";
import {common} from "../common/common";
import {Page404} from "./err/page-404";
import { Provider } from "mobx-react"
import LoginCompere from "./LoginCompere/Login";
import LoginAssistant from "./LoginAssistant/Login";
import {LiveCompere} from "./LiveCompere/liveCompere";

class App extends Component {
    componentDidMount(): void {
        // common.isLogin().catch(e => {
        //     window.location.hash = "login";
        // })

    }

    render() {
      return (
          <Provider>
              <HashRouter>
                  <Switch>
                      <Redirect path='/' exact to='/index' />
                      <Route path='/index' component={Index} />
                      <Route path='/login' component={IEBlockLogin} />

                      <Route path='/live_compere/:live_id' component={LiveCompere} />
                      {/*<Route path='/live_compere/:live_id/live' component={Index} />*/}
                      <Route path='/login_compere/:live_id' component={LoginCompere} />
                      <Route path='/login_assistant/:live_id' component={LoginAssistant} />
                      {/*<Route path='/register' component={Register} /> 后面需要再开*/}
                      {/*<Route path='/password_reset' component={Refund} 后面需要再开/>*/}
                      {/*<Route path='/fogetpassword' component={FogetPassword} />*/}
                      <Route path='/scanner/:id' component={SignInView} />
                      <Route path='/page404' component={Page404} />
                      <Redirect path='*' exact to='/page404' />
                  </Switch>
              </HashRouter>
          </Provider>
      );
  }
}

export default App;
