/**
 * 地理位置统计
 * Create by liaokai on 2020-06-24 17:17:15
 */
import {toPost} from "../../http-utils";

export interface IgeographicPositionItem {
    "title": string,
    "num": number
}

export const geographicPosition = toPost<
    {
        live_id : string
    },
    IgeographicPositionItem[]
>("/merchant/live/geographicPosition");

