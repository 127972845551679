import React, { Component } from 'react'
import style from './StepProcess.scss'
  
export class StepProcess extends Component {
constructor(props) {
  super(props);
  this.state = {
      step:[],
      status:0,
  };
     this.refreshProps = this.refreshProps.bind(this);
     this.createStepBox = this.createStepBox.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
  
}
refreshProps(props) {
  this.state.step = props.step!=undefined?props.step:this.state.step;
  this.state.status = props.status!=undefined?props.status:this.state.status;
  this.setState(this.state);
}
createStepBox(){
    if (this.state.step.length == 0) return;
    let result = [];
    for (let z = 0; z < this.state.step.length; z++) {
        result.push(
            <div className={[style.StepBox,'childcenter childcolumn'].join(' ')}>
                <div className={[style.StepName,z<=this.state.status?style.actName:''].join(' ')}>{this.state.step[z]}</div>
                <div className={[style.StepIndex,z<=this.state.status?style.actIndex:'','childcenter'].join(' ')}>{z+1}</div>
            </div>
        )
    }
    return result;
}
render() {
  return (
    <div className={[style.StepProcess,'childcenter'].join(' ')}>
        <div className={[style.StepGroup,'childcenter'].join(' ')}>
            {this.createStepBox()}
        </div>
        <div className={style.ProcessBody} style={{'--steplength':this.state.step.length,'--maxstatus':((100/(this.state.step.length-1))*this.state.status)+'%'}}></div>
    </div>
   )
   }
}
export default StepProcess