/**
 * 导出收费管理Excel
 * Create by liaokai on 2020-05-11 14:53:16
 */
import {toPost} from "../http-utils";

export const excelExportFee = toPost<
    {
        meetid : string
    },
    string
>("/merchant/meeting/excelExportFee");

