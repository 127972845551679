/**
 * 直播更新
 * Create by liaokai on 2020-05-30 18:00:39
 */
import {toPost} from "../../http-utils";

export const liveUpdate = toPost<
    {
        live_id: string,
        title: string,
        live_time : string,
        synopsis : string,
        poster : string,
        heat_value : number,
        doc?: string,
        is_heat?: number,
        live_status?: number,
        show_count? : number//观看次数开关
        show_online? : number//在线人数开关
            give_like? : number//在线人数开关
    },
    {

    }
>("/merchant/live/liveUpdate");
