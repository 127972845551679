import { toPost } from '../../../../../../../common/apis/http-utils';
import { FetchField } from '../types';
import { merge } from 'lodash';

export const formatListResult = (result: any) => {
  const response = merge({}, result);
  console.log('response', response);
  return {
    list: response?.data || [],
    total: response?.data.total || 0,
  };
};

// 接送机添加
export const givePlainStore = toPost<FetchField.Add, any>(`/merchant/meeting/givePlainStore`);

// 接送机列表
export const givePlainList = toPost<FetchField.List, any>(`/merchant/meeting/givePlainList`);

// 接送机用户列表
export const givePlainUsersList = toPost<FetchField.UserList, any>(`/merchant/meeting/givePlainUsersList`);

// 接送机用户列表导出
export const exportGivePlainUsersListUrl = toPost<FetchField.UserListExport, any>(`/merchant/meeting/exportGivePlainUsersListUrl`);

// 接送机用户添加详情列表
export const getGivePlainList = toPost<FetchField.UserDetailList, any>(`/merchant/meeting/getGivePlainList`);

// 选择接送机（添加）
export const choiceGivePlain = toPost<FetchField.Choice, any>(`/merchant/meeting/choiceGivePlain`);

// 接送机详情
export const givePlainInfo = toPost<FetchField.Detail, any>(`/merchant/meeting/givePlainInfo`);

// 接送机更改
export const givePlainUpdate = toPost<FetchField.Update, any>(`/merchant/meeting/givePlainUpdate`);

// 接送机发布
export const publishPlain = toPost<FetchField.Publish, any>(`/merchant/meeting/publishPlain`);

// 接送机删除
export const delPlain = toPost<FetchField.Add, any>(`/merchant/meeting/delPlain`);

export const userGivePlainUpdate = toPost<FetchField.Add, any>(`/merchant/meeting/userGivePlainUpdate`);

export const userGivePlainShow = toPost<any, any>(`/merchant/meeting/userGivePlainShow`);

export const userGivePlainDel = toPost<any, any>(`/merchant/meeting/userGivePlainDel`);