/**
 * 邮件重新发送
 * Create by liaokai on 2020-04-24 17:24:14
 */
import {toPost} from "../../http-utils";

export const emailSendEmailUsersAgain = toPost<
    {
        id : number[]
    },
    {

    }
    >("/merchant/email/sendEmailUsersAgain");
