/**
 * 专题详情
 * Create by liaokai on 2020-06-14 10:53:11
 */
import {toPost} from "../../http-utils";

export interface ISubjectShowItem {
    "live_id": number, //直播id
    "poster": string, //直播海报
    "title": string, //直播title
    "live_status": string, //直播状态
    "time": string, //直播时间
    "live_type": string,//直播类型
    "people_num": number //直播观看人数

}

export interface ISubjectShow {
    "id": number, //专题id
    "title": string, //专题标题
    "poster": string, //专题海报
    "synopsis": string, //专题简介
    "create_time": string, //专题创建时间
    "live_count": number, //直播数量
    "look_num": number, //专题下的所有直播观看人数
    "subject_dir": ISubjectShowItem[],
    url : string
}

export const subjectShow = toPost<
    {
        id : string
    },
    ISubjectShow
>("/merchant/live/subjectShow");

