/**
 * 邮件模板列表
 * Create by liaokai on 2020-04-23 17:52:00
 */
import {toPost} from "../../http-utils";

interface IEmailTemplateListItem {
    "id": number,
    "title": string,
    "create_time": string
}

export const emailSmsTemplateList = toPost<
    {
        meetid : number,
        page: number
    },
    {
        "total": number,
        "per_page": number,
        "current_page": number,
        "last_page": number,
        "data": IEmailTemplateListItem[]
    }
    >("/merchant/Email/templateList");
