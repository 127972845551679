/**
 * 提现记录
 * Create by liaokai on 2020-05-15 17:20:55
 */
import * as React from 'react';
import {Component} from "react";
import {CashRecordTable} from "./cash-record-table";
import {RouteComponentProps} from "react-router";
import {apis} from "../../../../common/apis/apis";
import {ItixianRecordItem} from "../../../../common/apis/apis/tixianRecord";
import {Button} from "antd";
import {InfoCircleFilled} from "@ant-design/icons/lib";
import {common} from "../../../../common/common";
import {Air} from "../../../../components/air/Air";

interface IProps extends RouteComponentProps{

}

interface IState{
}

export class CashRecord extends Component<IProps,IState>{
    readonly state : IState = {

    };

    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <div>
                <div style={{display : "flex", justifyContent : "space-between", alignItems : "center"}}>
                    <div style={{color : common.color.primary, display :"flex", alignItems : "center",margin : "0px 10px 15px"}}>
                        <InfoCircleFilled style={{ fontSize : 32}}/>
                        <Air horizontal={5}/>
                        <div>只针对已经结束的会议进行结算和提现</div>
                    </div>
                    <div>
                        <Button type={"link"} style={{paddingRight : 0}} onClick={()=>{
                            apis.tixianExport({}).then(res => {
                                window.open(res)
                            })
                        }}>
                            导出列表
                        </Button>
                    </div>
                </div>
                <CashRecordTable {...this.props}/>
            </div>
        );
    }
}
