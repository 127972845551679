/**
 * 观看限制设置
 * Create by liaokai on 2020-06-11 17:49:17
 */
import {toPost} from "../../http-utils";

export const createLiveTask = toPost<
    {
        live_record_id : number,
        status ?: number,
        img_file ?: string,
        width ?: number,
        height ?: number,
        referPos ?: number,
    },
    {
    }
>("/merchant/live/createLiveTask");
