/**
 * 会议列表
 * Create by liaokai on 2020-04-07 16:47:36
 */
import * as React from 'react';
import { Component } from "react";
import { Card, Divider, Empty, Input, message, Pagination, Popconfirm, Select } from "antd";
import Button from "antd/es/button";
import { Air } from "../../../../components/air/Air";
import { DeleteOutlined, EditOutlined, PlusOutlined, RollbackOutlined } from "@ant-design/icons/lib";
import { useState } from "react";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import { Responsive } from "../../../../components/common/responsive";
import style from "./meeting-list-view.scss"
import { MeetingCardStateless } from "../_card/meeting-card.stateless";
import { CheckBoxHigherOrder } from "../../../../components/check-box-higher-order/check-box-higher-order";
import { common } from "../../../../common/common";
import { Display } from "../../../../components/display/display";
import styled from "styled-components";
import {
    eMeetingListSearchRange,
    getMeetingList, tMeetingListDataItem,
    tMeetingListSort
} from "../../../../common/apis/apis/get-meeting-list";
import { delMeeting } from "../../../../common/apis/apis/del-meeting";
import { RouteComponentProps } from "react-router";
import { Texts } from "../../../../components/text/Texts";

const { Option } = Select;
const { Search } = Input;

export function SelectBoxCustom(props: any /* { data : {show:string,value:string}[], onSelect : (value : T) => void } */) {
    const [crtItem, setCrtItem] = useState(props.data[0]);
    return <Dropdown trigger={["click"]} overlay={
        <Menu onClick={value => {
            let clickItem = props.data.find((value1: any) => value1.value == value.key);
            setCrtItem(clickItem);
            props.onSelect && props.onSelect(clickItem);
        }}>
            {
                props.data.map((value: any) => {
                    return <Menu.Item key={value.value}>
                        {value.show}
                    </Menu.Item>
                })
            }
        </Menu>
    }>
        <Button size={"middle"}>
            {crtItem.show} <DownOutlined />
        </Button>
    </Dropdown>
}



interface IProps extends RouteComponentProps {

}

interface IState {
    editing: boolean,
    sort: tMeetingListSort,
    searchRange: eMeetingListSearchRange
    searchText: string,
    listData: tMeetingListDataItem[],
    pageCount: number      //总页数
    pageNum: number        //当前页码
}

const pageSize = 12

export class MeetingListView extends Component<IProps, IState>{
    selectedMeeting: tMeetingListDataItem[] = []

    //state初始化
    readonly state: IState = {
        editing: false,
        sort: "create_time",
        searchRange: eMeetingListSearchRange.all,
        searchText: "",
        listData: [],
        pageCount: 1,
        pageNum: 1
    };

    componentDidMount(): void {
        this.updateListData();
    }

    updateListData = async () => {
        let { sort, searchRange, searchText, pageCount, pageNum } = this.state;
        getMeetingList({
            keyword: searchText,
            orderfile: sort,
            meeting_status: searchRange,
            page: pageNum,
            pageNum: pageSize
        }).then(res => {
            this.setState({ listData: res.data, pageCount: res.page_count })
        })
    };

    render() {
        let { history } = this.props;
        let { editing, listData, pageCount } = this.state;
        return (
            <div className={style.container}>
                <Responsive>
                    <div className={style.responsive}>
                        <div className={style.head}>
                            <Texts.ChapterTitle>
                                会议列表
                            </Texts.ChapterTitle>
                            <div>
                                <Button type="primary" shape="round" icon={<PlusOutlined />} size={"large"} onClick={() => {
                                    history.push("new_meeting")
                                }}>
                                    新建会议
                                </Button>
                            </div>
                        </div>
                        <Divider style={{ margin: 0 }} />
                        <div className={style.content}>
                            {/*操作*/}
                            <div className={style.operationBar}>
                                {/*编辑*/}
                                <div className={style.left}>

                                    <div>
                                        <Display show={!editing} justHidden={true}>
                                            <SelectBoxCustom
                                                data={[
                                                    { value: 'create_time', show: '按照创建会议时间排序' },
                                                    { value: 'start_time', show: '按照会议时间排序' },
                                                ]}
                                                onSelect={(item: { value: tMeetingListSort, show: string }) => {
                                                    this.setState({ sort: item.value }, this.updateListData);
                                                }}
                                            />
                                        </Display>
                                    </div>
                                    <Air horizontal={5} />
                                    <div>
                                        <Display show={!editing} justHidden={true}>
                                            <Select defaultValue={eMeetingListSearchRange.all} onChange={value => {
                                                this.setState({ searchRange: value }, this.updateListData);
                                            }}>
                                                <Option value={eMeetingListSearchRange.all}>全部</Option>
                                                <Option value={eMeetingListSearchRange.preview}>预告</Option>
                                                <Option value={eMeetingListSearchRange.ing}>进行</Option>
                                                <Option value={eMeetingListSearchRange.over}>结束</Option>
                                            </Select>
                                        </Display>
                                    </div>
                                </div>
                                {/*搜索*/}
                                <div>
                                    <Display show={!editing} justHidden={true}>
                                        <Search placeholder={"搜索会议、地点"}
                                            onSearch={(text) => {
                                                this.setState({ searchText: text }, this.updateListData);
                                            }}>
                                        </Search>
                                    </Display>
                                </div>
                            </div>
                            <div className={style.cards}>
                                {
                                    editing ?
                                        <CheckBoxHigherOrder
                                            data={listData}
                                            itemStyle={{ display: "flex", flexDirection: "column" }}
                                            renderUnSelectItem={item =>
                                                <MeetingCardStateless select={"unselect"} data={item} />
                                            }
                                            renderSelectedItem={item =>
                                                <MeetingCardStateless selectColor={common.color.danger} select={"selected"} data={item} />
                                            }
                                            onChange={items => {
                                                this.selectedMeeting = items;
                                            }}
                                            extractKey={item => item.id.toString()}
                                        >
                                        </CheckBoxHigherOrder>
                                        :
                                        listData.map(item => {
                                            return (
                                            <div 
                                                key={item.id.toString()} 
                                                style={{ position: 'relative', display: "flex", flexDirection: "column" }} 
                                                onClick={() => {
                                                    history.push(`meeting/${item.id}`)
                                                }}
                                            >
                                                <div style={{"position": "absolute","padding":"0 10px","width": "100%","height": "22px","display": "flex","justifyContent": "flex-end","zIndex": 1,}}>
                                                    {item.is_register?<LiveType style={{marginRight:'10px'}} color={'gray'}>表单</LiveType>:null}
                                                    <LiveType color={'gray'}>
                                                        {item.pay_type === 1 
                                                            ? '免费'
                                                            : item.pay_type === 2
                                                                ? '收费'
                                                                : item.pay_type === 3 
                                                                    ? '按人群收费'
                                                                    : item.pay_type === 4 
                                                                        ? '按时间段收费'
                                                                        : item.pay_type === 5
                                                                            ? '按人群+时间收费'
                                                                            : ''}
                                                    </LiveType>
                                                </div>
                                                <MeetingCardStateless select={"none"} data={item} />
                                            </div>)
                                        })
                                }
                            </div>
                            {
                                listData?.length <= 0 ?
                                    <Empty style={{ margin: 50 }} description={"暂无会议"} />
                                    :
                                    undefined
                            }
                            <div className={style.pagination}>
                                <Pagination defaultCurrent={1} pageSize={pageSize} total={pageCount * pageSize} onChange={page => this.setState({ pageNum: page }, this.updateListData)} />
                            </div>
                        </div>
                    </div>
                </Responsive>
            </div>
        );
    }
}

const LiveType = styled.span`
  display: inline-block;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 0 0 5px 5px;
  color: white;
  background: ${props => props.color};
`;