/**
 * 关键字删除
 */
import {toPost} from "../../http-utils";

export const LiveKeywordsDelete = toPost<{
    live_id: string,
    id: number
}, {}>("/merchant/live/liveKeywordsDestroy");

