/**
 * 广告 - 路由
 * Create by gaohan on 2020-08-20
 */
import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import {
    SurfaceRouter,
    tSurfaceRouteItem,
} from "../../../../../../components/surface-router/surface-router";
import Plain from "./index";

interface IProps extends RouteComponentProps { }

interface IState { }

const routerData: tSurfaceRouteItem = {
    breadTitle: "接送机信息",
    routeName: "",
    component: Plain,
    children: [

    ],
};

export class PlainRouter extends Component<IProps, IState> {
    readonly state: IState = {};
    render() {
        let { } = this.props;
        let { } = this.state;
        return <SurfaceRouter {...this.props} entryRoute={routerData} />;
    }
}
