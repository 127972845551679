import React from 'react';
import { Form, Table, Switch, Button, message, Spin, Modal, Input } from "antd";
import { ModalProps } from 'antd/lib/modal'

type Props = {
  form?: any;
  formItems?: any[];
  children?: any;
  isTimeLayout?: boolean;
} & ModalProps;

const CostomModal = (props: Props) => {
  const { form, formItems, isTimeLayout = false, children, ...rest } = props;

  const timeLayout = !!isTimeLayout
    ? { labelCol: { span: 8 }, wrapperCol: { span: 12 } }
    : { labelCol: { span: 8 }, wrapperCol: { span: 12 } };
  return (
    <Modal
      okText="确定"
      cancelText="取消"
      {...rest}
    >
      {children}
      {form && (
        <Form
          form={form}
          // labelCol={{ span: 4 }}
          // wrapperCol={{ span: 16 }}
          {...timeLayout}
        >
          {formItems && formItems.map((item: any) => {
            if (item.render) {
              return item.render();
            }
            return (
              <Form.Item
                {...item}
                key={item.name}
              >
                <Input disabled={item.disabled} {...item.name === 'remark' ? {maxLength: 30} : {}} />
              </Form.Item>
            )
          })}
        </Form>
      )}
    </Modal>
  )
}
export default CostomModal;
export { CostomModal }