/**
 * 用户详情
 * Create by liaokai on 2020-06-09 17:17:55
 */
import {toPost} from "../../http-utils";

export interface IusersShowItem {
    "key": string,
    "value": string,
    "type": "str" | "img"
}

export const usersShow = toPost<
    {
        id : string
    },
    IusersShowItem[]
>("/merchant/live/usersShow");

