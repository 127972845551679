import React, { Component } from "react";
import style from "./EditExcelView.scss";
import ReactTable from "react-table";
import {message} from "antd";
import NewLabelBox from "../../../../../../meeting-list/meeting/menu/guest/index/components/NewLabelBox/NewLabelBox";
import {api} from "../../../../../../../../common/app";

export class EditExcelView extends Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      id:null,
      orgdata:null,
      file_name:this.props.file_name,
      data: [],
      datacolumn: 0,
      fail_num: this.props.failNum,
      success_num: this.props.successNum,
      all_num: this.props.allNum,
      title: null,
      selectedlabel:{},
    };
    this.refreshProps = this.refreshProps.bind(this);
    this.getData = this.getData.bind(this);
    this.HandleLabelSelect = this.HandleLabelSelect.bind(this);
  }
  componentWillReceiveProps(nextprops) {
    this.refreshProps(nextprops);
  }
  componentDidMount() {
    this.refreshProps(this.props);
    this.getData();
  }
  refreshProps(props) {
    this.state.orgdata = props.listdata;
    this.state.id = props.id;
    this.state.selectedlabel = props.selectedLabel;
    if (this.state.orgdata) {
      this.getData(this.state.orgdata);
    } else {
      this.props.HandleState(1);
    }
    this.setState(this.state);
  }
  getData(data) {

    if (!data) return;
    let datacolumn = [];
    let datacolumn2 = {"name":"姓名","email":"邮箱"};
    for (const key in datacolumn2) {
        const element = datacolumn2[key];
        datacolumn.push({
          Header: (
            <div
              className={[
                style.HeadCotnent,
                "childcenter childcontentstart"
              ].join(" ")}>
              {element}
            </div>
          ),
          accessor: key,
          Cell: props => {
            return (
              <div
                key={props.index + "guestdata" + props.index}
                className={[
                  style.ListChild,
                  "childcenter childcontentstart"
                ].join(" ")}>
                {props.value}
              </div>
            );
          },
          resizable: false,
          headerClassName: style.ListHead
        });
    }
    this.state.data = data;
    this.state.datacolumn = datacolumn;
    this.state.title = "导入邮箱";
    this.state.file_name = this.props.file_name;
    this.setState(this.state);
  }
  HandleLabelSelect(labeldata){
    this.state.selectedlabel = labeldata;
    this.props.LabelCallBack(labeldata);
    this.setState(this.state);
  }
  render() {
    const {data,fail_num,all_num,success_num} = this.state
    const str = ",成功导入"+this.state.success_num+"条数据 ,导入失败"+this.state.fail_num+"条数据" ;
    return (
      <div className={style.EditExcelView}>
        <div
          className={[style.ExcelName, "childcenter childcontentstart"].join(
            " "
          )}>
         {this.state.file_name + '-人员名单.xls '+str}
        </div>

        {this.state.datacolumn ? (
          <div className={style.ListBody} >
            <ReactTable style={{height:"400px"}}
              data={this.state.data}
              defaultPageSize={100000000000000000}
              columns={this.state.datacolumn}
              showPagination={false}
              minRows={4}
              NoDataComponent={() => {return <div className={[style.NoDataTips,'childcenter'].join(' ')}>上传失败</div>}}
            />
          </div>
        ) : (
          ""
        )}

        <div
          className={[
            style.EditTips,
            "childcenter childcolumn childalignstart"
          ].join(" ")}>
          <span>如发生导入信息不全或无法适用，请尝试以下解决方案：</span>
          <span> 1）将所有的列的文字设置为字符型 </span>
          <span> 2）去除所有计算公式，保留最终数值 </span>
          <span> 3）去除跨行、跨列的单元格合并 </span>
          <span> 4）去除表格特殊样式等等 </span>
          <span> 5) 请检查您的导入数据和表头顺序是否一致 </span>
        </div>
      </div>
    );
  }
}

class LabelInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labeldata: null,
      selected: {},
      newlabelbox: false
    };
    this.createLabel = this.createLabel.bind(this);
    this.HandleSelected = this.HandleSelected.bind(this);
    this.HandleNewLabelBox = this.HandleNewLabelBox.bind(this);
  }
  componentWillReceiveProps(nextprops) {
    this.refreshProps(nextprops);
  }
  componentDidMount() {
    this.refreshProps(this.props);
    this.setState(this.state);
  }
  refreshProps(props) {
    this.state.selected = props.selectedLabel;
    if (props.id!=this.state.id) {
      this.getLabel(props.id);
      this.state.id = props.id;
    }
  }
  HandleSelected(value) {
    this.state.selected[value] = !this.state.selected[value];
    this.props.onSelect(this.state.selected);
    this.setState(this.state);
  }
  createLabel() {
    if (!this.state.labeldata) return;
    let result = [];
    for (let z = 0; z < this.state.labeldata.length; z++) {
      let obj = this.state.labeldata[z];
      result.push(
        <div
          key={"label" + z}
          onClick={this.HandleSelected.bind(this, obj.id)}
          className={[
            style.LabelOption,
            this.state.selected[parseInt(obj.id)]? style.SelectedLabel : "",
            "childcenter"
          ].join(" ")}>
          {obj.name}
        </div>
      );
    }
    return result;
  }
  HandleNewLabelBox(boolean) {
    this.state.newlabelbox = boolean;
    this.setState(this.state);
  }
  getLabel(id) {
    if (!id) return;
    api.getMeetingLabel(id).then(
      res => {
        console.log(res);
        if (res.code === 200) {
          this.state.labeldata = res.result;
          this.setState(this.state);
        } else {
          message.error(res.message);
        }
      },
      err => {
        console.log(err);
      }
    );
  }
  render() {
    return (
      <div
        className={[style.LabeLSelectBox, "childcenter childalignstart"].join(
          " "
        )}>
        <div
          className={[style.InputName, "childcenter childcontentstart"].join(
            " "
          )}>
          {this.props.name}
        </div>
        <div
          className={[style.LabelGroup, "childcenter childcontentstart"].join(
            " "
          )}>
          {this.createLabel()}
          <div className={style.NewLabelButton}>
            <div
              className={style.ButtonValue}
              onClick={this.HandleNewLabelBox.bind(this, true)}>
              +新建标签
            </div>
            {this.state.newlabelbox ? (
              <div className={style.DropBox}>
                <NewLabelBox pos={"bl"} onClose={this.HandleNewLabelBox}  onCreateLabel={this.getLabel.bind(this,this.state.id)} meetingid={this.state.id} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default EditExcelView;
