import React, { useEffect, useState } from "react";
import BulletScreen from "rc-bullets";
import { useHistory } from "react-router-dom";
import { getBanner } from "../constants";
import { CloseOutlined } from "@ant-design/icons";
import { liveStore } from "../../live.store";
import { message } from "antd";
import { common } from "../../../../../../../common/common";
import { bindClient } from "../constants";

let screen: any = null;

type MessageItem = {
    name: string;
    message: string;
    create_time: string;
    headimgurl: string;
    type_name: string;
    type: string;
};

export default (props: any) => {
    const history = useHistory();
    const [ws, setWs] = useState({} as WebSocket);
    const [banner, setBanner] = useState("");

    const sendMessage = (message: MessageItem | MessageItem[]) => {
        const insertMessage = Array.isArray(message)
            ? message.map((item) => {
                  return (
                      <div style={{ fontSize: 28, padding: "20px 0px" }}>
                          {item.message}
                      </div>
                  );
              })
            : [
                  <div style={{ fontSize: 28, padding: "20px 0px" }}>
                      {message.message}
                  </div>,
              ];
        insertMessage.map((insertItem) => {
            screen.push(insertItem);
        });
    };

    useEffect(() => {
        getBanner({
            live_id: liveStore.live_id,
        }).then((response) => {
            setBanner(response.banner);
        });
    }, []);

    useEffect(() => {
        screen = new BulletScreen(".screen-message", { duration: 10 });
    }, []);

    // 初始化弹幕
    useEffect(() => {
        document.body.style.overflow = "hidden";
        const webSocket = new WebSocket(
            process.env.REACT_APP_ENV === "production"
                ? common.wsUrlProd
                : common.wsUrlDev
        );
        webSocket.onmessage = (e) => {
            const packageData = JSON.parse(e.data);
            switch (packageData.action) {
                case "bind":
                    // console.log("bind", packageData);
                    const bindData = packageData;
                    bindClient({
                        live_id: liveStore.live_id,
                        client_id: bindData.data.client_id,
                    })
                        .then((res) => {
                            message.success("已就绪");
                        })
                        .catch((e) => {
                            message.error("绑定ws失败 " + e.data?.message);
                        });
                    break;
                case "message":
                    // console.log("message", packageData);
                    const {
                        data,
                    }: { data: MessageItem | MessageItem[] } = packageData;
                    sendMessage(data);
                    break;
            }
        };
        webSocket.onopen = () => {
            console.log("success");
        };
        setWs(ws);
        return () => {
            document.body.style.overflow = "";
        };
    }, []);

    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#fff",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundImage: `url(${banner})`,
                zIndex: 100,
            }}
        >
            <div
                style={{ position: "absolute", right: 30, top: 30 }}
                onClick={() => history.goBack()}
            >
                <CloseOutlined style={{ fontSize: 40 }} />
            </div>
            <div
                className="screen-message"
                style={{
                    background: "rgb(249, 249, 249, 0.8)",
                    width: "85vw",
                    height: "90vh",
                    overflow: "hidden",
                }}
            />
        </div>
    );
};
