/**
 * 收益总览
 * Create by liaokai on 2020-05-11 15:56:38
 */
import * as React from 'react';
import {Component} from "react";
import {Button, Card, InputNumber, message, Modal, Statistic, Input, DatePicker} from "antd";
import {Air} from "../../../../../components/air/Air";
import {apis} from "../../../../../common/apis/apis";
import {ProceedsTable} from "./proceeds-table";
import {RouteComponentProps} from "react-router";
import 'moment/locale/zh-cn';
import {Moment} from "moment"
import moment from "moment";
import locale from "antd/lib/date-picker/locale/zh_CN";
import {ConvertCash} from "./convert-cash/convert-cash";
import {storeMenu} from "../../../../../common/menu.store";

const { Search } = Input;
const { RangePicker } = DatePicker;

interface IProps extends RouteComponentProps{

}

interface IState{
    money: any           //转入运营账户
    countCharge : number,   //收益余额
    transferAccModalConfirm : boolean   //展示转入运营账户弹窗
    convertCashModalConfirm : boolean   //展示提现弹窗
    searchDate? : [Moment,Moment], //搜索时间
    start_time? : string,
    end_time? : string,
    keyword? : string,
}

export class Proceeds extends Component<IProps,IState>{
    readonly state : IState = {
        money : 0,
        countCharge : 0,
        transferAccModalConfirm : false,
        convertCashModalConfirm : false,
        searchDate : undefined
    };

    componentDidMount() {
        apis.getMeetingProfit({
            start_time :  "",
            end_time : "",
            title :  "",
            page : 1,
            pageNum : 1
        })
            .then(res => {
                this.setState({countCharge : res.balance})
            })
            .catch(e => {
                message.error("加载失败")
            })
    }

    render() {
        let {} = this.props;
        let {searchDate,start_time,end_time, keyword,convertCashModalConfirm} = this.state;
        return (
            <>
                <Card>
                    <div style={{display: "flex", justifyContent : "space-between",alignItems: "center"}}>
                        <Statistic
                            prefix={"¥"}
                            title="收益余额"
                            value={this.state.countCharge ?? "****"}
                            precision={2}
                            suffix={"元"}
                        />
                        <div style={{display: "flex"}}>
                            <Button type={"default"} size={"large"} shape={"round"} onClick={()=>{
                                this.setState({transferAccModalConfirm : true})
                            }}>转入运营账户</Button>
                            <Air horizontal={5}/>
                            <Button type={"primary"} size={"large"}  shape={"round"} style={{width : 100}} onClick={()=>{
                                this.setState({convertCashModalConfirm : true})
                            }}>提现</Button>
                        </div>
                    </div>
                </Card>
                <Air vertical={12}/>
                <div style={{display: "flex", justifyContent : "flex-end"}}>
                    <div style={{display : "flex"}}>
                        <RangePicker
                            allowClear={true}
                            value={searchDate}
                            locale={locale}
                            onChange={(values, formatString) => {
                                // @ts-ignore
                                this.setState({searchDate: values}, ()=>{
                                    this.setState({
                                        start_time : this.state.searchDate?.[0].format("YYYY/MM/DD"),
                                        end_time : this.state.searchDate?.[1].format("YYYY/MM/DD"),
                                    })
                                })
                            }}
                        />
                        <Air horizontal={5}/>
                        <Search
                            allowClear={true}
                            placeholder="搜索会议标题"
                            onSearch={value => this.setState({keyword : value})}
                            style={{ width: 200 }}
                        />
                    </div>
                </div>
                <Air vertical={8}/>
                <ProceedsTable {...this.props} start_time={start_time} end_time={end_time} title={keyword}/>

                {/*弹窗*/}
                <Modal
                    visible={this.state.transferAccModalConfirm}
                    onCancel={()=>{
                        this.setState({transferAccModalConfirm : false})
                    }}
                    title={"转入运营账户"}
                    centered={true}
                    okText={"确定"}
                    cancelText={"取消"}
                    onOk={()=>{
                        apis.transferOperatingAcc({
                            price : this.state.money
                        }).then(res => {
                            message.success("转入运营账户成功");
                            this.setState({transferAccModalConfirm : false});
                            storeMenu.accountMenuRef?.go("prepaid-money");
                        }).catch(err => {
                            message.error(err.data?.message)
                        })
                    }}
                >
                    <div>
                        <div>请输入金额</div>
                        <Air vertical={3}/>
                        <InputNumber
                            placeholder={"请输入金额"}
                            style={{width : 150}}
                            defaultValue={0}
                            formatter={value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value?.replace(/¥\s?|(,*)/g, '') ?? "¥ "}
                            onChange={(v)=>{
                                this.setState({money : v ?? 0})
                            }}
                            value={this.state.money}
                            // max={this.state.countCharge ?? Number.MAX_VALUE}
                            min={0}
                            step={10}
                        />
                    </div>
                </Modal>
                {convertCashModalConfirm
                    ?
                    <ConvertCash onClose={()=>{
                        this.setState({convertCashModalConfirm : false})
                    }}/>
                :undefined}
            </>
        );
    }
}
