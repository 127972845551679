/**
 * 邮件发送记录-列表
 * Create by liaokai on 2020-04-21 17:50:39
 */
import * as React from 'react';
import {Component} from "react";
import {Button, message, Modal, Space, Table} from "antd";
import {RouteComponentProps} from "react-router";
import {apis} from "../../../../../../common/apis/apis";
import moment from "moment";
import { EditOutlined} from "@ant-design/icons/lib";
import {EmailEditAddressBook} from "./emailEditAddressBook";
import {EmailAddAddressBook} from "./emailAddAddressBook";
import {Texts} from "../../../../../../components/text/Texts";
import {Air} from "../../../../../../components/air/Air";
import {EmailAddAddress} from "./emailAddAddress";
import {EmailEditAddress} from "./emailEditAddress";
import {EmailDelAddress} from "./emailDelAddress";
import {Link} from "react-router-dom";
import {Flex} from "../../../../../../components/style/flex/flex";
import Search from "antd/es/input/Search";
import {FlexJustifyBetween} from "../../../../../../components/style/flex/flex-justify-between";


interface IProps extends RouteComponentProps<any>{

}

interface IState{
    listData : IEmailAddressList[],
    currentPage : number,
    total : number,
    pageSize : number,
    columns : any,
    momentNow : any,
    currentItem : IEmailAddressList | undefined,
    showEditSetting : boolean
    showDelSetting : boolean
    showAddSetting : boolean
}


interface IEmailAddressList {
    id : number,          //序号（仅占位）
    name : string,          //序号（仅占位）
    email: string,      //模版名
}

export class UsersTable extends Component<IProps,IState>{

    readonly state : IState = {
        listData : [],
        pageSize : 10,
        showEditSetting:false,
        showAddSetting:false,
        showDelSetting:false,
        currentPage : 1,
        total : 0,
        currentItem : undefined,
        columns : [
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
                render: (text: string) => text,
            },
            {
                title: '邮箱',
                dataIndex: 'email',
                key: 'email',
                // render: (text: number) => text,
                render: (text: string, record : IEmailAddressList) => (
                    record.email
                ),
            }
            ,
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                render: (text: string, record : IEmailAddressList) => (
                    <Space>
                        <Button type={"link"}
                                style={{padding : 0, marginRight: 10}}
                                onClick={()=>{
                            this.setState({showEditSetting : true, currentItem : record})
                        }} >编辑</Button>
                        <Button type={"link"}
                                style={{padding : 0, marginRight: 10}}
                                onClick={()=>{
                            this.setState({showDelSetting : true, currentItem : record})
                            // message.info("暂未开放")
                        }}>删除</Button>
                        {/*<Button size={"small"} icon={<ScissorOutlined />} onClick={()=>{*/}
                        {/*    message.info("暂未开放")*/}
                        {/*}}>编辑3</Button>*/}
                    </Space>
                ),
            }
        ],
        momentNow : moment.now()
    };
    refreshPage = ()=>{
        this.reqListData(this.state.currentPage)
    }
    reqListData(page : number = 1,pageSize:number=this.state.pageSize,search:string = ""){
        let addressBookId = this.props.match.params.address_book_id
        apis.emailAddressList({email_address_book_id : addressBookId,page : page,paginate:pageSize,search:search}).then(res => {
            this.setState({
                currentPage : res.current_page,
                total : res.total,
                listData : res.list.map(value => {
                    return {
                        id : value.id,
                        email : value.email,
                        name : value.name,
                    }
                })
            })
        })
    }

    timer : any

    componentDidMount(): void {
        this.reqListData(1)
        // this.timer = setInterval(()=>{
        //     this.reqListData(this.state.currentPage);
        //     this.setState({momentNow : moment.now()})
        // },3000)

    }

    // componentWillUnmount() {
    //     clearInterval(this.timer);
    // }

    render() {
        let {} = this.props;
        let addressBookId = this.props.match.params.address_book_id
        let {pageSize,currentPage, total, listData, columns,showEditSetting,currentItem,showAddSetting,showDelSetting} = this.state;
        return (
            <>
                <FlexJustifyBetween>
                    <Search
                        placeholder="请输入用户名"
                        style={{ width: "200px" }}
                        // disabled={!isEnableChat}
                        onChange={(event) => {
                            // this.setState({
                            //     searchText: event.target.value,
                            // });
                            console.log(event.target.value)
                        }}
                        onSearch={(value) => {
                            this.reqListData(1,pageSize,value)
                        }}
                        enterButton
                    />
                    <div style={{display: "flex", justifyContent:"flex-end"}}>
                        <Button type={"primary"}   shape={"round"} onClick={()=>{
                            this.setState({showAddSetting : true})
                        }}>添加</Button>
                        <Air horizontal={5}> </Air>
                        {/*<Button type={"primary"}  shape={"round"} onClick={()=>{*/}
                        {/*    // message.info("暂未开放")*/}
                        {/*}}>*/}
                        {/*    <Link to={`${addressBookId}/import`}>批量添加</Link>*/}
                        {/*</Button>*/}
                        <Button type={"primary"}  shape={"round"} onClick={()=>{
                            // message.info("暂未开放")
                        }}>
                            <Link to={`${addressBookId}/bulk_import`}>批量添加</Link>
                        </Button>
                    </div>


                </FlexJustifyBetween>
                <Air vertical={5}/>
                <Table
                    columns={columns}
                    dataSource={listData}
                    pagination={{
                        current : currentPage,
                        pageSize : pageSize,
                        total : total,
                        onChange : page => {
                            this.reqListData(page);
                            this.setState({currentPage : page})
                        },
                        onShowSizeChange:(currentPage,pageSize)=>{
                            this.setState({pageSize : Number(pageSize)})
                            // console.log(this.state.pageSize)
                            this.reqListData(currentPage,pageSize);
                        }
                    }} emptyText={"无联系人"}/>
                <EmailAddAddress visible={showAddSetting}  addressBookId={addressBookId}  onCancel={()=>{
                    this.setState({showAddSetting : false})
                }} onSubmit={()=>{
                    this.refreshPage();
                }}/>
                <EmailEditAddress visible={showEditSetting} storeItem={currentItem}
                                  onCancel={()=>{
                    this.setState({showEditSetting : false})
                }} onSubmit={()=>{
                    this.refreshPage();
                }}/>

                <EmailDelAddress visible={showDelSetting} storeItem={currentItem} id={currentItem?.id} onCancel={()=>{
                    this.setState({showDelSetting : false})
                }} onSubmit={()=>{
                    this.refreshPage();
                }}/>
            </>
        );
    }
}
