/**
 * 获取已开通的模块
 * Create by liaokai on 2020-04-23 12:07:13
 */
import {toPost} from "../http-utils";

export const getActiveModule = toPost<
    {

    },
    {
        "id": number,//记录id,无须处理
        "title": string,//模块名称
        "routerName": string//路由名
    }[]
>("/merchant/mokuai/getModel");

