/**
 * 直播编辑
 * Create by liaokai on 2020-05-23 17:57:00
 */
import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Texts } from "../../../../../../components/text/Texts";
import { TitleContentTable } from "../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import { Button, Card, DatePicker, Input, message, Switch, Spin } from "antd";
import { Flex } from "../../../../../../components/style/flex/flex";
import { Flex1 } from "../../../../../../components/style/flex/flex-1";
import styled from "styled-components";
import ImageCropper from "../../../../meeting-list/new-meeting/components/ImageCropper";
import { api } from "../../../../../../common/app";
import { common } from "../../../../../../common/common";
import { PlusCircleOutlined } from "@ant-design/icons/lib";
import { FlexJustifyCenter } from "../../../../../../components/style/flex/flex-justify-center";
import { Link } from "react-router-dom";
import { Air } from "../../../../../../components/air/Air";
import { Mask } from "./component/mask";
import { UploadImgTipStateless } from "./component/upload-img-tip-stateless";
import { liveStore as liveStoreReq } from "../../../../../../common/apis/apis/live/liveStore";
import moment from "moment";
import locale from "antd/lib/date-picker/locale/zh_CN";
import "moment/locale/zh-cn";
import { uploadFile } from "../../../../../../common/apis/apis/upload-file";
import { FlexJustifyAlignCenter } from "../../../../../../components/style/flex/flex-justify-align-center";
import { liveShow } from "../../../../../../common/apis/apis/live/liveShow";
import { liveStore } from "../live.store";
import { liveUpdate } from "../../../../../../common/apis/apis/live/liveUpdate";
import { enableShowSet } from "../../../../../../common/apis/apis/live/enableShowSet";
import { enableShowInfo } from "../../../../../../common/apis/apis/live/enableShowInfo";
import RichTextBox from "../../../../../../components/RichTextBox/RichTextBox";
import { RichTextBoxV2 } from "../../../../../../components/RichTextBox/RichTextBoxV2";
import BraftEditor from "braft-editor";

const { TextArea } = Input;

interface IProps extends RouteComponentProps<{ live_id?: string }> {}

interface IState {
    title: string; //标题
    live_time: string; //直播时间
    background_img: string; //直播时间
    synopsis: string; //简介
    poster: string; //封面
    is_heat: boolean; // 观看次数开关
    is_show_count: boolean; // 观看次数开关
    is_show_online: boolean; // 在线人数开关
    give_like: boolean; // 在线人数开关
    loading: boolean; // 是否创建中
    heat_value: number; // 热力值
}

export class LiveEdit extends Component<IProps, IState> {
    readonly state: IState = this.props?.match?.params?.live_id
        ? {
              ...liveStore.liveInfo,
              loading: false,
          }
        : {
              live_time: "",
              title: "",
              synopsis: "",
              poster: "",
              heat_value: 0,
              is_show_count: false,
              is_heat: false,
              background_img: '',
              is_show_online: false,
              give_like: false,
              loading: false,
          };

    synopsis: string = "";

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props?.match?.params?.live_id) {
            enableShowInfo({
                live_id: liveStore.live_id,
                type: 1,
            })
                .then((res) => {
                    const { is_show } = res;
                    this.setState({
                        is_show_online: is_show,
                    });
                })
                .finally(() => {});
            enableShowInfo({
                live_id: liveStore.live_id,
                type: 2,
            })
                .then((res) => {
                    const { is_show } = res;
                    this.setState({
                        is_show_count: is_show,
                    });
                })
                .finally(() => {});
            enableShowInfo({
                live_id: liveStore.live_id,
                type: 35,
            })
                .then((res) => {
                    const { is_show } = res;
                    this.setState({
                        is_heat: is_show,
                    });
                })
                .finally(() => {});
        }
    }

    componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any) {
        this.setState({ ...liveStore.liveInfo, loading: false });
    }

    onCropedImage = async (result: any): Promise<boolean> => {
        let formdata = new FormData();
        formdata.append("file", result);
        formdata.append("type", "base64");
        try {
            let res = await uploadFile(formdata);
            this.setState({ poster: res });
            return !!res;
        } catch (err) {
            message.error("服务器错误！");
            return false;
        }
    };
    onCropedImage1 = async (result: any): Promise<boolean> => {
        let formdata = new FormData();
        formdata.append("file", result);
        formdata.append("type", "base64");
        try {
            let res = await uploadFile(formdata);
            this.setState({ background_img: res });
            return !!res;
        } catch (err) {
            message.error("服务器错误！");
            return false;
        }
    };
    render() {
        let {
            match: { params },
            history,
        } = this.props;
        let {
            title,
            live_time,
            synopsis,
            give_like,
            poster,
            background_img,
            is_show_count,
            is_show_online,
            is_heat,
            heat_value
        } = this.state;
        const isNew = !params.live_id;
        return (
            <Spin spinning={this.state.loading}>
                {isNew ? (
                    <>
                        <Texts.ChapterTitle>创建直播</Texts.ChapterTitle>
                        <Air vertical={10} />
                    </>
                ) : undefined}
                <Flex>
                    <div style={{ flex: 7, paddingRight: "20px" }}>
                        <TitleContentTable rowGap={15} colGap={20}>
                            <div>*标题</div>
                            <Input
                                maxLength={30}
                                placeholder={"请输入直播标题"}
                                value={title}
                                onChange={(e) =>
                                    this.setState({ title: e.target.value })
                                }
                            />
                            <div>*时间</div>
                            <DatePicker
                                format={common.formatString}
                                value={
                                    live_time
                                        ? moment(live_time, common.formatString)
                                        : undefined
                                }
                                // disabledDate={disabledDate}
                                // disabledTime={disabledDateTime}
                                showTime={{
                                    defaultValue: moment(
                                        moment.now(),
                                        common.formatString
                                    ),
                                }}
                                onChange={(date, dateString) => {
                                    this.setState({ live_time: dateString });
                                }}
                                locale={locale}
                            />

                            {/* <div style={{alignSelf : "flex-start"}}>*直播简介</div> */}
                            {/*<TextArea rows={6} placeholder={"请输入直播简介"} value={synopsis} onChange={(e)=>{*/}
                            {/*    this.setState({synopsis : e.target.value})*/}
                            {/*}}/>*/}

                            <div>*直播简介</div>
                            <Card bordered={true}>
                                <RichTextBoxV2
                                    value={BraftEditor.createEditorState(
                                        synopsis
                                    )}
                                    onChange={(richContent) =>
                                        (this.state.synopsis = richContent.toHTML())
                                    }
                                />
                            </Card>

                            {/*<div>*/}
                            <div>显示观看次数</div>
                            <Switch
                                style={{ width: "10px" }}
                                checked={is_show_count}
                                onChange={(value) => {
                                    this.setState({
                                        is_show_count: value,
                                    });
                                    // enableShowSet({
                                    //     live_id: liveStore.live_id,
                                    //     type: 2
                                    // }).then(res => {
                                    //     if (value) {
                                    //         message.success('已开启');
                                    //     } else {
                                    //         message.success('已关闭');
                                    //     }
                                    // }).finally(() => {
                                    // });
                                }}
                            />

                            {/*<Air vertical={5}/>*/}
                            <div>显示在线人数</div>
                            <Switch
                                style={{ width: "10px" }}
                                checked={is_show_online}
                                onChange={(value) => {
                                    this.setState({
                                        is_show_online: value,
                                    });
                                    // enableShowSet({
                                    //     live_id: liveStore.live_id,
                                    //     type: 1
                                    // }).then(res => {
                                    //     if (value) {
                                    //         message.success('已开启');
                                    //     } else {
                                    //         message.success('已关闭');
                                    //     }
                                    // }).finally(() => {
                                    // });
                                }}
                            />

                            <div>点赞人数</div>
                            <Switch
                                style={{ width: "10px" }}
                                checked={give_like}
                                onChange={(value) => {
                                    this.setState({
                                        give_like: value,
                                    });
                                    // enableShowSet({
                                    //     live_id: liveStore.live_id,
                                    //     type: 1
                                    // }).then(res => {
                                    //     if (value) {
                                    //         message.success('已开启');
                                    //     } else {
                                    //         message.success('已关闭');
                                    //     }
                                    // }).finally(() => {
                                    // });
                                }}
                            />
                            <div>热力值</div>
                            <Switch
                                style={{ width: "10px" }}
                                checked={is_heat}
                                onChange={(value) => {
                                    this.setState({
                                        is_heat: value,
                                    });
                                    // enableShowSet({
                                    //     live_id: liveStore.live_id,
                                    //     type: 1
                                    // }).then(res => {
                                    //     if (value) {
                                    //         message.success('已开启');
                                    //     } else {
                                    //         message.success('已关闭');
                                    //     }
                                    // }).finally(() => {
                                    // });
                                }}
                            />
                            <div>热力值</div>
                            <div style={{width:"300px",  display: "flex", flexDirection: "row" }}>
                                <Input
                                    maxLength={10}
                                    type={"number"}
                                    placeholder={"请输入热力值"}
                                    value={heat_value}
                                    onChange={(e) =>{
                                        if(Number(e.target.value) < 99999999){
                                            this.setState({ heat_value: Number(e.target.value) })
                                        }else{
                                            message.error('热力值最大不能超过99999999')
                                        }
                                    }
                                    }
                                />

                                <div style={{width:"330px",margin: "4px 4px 4px 24px",color: common.color.primary}}>热力值为0时代表关闭</div>
                            </div>

                            <div>背景图</div>
                            <div
                                style={{
                                    display: "flex",
                                    background: "#f3f3f3",
                                    width: "320px",
                                    height: "180px",
                                    overflow: "hidden",
                                    border: `2px ${common.color.disable} solid`,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minHeight: "150px",
                                }}
                            >
                                {
                                    <ImageCropper
                                        text={
                                            <Mask
                                                floatView={
                                                    background_img ? (
                                                        <UploadImgTipStateless
                                                            theme={"dark"}
                                                            title={'上传背景图'}
                                                        />
                                                    ) : undefined
                                                }
                                            >
                                                {background_img ? (
                                                    <FlexJustifyCenter
                                                        style={{
                                                            width: "320px",
                                                            height: "180px",
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                maxWidth: "320px",
                                                                width: "320px",
                                                                height: "180px",
                                                                maxHeight: "180px",
                                                            }}
                                                            src={background_img}
                                                            alt=""
                                                        />
                                                    </FlexJustifyCenter>
                                                ) : (
                                                    <UploadImgTipStateless
                                                        title={'上传背景图'}
                                                    />
                                                )}
                                            </Mask>
                                        }
                                        onUpdate={this.onCropedImage1}
                                    />
                                }
                            </div>
                            {/*</div>*/}
                        </TitleContentTable>
                    </div>
                    {/* 海报 */}
                    <div
                        style={{
                            display: "flex",
                            background: "#f3f3f3",
                            width: "320px",
                            height: "180px",
                            overflow: "hidden",
                            border: `2px ${common.color.disable} solid`,
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "150px",
                        }}
                    >
                        {
                            <ImageCropper
                                text={
                                    <Mask
                                        floatView={
                                            poster ? (
                                                <UploadImgTipStateless
                                                    theme={"dark"}
                                                />
                                            ) : undefined
                                        }
                                    >
                                        {poster ? (
                                            <FlexJustifyCenter
                                                style={{
                                                    width: "320px",
                                                    height: "180px",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        maxWidth: "320px",
                                                        width: "320px",
                                                        height: "180px",
                                                        maxHeight: "180px",
                                                    }}
                                                    src={poster}
                                                    alt=""
                                                />
                                            </FlexJustifyCenter>
                                        ) : (
                                            <UploadImgTipStateless />
                                        )}
                                    </Mask>
                                }
                                onUpdate={this.onCropedImage}
                            />
                        }
                    </div>
                </Flex>
                <Air vertical={30} />
                <FlexJustifyCenter>
                    <Button
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        取消
                    </Button>
                    <Air horizontal={10} />
                    <Button
                        type={"primary"}
                        onClick={(e) => {
                            const {
                                title,
                                live_time,
                                synopsis,
                                poster,
                            } = this.state;
                            if (title && live_time && synopsis && poster) {
                                this.setState({ loading: true });
                                if (isNew) {
                                    liveStoreReq({
                                        ...this.state,
                                        is_heat: is_heat ? 1 : 2,
                                        give_like: give_like ? 1 : 2,
                                        show_count: is_show_count ? 1 : 2,
                                        show_online: is_show_online ? 1 : 2,
                                        synopsis: synopsis,
                                    })
                                        .then((res) => {
                                            message.success("创建成功");
                                            liveStore.reload();
                                            history.push("index");
                                        })
                                        .catch((e) => {
                                            message.error(
                                                "创建失败 " + e.data?.message
                                            );
                                            e.preventDefault();
                                            e.stopPropagation();
                                        })
                                        .finally(() => {
                                            this.setState({ loading: false });
                                        });
                                } else {
                                    liveUpdate({
                                        ...this.state,
                                        is_heat: is_heat ? 1 : 2,
                                        give_like: give_like ? 1 : 2,
                                        synopsis: synopsis,
                                        show_count: is_show_count ? 1 : 2,
                                        show_online: is_show_online ? 1 : 2,
                                        live_id: liveStore.live_id,
                                    })
                                        .then((res) => {
                                            message.success("保存成功");
                                            liveStore.reload();
                                        })
                                        .catch((e) => {
                                            message.error(
                                                "baocun 失败 " + e.data?.message
                                            );
                                            e.preventDefault();
                                            e.stopPropagation();
                                        })
                                        .finally(() => {
                                            this.setState({ loading: false });
                                        });
                                }
                            } else {
                                if (!poster) {
                                    message.error("请上传图片");
                                } else {
                                    message.error("请填写完整信息");
                                }
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}
                    >
                        保存
                    </Button>
                </FlexJustifyCenter>
            </Spin>
        );
    }
}

const FlexN = styled.div`
    flex: 4;
`;
