/**
 * 调研问卷更新
 * Create by liaokai on 2020-06-16 18:33:11
 */
import {toPost} from "../../http-utils";
import {IQuestionStoreQuestion} from "./questionStore";

export const liveSurveyQuestionnaireUpdate = toPost<
    {
        title : string,
        id : string,
        live_id : string,
        data : IQuestionStoreQuestion[]
    },
    {

    }
>("/merchant/live/liveSurveyQuestionnaireUpdate");
