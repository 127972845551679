/**
 * 用户管理-列表
 * Create by liaokai on 2020-05-26 15:36:50
 */
import * as React from "react";
import {Component} from "react";
import {FlexJustifyBetween} from "../../../../../../../components/style/flex/flex-justify-between";
import {Flex} from "../../../../../../../components/style/flex/flex";
import {
    Button,
    Descriptions,
    Popover,
    Table,
    Input,
    DatePicker,
    Modal,
    message,
    Switch,
    Spin,
} from "antd";
import locale from "antd/lib/date-picker/locale/zh_CN";
import {Air} from "../../../../../../../components/air/Air";
import {
    IUsersIndexItem,
    usersIndex,
    userUserForbidSet,
    getOnlineCount,
} from "../../../../../../../common/apis/apis/live/usersIndex";
import {questionIndex} from "../../../../../../../common/apis/apis/live/questionIndex";
import {liveStore} from "../../live.store";
import {common} from "../../../../../../../common/common";
import {Link} from "react-router-dom";
import {QuestionCircleOutlined} from "@ant-design/icons/lib";
import {Texts} from "../../../../../../../components/text/Texts";
import {merchantLiveExportInviteListUrl} from "../../invite/constants";
import {exportUserListCreditUrl} from "../../../../../../../common/apis/apis/live/exportUserListCreditUrl";
import {enableShowInfo} from "../../../../../../../common/apis/apis/live/enableShowInfo";
import invariant from "invariant";
import {enableShowSet} from "../../../../../../../common/apis/apis/live/enableShowSet";
import {bindClient} from "../../../../../../../common/apis/apis/live/bindClient";

const {Search} = Input;
const {RangePicker} = DatePicker;
interface IProps {}

interface IState {
    dataSource: IUsersIndexItem[];
    currentPage: number;
    total: number;
    pageSize: number;
    loading: boolean;
    createTime: [string, string];
    userName: string;
    useReview: boolean;
    selectedRowKeys: any[];
    online_count: number;
}
const exportUser = async () => {
    exportUserListCreditUrl({
        live_id: Number(liveStore.live_id),
    }).then((response) => {
        const href = response.url;
        window.open(href, "_blank");
        message.success("导出成功");
    });
};
export class LiveUserList extends Component<IProps, IState> {
    private ws: WebSocket | null = null;
    readonly state: IState = {
        dataSource: [],
        pageSize: 15,
        total: 0,
        currentPage: 1,
        loading: false,
        createTime: ["", ""],
        userName: "",
        useReview: false,
        selectedRowKeys: [],
        online_count: 0,
    };

    async onReviewItem(item: any) {
        try {
            const payload = {
                live_id: liveStore.live_id,
                live_user_id: [item.id],
                forbid_type: item.forbid === 1 ? 2 : 1,
            };
            const result = await userUserForbidSet(payload as any);
            this.setState({selectedRowKeys: []});
            message.success("审核成功！");
            this.reqTableData(1);
        } catch (error) {
            message.warn(error.message);
        }
    }

    newConnect = () => {
        this.ws = new WebSocket(
            process.env.REACT_APP_ENV === "production" ?
                common.wsUrlProd :
                common.wsUrlDev
        );
        this.ws.onclose = () => {
            setTimeout(() => {
                this.newConnect();
            }, 1000);
        };
        this.ws.onmessage = (e) => {
            const packageData = JSON.parse(e.data);
            console.log("packageData, ", packageData);
            switch (packageData.action) {
                case "bind":
                    const bindData: any = packageData;
                    bindClient({
                        live_id: liveStore.live_id,
                        client_id: bindData.data.client_id,
                        type: 2,
                    } as any)
                        .then((res) => {
                            console.log("success");
                            // message.success("绑定ws完成")
                        })
                        .catch((e) => {
                            // message.error("绑定ws失败 " + e.data?.message)
                        });
                    break;
                case "online_count":
                    this.setState({
                        online_count: packageData.data.online_count,
                    });
                    break;
            }
            // message.success(JSON.stringify(e.data))
        };
        this.ws.onopen = (e) => {
            // message.success("websocket链接成功")
        };
    };

    reqTableData(page: number) {
        this.setState({loading: true});
        usersIndex({
            live_id: liveStore.live_id,
            page,
            paginate: this.state.pageSize,
        })
            .then((res) => {
                this.setState({
                    dataSource: res.data,
                    total: res.total,
                    currentPage: page,
                });
            })
            .finally(() => {
                this.setState({loading: false});
            });
    }

    componentDidMount(): void {
        this.reqTableData(1);

        getOnlineCount({live_id: liveStore.live_id}).then((response) => {
            this.setState({online_count: response.uid_online_count});
        });

        enableShowInfo({
            live_id: liveStore.live_id,
            type: 27,
        }).then((res) => {
            this.setState({useReview: res.is_show});
        });

        this.newConnect();
    }

    handleSearch() {
        const {userName, createTime} = this.state;
        this.setState({
            loading: true,
            currentPage: 1,
        });
        usersIndex({
            live_id: liveStore.live_id,
            page: 1,
            paginate: this.state.pageSize,
            userName,
            createTime,
        })
            .then((res) => {
                this.setState({
                    dataSource: res.data,
                    total: res.total,
                });
            })
            .finally(() => {
                this.setState({loading: false});
            });
    }

    handleExport() {
        console.log("export");
    }

    onSelectChange = (selectedRowKeys: any[]) => {
        this.setState({selectedRowKeys});
    };

    onReview = async (type?: string) => {
        try {
            const {selectedRowKeys} = this.state;
            invariant(selectedRowKeys.length > 0, "请选择要审核的用户");

            const payload = {
                live_id: liveStore.live_id,
                live_user_id: selectedRowKeys,
                forbid_type: type === "REJECT" ? 2 : 1,
            };
            const result = await userUserForbidSet(payload as any);
            // console.log("result", result);
            this.setState({selectedRowKeys: []});
            message.success("审核成功！");
            this.reqTableData(1);
        } catch (error) {
            message.warn(error.message);
        }
    };

    render() {
        let {} = this.props;
        const {
            dataSource,
            pageSize,
            total,
            currentPage,
            loading,
            useReview,
            selectedRowKeys,
        } = this.state;

        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (keys: any, selectedRows: any) => {
                this.onSelectChange(keys);
            },
        };

        const columns = [
            {
                title: "用户昵称",
                dataIndex: "name",
                key: "name",
                render: (text: boolean) => text,
            },
            // {
            //     title: (
            //         <Popover
            //             content={
            //                 <div style={{width: 500}}>
            //                     {/*<Texts.TableTitle>*/}
            //                     {/*    本积分是用户在本场直播中参与互动所得积分，用户可以使用积分来进行下载等*/}
            //                     {/*</Texts.TableTitle>*/}
            //                     {/*<Air vertical={10} />*/}
            //                     {/*<Texts.TableTitle>具体规则：</Texts.TableTitle>*/}
            //                     {/*<Air vertical={5} />*/}
            //                     {/*<Descriptions bordered column={1}>*/}
            //                     {/*    <Descriptions.Item label="参与聊天">*/}
            //                     {/*        5分（5分钟以内发表得1分，以后每5分钟得1分）*/}
            //                     {/*    </Descriptions.Item>*/}
            //                     {/*    <Descriptions.Item label="参与互动问答">*/}
            //                     {/*        5分（每次参与互动问答得5分）*/}
            //                     {/*    </Descriptions.Item>*/}
            //                     {/*    <Descriptions.Item label="参与调研">*/}
            //                     {/*        5分（每次参与调研得5分）*/}
            //                     {/*    </Descriptions.Item>*/}
            //                     {/*    <Descriptions.Item label="观看直播">*/}
            //                     {/*        1分（观看直播一分钟得1分）*/}
            //                     {/*    </Descriptions.Item>*/}
            //                     {/*</Descriptions>*/}
            //                 </div>
            //             }
            //             placement={"right"}
            //         >
            //             <span>
            //                 {/*积分 <QuestionCircleOutlined />*/}
            //             </span>
            //         </Popover>
            //     ),
            //     render: (data: IUsersIndexItem) => (
            //         <Link
            //             to={`user/credit-detail/${liveStore.live_id}/${data.id}`}
            //         >
            //             <span style={{textDecoration: "underline"}}>
            //                 {data.credit}
            //             </span>
            //         </Link>
            //     ),
            // },
            {
                title: "付费",
                dataIndex: "is_pay",
                key: "is_pay",
                render: (text: boolean) => (text ? "是" : "否"),
            },
            {
                title: "邀请码",
                dataIndex: "invitation_code",
                key: "invitation_code",
                render: (text: string) => text,
            },
            {
                title: "密码",
                dataIndex: "password",
                key: "password",
                render: (text: string) => text,
            },
            {
                title: "白名单",
                dataIndex: "white_list",
                key: "white_list",
                render: (text: string) => text,
            },
            {
                title: "审核状态",
                dataIndex: "forbid",
                key: "forbid",
                render: (text: number) =>
                    text === 1 ? "审核通过" : "审核不通过",
            },
            {
                title: `在线（${this.state.online_count || 0}）`,
                dataIndex: "is_online",
                key: "is_online",
                render: (text: boolean) => (text ? "是" : "否"),
            },
            {
                title: "注册时间",
                dataIndex: "create_time",
                key: "create_time",
                render: (text: string) => text,
            },
            {
                title: "操作",
                dataIndex: "id",
                key: "id",
                render: (text: string, item: any) => (
                    <div>
                        <Link to={`user/detail/${text}`}>
                            <Button type={"link"} style={{paddingLeft: 0}}>
                                详情
                            </Button>
                        </Link>

                        {`  `}
                        <Button
                            onClick={() =>
                                this.state.useReview ?
                                    this.onReviewItem(item) :
                                    () => {}
                            }
                            type={"link"}
                            style={{paddingLeft: 0}}
                        >
                            {item.forbid === 1 ? "拒审" : "审核"}
                        </Button>
                    </div>
                ),
            },
        ];
        return (
            <div>
                <FlexJustifyBetween>
                    <Flex>
                        <Flex style={{alignItems: "center"}}>
                            <Switch
                                checked={useReview}
                                onChange={(value) => {
                                    enableShowSet({
                                        live_id: liveStore.live_id,
                                        type: 27,
                                    }).then((res) => {
                                        this.setState({
                                            useReview: res.is_show,
                                        });
                                    });
                                }}
                            />
                            <Air horizontal={3} />
                            <div>开启审核</div>
                            <Air horizontal={3} />
                        </Flex>

                        <RangePicker
                            // disabled={!useReview}
                            placeholder={["开始时间", "结束时间"]}
                            style={styles.searchStyle}
                            locale={locale}
                            onChange={(date, dateString) => {
                                this.setState({
                                    createTime: dateString,
                                });
                            }}
                        />
                        <Air horizontal={5} />
                        <Search
                            placeholder="请输入用户昵称"
                            style={{width: "200px"}}
                            // disabled={!useReview}
                            onChange={(event) => {
                                this.setState({userName: event.target.value});
                            }}
                            onSearch={(value) => {
                                this.handleSearch();
                            }}
                            enterButton
                        />
                        {/* <Input*/}
                        {/*    placeholder="请输入用户昵称"*/}
                        {/*    style={styles.searchStyle}*/}
                        {/*    onChange={(event) => {*/}
                        {/*        this.setState({*/}
                        {/*            userName: event.target.value*/}
                        {/*        });*/}
                        {/*    }}*/}
                        {/* />*/}
                        {/* <Button onClick={() => this.handleSearch()}>搜索</Button>*/}
                    </Flex>
                    <Flex>
                        <Button
                            type={"primary"}
                            // disabled={!useReview}
                            onClick={exportUser}
                        >
                            导出
                        </Button>
                        {/* <Button onClick={() => this.handleExport()}>导出</Button>*/}
                    </Flex>
                </FlexJustifyBetween>
                <Air vertical={5} />
                <div>
                    <Table
                        rowKey="id"
                        loading={loading}
                        columns={columns}
                        dataSource={dataSource}
                        rowSelection={rowSelection}
                        // emptyText={"暂无内容"}
                        pagination={{
                            defaultCurrent: 1,
                            current: currentPage,
                            pageSize: pageSize,
                            total: total,
                            onChange: (page) => {
                                this.reqTableData(page);
                                this.setState({currentPage: page});
                            },
                        }}
                    />
                    <Flex style={{marginTop: -40}}>
                        <Button type="primary" onClick={() => this.onReview()}>
                            批量审核
                        </Button>
                        <Button
                            type="danger"
                            style={{marginLeft: 12}}
                            onClick={() => this.onReview("REJECT")}
                        >
                            批量拒审
                        </Button>
                    </Flex>
                </div>
            </div>
        );
    }
}

interface UserCreditProps {
    userId: String;
}

interface UserCreditState {
    dataList: Array<any>;
}

// class userCreditModel extends Component<UserCreditProps,UserCreditState>{
//     constructor(props:UserCreditProps){
//         super(props);
//         this.state={
//             loading:false,
//             dataList:[],
//             currentPage:0,
//             pageSize:10,
//             total:0,
//         }
//     }
//     render(){
//         return (
//             <Table
//                     loading={loading}
//                     columns={columns}
//                     dataSource={dataSource}
//                     // emptyText={"暂无内容"}
//                     pagination={{
//                         defaultCurrent : 1,
//                         current : currentPage,
//                         pageSize : pageSize,
//                         total : total,
//                         onChange : page => {
//                             this.reqTableData(page);
//                             this.setState({currentPage : page})
//                         }
//                     }}
//                 />
//         )
//     }
// }

const styles = {
    searchStyle: {
        width: "250px",
        marginRight: "10px",
    },
};
