/**
 * 关键词过滤
 * Create by liaokai on 2020-05-23 18:24:24
 */
import * as React from 'react';
import {Component} from "react";
import {Button} from "antd";
import {EditableTagGroup} from "./compenont/editable-tag-group";
import {Flex} from "../../../../../../components/style/flex/flex";
import styled from "styled-components";
import {LiveKeywordsDelete} from "../../../../../../common/apis/apis/live/LiveKeywordsDelete";
import {ILiveKeyWordsIndexItem, LiveKeyWordsIndex} from "../../../../../../common/apis/apis/live/LiveKeyWordsIndex";
import {LiveKeywordsStore} from "../../../../../../common/apis/apis/live/LiveKeywordsStore";
import {liveStore} from "../live.store";

interface IProps{

}

interface IState{
    data : ILiveKeyWordsIndexItem[]
}

export class KeywordFilter extends Component<IProps,IState>{
    readonly state : IState = {
        data: []
    };

    componentDidMount() {
        LiveKeyWordsIndex({
            live_id : liveStore.live_id
        }).then(res => {
            this.setState({data : res.data})
        })
    }

    render() {
        let {} = this.props;
        let {data} = this.state;
        return (
            <div>
                <Tags>
                    <EditableTagGroup
                        data={data}
                        onChange={async (add, reduce) => {
                            if(add){
                                const res = await LiveKeywordsStore({
                                    live_id : liveStore.live_id,
                                    keyword : add.keyword
                                })
                                return res
                            }
                            if(reduce){
                                await LiveKeywordsDelete({
                                    live_id : liveStore.live_id,
                                    id : reduce.id
                                })
                                return reduce
                            }
                            throw new Error("添加和删除必须填一个")
                        }}
                        extractTitle={item => item.keyword}
                        createNewItem={(keyword)=>{
                            return {
                                "id": 0,
                                "keyword": keyword,
                                "create_time": ""
                            }
                        }}
                    />
                </Tags>
            </div>
        );
    }
}

const Tags = styled(Flex)`
  flex-wrap: wrap;
`
