import { toPost } from "../../../../../../../common/apis/http-utils";
import { FetchField} from '../types'

export const formatListResult = (result: any): any => {
    const mergeResult = result;
    return {
        list: mergeResult.data,
        total: mergeResult.total,
    };
};

export const liveSignList = toPost<FetchField.List, any>(`/merchant/live/liveSignList`);

export const exportCheckListUrl = toPost<{ live_id: any }, any>(
    `/merchant/live/exportLiveSignUrl`
);