/**
 * 使用余额购买短信包
 * Create by liaokai on 2020-05-09 18:33:54
 */
import {toPost} from "../http-utils";

export const buySmsPackageByCash = toPost<
    {
        smsPackageId : string
    },
    {

    }
>("/merchant/buySmsPackageByCash");
