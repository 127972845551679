/**
 * 平面路由
 *  这个组件为了解决面包屑无法自动生成的问题
 *  接收有层次的组件树，经过深度优先遍历后，平铺在Switch内，层次深的组件优先匹配
 *  面包屑组件同样接收 类型为 tRouteItem 的参数，以实现自动面包屑
 *
 * Create by liaokai on 2020-04-16 16:07:52
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {Redirect, Route, Switch} from "react-router-dom";
import {BreadcrumbCustomRouter} from "../breadcrumbx/breadcrumb-custom-router";
import {Air} from "../air/Air";
import {Divider} from "antd";

export type tSurfaceRouteItem = {
    breadTitle : string | JSX.Element
    routeName : string
    component : React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
    lastNode? : tSurfaceRouteItem
    children : tSurfaceRouteItem[]
}

interface IProps extends RouteComponentProps{
    entryRoute? : tSurfaceRouteItem
}

interface IState{

}

export class SurfaceRouter extends Component<IProps,IState>{
    readonly state : IState = {

    };

    // 回溯
    public static reBack = (data? : tSurfaceRouteItem) : tSurfaceRouteItem[] => {
        if(!data){
            return []
        }
        if(data.lastNode){
            return SurfaceRouter.reBack(data.lastNode).concat([data])
        }else{
            return [data]
        }
    };

    // 深度优先遍历
    public static deepTraverse = (data : tSurfaceRouteItem, callback : (data : tSurfaceRouteItem) => void) => {
        for (let i = 0; i < data.children.length; i++) {
            let crt  = data.children[i];
            crt.lastNode = data;
            SurfaceRouter.deepTraverse(crt, callback);
        }
        callback(data);
    };

    // 树遍历迭代器
    public static deepTraverseIterator = (data? : tSurfaceRouteItem) : tSurfaceRouteItem[] => {
        if(!data) return [];
        let a : tSurfaceRouteItem[] = [];
        SurfaceRouter.deepTraverse(data,(data)=>{a.push(data)});
        return a;
    };

    breadcrumbCustomRouter = React.createRef<BreadcrumbCustomRouter>();

    render() {
        let {entryRoute, match : {path}, match,location,} = this.props;
        let {} = this.state;
        return (
            <div>
                <Air vertical={2}/>
                <BreadcrumbCustomRouter
                    ref={this.breadcrumbCustomRouter}
                    basePath={path}
                />
                <Divider style={{margin: "10px 0px 20px"}}/>
                <Switch>
                    {
                        SurfaceRouter.deepTraverseIterator(entryRoute)
                            .map(value =>
                                <Route
                                    key={value.routeName}
                                    path={`${path}/${SurfaceRouter.reBack(value).map(value1 => value1.routeName).filter(value1 => !!value1).join("/")}`}
                                    render={props => {
                                        let Comp = value.component;
                                        setTimeout(()=>{
                                            this.breadcrumbCustomRouter.current?.setParams(props, value);
                                        },0)
                                        return <Comp {...props}/>
                                    }}
                                />
                            )
                    }
                    <Redirect path={`${path}`} exact={true} to={`${path}/${SurfaceRouter.reBack(SurfaceRouter.deepTraverseIterator(entryRoute).pop()).map(value1 => value1.routeName).join("/")}`} />
                </Switch>
            </div>

        );
    }
}
