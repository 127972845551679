/**
 * 会议列表
 * Create by liaokai on 2020-04-03 11:26:53
 */
import {toPost} from "../http-utils";

export type tMeetingListSort = "start_time" | "create_time"

export enum eMeetingListSearchRange {
    all = 0,        //全部
    preview = -1,   //预告
    ing= 1,         //进行
    over = 2,       //结束
}

export type tMeetingListDataItem = {
    "id": number,           //会议id
    "cover": string,        //会议封面图
    "title": string,        //会议标题
    "start_time": string,   //会议开始时间 (格式 2019/03/08 11:00:00)
    "end_time": string,     //会议结束时间
    "city": string,         //城市
    "user_count": number,   //参与人数
    "meeting_status": eMeetingListSearchRange   //会议状态,-1预告,1进行中,2结束
    "is_register": any;
    'pay_type': any;
}

export const getMeetingList = toPost<
    {
        keyword : string                            //关键字
        orderfile : tMeetingListSort                //排序方式
        meeting_status : eMeetingListSearchRange    //会议状态
        page : number
        pageNum? : number
    },
    {
        "data": tMeetingListDataItem[]  //会议列表
        "page_count": 1
    }
>("/merchant/meeting/index");
