/**
 * 遮罩
 * Create by liaokai on 2020-05-25 16:12:17
 */
import * as React from 'react';
import {Component} from "react";
import styled from "styled-components";

interface IProps{
    floatView? : JSX.Element    //遮罩内容
    type? : "hover" | "static"
    showMask? : boolean     //仅当type : static 时可用
    backgroundColor? : string
}

interface IState{
    showMask : boolean
}

export class Mask extends Component<IProps,IState>{
    readonly state : IState = {
        showMask : false
    };
    render() {
        let {children,floatView,type,backgroundColor} = this.props;
        let {showMask} = this.state;
        let _showMask : boolean = showMask
        if(type === "static"){
            _showMask = !!this.props.showMask
        }
        return (
            <Container
                onMouseEnter={()=>{
                    this.setState({showMask : true})
                }}
                onMouseLeave={()=>{
                    this.setState({showMask : false})
                }}
            >
                {
                    floatView ?
                        <Mask_ show={_showMask} backgroundColor={backgroundColor}>
                            {floatView}
                        </Mask_>
                        :
                        undefined
                }
                {children}
            </Container>
        );
    }
}

const Container = styled.div`
  position: relative;
  
`

const Mask_ = styled.div<{show : boolean, backgroundColor? : string}>`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top : 0px;
  background: ${p => p.backgroundColor ?? "rgba(0,0,0,0.5)"};
  display: ${params => params.show ? "inherit" : "none"};
`
