/**
 * 固定比例组件
 * Create by liaokai on 2020-04-07 19:16:25
 */
import * as React from 'react';
import {Component} from "react";

interface IProps{
    num : number,   // 高宽比，0～1
}

interface IState{

}

export class Ratio extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {

    };
    render() {
        let {num,children} = this.props;
        let {} = this.state;
        return (
            <div style={{width : "100%", paddingBottom : `${num * 100}%`, position :"relative"}}>
                <div style={{width : "100%",height : "100%", position: "absolute", top : 0, left : 0}}>
                    {children}
                </div>
            </div>
        );
    }
}
