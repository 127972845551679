/**
 * 获取需要添加的模块
 * Create by liaokai on 2020-04-21 15:27:41
 */
import {toPost} from "../http-utils";

export const getModules = toPost<
    {

    },
    {
        list : {
            icon : string,
            title : string,
            content : string,
            qrImg : string
        }[]
    }
>("")
