/**
 * 文档列表
 * Create by liaokai on 2020-05-29 17:27:08
 */
import * as React from 'react';
import {Component} from "react";
import {docIndex, IDocIndex} from "../../../../../../common/apis/apis/live/docIndex";
import {observer} from "mobx-react";
import {Button, message, Modal, Table} from "antd";
import {common} from "../../../../../../common/common";
import {liveStore} from "../../menu/live.store";
import {uploadFile} from "../../../../../../common/apis/apis/upload-file";
import {FlexJustifyEnd} from "../../../../../../components/style/flex/flex-justify-end";
import {LoadingSingleFile} from "../../../../../../components/loading-single-file";
import {Air} from "../../../../../../components/air/Air";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons/lib";
import {Flex} from "../../../../../../components/style/flex/flex";
import {FlexAlignCenter} from "../../../../../../components/style/flex/flex-align-center";
import { publishDoc } from '../../../../../../common/apis/apis/live/publishDoc';

interface IProps{
    visiable : boolean
    onCancel? : () => void
    onSelect? : (item : IDocIndex) => void
}

interface IState{
    dataSource : IDocIndex[],
    currentPage : number,
    total : number,
    pageSize: number,
    loading : boolean,
    uploading : boolean
}

@observer
export class DocListSelect extends Component<IProps, IState> {
    readonly state: IState = {
        
        dataSource: [],
        pageSize: 5,
        total: 0,
        currentPage: 1,
        loading: false,
        uploading: false,
    };

    reqTableData(page: number = 1) {
        this.setState({ loading: true });
        docIndex({
            live_id: liveStore.live_id,
            page,
            paginate: this.state.pageSize,
        })
            .then((res) => {
                this.setState({
                    dataSource: res.data,
                    total: res.total,
                    currentPage: page,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    componentDidMount(): void {
        this.reqTableData(1);
    }

    onCropedImage = async (result: any): Promise<boolean> => {
        let formdata = new FormData();
        formdata.append("file", result);
        formdata.append("live_id", liveStore.live_id);
        try {
            this.setState({ uploading: true });
            let res = await uploadFile(formdata, "/merchant/live/docStore");
            this.reqTableData(this.state.currentPage);
            return !!res;
        } catch (err) {
            message.error("服务器错误！");
            return false;
        } finally {
            this.setState({ uploading: false });
        }
    };

    render() {
        let { visiable, onCancel, onSelect } = this.props;
        let {
            dataSource,
            pageSize,
            total,
            currentPage,
            loading,
            uploading,
        } = this.state;
        const columns = [
            {
                title: "文档名称",
                dataIndex: "origin_name",
                key: "origin_name",
                render: (text: boolean) => text,
            },
            {
                title: "上传日期",
                dataIndex: "create_time",
                key: "create_time",
                render: (text: string) => text,
            },
            {
                title: "页数",
                dataIndex: "page",
                key: "page",
                render: (text: string) => text,
            },
            {
                title: "操作",
                dataIndex: "id",
                key: "id",
                render: (text: string, record: IDocIndex) => (
                    <div>
                        <Button
                            type="link"
                            style={{ paddingLeft: 0 }}
                            onClick={() => {
                                publishDoc({ doc_id: Number(text) })
                                    .then((res) => {
                                        message.success("操作成功!");
                                        this.reqTableData(currentPage);
                                    })
                                    .catch((err) => {
                                        message.error("操作失败！");
                                    });
                            }}
                        >
                            {record.publish_status === 1 ? "发布" : "取消发布"}
                        </Button>
                        <Button
                            style={{ paddingLeft: 0 }}
                            disabled={record.publish_status === 1}
                            type={"link"}
                            onClick={() => {
                                const { onSelect, onCancel } = this.props;
                                onSelect?.(record);
                                onCancel?.();
                            }}
                        >
                            去演讲
                        </Button>
                    </div>
                ),
            },
        ]
        return (
            <Modal
                title={"选择文档"}
                visible={visiable}
                onCancel={() => {
                    onCancel?.();
                }}
                width={"80%"}
                cancelText={"返回"}
                okText={
                    <LoadingSingleFile
                        onLoadFile={(file) => {
                            this.onCropedImage(file);
                        }}
                    >
                        <FlexAlignCenter>
                            <div>添加文档</div>
                            {uploading ? (
                                <>
                                    <Air horizontal={5} />
                                    <LoadingOutlined />
                                </>
                            ) : undefined}
                        </FlexAlignCenter>
                    </LoadingSingleFile>
                }
            >
                <div>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={dataSource}
                        emptyText={"暂无内容"}
                        pagination={{
                            defaultCurrent: 1,
                            current: currentPage,
                            pageSize: pageSize,
                            total: total,
                            onChange: (page) => {
                                this.reqTableData(page);
                                this.setState({ currentPage: page });
                            },
                        }}
                    />
                </div>
            </Modal>
        );
    }
}
