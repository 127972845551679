import React, { Component } from 'react';
import styles from './FormRow.scss';
import classNames from 'classnames';

export class FormRow extends Component {
  state = {
    focus: false,
  };

  onFocus = () => {
    this.setState({ focus: true });
  };

  onBlur = () => {
    this.setState({ focus: false });
  };

  render() {
    const { focus } = this.state;
    const {
      icon,
      title,
      button,
      buttonClick,
      pad = true,
      ...rest
    } = this.props;

    return (
      <div
        className={classNames(styles.FormRow, {
          [`${styles['FormRow-border']}`]: !!focus,
        })}
        style={{ ...(!pad ? { paddingLeft: '10px' } : {}) }}
      >
        {!!icon && (
          <div
            className={styles['FormRow-icon']}
            style={{ backgroundImage: `url(${icon})` }}
          />
        )}
        {!!title && <div className={styles['FormRow-title']}>{title}</div>}
        <input
          className={styles['FormRow-input']}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          {...rest}
        />
        {!!button && (
          <div
            className={styles['FormRow-button']}
            style={{
              backgroundColor:
                button === '发送验证码' ? 'rgba(20,133,255,1)' : '#D1D1D1',
            }}
            onClick={buttonClick}
          >
            {button}
          </div>
        )}
      </div>
    );
  }
}
export default FormRow;
