// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormRow_FormRow__32KC9{margin-bottom:15px;width:410px;height:60px;background:#fff;box-sizing:border-box;display:flex;padding:0 25px 0 25px;align-items:center;border:1px solid #fff}.FormRow_FormRow-border__3lmYt{border-color:#3b87f7}.FormRow_FormRow-icon___wh0I{width:12px;height:16px;background-repeat:no-repeat;background-size:contain;background-position:center center;margin-right:10px}.FormRow_FormRow-title__1Wj-L{font-size:16px;min-width:60px;font-family:Source Han Sans CN;font-weight:400;color:#0089ff}.FormRow_FormRow-input__Hj1Mn{display:flex;width:100%;border:0px;outline:none;cursor:pointer;font-size:16px}.FormRow_FormRow-input__Hj1Mn::-webkit-input-placeholder{font-size:14px;font-family:Source Han Sans CN;font-weight:400;color:#c4c4c4}.FormRow_FormRow-input__Hj1Mn:-ms-input-placeholder{font-size:14px;font-family:Source Han Sans CN;font-weight:400;color:#c4c4c4}.FormRow_FormRow-input__Hj1Mn::-ms-input-placeholder{font-size:14px;font-family:Source Han Sans CN;font-weight:400;color:#c4c4c4}.FormRow_FormRow-input__Hj1Mn::placeholder{font-size:14px;font-family:Source Han Sans CN;font-weight:400;color:#c4c4c4}.FormRow_FormRow-button__2X84g{cursor:pointer;height:100%;width:118px;background:#1485ff;display:flex;align-items:center;justify-content:center;font-size:15px;font-family:Source Han Sans CN;font-weight:400;color:#fff}\n", ""]);
// Exports
exports.locals = {
	"FormRow": "FormRow_FormRow__32KC9",
	"FormRow-border": "FormRow_FormRow-border__3lmYt",
	"FormRow-icon": "FormRow_FormRow-icon___wh0I",
	"FormRow-title": "FormRow_FormRow-title__1Wj-L",
	"FormRow-input": "FormRow_FormRow-input__Hj1Mn",
	"FormRow-button": "FormRow_FormRow-button__2X84g"
};
module.exports = exports;
