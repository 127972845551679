/**
 * session持久化
 * Create by liaokai on 2020-05-18 13:58:54
 */
import {toPost} from "../http-utils";

export const ajaxAir = toPost<
    {
    },
    {

    }
>("/api/api/ajaxAir");
