import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    PlusCircleOutlined,
    CloseCircleFilled,
    ExclamationCircleOutlined,
} from "@ant-design/icons/lib";
import { Form, Input, Select, Button, Modal, message } from "antd";
import { useBoolean } from "ahooks";
import invariant from "invariant";
import ImageCropper from "../../../../../meeting-list/new-meeting/components/ImageCropper";
import { uploadFile } from "../../../../../../../common/apis/apis/upload-file";
import { FlexJustifyCenter } from "../../../../../../../components/style/flex/flex-justify-center";
import { FetchField } from "../types";
import {
    merchantLiveAdUpdate,
    merchantLiveAdShow,
    merchantLiveAdStore,
} from "../constants";
import { Mask } from "../../../../../live-list/live/menu/edit/component/mask";
import { UploadImgTipStateless } from "../../../../../live-list/live/menu/edit/component/upload-img-tip-stateless";

const AdvertModalItem = ({ type }: any) => {
    const containerStyle: any =
        type === 2
            ? {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
            }
            : {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            };
    const pcItem: any = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    };
    const imgStyle = { width: 400, marginBottom: 12 };

    return (
        <div style={containerStyle}>
            {type === 2 && (
                <>
                    <div style={pcItem}>
                        <img
                            src={`https://net.huanmusic.com/meet/1.png`}
                            style={imgStyle}
                        />
                        <span>PC端大屏广告示例</span>
                    </div>
                    <div style={{ ...pcItem, marginLeft: 100 }}>
                        <img
                            src={`https://net.huanmusic.com/meet/2.png`}
                            style={{ ...imgStyle, width: 250 }}
                        />
                        <span>手机端大屏广告示例</span>
                    </div>
                </>
            )}
            {type === 1 && (
                <>
                    <div style={pcItem}>
                        <div>
                            <img
                                src={`https://net.huanmusic.com/meet/3.png`}
                                style={imgStyle}
                            />
                            <img
                                src={`https://net.huanmusic.com/meet/7.png`}
                                style={{ ...imgStyle, marginLeft: 24 }}
                            />
                        </div>
                        <span>PC端小图广告示例</span>
                    </div>
                    <div style={{ ...pcItem, marginTop: 40 }}>
                        <div>
                            <img
                                src={`https://net.huanmusic.com/meet/4.png`}
                                style={{ ...imgStyle, width: 250 }}
                            />
                            <img
                                src={`https://net.huanmusic.com/meet/5.png`}
                                style={{
                                    ...imgStyle,
                                    width: 250,
                                    marginLeft: 24,
                                }}
                            />
                        </div>
                        <span>手机端小图广告示例</span>
                    </div>
                </>
            )}
        </div>
    );
};

const { Option } = Select;

const imageConfig = {
    full: {
        pc: {
            width: 1200 / 3, // 1200
            height: 675 / 3, // 675
        },
        mobile: {
            width: 422 / 3, // 422
            height: 750 / 3, // 750
        },
    },
    bottom: {
        width: 750 / 2,
        height: 170 / 2,
    },
    swiper: {
        icon: {
            width: 150,
            height: 150,
        },
        images: {
            width: 750 / 2,
            height: 422 / 2,
        },
    },
};

interface State {
    advertisementType: number;
    advertisementImages: {
        pcBigImage: any;
        mobileBigImage: any;
        icon: any;
        swiperImage: any[];
        mobileBottomImage: any;
    };
}

function AdvertisementAdd(props: any) {
    const [form] = Form.useForm();
    const history = useHistory();

    const initState: State = {
        advertisementType: 2,
        advertisementImages: {
            pcBigImage: "",
            mobileBigImage: "",
            icon: "",
            swiperImage: [],
            mobileBottomImage: '',
        },
    };

    const [visible, { toggle, setFalse, setTrue }] = useBoolean(false);
    const [advertisementType, setAdvertisementType] = useState(
        initState.advertisementType
    );
    const [advertisementImages, setAdvertisementImages] = useState(
        initState.advertisementImages
    );

    useEffect(() => {
        form.setFieldsValue({
            type: 2
        });
    }, []);

    useEffect(() => {
        if (!!props.match.params.id) {
            merchantLiveAdShow({ id: props.match.params.id }).then(
                (response: any) => {
                    console.log("response", response);
                    setAdvertisementType(Number(response.type));
                    form.setFieldsValue({
                        title: response.title,
                        type: Number(response.type),
                    });

                    setAdvertisementImages((prevImages) => {
                        return {
                            ...prevImages,
                            pcBigImage: response.pc_big_img,
                            mobileBigImage: response.big_img,
                            mobileBottomImage: response.big_img,
                            icon: response.pc_icon,
                            swiperImage: response.pc_slideshow,
                        };
                    });
                }
            );
        }
    }, []);

    const onDeleteSwiperImage = (index: number) => {
        const { swiperImage } = advertisementImages;
        const newSwiperImage = swiperImage;
        newSwiperImage.splice(index, 1);
        setAdvertisementImages({
            ...advertisementImages,
            swiperImage: newSwiperImage,
        });
    };

    const onCropedImage = async (result: any, key: string, index?: number) => {
        let formdata = new FormData();
        formdata.append("file", result);
        formdata.append("type", "base64");
        try {
            const response = await uploadFile(formdata);
            if (key === "swiperImage") {
                console.log("response", response);

                if (typeof index === "number") {
                    setAdvertisementImages((prevImages) => {
                        const newImages = prevImages.swiperImage;
                        newImages.splice(index, 1, response);
                        console.log("newImages", newImages);
                        return {
                            ...prevImages,
                            [key]: newImages,
                        };
                    });
                    return true;
                }
                setAdvertisementImages({
                    ...advertisementImages,
                    [key]: advertisementImages.swiperImage.concat(response),
                });
                return !!response;
            }
            setAdvertisementImages({
                ...advertisementImages,
                [key]: response,
            });
            return !!response;
        } catch (err) {
            message.error("服务器错误！");
            return false;
        }
    };

    const onFinish = (values: any) => {
        try {
            const isEdit = !!props.match.params.id;
            const fetchFunction = isEdit
                ? merchantLiveAdUpdate
                : merchantLiveAdStore;
            
            const { mobileBigImage, mobileBottomImage } = advertisementImages;

            if (!mobileBigImage && !mobileBottomImage) {
                message.error('请上传图片');
                return;
            }
            
            const payload: FetchField.MerchantLiveAdStoreEdit = {
                id: props.match.params.id,
                meetid: props.match.params.meeting_id,
                title: values.title,
                type: values.type,
                // pc_big_img: pcBigImage,
                big_img: values.type === 2 ? mobileBigImage : mobileBottomImage,
            } as any;
            console.log("payload", payload);
            fetchFunction(payload)
                .then((response) => {
                    console.log("response", response);
                    message.success(
                        `${!!isEdit ? "修改" : "新增"}广告成功！`
                    );
                    history.goBack();
                })
                .catch((error) => {
                    message.error(error.message);
                });
        } catch (error) {
            message.error(error.message);
        }
    };

    const FloatView = ({ width, height, theme }: any) => (
        <div
            style={{
                width,
                height,
                border: "1px solid #e3e3e3",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: theme === "light" ? "#999" : "#eee",
            }}
        >
            <PlusCircleOutlined style={{ fontSize: 50 }} />
        </div>
    );
    const ImageCropperContainer = (config: any, key: any) => {
        const currentImage: any = (advertisementImages as any)[key];
        const isEqual: boolean = config.width === config.height;
        const isHeight: boolean = config.height > config.width;

        const baseProps = key === 'mobileBottomImage' 
            ? {
                width: 4.4,
                height: 1
            } 
            : {
                width: isEqual ? 1 : isHeight ? 9 : 16,
                height: isEqual ? 1 : isHeight ? 16 : 9,
            };

        return (
            <div
                style={{
                    position: "relative",
                    width: `${config.width}px`,
                    height: `${config.height}px`,
                    marginLeft: key !== "swiperImage" ? 80 : "",
                    marginBottom: 20,
                }}
            >
                <ImageCropper
                    text={
                        <Mask
                            floatView={
                                currentImage &&
                                FloatView({ ...config, theme: "dark" })
                            }
                        >
                            {currentImage ? (
                                <FlexJustifyCenter style={{ ...config }}>
                                    <img
                                        style={{
                                            maxWidth: `${config.width}px`,
                                            maxHeight: `${config.height}px`,
                                            width: config.width,
                                            height: config.height,
                                        }}
                                        src={currentImage}
                                        alt=""
                                    />
                                </FlexJustifyCenter>
                            ) : (
                                    FloatView(config)
                                )}
                        </Mask>
                    }
                    {...baseProps}
                    onUpdate={(result: any) => onCropedImage(result, key)}
                />
            </div>
        );
    };

    const isEdit = !!props.match.params.id;
    return (
        <div>
            <Form form={form} onFinish={onFinish}>
                <Form.Item
                    label="广告名称"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "请输入广告名称!",
                        },
                    ]}
                >
                    <Input style={{ width: 400 }} />
                </Form.Item>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <Form.Item
                        label="广告位置"
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: "请选择广告位置!",
                            },
                        ]}
                    >
                        <Select
                            placeholder="请选择广告位置"
                            style={{ width: 400 }}
                            disabled={isEdit}
                            onChange={(value) => {
                                console.log("value", value);
                                setAdvertisementType(Number(value));
                            }}
                        >
                            <Option value={2}>全屏</Option>
                            <Option value={3}>底部</Option>
                        </Select>
                    </Form.Item>
                    <div
                        style={{ marginLeft: 10, marginBottom: 24 }}
                        onClick={() => {
                            if (
                                advertisementType !== 1 &&
                                advertisementType !== 2 && 
                                advertisementType !== 3
                            ) {
                                message.warn("请先选择广告类型！");
                                return;
                            }
                            setTrue();
                        }}
                    >
                        <ExclamationCircleOutlined />
                        <span style={{ marginLeft: 4 }}>查看显示示例</span>
                    </div>
                </div>

                {Number(advertisementType) === 2 && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                        }}
                    >
                        {ImageCropperContainer(
                            imageConfig.full.mobile,
                            "mobileBigImage"
                        )}
                        <div style={{ margin: "0 0 20px 20px" }}>
                            手机端 （支持jpg，png，gif图片，图片大小：2M以内）
                        </div>
                    </div>
                )}
                {Number(advertisementType) === 3 && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                        }}
                    >
                        {ImageCropperContainer(
                            imageConfig.bottom,
                            "mobileBottomImage"
                        )}
                        <div style={{ margin: "0 0 20px 20px" }}>
                            手机端 （支持jpg，png，gif图片，图片大小：2M以内）
                        </div>
                    </div>
                )}

                <Form.Item style={{ marginTop: 12, marginLeft: 80 }}>
                    <Button type="primary" htmlType="submit">
                        保存
                    </Button>
                    <Button
                        onClick={() => history.goBack()}
                        style={{ marginLeft: 24 }}
                    >
                        取消
                    </Button>
                </Form.Item>
            </Form>

            <Modal
                visible={visible}
                // centered={true}
                width={1000}
                onCancel={() => toggle(false)}
                maskClosable={true}
                title="显示示例"
                footer={null}
            >
                <AdvertModalItem type={advertisementType} />
            </Modal>
        </div>
    );
}
export { AdvertisementAdd };
