/**
 * 邮件模版-列表
 * Create by liaokai on 2020-04-21 17:49:30
 */
import * as React from 'react';
import {Component} from "react";
import {Button, message, Table} from "antd";
import {RouteComponentProps} from "react-router";
import {ColumnsType} from "antd/lib/table/interface";
import {apis} from "../../../../../../../../common/apis/apis";
import {emailSmsTemplateList} from "../../../../../../../../common/apis/apis/email/emailTemplateList";
import {emailTemplateDestroy} from "../../../../../../../../common/apis/apis/email/emailTemplateDestroy";
import {EmailTemplateDestroy} from "../../../../../../email/menu/overview/email-template-destroy";

interface IProps extends RouteComponentProps{

}

interface IState{
    columns : ColumnsType<any>,
    showDelModal : boolean,
    id : number,
    listData : ISMSTemplateItem[],
    current : number
    total : number,
    pageSize : number,
}


interface ISMSTemplateItem {
    index : undefined,          //序号（仅占位）
    templateTitle: string,      //模版名
    createTime: string,         //创建时间
    operation: undefined,       //操作（仅占位）
    id : number
}


export class EmailTemplateTable extends Component<IProps,IState>{
    readonly state : IState = {
        showDelModal : false,
        id:0,
        columns : [
            {
                title: '编号',
                dataIndex: 'index',
                key: 'index',
                render: (text : boolean, record : ISMSTemplateItem, index : number) => (this.state.pageSize * (this.state.current-1) + index + 1).toString(),
            },
            {
                title: '模版标题',
                dataIndex: 'templateTitle',
                key: 'templateTitle',
                render: (text: string) => text,
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
                render: (text: string) => text,
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                render: (text: string, record : ISMSTemplateItem, index : number) => <div>
                    <Button
                        type={"link"}
                        style={{padding : 0, marginRight: 10}}
                        onClick={()=>{
                            let {location: {pathname}} = this.props;
                            window.location.hash = `${pathname}/edit_email_template/${record.id}`
                        }}
                    >
                        编辑模板
                    </Button>
                    {/*<Button*/}
                    {/*    type={"link"}*/}
                    {/*    style={{padding : 0, marginRight: 10}}*/}
                    {/*    onClick={()=>{*/}
                    {/*        let {location: {pathname}} = this.props;*/}
                    {/*        window.location.hash = `${pathname}/email_send/${record.id}`*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    群发*/}
                    {/*</Button>*/}
                    <Button
                        type={"link"}
                        style={{padding : 0, marginRight: 10}}
                        onClick={()=>{
                            // apis.emailTemplateDestroy({
                            //     id : record.id
                            // }).then(res => {
                            //     this.reqTableData(this.state.current)
                            //     message.success("删除完成")
                            // }).catch(e => {
                            //     message.error(`删除失败 ${e?.message ?? ""}`)
                            // })
                            this.setState({showDelModal:true,id:record.id})
                        }}
                    >
                        删除
                    </Button>
                </div>,
            },
        ],
        listData : [],
        pageSize : 5,
        total : 0,
        current : 1
    };
    refreshPage = ()=>{
        this.reqTableData(this.state.current)
    }
    reqTableData(page : number){
        let {match} = this.props;
        apis.emailSmsTemplateList({meetid : parseInt((match as any)?.params?.meeting_id), page : page}).then(res => {
            this.setState({
                pageSize : res.per_page,
                total : res.total,
                listData : res.data.map(value => {
                    return {
                        index : undefined,
                        templateTitle : value.title,
                        createTime : value.create_time,
                        operation : undefined,
                        id : value.id
                    }
                })
            },()=>{
                if(!this.state?.listData?.length){
                    if(this.state.current > 1){
                        this.reqTableData(1)
                        this.setState({current : 1})
                    }
                }
            })
        })
    }

    componentDidMount(): void {
        this.reqTableData(1)
    }

    render() {
        let {} = this.props;
        let {columns, listData, pageSize, total, current,showDelModal,id} = this.state;
        return (
            <>
            <Table
                columns={columns}
                dataSource={listData}
                emptyText={"暂无内容"}
                pagination={{
                    defaultCurrent : 1,
                    current : current,
                    pageSize : pageSize,
                    total : total,
                    onChange : page => {
                        this.reqTableData(page);
                        this.setState({current : page})
                    }
                }}
            />
            <EmailTemplateDestroy
                visible={showDelModal}
                id={id}
                onSubmit={(msg:string)=>{
                    this.setState({showDelModal:false})
                    message.success(msg)
                    this.refreshPage()
                }}
                onCancel={()=>{
                    this.setState({showDelModal:false})
                    this.refreshPage()
                }}
            >
            </EmailTemplateDestroy>
            </>
        );
    }
}
