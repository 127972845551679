/**
 * 直播回放列表
 * Create by liaokai on 2020-06-11 14:25:15
 */
import {toPost} from "../../http-utils";

export interface IliveRecordIndexItem {
    "id": number,
    "title": string,
    "publish_status": string,
    "poster": string,
    "run_time": string
    "expiration_time": string, //过期时间
    "status": string, //状态 1 代表回放
    tanscode_status:1|2|3|0,//转码状态  1转码中2转码成功3转码失败
    "is_default": boolean, //是否默认
    "create_time": string, // 创建时间
}

export const liveRecordIndex = toPost<
    {
        live_id : string,
        page : number
        paginate? : number
    },
    {
        "current_page": number,
        "total": number,
        "last_page": number,
        "data": IliveRecordIndexItem[]
    }
>("/merchant/live/liveRecordIndex");

export const delVideo = toPost<
    {
        id : number,
    },
    {
      code:number
    }
>("/merchant/live/delVideo");

