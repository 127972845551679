/**
 * 更新问答
 * Create by liaokai on 2020-05-28 13:21:48
 */
import {toPost} from "../../http-utils";

export const questionUpdate = toPost<
    {
        id : string
        title : string,
        type : string,
        content : {
            title : string
            value : string
        }[],
        live_id : string
    },
    any
    >("/merchant/live/questionUpdate");

