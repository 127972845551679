/**
 * 获取注册表单字段
 * Create by liaokai on 2020-04-03 11:26:53
 */
import {toPost} from "../http-utils";
import {tFormTypes} from "../../../routes/index/meeting-list/meeting/menu/form/form-preview/form-preview";

export const getRegisterField = toPost<
    {
        meetid : number
    },
    {
        "list":{
            "system":{
                "id":number,
                "en_name": string,
                "zh_name": string,
                "type": tFormTypes,
                "check": string,
                "mid":number,
                "is_required":number,
                "is_check":number
            }[]
            ,
            "myself":{
                "id":number,
                "en_name": string,
                "zh_name": string,
                "type": tFormTypes,
                "check": string,
                "mid":number,
                "is_required":number,
                "is_check":number
            }[]
        }
    }
>
("/merchant/meeting/getRegisterField");
