import { toPost } from "../../../../../../../common/apis/http-utils";
import { AdvertisementItem, FetchField, AdvertisementDetail } from "../types";

export const formatListResult = (result: any): any => {
    console.log("formatListResult", result);
    const mergeResult = result;
    return {
        list: mergeResult.data,
        total: mergeResult.total,
    };
};

export const merchantLiveAdList = toPost<
    FetchField.MerchantLiveAdList,
    AdvertisementItem[]
>(`/merchant/meeting/adList`);

export const merchantLiveAdStore = toPost<FetchField.MerchantLiveAdStore, any>(
    "/merchant/meeting/adStore"
);

export const merchantLiveAdUpdate = toPost<
    FetchField.MerchantLiveAdStoreEdit,
    any
>("/merchant/meeting/adUpdate");

export const merchantLiveAdSet = toPost<{ id: string }, any>(
    "/merchant/meeting/adSet"
);

export const merchantLiveAdShow = toPost<
    FetchField.MerchantLiveAdShow,
    AdvertisementDetail
>("/merchant/meeting/adShow");

export const merchantLiveAdDestroy = toPost<
    FetchField.MerchantLiveAdShow,
    AdvertisementDetail
>("/merchant/meeting/adDestroy");