/**
 * 直播间绑定
 * Create by liaokai on 2020-06-15 14:32:47
 */
import {toPost} from "../../http-utils";

export const bindLive = toPost<
    {
        current_live_id: string,
        bind_live_id : string[]
    },
    {

    }
>("/merchant/live/bindLive");

