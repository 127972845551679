/**
 * 直播编辑
 * Create by liaokai on 2020-05-23 17:57:00
 */
import * as React from "react";
import { Component } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { Texts } from "../../../../../../components/text/Texts";
import { TitleContentTable } from "../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import { Button, Card, DatePicker, Input, message, Switch, Spin } from "antd";
import { Flex } from "../../../../../../components/style/flex/flex";
import { FlexJustifyCenter } from "../../../../../../components/style/flex/flex-justify-center";
import { Air } from "../../../../../../components/air/Air";
import "moment/locale/zh-cn";
import { liveStore } from "../live.store";
import { RichTextBoxV2 } from "../../../../../../components/RichTextBox/RichTextBoxV2";
import BraftEditor from "braft-editor";
import invariant from 'invariant';
import { liveInfoAdd, liveInfoUpdate, liveInfoShow } from './constants'

export default function (props: any) {
  const editId = props.match.params.editId;
  const history = useHistory();
  const [title, setTitle] = React.useState('');
  const [sort, setSort] = React.useState('');
  const [status, setStatus] = React.useState(true);

  const [content, setContent] = React.useState(
    // 创建一个空的editorState作为初始值
    BraftEditor.createEditorState(null)
  )

  const isEdit = !!editId;

  React.useEffect(() => {
    if (!!editId) {
      liveInfoShow({ id: editId })
        .then((result: any) => {
          setTitle(result.title)
          setContent(BraftEditor.createEditorState(result.content))
          setSort(result.sort)
          setStatus(result.status === 1 ? true : false)
        })
    }
  }, [editId]);

  const onSubmit = () => {
    try {
      invariant(!!title, '请输入标题');
      invariant(!!content, '请设置内容');
      invariant(!!sort, '排序必填');
      const fetchFunction = isEdit ? liveInfoUpdate : liveInfoAdd;
      const payload = {
        ...isEdit ? { id: editId } : {},
        live_id: liveStore.live_id,
        title,
        sort:sort,
        content: content.toHTML(),
        status: status === true ? 1 : 0,
      };

      fetchFunction(payload)
        .then((result: any) => {
          message.success(isEdit ? '修改成功' : '新增成功！')
          console.log('result', result);
          history.goBack();
        })
        .catch((error) => {
          console.log('error', error);
          message.error(error.data?.result);
        })
    } catch (error) {
      message.error(error.message);
    }

  }

  return (
    <div>
      <Flex>
        <TitleContentTable rowGap={15} colGap={20}>
          <div>*标题</div>
          <Input
            maxLength={8}
            placeholder='请输入信息标题（最多4字）'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <div>*直播简介</div>
          <Card bordered={true}>
            <RichTextBoxV2
              value={content}
              onChange={(text) => setContent(text)}
            />
          </Card>

          <div>排序</div>
          <Input
              type={"number"}
            value={sort}
            placeholder='请输入信息排序'
            onChange={e => setSort(e.target.value)}
          />

          <div>状态</div>
          <div style={{ display: 'flex' }}>
            <Switch
              checked={status}
              onChange={e => setStatus(e)}
            />
          </div>
        </TitleContentTable>
      </Flex>
      <Air vertical={30} />
      <FlexJustifyCenter>
        <Button
          onClick={() => {
            history.goBack();
          }}
        >
          取消
        </Button>
        <Air horizontal={10} />
        <Button type={"primary"} onClick={onSubmit}>
          保存
        </Button>
      </FlexJustifyCenter>
    </div >
  )
}