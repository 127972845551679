import React, { Component } from 'react'
import style from './register-form.scss'
import { Affix, Switch, message, Modal, Spin } from "antd";
import { IphoneSimulator } from "../../../../../../components/iphone-simulator/iphone-simulator";
import { FormPreview } from "./form-preview/form-preview";
import { FormTable, ITableRowItem } from "./form-table/form-table";
import { RouteComponentProps } from "react-router";
import { RegisterFormContext } from "./register-form-context";
import { apis } from "../../../../../../common/apis/apis";
import { FlexJustifyEnd } from '../../../../../../components/style/flex/flex-justify-end';
import { Air } from '../../../../../../components/air/Air';
import { meetingShowSet, meetingShowList, meetingShowSetInfo } from '../hotel/constants';


interface IProps extends RouteComponentProps<any> {
}

interface IState {
    input: string,
    showDropItems: boolean
    tableData: ITableRowItem[]
    visible: boolean;
}


export class RegisterForm extends Component<IProps, IState> {
    readonly state: IState = {
        input: "",
        showDropItems: false,
        tableData: [],
        visible: false,
    };

    constructor(props: IProps) {
        super(props,);
    }

    componentDidMount() {
        let meetingId = this.props.match.params.meeting_id;
        meetingShowSetInfo({ meetid: meetingId, type: 1 })
            .then((result) => {
                this.setState({ visible: result.is_show });
            })
    }

    // 加入提示信息
    onChangeSwitch = (e: any) => {
        let meetingId = this.props.match.params.meeting_id;
        if (e.valueOf() === false) {
            Modal.confirm({
                title: "提示",
                content: (
                    <div>
                        您关闭表单将：
                        <p>1）自动关闭“会议信息”内所有需要注册的选项</p>
                        <p>2）自动关闭“行程”，用户将无法选择行程</p>
                        <p>3）自动关闭“接送机”，用户将无法选择接送机</p>
                        <p>4）自动关闭“酒店”，用户将无法选择酒店</p>
                        确定要关闭吗？
                    </div>
                ),
                okText: "确定 ",
                cancelText: "取消",
                onOk: () => {
                    this.setState({ visible: e.valueOf() }, () => {
                        meetingShowSet({
                            meetid: meetingId,
                            type: 1,
                            is_show: e.valueOf() === true ? 1 : 2
                        } as any).then(res => {
                            this.setState({ visible: res.is_show })
                        }).catch((e) => {
                            this.setState({ visible: !this.state.visible })
                            message.error("保存失败!" + e.data.message)
                        })
                    })
                }
            })
        } else {
            this.setState({ visible: e.valueOf() }, () => {
                meetingShowSet({
                    meetid: meetingId,
                    type: 1,
                    is_show: e.valueOf() === true ? 1 : 2
                } as any).then(res => {
                    this.setState({ visible: res.is_show })
                }).catch((e) => {
                    this.setState({ visible: !this.state.visible })
                    message.error("保存失败!" + e.data.message)
                })
            })
        }
    }

    render() {
        let { } = this.props;

        let { tableData, visible } = this.state;
        let meetingId = this.props.match.params.meeting_id;
        return (
            <RegisterFormContext.Provider
                value={{
                    meetingId: meetingId,
                }}
            >
                <div className={style.container}>
                    {/*<div className={style.header}>*/}
                    {/*    注册表单*/}
                    {/*</div>*/}
                    <FlexJustifyEnd>
                        <Switch
                            checked={visible}
                            onChange={this.onChangeSwitch.bind(this)}
                        />
                        <Air horizontal={5} />
                        <span>启用表单</span>
                    </FlexJustifyEnd>
                    <Air vertical={5} />
                    <Spin spinning={!visible} indicator={<div />} tip='尚未开启表单'>
                        <div className={style.content}>
                            <Affix offsetTop={60}>
                                <IphoneSimulator width={280}>
                                    <div style={{ padding: "15px" }}>
                                        <FormPreview
                                            data={
                                                tableData.map((value) => {
                                                    return {
                                                        title: value.title,
                                                        type: value.type,
                                                        mandatory:
                                                            value.mandatory,
                                                    };
                                                }) as any[]
                                            }
                                        />
                                    </div>
                                </IphoneSimulator>
                            </Affix>
                            <div className={style.config}>
                                <FormTable
                                    onChange={async (data) => {
                                        this.setState({ tableData: data });
                                        try {
                                            await apis.setMeetingRegisterField({
                                                meetid: meetingId,
                                                fieldes: data.map((value) => {
                                                    return {
                                                        name: value.ruleKey,
                                                        is_required: value.mandatory
                                                            ? 1
                                                            : 0,
                                                    };
                                                }),
                                            });
                                        } catch (e) {
                                            console.log(
                                                "error > apis.setMeetingRegisterField"
                                            );
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </Spin>
                </div>
            </RegisterFormContext.Provider>
        );
    }
}
