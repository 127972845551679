/**
 * 启用关闭直播设置
 * Create by yangyile on 2020-08-04 15:15:31
 */
import {toPost} from "../../http-utils";

export const enableShowSet = toPost<
    {
      live_id: string,
      type: Number
    },
    {
        is_show: boolean,
        type: Number
    }
>("/merchant/live/enableShowSet");