// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".iphone-simulator_container__ZESou{position:relative}.iphone-simulator_container__ZESou .iphone-simulator_iphoneBorderBox__3QmBN{max-width:100%;max-height:100%;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-user-drag:none;z-index:4}.iphone-simulator_container__ZESou .iphone-simulator_content__2qfuc{display:flex;flex-direction:column;overflow-y:auto;position:absolute;left:0px;right:0px;top:0px;bottom:0px;z-index:3}\n", ""]);
// Exports
exports.locals = {
	"container": "iphone-simulator_container__ZESou",
	"iphoneBorderBox": "iphone-simulator_iphoneBorderBox__3QmBN",
	"content": "iphone-simulator_content__2qfuc"
};
module.exports = exports;
