/**
 * 获取系统//1 meeting_list	会议管理系统
 //2	live_list 直播管理系统
 //3	 email 邮件管理系统
 //4	sms 短信管理系统
 * Create by liaokai on 2020-05-14 17:13:22
 */
import {toPost} from "../http-utils";

export const getSys = toPost<
    {

    },
    {
       sys:string
    }[]
>("/api/api/getSys");
