/**
 * 直播间
 * Create by liaokai on 2020-05-26 10:36:40
 */
import * as React from 'react';
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Responsive } from "../../../../../components/common/responsive";
import { Flex } from "../../../../../components/style/flex/flex";
import { Flex1 } from "../../../../../components/style/flex/flex-1";
import { FlexCol } from "../../../../../components/style/flex/flex-col";
import styled from "styled-components";
import { Button, Drawer, Input, Tag, Comment, Avatar, Tooltip, message, Modal, Radio as AndRadio, Alert } from "antd";
import {
    AppstoreOutlined,
    ArrowLeftOutlined,
    CoffeeOutlined,
    ExclamationCircleOutlined,
    PlusOutlined,
    SettingOutlined
} from "@ant-design/icons/lib";
import { FlexJustifyBetween } from "../../../../../components/style/flex/flex-justify-between";
import { common } from "../../../../../common/common";
import { Link } from "react-router-dom";
import { FlexColHorCenter } from "../../../../../components/style/flex/flex-col-hor-center";
//@ts-ignore
// import swf from "rtmp-streamer/RtmpStreamer.swf"
import swf from "../../../../../assets/RtmpStreamer.swf"
import { SmallPic } from "./component/small-pic";
import moment from "moment"
import { Mask } from "../menu/edit/component/mask";
import { FlexColVerCenter } from "../../../../../components/style/flex/flex-col-ver-center";
import { Air } from "../../../../../components/air/Air";
import { Radio } from "../../../../../components/common/radio";
import { LoadingSingleFile } from "../../../../../components/loading-single-file";
import { liveStore } from "../menu/live.store";
import { uploadFile } from "../../../../../common/apis/apis/upload-file";
import logoGray from "../../../../../assets/logo-gray.png"
import { Texts } from "../../../../../components/text/Texts";
import { FlexJustifyAlignCenter } from "../../../../../components/style/flex/flex-justify-align-center";
import { TitleContentTable } from "../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import { authentication } from "../../../../../common/apis/apis/live/authentication";
import { sendMessage } from "../../../../../common/apis/apis/live/sendMessage";
import { pushNotify } from "../../../../../common/apis/apis/live/pushNotify";
import { endLive } from "../../../../../common/apis/apis/live/endLive";
import { DocListSelect } from "./component/doc-list-select";
import { docShow, IDocShowItem } from "../../../../../common/apis/apis/live/docShow";
import { bindClient } from "../../../../../common/apis/apis/live/bindClient";
import { setLiveLookBack } from "../../../../../common/apis/apis/live/setLiveLookBack"; // 设置回看
import { useDoc } from "../../../../../common/apis/apis/live/useDoc";
import { FlexAlignCenter } from "../../../../../components/style/flex/flex-align-center";
import { LiveRoomTitle } from "./component/title";
import { LivePlugins } from "./plugins/plugins";
//@ts-ignore
import { Steps, Hints } from 'intro.js-react';
import 'intro.js/introjs.css';
import { PermissionsCheck } from "./component/permissions-check";
import {LiveSetRecord} from "../../../../../common/apis/apis/live/liveSetRecord";

const { confirm, info } = Modal;

interface IChat {
    "name": string,
    "message": string,
    "create_time": string,
    "headimgurl"?: string
}

interface IProps extends RouteComponentProps {

}

interface IState {
    address: string,   //推流地址前半段
    name: string,      //推流地址后半段
    drawer: boolean,   //展示抽屉
    living: boolean    //正在直播
    bigImg: string     //大图url
    showVideoSetting: boolean, //显示视频设置modal
    showSelectDoc: boolean     //显示选择文档modal
    messageInput: string,      //聊天输入
    smallPicList: IDocShowItem[],    //缩略图列表
    chatList: IChat[],
    showIntro: boolean,
    showPermissionsCheck: boolean,
    permissionPass: boolean,    //权限检测通过
    isLookBack: boolean, //是否设置默认回看
    liveStartTime:number // 直播开始时间
}

interface IWsData<T> {
    "code": number,
    "action": "bind",
    "status": boolean,
    "message": string,
    "data": T
}

export class Live_room extends Component<IProps, IState>{
    readonly state: IState = {
        address: "",
        name: "",
        drawer: false,
        living: false,
        bigImg: "",
        showVideoSetting: false,
        showSelectDoc: false,
        messageInput: "",
        smallPicList: [],
        chatList: [
            // {
            //     "name":"姓名",
            //     "message":"消息内容",
            //     "create_time":"2020-20-20 20:20:20"
            // }
        ],
        showIntro: false,
        showPermissionsCheck: false,
        permissionPass: false,
        isLookBack: false,
        liveStartTime:0,
    };

    setVideoQuality = (level: number) => {
        const RtmpStreamer = require("rtmp-streamer")
        let streamer = new RtmpStreamer(document.getElementById('rtmp-streamer'));
        streamer.setCamFrameInterval(30)    //默认30帧
        switch (level) {
            case 1:
                streamer.setCamMode(640, 480, 30);    //480p
                break;
            case 2:
                streamer.setCamMode(1280, 720, 30);   //720p
                break;
            case 3:
                streamer.setCamMode(1920, 1080, 30);  //1080p
                break;
        }
    }
    private ws: WebSocket | null = null;
    chatListScroll: any = React.createRef();

    //聊天滑动到底部
    chatListToBottom = () => {
        const ele = document.getElementById("chatList")
        ele?.scrollTo?.(0, ele.scrollHeight ?? 0)
    }

    newConnect = () => {
        this.ws = new WebSocket(process.env.REACT_APP_ENV === "production" ? common.wsUrlProd : common.wsUrlDev);
        this.ws.onclose = () => {
            setTimeout(() => {
                this.newConnect()
            }, 1000)
        }
        this.ws.onmessage = (e) => {
            const packageData = JSON.parse(e.data)
            switch (packageData.action) {
                case "bind":
                    const bindData: IWsData<{ client_id: string }> = packageData;
                    bindClient({
                        live_id: liveStore.live_id,
                        client_id: bindData.data.client_id
                    }).then(res => {
                        // message.success("绑定ws完成")
                    }).catch(e => {
                        // message.error("绑定ws失败 " + e.data?.message)
                    })
                    break;
                case "message":
                    const messageData: IWsData<{
                        "name": string,
                        "message": string,
                        "create_time": string
                    }> = packageData;
                    this.setState({
                        chatList: this.state.chatList.concat(messageData.data)
                    }, () => {
                        this.chatListToBottom();
                    })
                    break;
            }
            // message.success(JSON.stringify(e.data))
        }
        this.ws.onopen = e => {
            // message.success("websocket链接成功")
        }
    }

    componentDidMount() {
        //获取、设置推流地址
        authentication({
            live_id: liveStore.live_id
        }).then(res => {
            this.setState({
                address: res.server_url,
                name: res.stream_sign
            })
        })
        this.newConnect();

        if (localStorage.getItem("live_room_intro")) {

        } else {
            this.setState({ showIntro: true })
        }
    }
    componentWillUnmount () {
        window.removeEventListener("beforeunload",()=>{});
    }
    // window.onbeforeunload (e) {
    //     e = e || window.event;
    //     if (e) {
    //         e.returnValue = '关闭提示';
    //     }

    //     // this.toCloseFun()//调用自己的方法

    //     // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
    //     return '关闭提示';
    // }
    openLiving = () => {
        let { address, name, showIntro, drawer, messageInput, living, smallPicList, bigImg, showVideoSetting, showSelectDoc, chatList, showPermissionsCheck, permissionPass } = this.state;
        if (living) {
            const RtmpStreamer = require("rtmp-streamer")
            let streamer = new RtmpStreamer(document.getElementById('rtmp-streamer'));
            streamer.disconnect();
            this.setState({ living: false })
            endLive({
                live_id: liveStore.live_id
            })
        } else {
            try {
                const RtmpStreamer = require("rtmp-streamer")
                let streamer = new RtmpStreamer(document.getElementById('rtmp-streamer'));
                streamer.publish(address, name);
                streamer.setScreenSize(270 * 1.85, 180 * 1.9)
                streamer.setCamMode(1920, 1080, 30);
                this.setState({ living: true })
                pushNotify({
                    live_id: liveStore.live_id,
                    action: "open"
                })
                window.addEventListener("beforeunload", e => {
                    console.log(e);
                    e.returnValue = true
                })
                this.setState({liveStartTime:new Date().getTime()})
            } catch (e) {
                this.setState({liveStartTime:0})
                message.error("请开启Flash")
            }
        }
    }

    render() {
        let { } = this.props;
        let { address, name, showIntro, drawer, messageInput, living, smallPicList, bigImg, showVideoSetting, showSelectDoc, chatList, showPermissionsCheck, permissionPass } = this.state;

        return (
            <Container className={"heiglight"}>
                <Steps
                    enabled={showIntro}
                    options={{
                        nextLabel: "下一个",
                        prevLabel: "上一个",
                        skipLabel: "跳过",
                        doneLabel: "知道了",
                        hidePrev: true,
                        showStepNumbers: false,
                        exitOnOverlayClick: false
                    }}
                    steps={[
                        {
                            element: '.live_room_tip_1',
                            intro: '这里选择直播PPT文档',
                        },
                        {
                            element: '.live_room_tip_4',
                            intro: '这里和用户实时互动',
                        },
                        {
                            element: '.live_room_tip_3',
                            intro: '根据带宽选择推流分辨率，建议720P/普通',
                        },
                        {
                            element: '.live_room_tip_2',
                            intro: '这里查看用户发言',
                            position: 'left',
                        },
                    ]}
                    initialStep={0}
                    onExit={() => {
                        this.setState({ showIntro: false }, () => {
                            localStorage.setItem("live_room_intro", "true")
                        })
                    }}
                />
                <Live>
                    <LeftSide>
                        <DocTitle>
                            <div>
                                <Air horizontal={50} />
                                {/*<Link to={"menu"}><ArrowLeftOutlined /> 回到管理列表</Link>*/}
                            </div>
                            <FlexColHorCenter>
                                <LiveRoomTitle />
                            </FlexColHorCenter>
                            <Flex>
                                <FlexAlignCenter>
                                    <ExclamationCircleOutlined />
                                    <Air horizontal={3} />
                                    <Tips>
                                        <div>如文档里如包含视频则无法正常使用；如文档不能正常显示文字,请将文档内的文字设置为宋体</div>
                                    </Tips>
                                    <Air horizontal={5} />
                                </FlexAlignCenter>
                                <Button className={"live_room_tip_1"} type={"primary"} onClick={() => {
                                    this.setState({ showSelectDoc: true })
                                }}>选择文档</Button>
                            </Flex>
                        </DocTitle>
                        <PPT>
                            <Thumbnail>
                                {
                                    !smallPicList.length ?
                                        <Alert message="请先选择文档" type="info" />
                                        : undefined
                                }
                                <Radio data={smallPicList.map((value, index) => {
                                    return {
                                        data: value,
                                        index: (index + 1).toString()
                                    }
                                })} renderUnSelectItem={(item) => (
                                    <SmallPic src={item.data.img_url} index={item.index} />
                                )} renderSelectedItem={(item: any) => (
                                    <SmallPic src={item.data.img_url} index={item.index} focus={true} />
                                )} initSelectIndex={0} onSelect={(item) => {
                                    this.setState({ bigImg: item.data.img_url })
                                    //切换ppt
                                    useDoc({
                                        doc_id: item.data.live_doc_id.toString(),
                                        doc_son_id: item.data.id.toString(),
                                        live_id:liveStore.live_id
                                    })
                                }}>
                                </Radio>
                            </Thumbnail>
                            <Blackboard>
                                <BigPic src={bigImg} />
                            </Blackboard>
                        </PPT>
                    </LeftSide>
                    <RightSide>
                        <div>
                            {/*<Mask type={"static"} backgroundColor={"gray"} floatView={<MaskFloat>*/}
                            {/*    <CoffeeOutlined style={{ fontSize: 40 }} />*/}
                            {/*    <Air vertical={5} />*/}
                            {/*    直播未开始*/}
                            {/*</MaskFloat>} showMask={!living}>*/}
                            {/*    <object>*/}
                            {/*        <embed*/}
                            {/*            id="rtmp-streamer"*/}
                            {/*            src={swf}*/}
                            {/*            // @ts-ignore*/}
                            {/*            quality="high"*/}
                            {/*            width="356px"*/}
                            {/*            height="200px"*/}
                            {/*            allowscriptaccess="sameDomain"*/}
                            {/*            type="application/x-shockwave-flash"*/}
                            {/*        />*/}
                            {/*    </object>*/}
                            {/*</Mask>*/}
                        </div>
                        <Chat>
                            {/*<ChatRecord className={"live_room_tip_2"} ref={this.chatListScroll} id={"chatList"}>*/}
                            {/*    {*/}
                            {/*        chatList.length ?*/}
                            {/*            chatList.map(value => (*/}
                            {/*                <Comment*/}
                            {/*                    actions={[]}*/}
                            {/*                    author={<a>{value.name}</a>}*/}
                            {/*                    avatar={*/}
                            {/*                        value.headimgurl ?*/}
                            {/*                            <Avatar*/}
                            {/*                                src={value.headimgurl}*/}
                            {/*                                alt={value.name}*/}
                            {/*                            />*/}
                            {/*                            : undefined*/}
                            {/*                    }*/}
                            {/*                    content={*/}
                            {/*                        <p>*/}
                            {/*                            {value.message}*/}
                            {/*                        </p>*/}
                            {/*                    }*/}
                            {/*                    datetime={*/}
                            {/*                        <Tooltip title={value.create_time}>*/}
                            {/*                            <span>{value.create_time}</span>*/}
                            {/*                        </Tooltip>*/}
                            {/*                    }*/}
                            {/*                />*/}
                            {/*            ))*/}
                            {/*            :*/}
                            {/*            <Alert style={{ marginTop: 10 }} message="还没有人说话哦" type="info" />*/}
                            {/*    }*/}
                            {/*</ChatRecord>*/}
                            {/*<div>*/}
                            {/*    <Input placeholder={"说点什么吧"} value={messageInput} onChange={e => {*/}
                            {/*        this.setState({ messageInput: e.target.value })*/}
                            {/*    }} suffix={*/}
                            {/*        <Button type={"primary"} onClick={() => {*/}
                            {/*            sendMessage({*/}
                            {/*                live_id: liveStore.live_id,*/}
                            {/*                message: messageInput*/}
                            {/*            }).then(res => {*/}
                            {/*                this.setState({ messageInput: "" })*/}
                            {/*            }).catch(e => {*/}
                            {/*                message.error("发送失败 " + e.data?.message)*/}
                            {/*            })*/}
                            {/*        }}>发送</Button>*/}
                            {/*    } />*/}
                            {/*</div>*/}
                        </Chat>
                    </RightSide>
                </Live>
                <BottomBar>
                    <div></div>
                    {/*<Flex>*/}
                    {/*    <div className={"live_room_tip_4"}>*/}
                    {/*        <LivePlugins />*/}
                    {/*    </div>*/}
                    {/*    <Air horizontal={10} />*/}
                    {/*    <Button type={"primary"} onClick={() => {*/}
                    {/*        if (!living) {*/}
                    {/*            confirm({*/}
                    {/*                title: '权限检测',*/}
                    {/*                content: '为保证直播顺利进行，推荐在直播开始之前进行设备检测',*/}
                    {/*                okText: "去检测",*/}
                    {/*                onOk: () => {*/}
                    {/*                    this.setState({ showPermissionsCheck: true, permissionPass: false });*/}
                    {/*                },*/}
                    {/*                cancelText: "不必了",*/}
                    {/*                onCancel: () => {*/}
                    {/*                    this.openLiving();*/}
                    {/*                }*/}
                    {/*            });*/}
                    {/*        } else {*/}
                    {/*            // const timeNum = (new Date().getTime() - this.state.liveStartTime)/1000*/}
                    {/*            // if(timeNum<180){*/}
                    {/*            //     info({*/}
                    {/*            //         title: '提示',*/}
                    {/*            //         content: '3分钟以内不生成直播回放',*/}
                    {/*            //         okText: "知道了",*/}
                    {/*            //         onOk: () => {*/}
                    {/*            //             console.log('ok');*/}
                    {/*            //             this.setState({*/}
                    {/*            //                 isLookBack: false*/}
                    {/*            //             });*/}
                    {/*            //         }*/}
                    {/*            //     });*/}
                    {/*            //     return false*/}
                    {/*            // }*/}
                    {/*            confirm({*/}
                    {/*                title: '提示',*/}
                    {/*                content: '是否设置当前直播内容为回看？',*/}
                    {/*                okText: "是",*/}
                    {/*                onOk: () => {*/}
                    {/*                    this.openLiving();*/}

                    {/*                    //后台给一个接口，设置成功*/}
                    {/*                    LiveSetRecord({*/}
                    {/*                        live_id : liveStore.live_id*/}
                    {/*                    }).then(res => {*/}
                    {/*                        message.success("设置成功")*/}
                    {/*                        this.setState({*/}
                    {/*                            isLookBack: true*/}
                    {/*                        });*/}
                    {/*                    }).catch(e => {*/}
                    {/*                        message.error("设置失败 " + e.data?.message)*/}
                    {/*                        this.setState({*/}
                    {/*                            isLookBack: true*/}
                    {/*                        });*/}
                    {/*                    })*/}
                    {/*                },*/}
                    {/*                cancelText: "否",*/}
                    {/*                onCancel: () => {*/}
                    {/*                    this.openLiving();*/}

                    {/*                }*/}
                    {/*            });*/}
                    {/*        }*/}
                    {/*    }}>{living ? "停止直播" : "开始直播"}</Button>*/}
                    {/*</Flex>*/}
                    {/*<Flex>*/}
                    {/*    <Button className={"live_room_tip_3"} type={"link"} icon={<SettingOutlined />} onClick={() => {*/}
                    {/*        this.setState({ showVideoSetting: true })*/}
                    {/*    }}>设置</Button>*/}
                    {/*    <Air horizontal={5} />*/}
                    {/*</Flex>*/}
                </BottomBar>
                <Modal
                    title={"直播设置"}
                    visible={showVideoSetting}
                    onCancel={() => {
                        this.setState({ showVideoSetting: false })
                    }}
                    okButtonProps={{ style: { display: "none" } }}
                    centered={true}
                    cancelText={"确定"}
                >
                    <TitleContentTable colGap={20} rowGap={15}>
                        <Texts.ItemTitle>视频质量</Texts.ItemTitle>
                        <AndRadio.Group defaultValue={2} onChange={e => {
                            this.setVideoQuality(e.target.value)
                        }}>
                            <AndRadio value={1}>流畅(480p)</AndRadio>
                            <AndRadio value={2}>清晰(720p)</AndRadio>
                            <AndRadio value={3}>高清(1080p)</AndRadio>
                        </AndRadio.Group>
                        <Texts.ItemTitle>音频质量</Texts.ItemTitle>
                        <AndRadio.Group defaultValue={8} onChange={e => {
                            const RtmpStreamer = require("rtmp-streamer")
                            let streamer = new RtmpStreamer(document.getElementById('rtmp-streamer'));
                            streamer.setMicQuality(e.target.value);
                        }}>
                            <AndRadio value={5}>流畅</AndRadio>
                            <AndRadio value={8}>普通</AndRadio>
                            <AndRadio value={10}>高清</AndRadio>
                        </AndRadio.Group>
                    </TitleContentTable>
                </Modal>
                <Modal
                    title={"权限检测"}
                    visible={showPermissionsCheck}
                    onCancel={() => {
                        this.setState({ showPermissionsCheck: false }, () => {
                            if (permissionPass) {
                                this.openLiving();
                            }
                        })
                    }}
                    destroyOnClose={true}
                    width={"60%"}
                    okButtonProps={{ style: { display: "none" } }}
                    centered={true}
                    cancelText={"确定"}
                >
                    <PermissionsCheck onSuccess={() => {
                        this.setState({ permissionPass: true })
                    }} />
                </Modal>
                <DocListSelect visiable={showSelectDoc} onSelect={item => {
                //    useDoc({
                //     doc_id: String(item.id)
                // }).then(res=>{
                //     docShow({
                //         live_doc_id: item.id.toString()
                //     }).then(res => {
                //         this.setState({
                //             smallPicList: res.doc_son,
                //         })
                //     })
                // })
                    docShow({
                        live_doc_id: item.id.toString()
                    }).then(res => {
                        this.setState({
                            smallPicList: res.doc_son,
                        })
                    })
                }} onCancel={() => {
                    this.setState({ showSelectDoc: false })
                }} />
            </Container>
        );
    }
}

const SmallPicWidth = 150

const Container = styled(FlexCol)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 20;
  background: #fff;
`

const Live = styled(Flex)`
  flex: 1;
  border-bottom: 1px rgba(0,0,0,0.1) solid;
  overflow-y: auto;
`

const BottomBar = styled(Flex)`
  justify-content: space-between;
  padding: 10px 0px;
`

const LeftSide = styled(Flex1)`
  display: flex;
  flex-direction: column;
  border-right: 1px rgba(0,0,0,0.1) solid;
`
const DocTitle = styled(FlexJustifyBetween)`
  border-bottom: 1px rgba(0,0,0,0.1) solid;
  align-items: center;
  padding: 10px;
`

const PPT = styled(Flex)`
  flex: 1;
  overflow-y: auto;
`

const Thumbnail = styled(FlexCol)`
  width: ${SmallPicWidth}px;
  overflow-y: scroll;
  background: ${common.color.disable};
`

const BigPic = styled.img`
  max-width: 100%;
  max-height: 100%;
  -webkit-user-drag: none;
`

const Blackboard = styled(Flex1)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("${logoGray}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 120px;
`

const RightSide = styled(FlexCol)`
  width: 356px;
`
const Chat = styled(Flex1)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-top: 1px rgba(0,0,0,0.1) solid;
`

const ChatRecord = styled(Flex1)`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
`

const MaskFloat = styled(FlexColVerCenter)`
  width: 100%;
  height: 100%;
  align-items: center;
  color: #ddd;
`

const Tips = styled.div`
  color: #333;
  font-size: 10px;
`
