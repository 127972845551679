/**
 * 直播功能状态
 * Create by liaokai on 2020-06-18 14:36:55
 */
import {toPost} from "../../http-utils";

export interface ILiveIconIndex {
    "red_packet": number, //红包开启代表1 ，不开启代表2
    "gift": number,//奖品开启代表1 ，不开启代表2
    "question": number,//互动问答开启代表1 ，不开启代表2
    "survey_questionnaire": number //调研问卷开启代表1 ，不开启代表2
    live_relation : number
}

export const liveIconIndex = toPost<
    {
        live_id : string
    },
    ILiveIconIndex
>("/merchant/live/liveIconIndex");
