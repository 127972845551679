/**
 * 导出提现列表
 * Create by liaokai on 2020-05-15 18:33:12
 */
import {toPost} from "../http-utils";

export const tixianExport = toPost<
    {

    },
    string
>("/merchant/merchant/tixianExport");

