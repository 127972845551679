/**
 * 选择专题
 * Create by liaokai on 2020-06-15 10:57:35
 */
import * as React from 'react';
import {Component} from "react";
import {IQuestionIndexItem, questionIndex} from "../../../../../../../../common/apis/apis/live/questionIndex";
import {common} from "../../../../../../../../common/common";
import {Button, message, Modal, Table} from "antd";
import {liveStore} from "../../../../menu/live.store";
import {questionPublish} from "../../../../../../../../common/apis/apis/live/questionPublish";
import {questionCancel} from "../../../../../../../../common/apis/apis/live/questionCancel";
import {ISubjectShowItem, subjectShow} from "../../../../../../../../common/apis/apis/live/subjectShow";
import {recentlyLive} from "../../../../../../../../common/apis/apis/live/recentlyLive";

interface IProps{
    visible : boolean,
    onCancel? : () => void
    onSelect? : (value: ISubjectShowItem) => void
}

interface IState{
    columns : any[]
    dataSource : ISubjectShowItem[],
    currentPage : number,
    total : number,
    pageSize: number,
    loading : boolean,
}

export class LiveSelectProkect extends Component<IProps,IState>{
    readonly state : IState = {
        columns : [
            // {
            //     title: '序号',
            //     dataIndex: 'live_id',
            //     key: 'live_id',
            //     render: (text : string) => text,
            // },
            {
                title: '直播标题',
                dataIndex: 'title',
                key: 'title',
                render: (text : string) => text,
            },
            {
                title: '直播时间',
                dataIndex: 'time',
                key: 'time',
                render: (text : string) => text,
            },
            {
                title: '直播状态',
                dataIndex: 'live_status',
                key: 'live_status',
                render: (text : string) => text,
            },
            {
                title: '观看人数',
                dataIndex: 'people_num',
                key: 'people_num',
                render: (text : string) => text,
            },
            {
                title: '操作',
                dataIndex: 'live_id',
                key: 'live_id',
                render: (text: string, record : ISubjectShowItem, index : number) => <div>
                    <Button style={{paddingLeft: 0}} onClick={()=>{
                        const {onCancel, onSelect} = this.props
                        onSelect?.(record);
                        onCancel?.();
                    }} type={"link"}>
                        选择
                    </Button>
                </div>,
            }
        ],
        dataSource : [],
        pageSize : common.defaultPaginate,
        total : 0,
        currentPage : 1,
        loading : false,
    };

    refreshPage = ()=>{
        this.reqTableData(this.state.currentPage)
    }

    reqTableData(page : number){
        recentlyLive({
            page : page,
            paginate : this.state.pageSize,
            live_time_begin:'',
            live_time_end:'',
            title:''
        }).then(res => {
            this.setState({
                dataSource : res.data,
                total : res.total,
                currentPage : page
            })
        })
    }

    componentDidMount(): void {
        this.reqTableData(1)
    }

    componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any) {
        if(nextProps.visible){
            this.refreshPage()
        }
    }

    render() {
        let {visible, onCancel} = this.props;
        let {columns, dataSource, currentPage, total, pageSize} = this.state;
        return (
            <Modal
                visible={visible}
                okButtonProps={{style : {display : "none"}}}
                cancelText={"取消"}
                onCancel={()=>{
                    onCancel?.();
                }}
                width={"70%"}
                centered={true}
            >
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    emptyText={"暂无内容"}
                    pagination={{
                        defaultCurrent : 1,
                        current : currentPage,
                        pageSize : pageSize,
                        total : total,
                        onChange : page => {
                            this.reqTableData(page);
                            this.setState({currentPage : page})
                        }
                    }}
                />
            </Modal>
        );
    }
}
