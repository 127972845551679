import * as Sentry from '@sentry/browser';
import {apis} from "./apis/apis";
import IEVersion from "./utils/ie-version";
import isLogin from "./utils/is-login";
import {getLastPathField, getNextPathField} from "./utils/router";
import defaultImg from "../assets/logo.png"
import defaultQrCode from "../assets/qrcode.png"
import {deepClone} from "./utils/clone-object";
import {singleTip} from "./utils/single-tip";

export const common = {
    wsUrlDev : "wss://huibaifen.rup-china.com/wss",
    // wsUrlDev : "ws://192.168.10.10:2348",
    wsUrlProd : "wss://portal.huibaifen.com/wss",
    amapkey : "0d8bc5493d3cad50f55b0f11fcf927a4",   //高德地图API key
    sentry : Sentry,                //哨兵
    color : {
        danger : "#ff4d4f",         //危险
        primary : "#1890ff",        //主色
        unactivated : "#bec8c8",    //失活
        disable : "#f5f5f5",        //不可用
        success : "#52c41a",        //成功
        warning : "#faad14",        //警告
        purple : "#a26ef4",         //紫色
        normalText : "rgba(0,0,0,0.65)"   //普通文字
    },
    apis : apis,    //新版api引用,
    isIE : IEVersion() !== -1,
    isLogin : isLogin,
    router : {
        getLastPathField,
        getNextPathField
    },
    defaultImg : defaultImg,
    defaultQRCode : defaultQrCode,
    deepClone : deepClone,
    maxPrice : 5000,
    refresh : ()=>{ window.history.go(0);},
    toTop : (animal? : boolean) => {    //回到顶部
        if(animal){
            let scrollToTop = window.setInterval(function() {
                let pos = window.pageYOffset;
                if ( pos > 0 ) {
                    window.scrollTo( 0, pos - 50 ); // how far to scroll on each step
                } else {
                    window.clearInterval( scrollToTop );
                }
            }, 2);
        }else{
            document.body.scrollIntoView()
        }
    },
    formatString : "YYYY-MM-DD HH:mm:ss",
    defaultPaginate : 10,
    singleTip : singleTip
};
