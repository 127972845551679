/**
 * 邮件模板列表
 * Create by liaokai on 2020-04-23 17:52:00
 */
import {toPost} from "../../http-utils";

interface IEmailTemplateListItem {

}

export const emailTemplateListAll = toPost<
    {

    },
    {
        "id": number,
        "title": string,
        "text": string,
        "create_time": string
    }[]
    >("/merchant/Email/templateListAll");
