// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".form-preview_container__BBXZn .form-preview_tips__gKR8I{font-size:10px}.form-preview_container__BBXZn .form-preview_BaseTitle__2mbz1{font-size:12px;font-weight:500;color:#006fce;box-shadow:rgba(0,111,206,0.15) 0 -8px 0px -2px inset}.form-preview_container__BBXZn .form-preview_BaseTitleBox___yZSi{display:flex;margin-top:5px}.form-preview_container__BBXZn .form-preview_submitBox__1HgsI{display:flex;justify-content:center;margin:30px 0px}\n", ""]);
// Exports
exports.locals = {
	"container": "form-preview_container__BBXZn",
	"tips": "form-preview_tips__gKR8I",
	"BaseTitle": "form-preview_BaseTitle__2mbz1",
	"BaseTitleBox": "form-preview_BaseTitleBox___yZSi",
	"submitBox": "form-preview_submitBox__1HgsI"
};
module.exports = exports;
