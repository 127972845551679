/**
 * 互动问答表格
 * todo 拆分编辑、添加为单个页面
 * Create by liaokai on 2020-05-25 18:55:45
 */
import * as React from "react";
import { Component } from "react";
import { Flex } from "../../../../../../../components/style/flex/flex";
import { Button, Input, message, Modal, Spin, Switch, Table } from "antd";
import { Air } from "../../../../../../../components/air/Air";
import { FlexJustifyBetween } from "../../../../../../../components/style/flex/flex-justify-between";
import {
    IQuestionIndexItem,
    questionIndex,
} from "../../../../../../../common/apis/apis/live/questionIndex";
import { liveStore } from "../../live.store";
import { common } from "../../../../../../../common/common";
import { TitleContentTable } from "../../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import { AddQuestionForm } from "./add-question-form";
import {
    IQuestionStoreQuestion,
    questionStore,
} from "../../../../../../../common/apis/apis/live/questionStore";
import { questionShow } from "../../../../../../../common/apis/apis/live/questionShow";
import { questionUpdate } from "../../../../../../../common/apis/apis/live/questionUpdate";
import { questionPublish } from "../../../../../../../common/apis/apis/live/questionPublish";
import { questionCancel } from "../../../../../../../common/apis/apis/live/questionCancel";
import { Link } from "react-router-dom";
import { liveIconIndex } from "../../../../../../../common/apis/apis/live/liveIconIndex";
import { presentStore } from "../../present-store";
import { observer } from "mobx-react";
import { liveIconSet } from "../../../../../../../common/apis/apis/live/liveIconSet";
import { enableShowInfo } from "../../../../../../../common/apis/apis/live/enableShowInfo";
import { enableShowSet } from "../../../../../../../common/apis/apis/live/enableShowSet";

interface IProps {}

interface IState {
    columns: any[];
    dataSource: IQuestionIndexItem[];
    currentPage: number;
    total: number;
    pageSize: number;
    isQuestionIcon: boolean;
    loading: boolean;
    showAddModal: boolean;
    editingId: string;
    addQuestionFormData: IQuestionStoreQuestion;
}

const defaultFormData: IQuestionStoreQuestion = {
    title: "",
    type: "single",
    content: [],
};

@observer
export class LiveQaList extends Component<IProps, IState> {
    readonly state: IState = {
        columns: [
            {
                title: "互动题目",
                dataIndex: "title",
                key: "title",
                render: (text: boolean) => text,
            },
            {
                title: "更新时间",
                dataIndex: "update_time",
                key: "update_time",
                render: (text: string) => text,
            },
            {
                title: "操作",
                dataIndex: "id",
                key: "id",
                render: (
                    text: string,
                    record: IQuestionIndexItem,
                    index: number
                ) => (
                    <div>
                        <Link to={`qa/question_detail/${text}`}>
                            <Button type="link">问答详情</Button>
                        </Link>
                        <Button
                            disabled={!record.is_edit}
                            style={{ paddingLeft: 0 }}
                            type={"link"}
                            onClick={() => {
                                this.setState(
                                    { showAddModal: true, editingId: text },
                                    () => {
                                        questionShow({
                                            id: text,
                                        }).then((res) => {
                                            let { question, option } = res;
                                            this.setState({
                                                addQuestionFormData: {
                                                    title: question.title,
                                                    type: question.type,
                                                    content: option.map(
                                                        (value) => {
                                                            return {
                                                                title:
                                                                    value.serial,
                                                                value:
                                                                    value.option,
                                                                isAnswer:
                                                                    value.isAnswer,
                                                            };
                                                        }
                                                    ),
                                                } as IQuestionStoreQuestion,
                                            });
                                        });
                                    }
                                );
                            }}
                        >
                            编辑
                        </Button>
                        <Link to={`qa/answer_list/${text}`}>
                            <Button type="link">回答详情</Button>
                        </Link>
                        <Button
                            style={{ paddingLeft: 0 }}
                            onClick={() => {
                                if (record.is_publish) {
                                    questionCancel({ id: text })
                                        .then(() => {
                                            this.refreshPage();
                                        })
                                        .catch((e) => {
                                            message.error(
                                                "操作失败 " + e.data?.message
                                            );
                                        });
                                } else {
                                    questionPublish({ id: text })
                                        .then(() => {
                                            this.refreshPage();
                                        })
                                        .catch((e) => {
                                            message.error(
                                                "操作失败 " + e.data?.message
                                            );
                                        });
                                }
                            }}
                            type={"link"}
                            disabled={!record.publish_type}
                        >
                            {record.is_publish ? "取消发布" : "发布"}
                        </Button>
                    </div>
                ),
            },
        ],
        dataSource: [],
        pageSize: common.defaultPaginate,
        total: 0,
        currentPage: 1,
        loading: false,
        showAddModal: false,
        isQuestionIcon: false,
        editingId: "",
        addQuestionFormData: defaultFormData,
    };

    refreshPage = () => {
        this.reqTableData(this.state.currentPage);
    };

    reqTableData(page: number) {
        this.setState({ loading: true });
        questionIndex({
            live_id: liveStore.live_id,
            page,
            paginate: this.state.pageSize,
        })
            .then((res) => {
                this.setState({
                    dataSource: res.data,
                    total: res.total,
                    currentPage: page,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    componentDidMount(): void {
        //获取图标状态
        enableShowInfo({ live_id: liveStore.live_id, type: 9 }).then((res) => {
            this.setState({ isQuestionIcon: res.is_show });
        });
        this.reqTableData(1);
    }

    hideModal = () => {
        this.setState({
            showAddModal: false,
            addQuestionFormData: defaultFormData,
            editingId: "",
        });
    };

    showModal = (editingId = "", cb = () => {}) => {
        this.setState(
            {
                showAddModal: true,
                editingId: editingId,
            },
            cb
        );
    };

    render() {
        let {} = this.props;
        let {
            columns,
            dataSource,
            pageSize,
            isQuestionIcon,
            total,
            currentPage,
            loading,
            showAddModal,
            addQuestionFormData,
            editingId,
        } = this.state;
        return (
            <div>
                <FlexJustifyBetween>
                    <Flex>
                        <Switch
                            checked={isQuestionIcon}
                            onChange={(v) => {
                                enableShowSet({
                                    live_id: liveStore.live_id,
                                    type: 9,
                                }).then((res) => {
                                    this.setState({
                                        isQuestionIcon: res.is_show,
                                    });
                                });
                                // let crt = v
                                // presentStore.setQuestion(crt);
                                // liveIconSet({
                                //     live_id : liveStore.live_id,
                                //     type : "question",
                                //     status : crt ? 1 : 2
                                // }).catch(e => {
                                //     presentStore.setQuestion(!crt);
                                //     message.error("设置失败 " + e.data?.message)
                                // })
                            }}
                        />
                        <Air horizontal={3} />
                        <div>开启问答</div>
                    </Flex>
                    <Flex>
                        <Button
                            disabled={presentStore.presentInfo.question !== 1}
                            type={"primary"}
                            onClick={() => {
                                this.showModal();
                            }}
                        >
                            添加问答
                        </Button>
                    </Flex>
                </FlexJustifyBetween>
                <Air vertical={5} />
                <Spin
                    indicator={<div />}
                    tip="尚未开启问答"
                    spinning={!isQuestionIcon}
                >
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={dataSource}
                        // emptyText={"暂无内容"}
                        pagination={{
                            defaultCurrent: 1,
                            current: currentPage,
                            pageSize: pageSize,
                            total: total,
                            onChange: (page) => {
                                this.reqTableData(page);
                                this.setState({ currentPage: page });
                            },
                        }}
                    />
                </Spin>

                <Modal
                    visible={showAddModal}
                    title={"添加问答"}
                    okText={"保存"}
                    cancelText={"取消"}
                    onOk={() => {
                        if (!!loading) {
                            return;
                        }
                        if (editingId) {
                            //编辑
                            this.setState({ loading: true });
                            questionUpdate({
                                title: addQuestionFormData.title,
                                type: addQuestionFormData.type,
                                content: JSON.stringify(
                                    addQuestionFormData.content
                                ) as any,
                                live_id: liveStore.live_id,
                                id: editingId,
                            })
                                .then((res) => {
                                    message.success("保存成功");
                                    this.reqTableData(currentPage);
                                    this.hideModal();
                                })
                                .catch((e) => {
                                    message.error(
                                        "保存失败 " + e?.data?.message
                                    );
                                })
                                .finally(() => {
                                    this.setState({ loading: false })
                                });
                        } else {
                            //新建
                            this.setState({ loading: true });
                            questionStore({
                                title: addQuestionFormData.title,
                                type: addQuestionFormData.type,
                                content: JSON.stringify(
                                    addQuestionFormData.content
                                ) as any,
                                live_id: liveStore.live_id,
                            })
                                .then((res) => {
                                    message.success("添加成功");
                                    this.reqTableData(currentPage);
                                    this.hideModal();
                                })
                                .catch((e) => {
                                    message.error(
                                        "添加失败 " + e?.data?.message
                                    );
                                })
                                .finally(() => {
                                    this.setState({ loading: false })
                                });
                        }
                    }}
                    onCancel={() => {
                        this.hideModal();
                    }}
                >
                    <AddQuestionForm
                        data={addQuestionFormData}
                        requireAnswer={true}
                        onChange={(data) => {
                            this.setState({ addQuestionFormData: data });
                        }}
                    />
                </Modal>
            </div>
        );
    }
}
