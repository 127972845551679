/**
 * 直播间解绑
 * Create by liaokai on 2020-06-15 13:54:38
 */
import {toPost} from "../../http-utils";

export const cancelBindLive = toPost<
    {
        current_live_id : string
        cancel_bind_live_id : string
    },
    {

    }
>("/merchant/live/cancelBindLive");
