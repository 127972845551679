/**
 * 互动问答 - 路由
 * Create by liaokai on 2020-05-23 17:33:38
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {SurfaceRouter, tSurfaceRouteItem} from "../../../../../../components/surface-router/surface-router";
import {LiveQaView} from "./qa";
import { LiveQaAnswerList } from "./answer-detail/answer-detail";
import QuestionDetail from './question-detail'

interface IProps extends RouteComponentProps{

}

interface IState{

}

const routerData : tSurfaceRouteItem = {
    breadTitle : "互动问答",
    routeName : "",
    component : LiveQaView,
    children : [
        {
            breadTitle : "问答详情",
            routeName : "answer_list/:qa_id",
            component : LiveQaAnswerList,
            children : []
        },
        {
            breadTitle : "问答详情",
            routeName : "question_detail/:qa_id",
            component : QuestionDetail,
            children : []
        }
        
    ]
}

export class LiveQaRouter extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={routerData}/>
        );
    }
}
