/**
 * 设置会议报名表单(增加、删除、排序)
 * Create by liaokai on 2020-04-03 11:26:53
 */
import {toPost} from "../http-utils";

export const setMeetingRegisterField = toPost<
    {
        meetid : number,
        fieldes : { "name": string, "is_required": number }[]
    },
    {

    }
>
("/merchant/meeting/setMeetingRegisterField");
