/**
 * 文档管理 - 列表
 * Create by liaokai on 2020-05-25 19:29:50
 */
import * as React from 'react';
import {Component} from "react";
import {Button, message, Modal, Table, Switch, Spin} from "antd";
import {docIndex, IDocIndex} from "../../../../../../../common/apis/apis/live/docIndex";
import {liveStore} from "../../live.store";
import {observer} from "mobx-react";
import {common} from "../../../../../../../common/common";
import {LoadingSingleFile} from "../../../../../../../components/loading-single-file";
import {Flex} from "../../../../../../../components/style/flex/flex";
import { FlexJustifyAround } from "../../../../../../../components/style/flex/flex-justify-around";
import {FlexJustifyEnd} from "../../../../../../../components/style/flex/flex-justify-end";
import {FlexJustifyBetween} from "../../../../../../../components/style/flex/flex-justify-between";
import {uploadFile} from "../../../../../../../common/apis/apis/upload-file";
import {Air} from "../../../../../../../components/air/Air";
import {Link, Prompt} from "react-router-dom";
import {PptReview} from "../preview/ppt-review";
import {docShow} from "../../../../../../../common/apis/apis/live/docShow";
import {enableShowSet} from "../../../../../../../common/apis/apis/live/enableShowSet";
import {enableShowInfo} from "../../../../../../../common/apis/apis/live/enableShowInfo";
import styled from "styled-components";
import {FlexAlignCenter} from "../../../../../../../components/style/flex/flex-align-center";
import {ExclamationCircleOutlined} from "@ant-design/icons/lib";
import { publishDoc } from '../../../../../../../common/apis/apis/live/publishDoc';

interface IProps{

}

interface IState{
    columns : any[]
    dataSource : IDocIndex[],
    currentPage : number,
    total : number,
    pageSize: number,
    loading : boolean,
    uploading : boolean,
    uploadingPercent : number,
    imgReviewList : string[],
    showReview: boolean,
    isDisableDoc: boolean,
}

@observer
export class LiveDocList extends Component<IProps,IState>{
    interval : any

    readonly state : IState = {
        columns : [
            {
                title: '文档名称',
                dataIndex: 'origin_name',
                key: 'origin_name',
                render: (text : boolean) => text,
            },
            {
                title: '上传日期',
                dataIndex: 'create_time',
                key: 'create_time',
                render: (text: string) => text,
            },
            {
                title: '页数',
                dataIndex: 'page',
                key: 'page',
                render: (text: string) => text,
            },
            {
                title: '进度',
                dataIndex: 'status',
                key: 'status',
                render: (text: string) => text,
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                render: (text: string, record : IDocIndex) => <div>
                    <Button style={{paddingLeft: 0}} type={"link"} onClick={()=>{
                        docShow({
                            live_doc_id : text
                        }).then(res => {
                            this.setState({imgReviewList : res.doc_son.map(value => value.img_url)})
                        })
                        this.setState({showReview: true})
                    }}>预览</Button>
                    {/*<Button style={{paddingLeft: 0}} type={"link"} onClick={()=>{*/}
                    {/*    publishDoc({doc_id:Number(text)}).then((res)=>{*/}
                    {/*        message.success("操作成功!")*/}
                    {/*        this.refreshPage()*/}
                    {/*    }).catch(err=>{*/}
                    {/*        message.error("操作失败！")*/}
                    {/*    })*/}
                    {/*}}>{record.publish_status===1?'发布':'取消发布'}</Button>*/}
                </div>,
            }
        ],
        dataSource : [],
        pageSize : common.defaultPaginate,
        total : 0,
        currentPage : 1,
        loading : false,
        uploading : false,
        uploadingPercent : 0,
        imgReviewList : [],
        showReview: false,
        isDisableDoc: false
    };

    reqTableData(page : number = 1, withLoading : boolean = true){
        this.setState({loading : withLoading})
        docIndex({
            live_id : liveStore.live_id,
            page,
            paginate : this.state.pageSize
        }).then(res => {
            this.setState({
                dataSource : res.data,
                total : res.total,
                currentPage : page
            })
        }).finally(()=>{
            this.setState({loading : false})
        })
    }

    refreshPage = ()=>{
        this.reqTableData(this.state.currentPage, false)
    }

    componentDidMount(): void {
        this.reqTableData(1)
        window.onbeforeunload = () => {
            if(this.state.uploading){
                return "您确定要离开此网站吗？"
            }
        };
        this.setState({ loading: true });
        enableShowInfo({
            live_id: liveStore.live_id,
            type: 3
        }).then(res => {
            const { is_show } = res;
            this.setState({
                isDisableDoc: is_show
            });
        }).finally(() => {
            this.setState({ loading: false })
        });
        this.interval = setInterval(()=>{
            this.refreshPage();
        },5000)
    }

    componentWillUnmount() {
        window.onbeforeunload = function () {

        }
        clearInterval(this.interval)
    }

    onCropedImage = async (result : any): Promise<boolean> =>{
        let formdata = new FormData();
        formdata.append('file',result);
        formdata.append('live_id',liveStore.live_id);
        try {
            this.setState({uploading : true})
            let res = await uploadFile(formdata, "/merchant/live/docStore", progressEvent => {
                console.log(progressEvent);
                this.setState({uploadingPercent : progressEvent.loaded / progressEvent.total})
            })
            this.reqTableData(this.state.currentPage)
            return !!res;
        }catch(err){
            message.error("服务器错误！");
            return false
        }finally {
            this.setState({uploading : false})
        }
    }


    render() {
        let {} = this.props;
        let {columns, dataSource, pageSize, total, currentPage, loading, uploading, imgReviewList, showReview, uploadingPercent, isDisableDoc} = this.state;
        return (
            <div>
                <FlexJustifyBetween>
                    <FlexJustifyBetween>
                        <Switch
                            checked={isDisableDoc}
                            onChange={(value) => {
                                this.setState({
                                    isDisableDoc: value
                                });
                                this.setState({ loading: true });
                                enableShowSet({
                                    live_id: liveStore.live_id,
                                    type: 3
                                }).then(res => {
                                    if (!value) {
                                        message.success('已关闭');
                                    } else {
                                        message.success('已开启');
                                    }
                                }).finally(() => {
                                    this.setState({ loading: false })
                                });
                            }}
                        />
                        <Air horizontal={5}/>
                        <span>启用文档</span>
                    </FlexJustifyBetween>
                    <FlexJustifyEnd>
                        <FlexAlignCenter>
                            <ExclamationCircleOutlined />
                            <Air horizontal={3}/>
                            <Tips>
                                <div>如文档里如包含视频则无法正常使用；如文档不能正常显示文字,请将文档内的文字设置为宋体</div>
                            </Tips>
                            <Air horizontal={5}/>
                        </FlexAlignCenter>
                        <LoadingSingleFile onLoadFile={file => {
                            this.onCropedImage(file)
                        }}>
                            <Button
                                type={"primary"} disabled={uploading || !isDisableDoc} loading={uploading}>
                                {
                                    !uploading ? "添加文档" :
                                        uploadingPercent < 1 ? Number(uploadingPercent.toFixed(2))*100+"%" :
                                            "正在转码"
                                }
                            </Button>
                        </LoadingSingleFile>
                    </FlexJustifyEnd>
                </FlexJustifyBetween>
                <Air vertical={10}/>
                <Spin indicator={<div />} tip="尚未开启文档" spinning={!isDisableDoc}>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={dataSource}
                        // emptyText={"暂无内容"}
                        pagination={{
                            defaultCurrent : 1,
                            current : currentPage,
                            pageSize : pageSize,
                            total : total,
                            onChange : page => {
                                this.reqTableData(page);
                                this.setState({currentPage : page})
                            }
                        }}
                    />
                </Spin>
                <Modal
                    width={"60%"}
                    cancelText={"确定"}
                    title={"预览"}
                    okButtonProps={{style : {display : "none"}}}
                    visible={showReview}
                    onCancel={()=>{
                        this.setState({showReview : false,imgReviewList : []});
                    }}
                >
{/*<<<<<<< HEAD*/}
{/*                    <PptReview imgs={imgReviewList}/>*/}
{/*=======*/}
                    <PptReview imgs={imgReviewList} showReview={showReview} />
{/*>>>>>>> origin/强制覆盖*/}
                </Modal>

                <Prompt message={"您正在上传文档，请等待..."} when={uploading}>

                </Prompt>
            </div>
        );
    }
}

const Tips = styled.div`
  color: #333;
  font-size: 10px;
`
