/**
 * 问卷调查
 * Create by liaokai on 2020-06-16 11:55:47
 */
import * as React from "react";
import { Component } from "react";
import {
    IQuestionIndexItem,
    questionIndex,
} from "../../../../../../common/apis/apis/live/questionIndex";
import { Button, message, Spin, Switch, Table } from "antd";
import { questionShow } from "../../../../../../common/apis/apis/live/questionShow";
import { IQuestionStoreQuestion } from "../../../../../../common/apis/apis/live/questionStore";
import { questionCancel } from "../../../../../../common/apis/apis/live/questionCancel";
import { questionPublish } from "../../../../../../common/apis/apis/live/questionPublish";
import { Link } from "react-router-dom";
import { common } from "../../../../../../common/common";
import { liveStore } from "../live.store";
import { Air } from "../../../../../../components/air/Air";
import { Flex } from "../../../../../../components/style/flex/flex";
import { FlexJustifyBetween } from "../../../../../../components/style/flex/flex-justify-between";
import {
    ILiveSurveyQuestionnaireIndexItem,
    liveSurveyQuestionnaireIndex,
} from "../../../../../../common/apis/apis/live/liveSurveyQuestionnaireIndex";
import { liveSurveyQuestionnairePublish } from "../../../../../../common/apis/apis/live/liveSurveyQuestionnairePublish";
import { liveSurveyQuestionnairePublishCancel } from "../../../../../../common/apis/apis/live/liveSurveyQuestionnaireCancel";
import { presentStore } from "../present-store";
import { liveIconSet } from "../../../../../../common/apis/apis/live/liveIconSet";
import { observer } from "mobx-react";
import { enableShowInfo } from "../../../../../../common/apis/apis/live/enableShowInfo";
import { enableShowSet } from "../../../../../../common/apis/apis/live/enableShowSet";
import { exportliveSurveyQuestionnaireByUserAnswerUrl } from "../../../../../../common/apis/apis/live/liveSurveyQuestionnaireByUserAnswer";

interface IProps {}

interface IState {
    columns: any[];
    dataSource: ILiveSurveyQuestionnaireIndexItem[];
    currentPage: number;
    total: number;
    pageSize: number;
    loading: boolean;
    isSurveyQuestionnaireIcon: boolean;
}

@observer
export class LiveQuestion extends Component<IProps, IState> {
    readonly state: IState = {
        columns: [
            {
                title: "问卷名称",
                dataIndex: "title",
                width: 400,
                key: "title",
                render: (text: boolean) => text,
            },
            {
                title: "更新时间",
                dataIndex: "update_time",
                key: "update_time",
                render: (text: string) => text,
            },
            {
                title: "操作",
                dataIndex: "id",
                key: "id",
                render: (
                    text: string,
                    record: ILiveSurveyQuestionnaireIndexItem,
                    index: number
                ) => (
                    <Flex>
                        <Button
                            disabled={!record.publish_type}
                            type={"link"}
                            style={{ paddingLeft: 0 }}
                            onClick={() => {
                                if (record.is_publish) {
                                    liveSurveyQuestionnairePublishCancel({
                                        id: text,
                                    })
                                        .then(() => {
                                            this.refreshPage();
                                        })
                                        .catch((e) => {
                                            message.error(
                                                "操作失败 " + e.data?.message
                                            );
                                        });
                                } else {
                                    liveSurveyQuestionnairePublish({
                                        id: text,
                                    })
                                        .then((res) => {
                                            this.refreshPage();
                                        })
                                        .catch((e) => {
                                            message.error(
                                                "操作失败 " + e.data?.message
                                            );
                                        });
                                }
                            }}
                        >
                            {record.is_publish ? "取消发布" : "发布"}
                        </Button>
                        <Link to={`question/edit/${text}`}>
                            <Button
                                disabled={!record.is_edit}
                                type={"link"}
                                style={{ paddingLeft: 0 }}
                            >
                                编辑问卷
                            </Button>
                        </Link>
                        <Link to={`question/detail/${text}`}>
                            <Button
                                type={"link"}
                                style={{ paddingLeft: 0 }}
                            >
                                问卷详情
                            </Button>
                        </Link>
                        <Link to={`question/answer/${text}/${record.title}`}>
                            <Button type={"link"} style={{ paddingLeft: 0 }}>
                                回答详情
                            </Button>
                        </Link>
                        {/*<Button  type={"link"}    onClick={()=>{*/}
                        {/*    exportliveSurveyQuestionnaireByUserAnswerUrl({id:record.id.toString()}).then(res=>{*/}
                        {/*        if(res.url){*/}
                        {/*            window.open(res.url)*/}
                        {/*        }else{*/}
                        {/*            message.info("暂无Excel可导出")*/}
                        {/*        }*/}
                        {/*    })*/}
                        {/*}}  >导出</Button>*/}

                        <Button
                            type={"link"}
                            style={{ paddingLeft: 0 }}
                            onClick={() => {
                                exportliveSurveyQuestionnaireByUserAnswerUrl({
                                    id: record.id.toString(),
                                }).then((res) => {
                                    if (res.url) {
                                        window.open(res.url);
                                    } else {
                                        message.info("暂无Excel可导出");
                                    }
                                });
                            }}
                        >
                            导出回答数据
                        </Button>
                    </Flex>
                ),
            },
        ],
        dataSource: [],
        pageSize: 15,
        total: 0,
        currentPage: 1,
        loading: false,
        isSurveyQuestionnaireIcon: false,
    };

    refreshPage = () => {
        this.reqTableData(this.state.currentPage);
    };

    reqTableData(page: number) {
        this.setState({ loading: true });
        liveSurveyQuestionnaireIndex({
            live_id: liveStore.live_id,
            page,
            paginate: this.state.pageSize,
        })
            .then((res) => {
                this.setState({
                    dataSource: res.data,
                    total: res.total,
                    currentPage: page,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    componentDidMount(): void {
        //获取图标状态
        enableShowInfo({ live_id: liveStore.live_id, type: 10 }).then((res) => {
            this.setState({ isSurveyQuestionnaireIcon: res.is_show });
        });
        this.reqTableData(1);
    }

    render() {
        let {} = this.props;
        let {
            columns,
            dataSource,
            pageSize,
            isSurveyQuestionnaireIcon,
            total,
            currentPage,
            loading,
        } = this.state;
        return (
            <div>
                <FlexJustifyBetween>
                    <Flex>
                        <Switch
                            checked={isSurveyQuestionnaireIcon}
                            onChange={(v) => {
                                enableShowSet({
                                    live_id: liveStore.live_id,
                                    type: 10,
                                }).then((res) => {
                                    this.setState({
                                        isSurveyQuestionnaireIcon: res.is_show,
                                    });
                                });
                                // let crt = v
                                // presentStore.setSurveyQuestionnaire(crt);
                                // liveIconSet({
                                //     live_id : liveStore.live_id,
                                //     type : "survey_questionnaire",
                                //     status : crt ? 1 : 2
                                // }).catch(e => {
                                //     presentStore.setSurveyQuestionnaire(!crt);
                                //     message.error("设置失败 " + e.data?.message)
                                // })
                            }}
                        />
                        <Air horizontal={3} />
                        <div>开启问卷</div>
                    </Flex>
                    <Link to={"question/edit/new"}>
                        <Button
                            disabled={
                                presentStore.presentInfo
                                    .survey_questionnaire !== 1
                            }
                            type={"primary"}
                        >
                            添加问卷
                        </Button>
                    </Link>
                </FlexJustifyBetween>
                <Air vertical={5} />
                <Spin
                    indicator={<div />}
                    tip="尚未开启问卷"
                    spinning={!isSurveyQuestionnaireIcon}
                >
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={dataSource}
                        // emptyText={"暂无内容"}
                        pagination={{
                            defaultCurrent: 1,
                            current: currentPage,
                            pageSize: pageSize,
                            total: total,
                            onChange: (page) => {
                                this.reqTableData(page);
                                this.setState({ currentPage: page });
                            },
                        }}
                    />
                </Spin>
            </div>
        );
    }
}
