/**
 * 用户管理-积分详情
 * Create by jiangsong on 2020-08-18 13:59:50
 */
import * as React from 'react';
import {Component} from "react";
import {FlexJustifyBetween} from "../../../../../../../components/style/flex/flex-justify-between";
import {Flex} from "../../../../../../../components/style/flex/flex";
import {Button, Descriptions, Popover, Table, Input, DatePicker} from "antd";
import locale from 'antd/lib/date-picker/locale/zh_CN';
import {Air} from "../../../../../../../components/air/Air";
import {IuserUserCreditIndexItem, usersIndex,userUserCreditIndex} from "../../../../../../../common/apis/apis/live/usersIndex";
import {liveStore} from "../../live.store";
import {common} from "../../../../../../../common/common";
import {QuestionCircleOutlined} from "@ant-design/icons/lib";
import {Texts} from "../../../../../../../components/text/Texts";
import { RouteComponentProps } from 'react-router-dom';
const { Search } = Input;
const { RangePicker } = DatePicker;
interface IProps extends RouteComponentProps<{user_id : string,live_id:string}>{

}
interface IState{
    columns : any[]
    dataSource : IuserUserCreditIndexItem[],
    currentPage : number,
    total : number,
    pageSize: number,
    loading: boolean,
    createTime: [string, string],
    userName: string
}

export class CreditDetail extends Component<IProps,IState>{
    readonly state : IState = {
        columns : [
            {
                title: '用户昵称',
                dataIndex: 'name',
                key: 'name',
                render: (text : boolean) => text,
            },
            // {
            //     title: '得分原因',
            //     dataIndex: 'remark',
            //     key: 'remark',
            //     render: (text : boolean) => text,
            // },
            // {
            //     title: <Popover
            //         content={<div style={{width: 500}}>
            //             <Texts.TableTitle>
            //                 本积分是用户在本场直播中参与互动所得积分，用户可以使用积分来进行下载等
            //             </Texts.TableTitle>
            //             <Air vertical={10}/>
            //             <Texts.TableTitle>
            //                 具体规则：
            //             </Texts.TableTitle>
            //             <Air vertical={5}/>
            //             <Descriptions bordered column={1}>
            //                 <Descriptions.Item label="参与聊天">5分（5分钟以内发表得1分，以后每5分钟得1分）</Descriptions.Item>
            //                 <Descriptions.Item label="参与互动问答">5分（每次参与互动问答得5分）</Descriptions.Item>
            //                 <Descriptions.Item label="参与调研">5分（每次参与调研得5分）</Descriptions.Item>
            //                 <Descriptions.Item label="观看直播">1分（观看直播一分钟得1分）</Descriptions.Item>
            //             </Descriptions>
            //         </div>}
            //         placement={"right"}
            //     >
            //         <span>
            //             积分 <QuestionCircleOutlined />
            //         </span>
            //     </Popover>,
            //     dataIndex: 'number',
            //     key: 'number',
            //     render: (text: string) => text,
            // },
            // {
            //     title: '得分时间',
            //     dataIndex: 'change_time',
            //     key: 'change_time',
            //     render: (text: string) => text,
            // }
        ],
        dataSource : [],
        pageSize : common.defaultPaginate,
        total : 0,
        currentPage : 1,
        loading: false,
        createTime: ['', ''],
        userName: ''
    };

    reqTableData(page : number){
        const {user_id,live_id} = this.props.match.params
        this.setState({loading : true})
        userUserCreditIndex({
            live_id,
            user_id,
            page,
            paginate : this.state.pageSize
        }).then(res => {
            this.setState({
                dataSource : res.data,
                total : res.total,
                currentPage : page
            })
        }).finally(()=>{
            this.setState({loading : false})
        })
    }

    componentDidMount(): void {
        this.reqTableData(1)
    }

    handleSearch() { 
        // const { userName, createTime } = this.state;
        this.setState({
            loading: true,
            currentPage: 1
        })
        userUserCreditIndex({
            live_id : liveStore.live_id,
            page: 1,
            paginate: this.state.pageSize,
        }).then(res => {
            this.setState({
                dataSource : res.data,
                total : res.total
            })
        }).finally(()=>{
            this.setState({loading : false})
        })
    }

    handleExport() { 
        console.log('export');
    }

    render() {
        let {} = this.props;
        let {columns, dataSource, pageSize, total, currentPage, loading} = this.state;
        return (
            <div>
                <FlexJustifyBetween>
                    <Flex>

                    </Flex>
                    <Flex>
                        <Button onClick={() => this.handleExport()}>导出</Button>
                    </Flex>
                </FlexJustifyBetween>
                <Air vertical={5}/>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={dataSource}
                    // emptyText={"暂无内容"}
                    pagination={{
                        defaultCurrent : 1,
                        current : currentPage,
                        pageSize : pageSize,
                        total : total,
                        onChange : page => {
                            this.reqTableData(page);
                            this.setState({currentPage : page})
                        }
                    }}
                />
            </div>

        );
    }
}


interface UserCreditProps{
    userId:String
}

interface UserCreditState{
    dataList:Array<any>
}

// class userCreditModel extends Component<UserCreditProps,UserCreditState>{
//     constructor(props:UserCreditProps){
//         super(props);
//         this.state={
//             loading:false,
//             dataList:[],
//             currentPage:0,
//             pageSize:10,
//             total:0,
//         }
//     }
//     render(){
//         return (
//             <Table
//                     loading={loading}
//                     columns={columns}
//                     dataSource={dataSource}
//                     // emptyText={"暂无内容"}
//                     pagination={{
//                         defaultCurrent : 1,
//                         current : currentPage,
//                         pageSize : pageSize,
//                         total : total,
//                         onChange : page => {
//                             this.reqTableData(page);
//                             this.setState({currentPage : page})
//                         }
//                     }}
//                 />
//         )
//     }
// }

const styles = {
    searchStyle: {
        width: '250px',
        marginRight: '10px'
    }
};
