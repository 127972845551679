/**
 * 邮件发送记录-列表
 * Create by liaokai on 2020-04-21 17:50:39
 */
import * as React from 'react';
import {Component} from "react";
import {Button, message, Modal, Space, Table} from "antd";
import {RouteComponentProps} from "react-router";
import {apis} from "../../../../../../common/apis/apis";
import moment from "moment";
import {EmailEditAddressBook} from "./emailEditAddressBook";
import {EmailAddAddressBook} from "./emailAddAddressBook";
import {Air} from "../../../../../../components/air/Air";
import {Link} from "react-router-dom";
import {EmailDelAddressBook} from "./emailDelAddressBook";

interface IProps extends RouteComponentProps<any>{

}

interface IState{
    listData : IEmailAddressBookList[],
    currentPage : number,
    total : number,
    pageSize : number,
    columns : any,
    momentNow : any,
    currentItem : IEmailAddressBookList | undefined,
    showDelSetting : boolean
    showShareSetting : boolean
    showAddSetting : boolean
}


interface IEmailAddressBookList {
    id : number,          //序号（仅占位）
    name : string,          //序号（仅占位）
    count: number,      //模版名
}


export class AddressBookTable extends Component<IProps,IState>{

    readonly state : IState = {
        listData : [],
        pageSize : 10,
        showShareSetting:false,
        showAddSetting:false,
        showDelSetting:false,
        currentPage : 1,
        total : 0,
        currentItem : undefined,
        columns : [
            {
                title: '管理组名',
                dataIndex: 'name',
                key: 'name',
                render: (text: string) => text,
            },
            {
                title: '管理组人数',
                dataIndex: 'count',
                key: 'count',
                // render: (text: number) => text,
                render: (text: string, record : IEmailAddressBookList) => (
                    record.count+"位联系人"
                ),
            }
            ,
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                render: (text: string, record : IEmailAddressBookList) => (
                    <Space>
                        <Button  type={"link"}
                                 style={{padding : 0, marginRight: 10}}
                                 onClick={()=>{
                            this.setState({showShareSetting : true, currentItem : record})
                        }} >编辑</Button>
                        <Button type={"link"}
                                style={{padding : 0, marginRight: 10}}
                                onClick={()=>{
                            // message.info("暂未开放")
                        }}> <Link to={`personnel/users/${record.id}`}>编辑分组名单</Link></Button>

                        <Button   type={"link"}
                                  style={{padding : 0, marginRight: 10}}
                                  onClick={()=>{
                            this.setState({showDelSetting : true, currentItem : record})
                        }} >删除</Button>
                        {/*<Button size={"small"} icon={<ScissorOutlined />} onClick={()=>{*/}
                        {/*    message.info("暂未开放")*/}
                        {/*}}>编辑3</Button>*/}
                    </Space>
                ),
            }
        ],
        momentNow : moment.now()
    };
    refreshPage = ()=>{
        console.log(this.state.pageSize)
        this.reqListData(this.state.currentPage,this.state.pageSize)
    }
    reqListData(page : number = 1,pageSize:number=this.state.pageSize){
        apis.emailAddressBookList({page : page,paginate:pageSize}).then(res => {
            this.setState({
                currentPage : res.current_page,
                // pageSize : res.per_page,
                total : res.total,
                listData : res.list.map(value => {
                    return {
                        id : value.id,
                        name : value.name,
                        count : value.count,
                        operation : undefined,
                    }
                })
            })
        })
    }

    timer : any

    componentDidMount(): void {
        this.reqListData(1)
        // this.timer = setInterval(()=>{
        //     this.reqListData(this.state.currentPage);
        //     this.setState({momentNow : moment.now()})
        // },3000)
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        let {} = this.props;

        let {pageSize,currentPage, total, listData, columns,showShareSetting,currentItem,showAddSetting,showDelSetting} = this.state;
        return (
            <>
                <div style={{display: "flex", justifyContent : "flex-end", alignItems: "center"}}>

                    <Button type={"primary"}  shape={"round"} onClick={()=>{
                        this.setState({showAddSetting : true})
                    }}>添加分组</Button>
                    {/*<Button type={"primary"} size={"small"}  shape={"round"} onClick={()=>{*/}
                    {/*    message.info("暂未开放")*/}
                    {/*}}>导入</Button>*/}

                </div>
                <Air vertical={5}/>
                <Table
                    columns={columns}
                    dataSource={listData}
                    pagination={{
                        current : currentPage,
                        pageSize : pageSize,
                        total : total,
                        onChange : (page,pageSize) => {
                            this.reqListData(page,pageSize);
                            this.setState({currentPage : page})
                            console.log(this.state.pageSize)
                        },
                        onShowSizeChange:(currentPage,pageSize)=>{
                            this.setState({pageSize : Number(pageSize)})
                            // console.log(this.state.pageSize)
                            this.reqListData(currentPage,pageSize);
                        }
                    }} emptyText={"暂无发送记录"}/>
                <EmailEditAddressBook visible={showShareSetting} editItem={currentItem}  onCancel={()=>{
                    this.setState({showShareSetting : false})
                }} onSubmit={()=>{
                    this.refreshPage();
                }}/>
                <EmailAddAddressBook visible={showAddSetting}  onCancel={()=>{
                    this.setState({showAddSetting : false})
                }} onSubmit={()=>{
                    this.refreshPage();
                }}/>
                <EmailDelAddressBook visible={showDelSetting} editItem={currentItem} onCancel={()=>{
                    this.setState({showDelSetting : false})
                }} onSubmit={()=>{
                    message.success('删除成功')
                    this.refreshPage();
                }}/>
            </>
        );
    }
}
