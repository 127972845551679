/**
 * 用户列表
 * Create by liaokai on 2020-05-28 11:03:13
 */
import { toPost } from "../../http-utils";

export interface IUsersIndexItem {
    id: number;
    name: string; //姓名
    forbid: number; //姓名
    create_time: string; //创建时间
    password: string; //创建时间
    invitation_code: string; //创建时间
    is_pay: boolean; //创建时间
    white_list: string; //创建时间
    credit: string; //积分
}

export const usersIndex = toPost<
    {
        live_id: string;
        userName?: string;
        createTime?: [string, string];
        page: number;
        paginate?: number;
    },
    {
        current_page: number;
        total: number;
        last_page: number;
        data: IUsersIndexItem[];
    }
>("/merchant/live/usersIndex");

export interface IuserUserCreditIndexItem {
    id: string;
    name: string; //用户
    type: string; //类型
    number: string; //表动数量
    change_time: string; //变动时间
    remark: string; //备注
}

export const userUserCreditIndex = toPost<
    {
        live_id: string;
        user_id?: string;
        page: number;
        paginate?: number;
    },
    {
        current_page: number;
        total: number;
        last_page: number;
        data: IuserUserCreditIndexItem[];
    }
>("/merchant/live/userUserCreditIndex");

export const userUserForbidSet = toPost<
    {
        live_id: string;
        forbid_type: string;
        live_user_id: number[];
    },
    any
>("/merchant/live/usersForbidSet");

export const getOnlineCount = toPost<{ live_id: string }, any>(
    `/merchant/live/getOnlineCount`
);
