/**
 * 邮件发送记录-列表
 * Create by liaokai on 2020-04-21 17:50:39
 */
import * as React from 'react';
import {Component} from "react";
import {Button, message, Table} from "antd";
import {Tip} from "../../../../../../../../components/tip/tip.stateless";
import {Link} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {apis} from "../../../../../../../../common/apis/apis";
import {IsmsTemplateLogListItem} from "../../../../../../../../common/apis/apis/sms/smsTemplateLogList";
import {common} from "../../../../../../../../common/common";
import {Air} from "../../../../../../../../components/air/Air";
import {emailTemplateLogList} from "../../../../../../../../common/apis/apis/email/emailTemplateLogList";
import moment from "moment";
import {emailTemplateLogCancel} from "../../../../../../../../common/apis/apis/email/emailTemplateLogCancel";
import {RecordCancel} from "../../../../../../email/menu/record/record-cancel";

interface IProps extends RouteComponentProps<any>{

}

interface IState{
    listData : ISMSTemplateItem[],
    currentPage : number,
    total : number,
    pageSize : number,
    columns : any,
    momentNow : any,
    showCancelSend : boolean,
    id : number
}


interface ISMSTemplateItem {
    id : number,
    index : undefined,          //序号（仅占位）
    isTiming : boolean,          //序号（仅占位）
    templateTitle: string,      //模版名
    sendTime: string,           //发送时间
    count : string,             //数量统计
    success : number,
    fail : number,
    sending : number,
    operation: undefined,       //操作（仅占位）
    status : number         //发送状态 1已经发送2取消发送3发送失败4等待发送5发送中
    balance_num : number         // 消耗的余额发送短息数量
    package_num : number         // 消耗的邮件包发送短息数量
}

export class SendRecordTable extends Component<IProps,IState>{
    readonly state : IState = {
        listData : [],
        pageSize : 10,
        currentPage : 1,
        total : 0,
        showCancelSend :false,
        id : 0,
        columns : [
            {
                title: '编号',
                dataIndex: 'id',
                key: 'id',
                render: (id : number)=>id

            },
            {
                title: '模版标题',
                dataIndex: 'templateTitle',
                key: 'templateTitle',
                render: (text: string) => text,
            },
            {
                title: '发送时间',
                dataIndex: 'sendTime',
                key: 'sendTime',
                render: (text: string,record:ISMSTemplateItem) =>
                <div>
                    <span>
                        {record.isTiming ? "[定时发送]":""}
                    </span>
                    <br/>
                    <span>
                    {text}
                    </span>
                </div>
                ,
            },
            {
                title: <div style={{display: "flex"}}>
                    <div>
                        发送总数
                    </div>
                </div>,
                dataIndex: 'count',
                key: 'count',
                render: (text: string) => text,
            },
            {
                title: '状态',
                dataIndex: 'sendStatus',
                key: 'sendStatus',
                render: (text: string, record : ISMSTemplateItem, index : number) => <div style={{opacity : "90%", fontSize : 10}}>
                    <span style={{color : common.color.success}}>成功: {record.success}</span>
                    <Air horizontal={3}/>
                    <span style={{color : common.color.danger}}>失败: {record.fail}</span>
                    <Air horizontal={3}/>
                    <span style={{color : common.color.warning}}>发送中: {record.sending}</span>
                </div>,
            },
            {
                title: '扣费信息',
                dataIndex: 'info',
                key: 'info',
                render: (text: string, record : ISMSTemplateItem, index : number) => <div style={{opacity : "90%", fontSize : 10}}>
                    <span style={{color : common.color.success}}>短信包: {record.package_num} 条</span>
                    <Air horizontal={8}/>
                    <span style={{color : common.color.warning}}>余额: {record.balance_num} 条</span>
                </div>,
            },
            {
                title: "操作",
                dataIndex: 'operation',
                key: 'operation',
                render: (text: string, record : ISMSTemplateItem) => <div>
                    <Link to={`${this.props.location.pathname}/email-multi_send_detail/${record.id}`}>
                        查看详情
                    </Link>
                    {
                        moment(record.sendTime, "YYYY/MM/DD HH:mm:ss").isAfter(moment.now()) && record.status !== 2 ?
                            <Button type={"link"} onClick={()=>{
                                // apis.emailTemplateLogCancel({id : record.id}).then(res => {
                                //     message.success("已取消")
                                //     this.reqListData(this.state.currentPage);
                                // }).catch(e => {
                                //     message.error("取消失败 " + e?.data?.message)
                                // })
                                this.setState({showCancelSend : true,id:record.id})
                            }}>取消发送</Button>
                            :
                            undefined
                    }
                </div>,
            },
        ],
        momentNow : moment.now()
    };
    refreshCurrent(){
        this.reqListData(this.state.currentPage)
    }
    reqListData(page : number = 1){
        apis.emailTemplateLogList({page : page,paginate:this.state.pageSize}).then(res => {
            this.setState({
                // pageSize : res.per_page,
                currentPage : res.current_page,
                total : res.total,
                listData : res.data.map(value => {
                    return {
                        index : undefined,
                        isTiming : value.isTiming,
                        templateTitle : value.title,
                        sendTime : value.send_time,
                        count : value.all.toString(),
                        success : value.success,
                        fail : value.fail,
                        sending : value.being,
                        operation : undefined,
                        id : value.id,
                        status : value.status,
                        balance_num : value.balance_num ,        // 消耗的余额发送短息数量
                        package_num : value.package_num  ,       // 消耗的邮件包发送短息数量
                    }
                })
            })
        })
    }

    timer : any

    componentDidMount(): void {
        this.reqListData(1)
        this.timer = setInterval(()=>{
            this.reqListData(this.state.currentPage);
            this.setState({momentNow : moment.now()})
        },3000)
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        let {} = this.props;
        let {pageSize,currentPage, total, listData, columns,showCancelSend,id} = this.state;
        return (
            <>
                <Table
                    columns={columns}
                    dataSource={listData}
                    pagination={{
                        current : currentPage,
                        pageSize : pageSize,
                        total : total,
                        onChange : (page,pageSize) => {
                            this.reqListData(page);
                            this.setState({currentPage : page})
                        },
                        onShowSizeChange:(currentPage,pageSize)=>{
                            this.reqListData(currentPage);
                            this.setState({pageSize : Number(pageSize)})
                        }
                    }} emptyText={"暂无发送记录"}
                />
                <RecordCancel
                    visible={showCancelSend}
                    id={id}
                    onCancel={()=>{
                        this.setState({showCancelSend:false})
                        this.refreshCurrent()
                    }}
                    onSubmit={(msg)=>{
                        this.setState({showCancelSend:false})
                        message.success(msg)
                        this.refreshCurrent()
                    }}
                >
                </RecordCancel>

            </>
        );
    }
}
