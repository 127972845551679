/**
 * 邮件模板列表
 * Create by liaokai on 2020-04-23 17:52:00
 */
import {toPost} from "../../http-utils";
export const emailAddressStore = toPost<
    {
        email_address_book_id : number,
        email : string,
        name : string,
    },
    {
        // "total": number,
        // "per_page": number,
        // "current_page": number,
        // "last_page": number,
        // "list": IEmailAddressListItem[]
    }
    >("/merchant/Email/emailAddressStore");
