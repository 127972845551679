/**
 * 结束直播回调
 * Create by liaokai on 2020-05-29 15:15:31
 */
import {toPost} from "../../http-utils";

export const endLive = toPost<
    {
        live_id : string
    },
    {

    }
>("/merchant/live/endLive");

