// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ParticleFlowBox_ParticleFlowBox__1cU-z{width:100%;height:100%;-webkit-filter:blur(0px);filter:blur(0px);position:absolute;top:50%;left:50%;transform:translateX(-50%) translateY(-50%);z-index:0}@-webkit-keyframes ParticleFlowBox_bluranmi__15uKo{0%{-webkit-filter:blur(2px);filter:blur(2px)}50%{-webkit-filter:blur(3px);filter:blur(3px)}100%{-webkit-filter:blur(2px);filter:blur(2px)}}@keyframes ParticleFlowBox_bluranmi__15uKo{0%{-webkit-filter:blur(2px);filter:blur(2px)}50%{-webkit-filter:blur(3px);filter:blur(3px)}100%{-webkit-filter:blur(2px);filter:blur(2px)}}\n", ""]);
// Exports
exports.locals = {
	"ParticleFlowBox": "ParticleFlowBox_ParticleFlowBox__1cU-z",
	"bluranmi": "ParticleFlowBox_bluranmi__15uKo"
};
module.exports = exports;
