/**
 * 获取用户信息
 * Create by liaokai on 2020-04-03 11:26:53
 */
import {toPost} from "../http-utils";

export const getUserInfo = toPost<
    {

    },
    {
        "mobile": string,
        "email": string,
        "information": number
    }
    >
("/merchant/merchant/index");
