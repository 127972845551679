/**
 * 直播管理 menu
 * Create by liaokai on 2020-05-23 17:11:37
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {tMenuVerticalTreeWithComp} from "../../../user/UserIndexView";
interface IProps extends RouteComponentProps<{live_id : string}>{

}

interface IState{
}
export class  defaultRoute extends Component<IProps, IState>{

    render() {
        return(
            <div>
                默认页面
            </div>
        );
    }
}