/**
 * 邮箱编辑
 * Create by liaokai on 2020-04-09 16:08:54
 */
import * as React from 'react';
import {Component} from "react";
import style from "./email-setting.scss"
import emailImg from "../../../../../../assets/e-mailicon.png"
import securityImg from "../../../../../../assets/sign/dun.png"
import {emailBind} from "../../../../../../common/apis/apis/email/email_bind";
import {message} from "antd";
import {common} from "../../../../../../common/common";
import {getEmailVercode} from "../../../../../../common/apis/apis/get-email-vercode";

interface IProps{
    type :  "new" | "edit",
    onClose? : () => void,
    onSuccess? : ()=> void
}

interface IState{
    lastPassword : string,
    emailName : string,
    emailCode : string,
    countdown : number
}

export class EmailSetting extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {
        lastPassword : "",
        emailName : "",
        emailCode : "",
        countdown : -1
};

    static defaultProps = {
        type : "new"
    };
    render() {
        let {type, onClose, onSuccess} = this.props;
        let {lastPassword, emailCode, emailName, countdown} = this.state;
        return (
            <div className={style.SettingBox}>
                {
                    type !== "new" ?
                        <>
                            <div className={style.SettingTitle}>{"绑定邮箱"}</div>
                            <div className={style.VerificationBox}>
                                <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
                                    <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                                        <img src={emailImg} alt=""/>
                                    </div>
                                    <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                                        <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                                            邮箱地址
                                        </div>
                                        <div className={style.Inputs}>
                                            <input type="text" placeholder={'请填写邮箱地址'} value={emailName} onChange={(event)=>{
                                                this.setState({emailName : event.target.value})
                                            }}/>
                                        </div>
                                    </div>
                                </div>

                                <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
                                    <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                                        <img src={securityImg} alt=""/>
                                    </div>
                                    <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                                        <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                                            邮箱验证码
                                        </div>
                                        <div className={style.PhoneCodeInput}>
                                            <input type="text" placeholder={'请填写验证码'} value={emailCode} onChange={(event)=>{
                                                this.setState({emailCode : event.target.value})
                                            }}/>
                                        </div>
                                        <div onClick={()=>{
                                            const down = ()=>{
                                                if(this.state.countdown > 0){
                                                    setTimeout(()=>{
                                                        this.setState({countdown : this.state.countdown-1}, down)
                                                    },1000)
                                                }
;                                            };
                                            this.setState({countdown : 60}, down);
                                        }} className={[style.SendCode,'childcenter'].join(' ')}><span>{countdown <= 0?'获取验证码': countdown+'s'}</span></div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className={style.SettingTitle}>{"新邮箱设置"}</div>
                            <div className={style.VerificationBox}>
                                <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
                                    <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                                        <img src={emailImg} alt=""/>
                                    </div>
                                    <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                                        <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                                            密码
                                        </div>
                                        <div className={style.Inputs}>
                                            <input type={"password"} placeholder={'请填写会佰分密码'} value={lastPassword} onChange={(event)=>{
                                                this.setState({lastPassword : event.target.value})
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.VerificationBox}>
                                <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
                                    <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                                        <img src={emailImg} alt=""/>
                                    </div>
                                    <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                                        <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                                            邮箱地址
                                        </div>
                                        <div className={style.Inputs}>
                                            <input type="text" placeholder={'请填写邮箱地址'} value={emailName} onChange={(event)=>{
                                                this.setState({emailName : event.target.value})
                                            }}/>
                                        </div>
                                    </div>
                                </div>

                                <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
                                    <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                                        <img src={securityImg} alt=""/>
                                    </div>
                                    <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                                        <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                                            邮箱验证码
                                        </div>
                                        <div className={style.PhoneCodeInput}>
                                            <input type="text" placeholder={'请填写验证码'} value={emailCode} onChange={(event)=>{
                                                this.setState({emailCode : event.target.value})
                                            }}/>
                                        </div>
                                        <div onClick={()=>{
                                            const down = ()=>{
                                                if(this.state.countdown > 0){
                                                    setTimeout(()=>{
                                                        this.setState({countdown : this.state.countdown-1}, down)
                                                    },1000)
                                                }
                                            };
                                            this.setState({countdown : 60}, down);
                                            getEmailVercode({email : emailName}).then(res => {
                                                message.success("验证码已发送")
                                            }).catch(e=>{
                                                message.error("验证码发送失败")
                                            });
                                        }} className={[style.SendCode,'childcenter'].join(' ')}><span>{countdown <= 0?'获取验证码': countdown+'s'}</span></div>
                                    </div>
                                </div>
                            </div>
                        </>
                }
                <div className={[style.HandleStatusGroup,'childcenter'].join(' ')}>
                    <div className={[style.StatusButton,style.bluebutton,'childcenter'].join(' ')} onClick={()=>{
                        emailBind({
                            email : emailName,
                            verifycode : emailCode ,
                            password : lastPassword
                        }).then(res => {
                            onClose?.();
                            onSuccess?.()
                            message.success("保存成功");
                        }).catch(e => {
                            message.error(e.data.message);
                            common.sentry.captureException(e);
                        });
                    }}>确定</div>
                    <div className={[style.StatusButton,style.graybutton,'childcenter'].join(' ')} onClick={()=>{onClose?.()}}>取消</div>
                </div>
            </div>
        );
    }
}
