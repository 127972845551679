/**
 * 问卷调查-新建/编辑
 * Create by liaokai on 2020-06-16 14:39:24
 */
import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import {
    Affix,
    Button,
    Card,
    Collapse,
    Input,
    message,
    Modal,
    Radio,
    Space,
    Checkbox,
} from "antd";
import { Flex } from "../../../../../../../components/style/flex/flex";
import { Texts } from "../../../../../../../components/text/Texts";
import { TitleContentTable } from "../../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import { Air } from "../../../../../../../components/air/Air";
import { FlexJustifyCenter } from "../../../../../../../components/style/flex/flex-justify-center";
import { FlexJustifyBetween } from "../../../../../../../components/style/flex/flex-justify-between";
import { PlusOutlined } from "@ant-design/icons/lib";
import { questionUpdate } from "../../../../../../../common/apis/apis/live/questionUpdate";
import { liveStore } from "../../live.store";
import {
    IQuestionStoreQuestion,
    questionStore,
} from "../../../../../../../common/apis/apis/live/questionStore";
import { AddQuestionForm } from "../../qa/component/add-question-form";
import { liveSurveyQuestionnaireStore } from "../../../../../../../common/apis/apis/live/liveSurveyQuestionnaireStore";
import { liveSurveyQuestionnaireShow } from "../../../../../../../common/apis/apis/live/liveSurveyQuestionnaireShow";
import { liveSurveyQuestionnaireUpdate } from "../../../../../../../common/apis/apis/live/liveSurveyQuestionnaireUpdate";
import invariant from "invariant";

const { Panel } = Collapse;

interface IProps
    extends RouteComponentProps<{ live_id: string; question_id: string }> {}

interface IState {
    answerName: string; //题目名
    showAddModal: boolean; //显示添加问题框
    addQuestionFormData: IQuestionStoreQuestion;
    questionFormList: IQuestionStoreQuestion[]; //正在展示的问卷题目
    editingIndex: number; //正在编辑的题目下标
    isDetail: boolean;
}

const defaultFormData: IQuestionStoreQuestion = {
    title: "",
    type: "single",
    content: [],
};

export class LiveQuestionEdit extends Component<IProps, IState> {
    readonly state: IState = {
        answerName: "",
        showAddModal: false,
        addQuestionFormData: defaultFormData,
        questionFormList: [],
        editingIndex: -1,
        isDetail: false,
    };

    hideModal = () => {
        this.setState({
            showAddModal: false,
            addQuestionFormData: defaultFormData,
        });
    };

    onSubmit = () => {
        try {
            let {
                addQuestionFormData,
                questionFormList,
                editingIndex,
            } = this.state;

            const currentQuestion: IQuestionStoreQuestion = Object.assign({
                ...addQuestionFormData,
            });

            console.log(
                "addQuestionFormData.title.trim() === ",
                addQuestionFormData.title.trim() === ""
            );
            if (addQuestionFormData.title.trim() === "") {
                message.error("请输入标题！");
                return;
            }
            let optionToken = true;
            addQuestionFormData.content.forEach((item) => {
                if (item.title.trim() === "") {
                    optionToken = false;
                }
            });
            if (!optionToken) {
                message.error("选项不能为空！");
                return;
            }
            console.log("addQuestionFormData", addQuestionFormData);
            console.log("questionFormList", questionFormList);
            console.log("editingIndex", editingIndex);
            // if (currentQuestion.type === "single") {
            //     invariant(
            //         currentQuestion.content.length > 0 &&
            //             currentQuestion.content.filter(
            //                 (item) => item.isAnswer === true
            //             ).length === 1,
            //         "请设置正确答案！"
            //     );
            // } else {
            //     invariant(
            //         currentQuestion.content.length > 0 &&
            //             currentQuestion.content.some(
            //                 (item) => item.isAnswer === true
            //             ),
            //         "请设置正确答案！"
            //     );
            // }
            if (editingIndex >= 0) {
                //编辑
                questionFormList[editingIndex] = addQuestionFormData;
                this.setState({
                    questionFormList: questionFormList,
                    showAddModal: false,
                    addQuestionFormData: defaultFormData,
                });
            } else {
                //新建
                this.setState({
                    questionFormList: questionFormList.concat(
                        addQuestionFormData
                    ),
                    showAddModal: false,
                    addQuestionFormData: defaultFormData,
                });
            }
        } catch (error) {
            message.warn(error.message);
        }
    };

    componentDidMount() {
        let {
            history,
            match: {
                params: { question_id },
            },
        } = this.props;
        if (this.props.match.url.indexOf("detail") > 0) {
            this.setState({ isDetail: true });
        }
        if (question_id) {
            liveSurveyQuestionnaireShow({
                id: question_id,
            })
                .then((res) => {
                    this.setState({
                        questionFormList: res.data,
                        answerName: res.title,
                    });
                })
                .catch((e) => {
                    message.error("加载失败 " + e.data?.message);
                });
        }
    }

    render() {
        let {
            history,
            match: {
                params: { question_id },
            },
        } = this.props;
        let {
            isDetail,
            answerName,
            showAddModal,
            addQuestionFormData,
            questionFormList,
            editingIndex,
        } = this.state;
        return (
            <div style={{flex: 1}}>
                <Texts.ChapterTitle>问卷信息</Texts.ChapterTitle>
                <Air vertical={5} />
                <TitleContentTable colGap={10} rowGap={10}>
                    <div>问卷名称</div>
                    <Input
                        disabled={isDetail}
                        placeholder={"请输入问卷名称"}
                        value={answerName}
                        onChange={(event) => {
                            this.setState({ answerName: event.target.value });
                        }}
                    />
                </TitleContentTable>
                <Air vertical={15} />
                {!isDetail && (
                    <FlexJustifyBetween>
                        <Texts.ChapterTitle>问卷题目</Texts.ChapterTitle>
                        <Affix offsetTop={80}>
                            <Button
                                type={"primary"}
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    this.setState({
                                        showAddModal: true,
                                        addQuestionFormData: defaultFormData,
                                        editingIndex: -1,
                                    });
                                }}
                            >
                                添加题目
                            </Button>
                        </Affix>
                    </FlexJustifyBetween>
                )}
                <Air vertical={5} />
                <div>
                    {questionFormList.map((value, index) => {
                        return (
                            <>
                                <Card
                                    title={value.title}
                                    extra={
                                        !isDetail ? (
                                            <Space>
                                                <Button
                                                    type={"primary"}
                                                    ghost
                                                    size={"small"}
                                                    onClick={() => {
                                                        this.setState({
                                                            showAddModal: true,
                                                            addQuestionFormData: value,
                                                            editingIndex: index,
                                                        });
                                                    }}
                                                >
                                                    编辑
                                                </Button>
                                                <Button
                                                    danger={true}
                                                    size={"small"}
                                                    onClick={() => {
                                                        this.setState({
                                                            questionFormList: questionFormList.filter(
                                                                (
                                                                    value1,
                                                                    index1
                                                                ) =>
                                                                    index1 !==
                                                                    index
                                                            ),
                                                        });
                                                    }}
                                                >
                                                    删除
                                                </Button>
                                            </Space>
                                        ) : (
                                            <div />
                                        )
                                    }
                                >
                                    <TitleContentTable rowGap={10} colGap={20}>
                                        <Texts.TableTitle>
                                            类型:
                                        </Texts.TableTitle>
                                        <div>
                                            {value.type === "single"
                                                ? "单选"
                                                : value.type === "multi"
                                                ? "多选"
                                                : "问答"}
                                        </div>
                                        {value.type !== "qa" && (
                                            <Texts.TableTitle
                                                style={{
                                                    alignSelf: "flex-start",
                                                }}
                                            >
                                                选项:
                                            </Texts.TableTitle>
                                        )}
                                        {value.type !== "qa" && (
                                            <div>
                                                {value.content.map((value1) => {
                                                    return (
                                                        <Flex>
                                                            {value.type ===
                                                            "single" ? (
                                                                <Radio
                                                                    checked={
                                                                        value1.isAnswer
                                                                    }
                                                                ></Radio>
                                                            ) : (
                                                                <Checkbox
                                                                    checked={
                                                                        value1.isAnswer
                                                                    }
                                                                ></Checkbox>
                                                            )}
                                                            <div
                                                                style={{
                                                                    marginLeft:
                                                                        "5px",
                                                                }}
                                                            >
                                                                {value1.title}
                                                            </div>
                                                        </Flex>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </TitleContentTable>
                                </Card>
                                <Air vertical={5} />
                            </>
                        );
                    })}
                </div>
                <Air vertical={15} />
                <FlexJustifyCenter>
                    {!isDetail ? (
                        <Space size={"large"}>
                            <Button
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                取消
                            </Button>
                            <Button
                                type={"primary"}
                                onClick={() => {
                                    Modal.confirm({
                                        title: "提示",
                                        content:
                                            "保存之后不能进行修改和编辑，是否保存？",
                                        okText: "保存",
                                        cancelText: "取消",
                                        onOk: () => {
                                            //编辑
                                            console.log(
                                                "questionFormList",
                                                questionFormList
                                            );
                                            if (question_id) {
                                                liveSurveyQuestionnaireUpdate({
                                                    live_id: liveStore.live_id,
                                                    title: answerName,
                                                    data: JSON.stringify(
                                                        questionFormList
                                                    ) as any,
                                                    id: question_id,
                                                })
                                                    .then((res) => {
                                                        message.success(
                                                            "更新成功"
                                                        );
                                                        history.goBack();
                                                    })
                                                    .catch((e) => {
                                                        message.error(
                                                            "更新失败 " +
                                                                e.data?.message
                                                        );
                                                    });
                                            }
                                            //新增
                                            else {
                                                liveSurveyQuestionnaireStore({
                                                    live_id: liveStore.live_id,
                                                    title: answerName,
                                                    data: JSON.stringify(
                                                        questionFormList
                                                    ) as any,
                                                })
                                                    .then((res) => {
                                                        message.success(
                                                            "添加成功"
                                                        );
                                                        history.goBack();
                                                    })
                                                    .catch((e) => {
                                                        message.error(
                                                            "添加失败 " +
                                                                e.data?.message
                                                        );
                                                    });
                                            }
                                        },
                                    });
                                }}
                            >
                                保存
                            </Button>
                        </Space>
                    ) : (
                        <Space size={"large"}>
                            <Button
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                返回
                            </Button>
                        </Space>
                    )}
                </FlexJustifyCenter>

                {/*悬浮*/}
                <Modal
                    visible={showAddModal}
                    title={"添加题目"}
                    okText={"保存"}
                    cancelText={"取消"}
                    onOk={() => this.onSubmit()}
                    onCancel={() => {
                        this.hideModal();
                    }}
                >
                    <AddQuestionForm
                        data={addQuestionFormData}
                        onChange={(data: any) => {
                            this.setState({ addQuestionFormData: data });
                        }}
                    />
                </Modal>
            </div>
        );
    }
}
