/**
 * 文档发布
 */
import {toPost} from "../../http-utils";


export const publishDoc = toPost<
    {
        doc_id:number
    },
    {
    }
>("/merchant/live/publishDoc");
