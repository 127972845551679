/**
 * 用户管理 - 路由
 * Create by liaokai on 2020-05-23 17:33:38
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {SurfaceRouter, tSurfaceRouteItem} from "../../../../../../components/surface-router/surface-router";
import {LiveUserView} from "./user";
import {LiveUserDetail} from "./detail/detail";
import {CreditDetail} from "./detail/credit-detail";

interface IProps extends RouteComponentProps{

}

interface IState{

}

const routerData : tSurfaceRouteItem = {
    breadTitle : "用户管理",
    routeName : "",
    component : LiveUserView,
    children : [
        {
            breadTitle : "详情",
            routeName : "detail/:user_id",
            component : LiveUserDetail,
            children : []
        },
        {
            breadTitle : "用户积分",
            routeName : "credit-detail/:live_id/:user_id",
            component : CreditDetail,
            children : []
        }
    ]
}

export class LiveUserRouter extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={routerData}/>
        );
    }
}
