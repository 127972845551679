/**
 * 邮件取消发送
 * Create by liaokai on 2020-05-21 14:09:26
 */
import {toPost} from "../../http-utils";

export const emailTemplateLogCancel = toPost<
    {
        id : number
    },
    {

    }
>("/merchant/email/emailTemplateLogCancel");
