/**
 * 充值记录列表
 * Create by liaokai on 2020-04-30 14:12:14
 */
import * as React from 'react';
import {Component} from "react";
import {Table} from "antd";
import {apis} from "../../../../../../common/apis/apis";
import {getPrepaidTable, IPrepaidTableItem} from "../../../../../../common/apis/apis/getPrepaidTable";

interface IProps{

}

interface IState{
    columns : any,
    dataSource : IPrepaidTableItem[],
    currentPage: number,
    total : number,
    loading : boolean
}

export class PrepaidTable extends Component<IProps,IState>{
    readonly state : IState = {
        columns : [
            {
                title: '交易编号',
                dataIndex: 'order_num',
                key: 'order_num',
                render: (text: string) => text,

            },
            {
                title: '交易时间',
                dataIndex: 'create_time',
                key: 'create_time',
                render: (text: string) => text,
            },
            {
                title: '收支类型',
                dataIndex: 'optype',
                key: 'optype',
                render: (text: string) => text,
            },
            {
                title: '交易方式',
                dataIndex: 'type',
                key: 'type',
                render: (text: number) => text
            },
            {
                title: '金额',
                dataIndex: 'price',
                key: 'price',
                render: (text: number) => `${text} 元`,
            }
        ],
        dataSource :[],
        total : 0,
        currentPage : 1,
        loading : false
    };

    componentDidMount() {
        this.reqListData();
    }

    reqListData(page: number = 1){
        this.setState({loading : true})
        apis.getPrepaidTable({
            page,
        }).then(res => {
            this.setState({dataSource : res.list, total : res.total})
        }).finally(()=>{
            this.setState({loading : false})
        })
    }

    render() {
        let {} = this.props;
        let {columns,dataSource, total, currentPage, loading} = this.state;
        return (
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    current: currentPage,
                    total: total,
                    onChange: page => {
                        this.reqListData(page);
                        this.setState({currentPage: page})
                    }
                }}
                loading={loading}
            />
        );
    }
}
