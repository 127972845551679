import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { liveStore } from "../../live.store";
import { message } from "antd";
import { common } from "../../../../../../../common/common";
import { bindClient } from "../constants";
import moment from "moment";
import { getBanner } from "../constants";

type MessageItem = {
    name: string;
    message: string;
    create_time: string;
    headimgurl: string;
    type_name: string;
    type: string;
};

export default (props: any) => {
    const history = useHistory();
    const [ws, setWs] = useState({} as WebSocket);
    const [bullets, setBullets] = useState([] as any[]);
    const [banner, setBanner] = useState("");

    useEffect(() => {
        getBanner({
            live_id: liveStore.live_id,
        }).then((response) => {
            setBanner(response.banner);
        });
    }, []);

    const sendMessage = (message: MessageItem | MessageItem[]) => {
        setBullets((prevbullets) => {
            const insertMessage = Array.isArray(message) ? message : [message];
            const newBullets = insertMessage.concat(prevbullets);
            return newBullets;
        });
    };

    // 初始化弹幕
    useEffect(() => {
        document.body.style.overflow = "hidden";
        const webSocket = new WebSocket(
            process.env.REACT_APP_ENV === "production"
                ? common.wsUrlProd
                : common.wsUrlDev
        );
        webSocket.onmessage = (e) => {
            const packageData = JSON.parse(e.data);
            switch (packageData.action) {
                case "bind":
                    console.log("bind", packageData);
                    const bindData = packageData;
                    bindClient({
                        live_id: liveStore.live_id,
                        client_id: bindData.data.client_id,
                    })
                        .then((res) => {
                            message.success("已就绪");
                        })
                        .catch((e) => {
                            message.error("绑定ws失败 " + e.data?.message);
                        });
                    break;
                case "message":
                    console.log("message", packageData);
                    const {
                        data,
                    }: { data: MessageItem | MessageItem[] } = packageData;
                    sendMessage(data);
                    break;
            }
        };
        webSocket.onopen = () => {
            console.log("success");
        };
        setWs(ws);
        return () => {
            document.body.style.overflow = "";
        };
    }, []);

    console.log("bullets, ", bullets);
    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#fff",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundImage: `url(${banner})`,
                zIndex: 100,
            }}
        >
            <div
                style={{ position: "absolute", right: 30, top: 30 }}
                onClick={() => history.goBack()}
            >
                <CloseOutlined style={{ fontSize: 40 }} />
            </div>
            <div
                className="screen-message"
                style={{
                    background: "rgb(249, 249, 249, 0.8)",
                    width: "85vw",
                    height: "90vh",
                    overflow: "auto",
                    padding: "40px 80px",
                }}
            >
                {bullets.map((item: MessageItem) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                position: "relative",
                                width: "100%",
                                marginTop: "20px",
                            }}
                        >
                            <div
                                style={{
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: "30px",
                                    marginRight: "30px",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundImage: `url(${item.headimgurl})`,
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    fontSize: "25px",
                                }}
                            >
                                <div style={{ color: "#0081e1" }}>
                                    {item.name}
                                </div>
                                <div>{item.message}</div>
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    right: 0,
                                    top: "50%",
                                    transform: `translateY(-50%)`,
                                }}
                            >
                                {moment(
                                    new Date(Number(item.create_time) * 1000)
                                ).format("HH:mm:ss")}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
