/**
 * 获取已发送的邮件数量
 * Create by liaokai on 2020-04-24 17:45:05
 */
import {toPost} from "../../http-utils";

export const emailCount = toPost<
    {

    },
    {
        "sendCount": number, //已经发送数量，只包含已经发送成功的
        "residue": number //剩余数量
    }
    >("/merchant/Email/messageCount");
