/**
 * 获取短信充值二维码到支付状态
 * Create by liaokai on 2020-05-09 14:33:19
 */
import {toPost} from "../http-utils";

export const getRechargeImgStatus = toPost<
    {
        orderNum : string
    },
    {
        status : number,    // 1/未支付 2/已支付 3/已过期
    }
    >("/merchant/Lcm/selectPaySms");
