/**
 * 编辑回看视频信息
 * Create by liaokai on 2020-06-19 16:08:17
 */
import * as React from 'react';
import {Component} from "react";
import {Modal} from "antd";
import {TitleContentTable} from "../routes/index/meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";

interface IProps{
    visible : boolean
    onCancel? : () => void
    onSubmit? : () => void
    title? : string
    titleOk? : string
    titleCancel? : string
    titleContent? : string
}

interface IState{
}

export class DestroyModul extends Component<IProps,IState>{
    readonly state : IState = {
    };


    render() {
        let {onCancel,onSubmit,visible,titleCancel,title,titleContent,titleOk} = this.props;
        let {} = this.state;
        return (
            <Modal
                visible={visible}
                onCancel={()=>{
                    onCancel?.();
                }}
                onOk={()=>{
                    onSubmit?.()
                }}
                cancelText={titleCancel}
                okText={titleOk}
                title={title}
            >
                <TitleContentTable colGap={10} rowGap={10}>
                    <div>{titleContent}</div>

                </TitleContentTable>
            </Modal>
        );
    }
}
