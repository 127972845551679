/**
 * 转入运营账户
 * Create by liaokai on 2020-05-09 14:30:29
 */
import {toPost} from "../http-utils";

export const transferOperatingAcc = toPost<
    {
        price : number
    },
    {

    }
    >("/merchant/Lcm/rollInOperation");
