import React, { Component } from 'react'
import style from './PasswordSet.scss'
import phoneicon from '../../../../../../assets/phoneicon.png'
import emailicon from '../../../../../../assets/e-mailicon.png'
import lockicon from  '../../../../../../assets/lockicon.png'
import shellicon from '../../../../../../assets/shellicon.png'
import {api} from '../../../../../../common/app'
import {message} from "antd";


let getVerifCodeCutdown;
export class PasswordSet extends Component {
constructor(props) {
  super(props);
  this.state = {
    VerificationType:'phone',
    info:{
        email: null,
        information: null,
        mobile: null,
    },
    formdata:{
      verifycode:'',
      newpassword:'',
    },
    cutdowntime:60,
  };
  this.refreshProps = this.refreshProps.bind(this);
  this.HandleInputChange = this.HandleInputChange.bind(this);
  this.getVerifCode = this.getVerifCode.bind(this);
  this.changePssword = this.changePssword.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
}
refreshProps(props) {
  this.state.info = props.info?props.info:this.state.info;
  this.setState(this.state);
}
HandleVerifType(type){
    this.state.VerificationType = type;
    this.setState(this.state);
}
HandleInputChange(type,e){
    this.state.formdata[type] = e.target.value;
    this.setState(this.state);
}
getVerifCode(){
    api.getVerifycode(this.state.info.mobile,'pass_verifycode',this.state.VerificationType).then(res=>{
        if (res.code === 200) {
            this.state.cutdowntime -= 1;
            this.setState(this.state);
            getVerifCodeCutdown = setInterval(() => {
                this.state.cutdowntime -= 1;
                this.setState(this.state);
                if (this.state.cutdowntime<=0) {
                    clearInterval(getVerifCodeCutdown);
                    this.state.cutdowntime = 60;
                    this.setState(this.state);
                }
            }, 1000);
            message.success(res.message);
        }else{
            message.error(res.message);
        }
    },err=>{
        console.log(err);

    })
}
changePssword(){
    api.updateUserPassword(this.state.formdata.newpassword,this.state.formdata.verifycode,'pass_verifycode').then(res=>{
        console.log(res);
        if (res.code === 200) {
            message.success(res.message);
            this.props.onClose();
        }else{
            message.error(res.message);
        }
    },err=>{
        console.log(err);

    })
}
componentWillUnmount(){
    clearInterval(getVerifCodeCutdown);
}
render() {
  return (
    <div className={style.SettingBox}>
        <div className={style.SettingTitle}>修改密码</div>
        <div className={style.smTips}>您可以选择手机号码或者电子邮箱验证后重置密码</div>

        <div className={[style.VerificationWay,'childcenter childcontentstart'].join(' ')}>
            <div className={style.Title}>验证方式</div>
            <div className={[style.CheckBoxGroup,'childcenter childcontentstart'].join(' ')} onClick={this.HandleVerifType.bind(this,'phone')}>
                <div className={[style.CheckBox,this.state.VerificationType === 'phone'?style.Checked:''].join(' ')}></div>
                <div className={style.CheckName}>手机验证</div>
            </div>
            {this.state.info.email?<div className={[style.CheckBoxGroup,'childcenter childcontentstart'].join(' ')} onClick={this.HandleVerifType.bind(this,'email')}>
                <div className={[style.CheckBox,this.state.VerificationType === 'email'?style.Checked:''].join(' ')}></div>
                <div className={style.CheckName}>邮箱验证</div>
            </div>:''}
        </div>

        {this.state.VerificationType === 'email' ? <div className={style.VerificationBox}>
            <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
                <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                    <img src={emailicon} alt=""/>
                </div>
                <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                    <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                        电子邮箱
                    </div>
                    <div className={style.Inputs}>
                        <input type="text" value={this.state.info.email} readOnly/>
                    </div>
                </div>
            </div>
            <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
                <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                    <img src={shellicon} alt=""/>
                </div>
                <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                    <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                        邮件验证码
                    </div>
                    <div className={style.PhoneCodeInput}>
                        <input type="text" placeholder={'请填写邮件验证码'} value={this.state.formdata.verifycode} onChange={this.HandleInputChange.bind(this,'verifycode')}/>
                    </div>
                    <div onClick={this.state.cutdowntime === 60?this.getVerifCode:()=>{}} className={[style.SendCode,'childcenter'].join(' ')}><span>{this.state.cutdowntime === 60?'获取验证码':this.state.cutdowntime+'s'}</span></div>
                </div>
            </div>
            <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
                <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                    <img src={lockicon} alt=""/>
                </div>
                <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                    <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                        新的密码
                    </div>
                    <div className={style.Inputs}>
                        <input type="text" placeholder={'请填写新的密码'} value={this.state.formdata.newpassword} onChange={this.HandleInputChange.bind(this,'newpassword')}/>
                    </div>
                </div>
            </div>
        </div>:''}

        {this.state.VerificationType === 'phone' ? <div className={style.VerificationBox}>
            <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
                <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                    <img src={phoneicon} alt=""/>
                </div>
                <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                    <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                        手机号
                    </div>
                    <div className={style.Inputs}>
                        <input type="text" value={this.state.info.mobile} readOnly/>
                    </div>
                </div>
            </div>
            <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
                <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                    <img src={shellicon} alt=""/>
                </div>
                <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                    <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                        短信验证码
                    </div>
                    <div className={style.PhoneCodeInput}>
                        <input type="text" placeholder={'请填写短信验证码'} value={this.state.formdata.verifycode} onChange={this.HandleInputChange.bind(this,'verifycode')}/>
                    </div>
                    <div onClick={this.state.cutdowntime === 60?this.getVerifCode:()=>{}}  className={[style.SendCode,'childcenter'].join(' ')}><span>{this.state.cutdowntime === 60?'获取验证码':this.state.cutdowntime+'s'}</span></div>
                </div>
            </div>
            <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
                <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                    <img src={lockicon} alt=""/>
                </div>
                <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                    <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                        新的密码
                    </div>
                    <div className={style.Inputs}>
                        <input type="password" placeholder={'请填写新的密码'} value={this.state.formdata.newpassword} onChange={this.HandleInputChange.bind(this,'newpassword')}/>
                    </div>
                </div>
            </div>
        </div>:''}

        <div className={[style.HandleStatusGroup,'childcenter'].join(' ')}>
            <div className={[style.StatusButton,style.bluebutton,'childcenter'].join(' ')} onClick={this.changePssword}>确定</div>
            <div className={[style.StatusButton,style.graybutton,'childcenter'].join(' ')} onClick={this.props.onClose}>取消</div>
        </div>

    </div>
   )
   }
}
export default PasswordSet
