/**
 * 多选高阶组件
 * Create by liaokai on 2020-04-08 12:10:41
 */
import * as React from 'react';
import {Component, CSSProperties} from "react";

type tStateData<T> = {
    data : T,
    selected : boolean,
}

interface IProps<T>{
    data : T[],                                         //数据（选择列表）
    renderUnSelectItem: (item : T) => JSX.Element,      //渲染未选中组件
    renderSelectedItem: (item : T) => JSX.Element,      //渲染已选中组件
    onChange?: (items : T[]) => void,                    //选择改变事件
    initSelect? : (item : T) => boolean,                //默认选中
    extractKey? : (item : T) => string,                 //从item中提取值作为key
    itemStyle? : CSSProperties,                         //item样式
}

interface IState<T>{
    data : tStateData<T>[]
}

export class CheckBoxHigherOrder<T> extends Component<IProps<T>,IState<T>>{
    //从props.data转换为state.data
    propsData2StateData = (data : T[], initSelect? : (item : T) => boolean) : tStateData<T>[] => {
        return data.map((value, index) => {
            return {
                data : value,
                selected : !! initSelect?.(value)
            }
        })
    };

    //state初始化
    readonly state : IState<T> = {
        data : this.propsData2StateData(this.props.data, this.props.initSelect)
    };

    componentWillReceiveProps(nextProps: Readonly<IProps<T>>, nextContext: any): void {
        this.setState({
            data: this.propsData2StateData(nextProps.data, nextProps.initSelect)
        })
    }

    render() {
        let {renderSelectedItem,renderUnSelectItem,onChange,extractKey,itemStyle} = this.props;
        let {data} = this.state;
        return (
            <>
                {
                    data.map((value, index) => {
                        return <div key={extractKey?.(value.data) ?? JSON.stringify(value.data)} style={itemStyle ?? {}} onClick={()=>{
                            let nextData : tStateData<T>[] = data.map((value1, index1) => {
                                return {
                                    data : value1.data,
                                    selected : index === index1 ? !value1.selected : value1.selected
                                }
                            });
                            this.setState({
                                data : nextData
                            },()=>{
                                onChange?.(nextData.filter(value1 => value1.selected).map(value1 => value1.data));
                            });
                        }}>
                            {value.selected ? renderSelectedItem(value.data) : renderUnSelectItem(value.data)}
                        </div>
                    })
                }
            </>
        )
    }
}
