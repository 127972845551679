/**
 * 直播播放器
 * Create by liaokai on 2020-06-15 18:38:33
 */
import * as React from 'react';
import {Component} from "react";
interface IProps{
    src : string    //m3u8地址
}
interface IState{
    seconds: number;
    message:string;
    statuses:boolean
}

export class AliLivePlayer extends Component<IProps,IState>{
    readonly state : IState = {
         seconds :120,  
         message : "",
         statuses:false
    };

    componentDidMount() {
        this.playVideo(this.props.src)
    }

    player : any = undefined;
    interval : any = undefined
    intervalConnect : any = undefined
    isStart :number = 0
    //播放视频
    playVideo = (m3u8SourceUrl : string)=>{
        if(m3u8SourceUrl){
            this.player?.dispose?.();
            let Aliplayer:any = (window as any).Aliplayer;
            this.player = new Aliplayer({
                id: 'video-player',
                width: '640px',
                autoplay: false, //如果是直播就要立刻播放如果是回放需要有暂停
                //支持播放地址播放,此播放优先级最高
                source : m3u8SourceUrl,
                "height": "360px",
                "isLive": false,
                "rePlay": false,
                "playsinline": true,
                "preload": true,
                "controlBarVisibility": "hover",
                "useH5Prism": true,
                // "cover":poster,
                "x5_type":'h5', //声明启用同层H5播放器
                ended : () => {
                    // this.props.playOver?.()
                },

                liveStreamStop : () => {
                    
                    // this.props.playOver?.()
                },
                error : ()=> {//此处不执行
                    
                }
            },(player : any) => {
                player.on("liveStreamStop",function(){
                    console.log('断流')

                })
                let _this = this
                player.on('waiting',function(){
                    console.log("数据缓冲")
                    _this.setState({
                         statuses:true,

                     });
                 })
                player.on('playing',function(){
                      console.log("播放中")
                    _this.setState({
                         statuses:false,
                         seconds:120,
                         message:''
                     });
                     _this.isStart = 0
                })



            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval)
        this.player?.dispose?.();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if(prevProps.src !== this.props.src){
            this.playVideo(this.props.src)
        }
    }
    closeConnect = () => {
         clearInterval(this.intervalConnect)
         this.isStart = 0
          
    };
    //重连定时器
    render() {
        let {} = this.state;
        let message = this.state.message

        return (
            <div>
                <div  style={{
                        display: message ? "block" : "none",
                        width: "100%",
                        height: "56.5vw",
                        zIndex:1002,
                        position:'fixed',
                        background:'#000',
                        color:'#fff',
                        textAlign:'center',
                        lineHeight:'56vw'
                    }}>
                    {message}
                </div>
               
                <div id={"video-player"} style={{position: "relative"}}>
                </div>
            </div>
        );
    }
}
