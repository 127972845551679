import React, { Component } from 'react'
import style from './SignInView.scss'
import ParticleFlowBox from 'components/ParticleFlowBox'
import {api} from 'common/app'
import HellView from 'components/AlertBox/BaseAlert'
  
export class SignInView extends Component {
constructor(props) {
  super(props);
  this.state = {
    id:null,
    userid:'',
    field:{},
    userinfo:{},
  };
     this.refreshProps = this.refreshProps.bind(this);
     this.getKeyDown = this.getKeyDown.bind(this);
     this.getMeetingInfo = this.getMeetingInfo.bind(this);
     this.SignIn = this.SignIn.bind(this);
     this.HandleInput = this.HandleInput.bind(this);
     this.createUserInfo = this.createUserInfo.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
  window.document.title = '签到大厅-会佰分会议管理系统'
  window.addEventListener('keydown',this.getKeyDown);
}
componentWillUnmount(){
  window.removeEventListener('keydown',this.getKeyDown)
}
refreshProps(props) {
  let params = props.match.params;
  if (this.state.id != params.id) {
    this.state.id = params.id;
    this.setState(this.state);
    this.getMeetingInfo(params.id);
  }
}
getKeyDown(e){
  switch (e.code) {
    case 'Enter':
    case 'NumpadEnter':
        this.SignIn();
        break;
  }
}
HandleInput(type,e){
  this.state[type] = e.target.value;
  this.setState(this.state);
}
getMeetingInfo(id){
  
    api.getMeetingInfoByHallID(id).then(res=>{
      if (res.code === 200) {
        this.state.info = res.result;
        this.setState(this.state);
      }else{
        
      }
    },err=>{
      console.log(err);
      HellView.error({
        message:'服务器错误！请稍后重试！'
      })
    })
  
}
SignIn(){
  if (this.state.userid!='') {
    api.SignInHall(this.state.id,this.state.userid).then(res=>{
      console.log(res);
      if (res.code === 200) {
        this.state.userinfo = res.result.data.user;
        this.state.field = res.result.data.field;
        this.setState(this.state);
        HellView.success({
          message:res.message
        })
      }else{
        HellView.error({
          message:res.message
        })
      }
      this.state.userid='';
      this.setState(this.state)
    },err=>{
      HellView.error({
        message:'服务器错误！请稍后重试！'
      })
      this.state.userid='';
      this.setState(this.state)
    })
  }else{
    HellView.error({
      message:'先输入用户编码再提交'
    })
  }
}
createUserInfo(){
  let result = [];
  for (const key in this.state.field) {
    if (this.state.field.hasOwnProperty(key)) {
      const field = this.state.field[key];
      result.push(
        <div className={[style.InfoRow,'childcenter'].join(' ')}>
            <div className={[style.RowName,'childcenter childcontentend'].join(' ')}>
              {field}
            </div>
            <div className={style.RowValue}>
              {this.state.userinfo[key]}
            </div>
         </div>
      );
    }
  }
  return result;
}
render() {
  return (
    <div className={[style.SignInView,'childcenter childcolumn'].join(' ')}>
      <div className={[style.SignBox,'childcenter childcolumn'].join(' ')}>
        <div className={style.MeetingTitle}>{this.state.info?this.state.info.title:''}</div>
        <div className={style.SignListBody}>
          <div className={[style.SignInputBox,'childcenter childcolumn'].join(' ')}>
            <div className={style.CountNum}>{this.state.info?'第'+this.state.info.user_count+'位参会者':''}</div>
            <div className={style.Inputs}>
              <input type="text" maxLength={14} value={this.state.userid} onInput={(e)=>{}} onChange={this.HandleInput.bind(this,'userid')} placeholder='请扫描/请输入用户编码'/>
            </div>
            <div className={style.SignedBox}>
               
               {this.createUserInfo()}
               
            </div>
          </div>
        </div>
      </div>
      <ParticleFlowBox key='signin'/>
    </div>
   )
   }
}
export default SignInView