// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sort_container__X3VfE .sort_title__ipmPn{font-size:21px;font-weight:400;color:#000;padding:20px 0px}.sort_container__X3VfE .sort_sortBox__3yeG0{background-color:white;min-height:600px;margin-bottom:30px}.sort_container__X3VfE .sort_sortBox__3yeG0 .sort_ope__36PQ1{display:flex;justify-content:space-between;align-items:center;background:#eeeeee;padding:15px 30px}.sort_container__X3VfE .sort_sortBox__3yeG0 .sort_ope__36PQ1 .sort_text__2TKi4{margin-right:10px;display:flex;align-items:center}.sort_container__X3VfE .sort_sortBox__3yeG0 .sort_ope__36PQ1 .sort_btns__ktsa9{display:flex}.sort_container__X3VfE .sort_sortBox__3yeG0 .sort_dragListBox__wCIQp{margin-top:20px}.sort_drapItem__18xmg{padding:5px 0px;cursor:default}.sort_drapItem__18xmg .sort_card__1jUka .sort_container__X3VfE{display:flex;justify-content:space-between;align-items:center}.sort_drapItem__18xmg .sort_card__1jUka .sort_container__X3VfE .sort_icon__2P_pI{font-size:22px;padding-left:20px;cursor:pointer}.sort_drapItem__18xmg .sort_card__1jUka .sort_container__X3VfE .sort_move__W5C3I{cursor:move}.sort_drapItem__18xmg .sort_card__1jUka .sort_container__X3VfE .sort_icon__2P_pI:hover{color:#0189ff}.sort_drapItem__18xmg .sort_card__1jUka .sort_container__X3VfE .sort_info__2qNj_{display:flex;align-items:center;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.sort_drapItem__18xmg .sort_card__1jUka .sort_container__X3VfE .sort_info__2qNj_ .sort_title__ipmPn{font-size:18px;margin-left:15px;padding:0px}.sort_drapItem__18xmg .sort_card__1jUka .sort_container__X3VfE .sort_box__3-43o{display:flex;flex-direction:row;align-items:center}.sort_card__1jUka:hover{background-color:#eee}\n", ""]);
// Exports
exports.locals = {
	"container": "sort_container__X3VfE",
	"title": "sort_title__ipmPn",
	"sortBox": "sort_sortBox__3yeG0",
	"ope": "sort_ope__36PQ1",
	"text": "sort_text__2TKi4",
	"btns": "sort_btns__ktsa9",
	"dragListBox": "sort_dragListBox__wCIQp",
	"drapItem": "sort_drapItem__18xmg",
	"card": "sort_card__1jUka",
	"icon": "sort_icon__2P_pI",
	"move": "sort_move__W5C3I",
	"info": "sort_info__2qNj_",
	"box": "sort_box__3-43o"
};
module.exports = exports;
