/**
 * 数据统计
 * Create by liaokai on 2020-05-23 18:19:05
 */
import * as React from 'react';
import {Component} from "react";
import { Texts } from '../../../../../../components/text/Texts';
import {LiveDataCountViewStateful} from "../overview/component/live-data-count-view-stateful";
import {Button, message, Space} from "antd";
import {Axis, Chart, Geom} from "bizcharts";
import moment from "moment"
import {Concurrent} from "./component/concurrent/concurrent";
import {Air} from "../../../../../../components/air/Air";
import {Terminal} from "./component/terminal/terminal";
import {AccessLocation} from "./component/location/access-location";
import {ShareCount} from "./component/share-count/share-count";
import styled from "styled-components";
import {exportInvitationCodeUrl} from "../../../../../../common/apis/apis/live/exportInvitationCodeUrl";
import {liveStore} from "../live.store";
import {exportUserDataUrl} from "../../../../../../common/apis/apis/live/exportUserDataUrl";
import {exportConcurrenceTableUrl} from "../../../../../../common/apis/apis/live/exportConcurrenceTableUrl";

interface IProps{

}

interface IState{

}



export class LiveDataCountView extends Component<IProps,IState>{
    readonly state : IState = {

    };
    
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <div>
                <div style={{display:"flex",justifyContent: "space-between"}}>

                <Texts.ItemTitle>
                    直播数据
                </Texts.ItemTitle>

                <Button
                    type={"primary"}
                    onClick={()=>{
                    exportUserDataUrl({
                        live_id : liveStore.live_id
                    }).then(res => {
                        if(res.url){
                            window.open(res.url)
                        }else{
                            message.info("暂无Excel可导出")
                        }
                    }).catch(e => {
                        message.error("导出失败 " + e.data?.message)
                    })
                }}>数据导出</Button>
                </div>
                <Air vertical={5}/>
                <div>
                    <LiveDataCountViewStateful/>
                </div>
                <Air vertical={15}/>
                <div style={{display:"flex",justifyContent: "space-between"}}>
                    <Texts.ItemTitle>
                        直播并发
                    </Texts.ItemTitle>
                    <Button
                        type={"primary"}
                        onClick={()=>{
                            exportConcurrenceTableUrl({
                                live_id : liveStore.live_id
                            }).then(res => {
                                if(res.url){
                                    window.open(res.url)
                                }else{
                                    message.info("暂无Excel可导出")
                                }
                            }).catch(e => {
                                message.error("导出失败 " + e.data?.message)
                            })
                        }}>数据导出</Button>
                </div>
                <Air vertical={20}/>
                <div>
                    <Concurrent/>
                </div>
                <Air vertical={15}/>
                <Texts.ItemTitle>
                    观看终端
                </Texts.ItemTitle>
                <Air vertical={20}/>
                <div>
                    <Terminal/>
                </div>
                <Air vertical={30}/>
                <Texts.ItemTitle>
                    地理位置
                </Texts.ItemTitle>
                <Air vertical={5}/>
                <div>
                    <AccessLocation/>
                </div>
                <Air vertical={15}/>
                {/*<Texts.ItemTitle>*/}
                {/*    有效分享*/}
                {/*</Texts.ItemTitle>*/}
                {/*<Air vertical={5}/>*/}
                {/*<div>*/}
                {/*    <ShareCount/>*/}
                {/*</div>*/}
            </div>
        );
    }
}
