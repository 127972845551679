/**
 * 数据统计-嘉宾
 * Create by liaokai on 2020-04-27 11:54:24
 */
import {toPost} from "../http-utils";

export const getGusetCountByMeeting = toPost<
    {
        meetid : string
    },
    {
        signNum : number
        userNum : number
    }
>("/merchant/meeting/statistical");
