/**
 * 传送带
 *  将本地高并发任务，转化为时间间隔固定的精简任务（忽略间隔内时间的中途任务）
 *  如实时搜索，因改变文字触发的任务
 * Create by liaokai on 2020-04-02 10:49:52
 */

type tJob = () => void

export class Conveyor {
    private readonly interval : number = 0;     //间隔时间
    private lastJob : tJob | undefined;         //最后添加的任务
    private working : boolean = false;          //时间片是否正在工作

    constructor(interval : number) {
        this.interval = interval;
    }

    private nextTimeSlice(){
        if(!this.working){
            this.working = true;
            setTimeout(()=>{
                try {
                    this.lastJob?.();
                }finally {
                    this.lastJob = undefined;
                    this.working = false
                }
            }, this.interval)
        }
    }

    public push(job : tJob){
        this.lastJob = job;
        this.nextTimeSlice();
    }

}
