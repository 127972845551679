/**
 * 调研问卷发布
 * Create by liaokai on 2020-06-16 18:44:33
 */
import {toPost} from "../../http-utils";

export const liveSurveyQuestionnairePublish = toPost<
    {
        id : string
    },
    {

    }
>("/merchant/live/liveSurveyQuestionnairePublish");

