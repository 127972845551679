/**
 * 支付方式选择
 * Create by liaokai on 2020-05-09 17:56:50
 */
import * as React from 'react';
import {Component} from "react";
import {common} from "../../../../../common/common";
import {Badge1} from "./badge-1";
import {CheckOutlined} from "@ant-design/icons/lib";
import {Radio} from "../../../../../components/common/radio";
import {PrepaidMoney} from "./prepaid-money";

export type tBuyMethod = "wechat" | "alipay" | "balance"

interface IProps{
    payMethods : tBuyMethod[],
    onSelect? : (method : tBuyMethod) => void
}

interface IState{

}

export class PayMethod extends Component<IProps,IState>{
    readonly state : IState = {

    };


    render() {
        let {payMethods,onSelect} = this.props;
        let {} = this.state;
        return (
            <Radio
                data={payMethods}
                initSelectIndex={0}
                onSelect={(item, index) => {
                    onSelect?.(item)
                }}
                renderSelectedItem={item => <div style={{border: `1px solid ${"#ff6e4b"}`}}>
                    <Badge1 comp={<div style={{
                        width: 0,
                        height: 0,
                        borderBottom: "40px solid transparent",
                        borderRight: `40px solid ${"#ff6e4b"}`,
                    }}>
                        <div style={{top: 0, right: 5, position: "absolute", color: "#fff"}}>
                            <CheckOutlined/>
                        </div>
                    </div>}>
                        <div style={{padding: "20px 25px", width : 250, display: "flex", justifyContent : "center"}}>
                            {PrepaidMoney.renderPayMethods(item)}
                        </div>
                    </Badge1>
                </div>}
                renderUnSelectItem={item => <div style={{border: `1px solid #dcdcdc`}}>
                    <div style={{padding: "20px 25px", width : 250, display: "flex", justifyContent : "center"}}>
                        {PrepaidMoney.renderPayMethods(item)}
                    </div>
                </div>}
            />
        );
    }
}
