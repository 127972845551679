import React, { Component } from 'react'
import { Chart, Coordinate,Tooltip, Interval } from 'bizcharts';
import {RouteComponentProps} from "react-router";
import {apis} from "../../../../../../common/apis/apis";
import {message} from "antd";
import {Texts} from "../../../../../../components/text/Texts";
import {Air} from "../../../../../../components/air/Air";

interface IProps extends RouteComponentProps<{meeting_id : string}>{

}

interface IState {
  userNum : number,
  signNum : number,
}

export class DataCountView extends Component<IProps,IState> {
  constructor(props : IProps) {
    super(props);
  }

  readonly state : IState = {
    userNum:0,
    signNum:0,
  }

  componentDidMount() {
    this.getDataCountData(this.props.match.params.meeting_id)
  }

  getDataCountData(id: string) {
    if (!id) return;
    apis.getGusetCountByMeeting({meetid: id}).then(res => {
      this.setState({
        signNum: res.signNum,
        userNum: res.userNum
      })
    }, err => {
      message.error("获取统计信息失败")
    })
  }

  render() {
    let {userNum, signNum} = this.state
    return (
      <div style={{width : "100%"}}>
          <div>
              <Texts.ItemTitle>签到</Texts.ItemTitle>
              <Air vertical={10}/>
              <Chart
                  height={200}
                  data={[
                      { item: '总人数', count: userNum},
                      { item: '已签到', count: signNum},
                      { item: '未签到', count: userNum - signNum},
                  ]}
                  autoFit
                  scale={{
                      count: {
                          formatter: (val:any) => {
                              val = val+"人";
                              return val;
                          },
                      },
                  }}
              >
                  <Interval position="item*count" color="item" />
              </Chart>
          </div>
      </div>
     )
   }
}
