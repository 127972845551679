/**
 *
 * Create by liaokai on 2020-03-24 17:17:46
 */
import * as React from 'react';
import { Component } from "react";
import { FormAddLine } from "../form-add-line/form-add-line";
import { Button, message, Switch, Table } from "antd";
import { DeleteOutlined, SwapOutlined } from "@ant-design/icons/lib";
import { tFormTypes } from "../form-preview/form-preview";
import { apis } from "../../../../../../../common/apis/apis";
import { RegisterFormContext } from "../register-form-context";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import "./form-table.css"
import style from "./form-table-local.scss"

export interface ICheck {
    [index: number]: string | number
}
export interface ITableRowItem {
    title: string,          //标题
    mandatory: boolean,     //必填
    type: tFormTypes,       //表单类型
    // required : boolean,     //不可删除
    operation: undefined,   //操作（仅占位）
    ruleKey: string,       //规则id
    ableDel: boolean,      //是否可删除
    check?: ICheck[]

}

interface IProps {
    onChange?: (data: ITableRowItem[]) => void,
}

interface IState {
    dataSource: (ITableRowItem)[],
    test: string
}

const type = 'DragbleBodyRow';
// @ts-ignore
const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = React.useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: monitor => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: (item: any) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        item: { type, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));
    return (
        <tr
            ref={(ref) as any}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{ cursor: 'move', ...style }}
            {...restProps}
        />
    );
};


export class FormTable extends Component<IProps, IState>{
    //state初始化
    readonly state: IState = {
        dataSource: [],
        test: ""
    };
    static contextType = RegisterFormContext;

    tableRefreshed = () => {
        this.props.onChange?.(this.state.dataSource)
    };

    componentDidMount(): void {
        apis.getMeetingRegisterField({ meetid: this.context.meetingId }).then(res => {
            this.setState({
                dataSource: res.map(value => {
                    return {
                        title: value.zh_name,
                        mandatory: value.is_required,
                        type: value.type,
                        ruleKey: value.en_name,
                        operation: undefined,
                        ableDel: value.required !== 1
                    }
                })
            }, () => {
                this.tableRefreshed();
            })
        });
    }

    columns = [
        {
            title: '表单项',
            dataIndex: 'title',
            key: 'title',
            render: (text: string, record: ITableRowItem, index: number) => {
                return text
            }
        },
        {
            title: '必填',
            dataIndex: 'mandatory',
            key: 'mandatory',
            render: (text: boolean, record: ITableRowItem, index: number) => {
                const disabled = record.title === '姓名' || record.title === '性别' || record.title === '手机号' || record.title === '身份证号码';
                return (
                    <Switch
                        checked={!!record.mandatory}
                        onChange={(checked, event) => {
                            record.mandatory = !record.mandatory;
                            this.setState(this.state, this.tableRefreshed)
                        }}
                        disabled={disabled}
                    />
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (text: unknown, record: ITableRowItem, index: number) => {
                return (
                    <div style={{ display: "flex" }}>
                        <Button disabled={!record.ableDel} size={"small"} type={"link"} danger={true} icon={<DeleteOutlined />} onClick={() => {
                            this.setState({ dataSource: this.state.dataSource.filter((value, index1) => index1 !== index) }, this.tableRefreshed);
                        }}>删除</Button>
                        <div style={{ cursor: "move" }}>
                            <Button size={"small"} type={"link"} icon={<SwapOutlined rotate={90} />} style={{ pointerEvents: "none" }}><span>顺序</span></Button>
                        </div>
                    </div>
                )
            }
        },
    ];

    components = {
        body: {
            row: DragableBodyRow,
        },
    };

    moveRow = (dragIndex: any, hoverIndex: any) => {
        const dataSource = this.state.dataSource.filter(value => true);
        const dragRow = dataSource[dragIndex];
        dataSource.splice(dragIndex, 1);
        dataSource.splice(hoverIndex, 0, dragRow);
        this.setState({ dataSource }, this.tableRefreshed)
    };

    render() {
        let { } = this.props;
        let { dataSource, test } = this.state;


        return (
            <DndProvider backend={HTML5Backend}>
                <Table
                    bordered
                    columns={this.columns}
                    dataSource={dataSource}
                    pagination={false}
                    components={this.components}
                    // @ts-ignore
                    onRow={(record, index) => ({
                        index,
                        moveRow: this.moveRow,
                    })}
                    rowClassName={style.row}
                    summary={(pageData) => {
                        return (
                            <FormAddLine onAdd={async item => {
                                if (dataSource.filter(value => value.title === item.title).length > 0) {
                                    message.info(`已存在 "${item.title}"`);
                                    return;
                                }
                                try {
                                    // if(!item.ruleKey){
                                    //     let res = await apis.addRegisterField({zh_name : item.title});
                                    //     item.ruleKey = res.en_name
                                    // }
                                    let afterDataSource = [...dataSource, item];
                                    this.setState({ dataSource: afterDataSource }, this.tableRefreshed);
                                } catch (e) {
                                    message.error(JSON.stringify(e.data?.message ?? "添加失败"));
                                }
                            }} />
                        );
                    }}
                />
            </DndProvider>
        );
    }
}
