import React, { Component } from 'react'
import style from './ImportGuestView.scss'
import StepProcess from './components/StepProcess'
import TemplateView from './View/TemplateView'
import ImportTemplateView from './View/ImportTemplateView'
import EditExcelView from './View/EditExcelView'
import ResultView from './View/ResultView'
import {api} from '../../../../../../common/app'
import {message} from "antd";

export class ImportGuestView extends Component {
constructor(props) {
  super(props);
  this.state = {
    id:null,
    file_name:"",
    stepstatus:0,
    listdata:null,
    selectLabel:{},
    result:{
      type:1,
      value:'导入失败！'
    },
  };
     this.refreshProps = this.refreshProps.bind(this);
     this.HandleStepStatus = this.HandleStepStatus.bind(this);
     this.customRoute = this.customRoute.bind(this);
     this.onGetList = this.onGetList.bind(this);
     this.HandleCallBackData = this.HandleCallBackData.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
}
refreshProps(props) {
  let params = props.match.params;
  if (this.state.id != params.address_book_id) {
    this.state.id = params.address_book_id;
    this.setState(this.state);
  }
}
HandleStepStatus(index){
  if (index === 3) {
    if (this.state.listdata.data === 0) {
      this.state.stepstatus = index;
      this.state.result={
        type:0,
        value:'没有需要导入的邮箱',
      }
      this.setState(this.state);
    }else{
      let selectLabel=[];
      for (const key in this.state.listdata) {
          selectLabel.push(this.state.listdata[key]);
      }
        api.emailAddressBatchStore(this.state.id,JSON.stringify(selectLabel)).then(res=>{
        if (res.code === 200) {
          this.state.result={
            type:2,
            value:'导入成功',
          }
          this.state.stepstatus = index;
          this.setState(this.state);
        }else{
            message.error(res.message);
        }
      },err=>{
        console.log(err);
      })
    }

  }else{
    this.state.stepstatus = index;
    this.setState(this.state);
  }
}
onGetList(data){
  this.state.listdata = data.data;
  this.state.file_name = data.file_name;
  this.state.success_num = data.success_num;
  this.state.fail_num = data.fail_num;
  this.state.all_num = data.all_num;
  this.setState(this.state);
}
//对应4步流程
customRoute(){
  switch (this.state.stepstatus) {
    case 0:
      return <TemplateView id={this.state.id}/>;
    case 1:
      return <ImportTemplateView id={this.state.id} HandleState={this.HandleStepStatus.bind(this)} onGetList={this.onGetList}/>;
    case 2:
      return <EditExcelView
                id={this.state.id}
                selectedLabel={this.state.selectLabel}
                failNum={this.state.fail_num}
                successNum={this.state.success_num}
                allNum={this.state.all_num}
                HandleState={this.HandleStepStatus.bind(this)}
                listdata={this.state.listdata}
                LabelCallBack={this.HandleCallBackData}
                file_name={this.state.file_name}
                resultComponent={this.state.result}
              />
    case 3:
      return <ResultView id={this.state.id} result={this.state.result}/>
  }
}
HandleCallBackData(formdata){
  this.state.selectLabel = formdata;
  this.setState(this.state);
}
render() {
  return (
    <div className={style.ImportGuestView}>
      <div className={style.ContentBox}>
          <div className={[style.ImportBox,'childcenter childcolumn'].join(' ')}>
              <div className={[style.Process,'childcenter'].join(' ')}>
                <StepProcess status={this.state.stepstatus} step={['下载模版','批量导入','选择人员','导入成功']}/>
              </div>

              <div className={style.ViewBox}>
                {this.customRoute()}
              </div>

              <div className={[style.HandleStatusGroup,'childcenter'].join(' ')}>
                {this.state.stepstatus == 0 || this.state.stepstatus == 3 ? '' :<div onClick={this.HandleStepStatus.bind(this,this.state.stepstatus-1)} className={[style.StatusButton,style.graybutton,'childcenter'].join(' ')}>上一步</div>}
                {this.state.stepstatus == 3?'':<div onClick={()=>{
                    if(this.state.stepstatus == 1 ){
                        console.log(this.state)
                        console.log(this.props)
                        message.info("请导入人员名单")
                    }
                    this.HandleStepStatus(this.state.stepstatus+1)
                }} className={[style.StatusButton,style.bluebutton,'childcenter'].join(' ')}>下一步</div>}
              </div>
          </div>
      </div>
    </div>
   )
   }
}
export default ImportGuestView
