import React from "react";
import { Form, Table, Switch, Button, message, Spin } from "antd";
import { useAntdTable, useBoolean, useMount } from "ahooks";
import {
  liveInfoList,
  liveInfoAdd,
  liveInfoShow,
  liveInfoDel,
  liveInfoUpdate,
  liveInfoChange,
} from "./constants";
import { liveStore } from "../live.store";
import { Air } from "../../../../../../components/air/Air";
import { enableShowSet } from "../../../../../../common/apis/apis/live/enableShowSet";
import { FlexJustifyBetween } from "../../../../../../components/style/flex/flex-justify-between";
import { Flex } from "../../../../../../components/style/flex/flex";
import { enableShowInfo } from "../../../../../../common/apis/apis/live/enableShowInfo";
import { Link, useHistory } from "react-router-dom";
import { formatListResult } from "../advertisement/constants";

export const Message = () => {

  const history = useHistory();
  const [form] = Form.useForm();

  const { tableProps, search } = useAntdTable(
    (paginatedParams: any, tableProps: any) => {
      return liveInfoList({
        live_id: liveStore.live_id,
        page: paginatedParams.current,
        paginate: 15,
      }).then((response) => {
        return response;
      });
    },
    { form, formatResult: formatListResult }
  );
  const { submit, reset } = search;

  const onDelete = (item: any) => {
    liveInfoDel({ id: item.id })
      .then((result) => {
        message.success('删除成功！');
        submit();
      })
      .catch((error) => {
        console.log('error', error);
        message.error(error.data?.result);
      })
  }

  const onChange = (item: any, status: boolean) => {
    const payload = {
      id: item.id,
      status: status === true ? 0 : 1,
    };

    liveInfoChange(payload)
      .then((result) => {
        message.success('设置成功！');
        submit();
      })
      .catch((error) => {
        console.log('error', error);
        message.error(error.data?.result);
      })
  }

  const columns = [
    {
      title: "标题",
      dataIndex: "title",
      width: 600,
    },
    {
      title: '排序',
      dataIndex: 'sort'
    },
    {
      title: '开关',
      render: (item: any) => {
        return (
          <Switch
            checked={item.status}
            style={{ marginRight: 15 }}
            onChange={(status) => onChange(item, item.status)}
          />
        )
      }
    },
    {
      title: "操作",
      render: (item: any) => {
        return (
          <div>
            <Button
              onClick={() => {
                history.push(
                  `message/edit/edit/${item.id}`
                );
              }}
              type={"link"}
              style={{ paddingLeft: 0 }}
            >
              编辑
          </Button>
            {/*{`  `}*/}
          {/*  <Button*/}
          {/*    onClick={() => onDelete(item)}*/}
          {/*    type={"link"}*/}
          {/*    style={{ paddingLeft: 0 }}*/}
          {/*  >*/}
          {/*    删除*/}
          {/*</Button>*/}
          </div>
        )
      }
    },
  ];

  return (
    <div>
      <FlexJustifyBetween style={{ marginBottom: 12 }}>
        <Flex>
          {/* <Switch checked={tableEnable} onChange={changeShowSet} />
          <Air horizontal={3} />
          <div>开启广告位</div> */}
        </Flex>
        <Flex>
          <Button type={"primary"}>
            <Link to="message/edit/new">新增直播信息</Link>
          </Button>
        </Flex>
      </FlexJustifyBetween>
      <Spin
        indicator={<div />}
        tip="尚未开启广告"
        spinning={false}
      >
        <Table rowKey="id" columns={columns} {...tableProps} />
      </Spin>
    </div>
  );
}