/**
 * 专题列表
 * Create by liaokai on 2020-06-13 22:01:20
 */
import {toPost} from "../../http-utils";

export interface ISubjectIndexItem {
    "id": number,
    "title": string, //标题
    "poster": string, //海报
    "create_time": string, //时间
    "live_count": number, //直播数量
    "look_num": number //观看人数
}

export const subjectIndex = toPost<
    {
        title? : string
        time_sort? : string,
        page? : number,
        paginate? : number
    },
    {
        "current_page": number,
        "total": number,
        "last_page": number,
        "data": ISubjectIndexItem[]
    }
>("/merchant/live/subjectIndex");

