/**
 * 专题列表
 * Create by liaokai on 2020-06-13 21:55:54
 */
import * as React from 'react';
import {Component} from "react";
import styled from "styled-components";
import {MetaCard} from "../../../../../components/meta-card";
import {FlexCol} from "../../../../../components/style/flex/flex-col";
import {Texts} from "../../../../../components/text/Texts";
import {Divider, Empty, Input, message, Pagination, Popconfirm, Select, Spin} from "antd";
import {Air} from "../../../../../components/air/Air";
import {Link} from "react-router-dom";
import {Flex} from "../../../../../components/style/flex/flex";
import {IRecentlyLiveItem, recentlyLive} from "../../../../../common/apis/apis/live/recentlyLive";
import {FlexJustifyEnd} from "../../../../../components/style/flex/flex-justify-end";
import {ISubjectIndexItem, subjectIndex} from "../../../../../common/apis/apis/live/subjectIndex";
import style from "../../../meeting-list/index/meeting-list-view.scss";
import {delMeeting} from "../../../../../common/apis/apis/del-meeting";
import Button from "antd/es/button";
import {DeleteOutlined, EditOutlined, RollbackOutlined} from "@ant-design/icons/lib";
import {Display} from "../../../../../components/display/display";
import {
    eMeetingListSearchRange, getMeetingList,
    tMeetingListDataItem,
    tMeetingListSort
} from "../../../../../common/apis/apis/get-meeting-list";
import {SelectBoxCustom} from "../../../meeting-list/index/meeting-list-view";
import {FlexJustifyBetween} from "../../../../../components/style/flex/flex-justify-between";
import {CheckBoxHigherOrder} from "../../../../../components/check-box-higher-order/check-box-higher-order";
import {MeetingCardStateless} from "../../../meeting-list/_card/meeting-card.stateless";
import {common} from "../../../../../common/common";
import {subjectDestroy} from "../../../../../common/apis/apis/live/subjectDestroy";

const { Option } = Select;
const { Search } = Input;

interface IProps{

}

interface IState{
    listData : ISubjectIndexItem[],
    pageSize : number,
    total : number,
    currentPage : number,
    loading : boolean
    editing : boolean
    searchText: string

}

export class ThemeListComp extends Component<IProps,IState>{
    selectedMeeting : ISubjectIndexItem[] = []

    readonly state : IState = {
        listData : [],
        pageSize : 8,
        total : 0,
        currentPage : 1,
        loading : false,
        editing : false,
        searchText : ""
    };

    componentDidMount() {
        this.getListData();
    }

    getListData = (page: number = 1) => {
        this.setState({loading : true})
        subjectIndex({
            title : this.state.searchText,
            time_sort : "",
            page : page,
            paginate : this.state.pageSize
        }).then(res => {
            this.setState({
                listData : res.data,
                currentPage : page,
                total : res.total
            })
        }).finally(()=>{
            this.setState({loading : false})
        })
    }

    refreshPage = ()=>{
        this.getListData(this.state.currentPage)
    }

    render() {
        let {} = this.props;
        let {listData, pageSize, currentPage, total, loading, editing, searchText} = this.state;
        return (
            <div>
                {/*操作*/}
                <FlexJustifyBetween>
                    {/*编辑*/}
                    <Flex>
                        {
                            editing
                                ?
                                [
                                    <div>
                                        <Popconfirm disabled={listData?.length <= 0} placement="bottomLeft" title={"确定要项目选中的会议吗？"} onConfirm={async ()=>{
                                            try {
                                                await subjectDestroy({subject_id : this.selectedMeeting.map(value => value.id.toString())});
                                                message.success("删除完成")
                                            }catch (e) {
                                                message.error("删除失败")
                                            }finally {
                                                this.refreshPage();
                                                this.setState({editing : false})
                                            }
                                        }} okText="删除" cancelText="取消">
                                            <Button danger={true}><DeleteOutlined/> 删除</Button>
                                        </Popconfirm>
                                    </div>
                                    ,
                                    <Air horizontal={5}/>
                                    ,
                                    <Button icon={<RollbackOutlined/>} onClick={()=>{
                                        this.setState({editing : false})
                                        this.selectedMeeting = []
                                    }}>取消</Button>
                                ]
                                :
                                listData?.length ?
                                <Button type="primary" icon={<EditOutlined/>} onClick={()=>{
                                    this.setState({editing : true})
                                }}>编辑</Button>
                                    : undefined
                        }
                        {/*<Air horizontal={5}/>*/}
                        {/*<div>*/}
                        {/*    <Display show={!editing} justHidden={true}>*/}
                        {/*        <SelectBoxCustom*/}
                        {/*            data={[*/}
                        {/*                {value:'start_time',show:'按照会议时间排序'},*/}
                        {/*                {value:'create_time',show:'按照创建时间排序'}*/}
                        {/*            ]}*/}
                        {/*            onSelect={(item : {value : tMeetingListSort, show : string})=>{*/}
                        {/*                this.setState({sort : item.value},this.updateListData);*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    </Display>*/}
                        {/*</div>*/}
                        {/*<Air horizontal={5}/>*/}
                        {/*<div>*/}
                        {/*    <Display show={!editing} justHidden={true}>*/}
                        {/*        <Select defaultValue={eMeetingListSearchRange.all} onChange={value=>{*/}
                        {/*            this.setState({searchRange : value},this.updateListData);*/}
                        {/*        }}>*/}
                        {/*            <Option value={eMeetingListSearchRange.all}>全部</Option>*/}
                        {/*            <Option value={eMeetingListSearchRange.preview}>预告</Option>*/}
                        {/*            <Option value={eMeetingListSearchRange.ing}>进行</Option>*/}
                        {/*            <Option value={eMeetingListSearchRange.over}>结束</Option>*/}
                        {/*        </Select>*/}
                        {/*    </Display>*/}
                        {/*</div>*/}
                    </Flex>
                    {/*搜索*/}
                    <div>
                        <Display show={!editing} justHidden={true}>
                            <Search placeholder={"搜索专题"}
                                    onSearch={(text)=>{
                                        this.setState({searchText : text}, ()=>{
                                            this.refreshPage()
                                        });
                                    }}>
                            </Search>
                        </Display>
                    </div>
                </FlexJustifyBetween>
                <Air vertical={8}/>
                <Spin spinning={loading}>
                    {
                        listData.length ?
                            <Grid>
                                {
                                    editing ?
                                        <CheckBoxHigherOrder
                                            data={listData}
                                            itemStyle={{display : "flex",flexDirection: "column"}}
                                            renderUnSelectItem={value =>
                                                <MetaCard
                                                    title={value.title}
                                                    time={value.create_time}
                                                    count={<div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <span>观众人数:{value.live_count}人</span>
                                                        <span>观看次数:{value.live_count}次</span>
                                                        </div>}
                                                    cover={value.poster}
                                                    select={"unselect"}
                                                />
                                            }
                                            renderSelectedItem={value =>
                                                <MetaCard
                                                    title={value.title}
                                                    time={value.create_time}
                                                    count={<div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <span>观众人数:{value.live_count}人</span>
                                                    <span>观看次数:{value.live_count}次</span>
                                                    </div>}
                                                    cover={value.poster}
                                                    select={"selected"}
                                                />                                            }
                                            onChange={items => {
                                                this.selectedMeeting = items;
                                            }}
                                            extractKey={item => item.id.toString()}
                                        >
                                        </CheckBoxHigherOrder>
                                        :
                                        listData.map(value => (
                                            <div>
                                                <FlexCol>
                                                    <MetaCard
                                                        title={value.title}
                                                        time={value.create_time}
                                                        count={<div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <span>观众人数:{value.live_count}人</span>
                                                        <span>观看次数:{value.live_count}次</span>
                                                        </div>}
                                                        cover={value.poster}
                                                        actionView={
                                                            <HoverItem>
                                                                <Link className={"link"} to={`prokect/preview/${value.id}`}>
                                                                    <div className={"textBox"}>
                                                                        预览
                                                                    </div>
                                                                </Link>
                                                                <Link className={"link"} to={`prokect/edit/${value.id}`}>
                                                                    <div className={"textBox"}>
                                                                        编辑
                                                                    </div>
                                                                </Link>
                                                            </HoverItem>
                                                        }
                                                    />
                                                </FlexCol>
                                            </div>
                                        ))
                                }
                            </Grid>
                            :
                            <Empty description={"暂无数据"}/>
                    }
                </Spin>
                <Air vertical={10}/>
                {
                    listData.length ?
                        <FlexJustifyEnd>
                            <Pagination pageSize={pageSize} current={currentPage} total={total} onChange={page => {
                                this.getListData(page)
                            }}/>
                        </FlexJustifyEnd>
                        :
                        undefined
                }
            </div>
        );
    }
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.8vw;
`

const HoverItem = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    //grid-template-rows: 50% 50%;

    & .link{
      color: #fff;
      display: inline-block;
      text-align: center;
      height: 100%;
    }
    
    & .link .textBox{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    & .link :hover {
        background: #3b87f7;
    }
`
