/**
 * 收益总览-会议列表
 * Create by liaokai on 2020-05-11 15:57:07
 */
import * as React from 'react';
import {Component} from "react";
import {Button, message, Table} from "antd";
import {IChargeManagement} from "../../../../../common/apis/apis/chargeManagement";
import {apis} from "../../../../../common/apis/apis";
import {IMeetingProfitItem} from "../../../../../common/apis/apis/getMeetingProfit";
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";
import ReactZmage from "react-zmage";

interface IProps extends RouteComponentProps{
    start_time? : string,
    end_time? : string,
    title? : string,
}

interface IState{
    loading : boolean,
    dataSource : IMeetingProfitItem[],
    currentPage : number,
    total : number
}

const defaultPageSize = 10

export class ProceedsTable extends Component<IProps,IState>{
    readonly state : IState = {
        loading : false,
        dataSource : [],
        currentPage : 1,
        total : 0
    };


    columns = [
        {
            title: '会议',
            key: 'cover',
            render: (text : string, record : IMeetingProfitItem, index : number) => <ReactZmage
                src={record.cover}
                // @ts-ignore
                style={{width : 110, height: 60}}
                alt=""
            />
        },
        {
            title: '标题',
            key: 'title',
            render: (text : string, record : IMeetingProfitItem, index : number) => record.title,
        },
        {
            title: '地点',
            key: 'city',
            render: (text : string, record : IMeetingProfitItem, index : number) => record.city,
        },
        {
            title: '时间',
            key: 'data',
            render: (text : string, record : IMeetingProfitItem, index : number) => record.start_time,
        },
        {
            title: '收益',
            key: 'proceeds',
            render: (text : string, record : IMeetingProfitItem, index : number) => <b>{record.count_aumont}元</b>,
        },
        {
            title: '操作',
            key: 'ope',
            render: (text: string, record : IMeetingProfitItem, index : number) => {
                const {location : {pathname}} = this.props
                return <Link to={`${pathname}/detail/${record.id}`}><span >查看详情></span></Link>
            }
        },
    ]

    getData = (page : number = 1,
               pageSize: number = defaultPageSize
    ) => {
        const {start_time,end_time, title} = this.props
        this.setState({loading : true})
        apis.getMeetingProfit({
            start_time : start_time ?? "",
            end_time : end_time ?? "",
            title : title ?? "",
            page : page,
            pageNum : pageSize
        })
            .then(res => {
                this.setState({dataSource : res.data, total : res.count_num})
            })
            .catch(e => {
                message.error("加载失败")
            }).finally(()=>{
                this.setState({loading : false})
            })
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)){
            this.getData();
        }
    }

    render() {
        let {} = this.props;
        let {loading,dataSource,currentPage,total} = this.state;
        return (
            <Table
                loading={loading}
                columns={this.columns}
                dataSource={dataSource}
                pagination={{
                    current: currentPage,
                    total: total,
                    pageSize : defaultPageSize,
                    onChange: page => {
                        this.getData(page);
                        this.setState({currentPage: page})
                    }
                }}
            />
        );
    }
}
