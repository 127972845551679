/**
 * 短信模板更新
 * Create by liaokai on 2020-04-24 12:40:23
 */
import {toPost} from "../../http-utils";
import {ITagItem} from "../../../../routes/index/meeting-list/meeting/menu/sms-multi-send/sms-template/editor/sms-template-editor";

export const smsTemplateUpdate = toPost<
    {
        meetid : number,
        title : string,
        text : ITagItem[],
        id : number,        //模板id
    },
    {

    }
>("/merchant/Lcm/smsTemplateUpdate");

