/**
 * 导出收支记录
 * Create by liaokai on 2020-05-09 15:37:07
 */
import {toPost} from "../http-utils";

export const journalAccountRecord = toPost<
    {

    },
    {
        excelDownloadUrl : string
    }
>("/merchant/journalAccountRecord");
