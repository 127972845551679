/**
 * 会议-菜单栏-前缀组件
 * Create by liaokai on 2020-05-12 11:09:22
 */
import * as React from 'react';
import { Component } from "react";
import { apis } from "../../../../../../../common/apis/apis";
import { IMeetingPandect } from "../../../../../../../common/apis/apis/getMeetingPandect";
import { Air } from "../../../../../../../components/air/Air";
import { storeMenu } from "../../../../../../../common/menu.store";
import style from "./meeting-menu-prefix.scss"
import { common } from "../../../../../../../common/common";
import { Tag } from "antd";
import styled from "styled-components";
import { FlexCol } from "../../../../../../../components/style/flex/flex-col";
import { Flex } from "../../../../../../../components/style/flex/flex";
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

interface IProps {
    meeting_id: string
}

interface IState {
    data?: IMeetingPandect,
}

export class MeetingMenuPrefix extends Component<IProps, IState>{
    readonly state: IState = {

    };

    componentDidMount() {
        apis.getMeetingPandect({ id: this.props.meeting_id }).then(res => {
            this.setState({ data: res })
        }).catch(e => {
            this.setState({ data: undefined })
        })
    }

    liveColor(meeting_status: string): string {
        return meeting_status === '进行'
            ? common.color.success
            : meeting_status === '结束'
                ? common.color.danger
                : '';

    }

    render() {
        let { } = this.props;
        let { data } = this.state;
        return (
            data ?
                <Container>
                    <FlexCol>
                        <Flex>
                            <div style={{ backgroundImage: `url(${data.cover})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', width: (45 * 16) / 9, height: 45, borderRadius: 4 }} />

                            <Air horizontal={5} />
                            <div>
                                <Flex>
                                    <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", wordBreak: "keep-all", overflow: "hidden" }}>{data.title}</div>
                                    <Air horizontal={5} />
                                    <div>
                                        <Tag color={this.liveColor(data.meeting_status)}>{data.meeting_status}</Tag>
                                    </div>
                                </Flex>

                                <Time>{data.start_time}</Time>
                            </div>
                        </Flex>
                    </FlexCol>
                    <Link to={"/index/meeting_list/index"}>返回会议列表 <RightOutlined /></Link>
                </Container>
                :
                <div />
        );
    }
}

const Container = styled.div`
  padding: 10px 10px;
  margin: 15px 0px;
  background: ${common.color.disable};
  display : flex;
  align-items: center;
  justify-content: space-between;
  cursor : pointer;
`

const Time = styled.div`
  margin-top: 5px;
  font-size: 10px;
  color: ${common.color.normalText};
`
