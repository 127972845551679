/**
 * 使用记录
 * Create by liaokai on 2020-04-30 12:11:59
 */
import * as React from 'react';
import {Component} from "react";
import {PaymentTable} from "./payment-table/payment-table";
import {Button} from "antd";
import {apis} from "../../../../../common/apis/apis";
import {orderAccountRecord} from "../../../../../common/apis/apis/orderAccountRecord";

interface IProps{

}

interface IState{

}

export class PaymentRecord extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <div>
                {/*<div style={{display: "flex", justifyContent : "flex-end",margin: "0px 0px 15px"}}>*/}
                {/*    <Button type={"link"} style={{paddingRight: 0}} onClick={()=>{*/}
                {/*        apis.orderAccountRecord({}).then(res => {*/}
                {/*            window.location.href = res.excelDownloadUrl*/}
                {/*        })*/}
                {/*    }}>导出记录</Button>*/}
                {/*</div>*/}
                <div>
                    <PaymentTable/>
                </div>
            </div>
        );
    }
}
