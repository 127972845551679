/**
 * 分会场 - 路由
 * Create by gaohan on 2020-09-08
 */
import * as React from 'react';
import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  SurfaceRouter,
  tSurfaceRouteItem,
} from '../../../../../../components/surface-router/surface-router';
import { Venue } from './venue';
import { Detail } from './detail';

interface IProps extends RouteComponentProps {}

interface IState {}

const routerData: tSurfaceRouteItem = {
  breadTitle: '分会场',
  routeName: '',
  component: Venue,
  children: [
    {
      breadTitle: '新增分会场',
      routeName: 'add',
      component: Detail,
      children: [],
    },
    {
      breadTitle: '编辑分会场',
      routeName: 'edit/:id',
      component: Detail,
      children: [],
    },
  ],
};

export class VenueRouter extends Component<IProps, IState> {
  readonly state: IState = {};
  render() {
    let {} = this.props;
    let {} = this.state;
    return <SurfaceRouter {...this.props} entryRoute={routerData} />;
  }
}
