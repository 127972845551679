import {toPost} from "../../http-utils";

export const autoHeatvalSet = toPost<
    {
        'live_id':any,
        'seconds':any,
        'status':any,
        'max':any,
        'min':any,
    },
    {

    }
    >("/merchant/live/autoHeatvalSet")