/**
 * 问卷调查 - 回答详情
 * Create by liaokai on 2020-06-16 14:42:59
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {
    IQuestionPublishShowItem,
    questionPublishShow
} from "../../../../../../../common/apis/apis/live/questionPublishShow";
import {common} from "../../../../../../../common/common";
import {FlexJustifyEnd} from "../../../../../../../components/style/flex/flex-justify-end";
import {Flex} from "../../../../../../../components/style/flex/flex";
import {Button, Table,Row,Col, message} from "antd";
import {Air} from "../../../../../../../components/air/Air";
import {
    ILiveSurveyQuestionnaireByUserAnswerItem,
    liveSurveyQuestionnaireByUserAnswer,
    exportliveSurveyQuestionnaireByUserAnswerUrl
} from "../../../../../../../common/apis/apis/live/liveSurveyQuestionnaireByUserAnswer";
import {Link} from "react-router-dom";

interface IProps extends RouteComponentProps<{question_id : string,title:string}>{

}

interface IState{
    columns : any[]
    dataSource : ILiveSurveyQuestionnaireByUserAnswerItem[],
    currentPage : number,
    total : number,
    pageSize: number,
    loading : boolean,
}

export class LiveQuestionAnswerDetail extends Component<IProps,IState>{
    readonly state : IState = {
        columns : [
            {
                title: '用户',
                dataIndex: 'username',
                key: 'username',
                render: (text : boolean) => text,
            },
            {
                title: '提交时间',
                dataIndex: 'commit_time',
                key: 'commit_time',
                render: (text: string) => text,
            },
            // {
            //     title: '获得积分',
            //     dataIndex: 'credit',
            //     key: 'credit',
            //     render: (text: string) => text,
            // },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                render: (text: string) => <Link to={`${this.props.match.params.question_id}/commit_detail/${text}`}>提交情况</Link>,
            },
        ],
        dataSource : [],
        pageSize : common.defaultPaginate,
        total : 0,
        currentPage : 1,
        loading : false
    };

    reqTableData(page : number){
        this.setState({loading : true})
        liveSurveyQuestionnaireByUserAnswer({
            id : this.props?.match?.params?.question_id,
            page : page,
            paginate : this.state.pageSize
        }).then(res => {
            this.setState({
                currentPage : page,
                total : res.total,
                dataSource : res.data
            })
        }).finally(()=>{
            this.setState({loading : false})
        })
    }

    componentDidMount(): void {
        this.reqTableData(1)
    }

    render() {
        let {} = this.props;
        let {columns, dataSource, pageSize, total, currentPage, loading} = this.state;
        return (
            <div>
                {/* <FlexJustifyEnd>
                    <Flex>
                    
                    </Flex>
                    <Flex>
                        <Button>导出</Button>
                        <Air horizontal={5}/>
                        <Search placeholder="搜索内容" onSearch={value => {}} enterButton />
                    </Flex>
                </FlexJustifyEnd> */}
                <Row justify="space-between" align="middle">
                    <Col><h4 style={{textAlign:'left'}}>问卷名称:{this.props?.match?.params?.title}</h4></Col>
                    <Col> 
                        <Button onClick={()=>{
                            exportliveSurveyQuestionnaireByUserAnswerUrl({id:this.props?.match?.params?.question_id}).then(res=>{
                                if(res.url){
                                    window.open(res.url)
                                }else{
                                    message.info("暂无Excel可导出")
                                }
                            })
                        }} >导出</Button>
                    </Col>
                </Row>
                <Air vertical={5}/>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={dataSource}
                    emptyText={"暂无内容"}
                    pagination={{
                        defaultCurrent : 1,
                        current : currentPage,
                        pageSize : pageSize,
                        total : total,
                        onChange : page => {
                            this.reqTableData(page);
                            this.setState({currentPage : page})
                        }
                    }}
                />
            </div>

        );
    }
}
