/**
 * 会议-签到管理-路由
 * Create by liaokai on 2020-04-15 18:15:45
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {SignInDetail} from "./detail/detail";
import {SurfaceRouter, tSurfaceRouteItem} from "../../../../../../components/surface-router/surface-router";
import {SignInView} from "./index/signin-view";
import Scanner from '../../../../../../routes/SignInView'

interface IProps extends RouteComponentProps{

}

interface IState{

}

const routerData : tSurfaceRouteItem = {
    breadTitle : "签到管理",
    routeName : "",
    component : SignInView,
    children : [
        {
            breadTitle : "签到详情",
            routeName : "detail/:sign_in_id",
            component : SignInDetail,
            children : []
        },
        // {
        //     breadTitle : "签到大厅",
        //     routeName : "scanner/:sign_in_id",
        //     component : Scanner,
        //     children : []
        // }
    ]
};

export class SigninRouter extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={routerData}/>
        );
    }
}
