/**
 * 用户-收益总览-路由节点
 * Create by liaokai on 2020-04-15 16:18:08
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import Detail from "./detail"
import {SurfaceRouter, tSurfaceRouteItem} from "../../../../components/surface-router/surface-router";
import {Proceeds} from "./index/proceeds";

interface IProps extends RouteComponentProps{

}

interface IState{

}

const routerData : tSurfaceRouteItem = {
    breadTitle : "收益总览",
    routeName : "",
    component : Proceeds,
    children : [
        {
            breadTitle : "查看详情",
            routeName : "detail/:detail_id",
            component : Detail,
            children : []
        }
    ]
};

export class ProceedsRouter extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={routerData}/>
        );
    }
}
