/**
 * 添加问答
 * Create by liaokai on 2020-05-28 13:26:41
 */
import * as React from "react";
import { Component } from "react";
import { Input, Radio, Checkbox } from "antd";
import { TitleContentTable } from "../../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import { AddQuestionFormOptions } from "./add-question-form-options";
import {
    IQuestionStoreOpt,
    IQuestionStoreQuestion,
} from "../../../../../../../common/apis/apis/live/questionStore";

interface IProps {
    data: IQuestionStoreQuestion;
    onChange?: (data: IQuestionStoreQuestion) => void;
    requireAnswer?: boolean;
}

interface IState {}

export function AddQuestionForm(props: IProps) {
    const onChangeForm = (item: any) => {
        let { data, onChange } = props;
        onChange?.({
            ...data,
            ...item,
        });
    };
    const {
        onChange,
        data: { title, type, content },
        data,
        requireAnswer,
    } = props;
    
    const isQa = window.location.href.indexOf('qa') !== -1;
    return (
        <TitleContentTable rowGap={12} colGap={12}>
            <div>标题</div>
            <Input
                placeholder={"请输入标题"}
                value={title}
                onChange={(e) => {
                    onChangeForm({ title: e.target.value });
                }}
            />
            <div>类型</div>
            <Radio.Group
                onChange={(e) => {
                    onChangeForm({ type: e.target.value });
                }}
                value={type}
            >
                <Radio value={"single"}>单选</Radio>
                <Radio value={"multi"}>多选</Radio>
                {!isQa && <Radio value={"qa"}>问答</Radio>}
            </Radio.Group>
            {data.type !== "qa" && (
                <div style={{ alignSelf: "flex-start" }}>选项</div>
            )}

            {data.type !== "qa" && (
                <AddQuestionFormOptions
                    requireAnswer={requireAnswer}
                    type={data.type}
                    options={content}
                    onChange={(options1) => onChangeForm({ content: options1 })}
                />
            )}
        </TitleContentTable>
    );
}
