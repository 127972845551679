/**
 * 关键字添加
 */
import {toPost} from "../../http-utils";

export const LiveKeywordsStore = toPost<{
    live_id: string,
    keyword: string
}, {
    "id": number,
    "keyword": string,
    "create_time": string
}>("/merchant/live/liveKeywordsStore");

