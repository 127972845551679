
import style from './BaseAlert.scss'
import React from 'react'
import ReactDOM from 'react-dom';
let base_box=(message,visible,type)=>{
    return (
    <div className={[style.BaseBox].join(' ')}>
        <div className={[style.AnmiBox,visible?style.SlideIn:style.SlideOut,style[type]].join(' ')} >
        {message}
        </div>
    </div>
    )
}
let messageTimeOut;
const HellView = ({
    visible=true,
    message,
    type,
}) => {
    return (
        ReactDOM.createPortal(base_box(message,visible,type),document.querySelector('body'))
    )
}

['message','error','success'].forEach(item => {
    // eslint-disable-next-line react/no-multi-comp
    HellView[item] = ({ ...props}) => {
        let div = document.createElement('div');
        let currentConfig = Object.assign({}, props);
        const destroy = () => {
            const unmountResult = ReactDOM.unmountComponentAtNode(div);
            if (unmountResult && div.parentNode) {
                div.parentNode.removeChild(div); 
            }
        }
        const render = (config) => {
            //name传入调用的方法名，用于区分使用不同footer和Icon
            ReactDOM.render(<HellView destroy={destroy} name={item} type={item} {...config}  />, div);
        }
        // 更新
        const update = (newConfig) => {
            currentConfig = Object.assign({}, currentConfig,newConfig);
            render(currentConfig);
        }
        render(currentConfig);
        messageTimeOut = setTimeout(() => {
            update({
                visible:false
            });
            setTimeout(() => {
                destroy();
            }, 500);
        }, 3000);
        return {
            destroy: destroy,
            update: update
        }
    }
});

export default HellView;