/**
 * 直播管理 menu
 * Create by liaokai on 2020-05-23 17:11:37
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {tMenuVerticalTreeWithComp} from "../../../user/UserIndexView";
import {OverviewRouter} from "../../../meeting-list/meeting/menu/overview/overview-router";
import {observer} from "mobx-react";
import {common} from "../../../../../common/common";
import {apis} from "../../../../../common/apis/apis";
import {Responsive} from "../../../../../components/common/responsive";
import {MenuContent} from "../../../../../components/menu-content/menu-content";
import {storeMenu} from "../../../../../common/menu.store";
import {MeetingMenuPrefix} from "../../../meeting-list/meeting/menu/compoonent/meeting-menu-prefix/meeting-menu-prefix";
import {LiveEditRouter} from "./edit/edit-router";
import {LiveOverviewRouter} from "./overview/overview-router";
import {LiveLimitRouter} from "./limit/limit-router";
import {LiveUserRouter} from "./user/user-router";
import {LiveFormRouter} from "./form/form-router";
import {LiveCreditRouter} from "./credit/credit-router";
import {LiveChatRouter} from "./chat/chat-router";
import {LiveRedEnvelopeRouter} from "./red-envelope/red-envelope-router";
import {LivePrizeRouter} from "./prize/prize-router";
import {LiveDocDownloadRouter} from "./doc-download/doc-download-router";
import {LiveQaRouter} from "./qa/qa-router";
import {LiveContentRouter} from "./live_content/live-content-router";
import {LiveDataCountRouter} from "./data-count/data-count-router";
import {LiveProkectRouter} from "./prokect/prokect-router";
import {LiveDocRouter} from "./doc/doc-router";
import {LiveKeywordFilterRouter} from "./keyword-filter/keyword-filter-router";
import {LiveCreditListRouter} from "./credit-list/credit-list-router";
import {Alert, Button, Tag} from "antd";
import {Padding} from "../../../../../components/style/space/padding";
import {FlexJustifyBetween} from "../../../../../components/style/flex/flex-justify-between";
import {Flex} from "../../../../../components/style/flex/flex";
import {Link, } from "react-router-dom";
import {FlexJustifyAlignCenter} from "../../../../../components/style/flex/flex-justify-align-center";
import styled from "styled-components";
import {Air} from "../../../../../components/air/Air";
import {RightOutlined, VideoCameraOutlined} from "@ant-design/icons/lib";
import {liveStore} from "./live.store";
import {liveShow} from "../../../../../common/apis/apis/live/liveShow";
import {FlexCol} from "../../../../../components/style/flex/flex-col";
import {FlexAlignCenter} from "../../../../../components/style/flex/flex-align-center";
import {LiveRelationsRouter} from "./relations/relations-router";
import {LiveQuestionRouter} from "./question/question-router";
import {defaultRoute} from "./default-route";
import {getMenu} from "../../../../../common/apis/apis/get-menu";
import {GiftRouter} from "./gift/gift-router";
import {defaultDevRoute} from "./default-dev-route";

interface IProps extends RouteComponentProps<{live_id : string}>{

}

interface IState{
    menuData : tMenuVerticalTreeWithComp
    defaultRoute : string
    isShow:boolean
}

const menuItemsRaw : tMenuVerticalTreeWithComp = [


    {
        //1 文档翻页2抽奖3问卷4问答5签到6成员管理7聊天过滤8公告9全员禁言
        title : "菜单",
        items : [
            {
                title : "默认页面",
                routerName : "defaultRoute",
                plusField : {
                    component : defaultRoute
                }
            },
            {
                title : "文档翻页",
                routerName : "doc_manager",
                plusField : {
                    component : LiveDocRouter
                }
            },
            {
                title : "抽奖",
                routerName : "gift",
                plusField : {
                    component : GiftRouter //奖品
                }
            },
            {
                title : "问卷",
                routerName : "question",
                plusField : {
                    component : LiveQuestionRouter
                }
            },
            {
                title : "问答",
                routerName : "qa",
                plusField : {
                    component : LiveQaRouter
                }
            },
            {
                title : "签到",
                routerName : "sign",
                plusField : {
                    component : defaultDevRoute
                }
            },
            {
                title : "成员管理",
                routerName : "user",
                plusField : {
                    component : LiveUserRouter
                }
            },
            {
                title : "聊天过滤",
                routerName : "chat",
                plusField : {
                    component : LiveChatRouter
                }
            },
            {
                title : "公告",
                routerName : "announcement",
                plusField : {
                    component : defaultDevRoute
                }
            },
            {
                title : "全员禁言",
                routerName : "banned",
                plusField : {
                    component : defaultDevRoute
                }
            },
        ]
    }
];

@observer
export class OtherMenu extends Component<IProps,IState>{

    //从菜单树过滤 hidden === true，且不在 openRouterNames 白名单上的节点
    static openMenu(tree : tMenuVerticalTreeWithComp, openRouterNames : string[]) : tMenuVerticalTreeWithComp{
        return common.deepClone(tree).map(value => {
            // 仅隐藏隐藏的菜单项（刷新页面会仍在原页面）
            value.items.forEach(value1 => {
                if(openRouterNames.filter(value2 => value2 === value1.routerName).length > 0){
                    value1.hidden = false
                }
            })
            return value;
        })
    }

    readonly state : IState = {
        menuData : OtherMenu.openMenu(menuItemsRaw, []),
        defaultRoute : 'defaultRoute',
        isShow: false
    };

    componentDidMount() {
        getMenu({live_id:this.props.match.params.live_id}).then((res)=>{
            let item = res.map(value => {
                if(value.default === true)
                {
                    this.setState({defaultRoute: menuItemsRaw[0].items[value.id].routerName})
                }
                return menuItemsRaw[0].items[value.id]
            })
            let menu =  [ {
                //1 文档翻页2抽奖3问卷4问答5签到6成员管理7聊天过滤8公告9全员禁言
                title : "菜单",
                items :item}];
            console.log(menu)
            this.setState({menuData:menu})


        })
    }

    render() {
        const {match : {params}} = this.props
        const {defaultRoute,isShow} = this.state;
        return (
            <Responsive>
                {/*本次直播信息*/}
                <AlertBox>
                    <Flex>
                        {/*直播状态所有角色都要显示*/}
                        <FlexCol>
                            <Flex>
                                <div>
                                    <div>{liveStore.liveInfo.title}</div>
                                </div>
                                <Air horizontal={5}/>
                                <div>
                                    <Tag color="#108ee9">{liveStore.liveInfo.live_status}</Tag>
                                </div>
                            </Flex>
                            <Time>{liveStore.liveInfo.live_time}</Time>
                        </FlexCol>
                    </Flex>
                    {/*这个只能主持人显示*/}
                    {
                        liveStore.role === 1 ?
                            <FlexAlignCenter>
                                <Air horizontal={15}/>
                                <Link to={"../live_room"} target={"_blank"}>
                                    <FlexJustifyAlignCenter>
                                        <Button type={"primary"} icon={<VideoCameraOutlined />}>开始直播</Button>
                                    </FlexJustifyAlignCenter>
                                </Link>
                            </FlexAlignCenter>
                            :
                            <FlexAlignCenter>
                                <Air horizontal={15}/>
                                <Link to={"../live_room"} target={"_blank"}>
                                    <FlexJustifyAlignCenter>
                                        <Button type={"primary"} icon={<VideoCameraOutlined />}>观看直播</Button>
                                    </FlexJustifyAlignCenter>
                                </Link>
                            </FlexAlignCenter>
                    }
                </AlertBox>
                <MenuContent
                    ref={ref => storeMenu.setLiveMenuRef(ref)}
                    {...this.props}
                    menuData={this.state.menuData}
                    defaultRouterName={defaultRoute}
                />
            </Responsive>
        );
    }
}


const Time = styled.div`
  margin-top: 5px;
  font-size: 10px;
  color: ${common.color.normalText};
`

const AlertBox = styled(FlexJustifyBetween)`
  align-items: center;
  margin: 15px 0px;
  padding: 15px;
  background: #f3f3f3;
  border: 1px solid #eee;
`

