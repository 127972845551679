/**
 * websocket绑定
 * Create by liaokai on 2020-05-29 18:58:53
 */
import {toPost} from "../../http-utils";
export interface replyKeywords {
    id:any,
    keywords:any,
    reply_content:any,
}
export const replyKeywordsList = toPost<
    {
        live_id : string
        paginate : any
        page : any
    },
    {
        "current_page": number,
        "total": number,
        "last_page": number,
        "data": replyKeywords[]
    }
>("/merchant/live/replyKeywordsList");
