// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".layout-sider-content_responsive__1BiXn{display:flex;flex-direction:row}.layout-sider-content_responsive__1BiXn .layout-sider-content_left__1LO9b{flex:4 1;flex-shrink:0;margin-right:3vw}.layout-sider-content_responsive__1BiXn .layout-sider-content_right__3_Btx{flex:25 1;flex-shrink:0}\n", ""]);
// Exports
exports.locals = {
	"responsive": "layout-sider-content_responsive__1BiXn",
	"left": "layout-sider-content_left__1LO9b",
	"right": "layout-sider-content_right__3_Btx"
};
module.exports = exports;
