// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PageBurster_PagerHandleBox__5b6zN{width:100%;height:56px;margin:0 auto}.PageBurster_PagerHandleBox__5b6zN .PageBurster_Pager__1aa3l .PageBurster_PagerNum__gcx1Q{width:28px;height:28px;box-shadow:#C4C4C4 0 0 0 1px inset;border-radius:6px;margin-right:7px;font-size:14px;font-weight:300;color:#c4c4c4;cursor:pointer}.PageBurster_PagerHandleBox__5b6zN .PageBurster_Pager__1aa3l .PageBurster_Act_PagerNum__2m8CJ{color:#fff;background:#0089FF;box-shadow:#0089FF 0 0 0 1px inset}.PageBurster_PagerHandleBox__5b6zN .PageBurster_Pager__1aa3l .PageBurster_ArrowIcon__3lclt{width:0px;height:0px;border-top:transparent 7.5px solid;border-bottom:transparent 7.5px solid;border-left:rgba(0,0,0,0.3) 9px solid;border-right:rgba(0,0,0,0.3) 0px solid}.PageBurster_PagerHandleBox__5b6zN .PageBurster_Pager__1aa3l .PageBurster_arrowLeft__2M15A{transform:rotate(180deg) translateX(0%)}.PageBurster_PagerHandleBox__5b6zN .PageBurster_Pager__1aa3l .PageBurster_arrowRight__2ZgNH{transform:translateX(25%)}\n", ""]);
// Exports
exports.locals = {
	"PagerHandleBox": "PageBurster_PagerHandleBox__5b6zN",
	"Pager": "PageBurster_Pager__1aa3l",
	"PagerNum": "PageBurster_PagerNum__gcx1Q",
	"Act_PagerNum": "PageBurster_Act_PagerNum__2m8CJ",
	"ArrowIcon": "PageBurster_ArrowIcon__3lclt",
	"arrowLeft": "PageBurster_arrowLeft__2M15A",
	"arrowRight": "PageBurster_arrowRight__2ZgNH"
};
module.exports = exports;
