/**
 * 订单支付 弹窗
 * Create by liaokai on 2020-05-14 13:39:12
 */
import * as React from 'react';
import {Component} from "react";
import {Descriptions, Modal} from "antd";
import {Air} from "../../../../../../../../components/air/Air";
import {common} from "../../../../../../../../common/common";
import {IRechargeImgWechat} from "../../../../../../../../common/apis/apis/get-recharge-img";

export interface IOrderPayData {
    orderId : string,
    product : string,
    price : number,
    createTime : string,
    payMethod : string
}

export interface IOrderPayProps {
    show: boolean
    data?: IOrderPayData
    onCancel?: () => void,
    cashier? : JSX.Element
}

interface IState{

}

export class OrderPay extends Component<IOrderPayProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {show, data, onCancel,cashier} = this.props;
        let {} = this.state;
        return (
            <Modal
                visible={show}
                onCancel={()=>{
                    onCancel?.();
                }}
                centered={true}
                title={"订单支付"}
                width={800}
                cancelText={"稍后支付"}
                okButtonProps={{style : {display : "none"}}}
            >
                <div style={{display : "flex", alignItems : "center"}}>
                    <Descriptions bordered={true} column={1} style={{flex : 1}}>
                        <Descriptions.Item label="订单编号">{data?.orderId}</Descriptions.Item>
                        <Descriptions.Item label="产品">{data?.product}</Descriptions.Item>
                        <Descriptions.Item label="价格">{data?.price?.toFixed?.(2) ?? ""} 元</Descriptions.Item>
                        <Descriptions.Item label="创建时间">{data?.createTime}</Descriptions.Item>
                        <Descriptions.Item label="支付方式">{data?.payMethod}</Descriptions.Item>
                    </Descriptions>
                    <Air horizontal={20}/>
                    <div style={{display : "flex", flexDirection : "column" ,alignItems : "center"}}>
                        {show ? cashier : undefined}
                    </div>
                    <Air horizontal={20}/>
                </div>
            </Modal>
        );
    }
}
