/**
 * 并发展示
 * Create by liaokai on 2020-06-23 18:19:04
 */
import * as React from "react";
import { Component } from "react";
import moment from "moment";
import { Chart, Point, Line, Annotation } from "bizcharts";
import { Button, Table } from "antd";
import {
    docIndex,
    IDocIndex,
} from "../../../../../../../../common/apis/apis/live/docIndex";
import { docShow } from "../../../../../../../../common/apis/apis/live/docShow";
import { common } from "../../../../../../../../common/common";
import { liveStore } from "../../../live.store";
import { Flex } from "../../../../../../../../components/style/flex/flex";
import { Flex1 } from "../../../../../../../../components/style/flex/flex-1";
import { Air } from "../../../../../../../../components/air/Air";
import {
    concurrenceChart,
    IConcurrenceChartItem,
} from "../../../../../../../../common/apis/apis/live/concurrenceChart";
import { concurrenceTable } from "../../../../../../../../common/apis/apis/live/concurrenceTable";

import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/line';

import 'echarts/lib/component/tooltip';

interface IProps { }

interface IState {
    columns: any[];
    dataSource: IConcurrenceChartItem[];
    currentPage: number;
    total: number;
    pageSize: number;
    loading: boolean;
    chartData: IConcurrenceChartItem[];
}

const scale = {
    // date: {type: 'cat'},
    value: {
        type: "linear",
        formatter: (val: string) => {
            return val;
        },
    },
};
const getDate = () => {
    let day1 = new Date();
    day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
    let s1 =
        day1.getFullYear() + "-" + (day1.getMonth() + 1) + "-" + day1.getDate();
    let day2 = new Date();
    day2.setTime(day2.getTime());
    let s2 =
        day2.getFullYear() + "-" + (day2.getMonth() + 1) + "-" + day2.getDate();
    return [s1, s2];
};
const defaultData = [
    {
        time: getDate()[0],
        num: 0,
    },
    {
        time: getDate()[1],
        num: 1,
    },
];

export class Concurrent extends Component<IProps, IState> {
    readonly state: IState = {
        columns: [
            {
                title: "时间",
                dataIndex: "time",
                key: "time",
                render: (text: boolean) => text,
            },
            {
                title: "并发",
                dataIndex: "num",
                key: "num",
                render: (text: string) => text,
            },
        ],
        dataSource: [],
        pageSize: 4,
        total: 0,
        currentPage: 1,
        loading: false,
        chartData: [],
    };

    componentDidMount() {

        const myChart = echarts.init(document.getElementById('main') as any);

        concurrenceChart({
            live_id: liveStore.live_id,
        }).then((res) => {
            console.log("res", res);
            const data = res.length > 0 ? res : defaultData;
            this.setState({ chartData: data });

            var dateList = data.map(function (item) {
                return item.time;
            });
            var valueList = data.map(function (item) {
                return item.num;
            });
            console.log('valueList', valueList);
            const option: any = {
                // Make gradient line here
                visualMap: [{
                    // show: false,
                    type: 'continuous',
                    // seriesIndex: 0,
                    min: 0,
                    max: 400
                }],
                title: {
                    // left: 'center',
                    text: 'Gradient along the y axis'
                },
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {
                    data: dateList
                },
                yAxis: {
                    splitLine: { show: false }
                },
                series: [{
                    type: 'line',
                    showSymbol: false,
                    data: valueList
                }]
            };
            myChart.setOption(option);
        });
        this.reqTableData();
    }

    reqTableData(page: number = 1, withLoading: boolean = true) {
        this.setState({ loading: withLoading });
        concurrenceTable({
            live_id: liveStore.live_id,
            page,
            paginate: this.state.pageSize,
        })
            .then((res) => {
                this.setState({
                    dataSource: res.data,
                    total: res.total,
                    currentPage: page,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    refreshPage = () => {
        this.reqTableData(this.state.currentPage, false);
    };

    render() {
        let { } = this.props;
        let {
            columns,
            dataSource,
            pageSize,
            total,
            currentPage,
            loading,
            chartData,
        } = this.state;
        console.log("chartData", chartData);

        const data = chartData.map((item) => {
            const date = moment(item.time).format("YYYY-MM-DD");
            return { ...item, time: date };
        });
        const dataRegionCfg: any = {
            start: data && data[0] ? [(data[0].time, data[0].num)] : [],
            end:
                data && data[data.length - 1]
                    ? [(data[data.length - 1].time, data[data.length - 1].num)]
                    : [],
            text: {
                content: "【关键区间】",
            },
            lineLength: 50,
        };
        return (
            <Flex>
                <div id="main" style={{ width: 400, height: 300 }}></div>

                {/* <Chart
                    width={500}
                    height={200}
                    data={data}
                    autoFit
                    scale={scale}
                >
                    <Line position="time*num" />

                    <Point
                        position="height*weight"
                        color="gender"
                        shape={["gender", ["circle", "square"]]}
                        style={{
                            fillOpacity: 0.85,
                        }}
                    />
                    <Annotation.DataRegion {...dataRegionCfg} />
                </Chart> */}

                <Air horizontal={10} />
                <Flex1>
                    <Table
                        size={"small"}
                        loading={loading}
                        columns={columns}
                        dataSource={dataSource}
                        emptyText={"暂无内容"}
                        pagination={{
                            defaultCurrent: 1,
                            current: currentPage,
                            pageSize: pageSize,
                            total: total,
                            onChange: (page) => {
                                this.reqTableData(page);
                                this.setState({ currentPage: page });
                            },
                        }}
                    ></Table>
                </Flex1>
            </Flex>
        );
    }
}
