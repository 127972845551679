import React, { Component } from 'react'
import style from './NewMeetingView.scss'
import {MeetingInfo} from "../meeting/menu/base/meeting-info";
import {Responsive} from "../../../../components/common/responsive";
import {Texts} from "../../../../components/text/Texts";
import {Typography} from "antd";

const {Title} = Typography

export class NewMeetingView extends Component {

render() {
    return <Responsive>
        <div className={style.NewMeetingView}>
            <div style={{margin: "30px 0px 10px"}}>
                <Title level={4}>新建会议</Title>
            </div>
            <MeetingInfo isNew={true}/>
        </div>
    </Responsive>
   }
}
export default NewMeetingView
