/**
 * 基本信息
 * Create by liaokai on 2020-04-01 10:48:22
 */
import * as React from 'react';
import { Component } from "react";
import { TitleContentTable } from "../lecturer/preview/title-content-table/title-content-table";
import { Button, Card, Cascader, DatePicker, Dropdown, Empty, Input, Menu, message, Radio } from "antd";
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import { Moment } from "moment"
import moment from "moment";
import { chinaCitys2CascaderOfAntd } from "../../../../../../common/data/china-citys/china-citys-for-antd-cascader";
import { Maps, tCoo } from "../../../../../../components/Maps/Maps";
import ImageCropper from "../../../new-meeting/components/ImageCropper/index";
import { LocationSearchInput, tLocationItem } from "./components/location-search-input/location-search-input";
import { api } from "../../../../../../common/app";
import { common } from "../../../../../../common/common";
import { RouteComponentProps } from "react-router";
import { apis } from "../../../../../../common/apis/apis";
import style from "./MeetingInfo.scss"
import ReactZmage from "react-zmage";
import { uploadFile } from "../../../../../../common/apis/apis/upload-file";
import { Mask } from '../../../../live-list/live/menu/edit/component/mask';
import { UploadImgTipStateless } from '../../../../live-list/live/menu/edit/component/upload-img-tip-stateless';
import { FlexJustifyCenter } from '../../../../../../components/style/flex/flex-justify-center';
import { getArea } from '../guest/guest/constants';

const { RangePicker } = DatePicker;

interface IProps extends RouteComponentProps<any> {
    isNew?: boolean
}

interface IState {
    meetingName: string,                    //会议名称
    meetingTime?: [Moment, Moment],          //会议时间, 固定长度 length = 2，[开始时间，结束时间]
    administrativeRegion?: string[],        //行政区，固定长度 length = 3， [省，市，区]
    selectedLoca?: tLocationItem,            //已选择的详细地址
    id?: number,                             //会议ID
    cover?: string,                          //封面图片地址
    live_link: string;
    live_type: number;
    live_link_2: string;
    options?: any[];
}

export class MeetingInfo extends Component<IProps, IState>{


    //state初始化
    readonly state: IState = {
        meetingName: "",
        id: undefined,
        live_link: '',
        live_type: 1,
        live_link_2: '',
        options: [],
    };


    componentDidMount(): void {
        if (!this.props.isNew) {
            this.initData();
        }

            
        getArea({}).then((response) => {
            this.setState({
                options: response,
            });
        });
    }

    //从远程获取默认数据
    initData() {
        let { match: { params: { meeting_id } } } = this.props;
        apis.getMeeting({ id: meeting_id }).then((res: any) => {
            // @ts-ignore
            this.setState({
                meetingName: res.title,
                meetingTime: [moment(res.start_time, "YYYY/MM/DD HH:mm:ss"), moment(res.end_time, "YYYY/MM/DD HH:mm:ss")],
                administrativeRegion: [res.province_code, res.city_code, res.area_code],
                selectedLoca: {
                    coo: {
                        latitude: res.latitude,
                        longitude: res.longitude,
                    },
                    name: res.address
                },
                id: res.id,
                cover: res.cover,
                live_link: res.type === 1 ? res.live_link : '',
                live_link_2: res.type === 2 ? res.live_link : '',
                live_type: res.type,

            } as any)
        }).catch(e => {
            common.sentry.captureException(e);
        });
    }

    //本地数据更新到远程
    updateDate() {
        let { meetingName, meetingTime, administrativeRegion, selectedLoca, id, cover, live_link, live_link_2, live_type, options } = this.state;
        console.log("live_link", live_link);

        if (live_type === 1 && live_link !== '') {
            if (live_link.startsWith('http') !== true) {
                message.error("外链地址不正确，地址以http://或者https://开头");
                return
            }
        }

        const pro = options?.find(a => a.value === administrativeRegion?.[0])
        console.log('pro', pro);
        const city = pro && pro.children.find((p: any) => p.value === administrativeRegion?.[1]);
        console.log('city', city);
        const area = city && city.children.find((c: any) => c.value === administrativeRegion?.[2]);
        console.log('area', area);
        console.log('administrativeRegion', administrativeRegion);
        // return;
        (api as any).createMeeting(
            meetingName,
            meetingTime?.[0].format("YYYY/MM/DD HH:mm:ss"),
            meetingTime?.[1].format("YYYY/MM/DD HH:mm:ss"),
            pro?.label || '',
            city?.label || '',
            area?.label || '',
            selectedLoca?.name,
            selectedLoca?.coo.longitude,
            selectedLoca?.coo.latitude,
            cover,
            id ?? "",
            live_type === 1 ? live_link : live_link_2,
            live_type,
            administrativeRegion?.[0],
            administrativeRegion?.[1],
            administrativeRegion?.[2],
        ).then((res: any) => {
            if (res.code == 200) {
                message.success("保存成功");
                if (this.props.isNew) {
                    window.location.hash = `#/index/meeting_list/meeting/${res.result.id}/overview`
                }
            } else {
                console.log("res", res);
                message.error(res.message);
                common.sentry.captureMessage("会议-基础数据-保存失败:" + JSON.stringify(res))
            }
        }, (err: any) => {
            console.log("err", err);
            message.success("保存失败");
            common.sentry.captureException(err)
        })
    }

    onCropedImage = async (result: any): Promise<boolean> => {
        let formdata = new FormData();
        formdata.append('file', result);
        formdata.append('type', 'base64');
        try {
            let res = await uploadFile(formdata)
            this.setState({ cover: res });
            return !!res;
        } catch (err) {
            message.error("服务器错误！");
            return false
        }
    }


    render() {
        let { isNew } = this.props;
        let { meetingName, selectedLoca, administrativeRegion, meetingTime, cover, id, live_link, options } = this.state;
        return (
            <div className={style.container}>
                {/*<div className={style.title}>*/}
                {/*    基本信息*/}
                {/*</div>*/}
                <div className={style.content}>
                    <TitleContentTable>
                        {/*会议名称*/}
                        <div className={style.lable}>
                            会议名称*
                        </div>
                        <div>
                            <Input placeholder="会议名称" maxLength={30} value={meetingName} onChange={event => this.setState({ meetingName: event.target.value })} />
                        </div>

                        {/*会议时间*/}
                        <div className={style.lable}>
                            会议时间*
                        </div>
                        <div>
                            <RangePicker allowClear={false} value={meetingTime} locale={locale} onChange={(values, formatString) => {
                                // @ts-ignore
                                this.setState({ meetingTime: values })
                            }} />
                        </div>

                        {/*省市区**/}
                        <div className={style.lable}>
                            省市区*
                        </div>
                        <div>
                            <Cascader
                                style={{ width: "100%" }}
                                allowClear={false}
                                options={options}
                                value={administrativeRegion}
                                onChange={(value: any) => {
                                    this.setState({ administrativeRegion: value })
                                }}
                                placeholder="选择省市区" />
                        </div>

                        {/*详细地址**/}
                        <div className={style.lable}>
                            详细地址*
                        </div>
                        <div>
                            <LocationSearchInput value={selectedLoca?.name} style={{ width: "100%" }} onChange={item => this.setState({ selectedLoca: item })} city={administrativeRegion?.[1]} />
                        </div>

                        {/*地图*/}
                        <div className={style.lable} />
                        <Card>
                            <Maps loca={selectedLoca?.coo} style={{ width: "100%", height: "32vw" }} />
                        </Card>


                        <div className={style.lable} style={{ marginTop: 16 }}>
                            线上直播
                        </div>
                        <div>
                            <Radio.Group value={this.state.live_type} onChange={e => this.setState({ live_type: e.target.value })}>
                                <Radio value={1}>外部直播</Radio>
                                <Radio value={2}>会佰分直播</Radio>
                            </Radio.Group>
                        </div>

                        <div className={style.lable}>
                            {''}
                        </div>
                        <div>
                            {this.state.live_type === 1
                                ? (
                                    <Input
                                        value={live_link}
                                        style={{ width: "100%" }}
                                        placeholder={"https://"}
                                        onChange={event => {
                                            this.setState({ live_link: event.target.value })
                                        }}
                                    />
                                )
                                : (
                                    <Input
                                        value={this.state.live_link_2}
                                        style={{ width: "100%" }}
                                        placeholder={"请输入会佰分直播ID"}
                                        onChange={event => {
                                            this.setState({ live_link_2: event.target.value })
                                        }}
                                    />

                                )}
                        </div>


                        {/*保存*/}
                        <div />
                        <div className={style.submitBox}>
                            <Button type={"primary"} size={"large"} onClick={() => {
                                this.updateDate();
                            }}>{isNew ? "提交" : "保存"}</Button>
                        </div>
                    </TitleContentTable>
                </div>
                <div style={{ position: 'relative' }}>
                    <div
                        style={{
                            display: "flex",
                            background: "#f3f3f3",
                            width: "320px",
                            height: "180px",
                            overflow: "hidden",
                            border: `2px ${common.color.disable} solid`,
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "150px",
                        }}
                    >
                        <ImageCropper
                            text={
                                <Mask
                                    floatView={
                                        cover ? (
                                            <UploadImgTipStateless
                                                theme={"dark"}
                                                title='上传封面'
                                            />
                                        ) : undefined
                                    }
                                >
                                    {cover ? (
                                        <FlexJustifyCenter
                                            style={{
                                                width: "320px",
                                                height: "180px",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    maxWidth: "320px",
                                                    width: "320px",
                                                    height: "180px",
                                                    maxHeight: "180px",
                                                }}
                                                src={cover}
                                                alt=""
                                            />
                                        </FlexJustifyCenter>
                                    ) : (
                                            <UploadImgTipStateless />
                                        )}
                                </Mask>
                            }
                            onUpdate={this.onCropedImage}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
