import React, { useEffect, useState } from 'react';
import { Form, Table, Switch, Button, message, Spin, Modal, Select, Space, DatePicker, Input, notification, Cascader, Popconfirm } from 'antd';
import { useAntdTable, useBoolean, useMount } from 'ahooks';
import { InfoCircleOutlined } from '@ant-design/icons'
import {
  givePlainUsersList,
  exportGivePlainUsersListUrl,
  publishPlain,
  delPlain,
  formatListResult,
  userGivePlainUpdate,
  userGivePlainShow,
  userGivePlainDel
} from './constants';
import { merchantUserIndex, formatListResult as formatListResultUser } from '../guest/guest/constants'
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { Air } from '../../../../../../components/air/Air';
import { FlexJustifyBetween } from '../../../../../../components/style/flex/flex-justify-between';
import { Flex } from '../../../../../../components/style/flex/flex';
import { useHistory } from 'react-router-dom';
import CostomModal from './component/modal';
import moment from 'moment';
import { meetingShowSetInfo, meetingShowSet, setLastTime, getLastTime } from '../hotel/constants';
import { getAreaCity } from '../guest/guest/constants';
import { getPlain, getTrain } from '../trip/constants';

export default (props: any) => {
  const meetid = props.match.params.meeting_id;
  const history = useHistory();
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [timeForm] = Form.useForm();
  const [tableEnable, { toggle }] = useBoolean(true);

  // 设置最晚提交时间
  const [timeVisible, setTimeVisible] = useState(false);
  // 修改接送机modal
  const [plainVisible, setPlainVisible] = useState(false);
  // 用户是否需要接送
  const [needSelected, setNeedSelected] = useState(0);
  // 表单属性根据needSelected变化
  const [formCommonProps, setFormCommonProps] = useState({} as any);
  // 修改接送机数据
  const [editPlain, setEditPlain] = useState({} as any);
  // 新增接送机的用户选择modal
  const [userVisible, setUserVisible] = useState(false);

  const [lastTime1, setLastTime1] = useState("");
  // area
  const [areaData, setAreaData] = useState([] as any[]);
  const [addressPickerData, setAddressPickerData] = useState([] as any[]);
  useMount(() => {
    meetingShowSetInfo({
      meetid,
      type: 4,
    }).then((res) => {
      toggle(res.is_show);
    });
    
    getAreaCity({})
      .then((result) => {
        setAreaData(result);
      })
  });

  useEffect(() => {
    let formFields: any = {};
    if (needSelected === 0) {
      formFields = {
        disabled: true,
        required: false
      };
    } else {
      formFields = {
        disabled: false,
        required: true
      };
    }
    setFormCommonProps(formFields);
  }, [needSelected]);

  const changeShowSet = () => {
    meetingShowSet({
      meetid,
      type: 4,
      is_show: !!tableEnable ? 2 : 1,
    } as any)
      .then((res) => {
        toggle(res.is_show);
      })
      .catch((e) => {
        message.error("设置失败 " + e.data?.message);
      });
  };

  const getLastTimeHandle = () => {
    getLastTime({ meetid, type: 2 })
      .then(response => {
        if (response.last_time !== 0) {
          setLastTime1(moment(response.last_time * 1000).format("YYYY-MM-DD"));
          const time = moment(response.last_time * 1000);
          timeForm.setFieldsValue({ last_time: time });
        } else {
          setLastTime1('');
          timeForm.setFieldsValue({ last_time: '' });
        }
      })
      .catch((e) => {
        message.error(e.data?.message);
      });
  }

  // 请求最后提交时间设置
  useEffect(() => {
    getLastTimeHandle();
  }, []);

  // 新增、修改接送机
  const onSetPlain = async () => {
    try {
      const values = await editForm.validateFields();
      // 是否是更改
      const isEdit = !!editPlain.id;
      // 请求url
      const fetchFunction = isEdit ? userGivePlainUpdate : userGivePlainUpdate;
      // 请求报文
      const payload: any = {
        is_need: values.is_need,
        remark: values.remark,
        name: values.name,
        mobile: values.mobile,
        type: values.type,
        choice_time: values.give_plain_time?.valueOf() / 1000,
        times: values.give_plain_times,
        city_code: values.city[1],
        province_code: values.city[0],
        area_code: 0,
        plain_id: values.plain_id,
        ...isEdit ? { id: editPlain.id,  } : {}
      }
      console.log('payload', payload);
      fetchFunction(payload)
        .then(response => {
          message.success('设置成功');
          setEditPlain({});
          editForm.resetFields();
          setPlainVisible(false);
          submit();
        })
        .catch((e) => {
          message.error(e.data?.message);
        });
    } catch (error) {
      message.warn(error?.errorFields[0]?.errors[0] || ' ');
    }
  }

  // 设置最晚提交时间
  const onSetTime = async () => {
    try {
      const values = await timeForm.validateFields();
      const payload = {
        meetid,
        type: 2,
        last_time: values.last_time ? moment(values.last_time).unix() as any : 0
      };
      setLastTime(payload)
        .then(() => {
          message.success('设置成功！');
        })
        .catch((error) => {
          message.warn(error?.data?.message);
        })
        .finally(() => {
          setTimeVisible(false);
          getLastTimeHandle();
        });
    } catch (error) {
      message.warn(error?.errorFields[0]?.errors[0] || ' ');
    }
  }

  const onExport = () => {
    exportGivePlainUsersListUrl({ meetid })
      .then((response) => {
        const href = response.url;
        window.open(href, "_blank");
        message.success("导出成功");
      })
      .catch((error) => {
        message.warn(error?.data?.message);
      });
  }

  const onDelete = (item: any) => {
    userGivePlainDel({ id: item.id })
      .then(() => {
        message.success(`删除成功`);
        submit();
      })
      .catch((error) => {
        message.warn(error?.data?.message);
      })
  }

  const onChangeCity = (value: string[]) => {
    const fetchUrl = editPlain.type === 1 ? getTrain : getPlain;
    fetchUrl({ city_code: value[1] }).then(
      (response: any) => {
        const pickerData =
          response.length > 0
            ? response.map((item: any) => {
              return {
                label: item.name,
                value: item.id,
              };
            })
            : [];
        console.log('pickerData', pickerData)
        editForm.setFieldsValue({
        plain_id: []
      })
        setAddressPickerData(pickerData)
      });
  }

  const userResult = useAntdTable(
    (paginatedParams: any, tableProps: any) => {
      return merchantUserIndex({
        meetid: meetid,
        page: paginatedParams.current,
        ...tableProps,
      }).then((response) => {
        return response;
      });
    },
    { form, formatResult: formatListResultUser, defaultPageSize: 15 }
  );

  const { tableProps, search } = useAntdTable(
    (paginatedParams: any, tableProps: any) => givePlainUsersList({
      meetid: meetid,
      // page: paginatedParams.current,
      // paginate: paginatedParams.pageSize
    }),
    { form, formatResult: formatListResult }
  );
  const { submit, reset } = search;

  const columns: any[] = [
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '类型',
      dataIndex: 'give_type',
    },
    {
      title: '接送点类型',
      dataIndex: 'type',
    },
    {
      title: '日期',
      dataIndex: 'give_plain_time',
    },
    {
      title: '接送地点',
      dataIndex: 'give_plain_location'
    },
    {
      title: '提交时间',
      dataIndex: 'create_time'
    },
    {
      title: '操作',
      render: (item: any) => {
        return (
          <div>
            <Button
              type={'link'}
              disabled={item.is_publish}
              style={{ paddingLeft: 0 }}
              onClick={() => {
                userGivePlainShow({ meetid, id: item.id })
                  .then((result) => {
                    
                    setEditPlain(result);

                    const fetchUrl = result.type === 1 ? getTrain : getPlain;
                    fetchUrl({ city_code: result.city_code }).then(
                      (response: any) => {
                        const pickerData =
                          response.length > 0
                            ? response.map((item: any) => {
                              return {
                                label: item.name,
                                value: item.id,
                              };
                            })
                            : [];
                        console.log('pickerData', pickerData)
                        setAddressPickerData(pickerData)
                      });

                    editForm.setFieldsValue({
                      name: result.name,
                      mobile: result.mobile,
                      create_time: result.create_time,
                      give_plain_time: moment(result.give_plain_time * 1000),
                      give_plain_times: result.give_plain_times,
                      is_need: result.is_need,
                      give_type: result.give_type,
                      type: result.type,
                      give_plain_location: result.give_plain_location,
                      remark: result.remark,
                      city: [result.province_code, result.city_code],
                      plain_id: result.plain_id,
                    });
                    setNeedSelected(item.is_need === '是' ? 1 : 0);
                    setPlainVisible(true);
                  })
                  .catch((error) => {
                    notification.warn({ message: error.message });
                  })
              }}
            >
              编辑
            </Button>
            {`  `}
            <Popconfirm
                title="删除后无法恢复，确认删除该信息吗?"
                onConfirm={() => {
                  onDelete(item);
                }}
                okText="确定"
                cancelText="取消"
            >

              <Button
                type={'link'}
                style={{ paddingLeft: 0 }}
              >
                删除
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ].map((item, index) => {
    return {
      ...item,
      key: item.dataIndex || `${index}`
    }
  });

  return (
    <div>
      <FlexJustifyBetween style={{ marginBottom: 12 }}>
        <Flex>
          <Switch checked={tableEnable} onChange={changeShowSet} />
          <Air horizontal={3} />
          <div>启用接送机</div>
        </Flex>
        <Flex>
          <Space>
            <Button
              disabled={!tableEnable}
              onClick={() => setTimeVisible(true)}
            >
              {lastTime1 || '最晚提交时间 '}<span style={{ textDecoration: 'underline', marginLeft: 6 }}>{` 设置`}</span>
            </Button>
            <Button type={'primary'} disabled={!tableEnable} onClick={onExport} >
              导出接送机
            </Button>
          </Space>
        </Flex>
      </FlexJustifyBetween>
      <Spin
        indicator={<div />}
        tip="尚未开启接送机"
        spinning={!tableEnable}
      >
        <Table rowKey="id" columns={columns} {...tableProps} pagination={false} />
      </Spin>

      <CostomModal
        visible={timeVisible}
        isTimeLayout={true}
        onCancel={() => {
          timeForm.resetFields();
          setTimeVisible(false);
        }}
        onOk={() => onSetTime()}
        title={'设置最后提交时间'}
        form={timeForm}
        formItems={[{
          render: () => {
            return (
              <Form.Item
                label={'最后提交&修改时间'}
                name='last_time'
              >
                <DatePicker style={{ width: 200 }} locale={locale} placeholder={"设置最晚提交时间"} />
              </Form.Item>
            )
          }
        }]}
      />

      <CostomModal
        visible={plainVisible}
        onCancel={() => {
          editForm.resetFields();
          setPlainVisible(false);
        }}
        onOk={() => onSetPlain()}
        title={'修改接送机'}
        form={editForm}
        formItems={[{
          render: () => {
            return (
              <Form.Item
                label='姓名'
                name='name'
                rules={[{ required: true, message: '请输入姓名' }]}
              >
                <Input disabled={true} />
              </Form.Item>
            )
          }
        }, {
          label: '手机号',
          name: 'mobile',
          disabled: true,
          rules: [{ required: true, message: '请输入手机号' }],
        }, {
          render: () => {
            return (
              <Form.Item
                label='是否需要接送'
                name='is_need'
                rules={[{ required: true, message: '请选择是否需要接送' }]}
              >
                <Select onChange={(e: number) => setNeedSelected(e)}>
                  <Select.Option value={0}>否</Select.Option>
                  <Select.Option value={1}>是</Select.Option>
                </Select>
              </Form.Item>
            )
          }
        }, {
          render: () => {
            return (
              <Form.Item
                label='接机/接车类型'
                name='type'
                rules={[{ required: true, message: '请选择类型' }]}
              >
                <Select
                  disabled={formCommonProps.disabled}
                  onChange={(value) => {
                    setEditPlain((prevPlain: any) => {
                      return {
                        ...prevPlain,
                        type: value
                      }
                    })
                    const citys = editForm.getFieldsValue(['city'])
                    const { city } = citys;
                    const fetchUrl = value === 1 ? getTrain : getPlain;
                    fetchUrl({ city_code: city[1] }).then(
                      (response: any) => {
                        const pickerData =
                          response.length > 0
                            ? response.map((item: any) => {
                              return {
                                label: item.name,
                                value: item.id,
                              };
                            })
                            : [];
                        editForm.setFieldsValue({
                          plain_id: []
                        })
                        setAddressPickerData(pickerData)
                      });
                  }}
                >
                  <Select.Option value={1}>火车</Select.Option>
                  <Select.Option value={2}>飞机</Select.Option>
                </Select>
              </Form.Item>
            )
          }
        }, 
        {
            render: () => {
              return (
                <Form.Item
                  label="城市"
                  name="city"
                  rules={[{ required: true, message: "请选择城市" }]}
                >
                  <Cascader onChange={(value) => onChangeCity(value)} options={areaData} />
                </Form.Item>
              )
            }
          },
          {
            render: () => {
              return (
                  <Form.Item
                      label="站点"
                      name="plain_id"
                      rules={[{ required: true, message: "请选择站点" }]}
                  >
                      <Select
                          options={addressPickerData}
                      />
                  </Form.Item>
              );
            },
          },
          {
          render: () => {
            return (
              <Form.Item
                label='日期'
                name='give_plain_time'
                rules={[{ required: true, message: '请选择日期' }]}
              >
                <DatePicker disabled={formCommonProps.disabled} style={{ width: '100%' }} locale={locale} placeholder={"设置日期"} />
              </Form.Item>
            )
          }
        }, {
          label: '车次/航班号',
          name: 'give_plain_times',
          disabled: formCommonProps.disabled,
          rules: [{ required: true, message: '请输入车次/航班号' }],
        }, {
          label: '备注信息',
            name: 'remark',
          disabled: formCommonProps.disabled,
        }]}
      >
        <Modal
          centered
          okText="确定"
          cancelText="取消"
          width={800}
          visible={userVisible}
          onCancel={() => setUserVisible(false)}
        >
          <Table
            rowKey="id"
            columns={[
              { title: '姓名', dataIndex: 'username' },
              { title: '手机号', dataIndex: 'mobile' },
              { title: '身份证号', dataIndex: 'userId' }
            ]}
            {...userResult.tableProps}
          />
        </Modal>
      </CostomModal>
    </div>
  );
};
