/**
 * 课件详情
 * Create by liaokai on 2020-05-30 19:10:44
 */
import {toPost} from "../../http-utils";

export const coursewareShow = toPost<
    {
        live_courseware_id : string
    },
    {
        "id": number,
        "title": string,
        "file": string,
        "live_id": number,
        credit : number
    }
>("/merchant/live/coursewareShow");
