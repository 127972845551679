/**
 * 邮件总览
 * Create by liaokai on 2020-07-03 13:30:20
 */
import * as React from 'react';
import {Component} from "react";
import {Texts} from "../../../../../components/text/Texts";
import {Air} from "../../../../../components/air/Air";
import {Button, Card, Statistic} from "antd";
import {Link} from "react-router-dom";
import {EmailTemplateTable} from "../../../meeting-list/meeting/menu/email-multi-send/index/template-table/template-table";
import {apis} from "../../../../../common/apis/apis";
import {RouteComponentProps} from "react-router";

interface IProps extends RouteComponentProps{

}

interface IState{
    sendCount : number,
    residue : number
}

export class EmailOverviewIndex extends Component<IProps,IState>{
    readonly state : IState = {
        sendCount : 0,
        residue : 0
    };

    componentDidMount() {
        apis.emailCount({}).then(res => {
            this.setState({sendCount : res.sendCount, residue : res.residue})
        })
    }

    render() {
        let {history, location: {pathname}} = this.props;
        let {sendCount, residue} = this.state;
        return (
            <div>
                <div>
                    <Texts.ItemTitle>
                        消息统计
                    </Texts.ItemTitle>
                    <Air vertical={5}/>
                    <div style={{display : "flex", justifyContent: "space-between", alignItems: "center"}} >
                        <div style={{display: "flex"}}>
                            <Card bordered={false}>
                                <Statistic title={<div>
                                    <span>邮件包剩余 </span>
                                </div>} value={residue} suffix={"条"} />
                                {/*<Link to={""}>查看记录</Link>*/}
                            </Card>
                            <Air horizontal={10}/>
                            <Card bordered={false}>
                                <Link to={"record"}>
                                    <Statistic title="已发送" value={sendCount} suffix={"条"} />
                                </Link>

                                {/*<Link to={""}>查看记录</Link>*/}
                            </Card>
                        </div>
                        <div>
                            <Button type={"primary"} shape={"round"} size={"large"}>
                                <Link to={`${pathname}/buy_email`}>购买邮件包</Link>
                            </Button>
                        </div>
                    </div>
                </div>
                <Air vertical={10}/>
                <div>
                    <div style={{display: "flex", justifyContent : "space-between", alignItems: "center"}}>
                        <Texts.ItemTitle>
                            邮件模板
                        </Texts.ItemTitle>
                        <Button type={"primary"} shape={"round"} onClick={()=>{
                            history.push(`${pathname}/add_email_template`)
                        }}>添加模版</Button>
                    </div>
                    <Air vertical={5}/>
                    <div>
                        <EmailTemplateTable {...this.props}/>
                    </div>
                </div>
            </div>
        );
    }
}
