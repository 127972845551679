/**
 * 下载邮件导入模板
 * Create by liaokai on 2020-04-23 18:13:11
 */
import {toPost} from "../../http-utils";
import {ITagItem} from "../../../../routes/index/meeting-list/meeting/menu/sms-multi-send/sms-template/editor/sms-template-editor";

export const excelExportUrl = toPost<
    {
        // title : string,
        // text : string,
        id? : any
    },
    {
        url:string
    }
    >("/merchant/Email/excelExportUrl");

