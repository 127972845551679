/**
 * 关闭订单
 * Create by liaokai on 2020-05-14 17:13:22
 */
import {toPost} from "../http-utils";

export const closeOrder = toPost<
    {
        order_num : string
    },
    {

    }
>("/merchant/Lcm/closeOrder");
