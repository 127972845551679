// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BannerTop_container__2VhMy{background-color:#FFFFFF;border-bottom:#0089FF solid 1px}.BannerTop_content__yQXRy{display:flex;flex-direction:row;justify-content:space-between;align-items:center}.BannerTop_content__yQXRy .BannerTop_left__2ro9L{display:flex;align-items:center;cursor:pointer}.BannerTop_content__yQXRy .BannerTop_left__2ro9L .BannerTop_text__2BF9g{font-size:22px;font-weight:bold;color:#0089FF;margin:8px 15px}.BannerTop_content__yQXRy .BannerTop_right__68srR{display:flex;flex-direction:row;align-items:center}.BannerTop_content__yQXRy .BannerTop_right__68srR .BannerTop_message__2-88_{margin-right:25px;cursor:pointer}.BannerTop_content__yQXRy .BannerTop_right__68srR .BannerTop_message__2-88_ .BannerTop_img__3DgWu{max-width:22px}.BannerTop_content__yQXRy .BannerTop_right__68srR .BannerTop_info__2kjfk .BannerTop_userName__JxjAM{display:flex;flex-direction:row;align-items:center;font-size:18px}.BannerTop_dropItem__oyLIW{display:flex;flex-direction:row;align-items:center}.BannerTop_dropItem__oyLIW .BannerTop_imgBox__kAXAG{display:flex;flex-direction:row;align-items:center}.BannerTop_dropItem__oyLIW .BannerTop_imgBox__kAXAG .BannerTop_itemImg__w9lhS{max-width:18px}.BannerTop_dropItem__oyLIW .BannerTop_imgBox__kAXAG .BannerTop_icon__7t6Fm{color:#2194ff;font-size:20px}.BannerTop_dropItem__oyLIW .BannerTop_itemText__10rbP{margin-left:10px}.BannerTop_browserTips__22EL_{display:flex;align-items:center;padding:20px 0px;background-color:#f4fbff;color:#3b87f7}.BannerTop_browserTips__22EL_ .BannerTop_left__2ro9L{flex:1 1}.BannerTop_browserTips__22EL_ .BannerTop_center__vQrdd{flex:8 1;display:flex;justify-content:center}.BannerTop_browserTips__22EL_ .BannerTop_right__68srR{flex:1 1;color:#3b87f7;font-size:25px;margin-right:30px;cursor:pointer;position:absolute;right:0px;padding:20px 0px}\n", ""]);
// Exports
exports.locals = {
	"container": "BannerTop_container__2VhMy",
	"content": "BannerTop_content__yQXRy",
	"left": "BannerTop_left__2ro9L",
	"text": "BannerTop_text__2BF9g",
	"right": "BannerTop_right__68srR",
	"message": "BannerTop_message__2-88_",
	"img": "BannerTop_img__3DgWu",
	"info": "BannerTop_info__2kjfk",
	"userName": "BannerTop_userName__JxjAM",
	"dropItem": "BannerTop_dropItem__oyLIW",
	"imgBox": "BannerTop_imgBox__kAXAG",
	"itemImg": "BannerTop_itemImg__w9lhS",
	"icon": "BannerTop_icon__7t6Fm",
	"itemText": "BannerTop_itemText__10rbP",
	"browserTips": "BannerTop_browserTips__22EL_",
	"center": "BannerTop_center__vQrdd"
};
module.exports = exports;
