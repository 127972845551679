/**
 * 获取收支记录列表
 * Create by liaokai on 2020-05-09 15:43:17
 */
import {toPost} from "../http-utils";

export interface IPrepaidTableItem {
    "id": number,
    "price": number, //金额 单位元
    "order_num": string, //订单交易编号
    "create_time": string, //交易时间
    "optype": number, // 1 + 2 -
    "type": number, //1微信2支付宝3余额4后台操作5收益转入6运营转出
    "status": number //1成功2失败
}

export const getPrepaidTable = toPost<
    {
        page : number,
        paginate? : number
    },
    {
        "current_page": 1, //当前页
        "last_page": 1, //最后页
        "total": 3, //总条数
        list : IPrepaidTableItem[]
    }
>("/merchant/Lcm/smsBalanceLog");
