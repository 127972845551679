/**
 * 文档 - 路由
 * Create by liaokai on 2020-05-23 17:33:38
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {SurfaceRouter, tSurfaceRouteItem} from "../../../../../../components/surface-router/surface-router";
import {LiveDocView} from "./doc";
import {LiveDocPreview} from "./preview/preview";

interface IProps extends RouteComponentProps{

}

interface IState{

}

const routerData : tSurfaceRouteItem = {
    breadTitle : "文档",
    routeName : "",
    component : LiveDocView,
    children : [
        {
            breadTitle : "预览",
            routeName : "preview/:doc_id",
            component : LiveDocPreview,
            children : []
        }
    ]
}

export class LiveDocRouter extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={routerData}/>
        );
    }
}
