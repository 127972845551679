/**
 * 邮件模板列表
 * Create by liaokai on 2020-04-23 17:52:00
 */
import {toPost} from "../../http-utils";

interface IEmailAddressBookListItem {
    "id": number,
    "name": string,
    "count": number
}

export const emailAddressBookSelect = toPost<
    {
    },
    {
        "id": number,
        "name": string,
        "count": number,
        // data : IEmailAddressBookListItem[]
    }[]
    >("/merchant/Email/emailAddressBookSelect");
