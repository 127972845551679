import React, {Component} from "react";
import {message} from "antd";
import {api} from "../../../../../../common/app";
import DarkBox from "../../../../../../components/DarkBox";
import style from "./convert-cash.scss";

export class ConvertCash extends Component{
    constructor(props){
        super(props);
        this.state={
            formdata:{
                company:'',
                account_name:'',
                bank:'',
                account_number:'',
                contacts:'',
                phone:'',
                amount:''
            }
        };
        this.HandleInputValue = this.HandleInputValue.bind(this);
        this.submitApply = this.submitApply.bind(this);
    }
    HandleInputValue(type,e){
        this.state.formdata[type] = e.target.value;
        this.setState(this.state);
    }
    submitApply(){
        for (const key in this.state.formdata) {
            if (this.state.formdata.hasOwnProperty(key)) {
                const element = this.state.formdata[key];
                if (!element) {
                    message.error('请保证所有信息填写完整！');
                    return ;
                }
            }
        }
        console.log(this.state.formdata);

        api.ApplyGetCharge(
            this.state.formdata.company,
            this.state.formdata.account_name,
            this.state.formdata.bank,
            this.state.formdata.account_number,
            this.state.formdata.contacts,
            this.state.formdata.phone,
            this.state.formdata.amount,
        ).then(res=>{
            if (res.code === 200) {
                this.props.onClose();
                message.success('提交申请成功！');
            }else{
                message.error(res.message)
            }
        },err=>{
            console.log(err);

        })
    }
    render(){
        return <DarkBox >

            <div className={[style.ChargeFormBox,'childcenter'].join(' ')}>
                <div className={style.CloseButton} onClick={this.props.onClose}></div>
                <div className={[style.FormBody,'childcenter childcolumn'].join(' ')}>
                    <div className={[style.FormTitle,'childcenter childalignstart childcolumn'].join(' ')}>
                        <span>提现表单</span>
                        <span>请认真填写以下表单内容</span>
                    </div>
                    <div className={[style.FormInputGroup,'childcenter childcolumn '].join(' ')}>
                        <div className={[style.InputRow,'childcenter'].join(' ')}>
                            <div className={style.InputName}>公司名称*</div>
                            <div className={style.InputBox}>
                                <input value={this.state.formdata.company} type="text" onChange={this.HandleInputValue.bind(this,'company')} />
                            </div>
                        </div>
                        <div className={[style.InputRow,'childcenter'].join(' ')}>
                            <div className={style.InputName}>提现账户名*</div>
                            <div className={style.InputBox}>
                                <input value={this.state.formdata.account_name} type="text" onChange={this.HandleInputValue.bind(this,'account_name')} />
                            </div>
                        </div>
                        <div className={[style.InputRow,'childcenter'].join(' ')}>
                            <div className={style.InputName}>提现银行*</div>
                            <div className={style.InputBox}>
                                <input value={this.state.formdata.bank} type="text" onChange={this.HandleInputValue.bind(this,'bank')} />
                            </div>
                        </div>
                        <div className={[style.InputRow,'childcenter'].join(' ')}>
                            <div className={style.InputName}>提现账号*</div>
                            <div className={style.InputBox}>
                                <input value={this.state.formdata.account_number} type="text" onChange={this.HandleInputValue.bind(this,'account_number')} />
                            </div>
                        </div>
                        <div className={[style.InputRow,'childcenter'].join(' ')}>
                            <div className={style.InputName}>联系人*</div>
                            <div className={style.InputBox}>
                                <input value={this.state.formdata.contacts} type="text" onChange={this.HandleInputValue.bind(this,'contacts')} />
                            </div>
                        </div>
                        <div className={[style.InputRow,'childcenter'].join(' ')}>
                            <div className={style.InputName}>联系电话*</div>
                            <div className={style.InputBox}>
                                <input value={this.state.formdata.phone} type="text" onChange={this.HandleInputValue.bind(this,'phone')} />
                            </div>
                        </div>
                        <div className={[style.InputRow,'childcenter'].join(' ')}>
                            <div className={style.InputName}>提现金额*</div>
                            <div className={style.InputBox}>
                                <input value={this.state.formdata.amount} type="text" onChange={this.HandleInputValue.bind(this,'amount')} />
                            </div>
                        </div>
                    </div>
                    <div className={style.HandleBox}>
                        <div className={style.TipsBox}>请认真核对以上内容后再进行提交，我们的工作人员会在收到申请以后的3个工作日内与您联系</div>
                        <div className={[style.ButtonGroup,'childcenter'].join(' ')}>
                            <div className={[style.ConfirmButton,'childcenter'].join(' ')} onClick={this.CreateNewMeeting} onClick={this.submitApply}>
                                提交
                            </div>
                            <div className={[style.CancelButton,'childcenter'].join(' ')} onClick={this.props.onClose}>
                                取消
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </DarkBox>
    }
}
