// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".buy-sms_container__2_DXD .buy-sms_smsPackageTypes__QzAXn{display:grid;grid-gap:10px;grid-template-columns:repeat(auto-fill, 255px)}.buy-sms_container__2_DXD .buy-sms_submitBtn__3VyfG{background:#ff784b;border:#ff784b}.buy-sms_container__2_DXD .buy-sms_submitBtn__3VyfG:hover{background:#ff6e4b;border:#ff6e4b}\n", ""]);
// Exports
exports.locals = {
	"container": "buy-sms_container__2_DXD",
	"smsPackageTypes": "buy-sms_smsPackageTypes__QzAXn",
	"submitBtn": "buy-sms_submitBtn__3VyfG"
};
module.exports = exports;
