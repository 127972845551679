/**
 * 转入收益账户
 * Create by liaokai on 2020-05-09 14:30:29
 */
import {toPost} from "../http-utils";

export const transferIncomeAcc = toPost<
    {
        price : number
    },
    {

    }
>("/merchant/Lcm/rollOutOperation");
