/**
 * 直播回放更新标题
 * Create by liaokai on 2020-06-19 16:23:42
 */
import {toPost} from "../../http-utils";

export const liveRecordUpdate = toPost<
    {
        id : string,
        title : string
    },
    {

    }
>("/merchant/live/liveRecordUpdate");

