/**
 * 互动问答-用户回答详情
 * Create by liaokai on 2020-05-25 19:08:20
 */
import * as React from 'react';
import {Component} from "react";
import {FlexJustifyEnd} from "../../../../../../../components/style/flex/flex-justify-end";
import {Flex} from "../../../../../../../components/style/flex/flex";
import {Button, Input, message, Table} from "antd";
import {Air} from "../../../../../../../components/air/Air";
import {RouteComponentProps} from "react-router";
import {
    IQuestionPublishShowItem,
    questionPublishShow
} from "../../../../../../../common/apis/apis/live/questionPublishShow";
import {common} from "../../../../../../../common/common";
import {exportMessageExportIndexUrl} from "../../../../../../../common/apis/apis/live/exportMessageExportIndexUrl";
import {liveStore} from "../../live.store";
import {exportQuestionPublishShowUrl} from "../../../../../../../common/apis/apis/live/exportQuestionPublishShowUrl";

const {Search} = Input

interface IProps extends RouteComponentProps<{qa_id : string}>{

}

interface IState{
    columns : any[]
    dataSource : IQuestionPublishShowItem[],
    currentPage : number,
    total : number,
    pageSize: number,
    loading : boolean,
}
export class LiveQaAnswerList extends Component<IProps,IState>{
    readonly state : IState = {
        columns : [
            {
                title: '用户',
                dataIndex: 'username',
                key: 'username',
                render: (text : boolean) => text,
            },
            {
                title: '回答时间',
                dataIndex: 'answer_time',
                key: 'answer_time',
                render: (text: string) => text,
            },
            {
                title: '答案',
                dataIndex: 'answer',
                key: 'answer',
                render: (text: string) => text,
            },
            // {
            //     title: '获得积分',
            //     dataIndex: 'credit',
            //     key: 'credit',
            //     render: (text: string) => text,
            // }
        ],
        dataSource : [],
        pageSize : common.defaultPaginate,
        total : 0,
        currentPage : 1,
        loading : false
    };

    reqTableData(page : number){
        this.setState({loading : true})
        questionPublishShow({
            id : this.props?.match?.params?.qa_id,
            page : page,
            paginate : this.state.pageSize
        }).then(res => {
            this.setState({
                currentPage : page,
                total : res.total,
                dataSource : res.data
            })
        }).finally(()=>{
            this.setState({loading : false})
        })
    }

    componentDidMount(): void {
        this.reqTableData(1)
    }

    render() {
        let {} = this.props;
        let {columns, dataSource, pageSize, total, currentPage, loading} = this.state;
        return (
            <div>
                <FlexJustifyEnd>
                    <Flex>
                        {/*<Button>导出</Button>*/}
                        <Button  type={"primary"}    onClick={()=>{
                            exportQuestionPublishShowUrl({
                                id: this.props.match.params.qa_id,
                            }).then((response) => {
                                const href = response.url;
                                window.open(href, "_blank");
                                message.success("导出成功");
                            });
                        }} >导出</Button>
                        {/*<Air horizontal={5}/>*/}
                        {/*<Search placeholder="搜索内容" onSearch={value => {}} enterButton />*/}
                    </Flex>
                </FlexJustifyEnd>
                <Air vertical={5}/>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={dataSource}
                    emptyText={"暂无内容"}
                    pagination={{
                        defaultCurrent : 1,
                        current : currentPage,
                        pageSize : pageSize,
                        total : total,
                        onChange : page => {
                            this.reqTableData(page);
                            this.setState({currentPage : page})
                        }
                    }}
                />
            </div>

        );
    }
}
