/**
 * 获取会议基本信息
 * Create by liaokai on 2020-04-03 11:26:53
 */
import {toPost} from "../http-utils";

export const getMeeting = toPost<
    {
        id : number
    },
    {
        id:         number,
        title:      string,
        start_time: string,  // "2020/03/31 00:00:00"
        end_time:   string,
        province:   string,
        city:       string,
        area:       string,
        address:    string,
        longitude:  string,
        latitude:   string,
        cover:      string
    }
>("/merchant/meeting/getMeeting");
