/**
 * 编辑/新建 专题
 * Create by liaokai on 2020-06-13 23:00:10
 */
import * as React from 'react';
import {Component} from "react";
import {TitleContentTable} from "../../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import {Button, Input, message} from "antd";
import {Flex} from "../../../../../../../components/style/flex/flex";
import {Flex1} from "../../../../../../../components/style/flex/flex-1";
import {Mask} from "../../edit/component/mask";
import {UploadImgTipStateless} from "../../edit/component/upload-img-tip-stateless";
import {FlexJustifyCenter} from "../../../../../../../components/style/flex/flex-justify-center";
import ImageCropper from "../../../../../meeting-list/new-meeting/components/ImageCropper/ImageCropper";
import {uploadFile} from "../../../../../../../common/apis/apis/upload-file";
import {RouteComponentProps} from "react-router";
import {ISubjectShow, ISubjectShowItem, subjectShow} from "../../../../../../../common/apis/apis/live/subjectShow";
import {ProkviewLiveList} from "../preview/prokview-live-list/prokview-live-list";
import {Air} from "../../../../../../../components/air/Air";
import {common} from "../../../../../../../common/common";
import styled from "styled-components";
import {FlexJustifyAlignCenter} from "../../../../../../../components/style/flex/flex-justify-align-center";
import {subjectStore} from "../../../../../../../common/apis/apis/live/subjectStore";
import {PlusOutlined} from "@ant-design/icons/lib";
import {subjectUpdate} from "../../../../../../../common/apis/apis/live/subjectUpdate";
import {LiveSelectProkect} from "./component/select-prokect";

const {TextArea} = Input

interface IProps extends RouteComponentProps<{prokect_id : string}>{

}

interface IState{
    data : ISubjectShow,
    visible : boolean
}

export class LiveProkectEdit extends Component<IProps,IState>{
    readonly state : IState = {
        data : {
            "id": 0, //专题id
            "title": "", //专题标题
            "poster": "", //专题海报
            "synopsis": "", //专题简介
            "create_time": "", //专题创建时间
            "live_count": 0, //直播数量
            "look_num": 0, //专题下的所有直播观看人数
            "subject_dir": [],
            url : ""
        },
        visible : false
    };

    onCropedImage = async (result : any): Promise<boolean> =>{
        let formdata = new FormData();
        formdata.append('file',result);
        formdata.append('type','base64');
        try {
            let res = await uploadFile(formdata)
            const {data} = this.state
            data.poster = res;
            this.setState({data : data })
            return !!res;
        }catch(err){
            message.error("服务器错误！");
            return false
        }
    }

    componentDidMount() {
        const {match : {params : {prokect_id}}} = this.props
        if(prokect_id){
            subjectShow({
                id : prokect_id
            }).then((res)=>{
                this.setState({data : res})
            })
        }
    }

    translation_subject_dir = (subject_dirs : ISubjectShowItem[])=>{
        return subject_dirs.map((value,index) => {
            return {
                ...value,
                sort : index
            }
        })
    }

    render() {
        let {history, match:{params:{prokect_id}}} = this.props;
        let {data : {poster, subject_dir, title, synopsis},data, visible} = this.state;
        return (
            <div>
                <Flex>
                    <Flex1>
                        <TitleContentTable colGap={10} rowGap={10}>
                            <div>*专题标题</div>
                            <Input placeholder={"请输入专题标题"} value={title} onChange={(event)=>{
                                data.title = event.target.value
                                this.setState({data: data})
                            }}/>
                            <div style={{alignSelf: "flex-start"}}>*专题简介</div>
                            <TextArea placeholder={"请输入专题简介"} rows={5} value={synopsis} onChange={event => {
                                data.synopsis = event.target.value
                                this.setState({data: data})
                            }}/>
                        </TitleContentTable>
                    </Flex1>
                    <ImgUploaderBox style={{width: 280,marginLeft:10,border: `1px ${common.color.disable} solid`}}>
                        {
                            <ImageCropper
                                text={
                                    <Mask
                                        floatView={
                                            poster ? <UploadImgTipStateless theme={"dark"}/> : undefined
                                        }
                                    >
                                        {
                                            poster ?
                                                <FlexJustifyCenter style={{width: "100%", height: 200, padding: 10}}>
                                                    <img
                                                        style={{maxWidth: "100%", maxHeight: "100%"}}
                                                        src={poster}
                                                        alt=""
                                                    />
                                                </FlexJustifyCenter>
                                                :
                                                <UploadImgTipStateless/>
                                        }
                                    </Mask>
                                }
                                onUpdate={this.onCropedImage}
                            />
                        }
                    </ImgUploaderBox>
                </Flex>
                <Air vertical={5}/>
                <TitleContentTable colGap={10} rowGap={10}>
                    <div style={{alignSelf: "flex-start"}}>*专题目录</div>
                    <div>
                        <Button icon={<PlusOutlined />} onClick={()=>{
                            this.setState({visible : true})
                        }}>添加</Button>
                        <Air vertical={5}/>
                        <ProkviewLiveList dataSource={subject_dir}/>
                    </div>
                    <div></div>
                    <div>
                        <Button type={"primary"} onClick={()=>{
                            if(prokect_id){
                                subjectUpdate({
                                    id : prokect_id,
                                    title : title,
                                    synopsis : synopsis,
                                    poster : poster,
                                    subject_dir : JSON.stringify(
                                        this.translation_subject_dir(subject_dir)
                                    ) as any
                                }).then(res => {
                                    message.success("保存成功")
                                }).catch(e =>{
                                    message.error("保存失败 " + e.data?.message)
                                })
                            }else{
                                subjectStore({
                                    title : title,
                                    synopsis : synopsis,
                                    poster : poster,
                                    subject_dir : JSON.stringify(
                                        this.translation_subject_dir(subject_dir)
                                    ) as any
                                }).then(res =>{
                                    message.success("新建成功")
                                    history.goBack();
                                }).catch(e => {
                                    message.error("新建失败 " + e.data?.message)
                                })
                            }
                        }}>保存</Button>
                    </div>
                </TitleContentTable>
                <LiveSelectProkect
                    visible={visible}
                    onCancel={()=>{
                        this.setState({visible : false})
                    }}
                    onSelect={item => {
                        if(subject_dir.filter(value => value.live_id === item.live_id).length){
                            message.error(`"${item.title}" 已存在`)
                        }else{
                            data.subject_dir = subject_dir.concat(item)
                            this.setState({data : data})
                        }
                    }}
                />
            </div>
        );
    }
}

const ImgUploaderBox = styled(FlexJustifyAlignCenter)`
  width:  250px;
  margin-left: 10px;
  border: 1px ${common.color.disable} solid ;
`
