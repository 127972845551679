/**
 * 发布回放视频
 * Create by liaokai on 2020-06-11 14:46:24
 */
import {toPost} from "../../http-utils";

export const publishRecord = toPost<
    {
        id : string
    },
    {

    }
>("/merchant/live/publishRecord");
