/**
 * 添加直播
 * Create by liaokai on 2020-05-26 18:53:36
 */
import {toPost} from "../../http-utils";

export const liveStore = toPost<
    {
        title: string,
        live_time : string,
        synopsis : string,
        heat_value : number,
        poster : string,
        doc? : string
        show_count? : number//观看次数开关
        show_online? : number//在线人数开关
        is_heat? : number//在线人数开关
        give_like? : number//在线人数开关

    },
    {

    }
>("/merchant/live/liveStore");
