/**
 * iphone边框
 * Create by liaokai on 2020-03-13 19:13:51
 */
import * as React from 'react';
import {Component} from "react";
import iphoneBorderImg from "../../assets/iphone-border.png"
import style from "./iphone-simulator.scss"

interface IProps{
    width?: number,
    height?: number,
    borderImg? : string
}

interface IState{

}

export class IphoneSimulator extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {

    };
    constructor(props: IProps){
        super(props);
    }
    render() {
        let {children,width,height} = this.props;
        let {} = this.state;
        return (
            <div className={style.container} style={{maxWidth: width,minWidth:width, maxHeight : height,minHeight: height}}>
                <img src={iphoneBorderImg} alt="" className={style.iphoneBorderBox}/>
                <div
                    className={style.content}
                    style={{
                        backgroundColor :  "rgba(0,255,0,0)",
                        margin : `${(width ?? 0) * 0.045}% ${(width ?? 0) * 0.025}% ${(width ?? 0) * 0.04}% ${(width ?? 0) * 0.025}%`
                    }}
                >
                    {children}
                </div>

            </div>
        );
    }
}
