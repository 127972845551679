/**
 * 添加问答
 * Create by liaokai on 2020-05-28 13:21:48
 */
import {toPost} from "../../http-utils";

export interface IQuestionStoreOpt {
    title : string
    value : string
    isAnswer : boolean
}

export type tQuestionType = "single" | "multi" | "qa"

export interface IQuestionStoreQuestion {
    title : string
    type : tQuestionType
    content : IQuestionStoreOpt[]
}

export const questionStore = toPost<
    IQuestionStoreQuestion & {
        live_id : string
    },
    any
>("/merchant/live/questionStore");

