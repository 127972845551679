/**
 * 短信发送确认清单
 * Create by liaokai on 2020-04-24 15:32:41
 */
import {toPost} from "../http-utils";
import {ITagItem} from "../../../routes/index/meeting-list/meeting/menu/sms-multi-send/sms-template/editor/sms-template-editor";

export interface ISendAffirm {
    "template_title": string,
    "template_content": ITagItem[],
    "send_obj": string,
    "send_sum": number, //发送总数
    "short_sum": number, //简短信
    "long_sum": number, //长短信
    "sms_balance": number, //余额 单位元
    "type": string,
    "residue": number, //短信包剩余数量
    "deductNum": number, //消耗短信包数量
    "deductPrice": number, //消耗多少余额
    "able_send": boolean //是否可以发送 true 为是 false 为否
}

export const sendAffirm = toPost<
    {
        id : number,
        meetid : number,
        template_id : number,
        type : number,
        send_time : string
    },
    ISendAffirm
>("/merchant/Lcm/sendAffirm");
