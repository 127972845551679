import React, { useEffect, useState } from 'react';
import { Form, Table, Switch, Button, message, Spin, Input, Select, Space, DatePicker , InputNumber} from 'antd';
import { useAntdTable, useBoolean, useMount } from 'ahooks';
import { InfoCircleOutlined } from '@ant-design/icons'
import {
  hotelList,
  hotelPublish,
  hotelRoomList,
  hotelStore,
  hotelUpdate,
  formatListResult,
  hotelRoomListSelectOptions,
  hotelRoomBatchStore,
  meetingShowSetInfo,
  meetingShowSet,
  setLastTime,
  getLastTime
} from './constants';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { Air } from '../../../../../../components/air/Air';
import { FlexJustifyBetween } from '../../../../../../components/style/flex/flex-justify-between';
import { Flex } from '../../../../../../components/style/flex/flex';
import CostomModal from './component/modal';
import CostomModalTime from '../plain/component/modal'
import moment from 'moment';

export default (props: any) => {
  const meetid = props.match.params.meeting_id;
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [roomForm] = Form.useForm();
  const [timeForm] = Form.useForm();
  const [tableEnable, { toggle }] = useBoolean(true);

  const [hotelVisible, setHotelVisible] = useState(false);
  const [hotelDetail, setHotelDetail] = useState({} as any);

  const [roomVisible, setRoomVisible] = useState(false);
  const [roomHotelDetail, setRoomHotelDetail] = useState({} as any);
  const [roomList, setRoomList] = useState([] as any[]);
  const [lastTime1, setLastTime1] = useState('')
  console.log("lastTime1", lastTime1);

  // 设置最晚提交时间
  const [timeVisible, setTimeVisible] = useState(false);

  useMount(() => {
    meetingShowSetInfo({
      meetid,
      type: 3,
    }).then((res) => {
      toggle(res.is_show);
    });
  });

  const changeShowSet = () => {
    meetingShowSet({
      meetid,
      type: 3,
      is_show: !!tableEnable ? 2 : 1,
    } as any)
      .then((res) => {
        toggle(res.is_show);
      })
      .catch((e) => {
        message.error("设置失败 " + e.data?.message);
      });
  };

  const getLastTimeHandle = () => {
    getLastTime({ meetid, type: 1 })
      .then(response => {
        if (response.last_time !== 0) {
          setLastTime1(moment(response.last_time * 1000).format('YYYY-MM-DD'));
          const time = moment(response.last_time * 1000);
          timeForm.setFieldsValue({ last_time: time });
        } else {
          setLastTime1('');
          timeForm.setFieldsValue({ last_time: '' });
        }
      })
      .catch((e) => {
        message.error(e.data?.message);
      });
  }

  // 请求最后提交时间设置
  useEffect(() => {
    getLastTimeHandle();
  }, []);

  useEffect(() => {
    // 请求该酒店的房型列表
    if (roomHotelDetail && roomHotelDetail.id) {
      hotelRoomList({ hotel_id: roomHotelDetail.id }).then((response) => {
        /**
         * 如果该酒店已经设置过房型列表则赋值，否则初始化
         */
        if (response && response.length > 0) {
          roomForm.setFieldsValue({ rooms: response });
        } else {
          roomForm.setFieldsValue({
            rooms: [{
              fieldKey: 0,
              key: 0,
              name: 0,
              room_type: 1,
            }, {
              fieldKey: 1,
              key: 1,
              name: 1,
              room_type: 2,
            }, {
              fieldKey: 2,
              key: 2,
              name: 2,
              room_type: 3,
            }],
          });
        }
        setRoomList(response);
      });
    }
  }, [roomHotelDetail, roomVisible]);

  const onSetHotel = async () => {
    try {
      const values: any = await editForm.validateFields();
      const isEdit = !!hotelDetail.id;
      const fetchUrl = isEdit ? hotelUpdate : hotelStore;
      const payload = {
        ...values,
        ...isEdit ? { hotel_id: hotelDetail.id } : { meetid: meetid },
      };
      fetchUrl(payload).then(() => {
        message.success(`${isEdit ? '编辑' : '新增'}成功`);
        setHotelVisible(false);
        setHotelDetail({});
        editForm.resetFields();
        submit();
      }).catch((error) => {
        message.warn(error?.data?.message);
      });
    } catch (error) {
      message.warn(error?.errorFields[0]?.errors[0] || ' ');
    }
  };

  // 设置最晚提交时间
  const onSetTime = async () => {
    try {
      const values = await timeForm.validateFields();
      const payload = {
        meetid,
        type: 1,
        last_time: values.last_time ? moment(values.last_time).unix() as any : 0
      };
      setLastTime(payload)
        .then(() => {
          message.success('设置成功！');
        })
        .catch((error) => {
          message.warn(error?.data?.message);
        })
        .finally(() => {
          setTimeVisible(false);
          getLastTimeHandle();
        });
    } catch (error) {
      message.warn(error?.errorFields[0]?.errors[0] || ' ');
    }
  }

  const onSetRoom = async () => {
    try {
      const values: any = await roomForm.validateFields();
      const payload = {
        hotel_id: roomHotelDetail.id,
        hotel_room: values.rooms.map((room: any) => {
          return {
            room_num: Number(room.room_num),
            room_remark: room.room_remark,
            room_type: room.room_type,
            start_time: 0,
            end_time: 0,
          };
        }),
      };
      hotelRoomBatchStore(payload)
        .then(() => {
          message.success('成功设置房型');
          submit();
        })
        .catch((error) => {
          message.warn(error?.data?.message);
        })
        .finally(() => {
          setRoomHotelDetail({});
          setRoomVisible(false);
        });
    } catch (error) {
      message.warn(error?.errorFields[0]?.errors[0] || ' ');
    }
  };

  const onPublishHotel = (item: any) => {
    hotelPublish({ hotel_id: item.id, publish_status: item.is_publish === true ? 1 : 2 })
      .then(() => {
        message.success(`${item.is_publish ? '取消' : ''}发布成功`);
        submit();
      })
      .catch((error) => {
        message.warn(error?.data?.message);
      })
  }

  const { tableProps, search } = useAntdTable(
    () => {
      return hotelList({ meetid: meetid }).then((response) => {
        return response;
      });
    },
    { form, formatResult: formatListResult }
  );
  const { submit, reset } = search;

  const columns: any[] = [
    {
      title: '酒店信息',
      dataIndex: 'title',
    },
    {
      title: '酒店位置',
      dataIndex: 'location',
    },
    {
      title: '房型',
      dataIndex: 'room',
      render: (room: any[]) => {
        const roomRenderArray = room.map((r) => `${r.title}：${r.room_num}间`);
        return <span>{roomRenderArray.join('，')}</span>;
      },
    },
    {
      title: '备注信息',
      dataIndex: 'notice',
    },
    {
      title: '操作',
      render: (item: any) => {
        return (
          <div>
            <Button
              type={'link'}
              disabled={item.is_publish}
              style={{ paddingLeft: 0 }}
              onClick={() => {
                setRoomHotelDetail(item);
                setRoomVisible(true);
              }}
            >
              设置房型
            </Button>
            <Button
              type={'link'}
              disabled={item.is_publish}
              style={{ paddingLeft: 0 }}
              onClick={() => {
                editForm.setFieldsValue({
                  ...item,
                });
                setHotelDetail(item);
                setHotelVisible(true);
              }}
            >
              编辑
            </Button>
            {`  `}
            <Button
              type={'link'}
              style={{ paddingLeft: 0 }}
              onClick={() => onPublishHotel(item)}
            >
              {!!item.is_publish ? '取消发布' : '发布'}
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <FlexJustifyBetween style={{ marginBottom: 12 }}>
        <Flex>
          <Switch checked={tableEnable} onChange={changeShowSet} />
          <Air horizontal={3} />
          <div>启用酒店</div>
        </Flex>
        <Flex>
          <Space>
            <Button
              disabled={!tableEnable}
              onClick={() => setTimeVisible(true)}
            >
              {lastTime1 || '最晚提交时间 '}<span style={{ textDecoration: 'underline', marginLeft: 6 }}>{` 设置`}</span>
            </Button>
            <Button type={'primary'} disabled={!tableEnable} onClick={() => setHotelVisible(true)}>
              添加酒店
          </Button>
          </Space>
        </Flex>
      </FlexJustifyBetween>
      <Spin
        indicator={<div />}
        tip="尚未开启酒店"
        spinning={!tableEnable}
      >
        <Table rowKey="id" columns={columns} {...tableProps} pagination={false} />
      </Spin>

      <CostomModal
        visible={hotelVisible}
        onCancel={() => {
          editForm.resetFields();
          setHotelVisible(false);
          setHotelDetail({});
        }}
        onOk={() => onSetHotel()}
        title={`${!!hotelDetail && hotelDetail.id ? '编辑' : '新增'}酒店`}
        form={editForm}
        formItems={[{
          label: '酒店名称',
          name: 'title',
          rules: [{ required: true, message: '请输入酒店名称' }],
        }, {
          label: '酒店地址',
          name: 'location',
          rules: [{ required: true, message: '请输入酒店地址' }],
        }, {
          label: '备注信息',
          name: 'notice',
        }]}
      />
      <CostomModalTime
        visible={timeVisible}
        isTimeLayout={true}
        onCancel={() => {
          timeForm.resetFields();
          setTimeVisible(false);
        }}
        onOk={() => onSetTime()}
        title={'设置最后提交时间'}
        form={timeForm}
        formItems={[{
          render: () => {
            return (
              <Form.Item
                label={'最后提交&修改时间'}
                name='last_time'
              >
                <DatePicker style={{ width: 200 }} locale={locale} placeholder={"设置最晚提交时间"} />
              </Form.Item>
            )
          }
        }]}
      />
      <CostomModal
        visible={roomVisible}
        onCancel={() => {
          setRoomHotelDetail({});
          setRoomVisible(false);
        }}
        width={600}
        onOk={() => onSetRoom()}
        title={`设置房型`}
      >
        <Form form={roomForm} name='room_forms' autoComplete='off'>
          <Form.List name='rooms'>
            {(fields) => {
              return (
                <div>
                  {fields.map((field, index) => {
                    return (
                      <Space key={field.key} style={{ marginBottom: 12 }}>
                        <div style={{ width: 40 }}>房型{index + 1}</div>
                        <Form.Item
                          noStyle
                          {...field}
                          name={[field.name, 'room_type']}
                          fieldKey={field.fieldKey}
                          rules={[{ required: true, message: '请设置房型' }]}
                        >
                          <Select
                            style={{ width: 150 }}
                            options={hotelRoomListSelectOptions}
                            placeholder="选择房型"
                            disabled={true}
                          />
                        </Form.Item>

                        <Form.Item
                          noStyle
                          {...field}
                          name={[field.name, 'room_num']}
                          fieldKey={field.fieldKey}
                          rules={[{ required: true, message: '请设置数量' }]}
                        >
                          <InputNumber
                            style={{ width: 150 }}
                            max={10000}
                            maxLength={4}
                            placeholder="选择房型数量"
                          />
                        </Form.Item>

                        <Form.Item
                          noStyle
                          {...field}
                          name={[field.name, 'room_remark']}
                          fieldKey={field.fieldKey}
                        >
                          <Input
                            style={{ width: 150 }}
                            placeholder="填写房型备注"
                            disabled={roomHotelDetail.is_publish}
                          />
                        </Form.Item>
                      </Space>
                    );
                  })}
                </div>
              );
            }}
          </Form.List>
        </Form>
      </CostomModal>
    </div>
  );
};
