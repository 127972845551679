import React, {Component} from 'react'
import style from './BannerTop.scss'
import {api} from '../../common/app';
import {Badge, Dropdown, Menu, message, Modal, Select} from "antd";
import {CloseOutlined, DownOutlined, MoneyCollectOutlined, PoweroffOutlined, UserOutlined} from '@ant-design/icons'
import {Air} from "../air/Air";
import logo from "../../assets/logo.png"
import {Responsive} from "../common/responsive";
import {common} from "../../common/common";
import {RouteComponentProps, matchPath} from "react-router";
import {
    AppstoreFilled,
    BellFilled,
    BellOutlined,
    BellTwoTone,
    ContactsOutlined, MailOutlined, MessageOutlined, PlayCircleOutlined,
    VideoCameraOutlined
} from "@ant-design/icons/lib";
import {apis} from "../../common/apis/apis";
import {FlexJustifyAlignCenter} from "../style/flex/flex-justify-align-center";
import {Redirect, Route, Switch} from "react-router-dom";
import {MeetingList} from "../../routes/index/meeting-list/meeting-list";
import {LiveList} from "../../routes/index/live-list/live-list";
import {UserIndexView} from "../../routes/index/user/UserIndexView";
import {getSys} from "../../common/apis/apis/getSys";

const {Option} = Select
const {confirm} = Modal

interface IProps extends RouteComponentProps{

}

interface IState {
    userinfo : {
        "mobile": string,
        "email": string,
        "information": number
    }
    meetingListStyle : {
        display : string
    }
    liveListStyle : {
        display : string
    }
    emailStyle: {
        display: string
    }
    smsStyle : {
        display: string
    }
}

export class BannerTop extends Component<IProps,IState> {
    constructor(props : any) {
        super(props);
        this.refreshProps = this.refreshProps.bind(this);
        this.Logout = this.Logout.bind(this);
    }

    readonly state: IState = {
        userinfo : {
            mobile : "",
            email : "",
            information : 0
        },
        meetingListStyle : {
            display : "block"
        },
        liveListStyle : {
            display : "none"
        },
        emailStyle: {
            display: "none"
        },
        smsStyle : {
            display: "none"
        },
    }

    componentDidMount() {
        this.refreshProps(this.props);
        getSys({}).then(res=>{
            res.map(value => {
               if(value.sys === 'live_list')
                {
                    let liveListStyle = {display: "block"};
                    this.setState({ liveListStyle: liveListStyle})
                }else if(value.sys === 'email')
                {
                    let emailStyle = {display: "block"};
                    this.setState({ emailStyle: emailStyle})
                }else if(value.sys === 'sms')
                {
                    let smsStyle = {display: "block"};
                    this.setState({ smsStyle: smsStyle})
                }
            })
        })
    }

    refreshProps(props : any) {
        this.getUserInfo();
    }

    getUserInfo = () => {
        apis.getUserInfo({}).then((res) => {
            this.setState({userinfo : res});
        }).catch(e => {
            window.location.hash = '#/login'
        });
    }

    Logout() {
        confirm({
            title: '确认退出',
            content: '退出操作可能影响您正在进行的直播，仍然退出吗？',
            okText: "退出",
            onOk : () => {
                (api as any).Logout().then((res : any) => {
                    if (res.code === 200) {
                        window.location.hash = '#/login';
                    } else {
                        message.error(res.message);
                    }
                }, (err : any) => {
                    common.sentry.captureException(err)
                })
            },
            cancelText:"取消"
        });

    }

    render() {
        let {meetingListStyle,liveListStyle,smsStyle,emailStyle} = this.state
        const {match: {path}, location:{pathname}} = this.props
        return (
            <div className={style.container}>
                <Responsive>
                    <div className={style.content}>
                        <div className={style.left} style={{display : "flex", }} onClick={() => {
                            if(matchPath(pathname,{path: `${path}/live_list`})){
                                window.location.hash = '#/index/live_list';
                            }else{
                                window.location.hash = '#/index/meeting_list';
                            }
                        }}>
                            <img src={logo} alt="" style={{maxHeight : 30,margin : "15px 0px"}}/>
                            <div className={style.text} >
                                会佰分
                            </div>
                        </div>
                        <div className={style.center}/>
                        <div className={style.right}>
                            <div>
                                <Select
                                    value={(()=>{
                                        if(matchPath(pathname,{path: `${path}/live_list`})){
                                            return "live_list"
                                        }else if(matchPath(pathname,{path: `${path}/meeting_list`})){
                                            return "meeting_list"
                                        }else if(matchPath(pathname,{path: `${path}/email`})){
                                            return "email"
                                        }else if(matchPath(pathname,{path: `${path}/sms`})){
                                            return "sms"
                                        }else {
                                            return "meeting_list";
                                        }
                                    })()}
                                    bordered={false}
                                    onSelect={value => {
                                        switch (value) {
                                            case "meeting_list":
                                                window.location.hash = '#/index/meeting_list';
                                                break;
                                            case "live_list":
                                                window.location.hash = '#/index/live_list';
                                                break;
                                            case "email":
                                                window.location.hash = '#/index/email';
                                                break;
                                            case "sms":
                                                window.location.hash = '#/index/sms';
                                                break;
                                            default:
                                                break;

                                        }
                                    }}
                                >
                                    <Option value={"meeting_list"} style={meetingListStyle} selected>
                                        <FlexJustifyAlignCenter>
                                            <ContactsOutlined  style={{fontSize: 18}}/><Air horizontal={3}/> 会议管理系统
                                        </FlexJustifyAlignCenter>
                                    </Option>
                                    <Option value={"live_list"} style={liveListStyle} >
                                        <FlexJustifyAlignCenter>
                                            <PlayCircleOutlined style={{fontSize: 18}}/><Air horizontal={3}/> 直播管理系统
                                        </FlexJustifyAlignCenter>
                                    </Option>
                                    <Option value={"email"} style={emailStyle} >
                                        <FlexJustifyAlignCenter>
                                            <MailOutlined style={{fontSize: 18}}/><Air horizontal={3}/> 邮件管理系统
                                        </FlexJustifyAlignCenter>
                                    </Option>
                                    <Option value={"sms"} style={smsStyle}>
                                        <FlexJustifyAlignCenter>
                                            <MessageOutlined style={{fontSize: 18}}/><Air horizontal={3}/> 短信管理系统
                                        </FlexJustifyAlignCenter>
                                    </Option>
                                </Select>
                            </div>
                            <Air horizontal={8}/>
                            {/*消息*/}
                            <div
                                className={style.message}
                                onClick={() => {
                                    window.location.hash = '#/index/notice'
                                }}
                            >
                                <Badge dot={false}>
                                    <BellFilled style={{fontSize : 24, color : common.color.primary}}/>
                                </Badge>
                            </div>
                            {/*下拉信息*/}
                            <div className={style.info}>
                                <Dropdown
                                    overlay={
                                        <Menu>
                                            <Menu.Item
                                                key="1"
                                                onClick={()=>{
                                                    window.location.hash = '#/index/user'
                                                }}
                                            >
                                                <div className={style.dropItem}>
                                                    <UserOutlined style={{color : "#2194ff", fontSize : "18px"}}/>
                                                    <div className={style.itemText}>账号信息</div>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item
                                                key="2"
                                                onClick={()=>{
                                                    window.location.hash = '#/index/user/proceeds'
                                                }}
                                            >
                                                <div className={style.dropItem}>
                                                    <MoneyCollectOutlined style={{color : "#2194ff", fontSize : "18px"}}/>
                                                    <div className={style.itemText}>账户收益</div>
                                                </div>
                                            </Menu.Item>
                                            <Menu.Item
                                                key="3"
                                                onClick={this.Logout}
                                            >
                                                <div className={style.dropItem}>
                                                    <PoweroffOutlined style={{color : "#2194ff", fontSize : "18px"}}/>
                                                    <div className={style.itemText}>退出登录</div>
                                                </div>
                                            </Menu.Item>
                                        </Menu>
                                    }>
                                    <a className={style.userName}>
                                        {this.state.userinfo ? this.state.userinfo.mobile : ' '}
                                        <Air horizontal={3}/>
                                        <DownOutlined />
                                    </a>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </Responsive>
            </div>
        )
    }
}

const styles= {
    display : "none"
}
