/**
 * 邮箱绑定
 * Create by liaokai
 */
import {toPost} from "../../http-utils";

export const emailBind = toPost<
    {
        email : string,
        verifycode : string,
        password : string
    },
    {

    }
    >("/merchant/Merchant/EmailEdit");
