/**
 * 短信管理系统
 * Create by liaokai on 2020-07-03 12:28:39
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {tMenuVerticalTreeWithComp} from "../user/UserIndexView";
import {EmailOverview} from "../email/menu/overview/overview-router";
import {common} from "../../../common/common";
import {apis} from "../../../common/apis/apis";
import {Responsive} from "../../../components/common/responsive";
import {MenuContent} from "../../../components/menu-content/menu-content";
import {Air} from "../../../components/air/Air";

interface IProps extends RouteComponentProps{

}

interface IState{
    menuData : tMenuVerticalTreeWithComp
}

const menuItemsRaw : tMenuVerticalTreeWithComp = [
    {
        title : "短信",
        items : [
            {
                title : "总览",
                routerName : "overview",
                plusField : {
                    component : EmailOverview
                }
            },
            {
                title : "人员管理",
                routerName : "personnel",
                plusField : {
                    component : EmailOverview
                }
            },
            {
                title : "发送记录",
                routerName : "record",
                plusField : {
                    component : EmailOverview
                }
            },
        ]
    },
];


export class Sms extends Component<IProps,IState>{
    //从菜单树过滤 hidden === true，且不在 openRouterNames 白名单上的节点
    static openMenu(tree : tMenuVerticalTreeWithComp, openRouterNames : string[]) : tMenuVerticalTreeWithComp{
        return common.deepClone(tree).map(value => {
            // 仅隐藏隐藏的菜单项（刷新页面会仍在原页面）
            value.items.forEach(value1 => {
                if(openRouterNames.filter(value2 => value2 === value1.routerName).length > 0){
                    value1.hidden = false
                }
            })
            return value;
        })
    }

    readonly state : IState = {
        menuData : Sms.openMenu(menuItemsRaw, [])
    };

    componentDidMount() {
        apis.getActiveModule({}).then(res => {
            this.setState({menuData : Sms.openMenu(menuItemsRaw, res.map(value => value.routerName))});
        })
    }
    render() {
        const {} = this.props
        return (
            <Responsive>
                <Air vertical={20}/>
                <MenuContent
                    {...this.props}
                    menuData={this.state.menuData}
                    defaultRouterName={"overview"}
                />
            </Responsive>
        );
    }
}
