import React, { useState, useRef } from "react";
import {
    Form,
    Table,
    Switch,
    Button,
    message,
    Spin,
    Input,
    Modal,
    DatePicker,
} from "antd";
import { useAntdTable, useBoolean, useMount } from "ahooks";
import locale from "antd/lib/date-picker/locale/zh_CN";
import {
    liveSignList,
    formatListResult,
    exportCheckListUrl,
} from "./constants";
import { liveStore } from "../live.store";
import { Air } from "../../../../../../components/air/Air";
import { enableShowSet } from "../../../../../../common/apis/apis/live/enableShowSet";
import { FlexJustifyBetween } from "../../../../../../components/style/flex/flex-justify-between";
import { Flex } from "../../../../../../components/style/flex/flex";
import { enableShowInfo } from "../../../../../../common/apis/apis/live/enableShowInfo";
import { Link } from "react-router-dom";
import moment from "moment";

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Search } = Input;

export default () => {
    const [form] = Form.useForm();
    const [tableEnable, { toggle }] = useBoolean(false);
    const [times, setTimes] = useState("");
    useMount(() => {
        enableShowInfo({
            live_id: liveStore.live_id,
            type: 29,
        }).then((res) => {
            toggle(res.is_show);
        });
    });

    const changeShowSet = () => {
        enableShowSet({
            live_id: liveStore.live_id,
            type: 29,
        })
            .then((res) => {
                toggle(res.is_show);
            })
            .catch((e) => {
                message.error("设置失败 " + e.data?.message);
            });
    };
    const exportList = () => {
        exportCheckListUrl({
            live_id: Number(liveStore.live_id),
        }).then((response: any) => {
            const href = response.url;
            window.open(href, "_blank");
            message.success("导出成功");
        });
    };

    const { tableProps, search } = useAntdTable(
        (paginatedParams: any, tableProps: any) => {
            console.log("paginatedParams", paginatedParams);
            const { times, ...rest } = tableProps;

            let timeParams: any = {};

            if (times && times.length === 2) {
                timeParams = {
                    start_time: times[0].format("YYYY/MM/DD HH:mm:ss"),
                    end_time: times[1].format("YYYY/MM/DD HH:mm:ss"),
                };
            }
            return liveSignList({
                live_id: liveStore.live_id,
                page: paginatedParams.current,
                paginate: 15,
                ...timeParams,
                ...rest,
            }).then((response) => {
                return response;
            });
        },
        { form, formatResult: formatListResult, defaultPageSize: 15 }
    );
    const { submit, reset } = search;

    const columns = [
        {
            title: "用户昵称",
            width: 700,
            dataIndex: "nickname",
        },
        {
            title: "签到时间",
            dataIndex: "create_time",
        },
    ];
    return (
        <div>
            <Form form={form}>
                <FlexJustifyBetween
                    style={{ marginBottom: 12, alignItems: "center" }}
                >
                    <Flex style={{ alignItems: "center" }}>
                        <Flex>
                            <Switch
                                checked={tableEnable}
                                onChange={changeShowSet}
                            />
                            <Air horizontal={3} />
                            <div>开启签到</div>
                            <Air horizontal={5} />
                        </Flex>
                        <Flex>
                            <Air horizontal={15} />
                            <Form.Item style={{ margin: 0 }} name="times">
                                <RangePicker
                                    locale={locale}
                                    style={{ width: "250px" }}
                                    disabled={!tableEnable}
                                    onChange={(date, dateString) => {
                                        const [dateStart, dateEnd] = dateString;
                                        console.log("date", date);
                                        console.log("dateString", dateString);
                                        setTimes(date as any);
                                        submit();
                                    }}
                                    value={times as any}
                                    // onChange={() => submit()}
                                />
                            </Form.Item>
                            <Air horizontal={5} />
                            <Form.Item style={{ margin: 0 }} name="search">
                                <Search
                                    placeholder="请输入用户昵称"
                                    style={{ width: "200px" }}
                                    disabled={!tableEnable}
                                    onSearch={() => submit()}
                                    enterButton
                                />
                            </Form.Item>
                        </Flex>
                    </Flex>
                    <Flex>
                        <Air horizontal={5} />
                        <Button
                            onClick={exportList}
                            type={"primary"}
                            disabled={!tableEnable}
                        >
                            导出
                        </Button>
                    </Flex>
                </FlexJustifyBetween>
            </Form>

            <Spin indicator={<div />} spinning={!tableEnable}>
                <Table rowKey="id" columns={columns} {...tableProps} />
            </Spin>
        </div>
    );
};
