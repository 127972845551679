import React, { Component } from 'react'
import style from './SetHallNameList.scss'
import DarkBox from 'components/DarkBox'
import CheckBox from 'components/CheckBox'
import {api} from 'common/app'
import ReactTable from "react-table";
import HellView from 'components/AlertBox/BaseAlert'
import searchicon from 'assets/searchicon.png'

const zh_key={
  username:'姓名',
  mobile:'手机号',
}

export class SetHallNameList extends Component {
constructor(props) {
  super(props);
  this.state = {
    id:null,
    meetingid:null,

    labellist:[],
    selectlabel:{},
    _searchvalue:'',
    searchvalue:'',

    datacolumn:[],
    data:[],
  };
  this.refreshProps = this.refreshProps.bind(this);
  this.getMeetingLabel = this.getMeetingLabel.bind(this);
  this.createLabelList = this.createLabelList.bind(this);
  this.HandleSelectLabel = this.HandleSelectLabel.bind(this);
  this.HandleInputValue = this.HandleInputValue.bind(this);
  this.getListByLabel = this.getListByLabel.bind(this);
  this.InitTableHead = this.InitTableHead.bind(this);
  this.HandleSearch = this.HandleSearch.bind(this);
  this.HandleSelect = this.HandleSelect.bind(this);
  this.submitSelected = this.submitSelected.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
  this.InitTableHead();
}
refreshProps(props) {
  if (this.state.id != props.id) {
    this.state.id = props.id;
    this.setState(this.state);
  }
  if (this.state.meetingid != props.meetingid) {
    this.state.meetingid = props.meetingid;
    this.setState(this.state);
    this.getMeetingLabel(props.meetingid);
    this.getListByLabel();
  }
}
getMeetingLabel(id){
  api.getMeetingLabel(id).then(res=>{
    console.log(res);
    if (res.code === 200) {
      this.state.labellist = res.result;
      this.setState(this.state);
    }else{
      HellView.error({
        message:res.message
      });
    }
  },err=>{
    console.log(err);

  })
}
getListByLabel(){
  let labelArray=[];
  for (const key in this.state.selectlabel) {
    if (this.state.selectlabel.hasOwnProperty(key)) {
      const element = this.state.selectlabel[key];
      if (element) {
        labelArray.push(key);
      }
    }
  }
  api.getNameListByLabel(this.state.id,labelArray,this.state.searchvalue).then(res=>{
    console.log(res);
    if (res.code === 200) {
      this.state.data = res.result;
      this.setState(this.state);
    }else{
      HellView.error({
        message:res.message
      });
    }
  },err=>{
    console.log(err);
  })
}
HandleSelectLabel(key){
  this.state.selectlabel[key] = !this.state.selectlabel[key];
  this.getListByLabel();
  this.setState(this.state);
}
createLabelList(){
  if (this.state.labellist.length<=0) return;
  let result = [];
  for (let z = 0; z < this.state.labellist.length; z++) {
    const element = this.state.labellist[z];
    result.push(
          <div className={[style.OptionCheck,'childcenter childcontentstart'].join(' ')} onClick={this.HandleSelectLabel.bind(this,element.id)}>
            <CheckBox checked={this.state.selectlabel[element.id]}/> <div className={style.OptionName}>{element.name}</div>
          </div>
    )
  }
  return result;
}
HandleInputValue(type,e){
  this.state[type] = e.target.value;
  this.setState(this.state);
}
HandleSelect(index){
  this.state.data[index].checked = !this.state.data[index].checked;
  this.setState(this.state);
}
getCheckAll(){
  let checkall = true;
  for (let z = 0; z < this.state.data.length; z++) {
    const element = this.state.data[z];
    if (!element.checked) {
      checkall = false;
    }
  }
  return checkall
}
InitTableHead(){
  let datacolumn = [];
  datacolumn.push({
    Header: <div className={[style.CheckBox,'childcenter'].join(' ')}></div>,
    accessor: null,
    Cell:props=>{
        return <div key={props.index+'guestdata'+ props.index} className={[style.CheckBox,'childcenter'].join(' ')} onClick={this.HandleSelect.bind(this,props.index)}>
          <CheckBox checked={this.state.data[props.index].checked?true:false}/>
        </div>
    },
    resizable:false,
    sortable:false,
    width:50,
    headerClassName:style.ListHead,
  })
  for (const key in zh_key) {
    if (zh_key.hasOwnProperty(key)) {
        const element = zh_key[key];
        datacolumn.push({
            Header: <div className={[style.HeadCotnent,'childcenter childcontentstart'].join(' ')}>{element}</div>,
            accessor: key,
            Cell:props=>{
                return <div key={props.index+'guestdata'+ props.index} className={[style.ListChild,'childcenter childcontentstart'].join(' ')}>{props.value}</div>
            },
            resizable:false,
            width:key ==='paytime'?200:undefined,
            headerClassName:style.ListHead,
        })
    }
  }
  this.state.datacolumn = datacolumn;
}
HandleSearch(){
  if(this.state.searchvalue){
    this.state.searchvalue = ''
  }else{
    this.state.searchvalue = this.state._searchvalue;
  }
  this.getListByLabel(this.state.id);
  this.setState(this.state);
}
submitSelected(){
  let submitarray = [];
  for (let z = 0; z < this.state.data.length; z++) {
    const element = this.state.data[z];
    if(element.checked){
      submitarray.push(element.id);
    }
  }
  if(submitarray.length > 0){
      api.setHallNameList(this.state.id,submitarray).then(res=>{
          console.log(res);
          if (res.code === 200) {
              this.props.onClose();
          }else{
              HellView.error({
                  message:res.message
              });
          }
          window.location.reload();
      },err=>{
          console.log(err);

      })
  }
}
render() {
  return (
    <DarkBox >
        <div className={style.SetHallNameList}>
            <div className={style.CloseButton} onClick={this.props.onClose}></div>
            <div className={style.Title}>设置会场人员</div>

            <div className={[style.HandleGroup,'childcenter childcontentstart'].join(' ')}>
              <div className={[style.FilterOptionBox,'childcenter'].join(' ')} >
                <span >快速筛选</span>
                <div className={style.OptionBox} tabIndex='0'>
                  <div className={[style.OptionValue,'childcenter'].join(' ')}>
                    <span>标签...</span>
                    <span></span>
                  </div>
                  <div className={style.DropBox}>
                    {this.createLabelList()}
                  </div>
                </div>
                <div className={[style.SearchInputBox,'childcenter'].join(' ')}>
                    <div className={[style.SearchInput,'childcenter'].join(' ')}>
                        <div className={[style.SearchIcon,'childcenter'].join(' ')}>
                            <img src={searchicon} alt=""/>
                        </div>
                        <div className={style.Inputs}>
                            <input type="text" value={this.state._searchvalue} onChange={this.HandleInputValue.bind(this,'_searchvalue')}/>
                        </div>
                    </div>
                    <div className={[style.SearchButton,'childcenter'].join(' ')} onClick={this.HandleSearch}>
                      {this.state.searchvalue?'取消检索':'快速检索'}
                    </div>
                </div>
              </div>
            </div>

            <div className={style.NameListBody}>
                <ReactTable
                    data={this.state.data}
                    columns={this.state.datacolumn}
                    showPagination={false}
                    defaultPageSize = {100000}
                    minRows={12}
                    style={{
                      height: "300px"
                    }}
                    NoDataComponent={() => {return <div className={[style.NoDataTips,'childcenter'].join(' ')}>暂无可添加数据</div>}}
                />
            </div>
            <div className={[style.FormButtonGroup,'childcenter'].join(' ')}>
              <div className={[style.Button,style.Confirm,'childcenter'].join(' ')} onClick={this.submitSelected}>确认</div>
              <div className={[style.Button,style.Cancel,'childcenter'].join(' ')} onClick={this.props.onClose}>取消</div>
            </div>
        </div>
    </DarkBox>
   )
   }
}
export default SetHallNameList
