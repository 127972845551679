/**
 * 直播管理 menu
 * Create by liaokai on 2020-05-23 17:11:37
 */
import * as React from 'react';
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { tMenuVerticalTreeWithComp } from "../../../user/UserIndexView";
import { OverviewRouter } from "../../../meeting-list/meeting/menu/overview/overview-router";
import { observer } from "mobx-react";
import { common } from "../../../../../common/common";
import { apis } from "../../../../../common/apis/apis";
import { Responsive } from "../../../../../components/common/responsive";
import { MenuContent } from "../../../../../components/menu-content/menu-content";
import { storeMenu } from "../../../../../common/menu.store";
import { MeetingMenuPrefix } from "../../../meeting-list/meeting/menu/compoonent/meeting-menu-prefix/meeting-menu-prefix";
import { LiveEditRouter } from "./edit/edit-router";
import { LiveOverviewRouter } from "./overview/overview-router";
import { LiveLimitRouter } from "./limit/limit-router";
import { LiveUserRouter } from "./user/user-router";
import { LiveFormRouter } from "./form/form-router";
import { LiveCreditRouter } from "./credit/credit-router";
import { LiveChatRouter } from "./chat/chat-router";
import { LiveRedEnvelopeRouter } from "./red-envelope/red-envelope-router";
import { LivePrizeRouter } from "./prize/prize-router";
import { LiveDocDownloadRouter } from "./doc-download/doc-download-router";
import { LiveQaRouter } from "./qa/qa-router";
import { LiveContentRouter } from "./live_content/live-content-router";
import { LiveDataCountRouter } from "./data-count/data-count-router";
import { LiveProkectRouter } from "./prokect/prokect-router";
import { LiveDocRouter } from "./doc/doc-router";
import { LiveKeywordFilterRouter } from "./keyword-filter/keyword-filter-router";
import { LiveCreditListRouter } from "./credit-list/credit-list-router";
import { Alert, Button, Tag } from "antd";
import { Padding } from "../../../../../components/style/space/padding";
import { FlexJustifyBetween } from "../../../../../components/style/flex/flex-justify-between";
import { Flex } from "../../../../../components/style/flex/flex";
import { Link, } from "react-router-dom";
import { FlexJustifyAlignCenter } from "../../../../../components/style/flex/flex-justify-align-center";
import styled from "styled-components";
import { Air } from "../../../../../components/air/Air";
import { RightOutlined, VideoCameraOutlined } from "@ant-design/icons/lib";
import { liveStore } from "./live.store";
import { liveShow } from "../../../../../common/apis/apis/live/liveShow";
import { FlexCol } from "../../../../../components/style/flex/flex-col";
import { FlexAlignCenter } from "../../../../../components/style/flex/flex-align-center";
import { LiveRelationsRouter } from "./relations/relations-router";
import { LiveQuestionRouter } from "./question/question-router";
import { EditRole } from "./role/edit-role";
import { EditRoleRoute } from "./role/edit-role-route";
import { AdvertisementRouter } from "./advertisement/advertisement-router";
import { InviteRouter } from "./invite/invite-router";
import { AnswerRouter } from "./answer/answer-router";
import { VenueRouter } from "./venue/venue-router";
import { CheckRouter } from "./check/check-router";
import { MessageRouter } from "./message/message-router";
import {AttentionRouter} from "./attention/attention-router";
import {ReplyKeywordsRouter} from "./reply-keywords/reply-keywords-router";
import {RobotChatRouter} from "./robot-chat/robot-chat-router";
import {LiveTabRouter} from "./live-tab/live-tab-router";
import {HeatValRouter} from "./heat-val/heat-val-router";
interface IProps extends RouteComponentProps<{ live_id: string }> {

}

interface IState {
    menuData: tMenuVerticalTreeWithComp
}

const menuItemsRaw: tMenuVerticalTreeWithComp = [
    {
        title: "总览",
        items: [
            {
                title: "总览",
                routerName: "overview",
                plusField: {
                    component: LiveOverviewRouter,
                },
            },
        ],
    },
    {
        title: "直播设置",
        items: [
            {
                title: "编辑直播",
                routerName: "edit",
                plusField: {
                    component: LiveEditRouter,
                },
            },
            {
                title: "直播信息",
                routerName: "message",
                plusField: {
                    component: MessageRouter,
                },
            },
            {
                title: "直播tab",
                routerName: "live_tab",
                plusField: {
                    component: LiveTabRouter,
                },
            },
            // {
            //     title: "角色设置",
            //     routerName: "set_role",
            //     plusField: {
            //         component: EditRoleRoute
            //     }
            // },
            {
                title: "文档",
                routerName: "doc_manager",
                plusField: {
                    component: LiveDocRouter,
                },
            },
            // {
            //     title: "观看限制",
            //     routerName: "limit",
            //     plusField: {
            //         component: LiveLimitRouter,
            //     },
            // },
            // {
            //     title: "强制关注",
            //     routerName: "attention",
            //     plusField: {
            //         component: AttentionRouter,
            //     },
            // },
            // {
            //     title: "报名表单",
            //     routerName: "form",
            //     plusField: {
            //         component: LiveFormRouter,
            //     },
            // },
            {
                title: "广告位",
                routerName: "advertisement",
                plusField: {
                    component: AdvertisementRouter,
                },
            },
            {
                title: "关联直播",
                routerName: "relations",
                plusField: {
                    component: LiveRelationsRouter,
                },
            },
            {
                title: "分会场",
                routerName: "venue",
                plusField: {
                    component: VenueRouter,
                },
            },
        ],
    },
    {
        title: "用户管理",
        items: [
            {
                title: "用户信息",
                routerName: "user",
                plusField: {
                    component: LiveUserRouter,
                },
            },
            // {
            //     title: "积分明细",
            //     routerName: "credit_list",
            //     plusField: {
            //         component: LiveCreditListRouter,
            //     },
            //     hidden: true,
            // },
            // {
            //     title: "邀约榜",
            //     routerName: "invite",
            //     plusField: {
            //         component: InviteRouter,
            //     },
            // },
            {
                title: "签到信息",
                routerName: "check",
                plusField: {
                    component: CheckRouter,
                },
            }
        ],
    },
    // {
    //     title : "积分管理",
    //     items : [
    //         {
    //             title : "积分管理",
    //             routerName : "credit",
    //             plusField : {
    //                 component :LiveCreditRouter
    //             },
    //             hidden : true
    //         },
    //     ]
    // },
    {
        title: "互动管理",
        items: [
            {
                title: "聊天",
                routerName: "chat",
                plusField: {
                    component: LiveChatRouter,
                },
            },
            {
                title: "机器人回复",
                routerName: "ReplyKeywordsRouter",
                plusField: {
                    component: ReplyKeywordsRouter,
                },
            },
            {
                title: "机器人聊天",
                routerName: "RobotChatRouter",
                plusField: {
                    component: RobotChatRouter,
                },
            },
            {
                title: "热力值",
                routerName: "HeatValRouter",
                plusField: {
                    component: HeatValRouter,
                },
            },
            // {
            //     title : "关键词过滤",
            //     routerName : "keyword_filter",
            //     plusField : {
            //         component :LiveKeywordFilterRouter
            //     }
            // },
            // {
            //     title: "发红包",
            //     routerName: "red_envelope",
            //     plusField: {
            //         component: LiveRedEnvelopeRouter,
            //     },
            //     // hidden: true,
            // },
            // {
            //     title: "发奖品",
            //     routerName: "prize",
            //     plusField: {
            //         component: LivePrizeRouter,
            //     },
            //     // hidden: true,
            // },
            {
                title: "课件下载",
                routerName: "doc_download",
                plusField: {
                    component: LiveDocDownloadRouter,
                },
            },
            {
                title: "互动问答",
                routerName: "qa",
                plusField: {
                    component: LiveQaRouter,
                },
            },
            {
                title: "问卷调查",
                routerName: "question",
                plusField: {
                    component: LiveQuestionRouter,
                },
            },

            {
                title: "问答",
                routerName: "answer",
                plusField: {
                    component: AnswerRouter,
                },
            },
        ],
    },
    {
        title: "直播管理",
        items: [
            {
                title: "视频内容",
                routerName: "live_content",
                plusField: {
                    component: LiveContentRouter,
                },
            },
            {
                title: "数据统计",
                routerName: "data_count",
                plusField: {
                    component: LiveDataCountRouter,
                },
            },
        ],
    },
    // {
    //     title : "专题",
    //     items : [
    //         {
    //             title : "专题管理",
    //             routerName : "prokect",
    //             plusField : {
    //                 component :LiveProkectRouter
    //             }
    //         },
    //     ]
    // },
    // {
    //     title : "资料管理",
    //     items : [
    //         {
    //             title : "文档",
    //             routerName : "doc",
    //             plusField : {
    //                 component :LiveDocRouter
    //             }
    //         },
    //     ]
    // },
];

@observer
export class LiveMenu extends Component<IProps, IState>{

    //从菜单树过滤 hidden === true，且不在 openRouterNames 白名单上的节点
    static openMenu(tree: tMenuVerticalTreeWithComp, openRouterNames: string[]): tMenuVerticalTreeWithComp {
        return common.deepClone(tree).map(value => {
            // 仅隐藏隐藏的菜单项（刷新页面会仍在原页面）
            value.items.forEach(value1 => {
                if (openRouterNames.filter(value2 => value2 === value1.routerName).length > 0) {
                    value1.hidden = false
                }
            })
            return value;
        })
    }

    readonly state: IState = {
        menuData: LiveMenu.openMenu(menuItemsRaw, [])
    };
    liveColor(status: string): string {
        switch (status) {
            case "直播中":
                return '#f00'
            default:
                return '#108ee9'
        }
    }
    componentDidMount() {
        apis.getActiveModule({}).then(res => {
            this.setState({ menuData: LiveMenu.openMenu(menuItemsRaw, res.map(value => value.routerName)) });
        })
    }

    render() {
        const { match: { params } } = this.props
        return (
            <Responsive>
                {/*本次直播信息*/}
                <AlertBox>
                    <Flex>
                        <FlexCol>
                            <Flex>
                                <div>
                                    <div>{liveStore.liveInfo.title}</div>
                                </div>
                                <Air horizontal={5} />
                                <div>
                                    <Tag color={this.liveColor(liveStore.liveInfo.live_status)}>{liveStore.liveInfo.live_status}</Tag>
                                </div>
                            </Flex>
                            <Time>{liveStore.liveInfo.live_time}</Time>
                        </FlexCol>
                    </Flex>
                    <FlexAlignCenter>
                        <Link to={"/index/live_list/index"}>返回直播列表 <RightOutlined /></Link>
                        <Air horizontal={15} />
                        <Link to={`/index/live_list/live/${liveStore.live_id}/live_room`} target={"_blank"}>
                            <FlexJustifyAlignCenter>
                                <Button type={"primary"} icon={<VideoCameraOutlined />}>PPT</Button>
                            </FlexJustifyAlignCenter>
                        </Link>
                    </FlexAlignCenter>
                </AlertBox>
                <MenuContent
                    ref={ref => storeMenu.setLiveMenuRef(ref)}
                    {...this.props}
                    menuData={this.state.menuData}
                    defaultRouterName={"overview"}
                />
            </Responsive>
        );
    }
}


const Time = styled.div`
  margin-top: 5px;
  font-size: 10px;
  color: ${common.color.normalText};
`

const AlertBox = styled(FlexJustifyBetween)`
  align-items: center;
  margin: 15px 0px;
  padding: 15px;
  background: #f3f3f3;
  border: 1px solid #eee;
`
