/**
 * Header + dynamic router
 * Create by liaokai on 2020-04-13 15:01:43
 */
import * as React from 'react';
import { Component } from "react";
import { BannerTop } from "../../components/BannerTop/BannerTop";
import style from "./index.scss"
import { Redirect, Route, Switch } from "react-router-dom";
import { MeetingList } from "./meeting-list/meeting-list";
import Notice from "./notice"
import { UserIndexView } from "./user/UserIndexView";
import { RouteComponentProps } from "react-router";
import { Footer } from "../../components/footer/footer";
import { LoginCorner } from "../../components/LoginBox/LoginCorner";
import { Affix } from "antd";
import { LiveList } from "./live-list/live-list";
import { Email } from "./email/email";
import { Sms } from "./sms/sms";
import { ajaxAir } from "../../common/apis/apis/ajaxAir";

interface IProps extends RouteComponentProps {

}

interface IState {

}

export class Index extends Component<IProps, IState>{

    readonly state: IState = {
    };

    componentDidMount(): void {
        setInterval(
            () => {
                ajaxAir({});
            },
            600000);
    }

    render() {
        let { match: { path } } = this.props;
        let { } = this.state;
        return (
            <div className={style.container}>
                <Affix offsetTop={0}>
                    <BannerTop {...this.props} />
                </Affix>

                <Switch>
                    <Redirect path={path} exact to={`${path}/meeting_list`} />
                    <Route path={`${path}/meeting_list`} component={MeetingList} />
                    <Route path={`${path}/live_list`} component={LiveList} />
                    <Route path={`${path}/email`} component={Email} />
                    <Route path={`${path}/sms`} component={Sms} />
                    <Route path={`${path}/notice`} component={Notice} />
                    <Route path={`${path}/user`} component={UserIndexView} />
                </Switch>
                <LoginCorner />
                <Footer />
            </div>
        );
    }
}
