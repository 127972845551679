import React, { useState, useEffect } from 'react';
import { Descriptions, Avatar } from 'antd';
import { observer } from 'mobx-react'
import { guestStore } from './constants/store'
import { getUserInfoDetail } from './constants'
import {RouteComponentProps} from "react-router";

interface IProps extends RouteComponentProps<{id : any}>{

}

@observer
export default class Detail extends React.Component<IProps, any> {
  state = {
    descriptions: [] as any[]
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    getUserInfoDetail({ uid: id })
      .then((result) => {
        this.setState({ descriptions: result });
      });
    // const { gusetDetail, field } = guestStore;
    // if (gusetDetail && gusetDetail.username) {
    //   let desc: any[] = [];
    //   Object.keys(field).forEach((key) => {
    //     desc.push({
    //       label: field[key],
    //       value: gusetDetail[key]
    //     })
    //   })
    // }
  }
  render() {
    const { descriptions } = this.state;
    return (
      <div>
        <Descriptions title="嘉宾详情" bordered column={1}>
          {descriptions && descriptions.map((item: any) => {
            return (
              <Descriptions.Item label={item.key}>
                {item.type === 'img'
                  ? <Avatar src={item.val} />
                  : item.val}
              </Descriptions.Item>
            )
          })}
        </Descriptions>
      </div>
    )
  }
}

// const Detail = (props: any) => {
//   const { data, field } = props;
//   const [descriptions, setDescriptions] = useState([] as any);

//   useEffect(() => {
//     if (data.username) {
//       let desc: any[] = [];
//       Object.keys(field).forEach((key) => {
//         desc.push({
//           label: field[key],
//           value: data[key]
//         })
//       })

//       setDescriptions(desc);
//     }
//   }, [data])

//   return (

//   )
// }

// export default observer(Detail);