/**
 * 防止多次展示提示
 * Create by liaokai on 2020-06-18 13:54:33
 */
import {message} from "antd";

let showing : boolean = false
export function singleTip(text : string) {
    if(!showing){
        showing = true
        message.error(text, undefined, ()=>{
            showing = false
        })
    }
}
