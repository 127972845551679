// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".email-send_container__3jBXL{display:flex}.email-send_container__3jBXL .email-send_left__3uzMD{flex:1 1;padding-right:30px}.email-send_container__3jBXL .email-send_left__3uzMD .email-send_item__3z7Pt{display:flex;align-items:center;margin-bottom:15px}.email-send_container__3jBXL .email-send_left__3uzMD .email-send_templateContent__FVSIQ .email-send_moduleText__I94-W{word-wrap:break-word;word-break:break-all}.email-send_container__3jBXL .email-send_left__3uzMD .email-send_templateContent__FVSIQ .email-send_editText__21Pgh{display:flex;justify-content:flex-end}.email-send_container__3jBXL .email-send_left__3uzMD .email-send_sendBtns__1_EiG{display:flex;justify-content:flex-end}.email-send_container__3jBXL .email-send_right__3RRRF .email-send_smsPreview__3sen7{word-wrap:break-word;word-break:break-all}.email-send_rightSide__ievWP{display:flex;flex-direction:row-reverse;margin-right:10px}\n", ""]);
// Exports
exports.locals = {
	"container": "email-send_container__3jBXL",
	"left": "email-send_left__3uzMD",
	"item": "email-send_item__3z7Pt",
	"templateContent": "email-send_templateContent__FVSIQ",
	"moduleText": "email-send_moduleText__I94-W",
	"editText": "email-send_editText__21Pgh",
	"sendBtns": "email-send_sendBtns__1_EiG",
	"right": "email-send_right__3RRRF",
	"smsPreview": "email-send_smsPreview__3sen7",
	"rightSide": "email-send_rightSide__ievWP"
};
module.exports = exports;
