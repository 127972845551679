import React, { Component } from 'react'
import style from './ChargeSettingView.scss'
import bellicon from '../../../../assets/bellicon.png'
import wechaticon from '../../../../assets/wechaticon.png'
import {common} from "../../../../common/common";
import {InfoCircleFilled} from "@ant-design/icons/lib";
import {Air} from "../../../../components/air/Air";

export class ChargeSettingView extends Component {
constructor(props) {
  super(props);
  this.state = {};
     this.refreshProps = this.refreshProps.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
}
refreshProps(props) {

}
render() {
  return (
    <div className={style.ViewBox}>
        <div className={style.ChargeSettingBox}>
            <div style={{color : common.color.primary, display :"flex", alignItems : "center",margin : "0px 10px 15px"}}>
                <InfoCircleFilled style={{ fontSize : 32}}/>
                <Air horizontal={5}/>
                <div>只针对已经结束的会议进行结算和提现</div>
            </div>
            <div className={style.GetCashWayBox}>
                <div className={[style.TipsBox,'childcenter childcolumn childalignstart'].join(' ')}>
                    <span>会佰分代收</span>
                    <span>门票费用由会佰分代收，微信会收取总金额的6%手续费，会议结束后方可提取款项</span>
                </div>
                <div className={style.CashWayBox}>
                    <div className={[style.WayBox,'childcenter childcolumn'].join(' ')}>
                        <div className={style.WayImage}>
                            <img src={wechaticon} alt=""/>
                        </div>
                        <div className={[style.WayName,style.wechat].join(' ')}>微信支付</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   )
   }
}
export default ChargeSettingView
