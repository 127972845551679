/**
 * 邮件发送记录
 * Create by liaokai on 2020-07-03 13:52:39
 */
import * as React from 'react';
import {Component} from "react";
import { Texts } from '../../../../../components/text/Texts';
import {Air} from "../../../../../components/air/Air";
import {RouteComponentProps} from "react-router";
import {SendRecordTable} from "../../../meeting-list/meeting/menu/email-multi-send/index/send-record-table/send-record-table";

interface IProps extends RouteComponentProps<any>{

}

interface IState{

}

export class EmailRecord extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <div>
                <Texts.ItemTitle>
                    {/*发送记录*/}
                </Texts.ItemTitle>
                <Air vertical={5}/>
                <div>
                    <SendRecordTable {...this.props}/>
                </div>
            </div>
        );
    }
}
