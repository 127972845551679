/**
 * 获取所有会前模块
 * Create by liaokai on 2020-04-23 13:55:20
 */
import {toPost} from "../http-utils";

export interface IAllModuleBeforeItem {
    "id": number,
    "title": string, //标题
    "describe": string, //描述
    "icon": string, // 图标
    "sort": number,
    "type": number,
    "status": number,
    "fee": number, //价格 单位分
    "open_module": boolean, //是否已经开通
    "routerName" : string
}

export const getAllModule = toPost<
    {

    },
    IAllModuleBeforeItem[]
>("/merchant/mokuai/index");
