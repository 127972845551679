// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BannerTop_container__3jzNF{background-color:#FFFFFF;border-bottom:#0089FF solid 1px}.BannerTop_content__2B53J{display:flex;flex-direction:row;justify-content:space-between;align-items:center}.BannerTop_content__2B53J .BannerTop_left__2zI54{display:flex;align-items:center;cursor:pointer}.BannerTop_content__2B53J .BannerTop_left__2zI54 .BannerTop_text__3KvVi{font-size:22px;font-weight:bold;color:#0089FF;margin:8px 15px}.BannerTop_content__2B53J .BannerTop_right__1Hlnz{display:flex;flex-direction:row;align-items:center}.BannerTop_content__2B53J .BannerTop_right__1Hlnz .BannerTop_message__3x2Ii{margin-right:25px;cursor:pointer}.BannerTop_content__2B53J .BannerTop_right__1Hlnz .BannerTop_message__3x2Ii .BannerTop_img__35Ur2{max-width:22px}.BannerTop_content__2B53J .BannerTop_right__1Hlnz .BannerTop_info__1ib3U .BannerTop_userName__3UJvG{display:flex;flex-direction:row;align-items:center;font-size:18px}.BannerTop_dropItem__FNjVu{display:flex;flex-direction:row;align-items:center}.BannerTop_dropItem__FNjVu .BannerTop_imgBox__3D-Zn{display:flex;flex-direction:row;align-items:center}.BannerTop_dropItem__FNjVu .BannerTop_imgBox__3D-Zn .BannerTop_itemImg__6OCuo{max-width:18px}.BannerTop_dropItem__FNjVu .BannerTop_imgBox__3D-Zn .BannerTop_icon__3i-ux{color:#2194ff;font-size:20px}.BannerTop_dropItem__FNjVu .BannerTop_itemText__15kCh{margin-left:10px}.BannerTop_browserTips__YjQ_P{display:flex;align-items:center;padding:20px 0px;background-color:#f4fbff;color:#3b87f7}.BannerTop_browserTips__YjQ_P .BannerTop_left__2zI54{flex:1 1}.BannerTop_browserTips__YjQ_P .BannerTop_center__3pB9b{flex:8 1;display:flex;justify-content:center}.BannerTop_browserTips__YjQ_P .BannerTop_right__1Hlnz{flex:1 1;color:#3b87f7;font-size:25px;margin-right:30px;cursor:pointer;position:absolute;right:0px;padding:20px 0px}\n", ""]);
// Exports
exports.locals = {
	"container": "BannerTop_container__3jzNF",
	"content": "BannerTop_content__2B53J",
	"left": "BannerTop_left__2zI54",
	"text": "BannerTop_text__3KvVi",
	"right": "BannerTop_right__1Hlnz",
	"message": "BannerTop_message__3x2Ii",
	"img": "BannerTop_img__35Ur2",
	"info": "BannerTop_info__1ib3U",
	"userName": "BannerTop_userName__3UJvG",
	"dropItem": "BannerTop_dropItem__FNjVu",
	"imgBox": "BannerTop_imgBox__3D-Zn",
	"itemImg": "BannerTop_itemImg__6OCuo",
	"icon": "BannerTop_icon__3i-ux",
	"itemText": "BannerTop_itemText__15kCh",
	"browserTips": "BannerTop_browserTips__YjQ_P",
	"center": "BannerTop_center__3pB9b"
};
module.exports = exports;
