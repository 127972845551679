/**
 * 首页-会议-讲师-编辑
 * Create by liaokai on 2020-03-12 16:25:01
 */
import * as React from 'react';
import { Component } from "react";
import style from "./edit.scss";
import { Form, Badge, Input, Radio, Button, Card, message } from "antd";
import RichTextBox from "../../../../../../../components/RichTextBox/RichTextBox";
import { RouteComponentProps } from "react-router";
import { Air } from "../../../../../../../components/air/Air";
import { api } from "../../../../../../../common/app";
import { Radio as RadioX } from "../../../../../../../components/common/radio";
import { CheckCircleTwoTone } from "@ant-design/icons/lib";
import { HeaderIcon } from "../sort/header-icon/header-icon";
import { BreadcrumbCustomStateless } from "../../../../../../../components/breadcrumbx/breadcrumb-custom-stateless";
import { SurfaceRouter } from "../../../../../../../components/surface-router/surface-router";
import IconSelect from './component/icon'

// @ts-ignore
interface IProps extends RouteComponentProps<{ module_id?: number, meeting_id: string }> {

}

interface IState {
    richContent: string,
    type: string
    iconUrls: string[],
    iconSelectIndex: number,
    title: string,
    url: string,
    submitDisable: boolean;
    selectedIconUrl: string;
    selectedCostomIconUrl: string;
}

export class Edit extends Component<IProps, IState>{
    richTextBox: any = undefined

    //state初始化
    readonly state: IState = {
        richContent: "",
        type: "1",
        iconUrls: [],
        iconSelectIndex: 0,
        title: "",
        url: "",
        submitDisable: false,
        selectedIconUrl: '',
        selectedCostomIconUrl: '',
    };
    constructor(props: IProps) {
        super(props);
    }

    async componentDidMount(): Promise<void> {
        let res = await (api as any).getIcon();
        this.setState({ iconUrls: res.result }, () => {
            if (this.props.match.params.module_id) {
                // 编辑
                (api as any).getModelDetail(this.props.match.params.module_id).then((res: any) => {
                    let result = res.result?.[0];
                    if (result) {
                        // alert(JSON.stringify(result));
                        this.setState({
                            iconSelectIndex: this.state.iconUrls.indexOf(result.icon),
                            type: result.type.toString(),
                            title: result.title,
                            url: result.url,
                            selectedIconUrl: result.icon
                        });
                        this.richTextBox?.setContent(result.content);
                    }
                });
            }
        });
    }


    render() {
        let { match: { params }, history } = this.props;
        let { type, richContent, iconUrls, title, url, iconSelectIndex, submitDisable } = this.state;

        return (
            <div className={style.container}>
                {/*<div className={style.title}>*/}
                {/*    <span><span style={{cursor: "pointer"}} onClick={()=>{*/}
                {/*        this.props.history.goBack();*/}
                {/*    }}>会议介绍</span> > {params.module_id ? "模块编辑" : "新建模块"}</span>*/}
                {/*</div>*/}
                <div className={style.infoBox}>
                    <Form
                        className={style.form}
                        name="basic"
                        onFinish={(values) => {
                            (api as any).addModel(
                                this.props.match.params.meeting_id,
                                this.state.title,
                                this.state.type,
                                this.state.selectedIconUrl || this.state.selectedCostomIconUrl,
                                richContent,
                                this.state.url,
                                params.module_id ?? undefined
                            ).then((res: any) => {
                                if (res.code === 200) {
                                    message.success(res.message);
                                    history.goBack();
                                } else {
                                    message.warn(res.message);
                                    this.setState({ submitDisable: true }, () => {
                                        setTimeout(() => {
                                            this.setState({ submitDisable: false })
                                        }, 1000)
                                    })
                                }
                            });
                        }}
                        onFinishFailed={() => {

                        }}
                        labelCol={{ xxl: 2, xl: 2, lg: 3, md: 4, sm: 5, xs: 8 }}
                        wrapperCol={{ xxl: 22, xl: 22, lg: 21, md: 20, sm: 19, xs: 12 }}
                    >

                        <Form.Item
                            label="选择Icon"
                            name="icon"
                            rules={[{ required: false, message: '请选择一个Icon' }]}
                        >
                            <IconSelect
                                icon={this.state.selectedIconUrl}
                                costomIcon={this.state.selectedCostomIconUrl}
                                setIcon={(icon) => this.setState({ selectedIconUrl: icon })}
                                setCostomIcon={(icon) => this.setState({ selectedCostomIconUrl: icon })}
                            />
                        </Form.Item>
                        <Form.Item
                            label="模版标题"
                        >
                            <Input maxLength={6} placeholder={"标题"} value={title} onChange={event => this.setState({ title: event.target.value })} />
                        </Form.Item>
                        <Form.Item
                            label="类型"
                        >
                            <Radio.Group value={type} onChange={e => {
                                this.setState({ type: e.target.value })
                            }}>
                                <Radio value="1">图文</Radio>
                                <Radio value="2">外链</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="外链地址"
                            style={{ display: type === "2" ? undefined : "none" }}
                        >
                            <Input placeholder={"https://"} value={url} onChange={event => this.setState({ url: event.target.value })} />
                        </Form.Item>
                        <Form.Item
                            label="内容编辑"
                            name="richText"
                            rules={[{ required: false, message: '请输入编辑内容' }]}
                            style={{ display: type === "1" ? undefined : "none" }}
                        >
                            <Card bordered={true}>
                                <RichTextBox ref={ref => this.richTextBox = ref} onContentChange={(richContent: string) => this.setState({ richContent })} />
                            </Card>
                        </Form.Item>
                        <Form.Item label={" "} colon={false}>
                            <div className={style.submitBox}>
                                <Button disabled={submitDisable} type="primary" htmlType="submit">
                                    保存
                                </Button>
                                <Air horizontal={10} />
                                <Button onClick={() => {
                                    history.goBack();
                                }}>
                                    取消
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
}
