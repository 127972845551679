/**
 *
 * Create by liaokai on 2020-04-27 16:07:07
 */
import {toPost} from "../http-utils";

export const setNewHall = toPost<
    {
        id? : number,
        meetid : number,
        title : string
    },
    {

    }
>("/merchant/meeting_place/add");
