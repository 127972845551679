/**
 * 观看终端统计
 * Create by liaokai on 2020-06-24 17:31:58
 */
import {toPost} from "../../http-utils";

export interface ITerminal {
    title : string,
    percentage : number,
    live_count : number,
    record_count : number,
    num : number
}

export const terminal = toPost<
    {
        live_id : string
    },
    ITerminal[]
>("/merchant/live/terminal");

