// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".StepProcess_StepProcess__114FQ{position:relative}.StepProcess_StepProcess__114FQ .StepProcess_StepGroup__1aquV{position:relative;z-index:2}.StepProcess_StepProcess__114FQ .StepProcess_StepGroup__1aquV .StepProcess_StepBox__1mEfn{margin:0 112px}.StepProcess_StepProcess__114FQ .StepProcess_StepGroup__1aquV .StepProcess_StepBox__1mEfn .StepProcess_StepName__2y6Ov{position:absolute;top:-38px;font-size:18px;font-weight:400;color:rgba(0,0,0,0.4)}.StepProcess_StepProcess__114FQ .StepProcess_StepGroup__1aquV .StepProcess_StepBox__1mEfn .StepProcess_StepIndex__X7l3Y{width:26px;height:26px;background:#D9D9D9;border-radius:50%;color:#fff;position:relative;z-index:1}.StepProcess_StepProcess__114FQ .StepProcess_StepGroup__1aquV .StepProcess_StepBox__1mEfn .StepProcess_actName__3BWRa{color:#000}.StepProcess_StepProcess__114FQ .StepProcess_StepGroup__1aquV .StepProcess_StepBox__1mEfn .StepProcess_actIndex__IGY32{position:relative;overflow:hidden}.StepProcess_StepProcess__114FQ .StepProcess_StepGroup__1aquV .StepProcess_StepBox__1mEfn .StepProcess_actIndex__IGY32::after{position:absolute;content:'';width:100%;height:100%;top:0%;left:0%;z-index:-1;border-radius:50%;background:#0189FF;-webkit-animation:StepProcess_backgroundAnim__qXMS1 0.3s 0.25s backwards;animation:StepProcess_backgroundAnim__qXMS1 0.3s 0.25s backwards}@-webkit-keyframes StepProcess_backgroundAnim__qXMS1{0%{transform:translateX(-100%)}100%{transform:translateX(0%)}}@keyframes StepProcess_backgroundAnim__qXMS1{0%{transform:translateX(-100%)}100%{transform:translateX(0%)}}.StepProcess_StepProcess__114FQ .StepProcess_ProcessBody__U44PT{width:calc((var(--steplength) * (250px)) - 234px);height:6px;background:#D9D9D9;position:absolute;z-index:0;top:50%;left:50%;transform:translateX(-50%) translateY(-50%)}.StepProcess_StepProcess__114FQ .StepProcess_ProcessBody__U44PT::after{position:absolute;content:'';width:var(--maxstatus);height:100%;background:#0189FF;transition:width 0.3s}\n", ""]);
// Exports
exports.locals = {
	"StepProcess": "StepProcess_StepProcess__114FQ",
	"StepGroup": "StepProcess_StepGroup__1aquV",
	"StepBox": "StepProcess_StepBox__1mEfn",
	"StepName": "StepProcess_StepName__2y6Ov",
	"StepIndex": "StepProcess_StepIndex__X7l3Y",
	"actName": "StepProcess_actName__3BWRa",
	"actIndex": "StepProcess_actIndex__IGY32",
	"backgroundAnim": "StepProcess_backgroundAnim__qXMS1",
	"ProcessBody": "StepProcess_ProcessBody__U44PT"
};
module.exports = exports;
