/**
 * 取消互动问答
 * Create by liaokai on 2020-06-12 15:56:09
 */
import {toPost} from "../../http-utils";

export const questionCancel = toPost<
    {
        id : string
    },
    {

    }
>("/merchant/live/questionCancel");
