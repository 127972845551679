import {action, observable} from "mobx";
import {MenuContent} from "../components/menu-content/menu-content";

class MenuStore {
    @observable
    meetingMenuRef : MenuContent | null = null;

    @observable
    accountMenuRef : MenuContent | null = null;

    @observable
    liveMenuRef : MenuContent | null = null;

    @action.bound
    public setMeetingMenuRef(ref: MenuContent | null):void {
        this.meetingMenuRef = ref;
    }

    @action.bound
    public setAccountMenuRef(ref: MenuContent | null):void {
        this.accountMenuRef = ref;
    }

    @action.bound
    public setLiveMenuRef(ref: MenuContent | null):void {
        this.liveMenuRef = ref;
    }
}

export const storeMenu = new MenuStore()
