// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".form-table-local_row__NO_S9 td{cursor:default}\n", ""]);
// Exports
exports.locals = {
	"row": "form-table-local_row__NO_S9"
};
module.exports = exports;
