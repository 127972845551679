import { toPost } from "../../../../../../common/apis/http-utils";

export const getShare = toPost<{ meetid: string }, {
  id: number,
  meetid: number,
  title: string,
  link: string,
  desc: string,
  icon: string
}>(`/merchant/meeting/getShare`);


export const setShare = toPost<{
  id: number,
  meetid: number,
  title: string,
  link: string,
  desc: string,
  icon: string
}, any>(`/merchant/meeting/setShare`);