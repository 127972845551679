/**
 * 表单项 - 增加一项
 * Create by liaokai on 2020-03-24 13:42:58
 */
import * as React from 'react';
import {Component} from "react";
import {FormItemTitle, IFormItem} from "../form-item-title/form-item-title";
import {Button, Switch, Typography} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons/lib";
import {ITableRowItem} from "../form-table/form-table";
import {tFormTypes} from "../form-preview/form-preview";



interface IProps{
    onAdd?: (item : ITableRowItem)=>void
}

interface IState{
    item? : IFormItem,
    checked : boolean
}

export class FormAddLine extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {
        item : undefined,
        checked : false
    };
    private formItemTitle : FormItemTitle | null = null;
    constructor(props: IProps){
        super(props);
    }
    render() {
        let {onAdd} = this.props;
        let {item, checked} = this.state;
        return (
            <>
                <tr>
                    <th>
                        <FormItemTitle ref={ref => this.formItemTitle = ref} onChange={data => {
                            this.setState({item : data})
                        }}/>
                    </th>
                    <td>
                        <Switch disabled={!item?.name} checked={checked} onChange={()=>{
                            this.setState({checked : !checked})
                        }} />
                    </td>
                    <td>
                        <Button
                            disabled={!item}
                            type={"primary"}
                            icon={<PlusCircleOutlined />}
                            shape={"round"}
                            onClick={async () => {
                                if (!item?.name) {
                                    return
                                }
                                await onAdd?.({
                                    title : item?.name ?? "",      //表单项
                                    mandatory: checked,     //必填
                                    type: item?.rule.type ?? "input",   //表单类型
                                    operation: undefined,   //操作（仅占位）
                                    ruleKey : item?.rule.key ?? "",
                                    ableDel : true
                                });
                                this.formItemTitle?.clear()
                            }}
                        >确认添加</Button>
                    </td>
                </tr>
            </>
        );
    }
}
