/**
 * 互动问答详情
 * Create by liaokai on 2020-05-28 15:34:23
 */
import {toPost} from "../../http-utils";

export const questionShow = toPost<
    {
        id : string
    },
    {
        question : {
            "question_id": number,
            "title": string,
            "live_id": number,
            "type": string
            "error_credit": number, //错误积分,可为负数
            "right_credit": number, //正确积分
        },
        option : {
            "option_id": number,    //选项id
            "serial": string,       //序号
            "option": string,       //选项
            "isAnswer": boolean     //是否是正确答案
        }[]
    }
>("/merchant/live/questionShow");
