import {ILiveIconIndex, liveIconIndex} from "../../../../../common/apis/apis/live/liveIconIndex";
import {liveStore} from "./live.store";
import {action, observable} from "mobx";
class PresentStore {
    @observable
    presentInfo : ILiveIconIndex = {
        red_packet: 2, //红包开启代表1 ，不开启代表2
        "gift": 2,//奖品开启代表1 ，不开启代表2
        "question": 2,//互动问答开启代表1 ，不开启代表2
        "survey_questionnaire": 2, //调研问卷开启代表1 ，不开启代表2
        "live_relation" : 2
    }

    reload = () => {}

    @action.bound
    public setPresentInfo(data: ILiveIconIndex):void {
        this.presentInfo = data;
    }

    @action.bound
    public setRed(open : boolean):void {
        this.presentInfo.red_packet = open ? 1 : 2
    }

    @action.bound
    public setGift(open : boolean):void {
        this.presentInfo.gift = open ? 1 : 2
    }

    @action.bound
    public setQuestion(open : boolean):void {
        this.presentInfo.question = open ? 1 : 2
    }

    @action.bound
    public setSurveyQuestionnaire(open : boolean):void {
        this.presentInfo.survey_questionnaire = open ? 1 : 2
    }

    @action.bound
    public setLiveRelation(open : boolean):void {
        this.presentInfo.live_relation = open ? 1 : 2
    }

    @action.bound
    public setReload(fun: ()=>void):void {
        this.reload = fun
    }
}

export const presentStore = new PresentStore();
