/**
 * 提现记录 列表
 * Create by liaokai on 2020-05-15 17:21:26
 */
import * as React from 'react';
import {Component} from "react";
import {Button, message, Table} from "antd";
import {RouteComponentProps} from "react-router";
import {apis} from "../../../../common/apis/apis";
import {ItixianRecordItem} from "../../../../common/apis/apis/tixianRecord";

interface IProps extends RouteComponentProps{

}

interface IState{
    columns : any[],
    currentPage : number,
    total: number
    listData : ItixianRecordItem[],
    loading : boolean
}

const defaultPageSize = 10

export class CashRecordTable extends Component<IProps,IState>{
    readonly state : IState = {
        columns : [
            {
                title: '提现账户名',
                dataIndex: 'account_name',
                key: 'account_name',
                render: (text: string) => text,
            },
            {
                title: '提现银行账号',
                dataIndex: 'account_number',
                key: 'account_number',
                render: (text: string) => text,
            },
            {
                title: '联系人/联系电话',
                dataIndex: 'phone',
                key: 'phone',
                render: (text: string) => text,
            },
            {
                title: '时间',
                dataIndex: 'create_time',
                key: 'create_time',
                render: (text: string) => text,
            },
            {
                title: '金额',
                dataIndex: 'amount',
                key: 'amount',
                render: (text: string) => text + " 元",
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text: string) => text,
            },
        ],
        currentPage : 0,
        total : 0,
        listData : [],
        loading : false
    };

    componentDidMount() {
        this.getListData();
    }

    getListData =  (page: number = 1, )=>{
        this.setState({loading : true})
        apis.tixianRecord({page, pageNum : defaultPageSize}).then(res => {
            this.setState({
                currentPage : page,
                total : res.count_page * defaultPageSize,
                listData : res.data
            })
        }).finally(()=>{
            this.setState({loading : false})
        })
    }

    render() {
        let {} = this.props;
        let {columns, listData, currentPage, total, loading} = this.state;
        return (
            <Table
                loading={loading}
                columns={columns}
                dataSource={listData}
                pagination={{
                    current: currentPage,
                    total: total,
                    onChange: page => {
                        this.getListData(page);
                    }
                }}
            />
        );
    }
}
