/**
 * 帮助悬浮按钮
 * Create by GaoHan
 * Edit by liaokai on 2020-04-09 12:19:57
 */
import React, { Component } from 'react'
import icon from '../../assets/ques.png';
import qrcode from '../../assets/qrcode.png';
import {Popover, Affix, BackTop} from "antd";
import {QuestionCircleFilled, UpCircleFilled} from "@ant-design/icons/lib";
import {common} from "../../common/common";
import {Air} from "../air/Air";

export class LoginCorner extends Component {
  state = {
    focus: false
  }
  render () {
    return (
        <div style={{position : "fixed", right : 30, bottom : 60, zIndex : 10,display : "flex", flexDirection: "column"}}>
            {/*回到顶部*/}
            <UpCircleFilled
                style={{color : common.color.primary, fontSize: 45, boxShadow : "3px 3px 10px rgba(0,0,0,0.5)", borderRadius: "100%", cursor : "pointer"}}
                onClick={()=>{
                    common.toTop(true)
                }}
            />
            <Air vertical={5}/>
            <Popover placement={"topRight"} content={
                <div style={{display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding : "0px 20px"
                }}>
                    <div>遇到问题吗</div>
                    <div style={{fontSize: '12px', color: 'rgba(0,0,0,1)', opacity: '0.4', marginTop: '8px'}}>请打开微信扫描客服二维码</div>
                    <img src={qrcode} style={{maxWidth : 120, maxHeight : 120}} />
                    <div style={{fontSize: '12px', color: 'rgba(0,0,0,1)', opacity: '0.4', marginTop: '8px'}}>或者电话联系我们的客服</div>
                    <div style={{fontSize: '12px', color: 'rgba(0,0,0,1)', opacity: '0.4', marginTop: '8px'}}>联系方式 19921530436</div>
                    <div style={{fontSize: '12px', color: 'rgba(0,0,0,1)', opacity: '0.4', marginTop: '8px'}}>工作日  09:00-18:00</div>
                </div>
            } trigger={["hover"]}>
                <QuestionCircleFilled
                    style={{color : common.color.primary, fontSize: 45, boxShadow : "3px 3px 10px rgba(0,0,0,0.5)", borderRadius: "100%", cursor : "pointer"}}
                />
            </Popover>
        </div>
    )
  }
}
