/**
 * 地理位置
 * Create by liaokai on 2020-06-24 11:03:33
 */
import * as React from 'react';
import {Component} from "react";
import {Chart, Interval, Tooltip} from "bizcharts";
import {
    geographicPosition,
    IgeographicPositionItem
} from "../../../../../../../../common/apis/apis/live/geographicPosition";
import {liveStore} from "../../../live.store";
import {Empty} from "antd";

interface IProps{

}

interface IState{
    data : IgeographicPositionItem[]
}

const defaultData = [{
    title: '北京',
    num: 100
}];

export class AccessLocation extends Component<IProps,IState>{
    readonly state : IState = {
        data : []
    };

    componentDidMount() {
        geographicPosition({
            live_id : liveStore.live_id
        }).then(res => {
            const tempData = res.length > 0 ? res : defaultData;
            this.setState({
                data : tempData
            })
        })
    }

    render() {
        let {} = this.props;
        let {data} = this.state;
        return (
            <div>
                {
                    data?.length ?
                        <Chart height={250} autoFit data={data.map(value => ({
                            provinces : value.title,
                            "观看次数" : value.num
                        }))} interactions={['active-region']} padding={[30, 30, 30, 50]} >
                            <Interval position="provinces*观看次数" />
                            <Tooltip shared />
                        </Chart>
                        :
                        <Empty description={"暂无内容"}/>
                }
            </div>
        );
    }
}
