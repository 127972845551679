/**
 * 观看终端
 * Create by liaokai on 2020-06-23 18:49:56
 */
import * as React from 'react';
import {Component} from "react";
import {
    Chart,
    Interval,
    Tooltip,
    Axis,
    Coordinate
} from 'bizcharts';
import moment from "moment";
import {Air} from "../../../../../../../../components/air/Air";
import {Flex1} from "../../../../../../../../components/style/flex/flex-1";
import {Empty, Table} from "antd";
import {Flex} from "../../../../../../../../components/style/flex/flex";
import {IDocIndex} from "../../../../../../../../common/apis/apis/live/docIndex";
import {liveStore} from "../../../live.store";
import {ITerminal, terminal} from "../../../../../../../../common/apis/apis/live/terminal";

interface IProps{

}

interface IState{
    columns : any[]
    dataSource : ITerminal[],
    currentPage : number,
    total : number,
    pageSize: number,
    loading : boolean,
}

const cols = {
    num: {
        formatter: (val: any) => {
            // val = val * 100 + '%';
            return val;
        },
    },
};
const  data = [{
    'title':'手机',
    'num':1,
    'percentage':50,
},{
    'title':'电脑',
    'num':1,
    'percentage':50,
}];
export class Terminal extends Component<IProps,IState>{
    readonly state : IState = {
        columns : [
            {
                title: '观看方式',
                dataIndex: 'title',
                key: 'title',
                render: (text : boolean) => text,
            },

            {
                title: '直播观看次数',
                dataIndex: 'live_count',
                key: 'live_count',
                render: (text: string) => text,
            },
            {
                title: '回放观看次数',
                dataIndex: 'record_count',
                key: 'record_count',
                render: (text: string) => text,
            },
            {
                title: '总观看次数',
                dataIndex: 'num',
                key: 'num',
                render: (text: string) => text,
            },
            {
                title: '总观看次数占比',
                dataIndex: 'percentage',
                key: 'percentage',
                render: (text: string) => text+"%",
            },
        ],
        dataSource : [],
        pageSize : 5,
        total : 0,
        currentPage : 1,
        loading : false,
    };

    reqTableData(page : number = 1){
        this.setState({loading : true})
        terminal({
            live_id : liveStore.live_id
        }).then(res => {
            this.setState({
                dataSource : res
            })
        }).finally(()=>{
            this.setState({loading : false})
        })
    }

    refreshPage = ()=>{
        this.reqTableData(this.state.currentPage)
    }

    componentDidMount() {
        terminal({
            live_id : liveStore.live_id
        }).then(res => {
            this.setState({
                dataSource : res
            })
            console.log(res)
        })
    }

    render() {
        let {} = this.props;
        let {columns, dataSource, pageSize, total, currentPage, loading} = this.state;
        return (
            <Flex>
                {
                    dataSource.length>0 && (dataSource[0].num>0 || dataSource[1].num>0) ?
                        <Chart width={500} height={200} data={dataSource} scale={cols} autoFit>
                            <Coordinate type="theta" radius={0.75} />
                            <Tooltip showTitle={false} />
                            <Axis visible={false} />
                            <Interval
                                position="num"
                                adjust="stack"
                                color="title"
                                style={{
                                    lineWidth: 1,
                                    stroke: '#fff',
                                }}
                                label={['num', {
                                    content: (data) => {
                                        return `${data.title}: ${data.percentage}%`;
                                    },
                                }]}
                            />
                        </Chart>
                        :
                        <Chart width={500} height={200} data={data} scale={cols} autoFit>
                            <Coordinate type="theta" radius={0.75} />
                            <Tooltip showTitle={false} />
                            <Axis visible={false} />
                            <Interval
                                position="num"
                                adjust="stack"
                                color="title"
                                style={{
                                    lineWidth: 1,
                                    stroke: '#fff',
                                }}
                                label={['num', {
                                    content: (data) => {
                                        return `${data.title}: ${data.percentage}%`;
                                    },
                                }]}
                            />
                        </Chart>
                        // <Empty style={{width : 400}} description={"暂无图表"}/>
                }
                <Air horizontal={10}/>
                <Flex1>
                    <Table
                        size={"small"}
                        loading={loading}
                        columns={columns}
                        dataSource={dataSource}
                        // emptyText={"暂无图表"}
                        pagination={{
                            defaultCurrent : 1,
                            current : currentPage,
                            pageSize : pageSize,
                            total : total,
                            onChange : page => {
                                // this.reqTableData(page);
                                // this.setState({currentPage : page})
                            }
                        }}
                    >
                    </Table>
                </Flex1>
            </Flex>
        );
    }
}
