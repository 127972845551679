import { toPost } from '../../../../../../../../common/apis/http-utils';
import { FetchField } from '../types';

export const formatListResult = (result: any): any => {
  const mergeResult = result;
  return {
    list: mergeResult.list.data,
    total: mergeResult.list.count_num,
  };
};

// export const merchantUserIndex = toPost<FetchField.List, any>(
//   `/merchant/Users/index`
// );

export const merchantUserIndex = toPost<any, any>(`/merchant/users/userList`);

export const merchantUserGetHeaderFields = toPost<{ meetid: string }, any>(
  `/merchant/Users/getHeaderFields`
);

export const merchantUserDelUsers = toPost<{ uid: string }, any>(
  `/merchant/Users/delUsers`
);

export const merchantUserMakeTag = toPost<{ uid: string; mtaid: string }, any>(
  `/merchant/meeting/makeTag`
);

export const merchantUserGetMeetingTag = toPost<{ meetid: string }, any>(
  `/merchant/meeting/getMeetingTag`
);

export const merchantUserDleMeetingTag = toPost<{ id: string }, any>(
  `/merchant/meeting/dleMeetingTag`
);

export const merchantUserSetMeetingTag = toPost<
  { meetid: string; name: string; id: string },
  any
>(`/merchant/meeting/setMeetingTag`);

export const merchantUserGetUserinfo = toPost<{ uid: string }, any>(
  `/merchant/Users/getUserinfo`
);

export const merchantUserTagStatistics = toPost<{ meetid: string }, any>(
  `/merchant/Users/statistics`
);

export const merchantUserGetUserAdd = toPost<
  { meetid: string; fields: string; tagid: string; uid: string },
  any
>(`/merchant/Users/add`);

export const merchantUserGetUserDownloadTemplate = toPost<
  { meetid: string },
  any
>(`/merchant/Users/downloadTemplate`);

export const merchantUserGetUserImportUser = toPost<
  { meetid: string; file: any },
  any
>(`/merchant/Users/importUser`);

export const merchantUserGetUserImportUserSave = toPost<
  { meetid: string; tag: string; user: string },
  any
>(`/merchant/Users/importUserSave`);

export const getArea = toPost<
  any,
  any
  >(`/api/api/getArea`);

  
export const getAreaCity = toPost<
  any,
  any
>(`/api/api/getAreaCity`);

export const getEducation = toPost<any, any>(`/api/api/getEducation`);

export const getSubjectSpecialty = toPost<any, any>(`/api/api/getSubjectSpecialty`);

export const getHospital = toPost<any, any>(`/api/api/getHospital`);

export const getTechnical = toPost<any, any>(`/api/api/getTechnical`);

export const getUserInfoDetail = toPost<any, any>(`/merchant/meeting/getUserInfo`);

