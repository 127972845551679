/**
 * 头像
 * Create by liaokai on 2020-03-13 16:26:20
 */
import * as React from 'react';
import { Component } from "react";
import style from "./header-icon.scss";

interface IProps {
    src: string,
    alt?: string
}

interface IState {

}

export class HeaderIcon extends Component<IProps, IState>{
    //state初始化
    readonly state: IState = {

    };
    constructor(props: IProps) {
        super(props);
    }
    render() {
        let { src, alt } = this.props;
        let { } = this.state;
        return (
            <div className={style.imgBox} style={{ backgroundImage: `url(${src})` }}>
                {/* <img className={style.img} src={src} alt={alt} /> */}
            </div>
        );
    }
}
