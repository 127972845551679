/**
 * 富文本编辑器
 * Create by liaokai on 2020-06-09 16:56:01
 */
import * as React from 'react';
import {Component} from "react";
import BraftEditor, {EditorState} from "braft-editor";
import {api} from "../../common/app";

interface IProps{
    value : string,
    onChange : (value: EditorState) => void
    placeholder? : string
}

interface IState{

}

export class RichTextBoxV2 extends Component<IProps,IState>{
    readonly state : IState = {

    };

    uploadFile = (param : any) => {
        let formdata = new FormData();
        formdata.append('file', param.file);
        formdata.append('type', 'img');
        (api as any).UploadFile(formdata, (progressEvent : any) => {
            let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
            param.progress(complete);
        }).then((res: any) => {
            if (res.code === 200) {
                param.success({
                    url: res.result,
                    meta: {
                        // id: res.title,
                        // title: res.title,
                        // alt: res.title,
                    }
                });
            } else {
                param.error({
                    msg: res.message
                });
            }
        })
    }

    render() {
        let {value, onChange, placeholder} = this.props;
        let {} = this.state;
        return (
            <BraftEditor
                value={value}
                onChange={onChange}
                placeholder={placeholder ?? '点击此处编辑项目介绍内容'}
                media={{
                    uploadFn: this.uploadFile
                }}
            />
        );
    }
}
