import React, { Component } from 'react'
import style from './PhoneSet.scss'
import {api} from '../../../../../../common/app'
import phoneicon from '../../../../../../assets/phoneicon.png'
import lockicon from  '../../../../../../assets/lockicon.png'
import shellicon from '../../../../../../assets/shellicon.png'
import {message} from "antd";

let getVerifyTime;
export class PhoneSet extends Component {
constructor(props) {
  super(props);
  this.state = {
    oldpassword :'',
    mobile :'',
    password :'',
    verifycode :'',
    getCutdownTime:60,
  };
  this.refreshProps = this.refreshProps.bind(this);
  this.HandleInputChange = this.HandleInputChange.bind(this);
  this.getVerifycode = this.getVerifycode.bind(this);
  this.changeUserPhone = this.changeUserPhone.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
}
refreshProps(props) {

}
changeUserPhone(){
    if (this.state.oldpassword&&this.state.mobile&&this.state.password&&this.state.verifycode) {
        api.updateUserPhone(this.state.oldpassword,this.state.mobile,this.state.password,this.state.verifycode).then(res=>{
            if (res.code === 200) {
                message.success(res.message);
                window.location.reload();
            }else{
                message.error(res.message);
            }
        },err=>{

        })
    }else{
        message.error('请确保信息填写完整')
        return ;
    }

}
HandleInputChange(type,e){
    this.state[type] = e.target.value;
    this.setState(this.state);
}
getVerifycode(){
    if (!this.state.mobile) {
        message.error('请先填写接收验证码的手机号！')
        return;
    }
    api.updateImportantInfoVerify(this.state.mobile).then(res=>{
        if (res.code === 200) {
            this.state.getCutdownTime-=1;
            this.setState(this.state);
            getVerifyTime = setInterval(() => {
                this.state.getCutdownTime = this.state.getCutdownTime - 1;
                this.setState(this.state);
                if (this.state.getCutdownTime<=0) {
                    clearInterval(getVerifyTime);
                    this.state.getCutdownTime = 60;
                    this.setState(this.state);
                }
            }, 1000);
            message.success(res.message);
        }else{
            message.error(res.message);
        }


    },err=>{
        console.log(err);

    })
}
componentWillUnmount(){
    clearInterval(getVerifyTime);
}
render() {
  return (
    <div className={style.SettingBox}>
        <div className={style.SettingTitle}>原账号确认</div>
        {/* <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
            <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                <img src={phoneicon} alt=""/>
            </div>
            <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                    旧手机号
                </div>
                <div className={style.Inputs}>
                    <input type="text" placeholder={'请填写旧的手机号'}/>
                </div>
            </div>
        </div> */}
        <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
            <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                <img src={lockicon} alt=""/>
            </div>
            <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                    旧密码
                </div>
                <div className={style.Inputs}>
                    <input type="password" placeholder={'请填写旧密码'} value={this.state.oldpassword}  onChange={this.HandleInputChange.bind(this,'oldpassword')}/>
                </div>
            </div>
        </div>
        <div className={style.SettingTitle}>新账号设置</div>
        <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
            <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                <img src={phoneicon} alt=""/>
            </div>
            <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                    新手机号
                </div>
                <div className={style.Inputs}>
                    <input type="text" placeholder={'请填写新的手机号'} value={this.state.mobile}  onChange={this.HandleInputChange.bind(this,'mobile')}/>
                </div>
            </div>
        </div>
        <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
            <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                <img src={shellicon} alt=""/>
            </div>
            <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                    短信验证码
                </div>
                <div className={style.PhoneCodeInput}>
                    <input type="text" placeholder={'请填写短信验证码'} value={this.state.password}  onChange={this.HandleInputChange.bind(this,'password')}/>
                </div>
                <div className={[style.SendCode,'childcenter'].join(' ')} onClick={this.state.getCutdownTime === 60?this.getVerifycode:()=>{}}><span>{this.state.getCutdownTime === 60?'获取验证码':this.state.getCutdownTime+'s'}</span></div>
            </div>
        </div>
        <div className={[style.SettingRow,'childcenter childcontentstart'].join(' ')}>
            <div className={[style.SettingIcon,'childcenter'].join(' ')}>
                <img src={lockicon} alt=""/>
            </div>
            <div className={[style.SettingInputBox,'childcenter childcontentstart'].join(' ')}>
                <div className={[style.InputTitle,'childcenter childcontentstart'].join(' ')}>
                    新密码
                </div>
                <div className={style.Inputs}>
                    <input type="password" placeholder={'8-16位，必须包含数字和字母'} value={this.state.verifycode}  onChange={this.HandleInputChange.bind(this,'verifycode')}/>
                </div>
            </div>
        </div>
        <div className={[style.HandleStatusGroup,'childcenter'].join(' ')}>
            <div className={[style.StatusButton,style.bluebutton,'childcenter'].join(' ')} onClick={this.changeUserPhone}>确定</div>
            <div className={[style.StatusButton,style.graybutton,'childcenter'].join(' ')} onClick={this.props.onClose}>取消</div>
        </div>
    </div>
   )
   }
}
export default PhoneSet
