/**
 * 基本信息-路由
 * Create by liaokai on 2020-04-17 10:20:00
 */
import * as React from 'react';
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { SurfaceRouter, tSurfaceRouteItem } from "../../../../../../components/surface-router/surface-router";
import { MeetingOverview } from "../overview/overview";
import { MeetingInfo } from "./meeting-info";

interface IProps extends RouteComponentProps {

}

interface IState {

}

const routerData: tSurfaceRouteItem = {
    breadTitle: "基本信息",
    routeName: "",
    component: MeetingInfo,
    children: []
}

export class MeetingInfoRouter extends Component<IProps, IState>{
    readonly state: IState = {

    };
    render() {
        let { } = this.props;
        let { } = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={routerData} />
        );
    }
}
