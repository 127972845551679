// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "body{margin:0;padding:0;font-family:sans-serif}ul,ol,ul li,ol li{list-style:none !important}body,div,ul,li,ol,h1,h2,h3,h4,h5,h6,input,textarea,select,p,dl,dt,dd,a,img,button,form,table,th,tr,td,tbody,article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,span,section{margin:0;padding:0;border:none}div,p,a{color:unset;text-decoration:none !important}*{font-family:\"Source Han Sans CN\",\"微软雅黑\",\"Microsoft YaHei\" !important}a span{font-family:unset !important}html,body{width:100%;top:0px;left:0px;margin:0 auto;-webkit-tap-highlight-color:transparent;font-family:\"Source Han Sans CN\",\"微软雅黑\",\"Microsoft YaHei\" !important}html.index_modal-open__31ciZ,body.index_modal-open__31ciZ{position:fixed;width:100%}.childcenter{display:flex;justify-content:center;align-items:center}.childcolumn{flex-direction:column}.childrow{flex-direction:column}.childcontentstart{justify-content:flex-start}.childcontentend{justify-content:flex-end}.childalignstart{align-items:flex-start}.childalignend{align-items:flex-end}\n", ""]);
// Exports
exports.locals = {
	"modal-open": "index_modal-open__31ciZ"
};
module.exports = exports;
