/**
 * 获取会议信息总览
 * Create by liaokai on 2020-05-12 11:11:30
 */
import {toPost} from "../http-utils";

export interface IMeetingPandect {
    "id":number,
    "title":string,
    "start_time":string,
    "end_time":string,
    "province":string,
    "city":string,
    "area":string,
    "address":string,
    "longitude":string,
    "latitude":string,
    "cover":string,
    "reg_num":number,
    "sign_num":number,
    "meeting_status":string,
    "strat_time_week":string,
    "end_time_week":string
}

export const getMeetingPandect = toPost<
    {
        id : string
    },
    IMeetingPandect
>("/merchant/meeting/pandect");

