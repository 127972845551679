import { action, observable } from "mobx";

class Detail {
  @observable
  gusetDetail: any = {}

  @observable
  field: any = {}

  @action.bound
  public setDetail(data: any): void {
    this.gusetDetail = data;
  }

  @action.bound
  public setField(data: any): void {
    this.field = data;
  }
}

export const guestStore = new Detail();