/**
 * 邮件管理系统
 * Create by liaokai on 2020-07-03 12:05:56
 */
import * as React from 'react';
import {Component} from "react";
import {Responsive} from "../../../components/common/responsive";
import {MenuContent} from "../../../components/menu-content/menu-content";
import {tMenuVerticalTreeWithComp} from "../user/UserIndexView";
import {EmailOverview} from "./menu/overview/overview-router";
import {common} from "../../../common/common";
import {apis} from "../../../common/apis/apis";
import {RouteComponentProps} from "react-router";
import {Air} from "../../../components/air/Air";
import {PersonnelRouter} from "./menu/personnel/personnel-router";
import {EmailRecordRouter} from "./menu/record/record-router";
import {EmailSend} from "../meeting-list/meeting/menu/email-multi-send/multi-send/email-send";

interface IProps extends RouteComponentProps{

}

interface IState{
    menuData : tMenuVerticalTreeWithComp
}

const menuItemsRaw : tMenuVerticalTreeWithComp = [
    {
        title : "邮件",
        items : [
            {
                title : "总览",
                routerName : "overview",
                plusField : {
                    component : EmailOverview
                }
            },
            {
                title : "人员管理",
                routerName : "personnel",
                plusField : {
                    component : PersonnelRouter
                }
            },
            {
                title : "新建群发",
                routerName : "newEmailSend",
                plusField : {
                    component : EmailSend
                }
            },
            {
                title : "发送记录",
                routerName : "record",
                plusField : {
                    component : EmailRecordRouter
                }
            },
        ]
    },
];


export class Email extends Component<IProps,IState>{
    //从菜单树过滤 hidden === true，且不在 openRouterNames 白名单上的节点
    static openMenu(tree : tMenuVerticalTreeWithComp, openRouterNames : string[]) : tMenuVerticalTreeWithComp{
        return common.deepClone(tree).map(value => {
            // 仅隐藏隐藏的菜单项（刷新页面会仍在原页面）
            value.items.forEach(value1 => {
                if(openRouterNames.filter(value2 => value2 === value1.routerName).length > 0){
                    value1.hidden = false
                }
            })
            return value;
        })
    }

    readonly state : IState = {
        menuData : Email.openMenu(menuItemsRaw, [])
    };

    componentDidMount() {
        apis.getActiveModule({}).then(res => {
            this.setState({menuData : Email.openMenu(menuItemsRaw, res.map(value => value.routerName))});
        })
    }
    render() {
        const {} = this.props
        return (
            <Responsive>
                <Air vertical={20}/>
                <MenuContent
                    {...this.props}
                    menuData={this.state.menuData}
                    defaultRouterName={"overview"}
                />
            </Responsive>
        );
    }
}
