/**
 * 邮件群发
 * Create by liaokai
 */
import {toPost} from "../../http-utils";

export const emailSend = toPost<
    {
        id : number,
        meetid : number,
        type? : number,
        email_nick? : string,
        send_time? : string,
        template_id : number
    },
    {

    }
    >("/merchant/Email/send");
