/**
 * 间隔
 *  无任何内容，仅用于防止与其它组件之间，用于产生间距
 * Create by liaokai on 2020-03-09 17:33:11
 */
import * as React from 'react';
import {Component} from "react";

interface IProps{
    horizontal? : number,
    vertical? : number
}

interface IState{

}

export class Air extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {

    };
    constructor(props: IProps){
        super(props);
    }
    render() {
        let {vertical,horizontal} = this.props;
        let {} = this.state;
        return (
            <div style={{padding: `${vertical ?? 0}px ${horizontal ?? 0}px`}}/>
        );
    }
}
