/**
 * 登录
 * Create by liaokai on 2020-04-03 11:26:53
 */
import {toPost} from "../http-utils";

export const login = toPost<
    {
        mobile : string,        //电话号码
        password : string       //密码
    },
    {
        mobile : string,        //电话号码
    }
>
("/merchant/login/index");
