/**
 * 直播并发表格
 * Create by liaokai on 2020-07-19 17:34:09
 */
import {toPost} from "../../http-utils";
import {IConcurrenceChartItem} from "./concurrenceChart";

export const concurrenceTable = toPost<
    {
        live_id : string,
        page : number,
        paginate? : number
    },
    {
        "current_page": number,
        "total": number,
        "last_page": number,
        "data": IConcurrenceChartItem[]
    }
>("/merchant/live/concurrenceTable");
