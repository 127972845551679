/**
 * 邮件总览
 * Create by liaokai on 2020-07-03 13:15:14
 */
import * as React from 'react';
import {Component} from "react";
import {SurfaceRouter, tSurfaceRouteItem} from "../../../../../components/surface-router/surface-router";
import {RouteComponentProps} from "react-router";import {Personnel} from "./personnel";
import {EmailMultiSendDetail} from "../../../meeting-list/meeting/menu/email-multi-send/multi-send-detail/email-multi-send-detail";
import {UsersTable} from "./person-components/users-table";
import {Import} from "./person-components/import";
import BulkImport from "./bulk-import"

interface IProps extends RouteComponentProps<any>{

}

interface IState{

}

const routerData : tSurfaceRouteItem = {
    breadTitle : "人员管理",
    routeName : "",
    component : Personnel,
    children : [
        {
            breadTitle : "人员列表",
            routeName : "users/:address_book_id",
            component : UsersTable,
            children : [
                // {
                //     breadTitle : "批量添加",
                //     routeName : "import",
                //     component : Import,
                //     children : []
                // },
                {
                    breadTitle : "批量添加",
                    routeName : "bulk_import",
                    component : BulkImport,
                    children : []
                },
            ]
        },

    ]
}

interface IProps extends RouteComponentProps<any>{

}

interface IState{
}

export class PersonnelRouter extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={routerData}/>
        );
    }
}
