/**
 * 添加问答 - 添加选项
 * Create by liaokai on 2020-05-28 13:36:49
 */
import React, { useEffect } from "react";
import { Component } from "react";
import { FlexCol } from "../../../../../../../components/style/flex/flex-col";
import { Flex } from "../../../../../../../components/style/flex/flex";
import { Button, Checkbox, Input } from "antd";
import { Flex1 } from "../../../../../../../components/style/flex/flex-1";
import { CloseSquareOutlined, PlusOutlined } from "@ant-design/icons/lib";
import { Air } from "../../../../../../../components/air/Air";
import styled from "styled-components";
import {
    IQuestionStoreOpt,
    tQuestionType,
} from "../../../../../../../common/apis/apis/live/questionStore";

interface IProps {
    type?: tQuestionType;
    options: IQuestionStoreOpt[];
    onChange?: (options: IQuestionStoreOpt[]) => void;
    requireAnswer?: boolean;
}

interface IState {}

export function AddQuestionFormOptions(props: IProps) {
    const { onChange, options, type = "single", requireAnswer = true } = props;

    /**
     * 从多选改成单选的时候把选项重置成无答案
     */
    useEffect(() => {
        if (type === "single") {
            const argCp = options.filter(() => true);
            argCp.forEach((value) => {
                value.isAnswer = false;
            });
            onChange?.(argCp);
        }
    }, [type]);
    return (
        <FlexCol>
            {options.map((value, index, ary) => (
                <Item>
                    <Flex1>
                        <Input
                            placeholder={"请输入选项"}
                            value={value.value}
                            onChange={(e) => {
                                let aryCp = ary.filter(() => true);
                                aryCp[index].value = e.target.value;
                                aryCp[index].title = e.target.value;
                                onChange?.(aryCp);
                            }}
                        />
                    </Flex1>
                    <Air horizontal={10} />
                    {requireAnswer ? (
                        <Checkbox
                            checked={value.isAnswer}
                            onChange={(event) => {
                                let aryCp = ary.filter(() => true);
                                if (type === "single") {
                                    aryCp.forEach((value1) => {
                                        value1.isAnswer = false;
                                    });
                                    aryCp[index].isAnswer =
                                        event.target.checked;
                                } else {
                                    aryCp[index].isAnswer =
                                        event.target.checked;
                                }
                                onChange?.(aryCp);
                            }}
                        >
                            答案
                        </Checkbox>
                    ) : undefined}
                    <Button
                        type={"link"}
                        danger={true}
                        onClick={() => {
                            let aryCp = ary.filter(() => true);
                            aryCp.splice(index, 1);
                            onChange?.(aryCp);
                        }}
                    >
                        删除
                    </Button>
                </Item>
            ))}
            <div>
                <Button
                    type={"dashed"}
                    icon={<PlusOutlined />}
                    onClick={() =>
                        onChange?.([
                            ...options,
                            {
                                title: "",
                                value: "",
                                isAnswer: false,
                            },
                        ])
                    }
                >
                    添加选项
                </Button>
            </div>
        </FlexCol>
    );
}

const Item = styled(Flex)`
    margin-bottom: 7px;
    align-items: center;
`;
