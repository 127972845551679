import {getUserInfo} from "../apis/apis/get-user-info";

export default async () : Promise<boolean> => {
    try {
        await getUserInfo({});
        return true;
    }catch (e) {
        return false
    }
};
