/**
 * 新建/编辑 课件
 * Create by liaokai on 2020-05-25 17:57:21
 */
import * as React from 'react';
import {Component} from "react";
import {Input, InputNumber, message, Modal,Row, Upload} from "antd";
import {TitleContentTable} from "../../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import {FilePptOutlined} from "@ant-design/icons/lib";
import {CheckCircleOutlined} from "@ant-design/icons/lib";
import {Air} from "../../../../../../../components/air/Air";
import {Flex} from "../../../../../../../components/style/flex/flex";
import {LoadingSingleFile} from "../../../../../../../components/loading-single-file";
import {FlexCol} from "../../../../../../../components/style/flex/flex-col";
import {FlexColHorVerCenter} from "../../../../../../../components/style/flex/flex-col-hor-ver-center";
import styled from "styled-components";
import {liveStore} from "../../live.store";
import {uploadFile} from "../../../../../../../common/apis/apis/upload-file";
import {coursewareShow} from "../../../../../../../common/apis/apis/live/coursewareShow";


interface IProps{
    show? : boolean,
    docId? : string //不传为新建
    onCancel? : () => void,
    onSubmit? : ()=>void
}

interface IState{
    updating : boolean,
    title : string,
    credit : any,
    filePath : string
}

export class LiveDocDownloadEdit extends Component<IProps,IState>{
    readonly state : IState = {
        updating : false,
        title : "",
        credit : 0,
        filePath : ""
    };

    componentDidMount() {
        const {docId} = this.props
        if(docId){
            coursewareShow({
                live_courseware_id : docId
            }).then(res => {
                this.setState({
                    title : res.title,
                    credit : res.credit,
                    filePath : res.file
                })
            })
        }
    }

    render() {
        let {docId,show,onCancel, onSubmit} = this.props;
        let {updating, title , credit, filePath} = this.state;
        return (
            <Modal
                visible={show}
                title={docId ? "编辑课件" : "新建课件"} onCancel={()=>{
                    onCancel?.();
                }}
                maskClosable={!updating}
                okText={docId ? "保存" : "添加"}
                cancelText={"取消"}
                okButtonProps={{disabled : updating}}
                onOk={()=>{
                    let formdata = new FormData();
                    if(docId){
                        formdata.append('file',filePath);
                        formdata.append('id', docId);
                        formdata.append('title', title);
                        formdata.append('credit', credit.toString());
                    }else{
                        formdata.append('file',filePath);
                        formdata.append('live_id',liveStore.live_id);
                        formdata.append('title', title);
                        formdata.append('credit', credit.toString());
                    }
                    this.setState({updating : true})
                    uploadFile(formdata, docId ? "/merchant/live/coursewareUpdate" : "/merchant/live/coursewareStore").then(res => {
                        message.success("保存完成");
                        onSubmit?.()
                        onCancel?.()
                    }).catch(()=>{

                    }).finally(()=>{
                        this.setState({updating : false})
                    })
                }}
            >
                <TitleContentTable rowGap={10} colGap={10}>
                    <div>课件名称</div>
                    <div>
                        <Input placeholder={"请输入课件名称"} value={title} onChange={e => {
                            this.setState({title : e.target.value})
                        }}/>
                    </div>
                    {/*<div>所需积分</div>*/}
                    {/*<Flex>*/}
                    {/*    <InputNumber value={credit} onChange={e => {*/}
                    {/*        this.setState({credit : e ?? 0})*/}
                    {/*    }} min={0} style={{flex : 1}} placeholder={"请输入积分"}/>*/}
                    {/*</Flex>*/}
                    <div style={{alignSelf : "flex-start"}}>
                        <div>课件文件</div>
                    </div>
                    <Uploader>
                        <FlexColHorVerCenter>
                            <LoadingSingleFile onLoadFile={async file => {
                                const self = this
                                self.setState({updating : true})
                                let formdata = new FormData();
                                formdata.append('file', file);
                                formdata.append('type','other');
                                uploadFile(formdata).then(res => {
                                    self.setState({filePath : res})
                                }).catch(e => {
                                    message.error("上传失败 "+ e.data?.message)
                                }).finally(()=>{
                                    self.setState({updating : false})
                                })
                                return true
                            }}>
                                <FlexCol>
                                    <FilePptOutlined style={{fontSize : 50}} />
                                    <Air vertical={5}/>
                                    {
                                        updating ? "正在上传" :
                                            filePath ? upLoadSuccess() :
                                                <div>请上传文件</div>
                                    }
                                </FlexCol>
                            </LoadingSingleFile>
                        </FlexColHorVerCenter>
                    </Uploader>
                </TitleContentTable>
            </Modal>
        );
    }
}

// 文件上传成功
function upLoadSuccess() {
    return (
        <div>
            <Row justify="center">
                <CheckCircleOutlined style={{color:'green'}} />
            </Row>
            <h4>文件上传成功!</h4>
        </div>
    )
}


const Uploader = styled(FlexCol)`
  border: 1px dashed;
  padding: 25px 0px;
  cursor: pointer;
`
