import React, { Component } from 'react'
import style from './ChargeDetailView.scss'
import HellView from '../../../../../components/AlertBox/BaseAlert'
import PageBurster from '../../../../../components/PageBurster'
import ReactTable from "react-table";
import {api} from '../../../../../common/app'

const zh_key={
    username:'用户姓名',
    mobile:'联系方式',
    type:'付款方式',
    pay_time:'付款时间',
    money:'付款金额',
}

export class ChargeDetailView extends Component {
constructor(props) {
  super(props);
  this.state = {
      id:null,
      listpage:1,
      totalpage:1,
      meetinginfo:null,
      datacolumn:null,
  };
     this.refreshProps = this.refreshProps.bind(this);
     this.getCharegeDetail = this.getCharegeDetail.bind(this);
     this.onPageBusterChange = this.onPageBusterChange.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
  this.InitTableHead();
}
refreshProps(props) {
    let params = props.match.params;
    if (this.state.id != params.detail_id) {
      this.state.id = params.detail_id;
      this.setState(this.state);
      this.getCharegeDetail(params.detail_id);
    }
}
getCharegeDetail(id){
    api.getMeetingProfitDetail(id,this.state.listpage,12).then(res=>{
        if (res.code === 200) {
            this.state.meetinginfo = res.result.meeting;
            this.state.totalpage = res.result.count_page;
            this.state.order = res.result.order;
            this.setState(this.state);
        }else{
            HellView.error({
                message:res.message
            });
        }
    },err=>{
        console.log(err);

    })

}
InitTableHead(){
    let datacolumn = [];
    for (const key in zh_key) {
        if (zh_key.hasOwnProperty(key)) {
            const element = zh_key[key];
            datacolumn.push({
                Header: <div className={[style.HeadCotnent,'childcenter childcontentstart'].join(' ')}>{element}</div>,
                accessor: key,
                Cell:props=>{
                    return <div key={props.index+'guestdata'+ props.index} className={[style.ListChild,'childcenter childcontentstart'].join(' ')}>{props.value}</div>
                },
                resizable:false,
                width:key ==='paytime'?200:undefined,
                headerClassName:style.ListHead,
            })
        }
    }
    this.state.datacolumn = datacolumn;
    this.setState(this.state);
}
onPageBusterChange(page){
    this.state.listpage = page;
    this.getCharegeDetail(this.state.id);
    this.setState(this.state);
}
render() {
  return (
    <div className={style.ViewBox}>
        <div className={style.ChargeDetail}>

            {this.state.meetinginfo?<div className={[style.MeetingChargeBox,'childcenter childcontentstart'].join(' ')}>
                <div className={style.MeetingImage}>
                    <img src={this.state.meetinginfo.cover} alt=""/>
                </div>
                <div className={[style.MeetingTextInfo,'childcenter childcolumn'].join(' ')}>
                    <div className={style.MeetingName}>{this.state.meetinginfo.title}</div>
                    <div className={style.MeetingOtherInfo}>
                        <span>{this.state.meetinginfo.city}</span> <span>{this.state.meetinginfo.start_time}</span>
                    </div>
                </div>
                <div className={[style.ChargeNum,'childcenter'].join(' ')}>
                    <div className={[style.MeetingChargeValue,'childcenter'].join(' ')}>
                        ¥{this.state.meetinginfo.count_aumont}
                    </div>
                </div>
            </div>:''}
            <div className={style.DetailListBox}>
                {this.state.datacolumn?<div className={style.ListBody}>
                    <ReactTable
                        data={this.state.order}
                        columns={this.state.datacolumn}
                        showPagination={false}
                        minRows={12}
                        NoDataComponent={() => {return <div className={[style.NoDataTips,'childcenter'].join(' ')}>暂时没有收益记录</div>}}
                        style={{border:'none'}}
                    />
                </div>:''}
            </div>
            {/* 分页器 */}
            <div className={style.Burster}>
                <PageBurster nowpage={this.state.listpage} max={this.state.totalpage} onPageChange={this.onPageBusterChange}/>
            </div>
        </div>
    </div>
   )
   }
}
export default ChargeDetailView
