/**
 * 签到管理
 * Create by liaokai on 2020-04-27 13:55:35
 */
import * as React from 'react';
import {Component} from "react";
import style from "./signin-view.scss"
import {SigninViewGridItemStateless} from "./grid-item/signin-view-grid-item.stateless";
import {getMeetingHallInfo, IMeetingHallInfoItem} from "../../../../../../../common/apis/apis/getMeetingHallInfo";
import {apis} from "../../../../../../../common/apis/apis";
import {RouteComponentProps} from "react-router";
import {deleteMeetingHall} from "../../../../../../../common/apis/apis/deleteMeetingHall";
import {Button, message} from "antd";
import SetHallNameList from "./SetHallNameList/SetHallNameList";
import {NewVenue} from "./new-venue/new-venue";
import {Link} from "react-router-dom";
import {liveStore} from "../../../../../live-list/live/menu/live.store";
import {FlexJustifyAlignCenter} from "../../../../../../../components/style/flex/flex-justify-align-center";
import {VideoCameraOutlined} from "@ant-design/icons/lib";
import {FlexAlignCenter} from "../../../../../../../components/style/flex/flex-align-center";

interface IProps extends RouteComponentProps<{meeting_id : string}>{

}

interface IState{
    listData : IMeetingHallInfoItem[],
    setNameList : boolean,
    detailId : number
}

export class SignInView extends Component<IProps,IState>{
    readonly state : IState = {
        listData : [],
        setNameList : false,
        detailId : -1
    };

    refreshList(){
        apis.getMeetingHallInfo({meetid : this.props.match?.params.meeting_id}).then(res => {
            this.setState({listData : res})
        })
    }

    componentDidMount() {
        this.refreshList();
    }

    render() {
        let {history, location, match} = this.props;
        let {listData, setNameList, detailId} = this.state;
        const pathname = location?.pathname;
        const meeting_id = match?.params?.meeting_id
        return (
            <div className={style.container}>
                {/*新建会场*/}
                <div className={style.header}>
                    <NewVenue onConfirm={(meeting_name)=>{
                        apis.setNewHall({
                            id : undefined,
                            meetid : parseInt(meeting_id),
                            title : meeting_name
                        }).then(res => {
                            message.success("新建完成");
                            this.refreshList();
                        }).catch(e => {
                            message.error("新建失败 " + e?.data?.message)
                        })
                    }}/>
                </div>
                {/*网格列表*/}
                <div className={style.grid}>
                    {listData?.map(value => {
                        return <SigninViewGridItemStateless
                            title={value.title}
                            signInNum={value.signIn_num.toString()}
                            totalNum={value.user_num.toString()}
                            onClickSetVenueMembers={()=>{
                                this.setState({setNameList : true, detailId : value.id})
                            }}
                            onClickSignInScan={()=>{
                                window.open(`#/scanner/${value.id}`)
                                //   return   <Link to={`#/scanner/${value.id}`} target={"_blank"}>
                                //     <FlexJustifyAlignCenter>
                                //     <Button type={"primary"} icon={<VideoCameraOutlined />}>qq</Button>
                                // </FlexJustifyAlignCenter>
                                // </Link>
                            }}
                            onConfirmDelete={()=>{
                                apis.deleteMeetingHall({id : value.id}).then(res => {
                                    message.success("删除完成")
                                    this.refreshList();
                                }).catch(e => {
                                    message.error("删除失败 " + e?.data?.message)
                                })
                            }}
                            onSeeDetail={()=>{
                                history.push(`${pathname}/detail/${value.id}`)
                            }}
                        />
                    })}
                </div>
                {/*模态框-设置会场人员*/}
                {
                    setNameList ?
                        <SetHallNameList onClose={()=>{this.setState({setNameList : false})}} meetingid={meeting_id} id={detailId}/>
                    : undefined
                }
            </div>
        );
    }
}
