// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".new-venue_newMeetingBtnBox__3IpK1{display:flex;justify-content:space-around;margin-top:20px}\n", ""]);
// Exports
exports.locals = {
	"newMeetingBtnBox": "new-venue_newMeetingBtnBox__3IpK1"
};
module.exports = exports;
