/**
 * 封面图
 * Create by liaokai on 2020-05-25 10:44:54
 */
import * as React from 'react';
import {Component, CSSProperties} from "react";
import {CheckOutlined} from "@ant-design/icons/lib";
import {Badge, Card, Empty} from "antd";
import {eMeetingListSearchRange, tMeetingListDataItem} from "../common/apis/apis/get-meeting-list";
import {common} from "../common/common";
import {Ratio} from "./ratio/ratio";
import moment from "moment";

interface IProps{
    cover? : string             //图片封面
    stateView? : JSX.Element    //左上角状态
    actionView? : JSX.Element   //hover时下方操作
    title?: JSX.Element | string    //标题
    time? : JSX.Element | string    //左下
    count?: JSX.Element | string    //右下

    select? : "unselect" | "selected" | "none",
    cardStyle? : CSSProperties,     //card样式
    selectColor? : string       //选中的图标颜色
}

interface IState{
    showActionView : boolean
}

export class MetaCard extends Component<IProps,IState>{
    readonly state : IState = {
        showActionView : false
    };
    render() {
        let {cover, stateView, time, count, title,select,cardStyle,selectColor,actionView} = this.props;
        let {showActionView} = this.state;
        return (
            <Badge
                count={
                    ((select)=> {
                        switch (select) {
                            case "none":
                                return undefined;
                            case "selected":
                                return <div style={{border: `2px ${selectColor} solid`, borderRadius: "100%", padding: "5px", color: "#aaa", backgroundColor: "#fff"}}>
                                    <div style={{width : 15, height : 15, backgroundColor: "#fff" ,display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <CheckOutlined style={{color : selectColor}}/>
                                    </div>
                                </div>;
                            case "unselect":
                                return <div style={{border: "2px #ddd solid", borderRadius: "100%", padding: "5px", color: "#aaa", backgroundColor: "#fff"}}>
                                    <div style={{filter: "opacity(0)", width : 15, height : 15, backgroundColor: "#fff",display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <CheckOutlined />
                                    </div>
                                </div>
                        }
                    })(select)
                }
            >
                <Card
                    hoverable
                    style={cardStyle ?? {}}
                    bodyStyle={{padding : 10}}
                    onMouseEnter={event => {
                        this.setState({showActionView : true})
                    }}
                    onMouseLeave={event => {
                        this.setState({showActionView : false})
                    }}
                >
                    <div>
                        {/*左上角*/}
                        <div
                            style={{
                                position: "absolute",
                                top : 0,
                                left : 0,
                                zIndex : 1
                            }}
                        >
                            {stateView}
                        </div>
                        {/*封面*/}
                        {
                            cover ?
                                <Ratio num={0.5625}>
                                    <img src={cover} alt="" style={{width : "100%",maxWidth: "100%", height: "100%", maxHeight : "100%"}}/>
                                </Ratio>
                                :
                                <Ratio num={0.5625}>
                                    <Empty description={"加载失败"}/>
                                </Ratio>

                        }
                        {/*title*/}
                        <div style={{margin : "10px 0px 3px 0px",position : "relative", height : "1.5em"}}>
                            <div style={{position : "absolute", left : 0, right : 0, top :0, bottom: 0}}>
                                <div style={{whiteSpace: "nowrap", textOverflow : "ellipsis", overflow : "hidden"}}>
                                    {title}
                                </div>
                            </div>
                        </div>
                        <div style={{fontSize : 12, color : "rgba(0,0,0,0.7)", fontWeight: 300}}>{count}</div>
                        <div style={{fontSize : 12, color : "rgba(0,0,0,0.7)", fontWeight: 300, marginTop: '5px'}}>{time}</div>
                        {/*操作*/}
                        <div
                            style={{
                                position: "absolute",
                                bottom : 0,
                                left : 0,
                                right : 0,
                                top:0,
                                zIndex: 2,
                                background : "rgba(0,0,0,0.5)",
                                display : actionView && showActionView ? "inherit" : "none",
                                color:"#fff"
                            }}
                        >
                            {actionView}
                        </div>
                    </div>
                </Card>
            </Badge>
        );
    }
}
