/**
 * 获取已发送的短信数量
 * Create by liaokai on 2020-04-24 17:45:05
 */
import {toPost} from "../http-utils";

export const messageCount = toPost<
    {

    },
    {
        "sendCount": number, //已经发送数量，只包含已经发送成功的
        "residue": number //剩余数量
    }
>("/merchant/lcm/messageCount");
