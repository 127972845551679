/**
 * 获取直播列表
 * Create by liaokai on 2020-05-26 18:36:57
 */
import {toPost} from "../../http-utils";

export interface IRecentlyLiveItem {
    "live_id": number, //直播id
    "poster": string, //海报地址
    "title": string, //标题
    "live_status": string, //直播状态
    "time": string, //直播时间
    "live_type": string, //直播类型
    "people_num": number, //观众人数
    "look_num": number //观看次数
    "type_to_str": string //直播观看类型
    "is_register":boolean // 是否有表单
}

export const recentlyLive = toPost<
    {
        page : number
        paginate? : number
        live_time_begin:string|undefined
        live_time_end:string|undefined
        title:string
    },
    {
        "current_page": number,
        "total": number,
        "last_page": number,
        "data": IRecentlyLiveItem[]
    }
    >("/merchant/live/recentlyLive");
