/**
 * 常用默认文本样式
 * Create by liaokai on 2020-04-22 11:50:13
 */
import * as React from 'react';
import {Typography} from "antd";
import {TextProps} from "antd/lib/typography/Text";

const {Text} = Typography;

export class Texts{
    static ItemTitle = (props: TextProps)=>{
        return <Text style={{fontSize: 16, fontWeight: "bold", color:"rgba(0,0,0,0.85)"}} {...props}>{props.children}</Text>
    }
    static ChapterTitle = (props: TextProps)=>{
        return <Text style={{fontSize: 18, fontWeight: 400, color:"rgba(0,0,0,1)"}} {...props}>{props.children}</Text>
    }
    static TableTitle = (props: TextProps)=>{
        return <Text style={{fontSize: 14, color:"rgba(0,0,0,0.85)"}} {...props}>{props.children}</Text>
    }
}
