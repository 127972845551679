/**
 *
 * Create by liaokai on 2020-04-17 10:27:33
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {SurfaceRouter, tSurfaceRouteItem} from "../../../../../../components/surface-router/surface-router";
import {MeetingOverview} from "../overview/overview";
import {SetCharge} from "./set-charge";

interface IProps extends RouteComponentProps{

}

interface IState{

}

const routerData : tSurfaceRouteItem = {
    breadTitle : "收费设置",
    routeName : "",
    component : SetCharge,
    children : []
}

export class SetChargeRouter extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={routerData}/>
        );
    }
}
