/**
 * 切换ppt回调
 * Create by liaokai on 2020-05-30 13:45:51
 */
import {toPost} from "../../http-utils";

export const useDoc = toPost<
    {
        doc_id : string,
        live_id ?: string,
        doc_son_id ?: string,
    },
    {

    }
>("/merchant/live/useDoc");

