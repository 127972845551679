/**
 * 导出订单列表
 * Create by liaokai on 2020-05-09 16:05:39
 */
import {toPost} from "../http-utils";

export const orderAccountRecord = toPost<
    {

    },
    {
        excelDownloadUrl : string
    }
>("/merchant/orderAccountRecord");
