/**
 * 订单支付(弹窗) + 收银台
 * Create by liaokai on 2020-05-14 15:58:31
 */
import * as React from 'react';
import {Component} from "react";
import {PayQrcode} from "../../../../../../user/prepaid-money/index/pay-qrcode";
import {apis} from "../../../../../../../../common/apis/apis";
import {Button, Input, message} from "antd";
import {storeMenu} from "../../../../../../../../common/menu.store";
import {common} from "../../../../../../../../common/common";
import {Air} from "../../../../../../../../components/air/Air";
import {IOrderPayProps, OrderPay} from "./order-pay";
import {IRechargeImgWechat} from "../../../../../../../../common/apis/apis/get-recharge-img";
import {BuyEmail} from "../buy-email";
import {balancePaySmsAffirm} from "../../../../../../../../common/apis/apis/balancePaySmsAffirm";

interface IProps extends IOrderPayProps{
    pay_type? : number,
    orderData : {
        "money": number,
        "orderNum": string,
        "info": {
            "title": string,
        },
        "code_url": string,
        "balance": string,
        "create_time": string,
        "pay_type": number  // 1/微信支付 2/支付宝 3/运营账户余额
    } | undefined  //IRechargeImgWechat | undefined
    onPaySuccess? : () => void
}

interface IState{
    password : string
}

export class OrderPayAndCashier extends Component<IProps,IState>{
    readonly state : IState = {
        password : ""
    };

    render() {
        let {pay_type, orderData, onPaySuccess} = this.props;
        let {password} = this.state;
        return (
            <OrderPay {...this.props} data={{
                orderId : orderData?.orderNum ?? "",
                product : orderData?.info.title ?? "",
                price : orderData?.money ?? 0,
                createTime : orderData?.create_time ?? "",
                payMethod : BuyEmail.payMethodName(orderData?.pay_type),
                payType : Number(pay_type),
                balance : orderData?.balance??""
            }} cashier={
                <div>
                    {((pay_type)=>{
                        switch (pay_type) {
                            case 1:
                                return <PayQrcode
                                    onLoadQRCode={async () => {
                                        return {
                                            img : orderData?.code_url ?? "",
                                            price : orderData?.money ?? -1,
                                            id : orderData?.orderNum ?? ""
                                        }
                                    }}
                                    onRotationAsk={async (id)=>{
                                        const res = await apis.getRechargeImgStatus({
                                            orderNum : id
                                        })
                                        return res.status === 2
                                    }}
                                    onPaySuccess={()=>{
                                        message.success("购买完成");
                                        onPaySuccess?.();
                                    }}
                                />
                            case 2:
                                return <PayQrcode
                                    onLoadQRCode={async () => {
                                        return {
                                            img : orderData?.code_url ?? "",
                                            price : orderData?.money ?? -1,
                                            id : orderData?.orderNum ?? ""
                                        }
                                    }}
                                    onRotationAsk={async (id)=>{
                                        const res = await apis.getRechargeImgStatus({
                                            orderNum : id
                                        })
                                        return res.status === 2
                                    }}
                                    onPaySuccess={()=>{
                                        message.success("购买完成");
                                        onPaySuccess?.();
                                    }}
                                />
                            //余额支付
                            case 3:
                                return <div>
                                        <span>
                                            <span style={{color: common.color.primary}}>
                                                <span>¥</span>
                                                <span style={{ fontSize: 28}}>{orderData?.money?.toFixed(2)} 元</span>
                                            </span>
                                        </span>
                                    <Input.Password placeholder="输入密码" value={password} onChange={event => this.setState({password : event.target.value})}/>
                                    <Air vertical={12}/>
                                    <div style={{display : "flex", justifyContent: "center"}}>
                                        <Button type={"primary"} shape={"round"} onClick={()=>{
                                            apis.balancePaySmsAffirm({
                                                order_num : orderData?.orderNum ?? "",
                                                password : password
                                            }).then(res => {
                                                message.success("支付完成");
                                                onPaySuccess?.();
                                            }).catch((e)=>{
                                                message.error(`支付失败 ${e?.data?.message}`)
                                            })
                                        }}>确认支付</Button>
                                    </div>
                                </div>
                        }
                    })(pay_type)}
                </div>
            }/>
        );
    }
}
