/**
 *
 * Create by liaokai on 2020-05-09 16:08:17
 */
import {toPost} from "../http-utils";

export interface IOrderRecordTableItem {
    orderId : string,
    product : string,
    type : string,
    createData : string,
    price : string,
    payMethod : string,
    status : number  // 1待支付 2已支付 3已作废 4未支付已过期
}

export const getOrderRecordTable = toPost<
    {
        page : number,
        paginate? : number
    },
    {
        "list": {
            "id": number,
            "order_num": string, //订单号
            "origin_num": string,
            "title" : string,
            "type": string, //1代表新购
            "create_time": string, //订单创建时间
            "price": number, //价格 单位元
            "pay_type": number, //1微信2支付宝3后台人员手动添加4不收费自己添加5为短信发送扣款6短信取消回款7为余额购买
            "status": number, //1待支付2已支付3已作废4未支付已过期
            "expire_time": number ,//订单过期时间
            "pay_url": string//二维码
        }[]
        ,
        "current_page": number,//当前页
        "last_page": number, //最后一页
        "total": number //总条数
    }
>("/merchant/Lcm/merchantPackage");
