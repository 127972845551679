/**
 *
 * Create by liaokai on 2020-05-09 13:25:39
 */
import * as React from 'react';
import {Component} from "react";
import {common} from "../../../../../common/common";
import {Button, message, Spin} from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {apis} from "../../../../../common/apis/apis";
import {getRechargeImgStatus} from "../../../../../common/apis/apis/get-recharge-img-status";
import {Link} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {getRechargeImgWechat} from "../../../../../common/apis/apis/get-recharge-img";
import {CheckCircleFilled} from "@ant-design/icons/lib";

interface IProps{
    onLoadQRCode : () => Promise<{price : number, img : string, id : string}>    //加载二维码的方法
    onRotationAsk? : (id : string) => Promise<boolean>     //轮训是否已支付方法
    onPaySuccess? : () => void  //成功支付回调
    [triggerFields: string] : any   //任何触发重新加载的字段
}

interface IState{
    loading : number,   // 0/完成 1/加载中 2/加载失败 3/支付完成
    error : boolean,
    imgSrc : string,
    price : number,
    id : string
}

export class PayQrcode extends Component<IProps,IState>{
    readonly state : IState = {
        loading : 0,
        error : false,
        imgSrc : "https://www.baidu.com",
        price: 0,
        id : ""
    };

    timer : any

    componentDidMount() {
        this.loadQRCode();
        this.timer = setInterval(async ()=>{
            try {
                let res = await this.props.onRotationAsk?.(this.state.id)
                if(res){
                    this.setState({loading : 3})
                    this.props.onPaySuccess?.();
                    this.clearTimer();
                }
            }catch (e) {}
        }, 1000)
    }

    clearTimer = ()=>{
        clearInterval(this.timer)
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props))
            this.loadQRCode();
    }

    loadQRCode = ()=>{
        this.setState({loading : 1})
        this.props.onLoadQRCode().then(res => {
            this.setState({imgSrc : res.img, price : res.price, id : res.id})
            this.setState({loading : 0})
        }).catch(e => {
            this.setState({loading : 2})
        })
    }

    render() {
        let {} = this.props;
        let {loading, imgSrc, error, price} = this.state;
        let pricePro : any
        try {
            pricePro = price.toFixed(2)
        }catch (e) {
            pricePro = common.maxPrice.toFixed(2)
        }
        return (
            <div style={{display : "flex", flexDirection : "column", justifyContent: "center", alignItems : "center"}}>
                <div>
                    <span>
                        <span style={{color: common.color.primary}}>
                            <span>¥</span>
                            <span style={{ fontSize: 28}}>{pricePro}</span>
                        </span>
                    </span>
                </div>
                <div style={{width : 200, height : 200, position: "relative"}}>
                    <img
                        onLoad={event => {
                            this.setState({loading : 0})
                        }}
                        onLoadStart={event => {
                            this.setState({loading : 1})
                        }}
                        onError={event => {
                            this.setState({loading : 2})
                        }}
                        style={{width : "100%", height : "100%", position: "absolute", zIndex: 1}}
                        loading={"lazy"}
                        src={imgSrc}
                        alt=""
                    />
                    {
                        loading === 1 ?
                            <div style={{width : "100%", height : "100%", position: "absolute", zIndex: 2, display: "flex", justifyContent: "center", alignItems: "center", background: "rgba(0,0,0,0.5)"}}>
                                <Spin size={"large"}/>
                            </div>
                            : undefined
                    }
                    {
                        loading === 2 ?
                            <div style={{width : "100%", height : "100%", position: "absolute", zIndex: 2, display: "flex", justifyContent: "center", alignItems: "center", background: "rgba(0,0,0,0.5)"}}>
                                <ExclamationCircleOutlined /><Button style={{color: "#000"}} type={"link"} onClick={()=>{this.loadQRCode()}}>重新加载二维码</Button>
                            </div>
                            : undefined
                    }
                    {
                        loading === 3 ?
                            <div style={{width : "100%", height : "100%", position: "absolute", zIndex: 2, display: "flex", justifyContent: "center", alignItems: "center", background: "rgba(0,0,0,0.5)"}}>
                                <CheckCircleFilled style={{color: common.color.success, fontSize: 30}} />
                            </div>
                            : undefined
                    }
                </div>
            </div>
        );
    }
}
