/**
 * 关键字列表
 */
import {toPost} from "../../http-utils";

export interface ILiveKeyWordsIndexItem {
    "id": number,
    "keyword": string,
    "create_time": string
}

export const LiveKeyWordsIndex = toPost<{
    live_id: string,
    keyword?: string
}, {
    "current_page": number,
    "total": number,
    "last_page": number,
    "data": ILiveKeyWordsIndexItem[]
}>("/merchant/live/liveKeywordsIndex");


