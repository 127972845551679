/**
 * 选择关联直播
 * Create by liaokai on 2020-06-15 13:41:40
 */
import * as React from 'react';
import {Component} from "react";
import {Button, message, Modal, Table} from "antd";
import {
    allowBindLiveIndex,
    IAllowBindLiveIndexItem
} from "../../../../../../../common/apis/apis/live/allowBindLiveIndex";
import {liveStore} from "../../live.store";
import {common} from "../../../../../../../common/common";

interface IProps{
    visible : boolean
    onCancel? : () => void
    onSelect? : (item :IAllowBindLiveIndexItem) => void
}

interface IState{
    columns : any[]
    dataSource : IAllowBindLiveIndexItem[],
    currentPage : number,
    total : number,
    pageSize: number,
    loading : boolean,
}

export class LiveSelectRelations extends Component<IProps,IState>{
    readonly state : IState = {
        columns : [
            {
                title: '封面',
                dataIndex: 'poster',
                key: 'poster',
                render: (text : string) => <img src={text} style={{width : 120}} alt=""/>,
            },
            {
                title: '直播标题',
                dataIndex: 'title',
                key: 'title',
                render: (text: string) => text,
            },
            {
                title: '直播时间',
                dataIndex: 'live_time',
                key: 'live_time',
                render: (text: string) => text,
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                render: (text: string, record : IAllowBindLiveIndexItem, index : number) => <div>
                    <Button type={"link"} style={{paddingLeft : 0}} onClick={()=>{
                        const {onSelect,onCancel} = this.props
                        onSelect?.(record);
                        onCancel?.();
                    }}>选择</Button>
                </div>,
            }
        ],
        dataSource : [],
        pageSize : 5,
        total : 0,
        currentPage : 1,
        loading : false,
    };

    refreshPage = ()=>{
        this.reqList(this.state.currentPage)
    }

    componentDidMount() {
        this.reqList();
    }

    componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any) {
        if(nextProps.visible){
            this.refreshPage();
        }
    }

    reqList = (page : number = 1) => {
        this.setState({loading : true})
        allowBindLiveIndex({
            live_id : liveStore.live_id,
            page : page,
            paginate : this.state.pageSize
        }).then(res => {
            this.setState({
                dataSource : res.data,
                total : res.total,
                currentPage : page
            })
        }).finally(()=>{
            this.setState({loading : false})
        })
    }

    render() {
        let {visible, onCancel} = this.props;
        let {columns, dataSource, pageSize, total, currentPage, loading} = this.state;
        return (
            <Modal
                visible={visible}
                title={"新建关联"}
                okText={"保存"}
                cancelText={"取消"}
                onCancel={()=>{
                    onCancel?.()
                }}
                centered={true}
                width={"70%"}
            >
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={dataSource}
                    emptyText={"暂无内容"}
                    pagination={{
                        defaultCurrent : 1,
                        current : currentPage,
                        pageSize : pageSize,
                        total : total,
                        onChange : page => {
                            this.reqList(page);
                            this.setState({currentPage : page})
                        }
                    }}
                />
            </Modal>
        );
    }
}
