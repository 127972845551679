import styled, {StyledComponent} from "styled-components";
import React from "react";

type unit = number

interface ISpaceOuter {
    vertical?: unit
    horizontal? : unit
    left? : unit
    right? : unit
    bottom? : unit
    top? : unit
    value? : unit
}

interface ISpaceInner extends ISpaceOuter{
    spaceType? : "padding" | "margin" | "border"
}

export const Space = styled.div<ISpaceInner>`
    ${props => props.spaceType}-left: ${props => {
        return props.left ?? props.horizontal ??  props.value ?? 0
    }}px;
    ${props => props.spaceType}-right: ${props => {
        return props.right ?? props.horizontal ??  props.value ?? 0
    }}px;
    ${props => props.spaceType}-bottom: ${props => {
        return props.bottom ?? props.vertical ??  props.value ?? 0
    }}px;
    ${props => props.spaceType}-top: ${props => {
        return props.top ?? props.vertical ??  props.value ?? 0
    }}px;
`;

interface IBorderX extends ISpaceOuter{
    color? : string,
    line? : string
}

const Border_X = styled(Space)<IBorderX>`
  border-color: ${props => props.color ?? "rgba(0,0,0,0)"};
  border-style: ${props => props.line ?? "solid"};
`

interface IProps extends ISpaceOuter{
    children? : React.ReactNode;
}

interface IBorderProps extends IBorderX{
    children? : React.ReactNode;
}

export const Padding =  (props: IProps) => <Space {...props} spaceType={"padding"}/>

export const Margin  =  (props: IProps) =>  <Space {...props} spaceType={"margin"}/>

export const Border  =  (props: IBorderProps) =>  <Border_X {...props} spaceType={"border"}/>

