import React, { Component } from 'react'
import {Result, Button} from "antd"

export class ResultView extends Component {
constructor(props) {
  super(props);
  this.state = {};
     this.refreshProps = this.refreshProps.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
}
refreshProps(props) {
  this.state.option = props.result;
  this.setState(this.state);
}
render() {
  return (
      <Result
          status="success"
          title="导入结束"
          subTitle={this.state.option?.value ?? ""}
          extra={[
              <Button type="primary" onClick={()=>{
                    window.location.reload();
              }}>
                  重新导入
              </Button>
          ]}
      />
   )
   }
}
export default ResultView
