// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".prepaid-money_payItems__2jbuY{display:grid;grid-template-columns:repeat(auto-fill, 250px);grid-auto-rows:120px;grid-gap:10px}.prepaid-money_payItems__2jbuY>*:last-child{grid-column:2 span}.prepaid-money_payMethod__3VEbg{display:grid;grid-template-columns:repeat(auto-fill, 250px);grid-template-rows:120px;grid-gap:10px}.prepaid-money_submitBtn__3RcOM{background:#ff784b;border:#ff784b}.prepaid-money_submitBtn__3RcOM:hover{background:#ff6e4b;border:#ff6e4b}\n", ""]);
// Exports
exports.locals = {
	"payItems": "prepaid-money_payItems__2jbuY",
	"payMethod": "prepaid-money_payMethod__3VEbg",
	"submitBtn": "prepaid-money_submitBtn__3RcOM"
};
module.exports = exports;
