// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".signin-view_container__17WZ4 .signin-view_header__2RoKP{display:flex;justify-content:flex-end;margin-bottom:20px}.signin-view_container__17WZ4 .signin-view_grid__2D7Sz{display:grid;grid-template-columns:repeat(4, 1fr);grid-gap:1.8vw}\n", ""]);
// Exports
exports.locals = {
	"container": "signin-view_container__17WZ4",
	"header": "signin-view_header__2RoKP",
	"grid": "signin-view_grid__2D7Sz"
};
module.exports = exports;
