import React, { Component } from 'react'
import style from './overview.scss'
import { api } from '../../../../../../common/app'
import copy from "copy-to-clipboard";
import { Qrcode } from "../../../../../../components/common/qrcode";
import { Button, message, notification, Popover, Descriptions, Card, Statistic, Breadcrumb } from "antd";
import { Radio } from "../../../../../../components/common/radio";
// import ReactZmage from 'react-zmage';
import { PieChartOutlined, TeamOutlined } from "@ant-design/icons";
import { QuestionCircleOutlined } from "@ant-design/icons/lib";
import { apis } from "../../../../../../common/apis/apis";
import { common } from "../../../../../../common/common";
import { devDomain, prodDomain } from "../../../../../../common/apis/http";
import { LiveShareSetting } from './component/share-setting';

export class MeetingOverview extends Component<any, any> {
    qrCode: Qrcode | null = null;

    constructor(props: any) {
        super(props);
        this.state = {
            id: null,
            meetinginfo: null,
            showShareSetting: false,
        };
        this.refreshProps = this.refreshProps.bind(this);
    }

    //state初始化
    readonly state: any = {

    };

    componentWillReceiveProps(nextprops: any) {
        this.refreshProps(nextprops);
    }

    componentDidMount() {
        this.refreshProps(this.props);
    }

    refreshProps(props: any) {
        let params = props.match.params;
        if (this.state.id != params.meeting_id) {
            this.state.id = params.meeting_id;
            this.setState(this.state);
            this.getMeetingInfo(params.meeting_id);
        }
    }

    getMeetingInfo(id: number) {
        apis.getMeetingPandect({ id: id.toString() }).then(res => {
            this.setState({ meetinginfo: res })
        }).catch(e => {
            window.location.hash = '#/index/meeting_list/index'
        })
    }



    // breadcrumbNameMap = {
    //     '/meeting': '会议',
    //     '/meeting/menu': '总览',
    //     '/meeting/menu/1': '详情',
    // };
    //
    // Home = withRouter(props => {
    //     const { location } = props;
    //     const pathSnippets = location.pathname.split('/').filter(i => i);
    //     // alert(JSON.stringify(pathSnippets))
    //     const extraBreadcrumbItems = pathSnippets.map((_, menu) => {
    //         const url = `/${pathSnippets.slice(0, menu + 1).join('/')}`;
    //         return (
    //             <Breadcrumb.Item key={url}>
    //                 <Link to={url}>{this.breadcrumbNameMap[url]}</Link>
    //             </Breadcrumb.Item>
    //         );
    //     });
    //     const breadcrumbItems = [
    //         <Breadcrumb.Item key="home">
    //             <Link to="/">Home</Link>
    //         </Breadcrumb.Item>,
    //     ].concat(extraBreadcrumbItems);
    //     return (
    //         <div className="demo">
    //             <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    //         </div>
    //     );
    // });



    render() {
        let shareUrl = process.env.REACT_APP_ENV === "production" ?
            `${prodDomain}/wx/#/home/index/${this.state.id}` :
            `${devDomain}/wx/#/home/index/${this.state.id}`
        let { signNum, userNum } = this.state;
        return (
            <div className={style.container}>
                {/*<div className={[style.ViewTitle, 'childcenter childcontentstart'].join(' ')}>*/}
                {/*    <span>总览</span>*/}
                {/*</div>*/}

                <div className={style.primary}>
                    <div className={style.form}>
                        <div className={style.description}>
                            {/*<div>111111</div>*/}
                            {/*<Button type={"primary"}>222</Button>*/}
                            <Descriptions title="基本信息" bordered={false} column={1}>
                                <Descriptions.Item label="名称" className='overviewlabel'>
                                    {this.state.meetinginfo ? this.state.meetinginfo.title : ''}
                                </Descriptions.Item>
                                <Descriptions.Item label="时间" className='overviewlabel'>
                                    {
                                        this.state.meetinginfo
                                            ?
                                            //@ts-ignore
                                            `${new Date(this.state.meetinginfo.start_time).format('yyyy/MM/dd')} ${this.state.meetinginfo.strat_time_week} - ${new Date(this.state.meetinginfo.end_time).format('yyyy/MM/dd')} ${this.state.meetinginfo.end_time_week}`
                                            :
                                            ""
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="会议城市" className='overviewlabel'>
                                    {this.state.meetinginfo ? this.state.meetinginfo.city : ''}
                                </Descriptions.Item>
                                <Descriptions.Item label="会议地点" className='overviewlabel'>
                                    {this.state.meetinginfo ? this.state.meetinginfo.address : ''}
                                </Descriptions.Item>
                                <Descriptions.Item label="状态" className='overviewlabel'>
                                    {this.state.meetinginfo ? this.state.meetinginfo.meeting_status : ''}
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                        <div>
                            <Card bodyStyle={{ padding: 0 }}>
                                {this.state.meetinginfo
                                    ?
                                    <div
                                        style={{ width: 400, height: 400 * 9 / 16, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundImage: `url(${this.state.meetinginfo.cover})` }}
                                    // className={style.img}
                                    />
                                    :
                                    '暂无图片'}
                            </Card>
                            <Button style={{ marginTop: 3 }} onClick={() => { this.setState({ showShareSetting: true }) }}>分享设置</Button>
                        </div>
                    </div>


                    <Descriptions title={
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ marginRight: 10 }}>
                                会议推广
                            </div>
                            <Popover content={"扫码可预览预置信息，适用于微信分享"} placement={"right"}>
                                <QuestionCircleOutlined />
                            </Popover>
                        </div>
                    } className={style.meetingShare}>
                        <Descriptions.Item>
                            <Card title="移动端" bordered={true} style={{ width: 250 }}>
                                <div className={style.card}>
                                    <Qrcode value={shareUrl} ref={ref => this.qrCode = ref} />
                                    <div className={style.imgBtns}>
                                        <Popover
                                            placement={"bottomLeft"}
                                            content={
                                                <div className={style.sizeSelect}>
                                                    <Radio
                                                        data={[
                                                            { show: "小", size: 128 },
                                                            { show: "中", size: 256 },
                                                            { show: "大", size: 512 },
                                                            { show: "巨大", size: 1024 },
                                                        ]}
                                                        renderSelectedItem={item => <Button type={"primary"}
                                                            onClick={() => {
                                                                this.qrCode?.download(item.size)
                                                            }}>{item.show}</Button>}
                                                        renderUnSelectItem={item => <Button onClick={() => {
                                                            this.qrCode?.download(item.size)
                                                        }}>{item.show}</Button>}
                                                        extractKey={item => item.show}
                                                        initSelectIndex={1}
                                                    />
                                                </div>
                                            }
                                        >
                                            <Button type={"primary"} onClick={() => {
                                                this.qrCode?.download(500)
                                            }}>下载</Button>
                                        </Popover>
                                        <Button
                                            className={style.copeBtn}
                                            onClick={() => {
                                                copy(shareUrl);
                                                notification.success({
                                                    message: `推广链接已复制`,
                                                    description: shareUrl,
                                                    duration: 2.2,
                                                    style: { wordBreak: "break-word" }
                                                });
                                            }}>
                                            复制链接
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions title="会议数据" className={style.meetingShare}>
                        <Descriptions.Item>
                            <div className={style.meetingData}>
                                <Card bordered={false}>
                                    <Statistic title={"注册总人数"} value={this.state.meetinginfo?.reg_num ?? 0} prefix={<TeamOutlined />} valueStyle={{ color: "#EB5027" }} />
                                </Card>
                                <Card bordered={false}>
                                    <Statistic title="现场签到人数" value={this.state.meetinginfo?.sign_num ?? 0} prefix={<PieChartOutlined />} valueStyle={{ color: "#F2AB3C" }} />
                                </Card>
                            </div>
                        </Descriptions.Item>
                    </Descriptions>
                </div>

                <LiveShareSetting
                    meetid={this.state.id}
                    visible={this.state.showShareSetting}
                    onCancel={() => {
                        this.setState({ showShareSetting: false })
                    }}
                />
            </div>
        )
    }
}
