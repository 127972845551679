import React, { Component } from 'react'
import styles from './LoginBox.scss'
import logo from   '../../assets/sign/logo.png'
import close from  '../../assets/sign/close.png'
import qrcode from '../../assets/wechat-qrocode.jpeg'

export class LoginBox extends Component {
  state = {
    showTip: true,
    close: false,
    hover: false,
  }

  componentDidMount () {
    const isChrome =  navigator.userAgent.indexOf('Chrome') > -1;
    if (!!isChrome) {
      this.setState({ showTip: true });
    }
  }

  onItemClick = (item) => {
    if (item.value === 'home') {
      window.open( 'https://www.huibaifen.com')
    } else if (item.value === 'price') {
      window.open ('https://www.huibaifen.cn')
    }else if(item.value === 'center')
    {
      window.open ('https://rup-china.com')
    }
  }

  render () {
    const { showTip } = this.state;

    // const menus = [{
    //   title: '首页',
    //   value: 'home'
    // }, {
    //   title: '服务&支持',
    //   value: 'support'
    // }, {
    //   title: '价格',
    //   value: 'price'
    // }, ];
    const menus = [{
      title: '直播系统官网',
      value: 'home'
    }, {
      title: '会议系统官网',
      value: 'price'
    }, {
      title: '软件开发官网',
      value: 'center'
    }, ];

    return (
      <div>
        {/*{!!showTip && (*/}
        {/*  <aside className={styles["LoginHeader-tip"]} >*/}
        {/*    <span className="intro"  style={{marginRight: '20px'}} >为了获得更好的用户体验，建议您使用Google Chrome浏览器</span>*/}
        {/*    <span */}
        {/*      className={styles["gt-close"]}*/}
        {/*      onClick={() => {*/}
        {/*        this.setState({ showTip: false })*/}
        {/*      }} */}
        {/*    />*/}
        {/*  </aside>*/}
        {/*)}*/}
        <div className={styles["site-header"]} style={{top: '0px'}}>
          <div className={styles["wrap"]}>
            <div className={styles["nav-main"]}>
              <div
                className={styles['LoginHeader-icon']}
                style={{backgroundImage: `url(${logo})`}}
                onClick={() => {
                  window.location.hash = '#/login'
                }}
              />
              <div className={styles['LoginHeader-content-menus']} style={{marginLeft: '10%', marginBottom: '2px'}}>
                {menus.map((item) => {
                  return (
                    <div
                      className={styles['LoginHeader-content-menu']}
                      onClick={() => this.onItemClick(item)}
                      onMouseEnter={() => {
                        if (item.value === 'support') {
                          this.setState({ hover: true });
                        }
                      }}
                      onMouseLeave={() => {
                        if (item.value === 'support') {
                          this.setState({ hover: false });
                        }
                      }}
                    >
                      {item.title}

                      {item.value === 'support' && !!this.state.hover && (
                        <div className={styles['LoginHeader-qrcode']}>
                          <img src={qrcode} className={styles['LoginHeader-qrcode-img']} />
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
              <ul className={styles["nav-footer"]}>
                {/*<li>*/}
                {/*  <a*/}
                {/*    className={styles["register"]}*/}
                {/*    onClick={() => {*/}
                {/*      window.location.hash = '#/register'*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    免费使用*/}
                {/*  </a>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <div*/}
                {/*    className={styles['LoginHeader-content-menu']}*/}
                {/*    onClick={() => {*/}
                {/*      window.location.hash = '#/login'*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    登录*/}
                {/*  </div>*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default LoginBox;
