/**
 * 文档列表
 * Create by liaokai on 2020-05-28 09:57:04
 */
import {toPost} from "../../http-utils";

export interface IDocIndex {
    "id": number,
    "origin_name": string, //文件名字
    "create_time": string, //创建时间
    "page": number, //页数
    "hash": string, //hash 暂时使用不到
    publish_status:1|2 // 1未发布2一发布
    "status": string
}

export const docIndex = toPost<
    {
        live_id : string,
        page : number,
        paginate? : number
    },
    {
        "current_page": number,
        "total": number,
        "last_page": number,
        "data": IDocIndex[]
    }
>("/merchant/live/docIndex");

