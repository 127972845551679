/**
 * 权限检测
 * Create by liaokai on 2020-06-28 11:47:24
 */
import * as React from 'react';
import {Component, useState} from "react";
import {Steps} from "antd";
import FlashPermission from "../../../../../../assets/premission-tip.jpg"
import FlashPermission2 from "../../../../../../assets/premission-tip-2.jpg"
import {FlexJustifyCenter} from "../../../../../../components/style/flex/flex-justify-center";
import styled from "styled-components";
import {DownOutlined} from "@ant-design/icons/lib";
import {FlexColHorCenter} from "../../../../../../components/style/flex/flex-col-hor-center";
import {FlexJustifyAlignCenter} from "../../../../../../components/style/flex/flex-justify-align-center";


const { Step } = Steps;

type tStatus = 'wait' | 'process' | 'finish' | 'error';

interface IProps{
    onSuccess? : () => void
}

interface IState{
    data : ICheckItem[],
    current : number
    status : tStatus,
    content? : JSX.Element
}

interface ICheckItem {
    check : () => Promise<ICheckFunRes>
    title : string
    description : string,
    content? : JSX.Element
}

interface ICheckFunRes {
    status : boolean    //true 正常， false异常
    msg? : string    //原因
}

function PermissionTip() {
    const [other, setOther] = useState(false)
    return <>
        <PermissionTipContainer>
            <img src={FlashPermission} style={{maxWidth: "100%", maxHeight:500}} alt=""/>
            {
                other ?
                    <img src={FlashPermission2} style={{maxWidth: "100%", maxHeight:600}} alt=""/>
                    :
                    <FlexJustifyAlignCenter onClick={()=>{
                        setOther(true)
                    }}>
                        <div style={{cursor: "pointer"}}>其他情况</div>
                        <DownOutlined />
                    </FlexJustifyAlignCenter>
            }
        </PermissionTipContainer>
    </>
}

export class PermissionsCheck extends Component<IProps,IState>{
    //Flash权限检测
    flashCheck = async ():Promise<ICheckFunRes>=>{
        try {
            const RtmpStreamer = require("rtmp-streamer")
            let streamer = new RtmpStreamer(document.getElementById('rtmp-streamer'));
            streamer.setMicQuality(5)
            return {
                status : true,
            }
        }catch (e) {
            return {
                status : false,
            }
        }
    }

    videoCheck = async ():Promise<ICheckFunRes>=>{
        let videoNum = 0;
        let devices = await navigator.mediaDevices.enumerateDevices()
        devices.forEach(device =>{
            if (device.kind === "videoinput")
                videoNum++;
        });
        if(videoNum <= 0){
            return {
                status : false,
                msg : "缺少设备"
            }
        }

        return new Promise((resolve, reject) => {
            navigator.getUserMedia({video: true},
                function onSuccess(stream) {
                    resolve({
                        status : true
                    })
                },
                function onError(error) {
                    resolve({
                        status :
                        false,
                        msg : "未开启权限"
                    })
                }
            );
        })
    }

    audioCHeck = async ():Promise<ICheckFunRes>=>{
        let microphoneNum = 0;
        let devices = await navigator.mediaDevices.enumerateDevices()
        devices.forEach(device =>{
            if (device.kind === "audioinput")
                microphoneNum++;
        });
        if(microphoneNum <= 0){
            return {
                status : false,
                msg : "缺少设备"
            }
        }
        return new Promise((resolve, reject) => {
            navigator.getUserMedia({audio:true},
                function onSuccess(stream) {
                    resolve({
                        status : true
                    })
                },
                function onError(error) {
                    resolve({
                        status : false,
                        msg : "未开启权限"
                    })
                }
            );
        })

    }

    readonly state : IState = {
        data : [
            {
                check : this.flashCheck,
                title: "Flash权限",
                description : "待检测",
                content : <PermissionTip/>
            },
            {
                check : this.videoCheck,
                title: "摄像头",
                description : "待检测",
                content : <PermissionTip/>
            },
            {
                check : this.audioCHeck,
                title: "麦克风",
                description : "待检测",
                content : <PermissionTip/>
            },
        ],
        current : 0,
        status: "wait"
    };

    async componentDidMount() {
        const {data} = this.state
        const {onSuccess} = this.props
        for(let i = 0; i < data.length; i++) {
            const item = data[i]
            this.setState({current : i, status: "process"});
            const res = await item.check()
            if(res.status){
                item.description = "正常"
                this.setState({status : "finish", data: data},()=>{
                    if(i === data.length - 1){
                        onSuccess?.()
                    }
                })
            }else{
                item.description = res.msg ?? "异常"
                this.setState({status : "error", data: data, content : item?.content})
                break;
            }
        }
    }

    render() {
        let {} = this.props;
        let {data, current, status,content} = this.state;

        return (
            <div>
                <Steps current={current} status={status}>
                    {
                        data.map(value =>
                            <Step title={value.title} description={value.description} />
                        )
                    }
                </Steps>
                {
                    content
                }
            </div>
        );
    }
}

const PermissionTipContainer = styled(FlexColHorCenter)`
  max-height: 560px;
  overflow-y: auto;
`
