/**
 * 邮件模板列表
 * Create by liaokai on 2020-04-23 17:52:00
 */
import {toPost} from "../../http-utils";
export const emailDestroy = toPost<
    {
        id : number,
    },
    {
        // "total": number,
        // "per_page": number,
        // "current_page": number,
        // "last_page": number,
        // "list": IEmailAddressListItem[]
    }
    >("/merchant/Email/emailDestroy");
