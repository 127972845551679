/**
 * 直播数据
 * Create by liaokai on 2020-05-30 19:01:33
 */
import {toPost} from "../../http-utils";

export interface IliveData {
    "concurrence": number, //并发数
    "accumulatedAllPeople": number, //累计总人数
    "playbackCount": number //累计回放
    "income": number //收费金额
}

export const liveData = toPost<
    {
        live_id : string
    },
    IliveData
>("/merchant/live/liveData");

