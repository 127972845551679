/**
 * 获取充值二维码
 * Create by liaokai on 2020-05-09 14:33:19
 */
import {toPost} from "../http-utils";

export interface IRechargeImgWechat {
    "money": number,
    "num": number,
    "orderNum": string,
    "info": {
        "id": number,
        "corner": string,
        "title": string,
        "num": number,
        "describe": string,
        "expire": number,
        "expire_type": number,
        "origin_price": number,
        "price": number,
        "create_time": string,
        "update_time": string
    },
    "code_url": string,
    "create_time": string,
    "pay_type": number,  // 1/微信支付 2/支付宝 3/运营账户余额
    "balance": string  // 余额
}


export const getRechargeImgWechat = toPost<
    {
        id : string,     //充值金额
        type : number    // 1/微信支付 2/支付宝 3/运营账户余额
    },
    IRechargeImgWechat
>("/merchant/Lcm/paySms");
