/**
 * 展示控制
 * Create by liaokai on 2020-04-08 11:42:49
 */
import * as React from 'react';
import {Component} from "react";

interface IProps{
    show? : boolean,            //是否展示
    justHidden? : boolean,      //仅用样式的方式做隐藏（不移除节点）
}

interface IState{

}

export class Display extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {

    };
    static defaultProps={
        show : true,
        justHidden : false
    };
    render() {
        let {show, justHidden, children} = this.props;
        let {} = this.state;
        if(justHidden){
            return <div style={{display : show ? undefined : "none"}}>
                {children}
            </div>
        }else{
            if(show)
                return children;
            else
                return undefined
        }
    }
}
