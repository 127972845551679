/**
 * 编辑回看视频信息
 * Create by liaokai on 2020-06-19 16:08:17
 */
import * as React from 'react';
import {Component} from "react";
import {Input, message, Modal} from "antd";
import {TitleContentTable} from "../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import {emailAddressUpdate} from "../../../../../../common/apis/apis/email/emailAddressUpdate";
import {emailDestroy} from "../../../../../../common/apis/apis/email/emailDestroy";
interface IEmailAddressItem {
    "id": number,
    "name": string,
    "email": string,
}
const defaultItem : IEmailAddressItem = {
    "id": 0,
    "name": "",
    "email": "",
}

interface IProps{
    visible : boolean
    id : any
    onCancel? : () => void
    onSubmit? : () => void
    storeItem?: IEmailAddressItem
}

interface IState{
    storeItem : IEmailAddressItem
}

export class EmailDelAddress extends Component<IProps,IState>{
    readonly state : IState = {
        storeItem : defaultItem
    };

    // componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    //     if(JSON.stringify(this.props.storeItem) !== JSON.stringify(prevProps.storeItem) ){
    //         this.setState({storeItem : this.props.storeItem ?? defaultItem})
    //     }
    // }

    render() {
        let {storeItem} = this.state;
        let {onCancel,onSubmit,visible} = this.props;
        return (
            <Modal
                visible={visible}
                onCancel={()=>{
                    onCancel?.();
                }}
                onOk={()=>{
                    console.log(this.props)
                    emailDestroy({id:this.props.id}).then(()=>{
                        onSubmit?.()
                        onCancel?.()
                    }).catch(e => {
                        message.error("保存失败 " + e.data?.message)
                    })
                }}
                cancelText={"取消"}
                okText={"确认"}
                title={"删除"}
            >
                <TitleContentTable colGap={10} rowGap={10}>
                    <div>您确定要删除么?</div>

                </TitleContentTable>
            </Modal>
        );
    }
}
