/**
 * 已设置表单字段
 * Create by liaokai on 2020-04-03 11:26:53
 */
import {toPost} from "../http-utils";
import {tFormTypes} from "../../../routes/index/meeting-list/meeting/menu/form/form-preview/form-preview";

export const getMeetingRegisterField = toPost<
    {
        meetid : number
    },
    {
        "id": number,           //字段记录id
        "en_name": string,      //字段英文名称
        "zh_name": string,      //字段中文名称
        "type": tFormTypes,     //输入类型，说明同获取注册表单字段
        "check": string,
        "is_required": boolean  //是否必填，"1"是 "0"否
        "required" : number    // 0可删 1不可删
    }[]
>
("/merchant/meeting/getMeetingRegisterField");
