/**
 * 聊天 - 路由
 * Create by liaokai on 2020-05-23 17:58:36
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {SurfaceRouter, tSurfaceRouteItem} from "../../../../../../components/surface-router/surface-router";
import {LiveChatView} from "./chat";
import {LiveKeywordFilterRouter} from "../keyword-filter/keyword-filter-router";
import { KeywordFilter } from "../keyword-filter/keyword-filter";
import BulletScreen from './component/message'
import MessageList from "./component/messageList";
import MessageFilter from './component/filter'

interface IProps extends RouteComponentProps{

}

interface IState{

}

const routerData: tSurfaceRouteItem = {
    breadTitle: "聊天",
    routeName: "",
    component: LiveChatView,
    children: [
        {
            breadTitle: "关键词过滤",
            routeName: "keyword_filter",
            component: KeywordFilter,
            children: [],
        },
        {
            breadTitle: "留言上屏",
            routeName: "bullet",
            component: BulletScreen,
            children: [],
        },
        {
            breadTitle: "留言上屏",
            routeName: "bullet-list",
            component: MessageList,
            children: [],
        },
        {
            breadTitle: "手动过滤",
            routeName: "filter",
            component: MessageFilter,
            children: [],
        },
    ],
};

export class LiveChatRouter extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={routerData}/>
        );
    }
}
