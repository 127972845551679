/**
 * 直播列表
 * Create by liaokai on 2020-05-23 17:04:06
 */
import * as React from 'react';
import {Component} from "react";
import {Responsive} from "../../../../components/common/responsive";
import {Link} from "react-router-dom";
import {LiveListInfo} from "./component/live-list-info";
import {LiveListComp} from "./component/live-list-comp";
import {ThemeListComp} from "./component/theme-list-comp";
import {Tabs} from "antd";

const { TabPane } = Tabs;

interface IProps{

}

interface IState{

}

export class LiveListView extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <div>
                <Responsive>
                    <LiveListInfo/>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="直播列表" key="1">
                            <LiveListComp/>
                        </TabPane>
                        <TabPane tab="专题管理" key="2">
                            <ThemeListComp/>
                        </TabPane>
                    </Tabs>
                </Responsive>
            </div>
        );
    }
}
