/**
 * 会议管理系统
 * Create by liaokai on 2020-04-13 15:36:02
 */
import * as React from 'react';
import {Component} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {MeetingListView} from "./index/meeting-list-view";
import NewMeeting from "./new-meeting"
import {RouteComponentProps} from "react-router";
import {MeetingMenu} from "./meeting/menu/menu";

interface IProps extends RouteComponentProps{

}

interface IState{

}

export class MeetingList extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {

    };

    render() {
        let {match : {path, url}, location: {pathname}} = this.props;
        let {} = this.state;
        return (
            <Switch>
                <Redirect path={path} exact to={`${path}/index`} />
                <Route path={`${path}/index`} component={MeetingListView} />

                <Route path={`${path}/new_meeting`} component={NewMeeting} />

                <Route path={`${path}/meeting/:meeting_id`} component={MeetingMenu} />
            </Switch>
        );
    }
}
