/**
 * 收费设置
 * Create by liaokai on 2020-04-09 15:43:46
 */
import * as React from 'react';
import { Component } from "react";
import style from "./set-charge.scss";
import { Button, Input, InputNumber, message, Switch, Tabs } from 'antd';
import { RouteComponentProps } from "react-router";
import TabItem from './component/tab'
import { CHARGE_TYPE, setMeetingPay, getMeetingPay } from './constants';

const { TabPane } = Tabs;

interface IProps extends RouteComponentProps<any> {

}

interface IState {
    needMoney: boolean,
    moneyText: string,
    moneyNum: number
}

const tabs = [
    { tab: '免费', key: CHARGE_TYPE.free, type: CHARGE_TYPE.free },
    { tab: '标准', key: CHARGE_TYPE.standard, type: CHARGE_TYPE.standard },
    { tab: '按人群收费', key: CHARGE_TYPE.people, type: CHARGE_TYPE.people },
    { tab: '按时间段收费', key: CHARGE_TYPE.time, type: CHARGE_TYPE.time },
    { tab: '按人群+时间段收费', key: CHARGE_TYPE.peopleAndTime, type: CHARGE_TYPE.peopleAndTime },
];

export function SetCharge(props: IProps) {
    const meetid = props.match.params.meeting_id;
    const [init, setInit] = React.useState(false)
    const [chargeDetail, setChargeDetail] = React.useState({} as any);

    React.useEffect(() => {
        fetchCharge();
    }, [meetid]);

    const fetchCharge = () => {
        getMeetingPay({ meetid })
            .then((result: any) => {
                setChargeDetail(result);
                setInit(true)
            })
            .finally(() => {
                setInit(true)
            })
    }

    const setCharge = (params: any) => {
        try {
            const { payload, type } = params;

            const fetchParams =
                type === CHARGE_TYPE.free || type === CHARGE_TYPE.standard
                    ? payload
                    : { params: payload }
            setMeetingPay({
                type,
                meetid,
                ...fetchParams,
            })
                .then((result: any) => {
                    console.log('result', result);
                    message.success('设置成功！');
                    fetchCharge();
                })
                .catch((error: any) => {
                    message.error(error.data?.message)
                })
        } catch (error) {
            message.error(error.message);
        }
    }

    return (
        <div>
            {init && (
                <Tabs type="card" destroyInactiveTabPane={true} defaultActiveKey={`${chargeDetail.type}`}>
                    {tabs.map((tab) => {
                        return (
                            <TabItem  fetchCharge={fetchCharge} chargeDetail={chargeDetail} setCharge={setCharge} {...tab} />
                        )
                    })}
                </Tabs>
            )}
        </div>
    )
}

