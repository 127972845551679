import React, {Component} from 'react'
import style from './BannerTop.scss'
import {Badge, Dropdown, Menu, message, Modal, Select} from "antd";
import {CloseOutlined, DownOutlined, MoneyCollectOutlined, PoweroffOutlined, UserOutlined} from '@ant-design/icons'
import {Air} from "../air/Air";
import logo from "../../assets/logo.png"
import {Responsive} from "../common/responsive";
import {common} from "../../common/common";
import {RouteComponentProps, matchPath} from "react-router";
import {
    AppstoreFilled,
    BellFilled,
    BellOutlined,
    BellTwoTone,
    ContactsOutlined, MailOutlined, MessageOutlined, PlayCircleOutlined,
    VideoCameraOutlined
} from "@ant-design/icons/lib";
import {apis} from "../../common/apis/apis";
import {FlexJustifyAlignCenter} from "../style/flex/flex-justify-align-center";
import {Redirect, Route, Switch} from "react-router-dom";
import {MeetingList} from "../../routes/index/meeting-list/meeting-list";
import {LiveList} from "../../routes/index/live-list/live-list";
import {UserIndexView} from "../../routes/index/user/UserIndexView";
import {getSys} from "../../common/apis/apis/getSys";
import {getLiveRoleInfo} from "../../common/apis/apis/live/get-role-info";
import {api} from "../../common/app";
import {liveStore} from "../../routes/index/live-list/live/menu/live.store";

const {Option} = Select
const {confirm} = Modal

interface IProps extends RouteComponentProps<{live_id : string}>{
}

interface IState {
    userInfo : {
        "role": number,
        "name": string,
        "roleName": string,
    }
    live_id:any

}

export class BannerTop extends Component<IProps,IState> {
    constructor(props : any) {
        super(props);
        this.refreshProps = this.refreshProps.bind(this);
        this.Logout = this.Logout.bind(this);
    }

    readonly state: IState = {
        userInfo : {
            role : 0,
            name : "",
            roleName : "",
            // information : 0
        },
        live_id:0
    }

    componentDidMount() {
        this.refreshProps(this.props);
        this.setState({live_id:this.props.match.params.live_id})
    }

    refreshProps(props : any) {
        this.getUserInfo();
    }

    getUserInfo = () => {
        getLiveRoleInfo({}).then((res)=>{
            this.setState({userInfo : res})
            liveStore.setRole(res.role);
        })
    }
    Logout() {
        let {live_id,userInfo:{role}} = this.state;
        confirm({
            title: '确认退出',
            content: '您确认退出吗？',
            okText: "退出",
            onOk : function(){
                    (api as any).Logout().then((res : any) => {
                        if (res.code === 200) {
                            if (role === 2)
                            {
                                window.location.hash = '#/login_compere/'+live_id;
                            }else{
                                window.location.hash = '#/login_assistant/'+live_id;

                            }
                        } else {
                            message.error(res.message);
                        }
                    }, (err : any) => {
                        common.sentry.captureException(err)
                    })
            },
            cancelText:"取消"
        });
    }

    render() {
        let {live_id} = this.state
        const {match: {path}, location:{pathname}} = this.props
        return (
            <div className={style.container}>
                <Responsive>
                    <div className={style.content}>
                        <div className={style.left} style={{display : "flex", }}>
                            <img src={logo} alt="" style={{maxHeight : 30,margin : "15px 0px"}}/>
                            <div className={style.text} >
                                会佰分
                            </div>
                        </div>
                        <div className={style.center}/>
                        <div className={style.right}>
                            {/*消息*/}
                            <div
                                className={style.message}
                                onClick={() => {
                                    window.location.hash = '#/index/notice'
                                }}
                            >
                                <Badge dot={false}>
                                    <BellFilled style={{fontSize : 24, color : common.color.primary}}/>
                                </Badge>
                            </div>
                            {/*下拉信息*/}
                            <div className={style.info}>
                                <Dropdown
                                    overlay={
                                        <Menu>
                                            <Menu.Item
                                                key="3"
                                                onClick={this.Logout}
                                            >
                                                <div className={style.dropItem}>
                                                    <PoweroffOutlined style={{color : "#2194ff", fontSize : "18px"}}/>
                                                    <div className={style.itemText}>退出登录</div>
                                                </div>
                                            </Menu.Item>
                                        </Menu>
                                    }>
                                    <a className={style.userName}>
                                        {
                                            this.state.userInfo ?
                                                this.state.userInfo.name +" ("+ this.state.userInfo.roleName +")"
                                                : ' '
                                        }
                                        <Air horizontal={3}/>
                                        <DownOutlined />
                                    </a>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </Responsive>
            </div>
        )
    }
}

const styles= {
    display : "none"
}
