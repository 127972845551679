/**
 * 直播间绑定列表
 * Create by liaokai on 2020-06-15 13:46:16
 */
import {toPost} from "../../http-utils";

export interface IBindLiveIndexItem {
    "live_id": number, //直播间id
    "title": string, //标题
    "poster": string, //封面的url
    "live_time": string, //直播时间
    "live_bind_status": number //1代表已经绑定2代表未绑定
}

export const bindLiveIndex = toPost<
    {
        live_id : string
        page : number,
        paginate? : number
    },
    {
        "current_page": number,
        "total": number,
        "last_page": number,
        "data": IBindLiveIndexItem[]
    }
>("/merchant/live/bindLiveIndex");

