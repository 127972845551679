/**
 * 用户管理
 * Create by liaokai on 2020-05-23 18:03:04
 */
import * as React from 'react';
import {Component} from "react";
import {LiveUserList} from "./component/table";

interface IProps{

}

interface IState{

}

export class LiveUserView extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <div>
                <LiveUserList/>
            </div>
        );
    }
}
