/**
 *
 * Create by liaokai on 2020-04-15 16:50:09
 */
import * as React from 'react';
import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import Guest from './index';
import BulkImport from './bulk-import';
import {
  SurfaceRouter,
  tSurfaceRouteItem,
} from '../../../../../../components/surface-router/surface-router';
import Home from './guest/index';
import Detail from './guest/detail'

interface IProps extends RouteComponentProps { }

interface IState { }

const routerData: tSurfaceRouteItem = {
  breadTitle: '嘉宾管理',
  routeName: '',
  component: Home,
  // component: Guest,
  children: [
    {
      breadTitle: '批量添加',
      routeName: 'bulk_import',
      component: BulkImport,
      children: [],
    },
    {
      breadTitle: '嘉宾详情',
      routeName: 'detail/:id',
      component: Detail,
      children: [],
    },
  ],
};

export class GuestRouter extends Component<IProps, IState> {
  readonly state: IState = {};
  render() {
    let { } = this.props;
    let { } = this.state;
    return <SurfaceRouter {...this.props} entryRoute={routerData} />;
  }
}
