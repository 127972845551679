/**
 * 地图展示-具体位置
 * description：
 *  Map是地图
 *  Marker是地图钉
 * Create by XuChen
 * Edit by liaokai on 2020-04-01 18:11:57
 */
import React, {Component, CSSProperties} from 'react'
import { Map,Marker } from 'react-amap';

export type tCoo = {
    longitude : number,
    latitude : number
}

interface IProps {
    loca? : tCoo
    style? : CSSProperties
}

interface IState {
}

const defaultLoca = {
    longitude : 116.397477,
    latitude: 39.908692
}

export class Maps extends Component<IProps,IState> {

    static defaultProps:IProps = {
        style : {},
        loca : defaultLoca
    };

    render() {
        let {style, loca} = this.props;
        return (
            <div key={JSON.stringify(loca)} style={{width: 800, height: 500,...style}}>
                <Map zoom={15} center={loca} showIndoorMap={false}>
                    <Marker
                        position={loca}
                        // 避免过快渲染时发生错误，请不要删除此行
                        onInstanceCreated={()=> {}}
                    />
                </Map>
            </div>
        )
    }
}
