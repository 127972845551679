// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MeetingInfo_container__2OnXF{display:flex;flex-direction:row}.MeetingInfo_container__2OnXF .MeetingInfo_title__2Xo6a{font-size:21px;font-weight:400;color:#000;padding:20px 0px}.MeetingInfo_container__2OnXF .MeetingInfo_content__1lfB-{margin-right:20px;flex:1 1;background-color:#fff}.MeetingInfo_container__2OnXF .MeetingInfo_content__1lfB- .MeetingInfo_lable__2NWtc{margin:15px 20px}.MeetingInfo_container__2OnXF .MeetingInfo_content__1lfB- .MeetingInfo_uploadImgLable__3vB0A{padding:20px 0px}.MeetingInfo_container__2OnXF .MeetingInfo_content__1lfB- .MeetingInfo_submitBox__2tsiV{margin:50px 0px}.MeetingInfo_locationItem__21QKX{display:flex;justify-content:space-between}.MeetingInfo_locationItem__21QKX .MeetingInfo_district__3mrCr{color:#aaa}\n", ""]);
// Exports
exports.locals = {
	"container": "MeetingInfo_container__2OnXF",
	"title": "MeetingInfo_title__2Xo6a",
	"content": "MeetingInfo_content__1lfB-",
	"lable": "MeetingInfo_lable__2NWtc",
	"uploadImgLable": "MeetingInfo_uploadImgLable__3vB0A",
	"submitBox": "MeetingInfo_submitBox__2tsiV",
	"locationItem": "MeetingInfo_locationItem__21QKX",
	"district": "MeetingInfo_district__3mrCr"
};
module.exports = exports;
