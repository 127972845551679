/**
 * 获取推流地址
 * Create by liaokai on 2020-05-29 14:50:36
 */
import {toPost} from "../../http-utils";

export const authentication = toPost<
    {
        live_id : string
    },
    {
        "server_url": string,
        "stream_sign": string,
        "push_url": string,
        "live_num": string,
        "live_id": string
    }
>("/merchant/live/authentication");

