/**
 * 调研问卷用户回答列表
 * Create by liaokai on 2020-06-17 16:03:52
 */
import {toPost} from "../../http-utils";

export interface ILiveSurveyQuestionnaireByUserAnswerItem {
    "id": number,
    "username": string,
    "commit_time": string,
    "credit": number
}

export const liveSurveyQuestionnaireByUserAnswer = toPost<
    {
        id: string,
        page : number,
        paginate? : number
    },
    {
        "current_page": number,
        "total": number,
        "last_page": number,
        "data": ILiveSurveyQuestionnaireByUserAnswerItem[]
    }
>("/merchant/live/liveSurveyQuestionnaireByUserAnswer");

export const exportliveSurveyQuestionnaireByUserAnswerUrl = toPost<
    {
        id: string,
    },
    {
        url:string
    }
>("/merchant/live/exportliveSurveyQuestionnaireByUserAnswerUrl");

