/**
 * 账户充值
 * Create by liaokai on 2020-04-15 18:15:45
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {SurfaceRouter, tSurfaceRouteItem} from "../../../../components/surface-router/surface-router";
import {PrepaidMoney} from "./index/prepaid-money";

interface IProps extends RouteComponentProps{

}

interface IState{

}

const routerData : tSurfaceRouteItem = {
    breadTitle : "账户充值",
    routeName : "",
    component : PrepaidMoney,
    children : []
};

export class PrepaidMoneyRouter extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={routerData}/>
        );
    }
}
