/**
 * 收费管理
 * Create by liaokai on 2020-05-11 12:29:11
 */
import * as React from 'react';
import {Component} from "react";
import {Button, Input, message} from "antd";
import {Air} from "../../../../../../../components/air/Air";
import {ChargeManageTable} from "./charge-manage-table";
import {RouteComponentProps} from "react-router";
import {apis} from "../../../../../../../common/apis/apis";

const { Search } = Input;

interface IProps extends RouteComponentProps<{meeting_id : string}>{

}

interface IState{
    keyword : string
}

export class ChargeManage extends Component<IProps,IState>{
    readonly state : IState = {
        keyword : ""
    };
    render() {
        let {match : {params}} = this.props;
        let {keyword} = this.state;
        return (
            <div>
                <div style={{display : "flex", justifyContent : "space-between"}}>
                    <Search
                        placeholder="输入搜索内容"
                        onSearch={value => {
                            // alert(JSON.stringify(value));
                            this.setState({keyword : value})
                        }}
                        style={{ width: 200 }}
                    />
                    <div style={{display : "flex"}}>
                        <Button type={"link"} shape={"round"} onClick={()=>{
                            apis.excelExportFee({meetid : params.meeting_id}).then(res => {
                                window.open(res,"_blank");
                            }).catch(e => {
                                message.error("导出失败 "+e.data.message)
                            })
                        }}>导出表格</Button>
                    </div>
                </div>
                <Air vertical={8}/>
                <div>
                    <ChargeManageTable {...this.props} keyword={keyword}/>
                </div>
            </div>
        );
    }
}
