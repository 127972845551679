import {toPost} from "../../http-utils";

export const autoLiveHeatval = toPost<
    {
        live_id:any
    },
    {
        'live_id'?:any,
        'seconds'?:number,
        'status'?:number,
        'min'?:number,
        'max'?:number,
        'uuid'?:string,
    }
    >("/merchant/live/autoHeatval")