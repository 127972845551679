/**
 * 专题详情
 * Create by liaokai on 2020-06-14 11:18:53
 */
import * as React from 'react';
import {Component} from "react";
import {Responsive} from "../../../../components/common/responsive";
import {LiveProkectPreview} from "../live/menu/prokect/preview/live-prokect-preview";
import {RouteComponentProps} from "react-router";
import {LiveProkectEdit} from "../live/menu/prokect/new/live-prokect-edit";
import {Air} from "../../../../components/air/Air";

interface IProps extends RouteComponentProps<{prokect_id : string}>{

}

interface IState{

}

export class ProkectPreview extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <Responsive>
                <Air vertical={10}/>
                <LiveProkectPreview {...this.props}/>
            </Responsive>
        );
    }
}
