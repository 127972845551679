/**
 * 编辑回看视频信息
 * Create by liaokai on 2020-06-19 16:08:17
 */
import * as React from 'react';
import {Component} from "react";
import {Input, message, Modal} from "antd";
import {TitleContentTable} from "../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import {emailAddressBookDestroy} from "../../../../../../common/apis/apis/email/emailAddressBookDestroy";
interface IEmailAddressBookItem {
    "id": number,
    "name": string,
    "count": number, // 创建时间
}
const defaultItem : IEmailAddressBookItem = {
    "id": 0,
    "name": "",
    "count": 0, // 创建时间
}

interface IProps{
    visible : boolean
    onCancel? : () => void
    onSubmit? : () => void
    editItem?: IEmailAddressBookItem
}

interface IState{
    editItem : IEmailAddressBookItem
}

export class EmailDelAddressBook extends Component<IProps,IState>{
    readonly state : IState = {
        editItem : defaultItem
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if(JSON.stringify(this.props.editItem) !== JSON.stringify(prevProps.editItem) ){
            this.setState({editItem : this.props.editItem ?? defaultItem})
        }
    }

    render() {
        let {onCancel,onSubmit,visible} = this.props;
        let {editItem} = this.state;
        return (
            <Modal
                visible={visible}
                onCancel={()=>{
                    onCancel?.();
                }}
                onOk={()=>{
                    console.log(this.props)
                    emailAddressBookDestroy(editItem as any).then(()=>{
                        onSubmit?.()
                        onCancel?.()
                    }).catch(e => {
                        message.error("保存失败 " + e.data?.message)
                    })
                }}
                cancelText={"取消"}
                okText={"确认"}
                title={"删除"}
            >
                <TitleContentTable colGap={10} rowGap={10}>
                    <div>您确认删除么？</div>
                    {/*<Input placeholder={"请输入管理组名"} value={editItem.name} onChange={e => {*/}
                    {/*    editItem.name = e.target.value*/}
                    {/*    this.setState({editItem : editItem})*/}
                    {/*}}/>*/}
                </TitleContentTable>
            </Modal>
        );
    }
}
