/**
 * 问卷调查-取消发布
 * Create by liaokai on 2020-06-16 18:51:31
 */
import {toPost} from "../../http-utils";

export const liveSurveyQuestionnairePublishCancel = toPost<
    {
        id : string
    },
    {

    }
>("/merchant/live/liveSurveyQuestionnairePublishCancel");
