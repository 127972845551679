/**
 * 会议标签和邮件模板列表
 * Create by liaokai on 2020-05-21 11:52:48
 */
import {toPost} from "../../http-utils";
import {IModuleListItem, ITargetLitItem} from "../getModuleListAndTargetGroup";

export const emailTagAndTemplate = toPost<
    {
        meetid : string
    },
    {
        "meetTagList": ITargetLitItem[]
        ,
        "smsTemplateList": IModuleListItem[]
    }
>("/merchant/Email/tagAndTemplate");
