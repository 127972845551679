import React, { Component } from 'react'
import style from './IndexView.scss'
import PhoneSet from './components/PhoneSet'
import PasswordSet from './components/PasswordSet'
import phoneicon from '../../../../assets/phoneicon.png'
import emailicon from '../../../../assets/e-mailicon.png'
import lockicon from  '../../../../assets/lockicon.png'
import {EmailSetting} from "./components/email-setting/email-setting";
import {api} from '../../../../common/app'
import {message} from "antd";

export class IndexView extends Component {
constructor(props) {
  super(props);
  this.state = {
    SettingType:null,
    userinfo:{
        email: null,
        information: null,
        mobile: null,
    }
  };
     this.refreshProps = this.refreshProps.bind(this);
     this.HanldeSettingType = this.HanldeSettingType.bind(this);
     this.getUserInfo = this.getUserInfo.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
  this.getUserInfo();
}
refreshProps(props) {

}
HanldeSettingType(type){
    this.state.SettingType = type;
    this.setState(this.state);
}
getUserInfo(){
    api.getUserInfo().then(res=>{
        console.log(res);
        if (res.code === 200) {
            this.state.userinfo = res.result;
            this.setState(this.state);
        }else{
            message.error(res.message);
        }
    },err=>{
        console.log(err);

    })
}
render() {
  return (
    <div className={style.ViewBox}>
        {/*<div className={[style.ViewTitle,'childcenter childcontentstart'].join(' ')}>*/}
        {/*    <span>账号信息</span>*/}
        {/*</div>*/}
        <div className={style.IndexView}>
            <div className={style.UserID}>
                <div className={style.IDTitle}>账号名称</div>
                <div className={style.IDValue}>{this.state.userinfo.mobile?this.state.userinfo.mobile:''}</div>
            </div>
            <div className={[style.InfoHandleGroup,'childcenter'].join(' ')}>
                <div className={style.GroupColumn}>
                    <div className={style.ColumnName}>登录账号</div>
                    <div className={[style.InfoBox,'childcenter'].join(' ')}>
                        <div className={[style.Icon,'childcenter'].join(' ')}>
                            <img src={phoneicon} alt=""/>
                        </div>
                        <div className={[style.InfoDetail,'childcenter childcolumn'].join(' ')}>
                            <div className={style.DetailTitle}>手机号码</div>
                            <div className={style.Content}>{this.state.userinfo.mobile?this.state.userinfo.mobile:''}</div>
                            <div className={style.HandleEditButton} onClick={this.HanldeSettingType.bind(this,'phone')}><span>修改</span></div>
                        </div>
                    </div>
                </div>
                <div className={style.GroupColumn}>
                    <div className={style.ColumnName}>绑定邮箱</div>
                    <div className={[style.InfoBox,'childcenter'].join(' ')}>
                        <div className={[style.Icon,'childcenter'].join(' ')}>
                            <img src={emailicon} alt=""/>
                        </div>
                        <div className={[style.InfoDetail,'childcenter childcolumn'].join(' ')}>
                            <div className={style.DetailTitle}>电子邮箱</div>
                            <div className={style.Content} title={'feng@rup-china.com'}>{this.state.userinfo.email?this.state.userinfo.email:''}</div>
                            <div className={style.HandleEditButton}><span onClick={this.HanldeSettingType.bind(this,'email')}>{this.state.userinfo.email?'修改':'绑定'}</span></div>
                        </div>
                    </div>
                </div>
                <div className={style.GroupColumn}>
                    <div className={style.ColumnName}>登录密码</div>
                    <div className={[style.InfoBox,'childcenter'].join(' ')}>
                        <div className={[style.Icon,'childcenter'].join(' ')}>
                            <img src={lockicon} alt=""/>
                        </div>
                        <div className={[style.InfoDetail,'childcenter childcolumn'].join(' ')}>
                            <div className={style.DetailTitle}>登录密码</div>
                            {/* <div className={style.Content}>15921743320</div> */}
                            <div className={style.HandleEditButton} onClick={this.HanldeSettingType.bind(this,'password')}><span>修改</span></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.SettingBox}>
                {this.state.SettingType === 'phone'?<div className={[style.Floatright,'childcenter childcontentstart'].join(' ')}>
                    <PhoneSet onClose={this.HanldeSettingType.bind(this,null)}/>
                </div>:''}
                {this.state.SettingType === 'email'?<div className={[style.Floatright,'childcenter'].join(' ')} style={{justifyContent: "center"}}>
                    <EmailSetting onClose={this.HanldeSettingType.bind(this,null)} onSuccess={()=>{window.location.reload()}}/>
                </div>:''}
                {this.state.SettingType === 'password'?<div className={[style.Floatright,'childcenter childcontentend'].join(' ')}>
                    <PasswordSet onClose={this.HanldeSettingType.bind(this,null)} info={this.state.userinfo}/>
                </div>:''}
            </div>
        </div>
    </div>
   )
   }
}
export default IndexView
