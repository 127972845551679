// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ImportTemplateView_ImportTemplateView__1K6lf{width:calc(100% - 102px);min-height:280px;padding:0 51px}.ImportTemplateView_ImportTemplateView__1K6lf .ImportTemplateView_TextTips__c50V0{width:100%;margin-bottom:24px}.ImportTemplateView_ImportTemplateView__1K6lf .ImportTemplateView_TextTips__c50V0 span:nth-child(1){font-size:18px;font-weight:400;color:#000;margin-bottom:24px}.ImportTemplateView_ImportTemplateView__1K6lf .ImportTemplateView_TextTips__c50V0 span:nth-child(2){font-size:14px;font-family:Source Han Sans CN;font-weight:400;line-height:24px;color:#000;opacity:0.4}.ImportTemplateView_ImportTemplateView__1K6lf .ImportTemplateView_DownloadButton__1C4Dc{width:198px;height:48px;position:relative;box-shadow:0px 0px 10px rgba(0,0,0,0.16);border-radius:8px;font-size:16px;font-weight:400;color:#fff;cursor:pointer;overflow:hidden;background:#00b546}.ImportTemplateView_ImportTemplateView__1K6lf .ImportTemplateView_DownloadButton__1C4Dc .ImportTemplateView_grayscale__23aYk{-webkit-filter:invert(20%);filter:invert(20%)}.ImportTemplateView_ImportTemplateView__1K6lf .ImportTemplateView_DownloadButton__1C4Dc :nth-child(1){width:35px;margin-right:11px;position:relative;z-index:1}.ImportTemplateView_ImportTemplateView__1K6lf .ImportTemplateView_DownloadButton__1C4Dc :nth-child(2){position:relative;z-index:1}\n", ""]);
// Exports
exports.locals = {
	"ImportTemplateView": "ImportTemplateView_ImportTemplateView__1K6lf",
	"TextTips": "ImportTemplateView_TextTips__c50V0",
	"DownloadButton": "ImportTemplateView_DownloadButton__1C4Dc",
	"grayscale": "ImportTemplateView_grayscale__23aYk"
};
module.exports = exports;
