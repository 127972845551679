import React, {Component} from 'react'
import {MenuVertical, tMenuVerticalTree} from "../../../components/menu-vertical/menu-vertical";
import {RouteComponentProps} from "react-router";
import {Responsive} from "../../../components/common/responsive";
import {MenuContent} from "../../../components/menu-content/menu-content";
import {ProceedsRouter} from "./proceeds/router";
import {AccountInfoRouter} from "./account_info/account-info-router";
import {CashRecordRouter} from "./cashrecord/cash-record-router";
import {SetCashWayRouter} from "./setcashway/set-cash-way-router";
import {PrepaidRecord} from "./prepaid-record/index/prepaid-record";
import {PaymentRecord} from "./payment-record/index/payment-record";
import {PrepaidMoneyRouter} from "./prepaid-money/router";
import {PrepaidRecordRouter} from "./prepaid-record/router";
import {PaymentRecordRouter} from "./payment-record/router";
import {storeMenu} from "../../../common/menu.store";
import {Air} from "../../../components/air/Air";


interface IProps extends RouteComponentProps{

}

interface IState {
    // routerName : string
}

export type tMenuVerticalTreeWithComp = tMenuVerticalTree<{
    component : React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}>

const menuData : tMenuVerticalTreeWithComp = [
    {
        title : "账号",
        items : [
            {
                title : "账号信息",
                routerName : "account_info",
                plusField : {
                    component : AccountInfoRouter
                }
            }
        ]
    },
    {
        title : "运营账户",
        items : [
            {
                title : "账户充值",
                routerName : "prepaid-money",
                plusField : {
                    component : PrepaidMoneyRouter
                }
            },
            {
                title : "收支记录",
                routerName : "prepaid_record",
                plusField : {
                    component : PrepaidRecordRouter
                }
            },
            {
                title : "订单列表",
                routerName : "payment-record",
                plusField : {
                    component : PaymentRecordRouter
                }
            }
        ]
    },
    {
        title : "收益账户",
        items : [
            {
                title : "收益总览",
                routerName : "proceeds",
                plusField : {
                    component : ProceedsRouter
                }
            },
            {
                title : "提现记录",
                routerName : "cashrecord",
                plusField : {
                    component : CashRecordRouter
                }
            },
            {
                title : "收款设置",
                routerName : "setcashway",
                plusField : {
                    component : SetCashWayRouter
                }
            }
        ]
    },
];


export class UserIndexView extends Component<IProps, IState> {
    render() {
        return (
            <Responsive>
                <Air vertical={25}/>
                <MenuContent ref={ref => storeMenu.setAccountMenuRef(ref)} {...this.props} menuData={menuData} defaultRouterName={"account_info"}/>
            </Responsive>
        )
    }
}
