/**
 * 直播总览
 * Create by liaokai on 2020-05-23 17:34:43
 */
import * as React from 'react';
import {Component} from "react";
import {Texts} from "../../../../../../components/text/Texts";
import {Button, Descriptions, Input, notification, Popover, Statistic} from "antd";
import {common} from "../../../../../../common/common";
import {FlexJustifyBetween} from "../../../../../../components/style/flex/flex-justify-between";
import {Flex} from "../../../../../../components/style/flex/flex";
import {Qrcode} from "../../../../../../components/common/qrcode";
import {FlexCol} from "../../../../../../components/style/flex/flex-col";
import copy from "copy-to-clipboard";
import styled from "styled-components";
import {LiveDataCountViewStateful} from "./component/live-data-count-view-stateful";
import {ILiveShow, liveShow, pushItem} from "../../../../../../common/apis/apis/live/liveShow";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import {liveStore} from "../live.store";
import {TitleContentTable} from "../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import {Air} from "../../../../../../components/air/Air";
import {Flex1} from "../../../../../../components/style/flex/flex-1";
import {FlexColHorCenter} from "../../../../../../components/style/flex/flex-col-hor-center";
import {LiveShareSetting} from "./component/share-setting";
import style from "../../../../meeting-list/meeting/menu/overview/overview.scss";
import {Radio} from "../../../../../../components/common/radio";
import {CopyLink} from "./component/copy-link";
import {Subscribe} from "./component/subscribe";

interface IProps extends RouteComponentProps<{live_id? : string}>{

}

interface IState{
    showShareSetting : boolean
    showSubscribe :boolean
    live_status : string
}

@observer
export class LiveOverviewView extends Component<IProps,IState>{
    readonly state : IState = {
        showShareSetting : false,
        showSubscribe : false,
        live_status : liveStore.liveInfo.live_status
    };
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {

        let {} = this.props;
        let {showShareSetting,showSubscribe,live_status} = this.state;
        const data = liveStore.liveInfo
        return (
            <>
            <div>
                {/*直播信息*/}
                <Flex>
                    <Flex1>
                        <Texts.ItemTitle>
                            直播信息
                        </Texts.ItemTitle>
                        <Air vertical={8}/>
                        <Flex>
                            <Air horizontal={15}/>
                            <TitleContentTable colGap={20} rowGap={15}>
                                <Title>主题:</Title>
                                <div>{data.title}</div>
                                <Title>直播时间:</Title>
                                <div>{data.live_time}</div>
                                <Title>状态:</Title>

                                <div style={{display:"flex", alignItems: 'center'}}>
                                    {
                                        data.live_status === "预告" ? data.live_status
                                            :
                                            [
                                                <div style={{textAlign:"center"}}> {data.live_status} </div>
                                    ,
                                        <Air horizontal={5} />
                                        ,
                                        <Button onClick={
                                            ()=>{
                                                this.setState({showSubscribe:true})
                                            }
                                        } size="small">
                                            恢复为预告
                                        </Button>
                                    ]

                                }</div>
                                <Title>观看限制:</Title>
                                <div>{data.astrict}</div>
                                <Title>报名表单:</Title>
                                <div>{data.apply_form}</div>
                                <Title>观看地址:</Title>
                                <Flex>
                                    <div style={{width : 400}}>
                                        <CopyLink link={data.look_address} showQr={true} downloadQr={true}/>
                                    </div>
                                </Flex>
                                {data?.push_info.map((item: pushItem) => {
                                    const { title, push_url } = item;
                                    return <>
                                        <Title>{title}:</Title>
                                        <Flex>
                                            <div style={{ width: 400 }}>
                                                <Input
                                                    size="small"
                                                    value={push_url}
                                                    suffix={
                                                        <Button
                                                            type="link"
                                                            onClick={() => {
                                                                copy(push_url);
                                                                notification.success({
                                                                    message: '链接已复制',
                                                                    description: push_url,
                                                                    duration: 2.2,
                                                                    style: {wordBreak: "break-word"}
                                                                });
                                                            }}
                                                        >复制链接</Button>
                                                    }
                                                />
                                            </div>
                                        </Flex>
                                    </>
                                })}
                                {/*<Title>推流地址:</Title>*/}
                                {/*<Flex>*/}
                                {/*    <div style={{width : 400}}>*/}
                                {/*        <Input*/}
                                {/*            size={"small"}*/}
                                {/*            value={data.push_url}*/}
                                {/*            suffix={*/}
                                {/*                <Button type={"link"} onClick={()=>{*/}
                                {/*                    copy(data.push_url);*/}
                                {/*                    notification.success({*/}
                                {/*                        message: `链接已复制`,*/}
                                {/*                        description: data.push_url,*/}
                                {/*                        duration: 2.2,*/}
                                {/*                        style: {wordBreak: "break-word"}*/}
                                {/*                    });*/}
                                {/*                }}>复制链接</Button>*/}
                                {/*            }*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</Flex>*/}
                            </TitleContentTable>
                        </Flex>
                    </Flex1>
                    <div>
                        <div>
                            <img src={data.poster} alt="" style={{width: '400px'}}/>
                        </div>
                        <Air vertical={3}/>
                        <Button onClick={()=>{
                            this.setState({showShareSetting : true})
                        }}>分享设置</Button>
                    </div>
                </Flex>
                {/*/!*分享链接*!/*/}
                {/*<Air vertical={20}/>*/}
                {/*<div>*/}
                {/*    <Texts.ItemTitle>*/}
                {/*        分享链接*/}
                {/*    </Texts.ItemTitle>*/}
                {/*    <Air vertical={10}/>*/}
                {/*    <Flex>*/}
                {/*        <Air horizontal={15}/>*/}
                {/*        <TitleContentTable colGap={20} rowGap={15}>*/}
                {/*            <Title>微信端:</Title>*/}
                {/*            <Flex>*/}
                {/*                <div style={{width : 400}}>*/}
                {/*                    <CopyLink link={data.show_address} showQr={true} downloadQr={true}/>*/}
                {/*                </div>*/}
                {/*            </Flex>*/}
                {/*            <Title>电脑端:</Title>*/}
                {/*            <Flex>*/}
                {/*                <div style={{width : 400}}>*/}
                {/*                    <CopyLink link={data.pc_address} showQr={true} downloadQr={true}/>*/}
                {/*                </div>*/}
                {/*            </Flex>*/}
                {/*        </TitleContentTable>*/}
                {/*    </Flex>*/}
                {/*</div>*/}
                {/*直播数据*/}
                <Air vertical={20}/>
                <div>
                    <div>
                        <Texts.ItemTitle>
                            直播数据
                        </Texts.ItemTitle>
                        <Air vertical={15}/>
                        <Flex>
                            <Air horizontal={15}/>
                            <LiveDataCountViewStateful/>
                        </Flex>
                    </div>
                    {/*分享设置*/}
                    <LiveShareSetting visible={showShareSetting} onCancel={()=>{
                        this.setState({showShareSetting : false})
                    }}/>
                    <Subscribe visible={showSubscribe} onCancel={()=>{
                        this.setState({showSubscribe : false})
                    }}
                               onSubmit={()=>{
                                   this.setState({ showSubscribe: false });
                                   liveStore.reload();
                               }}
                    />
                </div>
            </div>
            </>

        );
    }
}

const Title = styled(Texts.TableTitle)`
  display: flex;
  justify-content: flex-end;
`
