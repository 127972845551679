/**
 * 关键词列表
 * Create by liaokai on 2020-05-26 17:31:46
 */
import * as React from 'react';
import {Input, message, Tag, Tooltip} from "antd";
import {PlusOutlined} from "@ant-design/icons/lib";
import styled from "styled-components";
import {common} from "../../../../../../../common/common";

interface IProps<T>{
    data : T[]
    onChange? : (add? : T, reduce? : T) => Promise<T>
    extractTitle : (item : T) => string
    createNewItem : (keyword : string) => T
}

interface IState<T>{
    stateData : T[]
    showInput : boolean
    inputValue : string
}

export class EditableTagGroup<T> extends React.Component<IProps<T>, IState<T>> {
    readonly state: IState<T> = {
        stateData : this.props.data,
        showInput : false,
        inputValue : ""
    };

    input = React.createRef<Input>()

    componentWillReceiveProps(nextProps: Readonly<IProps<T>>, nextContext: any) {
        if(JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)){
            this.setState({stateData : nextProps.data})
        }
    }

    addNew = (newWord : string) => {
        const {onChange, extractTitle, createNewItem} = this.props
        const {stateData} = this.state
        if(this.state.inputValue){
            this.setState({inputValue: ""})
            if(stateData?.filter(value => extractTitle(value) === this.state.inputValue)?.length){
                message.error(`已经存在'${newWord}'`)
                return;
            }
            const dataCp = common.deepClone(stateData)  //当前关键词列表
            const newItem = createNewItem(newWord)      //新关键词列表对象
            this.setState({stateData : stateData?.concat([newItem])})   //增加关键词
            onChange?.(newItem, undefined).then(res => {
                this.setState({stateData : stateData?.concat([res])})   //替换新增关键词对象
            }).catch(e => {
                this.setState({stateData : dataCp})     //还原关键词列表
            })
        }
    }

    render() {
        const { onChange, extractTitle} = this.props
        const {showInput, inputValue, stateData} = this.state;
        return (
            <>
                {
                    stateData?.map(value => <TagView visible={true} closable onClose={()=>{
                        const {onChange, extractTitle} = this.props
                        const {stateData} = this.state
                        const dataCp = common.deepClone(stateData)  //当前关键词列表
                        this.setState({
                            stateData : stateData?.filter(value1 => extractTitle(value1) !== extractTitle(value))   //减少关键词
                        })
                        onChange?.(undefined, value).then(res => {
                            //删除成功
                        }).catch(e => {
                            this.setState({stateData : dataCp})     //还原关键词列表
                        })
                    }}>
                        {extractTitle(value)}
                    </TagView>)
                }
                {
                    showInput ?
                        <div>
                            <Input
                                ref={this.input}
                                type="text"
                                value={inputValue}
                                onChange={(e) => this.setState({inputValue: e.target.value})}
                                onBlur={()=>{
                                    this.setState({showInput: false},()=>{
                                        this.addNew(this.state.inputValue)
                                    })
                                }}
                                onPressEnter={()=>{
                                    this.setState({showInput: false},()=>{
                                        this.addNew(this.state.inputValue)
                                    })
                                }}
                                style={{width : 90,marginTop : 5}}
                            />
                        </div>
                        :
                        <TagView style={{borderStyle : "dashed"}} onClick={()=>{
                            this.setState({showInput : true},()=>{
                                this.input?.current?.focus();
                            });
                        }}>
                            <PlusOutlined /> 新关键词
                        </TagView>
                }
            </>
        )

    }
}

const TagView = styled(Tag)`
  margin-top: 5px;
  padding: 5px 15px;
`
