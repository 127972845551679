import React, { useState, useRef, useEffect, useCallback } from "react";
import {
    Form,
    Table,
    Switch,
    Button,
    message,
    Spin,
    Input,
    Modal,
    Avatar,
    Popconfirm,
} from "antd";
import { useAntdTable, useBoolean, useMount } from "ahooks";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
    formatListResult,
    liveVenueIndex,
    liveVenueDestroy,
    liveVenueCateStore,
    liveVenueCateIndex,
    liveVenueCateUpdate,
    liveVenueCateDestroy,
} from "./constants";
import { liveStore } from "../live.store";
import { Air } from "../../../../../../components/air/Air";
import { enableShowSet } from "../../../../../../common/apis/apis/live/enableShowSet";
import { FlexJustifyBetween } from "../../../../../../components/style/flex/flex-justify-between";
import { Flex } from "../../../../../../components/style/flex/flex";
import { enableShowInfo } from "../../../../../../common/apis/apis/live/enableShowInfo";
// import Screen from "./component/screen";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

const { TextArea } = Input;
const { Search } = Input;

function Venue() {
    const history = useHistory();
    const [form] = Form.useForm();
    const [editForm] = Form.useForm();
    const [tableEnable, { toggle }] = useBoolean(false);
    const [value, setValue] = useState("");
    const [currentCate, setCurrentCate] = useState({} as any);
    const [editCate, setEditCate] = useState({} as any);
    const [editVisible, setEditVisible] = useState(false);

    useMount(() => {
        enableShowInfo({
            live_id: liveStore.live_id,
            type: 13,
        }).then((res) => {
            toggle(res.is_show);
        });
    });

    useEffect(() => {
        liveVenueCateIndex({
            live_id: liveStore.live_id,
        }).then((response) => {
            if (response && response[0]) {
                setCurrentCate(response[0]);
            }
        });
    }, []);

    const afterDeleteCallback = useCallback(() => {
        liveVenueCateIndex({
            live_id: liveStore.live_id,
        }).then((response) => {
            if (response && response[0]) {
                setCurrentCate(response[0]);
            }
        });
    }, []);

    const changeShowSet = () => {
        enableShowSet({
            live_id: liveStore.live_id,
            type: 13,
        })
            .then((res) => {
                toggle(res.is_show);
            })
            .catch((e) => {
                message.error("设置失败 " + e.data?.message);
            });
    };

    const onAdd = () => {
        history.push(`venue/add`);
    };

    const { tableProps, search } = useAntdTable(
        (paginatedParams: any, tableProps: any) => {
            return liveVenueIndex({
                live_venue_cate_id: currentCate?.id,
                page: paginatedParams.current,
                paginate: 15,
                ...tableProps,
            }).then((response) => {
                return response;
            });
        },
        { form, formatResult: formatListResult, defaultPageSize: 15 }
    );
    const { submit, reset } = search;

    /**
     * 点击分类之后设置当前分类
     */
    useEffect(() => {
        submit();
    }, [currentCate]);

    const cateProps = useAntdTable(
        (paginatedParams: any, tableProps: any) => {
            return liveVenueCateIndex({
                live_id: liveStore.live_id,
                ...tableProps,
            }).then((response) => {
                return response;
            });
        },
        {
            formatResult: (response) => {
                return {
                    list: response,
                    total: undefined,
                } as any;
            },
        }
    );

    const onEditCate = () => {
        const values = editForm.getFieldsValue();
        const isEdit = !!editCate.id;
        const text = isEdit ? "修改" : "新增";
        const fetchUrl = isEdit ? liveVenueCateUpdate : liveVenueCateStore;

        const payload: any = isEdit
            ? {
                  live_venue_cate_id: editCate.id,
                  title: values.title,
                  sort: values.sort,
              }
            : {
                  live_id: liveStore.live_id,
                  title: values.title,
                  sort: values.sort,
              };
        fetchUrl(payload)
            .then((response) => {
                message.success(`${text}成功！`);
            })
            .catch(() => {
                message.info(`${text}分类失败！`);
            })
            .finally(() => {
                setEditCate({});
                setEditVisible(false);
                cateProps?.search?.submit();
            });
    };

    const columns = [
        {
            title: "直播标题",
            dataIndex: "title",
        },
        {
            title: "封面图",
            dataIndex: "poster",
            render: (img: any) => {
                return <Avatar src={img} size="large" shape="square" />;
            },
        },
        {
            title: "排序",
            dataIndex: "sort",
        },
        {
            title: "操作",
            render: (item: any) => {
                return (
                    <div>
                        <Link to={`venue/edit/${item.id}`}>
                            <Button type={"link"} style={{ paddingLeft: 0 }}>
                                编辑
                            </Button>
                        </Link>

                        {`  `}
                        <Button
                            onClick={() => {
                                liveVenueDestroy({ live_venue_id: item.id })
                                    .then((response) => {
                                        message.success("删除成功");
                                    })
                                    .catch(() => {
                                        message.info("删除失败");
                                    })
                                    .finally(() => {
                                        submit();
                                    });
                            }}
                            type={"link"}
                            style={{ paddingLeft: 0 }}
                        >
                            删除
                        </Button>
                    </div>
                );
            },
        },
    ];

    const cateColumn: any = [
        {
            title: "全部分组",
            dataIndex: "title",
            key: "title",
            align: "center",
            render: (text: boolean, item: any) => {
                return (
                    <div
                        style={{
                            background:
                                item.id === currentCate.id ? "#f1f1f1" : "",
                        }}
                    >
                        <span
                            onClick={() => setCurrentCate(item)}
                            style={{
                                display: "inline-block",
                                marginRight: "5px",
                            }}
                        >
                            {text}
                        </span>
                        <EditOutlined
                            style={{ cursor: "pointer", marginRight: "2px" }}
                            onClick={() => {
                                editForm.setFieldsValue({
                                    title: item.title,
                                    sort: item.sort,
                                });
                                setEditCate(item);
                                setEditVisible(true);
                            }}
                        />
                        <Popconfirm
                            title="删除分组后会删除分组内所有资料，确认删除分组?"
                            onConfirm={() => {
                                liveVenueCateDestroy({
                                    live_venue_cate_id: item.id,
                                })
                                    .then((response) => {
                                        message.success("删除成功");
                                    })
                                    .catch(() => {
                                        message.info("删除失败");
                                    })
                                    .finally(() => {
                                        cateProps.search.reset();
                                        afterDeleteCallback();
                                    });
                            }}
                            okText="确定"
                            cancelText="取消"
                        >
                            <DeleteOutlined style={{ cursor: "pointer" }} />
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];
    return (
        <div>
            <Form form={form}>
                <FlexJustifyBetween
                    style={{ marginBottom: 12, alignItems: "center" }}
                >
                    <Flex>
                        <Switch
                            checked={tableEnable}
                            onChange={changeShowSet}
                        />
                        <Air horizontal={3} />
                        <div>开启分会场</div>
                        <Air horizontal={5} />
                    </Flex>
                    <Flex>
                        <Button
                            onClick={onAdd}
                            type={"primary"}
                            disabled={!tableEnable}
                        >
                            新增会场
                        </Button>
                    </Flex>
                </FlexJustifyBetween>
            </Form>

            <Spin
                indicator={<div />}
                tip="尚未开启分会场"
                spinning={!tableEnable}
            >
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: 3 }}>
                        <Table rowKey="id" columns={columns} {...tableProps} />
                    </div>

                    <WhiteGroupWrapper>
                        <WhiteGroup>
                            <Table
                                rowKey="id"
                                columns={cateColumn}
                                {...cateProps.tableProps}
                                emptyText={"暂无内容"}
                                pagination={false}
                            />
                            <Button
                                type="primary"
                                style={{ marginTop: "10px" }}
                                onClick={() => {
                                    setEditCate({});
                                    setEditVisible(true);
                                }}
                            >
                                添加分组
                            </Button>
                        </WhiteGroup>
                    </WhiteGroupWrapper>
                </div>
            </Spin>

            <Modal
                okText="确定"
                cancelText="取消"
                onCancel={() => setEditVisible(false)}
                onOk={() => onEditCate()}
                title={`${!!editCate.id ? "修改" : "新增"}分类`}
                visible={editVisible}
            >
                <Form form={editForm}>
                    <Form.Item
                        label="分类标题"
                        name="title"
                        rules={[{ required: true, message: "请输入分类标题" }]}
                    >
                        <Input maxLength={8} />
                    </Form.Item>
                    <Form.Item
                        label="分类排序"
                        name="sort"
                        rules={[{ required: true, message: "请输入分类排序" }]}
                    >
                        <Input type="number" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export { Venue };

const WhiteGroupWrapper = styled.div`
    width: 150px;
    text-align: center;
    margin-left: 5px;
`;
const WhiteGroup = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px;
`;
const AOper = styled.a`
    display: inline-block;
    margin-right: 10px;
`;
