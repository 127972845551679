/**
 * 支付记录列表
 * Create by liaokai on 2020-04-30 14:12:14
 */
import * as React from 'react';
import {Component} from "react";
import {Button, message, Table} from "antd";
import {getOrderRecordTable, IOrderRecordTableItem} from "../../../../../../common/apis/apis/getOrderRecordTable";
import {apis} from "../../../../../../common/apis/apis";
import {BuySms} from "../../../../meeting-list/meeting/menu/sms-multi-send/buy-sms/buy-sms";
import {common} from "../../../../../../common/common";
import {OrderPayAndCashier} from "../../../../meeting-list/meeting/menu/sms-multi-send/buy-sms/order-pay/order-pay-and-cashier";
import {IRechargeImgWechat} from "../../../../../../common/apis/apis/get-recharge-img";
import {IChargeManagement} from "../../../../../../common/apis/apis/chargeManagement";

interface IProps{

}

interface IState{
    columns : any,
    dataSource : IOrderRecordTableItem[],
    total : number,
    currentPage : number,
    showOrderPay : boolean,
    orderData : IRechargeImgWechat | undefined,
    loading : boolean
}

export class PaymentTable extends Component<IProps,IState>{
    readonly state : IState = {
        columns : [
            {
                title: '订单号',
                dataIndex: 'orderId',
                key: 'orderId',
                render: (text: string) => text,
            },
            {
                title: '产品',
                dataIndex: 'product',
                key: 'product',
                render: (text: string) => text,
            },
            {
                title: '类型',
                dataIndex: 'type',
                key: 'type',
                render: (text: string) => text,
            },
            {
                title: '创建时间',
                dataIndex: 'createData',
                key: 'createData',
                render: (text: string) => text,
            },
            {
                title: '金额',
                dataIndex: 'price',
                key: 'price',
                render: (text: string) => text,
            },
            {
                title: '支付方式',
                dataIndex: 'payMethod',
                key: 'payMethod',
                render: (text: string) => text,
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text: string, record : IOrderRecordTableItem & {data : any}, index : number) => <div>
                    {(()=>{
                        switch (record.status) {
                            case 1 :
                                return <div style={{display : "flex", alignItems : "center"}}>
                                    <Button
                                        type={"link"}
                                        style={{padding : 0}}
                                        onClick={()=>{
                                        //@ts-ignore
                                        this.setState({orderData : {
                                                    "money": record.data?.price,
                                                    "orderNum": record.data.order_num,
                                                    "info": {
                                                        "title": record.data?.title,
                                                    },
                                                    "code_url": record.data?.pay_url,
                                                    "create_time": record.data?.create_time,
                                                    "pay_type": record.data?.pay_type  // 1/微信支付 2/支付宝 3/运营账户余额
                                            }, showOrderPay : true})
                                        }}
                                    >
                                        支付
                                    </Button>
                                    <div style={{margin : "0px 5px", color : common.color.unactivated}}>|</div>
                                    <Button
                                        type={"link"}
                                        style={{padding : 0}}
                                        onClick={()=>{
                                            apis.closeOrder({order_num : record.data.order_num}).then(res => {
                                                message.success("操作成功")
                                                this.refershTable();
                                            }).catch(e => {
                                                message.error("销毁订单失败 "+e.data?.message);
                                            })
                                        }}
                                    >
                                        作废
                                    </Button>
                                </div>;
                            case 2 :
                                return "已支付";
                            case 3 :
                                return "已作废";
                            case 4 :
                                return "已过期"
                        }
                    })()}
                </div>,
            },
        ],
        currentPage : 0,
        total : 0,
        dataSource :[],
        showOrderPay : false,
        orderData : undefined,
        loading : false
    };

    refershTable = () => {
        this.reqListData(this.state.currentPage)
    }

    reqListData(page: number = 1){
        this.setState({loading : true})
        apis.getOrderRecordTable({
            page,
        }).then(res => {
            this.setState({dataSource : res.list.map(value => ({
                    orderId : value.order_num,
                    product : value.title,
                    type : value.type,
                    createData : value.create_time,
                    price : `${value.price} 元`,
                    payMethod : BuySms.payMethodName(value.pay_type),
                    status : value.status,
                    data : value
            })), total : res.total, currentPage : page})
        }).finally(()=>{
            this.setState({loading : false})
        })
    }

    componentDidMount() {
        this.reqListData();
    }

    render() {
        let {} = this.props;
        let {columns,dataSource, currentPage, total, showOrderPay, orderData, loading} = this.state;
        return (
            <div>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={dataSource}
                    pagination={{
                        current : currentPage,
                        total : total,
                        onChange : page => {
                            this.reqListData(page);
                            this.setState({currentPage : page})
                        }
                    }}
                >
                </Table>
                <OrderPayAndCashier
                    show={showOrderPay}
                    onCancel={()=>{
                        this.setState({showOrderPay : false})
                    }}
                    pay_type={orderData?.pay_type}
                    orderData={orderData}
                    onPaySuccess={()=>{
                        this.refershTable();
                        this.setState({showOrderPay : false})
                    }}
                />
            </div>

        );
    }
}
