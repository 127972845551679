/**
 * 获取收费管理列表
 * Create by liaokai on 2020-05-11 14:08:32
 */
import {toPost} from "../http-utils";

export interface IChargeManagement {
    "id":number,
    "jorderno": string,
    "norderno": string,
    "meetid": number,
    "uid": number,
    "type": string,
    "money": string,
    "status": string,
    "pay_time":string,
    "remark":string,
    "is_del":number,
    "create_time":string,
    "update_time":string,
    "username":string,
    "mobile": string
}

export const chargeManagement = toPost<
    {
        meetid : string
        keyword : string
        page : number
        pageNum : number
    },
    {
        "data": IChargeManagement[]
        ,
        "count":number,
        "page_count":number
    }
>("/merchant/meeting/chargeManagement");
