/**
 * 获取短信模板、目标群体的选项（tagAndTemplate）
 * Create by liaokai on 2020-04-24 14:09:55
 */
import {toPost} from "../http-utils";

export interface IModuleListItem {
    "id": number,
    "title": string
}

export interface ITargetLitItem {
    "name": string,
    "list": {
        "name": string,
        "id": number
    }[]
}

export const getModuleListAndTargetGroup = toPost<
    {
        meetid : string
    },
    {
        "meetTagList": ITargetLitItem[]
        ,
        "smsTemplateList": IModuleListItem[]
    }
>("/merchant/Lcm/tagAndTemplate");

