/**
 * ppt预览
 * Create by liaokai on 2020-05-30 13:55:53
 */
import * as React from 'react';
import {Component} from "react";
import {Carousel} from "antd";
import styled from "styled-components";
import {LeftCircleOutlined, LeftOutlined, RightCircleOutlined, RightOutlined} from "@ant-design/icons/lib";
import {common} from "../../../../../../../common/common";

interface IProps{
    imgs?: string[],
    showReview?: boolean
}

interface IState{
    currentPage: number,
    imgLoaded: boolean
}

export class PptReview extends Component<IProps,IState>{
    readonly state: IState = {
        currentPage: 1,
        imgLoaded: false
    };

    public carousel = React.createRef<Carousel>();

    componentDidUpdate() {
        const { showReview } = this.props;
        if (!showReview) {
            this.setState({currentPage: 1, imgLoaded: false});
        }
        this.preLoadImg();
    }

    shouldComponentUpdate(nextProps: any) {
        const { showReview } = this.props;
        if (!showReview && showReview === nextProps.showReview) {
            return false;
        }
        return true;
    }

    preLoadImg() {
        let { imgs = [] } = this.props;

        if (imgs?.length > 0) {
            let imgTemp = new Image();
            imgTemp.src = imgs[0];
            imgTemp.onload = () => {
                this.setState({
                    imgLoaded: true
                });
            };
        }
    }

    render() {
        let {imgs} = this.props;
        let { currentPage, imgLoaded } = this.state;
        return (
            <Container>
                {imgLoaded ? <Carousel
                    ref={this.carousel}
                    dots={false}
                    afterChange={(current) => {
                        this.setState({ currentPage: current + 1 });
                    }}
                >
                    {
                        imgs?.map(value => (
                            <img src={value} alt="test" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                        ))
                    }
                </Carousel> : <ImgLoading>正在加载中...</ImgLoading>}
                <LRBox>
                    <LeftOutlined onClick={()=>{
                        this.carousel?.current?.prev();
                    }}/>
                    <RightOutlined onClick={()=>{
                        this.carousel?.current?.next();
                    }}/>
                </LRBox>
                <Pagination>{currentPage}/{imgs?.length}</Pagination>
            </Container>
        );
    }
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: ${common.color.primary};
`

const LRBox = styled.div`
  position:absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 40px;
  z-index: 999999;
`

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
`

const ImgLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
