/**
 * 直播间可绑定列表
 * Create by liaokai on 2020-06-15 13:58:22
 */
import {toPost} from "../../http-utils";

export interface IAllowBindLiveIndexItem {
    "live_id": number, //直播间id
    "title": string, //标题
    "poster": string,//封面的url
    "live_time": string//直播时间
}

export const allowBindLiveIndex = toPost<
    {
        live_id : string
        page : number,
        paginate? : number
    },
    {
        "current_page": number,
        "total": number,
        "last_page": number,
        "data": IAllowBindLiveIndexItem[]
    }
>("/merchant/live/allowBindLiveIndex");
