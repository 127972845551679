/**
 * 编辑回看视频信息
 * Create by liaokai on 2020-06-19 16:08:17
 */
import * as React from 'react';
import {Component} from "react";
import {Input, message, Modal} from "antd";
import {TitleContentTable} from "../../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import {IliveRecordIndexItem} from "../../../../../../../common/apis/apis/live/liveRecordIndex";
import {liveRecordUpdate} from "../../../../../../../common/apis/apis/live/liveRecordUpdate";
import {publishRecord} from "../../../../../../../common/apis/apis/live/publishRecord";

const defaultItem : IliveRecordIndexItem = {
    "id": 0,
    "title": "",
    "publish_status": "",
    "poster": "",
    "run_time": "",
    "expiration_time": "", //过期时间
    tanscode_status:1,
    "status": "", //状态 1 代表回放
    "is_default": false, //是否默认
    "create_time": "", // 创建时间
}

interface IProps{
    visible : boolean
    onCancel? : () => void
    onSubmit? : () => void
    currentItem?: IliveRecordIndexItem
}

interface IState{
    currentItem : IliveRecordIndexItem,
}

export class LivePublishRecordVideo extends Component<IProps,IState>{
    readonly state : IState = {
        currentItem : defaultItem,
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if(JSON.stringify(this.props.currentItem) !== JSON.stringify(prevProps.currentItem) ){
            this.setState({currentItem : this.props.currentItem ?? defaultItem})
        }
    }
    render() {
        let {onCancel,onSubmit,visible} = this.props;
        let {currentItem} = this.state;
        return (
            <Modal
                visible={visible}
                onCancel={()=>{
                    onCancel?.();
                }}
                onOk={()=>{
                    // liveRecordUpdate(currentItem as any).then(()=>{
                    //     onSubmit?.()
                    //     onCancel?.()
                    // }).catch(e => {
                    //     message.error("保存失败 " + e.data?.message)
                    // })
                    publishRecord({
                        id : currentItem.id.toString()
                    }).then(()=>{
                        let  IMessage = currentItem.is_default ? '取消默认回放成功':"设置默认回放成功"
                        message.success(IMessage)
                            onSubmit?.()
                            onCancel?.()
                        }).catch(e => {
                        message.error("保存失败 "+ e.data?.message)
                    })
                }}
                cancelText={"取消"}
                okText={"保存"}
                title={"设置回放"}
            >
                <TitleContentTable colGap={10} rowGap={10}>
                    <div>{currentItem.is_default ? '取消默认回放后将无法进行观看' : '设置成默认回放后，将无法进行预约'}</div>
                </TitleContentTable>
            </Modal>
        );
    }
}
