/**
 * 文档预览
 * Create by liaokai on 2020-05-29 19:29:04
 */
import * as React from 'react';
import {Component} from "react";
import {Carousel} from "antd";
import {RouteComponentProps} from "react-router";
import {docShow, IDocShowItem} from "../../../../../../../common/apis/apis/live/docShow";
import {PptReview} from "./ppt-review";

interface IProps extends RouteComponentProps<{"live_id":string,"doc_id":string}>{

}

interface IState{
    docPages : IDocShowItem[]
}

export class LiveDocPreview extends Component<IProps,IState>{
    readonly state : IState = {
        docPages : []
    };

    componentDidMount() {
        docShow({
            live_doc_id : this.props?.match?.params?.doc_id
        }).then(res => {
            this.setState({docPages : res.doc_son})
        })
    }

    render() {
        let {} = this.props;
        let {docPages} = this.state;
        return (
            <div>
                <div style={{width : 500, height :300,background:"red"}}>
                    <PptReview/>
                </div>

                {
                    docPages.map(value => (
                        <img src={value.img_url} style={{width : "100%"}} alt=""/>
                    ))
                }
            </div>
        );
    }
}
