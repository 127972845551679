/**
 * 新增&修改 预置表单字段
 * Create by liaokai on 2020-04-03 11:26:53
 */
import {toPost} from "../http-utils";

export const addRegisterField = toPost<
    {
        zh_name : string,
        id? : number
    },
    {
        zh_name: string,
        en_name: string,
        type: string,
        required: number,
        mid: number,
        create_time: string,
        update_time: string
    }
    >("/merchant/meeting/addRegisterField")
