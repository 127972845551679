/**
 * API列表
 *  TS方式声明API，支持可预见的传入传出参数类型，用于逐渐替换AskPost.js
 * Create by liaokai on 2020-03-25 16:17:08
 */
import {login} from "./apis/login";
import {getRegisterField} from "./apis/get-register-field";
import {getMeetingRegisterField} from "./apis/get-meeting-register-field";
import {addRegisterField} from "./apis/add-register-field";
import {setMeetingRegisterField} from "./apis/set-meeting-register-field";
import {getMeeting} from "./apis/get-meeting";
import {getModules} from "./apis/get-modules";
import {getActiveModule} from "./apis/get-active-module";
import {getAllModule} from "./apis/get-all-module";
import {smsTemplateLogList} from "./apis/sms/smsTemplateLogList";
import {smsTemplateList} from "./apis/sms/smsTemplateList";
import {smsTemplateStore} from "./apis/sms/smsTemplateStore";
import {smsTemplateShow} from "./apis/sms/smsTemplateShow";
import {sendSms} from "./apis/sendSms";
import {smsField} from "./apis/sms/smsField";
import {sendSmsUsersList} from "./apis/sendSmsUsersList";
import {smsTemplateDestroy} from "./apis/sms/smsTemplateDestroy";
import {smsTemplateUpdate} from "./apis/sms/smsTemplateUpdate";
import {getModuleListAndTargetGroup} from "./apis/getModuleListAndTargetGroup";
import {sendAffirm} from "./apis/sendAffirm";
import {sendSmsUsersAgain} from "./apis/sendSmsUsersAgain";
import {messageCount} from "./apis/messageCount";
import {getGusetCountByMeeting} from "./apis/getGusetCountByMeeting";
import {getMeetingHallInfo} from "./apis/getMeetingHallInfo";
import {deleteMeetingHall} from "./apis/deleteMeetingHall";
import {setNewHall} from "./apis/setNewHall";
import {transferIncomeAcc} from "./apis/transfer-income-acc";
import {transferOperatingAcc} from "./apis/transferOperatingAcc";
import {getRechargeImgStatus} from "./apis/get-recharge-img-status";
import {journalAccountRecord} from "./apis/journalAccountRecord";
import {getPrepaidTable} from "./apis/getPrepaidTable";
import {orderAccountRecord} from "./apis/orderAccountRecord";
import {getOrderRecordTable} from "./apis/getOrderRecordTable";
import {getSmsPackageList} from "./apis/getSmsPackageList";
import {getAccMoney} from "./apis/getOperatingAccMoney";
import {buySmsPackageByCash} from "./apis/buySmsPackageByCash";
import {chargeManagement} from "./apis/chargeManagement";
import {excelExportFee} from "./apis/excelExportFee";
import {getMeetingProfit} from "./apis/getMeetingProfit";
import {getMeetingPandect} from "./apis/getMeetingPandect";
import {getRechargeImgWechat} from "./apis/get-recharge-img";
import {closeOrder} from "./apis/closeOrder";
import {operatingAccountBalance} from "./apis/operatingAccountBalance";
import {tixianRecord} from "./apis/tixianRecord";
import {tixianExport} from "./apis/tixianExport";
import {balancePaySmsAffirm} from "./apis/balancePaySmsAffirm";
import {getUserInfo} from "./apis/get-user-info";
import {emailSmsTemplateList} from "./apis/email/emailTemplateList";
import {emailTemplateStore} from "./apis/email/emailTemplateStore";
import {emailTemplateShow} from "./apis/email/emailTemplateShow";
import {emailTemplateDestroy} from "./apis/email/emailTemplateDestroy";
import {emailTagAndTemplate} from "./apis/email/emailTagAndTemplate";
import {emailTemplateUpdate} from "./apis/email/emailTemplateUpdate";
import {emailSendAffirm} from "./apis/email/emailSendAffirm";
import {emailSend} from "./apis/email/emailSend";
import {getEmailPackageList} from "./apis/email/emailShopList";
import {emailTemplateLogList} from "./apis/email/emailTemplateLogList";
import {emailSendEmailUsersAgain} from "./apis/email/emailSendEmailUsersAgain";
import {sendEmailUsersList} from "./apis/email/emailSendEmailUsersList";
import {emailTemplateLogCancel} from "./apis/email/emailTemplateLogCancel";
import {smsTemplateLogCancel} from "./apis/sms/smsTemplateLogCancel";
import {emailCount} from "./apis/email/emailCount";
import {recentlyLive} from "./apis/live/recentlyLive";
import {emailAddressBookList} from "./apis/email/emailAddressBookList";
import {emailAddressUpdate} from "./apis/email/emailAddressUpdate";
import {emailAddressBookUpdate} from "./apis/email/emailAddressBookUpdate";
import {emailAddressBookStore} from "./apis/email/emailAddressBookStore";
import {emailAddressList} from "./apis/email/emailAddressList";
import {emailAddressBookSelect} from "./apis/email/emailAddressBookSelect";
import {excelExportUrl} from "./apis/email/excelExportUrl";
import {emailTemplateListAll} from "./apis/email/emailTemplateListAll";


export const apis = {
    //登录
    login,
    //获取注册表单字段
    getRegisterField,
    //已设置表单字段
    getMeetingRegisterField,
    //新增&修改 预置表单字段
    addRegisterField,
    //设置会议报名表单(增加、删除、排序)
    setMeetingRegisterField,
    //获取会议基本信息
    getMeeting,
    //获取未开通的模块
    getModules,
    //获取已开通的模块
    getActiveModule,
    //获取所有会前模块
    getAllModule,
    //短信模版发送记录
    smsTemplateLogList,
    //短信模版列表
    smsTemplateList,
    //新建短信模板
    smsTemplateStore,
    //获取短信模板内容
    smsTemplateShow,
    //短信群发
    sendSms,
    //获取短信模板动态字段
    smsField,
    //某次模板的短信发送记录
    sendSmsUsersList,
    //短信模板删除
    smsTemplateDestroy,
    //短信模板更新
    smsTemplateUpdate,
    //获取短信模板、目标群体的选项
    getModuleListAndTargetGroup,
    //短信发送确认清单
    sendAffirm,
    //短信重发
    sendSmsUsersAgain,
    //获取已发送的短信数量
    messageCount,
    //获取数据统计（统计嘉宾）
    getGusetCountByMeeting,
    //签到管理列表
    getMeetingHallInfo,
    //删除会场
    deleteMeetingHall,
    //新建会场
    setNewHall,
    //转入到收益账户
    transferIncomeAcc,
    //转入运营账户
    transferOperatingAcc,
    //获取短信充值二维码(微信)
    getRechargeImgWechat,
    //获取充值二维码的状态(微信)
    getRechargeImgStatus,
    //导出收支记录
    journalAccountRecord,
    //获取收支记录列表
    getPrepaidTable,
    //导出订单列表
    orderAccountRecord,
    //获取订单列表
    getOrderRecordTable,
    //获取短信包购买列表
    getSmsPackageList,
    //获取运营账户余额
    getAccMoney,
    //使用余额购买短信包
    buySmsPackageByCash,
    //获取收费管理列表
    chargeManagement,
    //导出收费管理Excel
    excelExportFee,
    //会议收益总览
    getMeetingProfit,
    //获取会议信息总览
    getMeetingPandect,
    //关闭订单
    closeOrder,
    //充值运营账户余额
    operatingAccountBalance,
    //获取提现记录列表
    tixianRecord,
    //导出提现列表
    tixianExport,
    //短信购买，余额支付
    balancePaySmsAffirm,
    //获取用户信息
    getUserInfo,
    //邮件模板列表
    emailSmsTemplateList,
    //新建邮件模板
    emailTemplateStore,
    emailTemplateUpdate,
    //不分页的邮件模板列表
    emailAddressBookSelect,
    emailTemplateListAll,
    //邮件模板详情
    emailTemplateShow,
    //邮件模板删除
    emailTemplateDestroy,
    //会议标签和邮件模板列表
    emailTagAndTemplate,
    //邮件发送确认清单
    emailSendAffirm,
    //邮件发送
    emailSend,
    //获取邮件包商品列表
    getEmailPackageList,
    //下载邮件模板
    excelExportUrl,
    //邮件模版发送记录
    emailTemplateLogList,
    //邮件重新发送
    emailSendEmailUsersAgain,
    //获取某次邮件模板的发送记录
    sendEmailUsersList,
    //取消邮件发送
    emailTemplateLogCancel,
    //短信邮件发送
    smsTemplateLogCancel,
    //获取已发送的邮件数量
    emailCount,
    //获取直播列表
    recentlyLive,
    //邮件地址簿开始
    //邮件地址簿列表
    emailAddressBookList,
    //邮箱用户列表
    emailAddressList,
    //邮件地址更改
    emailAddressUpdate,
    //邮件地址簿更改
    emailAddressBookUpdate,
    //邮件地址铺添加
    emailAddressBookStore,
};
