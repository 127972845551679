// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TemplateView_TemplateView__1qXvz{width:calc(100% - 102px);min-height:280px;padding:0 51px}.TemplateView_TemplateView__1qXvz .TemplateView_TextTips__2cfQp{width:100%;margin-bottom:24px}.TemplateView_TemplateView__1qXvz .TemplateView_TextTips__2cfQp span:nth-child(1){font-size:18px;font-weight:400;color:#000;margin-bottom:24px}.TemplateView_TemplateView__1qXvz .TemplateView_TextTips__2cfQp span:nth-child(2){font-size:14px;font-family:Source Han Sans CN;font-weight:400;line-height:24px;color:#000;opacity:0.4}.TemplateView_TemplateView__1qXvz .TemplateView_DownloadButton__3VAeL{cursor:pointer;width:198px;height:48px;background:#00b546;box-shadow:0px 0px 10px rgba(0,0,0,0.16);border-radius:8px;font-size:16px;font-weight:400;color:#fff;cursor:pointer}.TemplateView_TemplateView__1qXvz .TemplateView_DownloadButton__3VAeL :nth-child(1){width:35px;margin-right:11px}\n", ""]);
// Exports
exports.locals = {
	"TemplateView": "TemplateView_TemplateView__1qXvz",
	"TextTips": "TemplateView_TextTips__2cfQp",
	"DownloadButton": "TemplateView_DownloadButton__3VAeL"
};
module.exports = exports;
