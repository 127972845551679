/**
 * 邮件模板列表
 * Create by liaokai on 2020-04-23 17:52:00
 */
import {toPost} from "../../http-utils";


export const emailAddressBookStore = toPost<
    {
        "name": string,
    },
    {
        "name": string,
        "mid": number,
        "create_time": string,
        "update_time": string,
        "id": any
    }
    >("/merchant/Email/emailAddressBookStore");
