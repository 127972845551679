/**
 * 面包屑XX
 * Create by liaokai on 2020-04-16 19:42:35
 */
import * as React from 'react';
import {Component} from "react";
import {SurfaceRouter, tSurfaceRouteItem} from "../surface-router/surface-router";
import {BreadcrumbCustomStateless} from "./breadcrumb-custom-stateless";
import {RouteComponentProps} from "react-router";


interface IProps {
    basePath: string
}

interface IState {
    routerProps?: RouteComponentProps,
    pathNode?: tSurfaceRouteItem,
    basePath: string
}

export class BreadcrumbCustomRouter extends Component<IProps, IState> {
    readonly state: IState = {
        basePath: this.props.basePath
    };

    public setParams(routerProps: RouteComponentProps, pathNode: tSurfaceRouteItem) {
        this.setState({routerProps, pathNode})
    }

    render() {
        let {} = this.props;
        let {routerProps, pathNode, basePath} = this.state;
        return (
            <BreadcrumbCustomStateless data={SurfaceRouter.reBack(pathNode).map(value => {
                let path = SurfaceRouter.reBack(value).map(value1 => value1.routeName).join("/");
                let link = `${basePath}${path ? `${path.startsWith("/") ? path : '/' + path}` : ""}`;
                let params = routerProps?.match.params;
                if (params) {
                    Reflect.ownKeys(params).map((value1) => {
                        link = link.replace(new RegExp(`:${value1 as string}`), (params as any)[value1]);
                    });
                }
                return {
                    title: value.breadTitle,
                    link: link
                }
            })}/>
        );
    }
}
