import React, { Component } from 'react'
import style from './NoticeCenterView.scss'
import {api} from '../../../common/app'
import NoticeBody from './components/NoticeBody'

import PageBurster from '../../../components/PageBurster'
import {message, Tabs} from "antd";
import {Responsive} from "../../../components/common/responsive";
const {TabPane} = Tabs;

export class NoticeCenterView extends Component {
constructor(props) {
  super(props);
  this.state = {
    NavClass:[],
    SelectNav:0,
    listpage:1,
    listdata:[],
  };
     this.refreshProps = this.refreshProps.bind(this);
     this.getNoticeList = this.getNoticeList.bind(this);
     this.createNoticeList = this.createNoticeList.bind(this);
     this.createNoticeClassNav = this.createNoticeClassNav.bind(this);
     this.SelectNoticeClass = this.SelectNoticeClass.bind(this);
     this.onPageBusterChange = this.onPageBusterChange.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
  // this.getNoticeList();
  this.getNoticeClass();
}
refreshProps(props) {
    this.state.listpage = isNaN(props.match.params.page)?1:props.match.params.page;
    this.getNoticeList(this.state.listpage);
    this.setState(this.state);
}
createNoticeList(){
    if(this.state.listdata.length <= 0) return;
    let result = [];
    for (let z = 0; z < this.state.listdata.length; z++) {
        result.push(<NoticeBody key={this.state.listdata[z].id} info={this.state.listdata[z]}/>)
    }
    return result;
}
getNoticeList(listpage){
  api.getNoticeList(listpage,12,this.state.SelectNav).then(res=>{
    console.log(res);
    if (res.code === 200) {
      this.state.listdata = res.result.data;
      this.state.totalpage = res.result.page_count;
    }else{
        message.error(res.message);
    }
    this.setState(this.state);
  },err=>{
    console.log(err);

  })
}
getNoticeClass(){
  api.getNoticeClass().then(res=>{
    if (res.code === 200) {
      this.state.NavClass = res.result;
      this.setState(this.state);
    }else{
        message.error(res.message);
    }
  },err=>{
    console.log(err);
  })
}
SelectNoticeClass(navid){
  this.state.SelectNav = navid;
  this.getNoticeList(1);
  this.setState(this.state);
}
createNoticeClassNav(){
  let result = [];
  for (let z = 0; z < this.state.NavClass.length; z++) {
    result.push(
        <div onClick={this.SelectNoticeClass.bind(this,this.state.NavClass[z].id)} className={[style.NavButton,this.state.SelectNav == this.state.NavClass[z].id?style.NavAct:'','childcenter'].join(' ')}>{this.state.NavClass[z].name}</div>
    )
  }
  return result;
}
onPageBusterChange(index){
  window.location.hash = '#/notice/'+index;
}
render() {
    let {NavClass} = this.state;
        return (
            <Responsive>
                <div className={style.NoticeCenterView}>
                    <div className={style.tabsBox}>
                        <Tabs defaultActiveKey="1" size={"large"} onChange={(key)=>{
                            this.SelectNoticeClass(key)
                        }}>
                            {
                                NavClass.map((value,index) => {
                                    return (
                                        <TabPane tab={value.name} key={index}>
                                            {/*Content of Tab Pane 1*/}
                                        </TabPane>
                                    );
                                })
                            }
                        </Tabs>
                    </div>
                    <div className={style.NoticeListBody}>
                        <div className={style.NoticeGroup}>
                            {
                                this.state.listdata.length==0
                                    ?
                                    <div className={[style.NoDataComponent,'childcenter'].join(' ')}>还没有消息</div>
                                    :
                                    this.createNoticeList()
                            }
                        </div>
                        <div className={style.PageBusterBox}>
                            <PageBurster nowpage={this.state.listpage} max={this.state.totalpage} onPageChange={this.onPageBusterChange}/>
                        </div>
                    </div>
                </div>
            </Responsive>
        )
   }
}
export default NoticeCenterView
