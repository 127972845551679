/**
 *
 * Create by liaokai on 2020-04-27 16:02:11
 */
import * as React from 'react';
import {Component} from "react";
import {Button, Input, message, Popover} from "antd";
import {api} from "../../../../../../../../common/app";
import style from "./new-venue.scss"
import {apis} from "../../../../../../../../common/apis/apis";
import {setNewHall} from "../../../../../../../../common/apis/apis/setNewHall";

interface IProps{
    onConfirm? : (meeting_name : string) => void
}

interface IState{
    newMeetingName : string,
    popoverVisible : boolean
}

export class NewVenue extends Component<IProps,IState>{
    readonly state : IState = {
        newMeetingName : "",
        popoverVisible : false
    };
    render() {
        let {onConfirm} = this.props;
        let {newMeetingName, popoverVisible} = this.state;
        return (
            <Popover
                content={
                    <div>
                        <Input
                            value={newMeetingName}
                            onChange={event => {
                                this.setState({
                                    newMeetingName : event.target.value,
                                })
                            }}
                            placeholder={"会场名称"}
                            maxLength={6}
                            suffix={
                                <div>{`${newMeetingName?.length ?? 0}/6`}</div>
                            }
                        />
                        <div className={style.newMeetingBtnBox}>
                            <Button
                                onClick={()=>{
                                    onConfirm?.(newMeetingName)
                                    this.setState({popoverVisible : false})
                                }}
                                type={"primary"}
                            >
                                确定
                            </Button>
                            <Button
                                onClick={()=>{
                                    this.setState({popoverVisible : false})
                                }}
                            >
                                取消
                            </Button>
                        </div>
                    </div>
                }
                trigger={["click"]}
                onVisibleChange={visible => {
                    this.setState({popoverVisible : visible, newMeetingName : ""})
                }}
                visible={popoverVisible}
                placement={"bottomRight"}
                title={
                    <div style={{fontSize: 15}}>会场名称</div>
                }
            >
                <Button size={"large"} shape={"round"} type="primary">新增会场</Button>
            </Popover>
        );
    }
}
