/**
 * 短信群发
 * Create by liaokai on 2020-04-23 18:34:19
 */
import {toPost} from "../http-utils";

export const sendSms = toPost<
    {
        id : number,
        meetid : number,
        type? : number,
        send_time? : string,
        template_id : number
    },
    {

    }
>("/merchant/Lcm/sendSms");
