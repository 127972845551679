/**
 * 专题管理 - 专题预览
 * Create by liaokai on 2020-06-13 23:09:27
 */
import * as React from 'react';
import {Component} from "react";
import {ISubjectShow, ISubjectShowItem, subjectShow} from "../../../../../../../common/apis/apis/live/subjectShow";
import styled from "styled-components";
import {Flex} from "../../../../../../../components/style/flex/flex";
import {Flex1} from "../../../../../../../components/style/flex/flex-1";
import {Texts} from "../../../../../../../components/text/Texts";
import {Popover, Tabs} from "antd";
import {ProkviewLiveList} from "./prokview-live-list/prokview-live-list";
import {RouteComponentProps} from "react-router";
import {Air} from "../../../../../../../components/air/Air";
import {CopyLink} from "../../overview/component/copy-link";
import {Qrcode} from "../../../../../../../components/common/qrcode";
import {FlexJustifyCenter} from "../../../../../../../components/style/flex/flex-justify-center";

const {TabPane} = Tabs

interface IProps extends RouteComponentProps<{prokect_id : string}>{

}

interface IState{
    data : ISubjectShow
    // ISubjectShowItem[]
}

export class LiveProkectPreview extends Component<IProps,IState>{
    readonly state : IState = {
        data : {
            "id": 0, //专题id
            "title": "-", //专题标题
            "poster": "", //专题海报
            "synopsis": "-", //专题简介
            "create_time": "-", //专题创建时间
            "live_count": 0, //直播数量
            "look_num": 0, //专题下的所有直播观看人数
            "subject_dir": [],
            url : ""
        }
    };

    componentDidMount() {
        const {match : {params : {prokect_id}}} = this.props
        subjectShow({
            id : prokect_id
        }).then((res)=>{
            this.setState({data : res})
        })
    }

    render() {
        let {} = this.props;
        let {data : {id,url, title, poster, synopsis, create_time, live_count, look_num, subject_dir}} = this.state;
        return (
            <Container>
                <Flex>
                    <div>
                        <img src={poster} style={{width: 200}} alt=""/>
                    </div>
                    <Air horizontal={15}/>
                    <Flex1>
                        <Texts.ChapterTitle>{title}</Texts.ChapterTitle>
                        <div>{create_time}</div>
                        <div>{live_count}个直播</div>
                        <div>{look_num}次观看</div>
                        <Flex>
                            <Popover placement={"bottom"} content={<div>
                                <Air vertical={5}/>
                                <FlexJustifyCenter>
                                    <Qrcode value={url ?? ""}/>
                                </FlexJustifyCenter>
                                <Air vertical={5}/>
                                <FlexJustifyCenter>
                                    <div>手机扫码观看</div>
                                </FlexJustifyCenter>
                                <Air vertical={5}/>
                                <CopyLink showQr={false} link={url ?? ""}/>
                            </div>}>
                                <div>分享</div>
                            </Popover>
                        </Flex>
                    </Flex1>
                </Flex>
                <div>
                    <Tabs defaultActiveKey="1" size={"large"}>
                        <TabPane tab="简介" key="1">
                            {synopsis}
                        </TabPane>
                        <TabPane tab="目录" key="2">
                            <ProkviewLiveList dataSource={subject_dir}/>
                        </TabPane>
                    </Tabs>
                </div>
            </Container>
        );
    }
}

const Container = styled.div`
  
`
