/**
 * 复制直播
 * Create by liaokai on 2020-05-26 18:36:57
 */

import {toPost} from "../../http-utils";

export const liveCopyStore = toPost<
    {
      copy_live_id : any
     
    },
    {

    }
>("/merchant/live/liveCopyStore");
