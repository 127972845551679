/**
 * 可拖动列表
 * Create by liaokai on 2020-03-11 18:23:20
 */
import * as React from 'react';
import {Component} from "react";
import {DragDropContext, Draggable, Droppable, DropResult, ResponderProvided} from "react-beautiful-dnd";
import {Empty} from "antd";
import emptyImg from "../../../../../../../assets/meeting-edit-empty.jpg"

interface IProps<T>{
    data : T[],
    extractKey : (item : T) => string
    renderItem : (item : T) => JSX.Element
    onDrapEnd? : (data : T[], srcIndex? : number, distIndex? : number | undefined) => void
}

interface IState<T>{
    locaList : T[]
}


export class DragList<T> extends Component<IProps<T>,IState<T>>{
    //state初始化
    readonly state : IState<T> = {
        locaList : this.props.data
    };
    constructor(props: IProps<T>){
        super(props);
    }

    componentWillReceiveProps(nextProps: Readonly<IProps<T>>, nextContext: any): void {
        this.setState({locaList : nextProps.data})
    }

    // 重新记录数组顺序
    reorder = (list : T[], startIndex : number, endIndex : number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    onDragEnd = (result: DropResult, provided: ResponderProvided) => {
        if (!result.destination) {
            return;
        }
        let locaList = this.reorder(
            this.state.locaList,
            result.source.index,
            result.destination.index
        );
        this.setState({ locaList }, ()=>{
            this.props.onDrapEnd?.(this.state.locaList, result.source.index, result.destination?.index);
        });
    };


    render() {
        let {extractKey,renderItem} = this.props;
        let {locaList} = this.state;
        if(locaList.length){
            return (
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {locaList.map((item, index) => (
                                    <Draggable
                                        key={extractKey(item).toString()}
                                        draggableId={extractKey(item).toString()}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                {renderItem(item)}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            );
        }else{
            return <img src={emptyImg} style={{maxWidth : "100%", marginTop : 30}} alt=""/>
        }
    }
}
