import {toPost} from "../../../../../../../common/apis/http-utils";

export const getBanner = toPost<{ live_id: any }, any>(
    `/live/Comperelive/getBanner`
);

export const checkMessageList = toPost<
    { live_id: string; status: number },
    any
>(`/merchant/live/checkMessageList`);

export const checkMessageUsersSet = toPost<
    { live_id: string; id: number; is_forbid_message: number },
    any
>(`/merchant/live/checkMessageUsersSet`);

export const checkMessage = toPost<
    { live_id: string; ids: any[]; status: number; type: number },
    any
>(`/merchant/live/checkMessage`);

export const checkMessageNumber = toPost<{ live_id: string }, any>(
    `/merchant/live/checkMessageNumber`
);

export const checkMessageUsersList = toPost<{ live_id: string }, any>(
    `/merchant/live/checkMessageUsersList`
);

export const bindClient = toPost<any, any>(`/live/CompereLive/bind`);
