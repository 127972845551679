/**
 * 编辑回看视频信息
 * Create by liaokai on 2020-06-19 16:08:17
 */
import * as React from 'react';
import {Component} from "react";
import {Input, message, Modal} from "antd";
import {emailAddressBookUpdate} from "../../../../../../common/apis/apis/email/emailAddressBookUpdate";
import {emailAddressBookStore} from "../../../../../../common/apis/apis/email/emailAddressBookStore";
import {TitleContentTable} from "../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
interface IEmailAddressBookItem {
    "name": string,
}
const defaultItem : IEmailAddressBookItem = {
    "name": "",
}

interface IProps{
    visible : boolean
    onCancel? : () => void
    onSubmit? : () => void
    storeItem?: IEmailAddressBookItem
}

interface IState{
    storeItem : IEmailAddressBookItem
}

export class EmailAddAddressBook extends Component<IProps,IState>{
    readonly state : IState = {
        storeItem : defaultItem
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if(JSON.stringify(this.props.storeItem) !== JSON.stringify(prevProps.storeItem) ){
            this.setState({storeItem : this.props.storeItem ?? defaultItem})
        }
    }

    render() {
        let {onCancel,onSubmit,visible} = this.props;
        let {storeItem} = this.state;
        return (
            <Modal
                visible={visible}
                onCancel={()=>{
                    onCancel?.();
                }}
                onOk={()=>{
                    console.log(this.props)
                    emailAddressBookStore(storeItem as any).then(()=>{
                        onSubmit?.()
                        onCancel?.()
                    }).catch(e => {
                        message.error("保存失败 " + e.data?.message)
                    })
                }}
                cancelText={"取消"}
                okText={"保存"}
                title={"添加"}
            >
                <TitleContentTable colGap={10} rowGap={10}>
                    <div>标题</div>
                    <Input placeholder={"请输入管理组名"}  onChange={e => {
                        storeItem.name = e.target.value
                        this.setState({storeItem : storeItem})
                    }}/>
                </TitleContentTable>
            </Modal>
        );
    }
}
