import React from 'react';
import { Form, Table, Switch, Button, message, Spin, Modal, Input } from "antd";
import { ModalProps } from 'antd/lib/modal/index'

type Props = {
  form?: any;
  formItems?: any[];
  children?: any;
} & ModalProps;

export default (props: Props) => {
  const { form, formItems, children, ...rest } = props;
  return (
    <Modal
      okText="确定"
      cancelText="取消"
      {...rest}
    >
      {children}
      {form && (
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          {formItems && formItems.map((item: any) => {
            if (item.render) {
              return item.render();
            }
            return (
              <Form.Item
                {...item}
                key={item.name}
              >
                <Input />
              </Form.Item>
            )
          })}
        </Form>
      )}
    </Modal>
  )
}