import React from "react";
import { Form, Table, Switch, Button, message, Spin } from "antd";
import { useAntdTable, useBoolean, useMount } from "ahooks";
import {
    liveInfoList,
    liveInfoAdd,
    liveInfoShow,
    liveInfoDel,
    liveInfoUpdate,
    liveInfoChange, liveTabStatus,
} from "./constants";
import { liveStore } from "../live.store";
import { Air } from "../../../../../../components/air/Air";
import { enableShowSet } from "../../../../../../common/apis/apis/live/enableShowSet";
import { FlexJustifyBetween } from "../../../../../../components/style/flex/flex-justify-between";
import { Flex } from "../../../../../../components/style/flex/flex";
import { enableShowInfo } from "../../../../../../common/apis/apis/live/enableShowInfo";
import { Link, useHistory } from "react-router-dom";
import { formatListResult } from "../advertisement/constants";
import {liveTabList} from "../../../../../../common/apis/apis/live/liveTabList";

export const LiveTab = () => {

  const history = useHistory();
  const [form] = Form.useForm();

  const { tableProps, search } = useAntdTable(
    (paginatedParams: any, tableProps: any) => {
      return liveTabList({
        live_id: liveStore.live_id
      }).then((response) => {
        return response;
      });
    },
    { form, formatResult: formatListResult }
  );
  const { submit, reset } = search;

  const onDelete = (item: any) => {
    liveInfoDel({ id: item.id })
      .then((result) => {
        message.success('删除成功！');
        submit();
      })
      .catch((error) => {
        console.log('error', error);
        message.error(error.data?.result);
      })
  }

  const onChange = (item: any, status: boolean) => {
    const payload = {
      id: item.id,
      status: status === true ? 1 : 0,
    };
        console.log(status)
      liveTabStatus(payload)
      .then((result) => {
        message.success('设置成功！');
        submit();
      })
      .catch((error) => {
        console.log('error', error);
        message.error(error.data?.result);
      })
  }

  const columns = [
    {
      title: "标题",
      dataIndex: "title",
      width: 300,
    },
      {
          title: "类型",
          dataIndex: "atitle",
          width: 300,
      },
    {
      title: '排序',
      dataIndex: 'sort'
    },
    {
      title: '状态',
      render: (item: any) => {
        return (
            // <>
            //     <div>
            //         {item.status == 1 ? ' 使用中' : '未使用'}
            //     </div>
            // </>
            item.type != 2 ?  <div>
                         {item.status == 1 ? ' 使用中' : '未使用'}
                     </div> :  <Switch
                checked={item.status == 1}
                style={{ marginRight: 15 }}
                onChange={(status) => onChange(item, status)}
            />
        )
      }
    },
    {
      title: "操作",
      render: (item: any) => {
        return (
          <div>
            <Button
              onClick={() => {
                history.push(
                  `live_tab/edit/edit/${item.id}`
                );
              }}
              type={"link"}
              style={{ paddingLeft: 0 }}
            >
              编辑
          </Button>
            {/*{`  `}*/}
          {/*  <Button*/}
          {/*    onClick={() => onDelete(item)}*/}
          {/*    type={"link"}*/}
          {/*    style={{ paddingLeft: 0 }}*/}
          {/*  >*/}
          {/*    删除*/}
          {/*</Button>*/}
          </div>
        )
      }
    },
  ];

  return (
    <div>
      <FlexJustifyBetween style={{ marginBottom: 12 }}>

      </FlexJustifyBetween>
      <Spin
        indicator={<div />}
        tip="尚未开启广告"
        spinning={false}
      >
        <Table rowKey="id" columns={columns} {...tableProps} />
      </Spin>
    </div>
  );
}