/**
 * 会议卡片
 * Create by liaokai on 2020-04-07 18:25:02
 */
import * as React from 'react';
import { Component, CSSProperties } from "react";
import { Badge, Card, Empty, Tag } from "antd";
import { CheckOutlined, EditOutlined, EllipsisOutlined, SettingOutlined } from "@ant-design/icons/lib";
import { common } from "../../../../common/common";
import { Ratio } from "../../../../components/ratio/ratio";
import { eMeetingListSearchRange, tMeetingListDataItem } from "../../../../common/apis/apis/get-meeting-list";
import moment from "moment";
import { MetaCard } from "../../../../components/meta-card";

interface IProps {
    data: tMeetingListDataItem
    select?: "unselect" | "selected" | "none",
    style?: CSSProperties,
    selectColor?: string       //选中的图标颜色
}

interface IState {

}

export class MeetingCardStateless extends Component<IProps, IState>{
    //state初始化
    readonly state: IState = {

    };
    static defaultProps = {
        select: "none",
        status: "预告",
        title: "加载中",
        date: "加载中",
        imgUrl: "http://api-document.rup-china.com/huibaifenapi/gitbook/gitbook-plugin-theme-fexaedit/logo.png",
        join: 0,
        selectColor: common.color.success
    };
    render() {
        let { select, style, selectColor } = this.props;
        let { data: { meeting_status, cover, title, start_time, end_time, user_count } } = this.props
        let { } = this.state;
        return (
            <MetaCard
                stateView={
                    <Tag
                        color={meeting_status === eMeetingListSearchRange.preview ? common.color.primary :
                            meeting_status === eMeetingListSearchRange.ing ? common.color.success :
                                meeting_status === eMeetingListSearchRange.over ? common.color.danger : undefined}>
                        {
                            meeting_status === eMeetingListSearchRange.preview ? "预告" :
                                meeting_status === eMeetingListSearchRange.ing ? "进行" :
                                    meeting_status === eMeetingListSearchRange.over ? "结束" : ""
                        }
                    </Tag>
                }
                cover={cover}
                select={select}
                title={title}
                time={moment(start_time, "YYYY/MM/DD HH:mm:ss").format("YYYY/MM/DD")}
                count={`${user_count}人参与`}
                selectColor={common.color.danger}
            >
            </MetaCard>
        );
    }
}
