// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NoticeBody_NoticeBody__vuzy3{width:calc(1118px - 48px);min-height:65px;margin:0 auto;border-bottom:rgba(112,112,112,0.2) 1px solid;padding:0 24px;transition:padding 0.5s,background 0.5s}.NoticeBody_NoticeBody__vuzy3 .NoticeBody_NoticeHead__2KcdR{width:100%;cursor:pointer;position:relative}.NoticeBody_NoticeBody__vuzy3 .NoticeBody_NoticeHead__2KcdR .NoticeBody_NoticeStatus__1PHZn{width:7px;height:7px;margin:0 7px 0 0;background:#FF0000;border-radius:50%}.NoticeBody_NoticeBody__vuzy3 .NoticeBody_NoticeHead__2KcdR .NoticeBody_NoticeTitle__26-HX{width:calc(100% - 200px);font-size:16px;font-weight:400;color:rgba(0,0,0,0.35);float:left;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.NoticeBody_NoticeBody__vuzy3 .NoticeBody_NoticeHead__2KcdR .NoticeBody_OpenTitle__35SDg{transition:color 0.5s;color:#000}.NoticeBody_NoticeBody__vuzy3 .NoticeBody_NoticeHead__2KcdR .NoticeBody_NoteceTime__2ALcd{width:200px;font-size:16px;font-weight:400;color:rgba(0,0,0,0.35);float:right}.NoticeBody_NoticeBody__vuzy3 .NoticeBody_NoticeHead__2KcdR .NoticeBody_NoteceTime__2ALcd .NoticeBody_DropDownIcon__1-yFM{width:0px;height:0px;margin-left:15px;border-top:#707070 8px solid;border-left:transparent 6px solid;border-right:transparent 6px solid;border-bottom:transparent 0px solid}.NoticeBody_NoticeBody__vuzy3 .NoticeBody_NoticeContent__xqa1Q{width:calc(100% - 22px);margin:8px 0;padding:0 11px;font-size:16px;font-weight:400;line-height:27px;color:#000}.NoticeBody_OpenBody__2Y1k9{background:#F7F7F7;padding-top:26px;padding-bottom:26px}\n", ""]);
// Exports
exports.locals = {
	"NoticeBody": "NoticeBody_NoticeBody__vuzy3",
	"NoticeHead": "NoticeBody_NoticeHead__2KcdR",
	"NoticeStatus": "NoticeBody_NoticeStatus__1PHZn",
	"NoticeTitle": "NoticeBody_NoticeTitle__26-HX",
	"OpenTitle": "NoticeBody_OpenTitle__35SDg",
	"NoteceTime": "NoticeBody_NoteceTime__2ALcd",
	"DropDownIcon": "NoticeBody_DropDownIcon__1-yFM",
	"NoticeContent": "NoticeBody_NoticeContent__xqa1Q",
	"OpenBody": "NoticeBody_OpenBody__2Y1k9"
};
module.exports = exports;
