/**
 * 互动问答表格
 * todo 拆分编辑、添加为单个页面
 * Create by liaokai on 2020-05-25 18:55:45
 */
import * as React from "react";
import {Button, Input, message, Modal, Radio, Select, Spin, Switch, Table} from "antd";
import {TitleContentTable} from "../../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import {replyKeywords} from "../../../../../../../common/apis/apis/live/replyKeywordsList";
import {FlexJustifyCenter} from "../../../../../../../components/style/flex/flex-justify-center";
import {Air} from "../../../../../../../components/air/Air";
import {liveStore as liveStoreReq} from "../../../../../../../common/apis/apis/live/liveStore";
import {liveStore} from "../../live.store";
import {liveUpdate} from "../../../../../../../common/apis/apis/live/liveUpdate";
import {Component} from "react";
import {attentionInfo} from "../../../../../../../common/apis/apis/live/attentionInfo";
import {autoMessage} from "../../../../../../../common/apis/apis/live/autoMessage";
import {attentionUpdate} from "../../../../../../../common/apis/apis/live/attentionUpdate";
import {autoMessageSet} from "../../../../../../../common/apis/apis/live/autoMessageSet";
import {autoLiveHeatval} from "../../../../../../../common/apis/apis/live/autoLiveHeatval";
import {autoHeatvalSet} from "../../../../../../../common/apis/apis/live/autoHeatvalSet";

interface IProp {

}

interface IState {
    'live_id'?:any,
    'seconds'?:number,
    'status'?:number,
    'min'?:number,
    'max'?:number,
}

export class HeatValStoreView extends Component<IProp,IState>{
    readonly state : IState = {
        live_id:liveStore.live_id,
        seconds: 1,
        min: 1,
        max: 1,
        status: 0,
    }
    componentDidMount(): void {
        autoLiveHeatval({live_id:liveStore.live_id}).then(res=>{
            this.setState({
                live_id:liveStore.live_id,
                seconds: res.seconds ? res.seconds : 1,
                min: res.min ? res.min : 1,
                max: res.max ? res.max : 1,
                status:res.status ? res.status : 0,
            })
        })
    }
    render() {
        const {live_id,seconds,status,min,max} = this.state

        return (
            <>
                <TitleContentTable rowGap={12} colGap={12}>
                    <div>最小值</div>
                    <Input
                        style={{width:"300px",  display: "flex", flexDirection: "row" }}
                        // width={'50px'}
                        placeholder={"请输入热力值最小"}
                        type={"number"}
                        value={min}
                        onChange={(e) => {
                            this.setState({min:Number(e.target.value)})
                        }}
                    />
                    <div>最大值</div>
                    <Input
                        style={{width:"300px",  display: "flex", flexDirection: "row" }}
                        // width={'50px'}
                        placeholder={"请输入热力值最大"}
                        type={"number"}
                        value={max}
                        onChange={(e) => {
                            this.setState({max:Number(e.target.value)})
                        }}
                    />
                    <div>秒</div>
                    <Input
                        style={{width:"300px",  display: "flex", flexDirection: "row" }}
                        // width={'50px'}
                        placeholder={"请输入秒"}
                        type={"number"}
                        value={seconds}
                        onChange={(e) => {
                            this.setState({seconds:Number(e.target.value)})
                        }}
                    />
                    <div>开关</div>
                    <Switch
                        style={{ width: "10px" }}
                        checked={status === 1}
                        onChange={(value) => {
                            console.log(value)
                            this.setState({status:value?1:0})
                        }}
                    />
                    <Air vertical={30} />

                </TitleContentTable>
                <FlexJustifyCenter>
                    <Button
                        type={"primary"}
                        onClick={()=>{
                            autoHeatvalSet({live_id:liveStore.live_id,min:min,max:max,status:status,seconds:seconds})
                                .then(
                                    message.success('保存成功')
                                ).catch((e) => {
                                message.error(
                                    e.data?.message
                                );
                            })
                        }}
                    >
                        保存
                    </Button>
                </FlexJustifyCenter>
            </>
        );
    }


}
