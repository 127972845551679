/**
 * 网络请求
 *  统一处理公共数据，并自动从返回数据包中提取可用数据，支持Promise，支持错误自动上报
 *  用于逐渐替换index.js
 * Create by liaokai on 2020-03-16 11:13:39
 */
import axios from 'axios';
import { common } from "../common";
import { message } from "antd";


export const prodDomain = "https://portal.huibaifen.com";       //正式服域名
//export const devDomain = "https://portal.huibaifen.com";      //测试服域名
export const devDomain = "http://www.baifen.com/index.php";      //本地服域名

let dev = `${devDomain}`;
let prod = `${prodDomain}`;

let host: string;
switch (process.env.REACT_APP_ENV) {
    case "production":
        host = prod;
        break;
    case "development":
        host = dev;
        break;
    default:
        host = dev;
        break;
}

const headers = {
    "Accept": 'application/json, text/plain, */*;charset=utf-8',
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
};


// 实例化 ajax请求对象
const ajaxInstance = axios.create({
    baseURL: host,
    timeout: 50 * 1000,
    withCredentials: true,
});


/**
 * 请求拦截器
 */
ajaxInstance.interceptors.request.use(
    async config => {
        config.headers = {
            ...headers,
        };
        return config;
    },
    err => {
        common.sentry.captureException(err);
        return Promise.reject(err);
    }
);

/**
 * 响应拦截器
 */
ajaxInstance.interceptors.response.use(
    response => {
        return new Promise(((resolve, reject) => {
            if (response.status === 200) {
                if (response.data.code) {
                    switch (response.data.code) {
                        case 200:
                            resolve(response);
                            return;
                        case 30001:
                            common.singleTip("请重新登录");
                            window.location.hash = "#/login"
                            break;
                        default:
                            break;
                    }
                } else {
                    common.sentry.captureException({
                        msg: "返回值中没有包体",
                        response: response
                    });
                }
            } else {
                common.sentry.captureException({
                    msg: "HTTP请求错误",
                    response: response
                });
            }
            reject(response);
        }));
    },
    error => {
        common.sentry.captureException(error);
        return Promise.reject(error)   // 返回接口返回的错误信息
    }
);


// get请求
const get = function <T = any>(url: string, params = {}) {
    return new Promise<T>((resolve, reject) => {
        ajaxInstance.get(`${host}${url}`, {
            params: params
        })
            .then(response => {
                if (response) {
                    resolve(response.data.result as T);
                } else {
                    resolve(undefined);
                }
            })
            .catch(err => {
                common.sentry.captureException(err);
                reject(err)
            })
    })
};

// post请求
const post = function <T = any>(url: string, data = {}) {
    console.log('data', data);
    return new Promise<T>((resolve, reject) => {
        ajaxInstance.post(`${host}${url}`, data)
            .then(response => {
                if (response) {
                    resolve(response.data.result as T);
                } else {
                    resolve(undefined);
                }
            }, err => {
                common.sentry.captureException(err);
                reject(err)
            })
    })
};

export { post, get, ajaxInstance }
