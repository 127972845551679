/**
 * 模块-预览
 * Create by liaokai on 2020-03-16 17:07:59
 */
import * as React from 'react';
import {Component} from "react";
import style from "./preview.scss"
import {Avatar, Badge, Button, Card, Col, Form, Input, message, Row} from "antd";
import {Link} from "react-router-dom";
import {Air} from "../../../../../../../components/air/Air";
import {Qrcode} from "../../../../../../../components/common/qrcode";
import {TitleContentTable} from "./title-content-table/title-content-table";
import {ImageUpdater} from "./image-updater/image-updater";
import {RouteComponentProps} from "react-router";
import {api} from "../../../../../../../common/app";
import {common} from "../../../../../../../common/common";
import {devDomain, prodDomain} from "../../../../../../../common/apis/http";

interface IProps extends RouteComponentProps<any,any>{

}

interface IState{
    title : string,
    content : string,
    imgUrl : string
}

export class Preview extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {
        title : "",
        content : "",
        imgUrl : ""
    };
    constructor(props: IProps){
        super(props);
    }

    componentDidMount(): void {
        (api as any).getWxShare(
            this.props.match.params.meeting_id,
        ).then((res : any) => {
            if(res.code === 200){
                let {id, meetid, title, desc, icon} = res.result ?? {};
                this.setState({
                    title,
                    content : desc,
                    imgUrl : icon,
                })
            }

        })
    }

    render() {
        let {history, match : {params}} = this.props;
        let {title,content,imgUrl} = this.state;
        let shareUrl = process.env.REACT_APP_ENV === "production" ?
            `${prodDomain}/wx/#/home/index/${params.meeting_id}` :
            `${devDomain}/wx/#/home/index/${params.meeting_id}`;
        return (
            <div className={style.container}>
                {/*<div className={style.title}>*/}
                {/*    <span style={{cursor: "pointer"}} onClick={()=>{*/}
                {/*        this.props.history.goBack();*/}
                {/*    }}>会议介绍</span> > <span>预览</span>*/}
                {/*</div>*/}
                <div className={style.content}>
                    <div className={style.ope}>
                        <div className={style.text}>
                            通常被称为“九宫格”，展示在参会者的手机端，并通过九宫格了解会议相关信息
                        </div>
                        <div className={style.btns}>
                            <Button type={"primary"} shape={"round"} disabled={false} >
                                <Link to={"edit/new"}>增加模块</Link>
                            </Button>
                        </div>
                    </div>
                    <div className={style.show}>
                        <div className={style.forms}>
                            <TitleContentTable>
                                <div className={style.title}>
                                    分享标题
                                </div>
                                <div className={style.body}>
                                    <Input placeholder={"这里填写分享标题"} value={title} size={"large"} onChange={event => this.setState({title : event.target.value})}/>
                                </div>
                                <div className={style.title}>
                                    分享内容
                                </div>
                                <div className={style.body}>
                                    <Input placeholder={"这里填写分享内容"} value={content} size={"large"} onChange={event => this.setState({content : event.target.value})}/>
                                </div>
                                <div className={style.title}>
                                    分享设置
                                </div>
                                <div className={style.body}>
                                    <div className={style.updater}>
                                        <ImageUpdater value={imgUrl} onChange={(imgUrl : string)=>{
                                            this.setState({imgUrl})
                                        }}/>
                                    </div>
                                </div>
                                <div></div>
                                <div className={style.btns}>
                                    <Button type={"primary"} onClick={()=>{
                                        (api as any).setWxShare(
                                            params.meeting_id,
                                            title,
                                            content,
                                            imgUrl,
                                            params.shareId
                                        ).then((res : any) => {
                                            if(res.code === 200){
                                                message.success("保存成功");
                                                history.goBack();
                                            }
                                        })
                                    }}>
                                        保存
                                    </Button>
                                    <Air horizontal={10}/>
                                    <Button onClick={()=>{
                                        history.goBack();
                                    }}>
                                        取消
                                    </Button>
                                </div>
                            </TitleContentTable>
                        </div>
                        <div>
                            <Card>
                                <div className={style.qr}>
                                    <Qrcode value={shareUrl}/>
                                    <div className={style.text}>微信扫码预览</div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
