/**
 * 调研问卷列表
 * Create by liaokai on 2020-06-16 18:12:18
 */
import {toPost} from "../../http-utils";

export interface ILiveSurveyQuestionnaireIndexItem {
    "id": number, //id
    "title": string, //标题
    "is_edit": boolean, //true可以编辑false不可以编辑
    "is_publish": boolean, //false 未发布 true 已经发布
    "publish_type": boolean, //false 至灰 true 高亮
    "update_time": string //更新时间
}

export const liveSurveyQuestionnaireIndex = toPost<
    {
        live_id : string,
        page : number,
        paginate? : number
    },
    {
        "current_page": number,
        "total": number,
        "last_page": number,
        "data": ILiveSurveyQuestionnaireIndexItem[]
    }
>("/merchant/live/liveSurveyQuestionnaireIndex");

