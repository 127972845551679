/**
 * 互动问答表格
 * todo 拆分编辑、添加为单个页面
 * Create by liaokai on 2020-05-25 18:55:45
 */
import * as React from "react";
import { Component } from "react";
import { Flex } from "../../../../../../../components/style/flex/flex";
import { Button, Input, message, Modal, Spin, Switch, Table } from "antd";
import { Air } from "../../../../../../../components/air/Air";
import { FlexJustifyBetween } from "../../../../../../../components/style/flex/flex-justify-between";
import { liveStore } from "../../live.store";
import { common } from "../../../../../../../common/common";
import { enableShowInfo } from "../../../../../../../common/apis/apis/live/enableShowInfo";
import { enableShowSet } from "../../../../../../../common/apis/apis/live/enableShowSet";
import {replyKeywords, replyKeywordsList} from "../../../../../../../common/apis/apis/live/replyKeywordsList";
import {ReplyKeywordsStoreView} from "./reply-keywords-store-view";
import {AddQuestionForm} from "../../qa/component/add-question-form";
import {replyKeywordsStore} from "../../../../../../../common/apis/apis/live/replyKeywordsStore";
import {replyKeywordsDel} from "../../../../../../../common/apis/apis/live/replyKeywordsDel";

interface IProps {}

interface IState {
    columns: any[];
    dataSource: replyKeywords[];
    currentPage: number;
    total: number;
    pageSize: number;
    kai: boolean;
    showModal: boolean;
    showModalDel: boolean;
    addReplyKeywords: replyKeywords;
    currentId: any;
}
export class ReplyKeywordsListView extends Component<IProps, IState> {
    readonly state: IState = {
        columns: [
            {
                title: "关键词",
                dataIndex: "keywords",
                key: "keywords",
                render: (text: boolean) => text,
            },
            {
                title: "回复内容",
                dataIndex: "reply_content",
                key: "reply_content",
                render: (text: string) => text,
            },
            {
                title: "操作",
                dataIndex: "id",
                key: "id",
                render: (
                    text: string,
                ) => (
                   <Button
                       onClick={()=>{
                           this.setState({showModalDel:true,currentId:text})
                       }}
                   type={"danger"}
                   >
                       删除
                   </Button>
                ),
            },
        ],
        dataSource: [],
        addReplyKeywords: {
            id:0,
            keywords:"",
            reply_content:"",
        },
        pageSize: common.defaultPaginate,
        currentId: 0,
        total: 0,
        currentPage: 1,
        showModal:false,
        showModalDel:false,
        kai:false
    };

    refreshPage = () => {
        this.reqTableData(this.state.currentPage);
    };

    reqTableData(page: number) {
        replyKeywordsList({
            live_id: liveStore.live_id,
            page,
            paginate: this.state.pageSize,
        })
            .then((res) => {
                this.setState({
                    dataSource: res.data,
                    total: res.total,
                    currentPage: page,
                });
            })
            .finally(() => {
                // this.setState({ loading: false });
            });
    }

    componentDidMount(): void {
        //获取图标状态
        enableShowInfo({ live_id: liveStore.live_id, type: 31 }).then((res) => {
            this.setState({ kai: res.is_show });
        });
        this.reqTableData(1);
    }



    render() {
        let {} = this.props;
        let {
            currentId,
            addReplyKeywords,
            kai,
            showModalDel,
            columns,
            dataSource,
            pageSize,
            total,
            currentPage,
            showModal,
        } = this.state;
        return (
            <>
            <div>
                <FlexJustifyBetween>
                    <Flex>
                        <Switch
                            checked={kai}
                            onChange={(v) => {
                                enableShowSet({
                                    live_id: liveStore.live_id,
                                    type: 31,
                                }).then((res) => {
                                    this.setState({
                                        kai: res.is_show,
                                    });
                                });
                            }}
                        />
                        <Air horizontal={3} />
                        <div>开启机器人回复</div>
                    </Flex>
                    <Button
                        type={"primary"}
                        onClick={()=>{
                            this.setState({showModal:true})
                        }}
                    >
                        新增关键词
                    </Button>
                </FlexJustifyBetween>
                <Air vertical={5} />
                <Spin
                    indicator={<div />}
                    tip="尚未开启问答"
                    spinning={!kai}
                >
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        // emptyText={"暂无内容"}
                        pagination={{
                            defaultCurrent: 1,
                            current: currentPage,
                            pageSize: pageSize,
                            total: total,
                            onChange: (page) => {
                                this.reqTableData(page);
                                this.setState({ currentPage: page });
                            },
                        }}
                    />
                </Spin>
            </div>
            <Modal
                visible={showModal}
                title={"添加关键词"}
                okText={"保存"}
                cancelText={"取消"}
                onOk={()=>{
                    if(addReplyKeywords.keywords === "" || addReplyKeywords.reply_content ===""){
                        message.error("请填写关键词和回复内容")
                    }
                    replyKeywordsStore(
                        {live_id:liveStore.live_id
                            ,keywords:addReplyKeywords.keywords
                            ,reply_content:addReplyKeywords.reply_content
                        }).then(()=>{
                        message.success("提交成功")
                        this.setState({showModal:false})
                        this.refreshPage();
                    }).catch(()=>{
                        message.error("提交失败")
                    })
                }}

                onCancel={()=>{
                    this.setState({showModal:false})
                }}
            >
                <ReplyKeywordsStoreView
                    data={addReplyKeywords}
                    onChange={(addReplyKeywords) => {
                        this.setState({ addReplyKeywords: addReplyKeywords });
                    }}
                />
            </Modal>


                <Modal
                    visible={showModalDel}
                    title={"删除关键词"}
                    okText={"确定删除"}
                    cancelText={"取消"}
                    onOk={()=>{
                        console.log(currentId)
                        replyKeywordsDel(
                            {live_id:liveStore.live_id
                                ,id:currentId
                            }).then(()=>{
                            message.success("删除成功")
                            this.setState({showModalDel:false})
                            this.refreshPage();
                        }).catch(()=>{
                            message.error("删除成功")
                        })
                    }}

                    onCancel={()=>{
                        this.setState({showModalDel :false})
                    }}
                >
                    您确定删除么？
                </Modal>
            </>
        );
    }
}
