/**
 * 邮件模板列表
 * Create by liaokai on 2020-04-23 17:52:00
 */
import {toPost} from "../../http-utils";
export interface IemailAddressBookItem {
    "id": number,
    "name": string,
    "create_time": string, // 创建时间
}

export const emailAddressBookUpdate = toPost<
    {
        id : number,
        name: string
    },
    {
        "id": number,
        "mid": number,
        "name": string,
        "create_time": string,
        "update_time": string,
        "delete_time": any,
    }
    >("/merchant/Email/emailAddressBookUpdate");
