/**
 * 编辑回看视频信息
 * Create by liaokai on 2020-06-19 16:08:17
 */
import * as React from 'react';
import {Component} from "react";
import { message, Modal} from "antd";
import {TitleContentTable} from "../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import {emailTemplateDestroy} from "../../../../../common/apis/apis/email/emailTemplateDestroy";
import {emailTemplateLogCancel} from "../../../../../common/apis/apis/email/emailTemplateLogCancel";


interface IProps{
    visible : boolean
    onCancel? : () => void
    onSubmit? : (msg:string) => void
    id : number
}

interface IState{
}

export class RecordCancel extends Component<IProps,IState>{
    readonly state : IState = {
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        // if(JSON.stringify(this.props.id) !== JSON.stringify(prevProps.id) ){
        //     this.setState({editItem : this.props.editItem ?? defaultItem})
        // }
    }

    render() {
        let {onCancel,onSubmit,visible,id} = this.props;
        let {} = this.state;
        return (
            <Modal
                visible={visible}
                onCancel={()=>{
                    onCancel?.();
                }}
                onOk={()=>{
                    console.log(this.props)
                    emailTemplateLogCancel({id:id}).then(()=>{
                        onSubmit?.('取消成功')
                        onCancel?.()
                    }).catch(e => {
                        message.error("取消失败 " + e.data?.message)
                    })
                }}
                cancelText={"取消"}
                okText={"确认"}
                title={"取消定时发送邮件"}
            >
                <TitleContentTable colGap={10} rowGap={10}>
                    <div>确定要取消定时发送？</div>
                </TitleContentTable>
            </Modal>
        );
    }
}
