/**
 * 短信模板动态字段
 * Create by liaokai on 2020-04-23 18:58:02
 */
import {toPost} from "../../http-utils";

export interface IMeetingTagItem {
    "id": number,
    "name": string,
}

export const smsField = toPost<
    {
        meetid : number
    },
    IMeetingTagItem[]
>("/merchant/Lcm/smsField");
