/**
 * 响应式主体
 * Create by liaokai on 2020-03-10 12:08:22
 */
import * as React from 'react';
import {Component} from "react";
import {Col, Dropdown, Menu, Row} from "antd";

interface IProps{

}

interface IState{

}

export class Responsive extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {

    };
    constructor(props: IProps){
        super(props);
    }
    render() {
        let {children} = this.props;
        let {} = this.state;
        return (
            <Row style={{minWidth: 900}}>
                <Col xs={1} sm={1} md={1} lg={2} xl={2}/>
                <Col xs={22} sm={22} md={22} lg={20} xl={20}>
                    {children}
                </Col>
                <Col xs={1} sm={1} md={1} lg={2} xl={2}/>
            </Row>
        );
    }
}
