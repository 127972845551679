/**
 * 邮件模板列表
 * Create by liaokai on 2020-04-23 17:52:00
 */
import {toPost} from "../../http-utils";

interface IEmailAddressListItem {
    "id": number,
    "name": string,
    "email": string
}

export const emailAddressList = toPost<
    {
        email_address_book_id : number,
        search ?: string,
        page: number
        paginate: number
    },
    {
        "total": number,
        "per_page": number,
        "current_page": number,
        "last_page": number,
        "list": IEmailAddressListItem[]
    }
    >("/merchant/Email/emailAddressList");
