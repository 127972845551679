import React, { useState, useEffect, useCallback } from "react";
import { Form, Switch, message, Spin, Button, Tabs, Input, Empty } from "antd";
import { useAntdTable, useBoolean, useMount } from "ahooks";
import { CloseOutlined } from "@ant-design/icons";
import {
    checkMessageList,
    checkMessage,
    checkMessageUsersList,
    checkMessageUsersSet,
} from "../constants";
import { liveStore } from "../../live.store";
import { Air } from "../../../../../../../components/air/Air";
import { enableShowSet } from "../../../../../../../common/apis/apis/live/enableShowSet";
import { FlexJustifyBetween } from "../../../../../../../components/style/flex/flex-justify-between";
import { Flex } from "../../../../../../../components/style/flex/flex";
import { enableShowInfo } from "../../../../../../../common/apis/apis/live/enableShowInfo";
import FilterItem from "./filter-item";
import { common } from "../../../../../../../common/common";
import { bindClient } from "../../../../../../../common/apis/apis/live/bindClient";
import { FilterMessage, MessageUser } from "../types";
import { merge } from "lodash";
import "./index.css";

const { Search } = Input;
const { TabPane } = Tabs;
const prefix = "component-filter";

export default function () {
    const [form] = Form.useForm();
    const [tableEnable, { toggle }] = useBoolean(false);
    const [ws, setWs] = useState(null as any);

    const [initToken, setInitToken] = useState({
        checkToken: false,
        rejectToken: false,
        userToken: false,
    });
    const [listUncheck, setListUncheck] = useState([] as FilterMessage[]);
    const [listReject, setListReject] = useState([] as FilterMessage[]);
    const [listClose, setListClose] = useState([] as MessageUser[]);
    const [renderListUser, setRenderListUser] = useState([] as MessageUser[]);
    const [selectedKey, setSelectedKey] = useState("1");
    const [searchValue, setSearchValue] = useState("");

    useMount(() => {
        enableShowInfo({
            live_id: liveStore.live_id,
            type: 30,
        }).then((res) => {
            toggle(res.is_show);
        });
    });

    /**
     * 进入页面先请求一下未审核的列表然后再用ws维护
     */
    useEffect(() => {
        checkMessageList({ live_id: liveStore.live_id, status: 1 }).then(
            (response) => {
                setInitToken((prevToken) => {
                    return {
                        ...prevToken,
                        checkToken: true,
                    };
                });
                setListUncheck(response);
            }
        );
    }, []);

    /**
     * 进入页面先请求一下已拒绝的列表然后再用ws维护
     */
    useEffect(() => {
        checkMessageList({ live_id: liveStore.live_id, status: 3 }).then(
            (response) => {
                setInitToken((prevToken) => {
                    return {
                        ...prevToken,
                        rejectToken: true,
                    };
                });
                setListReject(response);
            }
        );
    }, []);

    const fetchUsers = () => {
        checkMessageUsersList({ live_id: liveStore.live_id }).then(
            (response) => {
                setInitToken((prevToken) => {
                    return {
                        ...prevToken,
                        userToken: true,
                    };
                });
                setListClose(response);
            }
        );
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        if (!initToken.userToken) {
            return;
        }
        if (searchValue === "") {
            setRenderListUser(listClose);
        } else {
            const renderList = listClose.filter((l) => {
                const token = l.name.indexOf(searchValue) !== -1;
                return token;
            });
            setRenderListUser(renderList);
        }
    }, [searchValue, initToken, listClose]);

    /**
     * 插入一条数据到对应的列表中
     */
    const handleMessageInsertList = (
        item: FilterMessage | FilterMessage[],
        callback: (params: any) => void,
        compare?: boolean
    ) => {
        if (!!Array.isArray(item)) {
            // 是数组
            callback((prevState: FilterMessage[]) => {
                const nextList = item.concat(prevState);
                return nextList;
            });
        } else {
            callback((prevState: FilterMessage[]) => {
                const nextList = !compare
                    ? [item].concat(prevState)
                    : prevState.findIndex((p) => p.id === item.id) === -1
                    ? [item].concat(prevState)
                    : prevState;
                return nextList;
            });
        }
    };

    /**
     * 删除一条数据到对应的列表中
     */
    const handleMessageRemoveList = (
        item: FilterMessage | FilterMessage[],
        callback: (params: any) => void
    ) => {
        if (!!Array.isArray(item)) {
            // 是数组
            callback((prevState: FilterMessage[]) => {
                const nextList: FilterMessage[] = merge([], prevState);
                const startIndex = prevState.findIndex(
                    (i) => i.id === item[0].id
                );
                nextList.splice(startIndex > 0 ? startIndex : 0, item.length);
                return nextList;
            });
        } else {
            callback((prevState: FilterMessage[]) => {
                const nextList: FilterMessage[] = merge([], prevState);
                const itemIndex = nextList.findIndex((i) => i.id === item.id);

                if (itemIndex !== -1) {
                    nextList.splice(itemIndex, 1);
                }
                return nextList;
            });
        }
    };

    /**
     * 等列表请求结束了 再用websocket维护
     */
    useEffect(() => {
        console.log("ws", ws);
        if (!!ws) {
            return;
        }
        if (initToken.checkToken === true && initToken.rejectToken === true) {
            let webSocket = new WebSocket(
                process.env.REACT_APP_ENV === "production"
                    ? common.wsUrlProd
                    : common.wsUrlDev
            );
            webSocket.onmessage = (e) => {
                const packageData = JSON.parse(e.data);
                console.log("packageData", packageData);
                switch (packageData.action) {
                    case "bind":
                        const bindData = packageData;
                        bindClient({
                            live_id: liveStore.live_id,
                            client_id: bindData.data.client_id,
                            type: 2,
                        } as any)
                            .then((res) => {
                                message.success("准备就绪");
                            })
                            .catch((e) => {
                                message.error("绑定ws失败 " + e.data?.message);
                            });
                        break;
                    case "check_message1":
                        /**
                         * 未审核 之前是 check_message2;
                         */
                        handleMessageInsertList(
                            packageData.data,
                            setListUncheck
                        );
                        break;
                    case "check_message2":
                        // 未审核;
                        break;
                    case "message3":
                        // 审核拒绝;
                        handleMessageInsertList(
                            packageData.data,
                            setListReject
                        );
                        break;

                    case "add_user":
                        handleMessageInsertList(packageData.data, setListClose, true);
                        break;
                    case "del_user":
                        handleMessageRemoveList(packageData.data, setListClose);
                        break;
                }
            };
            setWs(webSocket);
        }
    }, [initToken, ws]);

    const changeShowSet = () => {
        enableShowSet({
            live_id: liveStore.live_id,
            type: 30,
        })
            .then((res) => {
                toggle(res.is_show);
            })
            .catch((e) => {
                message.error("设置失败 " + e.data?.message);
            });
    };

    const onAllPass = () => {
        check(listUncheck, 1, 2);
    };

    const onAllUnPass = () => {
        check(listUncheck, 1, 3);
    };

    const callback = (params: any) => {
        console.log("callback", params);
    };

    /**
     * item 点击数据
     * tab 处于第几个tab
     * type 拒绝还是通过
     */
    const check = (
        item: FilterMessage | FilterMessage[],
        tab: number,
        type: number
    ) => {
        const payload = {
            live_id: liveStore.live_id,
            status: tab,
            ids: Array.isArray(item) ? item.map((i) => i.id) : [item.id],
            type,
        };
        checkMessage(payload)
            .then((response) => {
                if (type === 2) {
                    // 通过审核 通过审核就把该条移除对应列表
                    handleMessageRemoveList(
                        item,
                        Number(tab) === 1 ? setListUncheck : setListReject
                    );
                } else if (type === 3) {
                    /**
                     * 后端ws维护
                     */
                    handleMessageRemoveList(item, setListUncheck);
                }
            })
            .catch((error) => {
                message.warn(error?.data?.result);
            });
    };

    const checkUser = (user: MessageUser, type: number) => {
        const payload = {
            id: user.id,
            live_id: liveStore.live_id,
            is_forbid_message: type,
        };
        checkMessageUsersSet(payload).then((response) => {
            fetchUsers();
        });
    };

    const renderTab = (tabProps: any, list: any[], key: any) => {
        return (
            <TabPane
                tab={`${tabProps.tab}（${list.length}）`}
                key={tabProps.key}
            >
                <div
                    style={{
                        maxHeight: "900px",
                        overflow: "auto",
                        minHeight: "400px",
                    }}
                    className={`${prefix}-scrollbar`}
                >
                    {list.length > 0 ? (
                        list.map((item) => {
                            return (
                                <FilterItem
                                    key={item.id}
                                    data={item}
                                    onClick={({ itemType, payload }: any) => {
                                        if (itemType === "filter") {
                                            check(item, key, payload.type);
                                        } else if (itemType === "user") {
                                            checkUser(item, payload.type);
                                        }
                                    }}
                                />
                            );
                        })
                    ) : (
                        <div>
                            <Empty />
                        </div>
                    )}
                </div>
            </TabPane>
        );
    };
    const tabsData = [
        {
            tab: "未审核",
            key: "1",
            list: listUncheck,
        },
        {
            tab: "已阻止",
            key: "3",
            list: listReject,
        },
        {
            tab: "禁言",
            key: "4",
            list: listClose,
        },
    ];

    return (
        <div>
            <Form form={form}>
                <FlexJustifyBetween
                    style={{ marginBottom: 12, alignItems: "center" }}
                >
                    <div> </div>
                    <Flex>
                        <Switch
                            checked={tableEnable}
                            onChange={changeShowSet}
                        />
                        <Air horizontal={3} />
                        <div>聊天过滤</div>
                    </Flex>
                </FlexJustifyBetween>
            </Form>

            <Spin
                indicator={<div />}
                tip="尚未开启审核"
                spinning={!tableEnable}
            >
                <div style={{ position: "relative" }}>
                    {selectedKey === "1" && (
                        <div
                            className={`${prefix}-buttons`}
                            style={{ zIndex: 999 }}
                        >
                            <Button type={"primary"} onClick={onAllPass}>
                                全部通过
                            </Button>
                            <Air horizontal={5} />
                            <Button onClick={onAllUnPass}>全部禁止</Button>
                        </div>
                    )}
                    {selectedKey === "4" && (
                        <div
                            className={`${prefix}-buttons`}
                            style={{ zIndex: 999 }}
                        >
                            <Search
                                placeholder="请输入用户昵称"
                                // onSearch={(value) => setSearchValue(value)}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                enterButton
                                suffix={
                                    searchValue !== "" && (
                                        <CloseOutlined
                                            style={{ fontSize: 12 }}
                                            onClick={() => setSearchValue("")}
                                        />
                                    )
                                }
                            />
                        </div>
                    )}
                    <Tabs activeKey={selectedKey} onChange={setSelectedKey}>
                        {tabsData.map((item) => {
                            const renderList =
                                item.key !== "4" ? item.list : renderListUser;
                            return renderTab(item, renderList, item.key);
                        })}
                    </Tabs>
                </div>
            </Spin>
        </div>
    );
}
