/**
 * 直播的分享详情
 * Create by liaokai on 2020-06-10 17:04:47
 */
import {toPost} from "../../http-utils";

export interface IliveShareShow {
    "live_id": number,
    "title": string,
    "content": string,
    "link": string,
    "cover": string
}

export const liveShareShow = toPost<
    {
        live_id : string
    },
    IliveShareShow
>("/merchant/live/liveShareShow");

