/**
 * 单次直播
 * Create by liaokai on 2020-05-26 10:54:41
 */
import * as React from 'react';
import {Component} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {LiveListView} from "../index";
import {LiveMenu} from "./menu/menu";
import {RouteComponentProps} from "react-router";
import {liveStore} from "./menu/live.store";
import {OtherMenu} from "./menu/other-menu";
import {Live_room} from "./live_room/live_room";

interface IProps extends RouteComponentProps<{live_id : string}>{

}

interface IState{

}

export class OtherLive extends Component<IProps,IState>{
    readonly state : IState = {

    };

    componentWillMount() {
        liveStore.setLiveId(this.props.match.params.live_id);
    }

    componentDidMount(): void {
        // liveStore.setReload(()=>{
        //     liveShow({id : this.props.match.params.live_id ?? ""}).then(res => {
        //         liveStore.setLiveInfo(res);
        //     })
        // })
        // liveStore.reload();
        // presentStore.setReload(()=>{
        //     liveIconIndex({
        //         live_id : this.props.match.params.live_id
        //     }).then(res => {
        //         presentStore.setPresentInfo(res)
        //     })
        // })
        // presentStore.reload();
    }

    render() {
        let {match : {path, url}, location: {pathname}} = this.props;
        let {} = this.state;
        return (

            <Switch>
                <Route path={`${path}/other-menu`} component={OtherMenu} />
                <Route path={`${path}/live_room`} component={Live_room} />
                <Redirect path={path} to={`${path}/other-menu`} />
            </Switch>
        );
    }
}
