/**
 * 编辑表单项
 * Create by liaokai on 2020-03-24 13:43:41
 */
import * as React from 'react';
import { Component, createContext } from "react";
import { tFormTypes } from "../form-preview/form-preview";
import { AutoComplete, Button, Dropdown, Input, Menu, message, Modal, Popover, Select } from "antd";
import { apis } from "../../../../../../../common/apis/apis";
import { RegisterFormContext } from "../register-form-context";
import { DownOutlined, PlusCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons/lib";
import { Air } from "../../../../../../../components/air/Air";
import style from "../../base/MeetingInfo.scss";
import { common } from "../../../../../../../common/common";

export interface IFormItem {
    name: string,      //表单项名称
    rule: {
        key: string        //规则代表
        type: tFormTypes    //规则类型
        content?: any      //规则具体限制
    }
}

interface IProps {
    onChange?: (data: IFormItem | undefined) => void
}

interface IState {
    input: string; //表单项内容
    showNewFormItemModal: boolean; //是否展示dropdown
    presetRules: IFormItem[]; //预置规则(经网络请求)
    newFormItemName: string;
}

class FormItemTitle extends Component<IProps, IState>{
    //state初始化
    readonly state: IState = {
        input: "",
        showNewFormItemModal: false,
        presetRules: [],
        newFormItemName: "",
    };
    constructor(props: IProps) {
        super(props);
    }
    static contextType = RegisterFormContext;

    //搜索预置表单
    search = (key: string): IFormItem | undefined => {
        let { presetRules } = this.state;
        return presetRules.filter(value => value.name === key)?.[0]
    };

    public clear() {
        this.setState({ input: "" });
        this.componentDidMount();
        this.props.onChange?.({
            name: "",
            rule: {
                type: "input",
                key: ""
            }
        });
    }

    async componentDidMount(): Promise<void> {
        apis.getRegisterField({ meetid: this.context.meetingId }).then(res => {
            let rules: IFormItem[] = res.list.myself.map(value => {
                return {
                    name: value.zh_name,
                    rule: {
                        type: value.type,
                        key: value.en_name
                    }
                }
            }).concat(res.list.system.map(value => {
                return {
                    name: value.zh_name,
                    rule: {
                        type: value.type,
                        key: value.en_name
                    }
                }
            }));
            this.setState({ presetRules: rules })
        });
    }

    render() {
        let { onChange } = this.props;
        let { input, showNewFormItemModal, presetRules, newFormItemName } = this.state;
        const options = presetRules.map((value, index) => {
            return {
                value: `${value.name}`,
                label: `${value.name}`,
                key: `${value.name}`
            }
        })
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                {/* <Select
                    style={{ width: 250 }}
                    allowClear
                    // value={this.state.value}
                    onChange={(text: string) => {
                        console.log("text", text);
                        this.setState({ input: text });
                        onChange?.(this.search(text));
                    }}
                    placeholder={"选择表单项"}
                >
                    <div
                    style={{ display: "flex", flexDirection: 'row', alignItems: "center"}} onClick={() => {
                        this.setState({ showNewFormItemModal: true })
                    }}>
                        <PlusCircleOutlined style={{ color: common.color.primary }} />
                        <Air horizontal={3} />
                        <Button type={"link"} style={{ padding: 0, fontSize: 10 }}>新建表单项</Button>
                    </div>
                    {options.map((item) => {
                        return (
                            <Select.Option value={item.value} key={item.key}>
                                {item.label}
                            </Select.Option>
                        );
                    })}
                </Select> */}
                <AutoComplete
                    style={{ width: 250 }}
                    allowClear
                    placeholder={"选择表单项"}
                    options={[
                        {
                            label: <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => {
                                this.setState({ showNewFormItemModal: true })
                            }}>
                                <PlusCircleOutlined style={{ color: common.color.primary }} />
                                <Air horizontal={3} />
                                <Button type={"link"} style={{ padding: 0, fontSize: 10 }}>新建表单项</Button>
                            </div>,
                            options: options
                        }
                    ]}
                    filterOption={(inputValue, option) => {
                        return option?.value?.indexOf?.(inputValue) >= 0
                    }}
                    value={input}
                    onChange={text => {
                        this.setState({ input: text });
                        onChange?.(this.search(text))
                    }}
                >
                </AutoComplete>
                <Modal
                    title={"添加表单项"}
                    centered={true}
                    visible={showNewFormItemModal}
                    cancelText={"取消"}
                    okText={"添加"}
                    onCancel={() => {
                        this.setState({ showNewFormItemModal: false });
                    }}
                    onOk={() => {
                        apis.addRegisterField({
                            zh_name: newFormItemName,
                            meetid: this.context.meetingId,
                        } as any)
                            .then(() => {
                                this.setState({ showNewFormItemModal: false });
                                this.componentDidMount();
                                message.success(
                                    `表单项 "${newFormItemName}" 新建完成`
                                );
                            })
                            .catch(() => {
                                message.error("添加失败");
                            });
                    }}
                >
                    <Input
                        placeholder={"输入表单项的名称"}
                        value={newFormItemName}
                        onChange={(event) =>
                            this.setState({
                                newFormItemName: event.target.value,
                            })
                        }
                    />
                </Modal>
            </div>
        );
    }
}

export { FormItemTitle }
