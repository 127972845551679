/**
 * 直播管理系统
 * Create by liaokai on 2020-05-22 19:41:18
 */
import * as React from 'react';
import {Component} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {MeetingListView} from "../meeting-list/index/meeting-list-view";
import {MeetingMenu} from "../meeting-list/meeting/menu/menu";
import {RouteComponentProps} from "react-router";
import {LiveListView} from "./index";
import {LiveMenu} from "./live/menu/menu";
import {LiveEdit} from "./live/menu/edit/edit";
import {NewLive} from "./new-live/new-live";
import {Live} from "./live/live";
import {LiveProkectEdit} from "./live/menu/prokect/new/live-prokect-edit";
import {LiveProkectPreview} from "./live/menu/prokect/preview/live-prokect-preview";
import {ProkectPreview} from "./prokect-preview/prokect-preview";
import {ProkectEdit} from "./prokect-edit/prokect-edit";

interface IProps extends RouteComponentProps{

}

interface IState{

}

export class LiveList extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {match : {path, url}, location: {pathname}} = this.props;
        let {} = this.state;
        return (
            <Switch>
                <Route path={`${path}/index`} component={LiveListView}/>
                <Route path={`${path}/live/:live_id`} component={Live}/>
                <Route path={`${path}/new_live`} component={NewLive}/>

                <Route path={`${path}/prokect/edit/new`} component={ProkectEdit}/>
                <Route path={`${path}/prokect/edit/:prokect_id`} component={ProkectEdit}/>
                <Route path={`${path}/prokect/preview/:prokect_id`} component={ProkectPreview}/>
                <Redirect path={path} exact to={`${path}/index`}/>
            </Switch>
        );
    }
}
