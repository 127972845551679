import React from "react";
import { Form, Table, Switch, Button, message, Spin } from "antd";
import { useAntdTable, useBoolean, useMount } from "ahooks";
import {
    merchantLiveAdList,
    formatListResult,
    merchantLiveAdSet,
    merchantLiveAdDestroy,
} from "./constants";
import { liveStore } from "../live.store";
import { Air } from "../../../../../../components/air/Air";
import { enableShowSet } from "../../../../../../common/apis/apis/live/enableShowSet";
import { FlexJustifyBetween } from "../../../../../../components/style/flex/flex-justify-between";
import { Flex } from "../../../../../../components/style/flex/flex";
import { enableShowInfo } from "../../../../../../common/apis/apis/live/enableShowInfo";
import { Link, useHistory } from "react-router-dom";

function Advertisement() {
    const history = useHistory();
    const [form] = Form.useForm();
    const [tableEnable, { toggle }] = useBoolean(false);

    useMount(() => {
        enableShowInfo({
            live_id: liveStore.live_id,
            type: 12,
        }).then((res) => {
            toggle(res.is_show);
        });
    });

    const changeShowSet = () => {
        enableShowSet({
            live_id: liveStore.live_id,
            type: 12,
        })
            .then((res) => {
                toggle(res.is_show);
            })
            .catch((e) => {
                message.error("设置失败 " + e.data?.message);
            });
    };

    const { tableProps, search } = useAntdTable(
        (paginatedParams: any, tableProps: any) => {
            return merchantLiveAdList({
                live_id: liveStore.live_id,
                ...tableProps,
            }).then((response) => {
                console.log("response", response);
                return response;
            });
        },
        { form, formatResult: formatListResult }
    );
    const { submit, reset } = search;

    const onChangeAdvertSet = (item: any) => {
        console.log("item", item);
        const payload = {
            id: item.id,
        };
        merchantLiveAdSet(payload)
            .then((response) => {
                submit();
                console.log("response", response);
            })
            .catch((error) => {
                message.error(error.message);
            });
    };

    const onDelete = async (id: number) => {
        const result = await merchantLiveAdDestroy({ id });
        console.log("result", result);
        message.success("删除成功");
        submit();
    };

    const columns = [
        {
            title: "广告名称",
            dataIndex: "title",
        },
        {
            title: "更新时间",
            dataIndex: "update_time",
        },
        {
            title: "广告位置",
            dataIndex: "location",
        },
        {
            title: "状态",
            dataIndex: "status",
            render: (key: any, item: any) => {
                return (
                    <Switch
                        checked={!!item.status}
                        onChange={() => onChangeAdvertSet(item)}
                    />
                );
            },
        },
        {
            title: "操作",
            render: (key: any) => {
                return (
                    <div>
                        <Button
                            onClick={() => {
                                history.push(
                                    `advertisement/edit/edit/${key.id}`
                                );
                            }}
                            type={"link"}
                            style={{ paddingLeft: 0 }}
                        >
                            编辑
                        </Button>
                        {`  `}
                        <Button
                            onClick={() => onDelete(key.id)}
                            type={"link"}
                            style={{ paddingLeft: 0 }}
                        >
                            删除
                        </Button>
                    </div>
                );
            },
        },
    ];
    return (
        <div>
            <FlexJustifyBetween style={{ marginBottom: 12 }}>
                <Flex>
                    <Switch checked={tableEnable} onChange={changeShowSet} />
                    <Air horizontal={3} />
                    <div>开启广告位</div>
                </Flex>
                <Flex>
                    <Button type={"primary"} disabled={!tableEnable}>
                        <Link to="advertisement/edit/new">添加广告位</Link>
                    </Button>
                </Flex>
            </FlexJustifyBetween>
            <Spin
                indicator={<div />}
                tip="尚未开启广告"
                spinning={!tableEnable}
            >
                <Table rowKey="id" columns={columns} {...tableProps} />
            </Spin>
        </div>
    );
}

export { Advertisement };
