import { toPost } from '../../../../../../../common/apis/http-utils';
import { merge } from 'lodash';

export const formatListResult = (result: any) => {
  const response = merge({}, result);
  console.log('response', response);
  return {
    list: response?.data || [],
    total: response?.data.total || 0,
  };
};

export const journeyList = toPost<any, any>('/merchant/meeting/journeyList');

export const journeyInfo = toPost<any, any>('/merchant/meeting/journeyInfo');

export const journeyUpdate = toPost<any, any>('/merchant/meeting/journeyUpdate');

export const journeyDel = toPost<any, any>('/merchant/meeting/journeyDel');

export const getTrain = toPost<any, any>('/api/api/getTrain');

export const getPlain = toPost<any, any>('/api/api/getPlain');