/**
 * 用户管理-详情
 * Create by liaokai on 2020-06-09 14:43:50
 */
import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Descriptions, Avatar } from "antd";
import {
    IusersShowItem,
    usersShow,
} from "../../../../../../../common/apis/apis/live/usersShow";
import { HeaderIcon } from "../../../../../meeting-list/meeting/menu/lecturer/sort/header-icon/header-icon";
import { userShowInput } from "../../../../../../../common/apis/apis/live/userShowInput";
import { Air } from "../../../../../../../components/air/Air";

interface IProps extends RouteComponentProps<{ user_id: string }> { }

interface IState {
    data: IusersShowItem[];
    data2: IusersShowItem[];
}

export class LiveUserDetail extends Component<IProps, IState> {
    readonly state: IState = {
        data: [],
        data2: [],
    };

    componentDidMount() {
        usersShow({
            id: this.props.match.params.user_id,
        }).then((res) => {
            this.setState({ data: res });
        });

        userShowInput({
            id: this.props.match.params.user_id,
        }).then((res) => {
            this.setState({ data2: res })
        });
    }

    renderData = (data: any[],title:string="用户详情") => {
        return (
            <Descriptions column={1} bordered title={title}>
                {data.map((value) => {
                    if (value.type === "str") {
                        return (
                            <Descriptions.Item label={value.key}>
                                {value.value}
                            </Descriptions.Item>
                        );
                    } else if (value.type === "img") {
                        return (
                            <Descriptions.Item label={value.key}>
                                {/* <HeaderIcon src={value.value} /> */}
                                <Avatar src={value.value} />
                            </Descriptions.Item>
                        );
                        // return <div/>
                    }
                })}
            </Descriptions>
        );
    }

    render() {
        let { } = this.props;
        let { data, data2 } = this.state;
        return (
            <div>
                {this.renderData(data)}
                <Air vertical={10} />
                {data2.length > 0 && this.renderData(data2,'支付详情')}
            </div>
        );
    }
}
