import React, { Component } from 'react'
import styles from './LoginBox.scss'

export class LoginBox extends Component {

  render () {
    return (
      <div className={styles.LoginFooter}>
        Copyright © huibaifen.com 2013-2020  沪ICP备17050365号
      </div>
    )
  }
}
export default LoginBox;
