/**
 * 直播转码确认组件
 * Create by liaokai on 2020-06-19 16:08:17
 */
import * as React from 'react';
import {Component} from "react";
import {message, Modal, Radio, Switch} from "antd";
import {IliveRecordIndexItem} from "../../../../../../../common/apis/apis/live/liveRecordIndex";
import {enableShowSet} from "../../../../../../../common/apis/apis/live/enableShowSet";
import {liveStore} from "../../live.store";
import {Air} from "../../../../../../../components/air/Air";
import {FlexJustifyEnd} from "../../../../../../../components/style/flex/flex-justify-end";
import {common} from "../../../../../../../common/common";
import {Input} from "antd/es";
import {uploadFile} from "../../../../../../../common/apis/apis/upload-file";
import {Mask} from "../../edit/component/mask";
import {UploadImgTipStateless} from "../../edit/component/upload-img-tip-stateless";
import {FlexJustifyCenter} from "../../../../../../../components/style/flex/flex-justify-center";
import ImageCropper from "../../../../../meeting-list/new-meeting/components/ImageCropper/ImageCropper";
import {createLiveTask} from "../../../../../../../common/apis/apis/live/createLiveTask";

const defaultItem : IliveRecordIndexItem = {
    "id": 0,
    "title": "",
    "publish_status": "",
    "poster": "",
    "run_time": "",
    "expiration_time": "", //过期时间
    "status": "", //状态 1 代表回放
    "is_default": false, //是否默认
    tanscode_status:1,
    "create_time": "", // 创建时间
}

interface ISubmitData  {
    'live_record_id':any, // 主键
    'status':any, //状态 1 使用水印 0 不适用水印
    'imgFile':any,//图片地址
    'width':any,//宽度和高度
    'height':any, //宽度和高度
    'referPos':any, // 1左上 2左下 3右上 4右下
}

const defaultSubmitData : ISubmitData = {
    'live_record_id':0, // 主键
    'status':0, //状态 1 使用水印 0 不适用水印
    'imgFile':"",//图片地址
    'width':8,//宽度和高度
    'height':8, //宽度和高度
    'referPos':1, // 1左上 2左下 3右上 4右下
}
interface IProps{
    visible : boolean
    onCancel? : () => void
    onSubmit? : () => void
    editItem?: IliveRecordIndexItem
}

interface IState{
    isSet : boolean,
    width : number,
    height : number,
    referPos : number,
    editItem : IliveRecordIndexItem,
    submitData : ISubmitData
    poster : string
}
export class LiveTaskVideo extends Component<IProps,IState>{
    readonly state : IState = {
        isSet : false,
        width : 8,
        height : 8,
        referPos : 0,
        editItem : defaultItem,
        submitData : defaultSubmitData,
        poster : "",
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        console.log(prevProps.editItem)
        if(JSON.stringify(this.props.editItem) !== JSON.stringify(prevProps.editItem) ){
            this.setState({editItem : this.props.editItem ?? defaultItem})
        }
    }
    onCropedImage = async (result: any): Promise<boolean> => {
        let formdata = new FormData();
        formdata.append("file", result);
        formdata.append("type", "base64");
        formdata.append("city", "shenzhen");
        try {
            let res = await uploadFile(formdata);
            this.setState({ poster: res });
            return !!res;
        } catch (err) {
            message.error("服务器错误！");
            return false;
        }
    };

    render() {
        const {visible,onCancel,onSubmit} = this.props
        const {editItem,poster,isSet,width,height,referPos} = this.state
        return (
            <>
                <Modal width="690px"
                       cancelText="关闭"
                       onOk={()=>{
                           // if(url.substr(-3).toLowerCase() === "mp4"){
                           //     window.location.href = url
                           // }else{
                           //     createLiveTask({live_record_id:editItem.id}).then(res=>{
                           //            message.success('操作成功!')
                           // })
                           //     message.error('正在进行转码...')
                           // };
                           // createLiveTask({live_record_id:editItem.id}).then(res=>{
                           //                message.success('操作成功!')
                           // }
                           // setInterval(()=>{
                           //     onSubmit?.();
                           // },300)
                            if(isSet){ //需要进行判断
                                if(width<8 || width>200 || height<8 ||height>200){
                                    message.error("宽度和高度需要在8-200")
                                    return false;
                                }
                                if(poster===""){
                                    message.error("图片必传")
                                    return false;
                                }
                                if(referPos<1 || referPos>4){
                                    message.error("位置必选")
                                    return false;
                                }
                            }
                            createLiveTask({live_record_id:editItem.id,status:isSet? 1 : 0,width:width,height:height,img_file:poster,referPos:referPos}).then(res=>{
                                onSubmit?.();
                            })
                           onSubmit?.();
                           // setInterval(()=>{
                           //         onSubmit?.();
                           //     },300)
                       }

                       } okText={'点我转码~转码需要一段时间，请耐心等待~'}
                       title="转码设置"
                       onCancel={()=>{
                           onCancel?.();
                       }} visible={visible}>
                    <div style={{display :"flex", alignItems : "center"}} >
                    <div style={{width:"100px",marginRight:"0px"}}>
                        是否设置水印
                    </div>
                        <Air horizontal={10} />
                    <Switch checked={isSet} onChange={e =>{
                        this.setState({isSet:e})
                    }} />
                    </div>
                    <Air vertical={10} />
                    <div style={{display :"flex", alignItems : "center"}} >
                        <div style={{width:"100px",marginRight:"0px"}}>
                            上传水印图片
                        </div>
                        <Air horizontal={10} />
                        <div>
                            <ImageCropper
                                isProportion={true}
                                desc={""}
                                text={
                                    <Mask
                                        floatView={
                                            poster ? (
                                                <UploadImgTipStateless
                                                    title={""} descript={"上传的图片建议大小为200kb以内，最大高/宽度为200px，最小高/宽度为200px"}
                                                    theme={"dark"}
                                                />
                                            ) : undefined
                                        }
                                    >
                                        {poster ? (
                                            <FlexJustifyCenter
                                                style={{
                                                    // width: "320px",
                                                    // height: "180px",
                                                }}
                                            >
                                                <img
                                                    src={poster}
                                                    alt=""
                                                />
                                            </FlexJustifyCenter>
                                        ) : (
                                            <UploadImgTipStateless title={""} descript={"上传的图片建议大小为200kb以内，最大高/宽度为200px，最小高/宽度为200px"} />
                                        )}
                                    </Mask>
                                }
                                onUpdate={this.onCropedImage}
                            />
                        </div>
                    </div>
                    <Air vertical={10} />
                    <div style={{display :"flex"}} >
                        <div style={{width:"100px",marginRight:"0px"}}>
                            大小（宽*高）
                        </div>
                        <div style={{display :"flex"}}>
                            <Air horizontal={10} />
                            <div style={{width:"100px"}}   >
                                <Input addonAfter="px"
                                       onChange={event => {
                                           if(typeof event.target.value == "number"){
                                               if(event.target.value>8 && event.target.value<200){

                                               }else{
                                                   message.error("高度在8-200之间")
                                               }
                                           }


                                           this.setState({width:Number(event.target.value)})
                                           console.log(this.state.width)
                                       }}

                                       maxLength={3} defaultValue={width} />
                            </div>
                            <Air horizontal={10} />
                            *
                            <Air horizontal={10} />
                            <div style={{width:"100px"}}   >
                            <Input  onChange={event => {
                                if(typeof event.target.value === "number"){
                                    if(event.target.value>8 && event.target.value<200){
                                        this.setState({height:event.target.value})
                                    }else{
                                        message.error("高度在8-200之间")
                                    }
                                }
                                this.setState({height:Number(event.target.value)})
                                console.log(this.state.height)
                            }} addonAfter="px" maxLength={3} defaultValue={height} />
                            </div>
                        </div>
                    </div>
                    <Air vertical={10} />

                    <div style={{display :"flex", alignItems : "center"}} >
                        <div style={{width:"100px",marginRight:"0px"}}>
                            位置
                        </div>
                        <Air horizontal={10} />
                        <Radio.Group onChange={e=>{
                            this.setState({referPos:e.target.value})
                        }}>
                            右上 <Radio value={2}></Radio>
                            左上 <Radio value={1}></Radio>
                            右下 <Radio value={4}></Radio>
                            左下 <Radio value={3}></Radio>
                        </Radio.Group>
                    </div>
                    <Air vertical={10} />
                    <span style={{color:"red"}}>
                                          您只有一次转码机会，请谨慎操作，如果不确定水印效果，请不要勾选设置水印
                    </span>
                </Modal>
            </>
        )
    }
}
