import { toPost } from "../../../../../../../common/apis/http-utils";

export enum CHARGE_TYPE {
  free = '1', // 免费
  standard = '2', // 标准
  people = '3', // 人群
  time = '4', // 时间
  peopleAndTime = '5', //时间段和人群
}

// 会议收费管理
export const chargeManagement = toPost<any, any>('/merchant/meeting/chargeManagement');

export const seachUser = toPost<any, any>('/merchant/meeting/seachUser');

export const addPayUser = toPost<any, any>('/merchant/meeting/addPayUser');

export const delOrder = toPost<any, any>('/merchant/meeting/delOrder');

export const excelExportFee = toPost<any, any>('/merchant/meeting/excelExportFee');

export const setMeetingPay = toPost<any, any>('/merchant/meeting/setMeetingPay');

export const getMeetingPay = toPost<any, any>('/merchant/meeting/getMeetingPay');