/**
 * 垂直菜单栏
 * Create by liaokai on 2020-04-14 15:52:28
 */
import * as React from 'react';
import {Component, useState} from "react";
import {Menu} from "antd";
import {Display} from "../display/display";
import {common} from "../../common/common";

const {ItemGroup, Item} = Menu;

export type tMenuVerticalTree<T = any> = {
    title : string,
    items : {
        title : string | JSX.Element,
        routerName : string,
        plusField? : T,
        hidden? : boolean
    }[],
}[]

interface IProps{
    basePath : string,
    tree : tMenuVerticalTree,
    selectedKey? : string
}

interface IState{
    basePath : string,
}

export class MenuVertical extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {
        basePath : this.props.basePath,
    };

    render() {
        let {tree, selectedKey} = this.props;
        let {basePath} = this.state;
        return (
            <Menu
                mode={"inline"}
                selectedKeys={selectedKey ? [selectedKey] : undefined}
                onSelect={({key}) => {
                    window.location.hash = `${basePath}/${key}`;
                    common.toTop();
                }}
            >
                {
                    tree.map(value => {
                        return <ItemGroup
                            key={value.title}
                            title={
                                <div style={{color : "#000",fontSize : 21, fontWeight : 400, paddingBottom: 10 , borderBottom : "#eee 1px solid"}}>
                                    {value.title}
                                </div>
                            }
                        >
                            {
                                value.items.map(value1 => {
                                    if(value1.hidden){
                                        return <Item key={value1.routerName} style={{display : "none"}}>{value1.title}</Item>
                                    }else{
                                        return <Item key={value1.routerName}>{value1.title}</Item>
                                    }
                                })
                            }
                        </ItemGroup>
                    })
                }
            </Menu>
        );
    }
}
