/**
 * 新增&修改 预置表单字段
 * Create by liaokai on 2020-04-03 11:26:53
 */
import {toPost} from "../http-utils";

export const getMenu = toPost<
    {
        live_id : any,
    },
    {
        'title':string,
        'id':number,
        'default':true,
    }[]
    >("/merchant/live/getMenu")
