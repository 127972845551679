import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './index.css';
import App from './routes/App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

//哨兵初始化
Sentry.init({dsn: "https://fa7e038ac60841f6ad0449450ec1a190@sentry.rup-china.com/2"});

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
