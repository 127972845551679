/**
 * 邮件总览
 * Create by liaokai on 2020-07-03 13:15:14
 */
import * as React from 'react';
import {Component} from "react";
import {SurfaceRouter, tSurfaceRouteItem} from "../../../../../components/surface-router/surface-router";
import {RouteComponentProps} from "react-router";
import {EmailRecord} from "./record";
import {EmailMultiSendDetail} from "../../../meeting-list/meeting/menu/email-multi-send/multi-send-detail/email-multi-send-detail";
import {EmailTemplate} from "../../../meeting-list/meeting/menu/email-multi-send/email-template/email-template";
import {EmailSend} from "../../../meeting-list/meeting/menu/email-multi-send/multi-send/email-send";
import {BuyEmail} from "../../../meeting-list/meeting/menu/email-multi-send/buy-email/buy-email";

interface IProps extends RouteComponentProps<any>{

}

interface IState{

}

const routerData : tSurfaceRouteItem = {
    breadTitle : "发送记录",
    routeName : "",
    component : EmailRecord,
    children : [
        {
            breadTitle : "群发详情",
            routeName : "email-multi_send_detail/:module_id",
            component : EmailMultiSendDetail,
            children : []
        },
        {
            breadTitle : "添加模版",
            routeName : "add_email_template",
            component : EmailTemplate,
            children : []
        },
        {
            breadTitle : "编辑模版",
            routeName : "edit_email_template/:module_id",
            component : EmailTemplate,
            children : []
        },
        {
            breadTitle : "新建群发",
            routeName : "email_send/:module_id",
            component : EmailSend,
            children : []
        },
        {
            breadTitle : "新建群发",
            routeName : "email_send",
            component : EmailSend,
            children : []
        },
        {
            breadTitle : "邮件包购买",
            routeName : "buy_email",
            component : BuyEmail,
            children : []
        },
    ]
}

interface IProps extends RouteComponentProps<any>{

}

interface IState{
}

export class EmailRecordRouter extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={routerData}/>
        );
    }
}
