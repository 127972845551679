/**
 * 图标附加提示
 * Create by liaokai on 2020-04-02 14:03:00
 */
import * as React from 'react';
import {Component} from "react";
import {Popover} from "antd";
import {TooltipPlacement} from "antd/lib/tooltip";
import {InfoCircleOutlined} from "@ant-design/icons/lib";

interface IProps{
    icon? : JSX.Element,
    placement? : TooltipPlacement,
    children? : JSX.Element | string
}

interface IState{

}

export class Tip extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {

    };
    render() {
        let {icon, placement,children} = this.props;
        let {} = this.state;
        return (
            <Popover placement={placement ?? "right"} content={children ?? <div/>}>
                {icon ?? <InfoCircleOutlined/>}
            </Popover>
        );
    }
}
