/**
 * 互动问答 - 问答详情
 * Create by liaokai on 2020-06-16 13:53:03
 */
import {toPost} from "../../http-utils";

export interface IQuestionPublishShowItem {
    "id": number,
    "username": string,
    "credit": number,
    "answer_time": string,
    "answer": string
}

export const questionPublishShow = toPost<
    {
        id : string,
        page : number,
        paginate? : number
    },
    {
        "current_page": number,
        "total": number,
        "last_page": number,
        "data": IQuestionPublishShowItem[]
    }
>("/merchant/live/questionPublishShow");

