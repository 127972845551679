/**
 * 会前-信息编辑
 * Create by liaokai on 2020-03-12 16:06:32
 */
import * as React from 'react';
import {Component} from "react";
import {Switch, Route, Redirect, Link} from "react-router-dom";
import {Sort} from "./sort/sort";
import {Edit} from "./edit/edit";
import {RouteComponentProps} from "react-router";
import {Preview} from "./preview/preview";
import {SurfaceRouter, tSurfaceRouteItem} from "../../../../../../components/surface-router/surface-router";
import {Breadcrumb} from "antd";

interface IProps extends RouteComponentProps<any>{

}

interface IState{
}

const data : tSurfaceRouteItem = {
    breadTitle : "会议介绍",
    routeName : "sort",
    component : Sort,
    children : [
        {
            breadTitle : "新建模块",
            routeName : "new",
            component : Edit,
            children : []
        },
        {
            breadTitle : "编辑模块",
            routeName : "edit/:module_id",
            component : Edit,
            children : []
        },
        {
            breadTitle : "分享设置",
            routeName : "preview",
            component : Preview,
            children : []
        },
    ]
};

export class LecturerRouter extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {
    };
    constructor(props: IProps){
        super(props);
    }


    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={data}/>
        );
    }
}
