/**
 * 签到管理列表
 * Create by liaokai on 2020-04-27 15:30:18
 */
import {toPost} from "../http-utils";

export interface IMeetingHallInfoItem {
    "id": number,
    "meetid":number,
    "title":string,
    "create_time":string,
    "update_time":string,
    "user_num":number,
    "signIn_num":number
}

export const getMeetingHallInfo = toPost<
    {
        meetid : string
    },
    IMeetingHallInfoItem[]
>("/merchant/meeting_place/index");
