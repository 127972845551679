/**
 * 调研问卷添加
 * Create by liaokai on 2020-06-16 17:59:49
 */
import {toPost} from "../../http-utils";
import {IQuestionStoreQuestion} from "./questionStore";

export const liveSurveyQuestionnaireStore = toPost<
    {
        title : string,
        live_id : string,
        data : IQuestionStoreQuestion[]
    },
    {

    }
>("/merchant/live/liveSurveyQuestionnaireStore");

