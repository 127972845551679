/**
 * websocket绑定
 * Create by liaokai on 2020-05-29 18:58:53
 */
import {toPost} from "../../http-utils";

export const replyKeywordsStore = toPost<
    {
        live_id : string
        keywords : string
        reply_content : string
    },
    {

    }
>("/merchant/live/replyKeywordsStore");
