/**
 * 直播 - 直播标题
 * Create by liaokai on 2020-05-30 17:47:21
 */
import * as React from 'react';
import {Component} from "react";
import {ILiveShow, liveShow} from "../../../../../../common/apis/apis/live/liveShow";
import {liveStore} from "../../menu/live.store";

interface IProps{

}

interface IState{
    data? : ILiveShow
}

export class LiveRoomTitle extends Component<IProps,IState>{
    readonly state : IState = {

    };

    componentDidMount() {
        liveShow({
            id : liveStore.live_id
        }).then(res => {
            this.setState({data : res})
            document.title = res.title + " - 会佰分"
        })
    }

    render() {
        let {} = this.props;
        let {data} = this.state;
        return (
            <div>
                {data?.title}
            </div>
        );
    }
}
