import * as React from 'react';
import {RouteComponentProps} from "react-router";
import {Component} from "react";
import style from "../index/index.scss";
import {Affix} from "antd";
import {Redirect, Route, Switch} from "react-router-dom";
import {MeetingList} from "../index/meeting-list/meeting-list";
import {LiveList} from "../index/live-list/live-list";
import {Email} from "../index/email/email";
import {Sms} from "../index/sms/sms";
import {UserIndexView} from "../index/user/UserIndexView";
import {LoginCorner} from "../../components/LoginBox/LoginCorner";
import {Footer} from "../../components/footer/footer";
import {BannerTop} from "../../components/OtherBannerTop/BannerTop";
import {liveStore} from "../index/live-list/live/menu/live.store";
import {OtherLive} from "../index/live-list/live/other-live";
import {OtherLiveList} from "../index/live-list/other-live-list";
import {liveShow} from "../../common/apis/apis/live/liveShow";
interface IProps extends RouteComponentProps<{live_id : string}>{
}
interface IState{

}
export  class LiveCompere extends Component<IProps,IState>{
    readonly state:IState={

    }
    componentWillMount(){
        liveStore.setLiveId(this.props.match.params.live_id)
        liveShow({id:liveStore.live_id}).then((res)=>{
            liveStore.setLiveInfo(res)
        })
    }
    render() {
        let {match : {path}} = this.props;
        let {} = this.state;
        return (
            <div className={style.container}>
                <Affix offsetTop={0}>
                    <BannerTop {...this.props} />
                </Affix>
                <Switch>
                    <Redirect path={path} exact to={`${path}/other_live`} />
                    {/*<Route path={`${path}/meeting_list`} component={MeetingList} />*/}
                    <Route path={`${path}/other_live`} component={OtherLive} />
                    {/*<Route path={`${path}/email`} component={Email} />*/}
                    {/*<Route path={`${path}/sms`} component={Sms} />*/}
                    {/*<Route path={`${path}/user`} component={UserIndexView} />*/}
                </Switch>
                <LoginCorner/>
                <Footer/>
            </div>
        );
    }
}