import React, { useState, useEffect } from 'react';
import { Tabs, Button, Input, Form, message, Row, Col, Modal, DatePicker } from 'antd';
import { CHARGE_TYPE } from '../../constants';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { common } from '../../../../../../../../common/common';
import moment from 'moment';
import locale from "antd/lib/date-picker/locale/zh_CN";
import FormModal from '../modal'
import { merge } from 'lodash';

export const placeHolders = {
  money: '0.00',
  people: '例如：医生/医学生'
}

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

function TabItem(props: any) {
  const { type, chargeDetail, setCharge, ...rest } = props;

  // 标准表单
  const [standForm] = Form.useForm();

  // 人群表单
  const [peopleForm] = Form.useForm();

  // 按时间段标点
  const [timeForm] = Form.useForm();

  // 时间和人群表单
  const [timeAndPeopleForm] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [modalForm] = Form.useForm();

  const [visible1, setVisible1] = useState(false);
  const [modalForm1] = Form.useForm();

  const [visible2, setVisible2] = useState(false);
  const [modalForm2] = Form.useForm();

  useEffect(() => {
    if (!chargeDetail) {
      return;
    }
    console.log('chargeDetail', chargeDetail);
    if (chargeDetail.type === 1) {
      return;
    }
    // 标准收费
    if (chargeDetail.type === 2) {
      standForm.setFieldsValue({ fee: chargeDetail.type2.fee });
      return;
    }
    // 人群表单
    if (chargeDetail.type === 3) {
      peopleForm.setFieldsValue({ params: chargeDetail.type3.params.map((item: any) => { return { fee: item.fee, people_type: item.people } }) });
      return;
    }
    // 按时间段标点
    if (chargeDetail.type === 4) {
      const timesData = merge([], chargeDetail.type4.params);
      timesData.splice(0, 2);
      timeForm.setFieldsValue({
        start: [moment(), moment(chargeDetail.type4?.params[0]?.end_time * 1000)],
        fee1: chargeDetail.type4?.params[0]?.fee,
        end: [moment(chargeDetail.type4?.params[1]?.start_time * 1000), null],
        fee2: chargeDetail.type4?.params[1]?.fee,
        times: timesData.map((item: any) => {
          return {
            fee: item.fee,
            time: [moment(item.start_time * 1000), moment(item.end_time * 1000)]
          }
        })
      });
      return;
    }
    // 时间和人群表单
    if (chargeDetail.type === 5) {
      const timesData = merge([], chargeDetail.type5.params);
      timesData.splice(0, 2);
      timeAndPeopleForm.setFieldsValue({
        start: [moment(), moment(chargeDetail.type5?.params[0]?.end_time * 1000)],
        startValues: chargeDetail.type5?.params[0].people_params,
        end: [moment(chargeDetail.type4?.params[1]?.start_time * 1000), null],
        endValues: chargeDetail.type5?.params[1].people_params,
        times: timesData.map((item: any) => {
          return {
            people_params: item.people_params,
            time: [moment(item.start_time * 1000), moment(item.end_time * 1000)]
          }
        })
      });
      return;
    }
  }, [chargeDetail]);

  const renderFree = () => {
    return (
      <div></div>
    )
  }
  const renderStandard = () => {
    return (
      <Form form={standForm}>
        <Form.Item name='fee' label='收费金额' rules={[{ required: true, message: '请输入收费金额' }]}>
          <Input placeholder='0.00' suffix='元' style={{ width: 300 }} />
        </Form.Item>
      </Form>
    )
  }
  const renderPeople = () => {
    return (
      <Form form={peopleForm} initialValues={{ params: [{ fee: '', people_type: '' }] }}>
        <Form.List name='params'>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field: any, index: number) => {
                  return (
                    <Row gutter={24} key={index}>
                      <Col span={8}>
                        <Form.Item name={[field.name, 'people_type']} label='设置人群' rules={[{ required: true, message: '请设置人群' }]}>
                          <Input placeholder={placeHolders.people} maxLength={10} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name={[field.name, 'fee']} label='收费金额' rules={[{ required: true, message: '请输入收费金额' }]}>
                          <Input placeholder={placeHolders.money} suffix='元' />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <PlusCircleOutlined
                          onClick={() => {
                            if (fields.length > 4) {
                              message.error('最多设置5种收费人群！');
                              return;
                            }
                            add();
                          }}
                          style={{ marginTop: 6, fontSize: 16, color: common.color.primary }}
                        />
                        {index !== 0 && <MinusCircleOutlined onClick={() => remove(index)} style={{ marginTop: 6, marginLeft: 12, fontSize: 16, color: common.color.primary }} />}
                      </Col>
                    </Row>
                  )
                })}
              </>
            )
          }}
        </Form.List>
      </Form>
    )
  }
  const renderTime = () => {
    return (
      <Form form={timeForm}>
        <Row justify="start">
          <Col span={6}>
            <Form.Item name={'start'} rules={[{ required: true, message: '请设置时间' }]} style={{ marginBottom: 8 }}>
              <RangePicker disabled={[true, false]} allowEmpty={[true, false]} placeholder={['今天', '结束日期']} locale={locale} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'fee1'} label='收费金额' rules={[{ required: true, message: '请输入收费金额' }]} style={{ marginBottom: 8 }}>
              <Input placeholder={placeHolders.money} suffix='元' />
            </Form.Item>
          </Col>
          <Col span={4}>
            <PlusCircleOutlined
              onClick={() => {
                const prevFieldsValue = timeForm.getFieldsValue();
                let nextTimes = prevFieldsValue.times;
                nextTimes
                  ? nextTimes.push({
                    time: [],
                    fee: ''
                  })
                  : nextTimes = [{
                    time: [],
                    fee: ''
                  }]
                timeForm.setFieldsValue({ times: nextTimes })
              }}
              style={{ marginTop: 6, fontSize: 16, color: common.color.primary }}
            />
          </Col>
        </Row>
        <Form.List name='times'>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field: any, index: number) => {
                  return (
                    <Row key={index} justify="start">
                      <Col span={6}>
                        <Form.Item name={[field.name, 'time']} rules={[{ required: true, message: '请设置时间' }]}>
                          <RangePicker locale={locale} />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item name={[field.name, 'fee']} label='收费金额' rules={[{ required: true, message: '请输入收费金额' }]}>
                          <Input placeholder={placeHolders.money} suffix='元' />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <PlusCircleOutlined
                          onClick={add}
                          style={{ marginTop: 6, fontSize: 16, color: common.color.primary }}
                        />
                        <MinusCircleOutlined onClick={() => remove(index)} style={{ marginTop: 6, marginLeft: 12, fontSize: 16, color: common.color.primary }} />
                      </Col>
                    </Row>
                  )
                })}
              </>
            )
          }}
        </Form.List>
        <Row justify="start">
          <Col span={6}>
            <Form.Item name={'end'} rules={[{ required: true, message: '请设置时间' }]}>
              <RangePicker disabled={[false, true]} placeholder={['开始日期', '以后']} allowEmpty={[false, true]} locale={locale} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={'fee2'} label='收费金额' rules={[{ required: true, message: '请输入收费金额' }]}>
              <Input placeholder={placeHolders.money} suffix='元' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
  const renderPeopleAndTime = () => {

    const buttonSuffix = (
      <PlusCircleOutlined
        onClick={() => {
          const prevFieldsValue = timeAndPeopleForm.getFieldsValue();
          let nextTimes = prevFieldsValue.times;
          nextTimes
            ? nextTimes.push({
              time: [],
              fee: '',
              people_type: ''
            })
            : nextTimes = [{
              time: [],
              fee: '',
              people_type: ''
            }]
          timeAndPeopleForm.setFieldsValue({ times: nextTimes })
        }}
        style={{ marginTop: 6, fontSize: 16, marginLeft: 12, color: common.color.primary }}
      />
    )
    return (
      <Form.Provider
        onFormFinish={(name: any, { values, forms }: any) => {
          if (name === 'modalForm') {
            const { timeAndPeopleForm } = forms;
            timeAndPeopleForm.setFieldsValue({
              startValues: values.items
            });
            setVisible(false);
          }
          if (name === 'modalForm1') {
            const { timeAndPeopleForm } = forms;
            timeAndPeopleForm.setFieldsValue({
              endValues: values.items
            });
            setVisible1(false);
          }
          if (name === 'modalForm2') {
            const { timeAndPeopleForm } = forms;
            const { index } = values;
            const nextValues = timeAndPeopleForm.getFieldsValue();
            const { times } = nextValues;
            times[index].people_params = values.items;
            timeAndPeopleForm.setFieldsValue({
              times
            });
            setVisible2(false);
          }
        }}
      >
        <Form form={timeAndPeopleForm} name='timeAndPeopleForm'>
          <Form.Item name='startValues' style={{ margin: 0, height: 0, overflow: 'hidden' }}><div /></Form.Item>
          <Form.Item name='endValues' style={{ margin: 0, height: 0, overflow: 'hidden' }}><div /></Form.Item>
          <Row justify="start">
            <Col span={6}>
              <Form.Item name={'start'} rules={[{ required: true, message: '请设置时间' }]} style={{ marginBottom: 8 }}>
                <RangePicker disabled={[true, false]} allowEmpty={[true, false]} placeholder={['今天', '结束日期']} locale={locale} />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                style={{ marginBottom: 8 }}
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.startValues !== curValues.startValues
                }}
              >
                {({ getFieldValue }) => {
                  const startValues = getFieldValue('startValues');
                  if (startValues && startValues.length > 0) {
                    return (
                      <div style={{ flexDirection: 'column' }}>
                        <div style={{ flexDirection: 'row' }}>
                          <Button onClick={() => setVisible(true)}>修改人群及收费金额</Button>
                          {buttonSuffix}
                        </div>
                        <div>
                          {startValues.map((startValue: any) => <span style={{ marginRight: 5 }}>{`${startValue.people_type} ${startValue.fee}元`}</span>)}
                        </div>
                      </div>
                    )
                  }
                  return (
                    <div>
                      <Button onClick={() => setVisible(true)}>设置人群及收费金额</Button>
                      {buttonSuffix}
                    </div>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
          <Form.List name='times'>
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field: any, index: number) => {
                    return (
                      <Row justify="start">
                        <Col span={6}>
                          <Form.Item name={[field.name, 'time']} rules={[{ required: true, message: '请设置时间' }]} style={{ marginBottom: 8 }}>
                            <RangePicker locale={locale} />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          {/* <Form.Item name='people_params' style={{ margin: 0, height: 0, overflow: 'hidden' }}><div /></Form.Item> */}
                          <Form.Item shouldUpdate style={{ marginBottom: 8 }}>
                            {({ getFieldValue }) => {
                              const timesValues = getFieldValue('times');
                              const currentParams = timesValues[index];
                              const { people_params } = currentParams;
                              if (people_params && people_params.length > 0) {
                                return (
                                  <div style={{ flexDirection: 'column' }}>
                                    <div style={{ flexDirection: 'row' }}>
                                      <Button
                                        onClick={() => {
                                          modalForm2.setFieldsValue({ index, items: people_params });
                                          setVisible2(true)
                                        }}
                                      >
                                        修改人群及收费金额
                                      </Button>
                                      <PlusCircleOutlined
                                        onClick={add}
                                        style={{ marginTop: 6, fontSize: 16, marginLeft: 12, color: common.color.primary }}
                                      />
                                      <MinusCircleOutlined onClick={() => remove(index)} style={{ marginTop: 6, marginLeft: 12, fontSize: 16, color: common.color.primary }} />
                                    </div>
                                    <div>
                                      {people_params.map((value: any) => <span style={{ marginRight: 5 }}>{`${value.people_type} ${value.fee}元`}</span>)}
                                    </div>
                                  </div>
                                )
                              }
                              return (
                                <div>
                                  <Button
                                    onClick={() => {
                                      modalForm2.setFieldsValue({ index, items: [{ people_type: '', fee: '' }] });
                                      setVisible2(true)
                                    }}
                                  >
                                    设置人群及收费金额
                                  </Button>
                                  <PlusCircleOutlined
                                    onClick={add}
                                    style={{ marginTop: 6, fontSize: 16, marginLeft: 12, color: common.color.primary }}
                                  />
                                  <MinusCircleOutlined onClick={() => remove(index)} style={{ marginTop: 6, marginLeft: 12, fontSize: 16, color: common.color.primary }} />
                                </div>
                              )
                            }}
                          </Form.Item>
                        </Col>
                      </Row>
                    )
                  })}
                </>
              )
            }}
          </Form.List>

          <Row justify="start">
            <Col span={6}>
              <Form.Item name={'end'} rules={[{ required: true, message: '请设置时间' }]} style={{ marginBottom: 8 }}>
                <RangePicker disabled={[false, true]} placeholder={['开始日期', '以后']} allowEmpty={[false, true]} locale={locale} />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                style={{ marginBottom: 8 }}
                shouldUpdate={(prevValues, curValues) => {
                  return prevValues.endValues !== curValues.endValues
                }}
              >
                {({ getFieldValue }) => {
                  const endValues = getFieldValue('endValues');
                  if (endValues && endValues.length > 0) {
                    return (
                      <div style={{ flexDirection: 'column' }}>
                        <Button onClick={() => setVisible1(true)}>修改人群及收费金额</Button>
                        <div>
                          {endValues.map((value: any) => <span style={{ marginRight: 5 }}>{`${value.people_type} ${value.fee}元`}</span>)}
                        </div>
                      </div>
                    )
                  }
                  return (
                    <Button onClick={() => setVisible1(true)}>设置人群及收费金额</Button>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <FormModal
          form={modalForm}
          name={'modalForm'}
          onOk={modalForm.submit}
          visible={visible}
          onCancel={() => setVisible(false)}
        />

        <FormModal
          form={modalForm1}
          name={'modalForm1'}
          onOk={modalForm1.submit}
          visible={visible1}
          onCancel={() => setVisible1(false)}
        />

        <FormModal
          form={modalForm2}
          name={'modalForm2'}
          visible={visible2}
          onCancel={() => setVisible2(false)}
          onOk={modalForm2.submit}
        />
      </Form.Provider>

    )
  }

  const prepareSubmit = async () => {
    try {

      let payload: any = {};
      if (type === CHARGE_TYPE.standard) {
        payload = await standForm.validateFields();
      }
      if (type === CHARGE_TYPE.people) {
        const peopleParams = await peopleForm.validateFields();
        payload = peopleParams.params;
      }
      if (type === CHARGE_TYPE.time) {
        const timeParams = await timeForm.validateFields();
        payload = [
          {
            start_time: moment().unix(),
            end_time: timeParams.start[1].unix(),
            fee: timeParams.fee1
          },
          {
            start_time: timeParams.end[0].unix(),
            end_time: 3999999999,
            fee: timeParams.fee2
          }
        ];

        if (!!timeParams.times) {
          timeParams.times.forEach((item: any) => {
            payload.push({
              fee: item.fee,
              start_time: item.time[0].unix(),
              end_time: item.time[1].unix(),
            })
          })
        }
      }
      if (type === CHARGE_TYPE.peopleAndTime) {
        const peopleAndTimeParams = await timeAndPeopleForm.validateFields();
        payload = [
          {
            start_time: moment().unix(),
            end_time: peopleAndTimeParams.start[1].unix(),
            people_params: peopleAndTimeParams.startValues
          },
          {
            start_time: peopleAndTimeParams.end[0].unix(),
            end_time: 3999999999,
            people_params: peopleAndTimeParams.endValues
          },
        ];
        if (!!peopleAndTimeParams.times) {
          peopleAndTimeParams.times.forEach((item: any) => {
            payload.push({
              start_time: item.time[0].unix(),
              end_time: item.time[1].unix(),
              people_params: item.people_params
            })
          });
        }
        console.log('payload', payload);
      }
      const params = {
        payload,
        type
      }
      setCharge(params);
    } catch (error) {
      error.errorFields && message.error(error.errorFields[0]?.errors[0]);
    }
  }

  const ChargeButton = () => {
    const renderTip = (tipType: CHARGE_TYPE) => {
      switch (tipType) {
        case CHARGE_TYPE.free:
          return '* 不收取任何费用'
        case CHARGE_TYPE.standard:
          return '* 无论人群和时间收费金额都一样';
        case CHARGE_TYPE.people:
          return '* 根据不同人群设置不同金额，您最多可以对五种人群进行设置';
        case CHARGE_TYPE.time:
          return '* 根据不同时间段设置不同的收费金额';
        case CHARGE_TYPE.peopleAndTime:
          return '* 根据不同时间段、不同人群设置不同的收费金额';
        default:
          return ''
      }
    }

    return (
      <div>
        <p>{renderTip(type)}</p>
        <Button type='primary' style={{ width: 100, marginTop: 12 }} onClick={prepareSubmit}>
          保存
        </Button>
      </div>
    )
  }

  return (
    <TabPane {...rest} style={{ paddingTop: 10 }}>
      {type === CHARGE_TYPE.free
        ? renderFree()
        : type === CHARGE_TYPE.standard
          ? renderStandard()
          : type === CHARGE_TYPE.people
            ? renderPeople()
            : type === CHARGE_TYPE.time
              ? renderTime()
              : type === CHARGE_TYPE.peopleAndTime
                ? renderPeopleAndTime() : null}
      <ChargeButton />
    </TabPane>
  )
}

export default TabItem;