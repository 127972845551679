import React, { Component } from 'react'
import style from './NoticeBody.scss'
import {api} from 'common/app'
  
export class NoticeBody extends Component {
constructor(props) {
  super(props);
  this.state = {
      info:null,
      opened:false,
  };
  this.refreshProps = this.refreshProps.bind(this);
  this.OpenMessage = this.OpenMessage.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
}
refreshProps(props) {
  this.state.info = props.info?props.info:this.state.info;
  this.setState(this.state);
}
OpenMessage(){
    this.state.opened = !this.state.opened;
    this.setState(this.state);
    api.setNoticeStatus(this.state.info.id).then(res=>{
      if (res.code === 200) {
        this.state.info.status = 1;
        this.setState(this.state);
      }
    },err=>{
    })
}

render() {
  return (
    <div className={[style.NoticeBody,this.state.opened?style.OpenBody:'','childcenter childcolumn'].join(' ')}>
       <div className={style.NoticeHead} onClick={this.OpenMessage}>
            
            <div className={[style.NoticeTitle,this.state.opened?style.OpenTitle:'','childcenter childcontentstart'].join(' ')}>
              {this.state.info?(this.state.info.status===0?<div className={style.NoticeStatus}></div>:'·'):''} 
              {this.state.info?this.state.info.title:''}
            </div>
            <div className={[style.NoteceTime,'childcenter'].join(' ')}> {this.state.info?this.state.info.create_time:''} <div className={style.DropDownIcon}></div> </div>
       </div>
       { this.state.opened?<div className={style.NoticeContent} dangerouslySetInnerHTML={{__html:this.state.info?this.state.info.content:''}}>
       </div>:'' }
    </div>
   )
   }
}
export default NoticeBody