/**
 * 换绑邮箱验证码
 * Create by liaokai
 */
import {toPost} from "../http-utils";

export const getEmailVercode = toPost<
    {
        email : string,
    },
    {

    }
    >("/merchant/Merchant/getEmaiCode");
