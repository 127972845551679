/**
 * 问卷调查-路由
 * Create by liaokai on 2020-06-16 11:55:17
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {SurfaceRouter, tSurfaceRouteItem} from "../../../../../../components/surface-router/surface-router";
import {LiveQuestion} from "./question";
import {LiveQuestionEdit} from "./edit/live-question-edit";
import {LiveQuestionAnswerDetail} from "./answer_detail/answer_detail";
import {LiveQuestionCommitDetail} from "./commit-detail/commit-detail";

interface IProps extends RouteComponentProps{

}

interface IState{

}

const routerData : tSurfaceRouteItem = {
    breadTitle : "问卷调查",
    routeName : "",
    component : LiveQuestion,
    children : [
        {
            breadTitle : "新建问卷",
            routeName : "edit/new",
            component : LiveQuestionEdit,
            children : []
        },
        {
            breadTitle : "编辑问卷",
            routeName : "edit/:question_id",
            component : LiveQuestionEdit,
            children : []
        },
        {
            breadTitle : "问卷详情",
            routeName : "detail/:question_id",
            component : LiveQuestionEdit,
            children : []
        },
        {
            breadTitle : "回答详情",
            routeName : "answer/:question_id/:title",
            component : LiveQuestionAnswerDetail,
            children : [
                {
                    breadTitle : "提交情况",
                    routeName : "commit_detail/:user_id",
                    component : LiveQuestionCommitDetail,
                    children : []
                }
            ]
        },
    ]
}

export class LiveQuestionRouter extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={routerData}/>
        );
    }
}
