/**
 * 并发数据图表
 * Create by liaokai on 2020-07-19 17:24:23
 */
import {toPost} from "../../http-utils";

export interface IConcurrenceChartItem {
    "time": string,
    "num": number
}

export const concurrenceChart = toPost<
    {
        live_id : string
    },
    IConcurrenceChartItem[]
>("/merchant/live/concurrenceChart");

