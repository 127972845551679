/**
 * 群发详情
 * Create by liaokai on 2020-04-21 18:05:01
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {Button, Descriptions, Table} from "antd";
import { Texts } from '../../../../../../../components/text/Texts';
import {Air} from "../../../../../../../components/air/Air";
import {Tip} from "../../../../../../../components/tip/tip.stateless";
import {MultiSendDetailTable} from "./multi-send-detail-table/multi-send-detail-table";



interface IProps extends RouteComponentProps<{meeting_id : string, module_id : string}>{

}

interface IState{

}

export class EmailMultiSendDetail extends Component<IProps,IState>{
    readonly state : IState = {

    };

    render() {
        let {match : {params : {module_id}}} = this.props;
        let {} = this.state;
        return (
            <div>
                <MultiSendDetailTable id={parseInt(module_id)}/>
            </div>
        );
    }
}
