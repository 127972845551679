/**
 * 分享设置
 * Create by liaokai on 2020-06-10 16:57:10
 */
import * as React from 'react';
import {Component} from "react";
import {Input, message, Modal} from "antd";
import {IliveShareShow, liveShareShow} from "../../../../../../../common/apis/apis/live/liveShareShow";
import {liveStore} from "../../live.store";
import {Flex} from "../../../../../../../components/style/flex/flex";
import {TitleContentTable} from "../../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import styled from "styled-components";
import {Flex1} from "../../../../../../../components/style/flex/flex-1";
import {liveShareSet} from "../../../../../../../common/apis/apis/live/liveShareSet";
import {uploadFile} from "../../../../../../../common/apis/apis/upload-file";
import {Mask} from "../../edit/component/mask";
import {UploadImgTipStateless} from "../../edit/component/upload-img-tip-stateless";
import {FlexJustifyCenter} from "../../../../../../../components/style/flex/flex-justify-center";
import ImageCropper from "../../../../../meeting-list/new-meeting/components/ImageCropper/ImageCropper";
import {liveSetSubscribe} from "../../../../../../../common/apis/apis/live/liveSubscribe";
const {TextArea} = Input

interface IProps{
    visible? : boolean
    onSubmit? : () => void
    onCancel? : () => void
}

interface IState{
        live_id : any
}



export class Subscribe extends Component<IProps,IState>{
    readonly state : IState = {
        live_id : liveStore.live_id
    };

    componentDidMount() {
        // liveShareShow({
        //     live_id : liveStore.live_id
        // }).then(res => {
        //     this.setState({...res})
        // })
    }

    componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any) {
        if(nextProps.visible){
            this.componentDidMount();
        }
    }

    onCancel = ()=>{
        this.props.onCancel?.()
    }
    onSubmit = ()=>{
        this.props.onSubmit?.()
    }

    render() {
        let {visible} = this.props;
        let {live_id} = this.state;
        return (
            <Modal
                title={"设置预告"}
                visible={visible}
                onCancel={()=>{
                    this.onCancel()
                }}
                okText={"确认"}
                cancelText={"返回"}
                onOk={()=>{
                    liveSetSubscribe({live_id:live_id}).then(res => {
                        message.success("设置成功")
                        this.onSubmit?.();
                    }).catch(e => {
                        message.error("保存失败")
                    })
                }}
                width={700}
            >
                <Flex>

                    <div>
                        修改后将无法恢复，确认将现在状态改为预告?
                    </div>
                </Flex>
            </Modal>
        );
    }
}


