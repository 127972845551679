/**
 * 获取提现记录列表
 * Create by liaokai on 2020-05-15 18:12:50
 */
import {toPost} from "../http-utils";

export interface ItixianRecordItem {
    "id": number,
    "mid":number,
    "company":string,
    "account_name":string,
    "bank":string,
    "account_number":string,
    "contacts":string,
    "phone":string,
    "amount":string,
    "status":string,
    "memo":string,
    "file_name":string,
    "mongodb_id":string,
    "create_time":number,
    "update_time":number,
    "create_ip":string
}

export const tixianRecord = toPost<
    {
        page : number
        pageNum? : number
    },
    {
        "data": ItixianRecordItem[],
        "count_page": number
    }
>("/merchant/merchant/tixianRecord");

