/**
 * 某次模板的邮件发送记录
 * Create by liaokai on 2020-04-24 10:02:37
 */
import {toPost} from "../../http-utils";

export interface ISendSmsUsersListItem {
    "id": number,
    "status": number,//1 发送中，2 已发送 3 发送失败 4 定时发送 未发生 5 定时发送已发送 6 定时发送 发送失败
    "username": string,
    // "phone": string,
    // "remart": string,//成功或者失败备注
    "email": string,//成功或者失败备注
    "remark": string,//成功或者失败备注
    "sex": string,
    "send_time": string //为null表示还没有发送，正常时间格式为2020-12-1 12:0:0
}

export const sendEmailUsersList = toPost<
    {
        id : number,

    },
    ISendSmsUsersListItem[]
    >("/merchant/Email/sendEmailUsersList");

