/**
 * 签到管理-网格单元
 * Create by liaokai on 2020-04-27 14:06:15
 */
import * as React from 'react';
import {Component} from "react";
import {Button, Card, Divider, message, Popconfirm} from "antd";
import { ScanOutlined} from "@ant-design/icons/lib";
import {Air} from "../../../../../../../../components/air/Air";

interface IProps{
    title? : string,
    totalNum? : string,
    signInNum? : string,
    onClickSignInScan? : ()=> void,
    onClickSetVenueMembers? : ()=>void,
    onConfirmDelete? : ()=> void,
    onSeeDetail? : ()=> void
}

interface IState{

}

export class SigninViewGridItemStateless extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {title, totalNum, signInNum, onClickSignInScan, onClickSetVenueMembers, onConfirmDelete, onSeeDetail} = this.props;
        let {} = this.state;
        return (
            <Card title={title ?? "-"} hoverable={true} style={{cursor : "default"}}>
                <div style={{display: "flex", alignItems : "center"}}>
                    <div style={{flex : 1}}>
                        <div style={{whiteSpace: "nowrap", fontSize : 11}}>
                            总人数： {totalNum ?? "-"} 人
                        </div>
                        <div style={{whiteSpace: "nowrap", fontSize : 11}}>
                            已签到： {signInNum ?? "-"} 人
                        </div>
                    </div>
                    <Button type={"link"} style={{paddingRight: 0}} onClick={()=>{onClickSignInScan?.()}}>
                        签到<ScanOutlined />
                    </Button>
                </div>
                <Air vertical={8}/>
                <div style={{display : "flex"}}>
                    <Button type={"primary"} onClick={()=>{onClickSetVenueMembers?.()}}>
                        设置会场人员
                    </Button>
                </div>
                <Divider style={{margin: "15px 0px 8px"}}/>
                <div style={{display : "flex", justifyContent: "space-between"}}>
                    <Popconfirm placement="bottomLeft" title={"确认删除这个会场吗？"} onConfirm={async ()=>{
                        onConfirmDelete?.()
                    }} okText="删除" cancelText="取消">
                        <Button type={"link"} danger={true} style={{paddingLeft : 0}}>
                            删除
                        </Button>
                    </Popconfirm>
                    <Button type={"link"} style={{paddingRight: 0}} onClick={()=>{onSeeDetail?.()}}>
                        查看详情 >
                    </Button>
                </div>
            </Card>
        );
    }
}
