/**
 * 会议-签到管理-详情
 * Create by liaokai on 2020-04-15 18:22:32
 */
import * as React from 'react';
import {Component} from "react";
import {api} from "../../../../../../../common/app";
import {message} from "antd";
import searchicon from "../../../../../../../assets/searchicon.png";
import PageBurster from "../../../../../../../components/PageBurster";
import style from "./detail.scss"
import ReactTable from "react-table";

const zh_key ={
    username:'姓名',
    mobile:'手机号',
    type:'签到方式',
    sign_time:'签到时间',
    status:'签到状态'
}

export class SignInDetail extends Component{
    constructor(props){
        super(props);
        this.state={
            id:null,
            listpage:1,
            totalpage:1,
            searchValue:'',
            _searchValue:'',

            datacolumn:[],
            data:[],
            exporturl:null,
        };
        this.getHallDetail = this.getHallDetail.bind(this);
        this.HandleInputChange = this.HandleInputChange.bind(this);
        this.onPageBusterChange = this.onPageBusterChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.getExportUrl = this.getExportUrl.bind(this);
    }
    componentDidMount(){
        this.refreshProps(this.props);
        this.InitTableHead();
        this.getExportUrl();
    }
    componentWillReceiveProps(nextprops){
        this.refreshProps(nextprops);
    }
    refreshProps(props){
        let id = props.match.params.sign_in_id
        if (this.state.id != id) {
            this.state.id = id;
            this.setState(this.state);
            this.getHallDetail(id);
        }
    }
    getHallDetail(id){
        api.getHallNameList(id,this.state.listpage,12,this.state.searchValue).then(res=>{
            console.log(res);
            if (res.code=== 200) {
                this.state.data = res.result.data;
                this.state.totalpage = res.result.count_page;
                this.setState(this.state);
            }
        },err=>{
            console.log(err);
        })

    }
    HandleInputChange(type,e){
        console.log(type,e.target.value);

        this.state[type] = e.target.value;
        this.setState(this.state);
    }
    getExportUrl(){
        api.getExportHallNameListUrl(this.state.id).then(res=>{
            console.log(res);
            if (res.code === 200) {
                this.state.exporturl = res.result;
            }else{
                this.state.exporturl = null;
            }
            this.setState(this.state);
        },err=>{
            console.log(err);

        })
    }
    DeleteSignName(index){
        let id = this.state.data[index].id;
        api.removeHallNameList(id).then(res=>{
            console.log(res);
            if (res.code === 200) {
                this.getHallDetail(this.state.id);
            }else{
                message.error(res.message);
            }
        },err=>{
            console.log(err);

        })
    }
    InitTableHead(){
        let datacolumn = [];
        for (const key in zh_key) {
            if (zh_key.hasOwnProperty(key)) {
                const element = zh_key[key];
                datacolumn.push({
                    Header: <div className={[style.HeadCotnent,'childcenter childcontentstart'].join(' ')}>{element}</div>,
                    accessor: key,
                    Cell:props=>{
                        return <div key={props.index+'guestdata'+ props.index} className={[style.ListChild,'childcenter childcontentstart'].join(' ')}>{props.value}</div>
                    },
                    resizable:false,
                    width:key ==='paytime'?200:undefined,
                    headerClassName:style.ListHead,
                })
            }
        }
        datacolumn.push({
            Header: <div className={[style.HeadCotnent,'childcenter childcontentstart'].join(' ')}>操作</div>,
            Cell:props=>{
                return <div key={props.index+'guestdata'+ props.index} className={[style.ListChild,'childcenter childcontentstart'].join(' ')}>
                    <span className={style.EditButton} onClick={this.DeleteSignName.bind(this,props.index)}>删除</span>
                </div>
            },
            resizable:false,
            width:100,
            headerClassName:style.ListHead,
        })
        this.state.datacolumn = datacolumn;
    }
    onPageBusterChange(index){
        this.state.listpage = index;
        this.getHallDetail(this.state.id);
        this.setState(this.state);
    }
    onSearch(){
        this.state.listpage = 1;
        if(this.state.searchValue){
            this.state.searchValue = ''
        }else{
            this.state.searchValue = this.state._searchValue;
        }
        this.getHallDetail(this.state.id);
        this.setState(this.state)
    }
    render(){
        return (
            <div>
                <div className={style.HallContent}>
                    <div className={[style.HandleGroup,'childcenter'].join(' ')}>
                        <div className={[style.SearchInputBox,'childcenter'].join(' ')}>
                            <div className={[style.SearchInput,'childcenter'].join(' ')}>
                                <div className={[style.SearchIcon,'childcenter'].join(' ')}>
                                    <img src={searchicon} alt=""/>
                                </div>
                                <div className={style.Inputs}>
                                    <input type="text" value={this.state._searchValue} onChange={this.HandleInputChange.bind(this,'_searchValue')}/>
                                </div>
                            </div>
                            <div className={[style.SearchButton,'childcenter'].join(' ')} onClick={this.onSearch}>
                                {this.state.searchValue?'取消检索':'快速检索'}
                            </div>
                        </div>
                        <div className={[style.ButtonGroup,'childcenter childcontentend'].join(' ')}>
                            {this.state.exporturl?<div className={[style.Button,'childcenter'].join(' ')} onClick={(()=>{window.open(this.state.exporturl)}).bind(this)}>导出表格</div>:''}
                        </div>
                    </div>
                    <div className={style.NameListBody}>
                        <ReactTable
                            data={this.state.data}
                            columns={this.state.datacolumn}
                            showPagination={false}
                            defaultPageSize = {100000}
                            minRows={12}
                            NoDataComponent={() => {return <div className={[style.NoDataTips,'childcenter'].join(' ')}>该会场暂无名单</div>}}
                        />
                    </div>
                    <div className={style.BusterComponent}>
                        <PageBurster nowpage={this.state.listpage} max={this.state.totalpage} onPageChange={this.onPageBusterChange}/>
                    </div>
                </div>
            </div>

        )
    }
}
