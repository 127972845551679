/**
 * 添加/编辑 短信模板
 * Create by liaokai on 2020-04-21 18:07:47
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {Texts} from "../../../../../../../components/text/Texts";
import {Air} from "../../../../../../../components/air/Air";
import {Button, Card, DatePicker, Descriptions, Empty, Input, message, Modal, Select} from "antd";
import {IphoneSimulator} from "../../../../../../../components/iphone-simulator/iphone-simulator";
import style from "../multi-send/email-send.scss"
import {Tip} from "../../../../../../../components/tip/tip.stateless";
import {ITagItem} from "../../sms-multi-send/sms-template/editor/sms-template-editor";
import {apis} from "../../../../../../../common/apis/apis";
import {IMeetingTagItem} from "../../../../../../../common/apis/apis/sms/smsField";
import {SmsTemplateEditor} from "../../sms-multi-send/sms-template/editor/sms-template-editor";
import {emailTemplateShow} from "../../../../../../../common/apis/apis/email/emailTemplateShow";
import {RichTextBoxV2} from "../../../../../../../components/RichTextBox/RichTextBoxV2";
import BraftEditor from "braft-editor";



interface IProps extends RouteComponentProps<{meeting_id : string, module_id : string}>{

}

interface IState{
    // templateContent : ITagItem[]
    templateTitle : string,
    synopsis : string,
    // tags : IMeetingTagItem[]
}

export class EmailTemplate extends Component<IProps,IState>{
    readonly state : IState = {
        // templateContent : [],
        templateTitle: "",
        synopsis: "",
        // tags : []
    };

    saveEmailTemplate(){
        let {templateTitle} = this.state;
        apis.emailTemplateStore({
            title : templateTitle,
            text : this.state.synopsis   //从对象转为json字符串
        }).then(res => {
            message.success("保存成功");
            window.location.hash="#/index/email/overview"

        }).catch(e => {
            message.error("保存失败");
        })
    }

    updateSmsTemplate(){
        let {templateTitle} = this.state;
        apis.emailTemplateUpdate({
            title : templateTitle,
            text : this.state.synopsis,
            id : this.getModuleId()
        }).then(res => {
            message.success("保存成功");
            window.location.hash="#/index/email/overview"
        }).catch(e => {
            message.error("保存失败");
        })
    }

    getModuleId(): number{
        return parseInt(this.props?.match?.params?.module_id);
    }

    componentDidMount(): void {
        let moduleId : number = this.getModuleId()
        //获取tags
        // apis.smsField({meetid : parseInt(this.props.match.params.meeting_id)}).then(res => {
        //     this.setState({tags : res})
        // })
        //如果编辑模式，获取默认模板信息
        if(!!moduleId){
            apis.emailTemplateShow({id : moduleId}).then(res => {
                this.setState({
                    templateTitle : res.title,
                    synopsis : res.text
                })
                console.log(res.text)
            })
        }
    }

    render() {
        let {history} = this.props;
        let { templateTitle, synopsis} = this.state;
        return (
            <div className={style.container}>
                <div className={style.left}>
                    <div className={style.item}>
                        <Texts.ItemTitle>
                            模版名称
                        </Texts.ItemTitle>
                        <Air horizontal={8}/>
                        <Input style={{flex : 1}} value={templateTitle} onChange={event => this.setState({templateTitle : event.target.value})}/>
                    </div>
                    <Air vertical={5}/>
                    <div className={style.templateContent}>
                        <Card type="inner" title="编辑模版内容">
                            {/*<SmsTemplateEditor tags={tags} content={templateContent} onChange={value => {*/}
                            {/*    this.setState({templateContent : value})*/}
                            {/*}}/> 这是之前的模板，不应该用*/}
                            <RichTextBoxV2
                                value={BraftEditor.createEditorState(synopsis)}
                                onChange={
                                    (richContent ) =>
                                        this.state.synopsis = richContent.toHTML()
                                }
                            />
                            {/*<Air vertical={10}/>*/}
                            {/*<div className={style.editText}>*/}
                            {/*    短信费用*/}
                            {/*    <Air horizontal={3}/>*/}
                            {/*    <Tip placement={"right"}>*/}
                            {/*        <div>*/}
                            {/*            <div>*/}
                            {/*                简短信（短信内容为0~70字时），费用 0.10 元*/}
                            {/*            </div>*/}
                            {/*            <div>*/}
                            {/*                长短信（短信内容大于70字时），费用 0.20 元*/}
                            {/*            </div>*/}
                            {/*            <div>*/}
                            {/*                系统将根据动态字段的长度，为每条短信动态计费*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </Tip>*/}
                            {/*</div>*/}
                        </Card>
                    </div>
                    <Air vertical={8}/>
                    <div className={style.sendBtns}>
                        <Button onClick={()=>{
                            history.goBack();
                        }}>返回</Button>
                        <Air horizontal={8}/>
                        <Button type={"primary"} onClick={()=>{
                            if(this.getModuleId()){
                                console.log(this.getModuleId())

                                this.updateSmsTemplate();
                            }else{
                                console.log(this.getModuleId())
                                //新建时保存，并返回到上一页
                                this.saveEmailTemplate();
                                history.goBack();
                            }
                        }}>保存</Button>
                    </div>
                </div>
                {/*<div className={style.right}>*/}
                {/*    <IphoneSimulator width={280}>*/}
                {/*        <div className={style.smsPreview} style={{padding : "20px 10px"}}>*/}
                {/*            <div style={{background : "#e8e8e8", borderRadius: 10, padding: 10}}>*/}
                {/*                {*/}
                {/*                    !!templateContent?.length ?*/}
                {/*                    templateContent.map(value => {*/}
                {/*                        return value.content*/}
                {/*                    })*/}
                {/*                    :*/}
                {/*                    <Empty description={"暂无内容"}/>*/}
                {/*                }*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </IphoneSimulator>*/}
                {/*</div>*/}
            </div>
        );
    }
}
