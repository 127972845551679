/**
 * 广告 - 路由
 * Create by gaohan on 2020-08-20
 */
import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import {
    SurfaceRouter,
    tSurfaceRouteItem,
} from "../../../../../../components/surface-router/surface-router";
import { Advertisement } from "./advertisement";
import { AdvertisementAdd } from "./advertisement-add/advertisementAdd";

interface IProps extends RouteComponentProps {}

interface IState {}

const routerData: tSurfaceRouteItem = {
    breadTitle: "广告位",
    routeName: "",
    component: Advertisement,
    children: [
        {
            breadTitle: "新增",
            routeName: "edit/new",
            component: AdvertisementAdd,
            children: [],
        },
        {
            breadTitle: "修改",
            routeName: "edit/edit/:id",
            component: AdvertisementAdd,
            children: [],
        },
    ],
};

export class AdvertisementRouter extends Component<IProps, IState> {
    readonly state: IState = {};
    render() {
        let {} = this.props;
        let {} = this.state;
        return <SurfaceRouter {...this.props} entryRoute={routerData} />;
    }
}
