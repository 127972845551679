/**
 * 观看限制 - 路由
 * Create by liaokai on 2020-05-23 17:58:36
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {SurfaceRouter, tSurfaceRouteItem} from "../../../../../../components/surface-router/surface-router";
import {ReplyKeywordsListView} from "./component/reply-keywords-list-view";

interface IProps extends RouteComponentProps{

}

interface IState{

}

const routerData : tSurfaceRouteItem = {
    breadTitle : "机器人回复",
    routeName : "",
    component : ReplyKeywordsListView,
    children : []
}

export class ReplyKeywordsRouter extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={routerData}/>
        );
    }
}
