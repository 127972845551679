/**
 * 404
 * Create by liaokai on 2020-05-08 18:59:21
 */
import * as React from 'react';
import {Component} from "react";
import { Result, Button } from 'antd';

interface IProps{

}

interface IState{
    countdown : number
}

export class Page404 extends Component<IProps,IState>{
    readonly state : IState = {
        countdown : 8
    };

    timer : any

    componentDidMount() {
        this.timer = setInterval(()=>{
            this.setState({countdown : this.state.countdown - 1},()=>{
                if(this.state.countdown <= 0){
                    clearInterval(this.timer);
                    window.location.hash = "/"
                }
            })
        },1000)
    }

    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <Result
                status="404"
                title="404"
                subTitle="页面未找到"
                extra={<Button type="primary" onClick={()=>{window.location.hash = "/"}}>回到首页({this.state.countdown}秒)</Button>}
            />
        );
    }
}
