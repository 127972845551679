/**
 * 课件下载 - 列表
 * Create by liaokai on 2020-05-25 17:45:36
 */
import * as React from 'react';
import {Component} from "react";
import {FlexJustifyEnd} from "../../../../../../../components/style/flex/flex-justify-end";
import {Flex} from "../../../../../../../components/style/flex/flex";
import {Button, Input, message, Modal, Table, Switch, Spin} from "antd";
import {Air} from "../../../../../../../components/air/Air";
import {IChargeManagement} from "../../../../../../../common/apis/apis/chargeManagement";
import {LiveDocDownloadEdit} from "./edit";
import {LiveDocDownloadDetail} from "./download-detail";
import {coursewareIndex, ICoursewareIndexItem} from "../../../../../../../common/apis/apis/live/coursewareIndex";
import {coursewareEnable} from "../../../../../../../common/apis/apis/live/coursewareEnable";
import {RouteComponentProps} from "react-router";
import {common} from "../../../../../../../common/common";
import {Mask} from "../../edit/component/mask";
import {UploadImgTipStateless} from "../../edit/component/upload-img-tip-stateless";
import {FlexJustifyCenter} from "../../../../../../../components/style/flex/flex-justify-center";
import ImageCropper from "../../../../../meeting-list/new-meeting/components/ImageCropper/ImageCropper";
import {api} from "../../../../../../../common/app";
import {uploadFile} from "../../../../../../../common/apis/apis/upload-file";
import {liveStore} from "../../live.store";
import {LoadingSingleFile} from "../../../../../../../components/loading-single-file";
import {FlexJustifyBetween} from "../../../../../../../components/style/flex/flex-justify-between";
import {coursewareShow} from "../../../../../../../common/apis/apis/live/coursewareShow";
import {enableShowSet} from "../../../../../../../common/apis/apis/live/enableShowSet";
import {enableShowInfo} from "../../../../../../../common/apis/apis/live/enableShowInfo";
import {EmailTemplateDestroy} from "../../../../../email/menu/overview/email-template-destroy";
import {DownloadPublish} from "./download-publish";

const {Search} = Input

interface IProps extends RouteComponentProps<{live_id? : string}>{

}

interface IState{
    columns : any[]
    dataSource : ICoursewareIndexItem[],
    currentItem : ICoursewareIndexItem|undefined,
    currentPage : number,
    total : number,
    pageSize: number,
    loading : boolean,
    showEditDocModal : boolean  //显示编辑/新建课件弹窗
    showPublish : boolean  //显示取消发布按钮
    showDocDownloadDetailModal : boolean    //显示下载详情弹窗
    edit_id: string,
    isEnableDownload: boolean,
    search:string
}

export class LiveDocDownloadList extends Component<IProps,IState>{
    readonly state : IState = {
        columns : [
            {
                title: '课件名称',
                dataIndex: 'title',
                key: 'title',
                render: (text : boolean) => text,
            },
            // {
            //     title: '所需积分',
            //     dataIndex: 'credit',
            //     key: 'credit',
            //     render: (text : boolean) => text,
            // },
            {
                title: '更新时间',
                dataIndex: 'update_time',
                key: 'update_time',
                render: (text: string) => text,
            },
            {
                title: '操作',
                dataIndex: 'id',
                key: 'id',
                render: (text: string, record : ICoursewareIndexItem, index : number) => <div>
                    <Button style={{paddingLeft: 0}} type={"link"} onClick={()=>{
                        this.setState({showEditDocModal : true, edit_id : text})
                        console.log(record)
                    }}>编辑</Button>
                    <Button style={{paddingLeft: 0}} type={"link"} onClick={()=>{
                        this.setState({showPublish : true, currentItem : record})
                    }}>{record.status === 1 ? "取消发布" : "发布"}</Button>
                    {/*<Button style={{paddingLeft: 0}} type={"link"} onClick={()=>{*/}
                    {/*    this.setState({showDocDownloadDetailModal : true})*/}
                    {/*}}>下载详情</Button>*/}
                </div>,
            }
        ],
        dataSource : [],
        currentItem : undefined,
        pageSize : common.defaultPaginate,
        total : 0,
        currentPage : 1,
        showPublish : false,
        loading : false,
        showEditDocModal : false,
        showDocDownloadDetailModal : false,
        edit_id: "",
        isEnableDownload: false,
        search:""
    };

    reqTableData(page : number = 1, pageSize : number = this.state.pageSize){
        this.setState({loading : true})
        coursewareIndex({
            live_id : this.props.match.params.live_id ?? "",
            page : page,
            search : this.state.search,
            paginate : pageSize
        }).then(res => {
            this.setState({
                dataSource : res.data,
                currentPage : page,
                total : res.total,
            })
        }).finally(()=>{
            this.setState({loading : false})
        })
    }

    componentDidMount(): void {
        this.reqTableData(1);
        this.setState({ loading: true });
        //todo 有空了把下面的换成装饰器 enableShowInfo
        enableShowInfo({
            live_id: liveStore.live_id,
            type: 5
        }).then(res => {
            const { is_show } = res;
            this.setState({
                isEnableDownload: is_show
            });
        }).finally(() => {
            this.setState({ loading: false })
        });
    }

    render() {
        let {} = this.props;
        let {columns, dataSource,showPublish,currentItem, edit_id, pageSize, total, currentPage, loading, showEditDocModal, showDocDownloadDetailModal, isEnableDownload } = this.state;
        return (
            <>
            <div>
                <FlexJustifyBetween>
                    <Flex>
                        <Flex style={{alignItems: 'center'}}>
                            <Switch
                                checked={isEnableDownload}
                                onChange={(value) => {
                                    this.setState({
                                        isEnableDownload: value
                                    });
                                    this.setState({loading : true})
                                    enableShowSet({
                                        live_id: liveStore.live_id,
                                        type: 5
                                    }).then(res => {
                                        if (!value) {
                                            message.success('已关闭');
                                        } else {
                                            message.success('已开启');
                                        }
                                    }).finally(()=>{
                                        this.setState({loading : false})
                                    })
                                }}
                            />
                            <Air horizontal={5}/>
                            <span>启用课件下载</span>
                        </Flex>
                        <Flex>
                            <Air horizontal={15}/>
                            <Search
                                placeholder={"请输入搜索内容"} enterButton disabled={!isEnableDownload}
                                onChange={value=>{
                                    this.setState({search : value.target.value})

                                }}
                            onSearch={value => {
                                this.reqTableData(1)
                            }}
                            >

                            </Search>
                        </Flex>
                    </Flex>
                    <Flex>
                        {/*<Button disabled={!isEnableDownload}>导出</Button>*/}
                        <Air horizontal={5}/>
                        <Button type={"primary"} onClick={()=>{
                            this.setState({showEditDocModal : true, edit_id : ""})
                        }} disabled={!isEnableDownload}>添加课件</Button>
                    </Flex>
                </FlexJustifyBetween>
                <Air vertical={5}/>
                <Spin indicator={<div />} tip="尚未开启课件下载" spinning={!isEnableDownload}>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={dataSource}
                        // emptyText={"暂无内容"}
                        pagination={{
                            defaultCurrent : 1,
                            current : currentPage,
                            pageSize : pageSize,
                            total : total,
                            onChange : page => {
                                this.reqTableData(page);
                                this.setState({currentPage : page})
                            }
                        }}
                    />
                </Spin>

                {
                    showEditDocModal ?
                        <LiveDocDownloadEdit docId={edit_id} onSubmit={()=>{
                            this.reqTableData(currentPage)
                        }} show={showEditDocModal} onCancel={() => {
                            this.setState({showEditDocModal: false})
                        }}/>
                        :
                        undefined
                }
                <LiveDocDownloadDetail show={showDocDownloadDetailModal} onCancel={()=>{
                    this.setState({showDocDownloadDetailModal : false})
                }}/>
            </div>

                <DownloadPublish
                    visible={showPublish}
                    item={currentItem}
                    onSubmit={(msg:string)=>{
                        // this.setState({showDelModal:false})
                        message.success(msg)
                        this.reqTableData(currentPage)
                    }}
                    onCancel={()=>{
                        this.setState({showPublish:false})
                        this.reqTableData(currentPage)
                    }}
                >
                </DownloadPublish>
            </>
        );
    }
}
