/**
 * websocket绑定
 * Create by liaokai on 2020-05-29 18:58:53
 */
import {toPost} from "../../http-utils";

export const bindClient = toPost<
    {
        live_id : string
        client_id : string
    },
    {

    }
>("/merchant/live/bind");
