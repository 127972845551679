/**
 * 充值
 * Create by liaokai on 2020-04-30 12:09:36
 */
import * as React from 'react';
import {Component} from "react";
import {Badge, Button, Card, Input, InputNumber, message, Modal, Statistic} from "antd";
import {
    AlipayCircleFilled,
    AlipaySquareFilled, CheckOutlined,
    EyeInvisibleOutlined,
    EyeOutlined, MoneyCollectFilled,
    WechatFilled
} from "@ant-design/icons/lib";
import {PrepaidTable} from "../../prepaid-record/index/prepaid-table/prepaid-table";
import {Air} from "../../../../../components/air/Air";
import {Radio} from "../../../../../components/common/radio";
import {Badge1} from "./badge-1";
import {common} from "../../../../../common/common";
import {PayMethodItem} from "./pay-method-item";
import eyeClose from "../../../../../assets/eye-close.png"
import eyeOpen from "../../../../../assets/eye-open.png"
import style from "./prepaid-money.scss"
import {PriceChoose} from "./price-choose";
import {Link} from "react-router-dom";
import {storeMenu} from "../../../../../common/menu.store";
import { Texts } from '../../../../../components/text/Texts';
import {PayQrcode} from "./pay-qrcode";
import {apis} from "../../../../../common/apis/apis";
import {transferOperatingAcc} from "../../../../../common/apis/apis/transferOperatingAcc";
import {RouteComponentProps} from "react-router";
import {PayMethod, tBuyMethod} from "./pay-method";
import {OrderPayAndCashier} from "../../../meeting-list/meeting/menu/sms-multi-send/buy-sms/order-pay/order-pay-and-cashier";
import {IOperatingAccountBalanceResult} from "../../../../../common/apis/apis/operatingAccountBalance";

interface IProps extends RouteComponentProps{

}

interface IState{
    payMethod : string, //支付方式
    payPrice : number,  //充值金额
    moneyPrivate : boolean,     //是否隐藏账户余额
    transferAccModalConfirm : boolean,  //是否展示转入运营账户确认框
    money : number | undefined,     //当前余额
    transferMoney: any,   //转入到运营账户金额
    applyOrder : boolean,   //正在生成订单
    showOrderPay : boolean, //展示订单支付框
    orderData : IOperatingAccountBalanceResult | undefined
}

const payMethods = ["wechat","alipay",/*"balance"*/]    //所有支付方式
const defaultMethodIndex = 0    //默认支付方式下标


export class PrepaidMoney extends Component<IProps,IState>{
    readonly state : IState = {
        payMethod : payMethods[defaultMethodIndex],
        payPrice : 0,
        moneyPrivate : true,
        transferAccModalConfirm : false,
        money : 0,
        transferMoney : 0,
        applyOrder : false,
        showOrderPay : false,
        orderData : undefined
    };

    componentDidMount() {
        apis.getAccMoney({}).then(res => {
            this.setState({money : res.sms_balance})
        })
    }

    public static renderPayMethods(method : tBuyMethod){
        switch (method) {
            case "balance":
                return <PayMethodItem text={"余额支付"} icon={<MoneyCollectFilled style={{color: common.color.warning, fontSize: 38}} />}/>;
            case "wechat":
                return <PayMethodItem text={"微信支付"} icon={<WechatFilled style={{color: "#00b546", fontSize: 38}} />}/>;
            case "alipay":
                return <PayMethodItem text={"支付宝"} icon={<AlipaySquareFilled style={{color: "#0089ff", fontSize: 38}}/>}/>;
        }
    }

    render() {
        let {history , location : {pathname}} = this.props;
        let {payMethod, moneyPrivate,transferAccModalConfirm, money, payPrice, transferMoney,applyOrder, showOrderPay, orderData} = this.state;
        return (
            <div>
                {/*账户余额*/}
                <Card>
                    <div style={{display: "flex", justifyContent : "space-between",alignItems: "center"}}>
                        <div>
                            <Statistic
                                prefix={"¥"}
                                title="账户余额"
                                value={moneyPrivate ? "****" : money}
                                suffix={<div style={{marginLeft: 5, cursor : "pointer"}} onClick={()=>{
                                    this.setState({moneyPrivate : !moneyPrivate})
                                }}>
                                    <img src={moneyPrivate ? eyeClose : eyeOpen} alt=""/>
                                </div>}
                                precision={2}
                            />
                            <Button type={"link"} style={{paddingLeft : 0}} onClick={event => storeMenu.accountMenuRef?.go("prepaid_record")}>查看收支记录</Button>
                        </div>
                        <div style={{display: "flex"}}>
                            <Button type={"default"} size={"large"} shape={"round"} onClick={()=>{
                                this.setState({transferAccModalConfirm : true})
                            }}>转入收益账户</Button>
                            {/*<Air horizontal={5}/>*/}
                            {/*<Button type={"primary"} size={"large"}  shape={"round"} style={{width : 100}} onClick={()=>{*/}

                            {/*}}>充值</Button>*/}
                        </div>
                    </div>
                </Card>
                {/*充值金额*/}
                <div>
                    <Air vertical={10}/>
                    <Texts.ItemTitle>
                        充值金额
                    </Texts.ItemTitle>
                    <Air vertical={5}/>
                    <PriceChoose onChange={value => {
                        this.setState({payPrice : value})
                    }}/>
                </div>

                <div>
                    <Air vertical={10}/>
                    <Texts.ItemTitle>
                        充值方式
                    </Texts.ItemTitle>
                    <Air vertical={5}/>
                    <div className={style.payMethod}>
                        <PayMethod payMethods={["wechat", "alipay",]} onSelect={method => this.setState({payMethod : method})}/>
                    </div>
                </div>
                <div style={{display: "flex",justifyContent : "center", marginTop : 30}}>
                    <Button
                        type={"primary"}
                        size={"large"}
                        shape={"round"}
                        className={style.submitBtn}
                        onClick={()=>{
                            this.setState({applyOrder: true})
                            apis.operatingAccountBalance({
                                price : payPrice,
                                type : payMethod === "wechat" ? 1 :
                                        payMethod === "alipay" ? 2 : -1
                            }).then(res => {
                                this.setState({orderData : res, showOrderPay : true})
                            }).catch(()=>{
                                message.error("生成订单失败");
                            }).finally(()=>{
                                this.setState({applyOrder : false})
                            })
                        }}
                        loading={applyOrder}
                    >提交订单</Button>
                </div>

                <OrderPayAndCashier
                    show={showOrderPay}
                    onCancel={()=>{
                        this.setState({showOrderPay : false})
                    }}
                    pay_type={orderData?.pay_type}
                    orderData={{
                        "money": orderData?.money ?? -1,
                        "orderNum": orderData?.orderNum ?? "",
                        "info": {
                            "title": orderData?.title ?? "",
                        },
                        "code_url": orderData?.code_url ?? "",
                        "create_time": orderData?.create_time ?? "",
                        "pay_type": orderData?.pay_type ?? -1  // 1/微信支付 2/支付宝 3/运营账户余额
                    }}
                    onPaySuccess={()=>{
                        message.success("支付成功")
                        this.setState({showOrderPay : false})
                        // common.refresh();
                    }}
                />

                {/*转到运营账户弹窗*/}
                <Modal
                    visible={transferAccModalConfirm}
                    onCancel={()=>{
                        this.setState({transferAccModalConfirm : false})
                    }}
                    onOk={e => {
                        apis.transferIncomeAcc({
                            price : transferMoney
                        }).then(res => {
                            this.setState({transferAccModalConfirm : false});
                            storeMenu.accountMenuRef?.go("proceeds");
                            message.success("转入收益账户成功")
                        }).catch(err => {
                            message.error(err.data?.message)
                        })
                    }}
                    title={"转入收益账户"}
                    centered={true}
                    okText={"确定"}
                    cancelText={"取消"}
                >
                    <div>
                        <div>请输入金额</div>
                        <Air vertical={3}/>
                        <InputNumber
                            placeholder={"请输入金额"}
                            style={{width : 150}}
                            defaultValue={0}
                            formatter={value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value?.replace(/¥\s?|(,*)/g, '') ?? ""}
                            onChange={(v)=>{
                                this.setState({transferMoney : v ?? 0})
                            }}
                            value={transferMoney}
                            // max={money ?? common.maxPrice}
                            min={0}
                            step={10}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
