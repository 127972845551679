/**
 * 高德地图api
 * Create by liaokai on 2020-04-01 14:56:22
 */

import AMapLoader from "@amap/amap-jsapi-loader";
import {common} from "../common";

export type tCompleteTips = {
    id : string,
    name : string,
    district : string,
    adcode : string,
    location : any,    //经纬度
    address : any[],
    typecode : string,
    city : any[]
}

class Amap {
    //地名搜索自动完成
    _autoCompleteModule : any = undefined;

    constructor() {
        this.loader(["AMap.AutoComplete"]).then(res => {
            this._autoCompleteModule = res;
        });
    }

    //高德地图JSAPI加载
    private async loader(plugins : string[]) : Promise<any> {
        try {
            return await AMapLoader.load({
                "key": common.amapkey,
                "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                "plugins": plugins  //插件列表
            });
        }catch(e){
            return e;
        }
    };

    //地名搜索-自动提示
    //@keyword 搜索关键字
    //@city 限定城市，留空则默认全国
    public autoComplete = async (keyword : string, city? : string) : Promise<{tips : tCompleteTips[]}> => {
        return new Promise(async (resolve, reject) => {
            if(!keyword){
                resolve({tips :[]});
                return ;
            }
            try {
                let autoOptions = {
                    city: city ?? "全国",
                };
                // 实例化AutoComplete
                let autoComplete = new this._autoCompleteModule.AutoComplete(autoOptions);
                // 根据关键字进行搜索
                autoComplete.search(keyword, function(status: "complete" | "error" | "no_data", result : any) {
                    switch (status) {
                        case "complete":
                            resolve(result);
                            return ;
                        case "error":
                            reject(result);
                            return ;
                        case "no_data":
                            resolve(result);
                            return;
                        default:
                            reject(result);
                            console.error("高德地图自动填充api返回了未知status");
                            return;
                    }
                })
            }catch (e) {
                reject(e);
                //todo sentry catch
            }
        })
    };
}


const aMap = new Amap();

export {aMap}
