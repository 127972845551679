import qs from 'qs';
const AskPost = (ajaxinstance) => {
    const customer = {}
    //登录
    customer.Login = (mobile,password) => {
        return ajaxinstance.post('merchant/login',qs.stringify({
          mobile,password
        }));
      }
    //登录
    customer.LoginAssistant = (command,live_id) => {
        return ajaxinstance.post('merchant/login/loginAssistant',qs.stringify({
            command,live_id
        }));
    }
    //登录
    customer.LoginCommand = (name,command,live_id) => {
        return ajaxinstance.post('merchant/login/loginCommand',qs.stringify({
            name,command,live_id
        }));
    }
    //获取验证码统一接口
    customer.getVerifycode = (mobile,type,way) => {
        return ajaxinstance.post('merchant/login/getYanzhengma',qs.stringify({
          mobile,type,way
        }));
      }
    //注册
    customer.Register = (params) => {
        return ajaxinstance.post('merchant/login/register', qs.stringify(params));
      }
    //忘记密码
    customer.FogetPassword = (params) => {
        return ajaxinstance.post('merchant/login/changePassword',qs.stringify(params));
    }

    //获取我的会议列表
    customer.getMeetingList = (page,pageNum,keyword,orderfile,meeting_status) => {
        return ajaxinstance.post('merchant/meeting/index',qs.stringify({
          page,pageNum,keyword,orderfile,meeting_status
        }));
      }
    //删除会议
    customer.deleteMeeting = (meetid) => {
        return ajaxinstance.post('merchant/meeting/delMeeting',qs.stringify({
          meetid
        }));
      }
    //新建会议
    customer.createMeeting = (title,start_time,end_time,province,city,area,address,longitude,latitude,cover,id, live_link, type, province_code,city_code,area_code) => {
        return ajaxinstance.post('merchant/meeting/add',qs.stringify({
          title,start_time,end_time,province,city,area,address,longitude,latitude,cover,id,live_link,type,province_code, city_code,area_code
        }));
      }
    //获取会议信息
    customer.getMeetingInfo = (id) => {
        return ajaxinstance.post('merchant/meeting/getMeeting',qs.stringify({
          id
        }));
      }
    //获取注册表单配置列表
    customer.getRegisterField = (meetid) => {
        return ajaxinstance.post('merchant/meeting/getRegisterField',qs.stringify({
          meetid
        }));
      }
    //设置注册表单配置列表
    customer.setMeetingRegisterField = (meetid,fieldes) => {
      return ajaxinstance.post('merchant/meeting/setMeetingRegisterField',qs.stringify({
        meetid,fieldes
      }));
    }
    //获取收费详情
    customer.getChargeDetail = (meetid) => {
      return ajaxinstance.post('merchant/meeting/getToll',qs.stringify({
        meetid
      }));
    }
    //设置收费详情
    customer.setChargeDetail = (meetid,is_toll,money) => {
      return ajaxinstance.post('merchant/meeting/setToll',qs.stringify({
        meetid,is_toll,money
      }));
    }

    //获取会议介绍内容
    customer.getMeetingAbout = (meetid) => {
      return ajaxinstance.post('merchant/meeting/getContent',qs.stringify({
        meetid
      }));
    }
    //设置会议介绍内容
    customer.setMeetingAbout = (meetid,content) => {
      return ajaxinstance.post('merchant/meeting/setContent',qs.stringify({
        meetid,content
      }));
    }
    //获取会议日程信息
    customer.getMeetingDate = (meetid) => {
      return ajaxinstance.post('merchant/meeting/getSchedule',qs.stringify({
        meetid
      }));
    }
    //设置会议日程PDF
    customer.setMeetingDate = (meetid,schedule) => {
      return ajaxinstance.post('merchant/meeting/setSchedule',qs.stringify({
        meetid,schedule
      }));
    }
    //获取讲者管理信息
    customer.getMeetingGuest = (meetid) => {
      return ajaxinstance.post('merchant/meeting/getSpeaker',qs.stringify({
        meetid
      }));
    }
    //设置讲者管理PDF
    customer.setMeetingGuest = (meetid,speaker) => {
      return ajaxinstance.post('merchant/meeting/setSpeaker',qs.stringify({
        meetid,speaker
      }));
    }
    //获取嘉宾管理列表
    customer.getMeetingGuestList = (meetid,keyword,page,pageNum,mtaid) => {
      return ajaxinstance.post('merchant/Users/index',qs.stringify({
        meetid,keyword,page,pageNum,mtaid
      }));
    }

    //获取该会议报名注册自定义字段
    customer.getMeetingFormField = (meetid) => {
      return ajaxinstance.post('merchant/meeting/getMeetingRegisterField',qs.stringify({
        meetid
      }));
    }
    //获取该会议标签字段
    customer.getMeetingLabel = (meetid) => {
      return ajaxinstance.post('merchant/meeting/getMeetingTag',qs.stringify({
        meetid
      }));
    }
    //新增或更新会议标签
    customer.setMeetingLabel = (meetid,name,id) => {
      return ajaxinstance.post('merchant/meeting/setMeetingTag',qs.stringify({
        meetid,name,id
      }));
    }
    //新增或修改嘉宾
    customer.setGuestInfo = (uid,meetid,other,tagid) => {
      return ajaxinstance.post('merchant/Users/add',qs.stringify({
        ...!!uid ? {uid} : {uid: 0},meetid,...other,tagid
      }));
    }
    //获取该会议 标签和总人数
    customer.getMeetingCountLabel = (meetid) => {
      return ajaxinstance.post('merchant/Users/statistics',qs.stringify({
        meetid
      }));
    }
    //用户批量打标签 (多对一)
    customer.setUserLabel = (mtaid,uid) => {
      return ajaxinstance.post('merchant/Users/makeTag',qs.stringify({
        mtaid,uid
      }));
    }

    //删除该会议内的嘉宾
    customer.deleteMeetingGuest = (uid) => {
      return ajaxinstance.post('merchant/Users/delUsers',qs.stringify({
        uid
      }));
    }
    //删除该会议内的嘉宾
    customer.getMeetingGuestInfo = (uid) => {
      return ajaxinstance.post('merchant/Users/getUserinfo',qs.stringify({
        uid
      }));
    }
    //下载批量导入嘉宾模板
    customer.getImportGuestTemplate = (meetid) => {
      return ajaxinstance.post('merchant/Users/downloadTemplate',qs.stringify({
        meetid
      }));
    }
    //下载批量导入邮箱
    customer.getImportEmailTemplate = () => {
        return ajaxinstance.post('merchant/email/excelExportUrl',qs.stringify({

        }));
    }
    //批量导入嘉宾模板
    customer.uploadGuestList = (formdata,progress) => {
      return ajaxinstance.post('merchant/Users/importUser',formdata,{
        onUploadProgress: progressEvent => {
              if (progress) {
                progress(progressEvent)
              }
            }
        });
    }

    //批量导入邮箱模板
    customer.uploadEmailUserList = (formdata,progress) => {
        return ajaxinstance.post('merchant/email/importUser',formdata,{
            onUploadProgress: progressEvent => {
                if (progress) {
                    progress(progressEvent)
                }
            }
        });
    }
    //批量导入嘉宾模板
    customer.saveUploadGuestList = (meetid,tag,user) => {
      return ajaxinstance.post('merchant/Users/importUserSave',qs.stringify({
        meetid,tag,user
      }));
    }

    //根据已报名人员添加缴费人员
    customer.getGuestByPhone = (meetid,mobile) => {
      return ajaxinstance.post('merchant/meeting/seachUser',qs.stringify({
        meetid,mobile
      }));
    }
 //批量添加邮箱
    customer.emailAddressBatchStore = (email_address_book_id,data) => {
      return ajaxinstance.post('merchant/email/emailAddressBatchStore',qs.stringify({
          email_address_book_id,data
      }));
    }

    //根据已报名人员添加缴费人员
    customer.setGuestPaidStatus = (meetid,uid,type,remark) => {
      return ajaxinstance.post('merchant/meeting/addPayUser',qs.stringify({
        meetid,uid,type,remark
      }));
    }
    //根据已缴费人员记录
    customer.deleteGuestPaidLog = (id) => {
      return ajaxinstance.post('merchant/meeting/delOrder',qs.stringify({
        id
      }));
    }
    //更新或添加会场
    customer.setNewHall = (id,meetid,title) => {
      return ajaxinstance.post('merchant/meeting_place/add',qs.stringify({
        id,meetid,title
      }));
    }

    //获取会场信息
    customer.getMeetingHallInfo = (meetid) => {
      return ajaxinstance.post('merchant/meeting_place/index',qs.stringify({
        meetid
      }));
    }
    //按标签搜索人员名单
    customer.getNameListByLabel = (mpid,mtaid,mobile) => {
      return ajaxinstance.post('merchant/meeting_place/placeSearch',qs.stringify({
        mpid,mtaid,mobile
      }));
    }

    //设置会场人员
    customer.setHallNameList = (mpid,uid) => {
      return ajaxinstance.post('merchant/meeting_place/setPlaceUser',qs.stringify({
        mpid,uid
      }));
    }

    //获取会场签到列表
    customer.getHallNameList = (id,page,pageNum,keyword) => {
      return ajaxinstance.post('merchant/meeting_place/userList',qs.stringify({
        id,page,pageNum,keyword
      }));
    }
    //删除会场
    customer.deleteMeetingHall = (id) => {
      return ajaxinstance.post('merchant/meeting_place/delete',qs.stringify({
        id
      }));
    }
    //删除会场内的名单
    customer.removeHallNameList = (id) => {
      return ajaxinstance.post('merchant/meeting_place/removeUser',qs.stringify({
        id
      }));
    }
    //导出会场名单
    customer.getExportHallNameListUrl = (id) => {
      return ajaxinstance.post('merchant/meeting_place/excelExport',qs.stringify({
        id
      }));
    }
    // 获取通知消息
    customer.getNoticeList = (page,pageNum,icid) => {
      return ajaxinstance.post('merchant/merchant/getInformation',qs.stringify({
        page,pageNum,icid
      }));
    }
    // 设置消息查看状态
    customer.setNoticeStatus = (id) => {
      return ajaxinstance.post('merchant/merchant/setInformationStatus',qs.stringify({
        id
      }));
    }
    // 获取通知消息分类
    customer.getNoticeClass = () => {
      return ajaxinstance.post('merchant/merchant/getInformationClass');
    }
    //获取用户信息
    customer.getUserInfo = () => {
      return ajaxinstance.post('merchant/merchant/index');
    }
    //修改用户登录手机号
    customer.updateUserPhone = (oldpassword,mobile,verifycode,password) => {
      return ajaxinstance.post('merchant/merchant/MerchantEdit',qs.stringify({
        oldpassword,mobile,password,verifycode
      }));
    }
    customer.getEmaiCode = (params) => {
      return ajaxinstance.post('/merchant/login/getEmaiCode', qs.stringify(params))
    }
    customer.emailchangePassword = (params) => {
      return ajaxinstance.post('/merchant/login/EmailchangePassword', qs.stringify(params))
    }
    //获取变更重要信息短信验证码
    customer.updateImportantInfoVerify = (mobile) => {
      return ajaxinstance.post('merchant/merchant/InformationChangesCode',qs.stringify({
        mobile
      }));
    }
    //提交修改密码
    customer.updateUserPassword = (password,verifycode,type) => {
      return ajaxinstance.post('merchant/merchant/passwordEdit',qs.stringify({
        password,verifycode,type
      }));
    }
    //会议收益总览
    customer.getMeetingProfit = (start_time,end_time,title,page,pageNum) => {
      return ajaxinstance.post('merchant/merchant/meetingFeePandect',qs.stringify({
        start_time,end_time,title,page,pageNum
      }));
    }
    //获取会议详情列表
    customer.getMeetingProfitDetail = (meetid,page,pageNum) => {
      return ajaxinstance.post('merchant/merchant/meetingFeelist',qs.stringify({
        meetid,page,pageNum
      }));
    }
    //提现申请
    customer.ApplyGetCharge = (company,account_name,bank,account_number,contacts,phone,amount) => {
      return ajaxinstance.post('merchant/merchant/tixianApply',qs.stringify({
        company,account_name,bank,account_number,contacts,phone,amount
      }));
    }
    //获取提现记录
    customer.getApplyRecord = (page,pageNum) => {
      return ajaxinstance.post('merchant/merchant/tixianRecord',qs.stringify({
        page,pageNum
      }));
    }
    //导出详情
    customer.getExportApplyRecord = () => {
      return ajaxinstance.post('merchant/merchant/tixianExport');
    }
    //导出详情
    customer.Logout = () => {
      return ajaxinstance.post('merchant/login/logout');
    }

    //签到页面 根据会场返回大会信息
    customer.getMeetingInfoByHallID = (mpid) => {
      return ajaxinstance.post('merchant/meeting_place/getMeetingPlace',qs.stringify({
        mpid
      }));
    }

    //签到页面 根据会场返回大会信息
    customer.SignInHall = (mpid,str) => {
      return ajaxinstance.post('merchant/meeting_place/CheckIn',qs.stringify({
        mpid,str
      }));
    }

    //会议模块列表
    customer.meetingModuleList = (meetid) => {
        return ajaxinstance.post('merchant/meeting/getModelList',qs.stringify({
            meetid
        }));
    }

    //新增&修改会议模块
    customer.addModel = (meetid,title,type,icon,content,url,id) => {
        return ajaxinstance.post('merchant/meeting/addModel',qs.stringify({
            meetid,title,type,icon,content,url,id
        }));
    }

    //会议模块排序
    customer.setModelSort = (data) => {
        return ajaxinstance.post('merchant/meeting/setModelSort',qs.stringify({
            data
        }));
    }
    
    //会议模块开启/关闭
    customer.setModel = (id, is_register) => {
      return ajaxinstance.post('merchant/meeting/setModel',qs.stringify({
        id, is_register
      }));
    }
    //会议模块开启/关闭
    customer.delModel = (id) => {
      return ajaxinstance.post('merchant/meeting/delModel',qs.stringify({
        id
      }));
    }

    //会议模块获取icon
    customer.getIcon = () => {
        return ajaxinstance.post('merchant/meeting/getIcon',qs.stringify({

        }));
    }

    //会议模块详情
    customer.getModelDetail = (id) => {
        return ajaxinstance.post('merchant/meeting/getModelDetail',qs.stringify({
            id
        }));
    }

    //获取微信分享设置
    customer.getWxShare = (meetid) => {
        return ajaxinstance.post('merchant/meeting/getShare',qs.stringify({
            meetid
        }));
    }

    //新增&修改会议分享设置
    customer.setWxShare = (meetid,title,desc,icon,id) => {
        return ajaxinstance.post('merchant/meeting/setShare',qs.stringify({
            meetid,title,desc,icon,id
        }));
    }




    //上传文件 @file formdata文件, @progress 上传过程回调函数
    customer.UploadFile = (file,progress) => {
        return ajaxinstance.post('api/qiniu/upload',file,{
              onUploadProgress: progressEvent => {
                if (progress) {
                  progress(progressEvent)
                }
              }
          });
    }

    return customer
  }

  export default AskPost
