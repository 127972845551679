/**
 * 设置直播的分享
 * Create by liaokai on 2020-06-10 17:32:21
 */
import {toPost} from "../../http-utils";
import {IliveShareShow} from "./liveShareShow";

export const liveShareSet = toPost<
    IliveShareShow,
    {

    }
>("/merchant/live/liveShareSet");

