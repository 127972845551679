/**
 * 导出邀请码
 * Create by liaokai on 2020-06-11 18:38:31
 */
import {toPost} from "../../http-utils";

export const exportConcurrenceTableUrl = toPost<
    {
        live_id : string
    },
    {
        url : string
    }
>("/merchant/live/exportConcurrenceTableUrl");
