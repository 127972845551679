/**
 * 短信模版发送记录
 * Create by liaokai on 2020-04-23 16:52:05
 */
import {toPost} from "../../http-utils";

export interface IsmsTemplateLogListItem {
    "id": number,
    "title": string,
    "send_time": string,
    "status": number, //发送状态 1已经发送2取消发送3发送失败4等待发送5发送中
    "all": number, //所有发送短信数量
    "success": number,//发送成功短信对象数量
    "fail": number,//发送失败短信数量
    "being": number//发送中发送短信数量
}

export const smsTemplateLogList = toPost<
    {
        meetid : number,
        page : number
    },
    {
        "total": number,
        "per_page": number,
        "current_page": number,
        "last_page": number,
        "data": IsmsTemplateLogListItem[],
    }
>("/merchant/Lcm/smsTemplateLogList");
