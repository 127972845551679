/**
 * 分享设置
 * Create by liaokai on 2020-06-10 16:57:10
 */
import * as React from 'react';
import {Component} from "react";
import {Input, message, Modal} from "antd";
import {IliveShareShow, liveShareShow} from "../../../../../../../common/apis/apis/live/liveShareShow";
import {liveStore} from "../../live.store";
import {Flex} from "../../../../../../../components/style/flex/flex";
import {TitleContentTable} from "../../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import styled from "styled-components";
import {Flex1} from "../../../../../../../components/style/flex/flex-1";
import {liveShareSet} from "../../../../../../../common/apis/apis/live/liveShareSet";
import {uploadFile} from "../../../../../../../common/apis/apis/upload-file";
import {Mask} from "../../edit/component/mask";
import {UploadImgTipStateless} from "../../edit/component/upload-img-tip-stateless";
import {FlexJustifyCenter} from "../../../../../../../components/style/flex/flex-justify-center";
import ImageCropper from "../../../../../meeting-list/new-meeting/components/ImageCropper/ImageCropper";
import {Air} from "../../../../../../../components/air/Air";
const {TextArea} = Input

interface IProps{
    visible? : boolean
    onCancel? : () => void
}

interface IState extends IliveShareShow{

}

const defaultState = {
    "live_id": 0,
    "title": "",
    "content": "",
    "link": "",
    "cover": ""
}

export class LiveShareSetting extends Component<IProps,IState>{
    readonly state : IState = defaultState;

    componentDidMount() {
        liveShareShow({
            live_id : liveStore.live_id
        }).then(res => {
            this.setState({...res})
        })
    }

    componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any) {
        if(nextProps.visible){
            this.componentDidMount();
        }
    }

    onCancel = ()=>{
        this.setState(defaultState);
        this.props.onCancel?.()
    }

    onCropedImage = async (result : any): Promise<boolean> =>{
        let formdata = new FormData();
        formdata.append('file',result);
        formdata.append('type','base64');
        try {
            let res = await uploadFile(formdata)
            this.setState({cover : res})
            return !!res;
        }catch(err){
            message.error("服务器错误！");
            return false
        }
    }

    render() {
        let {visible} = this.props;
        let {live_id, title, content, cover, link} = this.state;
        return (
            <Modal
                title={"分享设置"}
                visible={visible}
                onCancel={() => {
                    this.onCancel();
                }}
                okText={"保存"}
                cancelText={"返回"}
                onOk={() => {
                    liveShareSet(this.state)
                        .then((res) => {
                            message.success("保存成功");
                            this.onCancel?.();
                        })
                        .catch((e) => {
                            message.error("保存失败");
                        });
                }}
                width={700}
            >
                <Flex>
                    <Flex1>
                        <TitleContentTable colGap={10} rowGap={10}>
                            <div>标题</div>
                            <Input
                                value={title}
                                placeholder={"请输入标题"}
                                onChange={(event) => {
                                    this.setState({
                                        title: event.target.value,
                                    });
                                }}
                            />
                            <div>描述</div>
                            <TextArea
                                value={content}
                                rows={4}
                                placeholder={"请输入描述"}
                                onChange={(event) => {
                                    this.setState({
                                        content: event.target.value,
                                    });
                                }}
                            />
                            <div>链接</div>
                            <Input
                                value={link}
                                placeholder={"请输入链接"}
                                onChange={(event) => {
                                    this.setState({ link: event.target.value });
                                }}
                            />
                        </TitleContentTable>
                    </Flex1>
                    <Air horizontal={10} />
                    <div
                        style={{
                            background: "#f3f3f3",
                            height: "150px",
                            width: "150px",
                            overflow: "hidden",
                        }}
                    >
                        {
                            <ImageCropper
                                text={
                                    <Mask
                                        floatView={
                                            cover ? (
                                                <UploadImgTipStateless
                                                    title={"上传分享图片"}
                                                    descript={""}
                                                    theme={"dark"}
                                                />
                                            ) : undefined
                                        }
                                    >
                                        {cover ? (
                                            <Img
                                                src={cover}
                                                style={{
                                                    width: "150px",
                                                    height: "150px",
                                                    maxWidth: "150px",
                                                    maxHeight: "150px",
                                                }}
                                                width="150px"
                                                height="150px"
                                                alt=""
                                            />
                                        ) : (
                                            <UploadImgTipStateless
                                                title={"上传分享图片"}
                                                descript={""}
                                            />
                                        )}
                                    </Mask>
                                }
                                width={1}
                                height={1}
                                onUpdate={this.onCropedImage}
                            />
                        }
                    </div>
                </Flex>
            </Modal>
        );
    }
}

const Img = styled.img`
  width: 175px;
  margin-left: 0px;
`
