import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Form, Table, Button, message, Input, Modal, Popconfirm, Avatar } from 'antd';
import {
  merchantUserIndex,
  merchantUserDelUsers,
  merchantUserGetHeaderFields,
  merchantUserGetMeetingTag,
  merchantUserMakeTag,
  merchantUserSetMeetingTag,
  merchantUserDleMeetingTag,
  formatListResult,
  merchantUserTagStatistics,
} from './constants';
import { useAntdTable, useBoolean, useMount } from 'ahooks';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { api } from '../../../../../../../common/app';
import { Air } from '../../../../../../../components/air/Air';
import { enableShowSet } from '../../../../../../../common/apis/apis/live/enableShowSet';
import { Flex } from '../../../../../../../components/style/flex/flex';
import { enableShowInfo } from '../../../../../../../common/apis/apis/live/enableShowInfo';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FlexJustifyBetween } from '../../../../../../../components/style/flex/flex-justify-between';
import EditGuestBox from '../index/components/EditGuestBox';
import NewGuestBox from '../index/components/NewGuestBox';
import invariant from 'invariant';
import CostomModal from '../../plain/component/modal';
import EditModal from './modal';
import { guestStore } from './constants/store'

const { TextArea } = Input;
const { Search } = Input;

function Guest(props: any) {
  const id = props.match.params.meeting_id;
  const history = useHistory();
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [value, setValue] = useState('');
  const [currentCate, setCurrentCate] = useState({} as any);
  const [editCate, setEditCate] = useState({} as any);
  const [editVisible, setEditVisible] = useState(false);
  const [head, setHead] = useState({} as any);
  const [columns, setColumns] = useState([] as any[]);
  const [editUserVisible, setEditUserVisible] = useState(false);
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [editUser, setEditUser] = useState({} as any);
  const [tagStatistics, setTagStatistics] = useState([] as any[]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([] as any[]);

  const afterDeleteCallback = useCallback(() => {
    merchantUserGetMeetingTag({ meetid: id }).then((response) => {
      if (response && response[0]) {
        setCurrentCate(response[0]);
      }
    });
  }, []);

  const afterEditUserCallback = useCallback(() => {
    merchantUserGetMeetingTag({ meetid: id }).then((response) => {
      console.log('response', response);
    });
    merchantUserTagStatistics({ meetid: id }).then((response) => {
      setTagStatistics(response);
    });
  }, [currentCate]);

  useEffect(() => {
    merchantUserIndex({ meetid: id } as any).then((response) => {
      setHead(response.head);
    });

    merchantUserTagStatistics({ meetid: id }).then((response) => {
      setTagStatistics(response);
    });
  }, []);

  const onDelete = async () => {
    try {
      invariant(selectedRowKeys.length > 0, '请先选择要删除的嘉宾');
      const payload = {
        uid: selectedRowKeys,
      };

      Modal.confirm({
        title: "提示",
        okText: '确定',
        cancelText: '取消',
        content: "删除嘉宾将删除嘉宾的报名信息、行程信息、接送机信息、酒店信息，确定删除吗？",
        onOk: () => {
          console.log("payload", payload);
          merchantUserDelUsers(payload as any)
            .then((response) => {
              message.success("删除成功!");
              submit();
              
              cateProps?.search?.submit();
              merchantUserTagStatistics({ meetid: id }).then((response) => {
                setTagStatistics(response);
              });
            })
            .catch((error) => {
              message.warn(error?.data?.message);
            });
        }
      });
    } catch (error) {
      message.warn(error.message);
    }
  };

  const { tableProps, search } = useAntdTable(
    (paginatedParams: any, tableProps: any) => {
      return merchantUserIndex({
        meetid: id,
        page: paginatedParams.current,
        ...(currentCate.id ? { mtaid: currentCate.id } : {}),
        ...tableProps,
      }).then((response) => {
        return response;
      });
    },
    {
      form,
      formatResult: (res: any) => {
        return {
          list: res.data,
          total: res.total
        }
      }, defaultPageSize: 15
    }
  );
  const { submit, reset } = search;

  /**
   * 点击分类之后设置当前分类
   */
  useEffect(() => {
    submit();
  }, [currentCate]);

  const cateProps: any = useAntdTable(
    (paginatedParams: any, tableProps: any) => {
      return merchantUserGetMeetingTag({ meetid: id }).then((response) => {
        return response;
      });
    },
    {
      formatResult: (response) => {
        console.log('tag', response);
        return {
          list: response,
          total: undefined,
        } as any;
      },
    }
  );

  const onEditCate = () => {
    const values = editForm.getFieldsValue();
    const isEdit = !!editCate.id;
    const text = isEdit ? '修改' : '新增';
    const fetchUrl = merchantUserSetMeetingTag;

    const payload: any = isEdit
      ? {
        meetid: id,
        name: values.name,
        id: editCate.id,
      }
      : {
        meetid: id,
        name: values.name,
      };
    fetchUrl(payload)
      .then((response) => {
        message.success(`${text}成功！`);
        setEditCate({});
        setEditVisible(false);

        cateProps?.search?.submit();
        merchantUserTagStatistics({ meetid: id }).then((response) => {
          setTagStatistics(response);
        });
      })
      .catch((error) => {
        message.error(`${error?.data?.message}`);
      });
  };

  useEffect(() => {
    let columns: any[] = [
        {
            title: "头像",
            dataIndex: "headimgurl",
            render: (img: any) => {
                return <Avatar src={img} />;
            },
        },
        {
            title: "昵称",
            dataIndex: "nickname",
        },
        {
          title: '用户名',
          dataIndex: 'username',
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
        },{
          title: '创建时间',
          dataIndex: 'apply_time',
        },{
          title: '性别',
          dataIndex: 'sex',
        }
    ];

    columns.push({
      title: '操作',
      render: (item: any) => {
        return (
          <div>
            <Button
              type={'link'}
              style={{ paddingLeft: 0 }}
              onClick={() => {
                console.log('item', item);
                guestStore.setDetail(item);
                guestStore.setField(head);
                history.push(`guest/detail/${item.id}`)
              }}
            >
              详情
            </Button>
            <Button
              type={'link'}
              style={{ paddingLeft: 0 }}
              onClick={() => {
                setEditUserVisible(true);
                setEditUser(item);
              }}
            >
              编辑
            </Button>
          </div>
        );
      },
    });
    setColumns(columns);
  }, []);

  const cateColumn: any = [
    {
      title: '全部标签',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text: string, item: any) => {
        const currentStatistics = tagStatistics.find((s) => s.id === item.id);
        return (
          <div
            style={{
              background: item.id === currentCate.id ? '#f1f1f1' : '',
            }}
          >
            <span
              onClick={() => setCurrentCate(item)}
              style={{
                display: 'inline-block',
                marginRight: '5px',
              }}
            >
              {`${text}（${currentStatistics && currentStatistics.num || 0}）`}
            </span>
            {text !== '全部' && (
              <Popconfirm
                title="确认删除标签?"
                onConfirm={() => {
                  merchantUserDleMeetingTag({
                    id: item.id,
                  })
                    .then((response) => {
                      message.success('删除成功');
                    })
                    .catch((error) => {
                      message.info(error?.data?.message);
                    })
                    .finally(() => {
                      cateProps.search.reset();
                      afterDeleteCallback();
                    });
                }}
                okText="确定"
                cancelText="取消"
              >
                <DeleteOutlined style={{ cursor: 'pointer' }} />
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (keys: any, selectedRows: any) => {
      setSelectedRowKeys(keys);
    },
  };
  return (
    <div>
      <Form form={form}>
        <FlexJustifyBetween style={{ marginBottom: 12, alignItems: 'center' }}>
          <Flex>
            <Button
              onClick={() => setAddUserVisible(true)}
              type={'primary'}
              style={{ marginRight: 12 }}
            >
              单个添加
            </Button>
            <Button
              style={{ marginRight: 12 }}
              type={'primary'}
              onClick={() => {
                const {
                  history,
                  location: { pathname },
                } = props;
                history.push(`${pathname}/bulk_import`);
              }}
            >
              批量添加
            </Button>
            <Form.Item
              name="search"
              style={{ marginRight: 12, marginBottom: 0 }}
            >
              <Search
                placeholder="可以根据昵称，姓名，手机号码进行搜索"
                style={{ width: '300px' }}
                enterButton
                onSearch={submit}
              />
            </Form.Item>
          </Flex>
          <Flex>
            <Button type={'primary'} onClick={onDelete}>
              删除嘉宾
            </Button>
          </Flex>
        </FlexJustifyBetween>
      </Form>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 3 }}>
          <Table
            rowKey="id"
            rowSelection={rowSelection}
            columns={columns}
            {...tableProps}
          />
        </div>

        <WhiteGroupWrapper>
          <WhiteGroup>
            <Table
              rowKey="id"
              columns={cateColumn}
              {...cateProps.tableProps}
              emptyText={'暂无内容'}
              pagination={false}
            />
            <Button
              type="primary"
              style={{ marginTop: '10px' }}
              onClick={() => {
                setEditCate({});
                setEditVisible(true);
              }}
            >
              添加标签
            </Button>
          </WhiteGroup>
        </WhiteGroupWrapper>
      </div>

      <Modal
        okText="确定"
        cancelText="取消"
        onCancel={() => setEditVisible(false)}
        onOk={() => onEditCate()}
        title={`${!!editCate.id ? '修改' : '新增'}标签`}
        visible={editVisible}
      >
        <Form form={editForm}>
          <Form.Item
            label="标签标题"
            name="name"
            rules={[{ required: true, message: '请输入标签标题' }]}
          >
            <Input maxLength={8} />
          </Form.Item>
        </Form>
      </Modal>
      {/* {addUserVisible && (
        <NewGuestBox
          meetingid={id}
          onClose={() => setAddUserVisible(false)}
          onUpdate={submit}
        />
      )} */}

      <EditModal
        id={id}
        visible={editUserVisible}
        editUser={editUser}
        title='编辑嘉宾'
        onCancel={() => {
          setEditUserVisible(false);
        }}
        callback={() => {
          submit();
          cateProps.search.submit();

          merchantUserTagStatistics({ meetid: id }).then((response) => {
            setTagStatistics(response);
          });
        }}
      />

      <EditModal
        id={id}
        visible={addUserVisible}
        title='添加嘉宾'
        onCancel={() => {
          setAddUserVisible(false);
        }}
        callback={() => {
          submit();
          cateProps.search.submit();

          merchantUserTagStatistics({ meetid: id }).then((response) => {
            setTagStatistics(response);
          });
        }}
      />
    </div>
  );
}

export default Guest;

const WhiteGroupWrapper = styled.div`
  width: 150px;
  text-align: center;
  margin-left: 5px;
`;
const WhiteGroup = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
`;
const AOper = styled.a`
  display: inline-block;
  margin-right: 10px;
`;
