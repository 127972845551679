/**
 * 复制链接展示框
 * Create by liaokai on 2020-06-13 23:37:35
 */
import * as React from 'react';
import {Component} from "react";
import {Button, Input, notification, Popover} from "antd";
import copy from "copy-to-clipboard";
import {Qrcode} from "../../../../../../../components/common/qrcode";
import {Air} from "../../../../../../../components/air/Air";
import style from "../../../../../meeting-list/meeting/menu/overview/overview.scss";
import {Radio} from "../../../../../../../components/common/radio";
import {FlexColHorCenter} from "../../../../../../../components/style/flex/flex-col-hor-center";

interface IProps{
    link?: string
    showQr? : boolean
    downloadQr? : boolean
}

interface IState{

}

export class CopyLink extends Component<IProps,IState>{
    readonly state : IState = {

    };

    qrCode = React.createRef<Qrcode>()

    render() {
        let {link, showQr, downloadQr} = this.props;
        let {} = this.state;
        link = link ?? ""
        return (
            <Popover
                content={
                    showQr ?
                        <FlexColHorCenter>
                            <Qrcode ref={this.qrCode} value={link}/>
                            {
                                downloadQr ?
                                    <>
                                        <Air vertical={5}/>
                                        <Popover
                                            placement={"bottom"}
                                            content={
                                                <div className={style.sizeSelect}>
                                                    <Radio
                                                        data={[
                                                            {show: "小", size: 128},
                                                            {show: "中", size: 256},
                                                            {show: "大", size: 512},
                                                            {show: "巨大", size: 1024},
                                                        ]}
                                                        renderSelectedItem={item => <Button type={"primary"}
                                                                                            onClick={() => {
                                                                                                this.qrCode?.current?.download(item.size)
                                                                                            }}>{item.show}</Button>}
                                                        renderUnSelectItem={item => <Button onClick={() => {
                                                            this.qrCode?.current?.download(item.size)
                                                        }}>{item.show}</Button>}
                                                        extractKey={item => item.show}
                                                        initSelectIndex={1}
                                                    />
                                                </div>
                                            }
                                        >
                                            <Button type={"primary"} onClick={() => {
                                                this.qrCode?.current?.download(512)
                                            }}>下载</Button>
                                        </Popover>
                                    </>
                                    :
                                    undefined
                            }
                        </FlexColHorCenter>
                        :
                        undefined
                }>
                <Input
                    size={"small"}
                    value={link}
                    suffix={
                        <Button type={"link"} onClick={()=>{
                            copy(link ?? "");
                            notification.success({
                                message: `链接已复制`,
                                description: link,
                                duration: 2.2,
                                style: {wordBreak: "break-word"}
                            });
                        }}>复制链接</Button>
                    }
                />
            </Popover>
        );
    }
}
