/**
 * 直播列表的展示数据
 * Create by liaokai on 2020-05-30 19:05:41
 */
import {toPost} from "../../http-utils";

export interface IpandectIndex {
    "accumulatedCount": number,//正在直播
    "reservedCount": number, //预告
    "accumulatedAllPeople": number, //累计总人数
    "accumulatedPeople": number //总人数
    list : {title: string, number : string}[]
}

export const pandectIndex = toPost<
    {

    },
    IpandectIndex
>("/merchant/live/pandectIndex");
