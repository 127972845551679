// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".edit_container__KJ4zT .edit_infoBox__iO5RD{background-color:white}.edit_container__KJ4zT .edit_radioBox__1xcuW{display:flex;align-items:center}.edit_container__KJ4zT .edit_form__18KAj .edit_iconsBox__IZ-FL{display:flex;flex-wrap:wrap}.edit_container__KJ4zT .edit_form__18KAj .edit_iconsBox__IZ-FL>*{padding-right:20px}.edit_container__KJ4zT .edit_form__18KAj .edit_submitBox__blfRh{display:flex}\n", ""]);
// Exports
exports.locals = {
	"container": "edit_container__KJ4zT",
	"infoBox": "edit_infoBox__iO5RD",
	"radioBox": "edit_radioBox__1xcuW",
	"form": "edit_form__18KAj",
	"iconsBox": "edit_iconsBox__IZ-FL",
	"submitBox": "edit_submitBox__blfRh"
};
module.exports = exports;
