/**
 * 课件列表
 * Create by liaokai on 2020-05-27 11:48:39
 */
import {toPost} from "../../http-utils";

export interface ICoursewareIndexItem {
    "id": number,
    "title": string,
    "status": number,
    "credit": string|number,
    "update_time": string
}

export const coursewareIndex = toPost<
    {
        live_id : string
        page : number,
        paginate? : number
        search? : string
    },
    {
        "current_page": number,
        "total": number,
        "last_page": number,
        "data": ICoursewareIndexItem[]
    }
>("/merchant/live/coursewareIndex");

