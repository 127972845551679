// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NoticeCenterView_NoticeCenterView__24U02{margin:0 auto}.NoticeCenterView_NoticeCenterView__24U02 .NoticeCenterView_tabsBox__2RTHK{margin-top:10px}.NoticeCenterView_NoticeCenterView__24U02 .NoticeCenterView_CrumbsBox__1T5iN{width:100%;height:82px;font-size:21px;font-weight:400;color:#000}.NoticeCenterView_NoticeCenterView__24U02 .NoticeCenterView_CrumbsBox__1T5iN .NoticeCenterView_NavButton__1c_ro{min-width:88px;height:calc(100% - 7px);margin:0 50px;border-bottom:transparent 7px solid;cursor:pointer}.NoticeCenterView_NoticeCenterView__24U02 .NoticeCenterView_CrumbsBox__1T5iN .NoticeCenterView_NavAct__dTSme{border-bottom:#0089FF 7px solid}.NoticeCenterView_NoticeCenterView__24U02 .NoticeCenterView_NoticeListBody__2SqDy{width:100%;min-height:calc(916px - 66px);margin-bottom:72px;background:#fff;padding:33px 0}.NoticeCenterView_NoticeCenterView__24U02 .NoticeCenterView_NoticeListBody__2SqDy .NoticeCenterView_NoticeGroup__1SxhP{width:100%;min-height:calc(65px * 12);margin-bottom:60px}.NoticeCenterView_NoticeCenterView__24U02 .NoticeCenterView_NoticeListBody__2SqDy .NoticeCenterView_NoticeGroup__1SxhP .NoticeCenterView_NoDataComponent__1Etxf{width:100%;height:100%;font-size:20px;color:rgba(0,0,0,0.6)}.NoticeCenterView_NoticeCenterView__24U02 .NoticeCenterView_PageBusterBox__179-H{width:calc(100% - 60px);padding:0 30px}\n", ""]);
// Exports
exports.locals = {
	"NoticeCenterView": "NoticeCenterView_NoticeCenterView__24U02",
	"tabsBox": "NoticeCenterView_tabsBox__2RTHK",
	"CrumbsBox": "NoticeCenterView_CrumbsBox__1T5iN",
	"NavButton": "NoticeCenterView_NavButton__1c_ro",
	"NavAct": "NoticeCenterView_NavAct__dTSme",
	"NoticeListBody": "NoticeCenterView_NoticeListBody__2SqDy",
	"NoticeGroup": "NoticeCenterView_NoticeGroup__1SxhP",
	"NoDataComponent": "NoticeCenterView_NoDataComponent__1Etxf",
	"PageBusterBox": "NoticeCenterView_PageBusterBox__179-H"
};
module.exports = exports;
