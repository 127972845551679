import React, { Component } from "react";
import style from "./NewLabelBox.scss";
import {api} from '../../../../../../../../../common/app'
import {message} from "antd";

export class NewLabelBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id:null,
      labelname:'',
      pos:null,
    };
    this.refreshProps = this.refreshProps.bind(this);
    this.HandleInputValue = this.HandleInputValue.bind(this);
    this.HandleSubmitNewLabel = this.HandleSubmitNewLabel.bind(this);
    this.returnStyle = this.returnStyle.bind(this);
  }
  componentWillReceiveProps(nextprops) {
    this.refreshProps(nextprops);
  }
  componentDidMount() {
    this.refreshProps(this.props);
  }
  refreshProps(props) {
    this.state.pos = props.pos?props.pos:this.state.pos;
    this.state.id = props.meetingid?props.meetingid:this.state.id;
    this.setState(this.state);
  }
  HandleSubmitNewLabel(boolean){
      if (boolean) {
        api.setMeetingLabel(this.state.id,this.state.labelname,null).then(res=>{
          console.log(res);
          if (res.code === 200) {
            this.props.onClose(false);
            if(this.props.onCreateLabel){
              this.props.onCreateLabel()
            }
            this.setState(this.state);
          }else{
            message.error(res.message);
            this.props.onClose(false);
            this.setState(this.state);
          }
        },err=>{
          console.log(err);

          this.props.onClose(false);
          this.setState(this.state);
        })
      }else{
          this.props.onClose(false);
      }


  }
  returnStyle(){
    switch (this.state.pos) {
      default:
      case 'tr':
        return style.tr;
      case 'tl':
        return style.tl;
      case 'br':
        return style.br;
      case 'bl':
        return style.bl;
    }
  }
  HandleInputValue(e){
    this.state.labelname = e.target.value;
    this.setState(this.state);
  }
  render() {
    return (
      <div className={[style.DropBox,this.returnStyle()].join(' ')}>
        <div className={style.SettingTitle}>标签名称</div>
        <div className={[style.InputBox, "childcenter"].join(" ")}>
          <div className={style.Inputs}>
            <input value={this.state.labelname} type="text" maxLength={6} onChange={this.HandleInputValue.bind(this)}/>
          </div>
          <div className={[style.LengthTips, "childcenter"].join(" ")}>{this.state.labelname.length}/6</div>
        </div>
        <div className={[style.ButtonGroup, "childcenter"].join(" ")}>
          <div
            className={[style.Button, style.Submit, "childcenter"].join(" ")}
            onClick={this.HandleSubmitNewLabel.bind(this, true)}>
            确定
          </div>
          <div
            className={[style.Button, style.Cancel, "childcenter"].join(" ")}
            onClick={this.HandleSubmitNewLabel.bind(this, false)}>
            取消
          </div>
        </div>
      </div>
    );
  }
}
export default NewLabelBox;
