/**
 * ppt缩略图
 * Create by liaokai on 2020-05-28 11:38:35
 */
import * as React from 'react';
import {Component} from "react";
import styled from "styled-components";
import {Padding} from "../../../../../../components/style/space/padding";
import {Empty} from "antd";

interface IProps{
    src?: string,
    index? : string,
    focus? : boolean
}

interface IState{

}

export class SmallPic extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {index,src, focus} = this.props;
        let {} = this.state;
        return (
            <Container focus={focus}>
                {
                    src ?
                        <SmallPicView src={src}/>
                        :
                        <Empty/>
                }
                <Badge hasContent={!!index}>
                    {index}
                </Badge>
            </Container>
        );
    }
}

const Container = styled.div<{focus? : boolean}>`
  position: relative;
  margin: 5px;
  border: 1px ${p => p.focus ? "red" : "rgba(0,0,0,0)"} solid;
  background: #fff;
  cursor: pointer;
`

const SmallPicView = styled.img`
  max-width: 100%;
  -webkit-user-drag: none;
`

const Badge = styled.div<{hasContent? : boolean}>`
    position: absolute;
    left : 0px;
    bottom : 0px;
    background : rgba(0,0,0,0.5);
    font-size: 10px;
    color: #fff;
    padding : ${params => params.hasContent ? "0px 12px" : "0px"}
`
