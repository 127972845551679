/**
 * 收费管理表格
 * Create by liaokai on 2020-05-11 13:34:15
 */
import * as React from 'react';
import { Component } from "react";
import { Button, message, Modal, Table, Input } from "antd";
import { apis } from "../../../../../../../common/apis/apis";
import { RouteComponentProps } from "react-router";
import { IChargeManagement } from "../../../../../../../common/apis/apis/chargeManagement";
import { api } from "../../../../../../../common/app";

const { TextArea } = Input;

interface IProps extends RouteComponentProps<{ meeting_id: string }> {
    keyword?: string
}

interface IState {
    dataSource: IChargeManagement[],
    currentPage: number,
    total: number,
    pageSize: number,
    loading: boolean,
    showPayCostConfirm: boolean,
    currentItem: IChargeManagement | undefined,
    remarkInput: string
}

const defaultPageSize = 15      //默认页宽

export class ChargeManageTable extends Component<IProps, IState>{
    readonly state: IState = {
        dataSource: [],
        currentPage: 1,
        total: 0,
        pageSize: defaultPageSize,
        loading: false,
        showPayCostConfirm: false,
        currentItem: undefined,
        remarkInput: ""
    };

    columns = [
        {
            title: '姓名',
            dataIndex: 'username',
            key: 'username',
            render: (text: string) => text
        },
        {
            title: '手机号码',
            dataIndex: 'mobile',
            key: 'mobile',
            render: (text: string) => text,
        },
        {
            title: '缴费状态',
            dataIndex: 'status',
            key: 'status',
            render: (text: string) => text,
        },
        {
            title: '缴费方式',
            dataIndex: 'type',
            key: 'type',
            render: (text: string) => text,
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            render: (text: string) => text,
        },
        {
            title: '缴费时间',
            dataIndex: 'pay_time',
            key: 'pay_time',
            render: (text: string) => text
        },
        {
            title: '操作',
            render: (text: string, record: IChargeManagement, index: number) => {
                return (
                    <Button
                        type={"link"}
                        style={{ paddingLeft: 0 }}
                        onClick={() => {
                            this.setState({ showPayCostConfirm: true, currentItem: record })
                        }}
                    >
                        {record.status === '已缴费'
                            ? '已缴费'
                            : '手动缴费'}
                    </Button>
                )
            }
        },
    ]

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.keyword !== this.props.keyword)
            this.getData();
    }

    getData = (page: number = 1, pageSize: number = defaultPageSize) => {
        const { match: { params } } = this.props
        this.setState({ loading: true })
        apis.chargeManagement({
            meetid: params.meeting_id,
            keyword: this.props.keyword ?? "",
            page: page,
            pageNum: pageSize
        }).then(res => {
            this.setState({ dataSource: res.data, total: res.count, currentPage: page, pageSize: pageSize })
        }).catch((e) => {
            message.error("搜索失败 " + e.data?.message)
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    render() {
        let { match: { params } } = this.props;
        let { dataSource, currentPage, total, pageSize, loading, showPayCostConfirm, currentItem, remarkInput } = this.state;
        return (
            <>
                <Table
                    loading={loading}
                    columns={this.columns}
                    dataSource={dataSource}
                    pagination={{
                        current: currentPage,
                        total: total,
                        pageSize: pageSize,
                        onChange: page => {
                            this.getData(page);
                            this.setState({ currentPage: page })
                        }
                    }}
                />
                <Modal
                    title={`为 "${currentItem?.username ?? "用户"}" 手动缴费`}
                    visible={showPayCostConfirm}
                    okText={"确认缴费"}
                    cancelText={"取消"}
                    onOk={() => {
                        (api as any).setGuestPaidStatus(params.meeting_id, currentItem?.uid, 3, remarkInput).then((res: any) => {
                            if (res.code === 200) {
                                this.setState({ showPayCostConfirm: false, remarkInput: "" }, () => {
                                    message.success("缴费成功");
                                    this.getData();
                                })
                            } else {
                                message.error("缴费失败 " + res.message);
                            }
                        }, (err: any) => {
                            message.error("服务器错误，请稍候再试");
                        })
                    }}
                    onCancel={() => {
                        this.setState({ showPayCostConfirm: false })
                    }}
                >
                    <TextArea
                        value={remarkInput}
                        onChange={event => this.setState({ remarkInput: event.target.value })}
                        rows={6}
                        placeholder={"输入本次缴费备注（可留空）"}
                    />
                </Modal>
            </>
        );
    }
}
