/**
 * 推流回掉
 * Create by liaokai on 2020-05-29 15:07:59
 */
import {toPost} from "../../http-utils";

export const pushNotify = toPost<
    {
        live_id :  string,
        action : string
    },
    {

    }
>("/merchant/live/pushNotify");
