/**
 * 专题删除
 * Create by liaokai on 2020-06-15 13:28:17
 */
import {toPost} from "../../http-utils";

export const subjectDestroy = toPost<
    {
        subject_id : string[]
    },
    {

    }
>("/merchant/live/subjectDestroy");

