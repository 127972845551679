/**
 * 购买邮件包
 * Create by liaokai on 2020-05-08 19:45:31
 */
import * as React from 'react';
import {Component} from "react";
import {Texts} from "../../../../../../../components/text/Texts";
import {ISmsPackageItem, SmsPackageItem} from "../../sms-multi-send/buy-sms/sms-package-item/sms-package-item";
import style from "./buy-email.scss"
import {Radio} from "../../../../../../../components/common/radio";
import {getSmsPackageList} from "../../../../../../../common/apis/apis/getSmsPackageList";
import {apis} from "../../../../../../../common/apis/apis";
import {Air} from "../../../../../../../components/air/Air";
import {PayMethod, tBuyMethod} from "../../../../../user/prepaid-money/index/pay-method";
import {PayQrcode} from "../../../../../user/prepaid-money/index/pay-qrcode";
import {RouteComponentProps} from "react-router";
import {Button, Descriptions, Input, message, Modal} from "antd";
import {common} from "../../../../../../../common/common";
import {buySmsPackageByCash} from "../../../../../../../common/apis/apis/buySmsPackageByCash";
import {storeMenu} from "../../../../../../../common/menu.store";
import {OrderPay} from "./order-pay/order-pay";
import {IRechargeImgWechat} from "../../../../../../../common/apis/apis/get-recharge-img";
import {OrderPayAndCashier} from "./order-pay/order-pay-and-cashier";

const detaultSmsTypes = [
    {
        badge : "更多人选择",
        smsValue : 100,
        price : 10,
        originalPrice : 12,
    },
    {
        badge : "巨优惠",
        smsValue : 200,
        price : 15,
        originalPrice : 20,
    },
    {
        badge : "中型会议",
        smsValue : 500,
        price : 30,
        originalPrice : 50,
    },
    {
        badge : "大型会议",
        smsValue : 1000,
        price : 50,
        originalPrice : 100,
    }
]

interface IProps extends RouteComponentProps{

}

interface IState{
    smsItems : ISmsPackageItem[],
    selectedSmsType : ISmsPackageItem | undefined,
    selectedBuyMethod : tBuyMethod,
    money : number,
    showOrderPay : boolean  //展示订单支付弹窗
    orderData : IRechargeImgWechat | undefined
    applyOrder : boolean    //正在生成订单
}


export class BuyEmail extends Component<IProps,IState>{
    readonly state : IState = {
        smsItems : detaultSmsTypes,
        selectedSmsType : detaultSmsTypes[0],
        selectedBuyMethod : "wechat",
        money : 0,
        showOrderPay : false,
        orderData : undefined,
        applyOrder : false
    };

    componentDidMount() {
        apis.getEmailPackageList({}).then(res =>{
            this.setState({smsItems : res.map(value => ({
                    badge : value.describe,
                    smsValue : value.num,
                    price : value.price,
                    originalPrice : value.origin_price,
                    smsId : value.id.toString()  //邮件包id
                }))}, ()=>{
                this.setState({selectedSmsType : this.state.smsItems[0]})
            })
        })
    }

    public static payMethodName(type? : number) : string {
        switch (type) {
            case 1 :
                return "微信支付";
            case 2:
                return "支付宝";
            case 3:
                return "运营账户余额";
            default:
                return ""
        }
    }

    render() {
        let {history, location : {pathname}} = this.props;
        let {smsItems,selectedBuyMethod,selectedSmsType,money,showOrderPay, orderData, applyOrder} = this.state;
        return (
            <div className={style.container}>
                <div>
                    <Texts.ItemTitle>
                        邮件包类型
                    </Texts.ItemTitle>
                    <Air vertical={3}/>
                    <div className={style.smsPackageTypes}>
                        <Radio
                            data={smsItems}
                            initSelectIndex={0}
                            renderUnSelectItem={item => <SmsPackageItem titleSuffix={"条邮件"} {...item} />}
                            renderSelectedItem={item => <SmsPackageItem titleSuffix={"条邮件"} {...item} selected={true}/>}
                            onSelect={(item, index) => {
                                this.setState({selectedSmsType : item})
                            }}
                        />
                    </div>
                </div>
                <Air vertical={12}/>
                <div>
                    <Texts.ItemTitle>
                        购买方式
                    </Texts.ItemTitle>
                    <Air vertical={3}/>
                    <div className={style.smsPackageTypes}>
                        <PayMethod
                            payMethods={["wechat","alipay","balance"]}
                            onSelect={method => this.setState({selectedBuyMethod : method})}
                        />
                    </div>
                </div>
                <Air vertical={30}/>
                <div style={{display : "flex", justifyContent: "center"}}>
                    <Button
                        type={"primary"}
                        size={"large"}
                        shape={"round"}
                        className={style.submitBtn}
                        onClick={()=>{
                            this.setState({applyOrder: true})
                            apis.getRechargeImgWechat({
                                id : selectedSmsType?.smsId ?? "",
                                type : selectedBuyMethod === "wechat" ? 1 :
                                        selectedBuyMethod === "alipay" ? 2 :
                                         selectedBuyMethod === "balance" ? 3 : -1
                            }).then(res => {
                                console.log(JSON.stringify(res))
                                this.setState({orderData : res, showOrderPay : true})
                            }).catch(()=>{
                                message.error("生成订单失败");
                            }).finally(()=>{
                                this.setState({applyOrder : false})
                            })
                        }}
                        loading={applyOrder}
                    >提交订单</Button>
                </div>

                <OrderPayAndCashier
                    show={showOrderPay}
                    onCancel={()=>{
                        this.setState({showOrderPay : false})
                    }}
                    pay_type={orderData?.pay_type}
                    orderData={orderData}
                    onPaySuccess={()=>{
                        storeMenu.meetingMenuRef?.go?.("email_multi")
                        window.location.hash = "#/index/email/overview"
                        // this.setState({showOrderPay : false})
                    }}
                />

            </div>
        );
    }
}
