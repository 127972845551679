/**
 * 导出邀请码
 * Create by liaokai on 2020-06-11 18:38:31
 */
import {toPost} from "../../http-utils";

export const exportUserDataUrl = toPost<
    {
        live_id : any
    },
    {
        url : string
    }
>("/merchant/live/exportUserDataUrl");
