/**
 * 专题管理-专题目录
 * Create by liaokai on 2020-06-14 11:10:26
 */
import * as React from 'react';
import {Component} from "react";
import {ISubjectShowItem} from "../../../../../../../../common/apis/apis/live/subjectShow";
import {IQuestionIndexItem} from "../../../../../../../../common/apis/apis/live/questionIndex";
import {Button, message, Table} from "antd";
import {questionShow} from "../../../../../../../../common/apis/apis/live/questionShow";
import {IQuestionStoreQuestion} from "../../../../../../../../common/apis/apis/live/questionStore";
import {questionCancel} from "../../../../../../../../common/apis/apis/live/questionCancel";
import {questionPublish} from "../../../../../../../../common/apis/apis/live/questionPublish";

interface IProps{
    dataSource : ISubjectShowItem[]
}

interface IState{
    columns : any,
}

export class ProkviewLiveList extends Component<IProps,IState>{
    readonly state : IState = {
        columns : [
            {
                title: '序号',
                dataIndex: 'live_id',
                key: 'live_id',
                render: (text : boolean) => text,
            },
            {
                title: '直播标题',
                dataIndex: 'title',
                key: 'title',
                render: (text: string) => text,
            },
            {
                title: '直播时间',
                dataIndex: 'time',
                key: 'time',
                render: (text: string) => text,
            },
            {
                title: '直播状态',
                dataIndex: 'live_status',
                key: 'live_status',
                render: (text: string) => text,
            },
            {
                title: '观看人数',
                dataIndex: 'people_num',
                key: 'people_num',
                render: (text: string) => text,
            },
            // {
            //     title: '操作',
            //     dataIndex: 'id',
            //     key: 'id',
            //     render: (text: string, record : IQuestionIndexItem, index : number) => <div>
            //
            //     </div>,
            // }
        ],
    };

    render() {
        let {dataSource} = this.props;
        let {columns} = this.state;
        return (
            <Table
                columns={columns}
                dataSource={dataSource}
                // emptyText={"暂无内容"}
                pagination={{
                    hideOnSinglePage : true
                }}
            />
        );
    }
}
