import React, { useEffect, useState } from 'react';
import { Form, Table, Switch, Button, message, Spin, Modal, Select, Space, DatePicker, Input, Popconfirm } from 'antd';
import { useAntdTable, useBoolean, useMount } from 'ahooks';
import { InfoCircleOutlined } from '@ant-design/icons'
import {
  getHotelList,
  getHotelUserList,
  hotelUserDel,
  hotelUserUpdate,
  getHotelRoomList,
  hotelUserInfo,
  formatListResult,
  hotelUpdate,
} from './constants';
import { merchantUserIndex, formatListResult as formatListResultUser } from '../guest/guest/constants'
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { Air } from '../../../../../../components/air/Air';
import { FlexJustifyBetween } from '../../../../../../components/style/flex/flex-justify-between';
import { Flex } from '../../../../../../components/style/flex/flex';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { meetingShowSetInfo, meetingShowSet, setLastTime, getLastTime } from '../hotel/constants';
import CostomModal from '../plain/component/modal';
import { givePlainUsersList } from '../plain/constants';

const { Search } = Input;

const Manage = (props: any) => {
  const meetid = props.match.params.meeting_id;
  const history = useHistory();
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [tableEnable, { toggle }] = useBoolean(true);

  // 修改接送机modal
  const [plainVisible, setPlainVisible] = useState(false);
  // 用户是否需要接送
  const [needSelected, setNeedSelected] = useState(0);
  // 表单属性根据needSelected变化
  const [formCommonProps, setFormCommonProps] = useState({} as any);
  // 修改接送机数据
  const [editData, setEditData] = useState({} as any);

  const [hotelList, setHotelList] = useState([] as any);
  const [roomList, setRoomList] = useState([] as any);
  // 选中的hotel
  const [selectedHotelId, setSelectedHotelId] = useState(-1);
  const [selectedRoomId, setSelectedRoomId] = useState(-1);

  const [init, setInit] = useState(false);

  useEffect(() => {
    getHotelList({ meetid })
      .then((result: any) => {
        setHotelList(result);
      })
  }, []);

  useEffect(() => {
    if (!!selectedHotelId && !!init) {
      const currentHotel = hotelList.find((h: any) => h.hotel_id === selectedHotelId);
      editForm.setFieldsValue({ hotel_remark: currentHotel?.notice || '' });


      getHotelRoomList({ meetid, hotel_id: selectedHotelId })
        .then((result) => {
          const roomdId = editForm.getFieldValue('room_id');
          const currentRoom = result.find((r: any) => r.room_id === roomdId);
          !currentRoom && editForm.setFieldsValue({ room_id: '' });
          setRoomList(result);
        })
    }
  }, [selectedHotelId, init])

  useEffect(() => {
    let formFields: any = {};
    if (needSelected === 0) {
      formFields = {
        disabled: true,
        required: false
      };
    } else {
      formFields = {
        disabled: false,
        required: true
      };
    }
    // setFormCommonProps(formFields);
  }, [needSelected]);

  // 关闭modal时清空edit数据
  useEffect(() => {
    if (plainVisible === false) {
      setEditData({});
    }
  }, [setPlainVisible]);

  const changeShowSet = () => {
    meetingShowSet({
      meetid,
      type: 5,
      is_show: !!tableEnable ? 2 : 1,
    } as any)
      .then((res) => {
        toggle(res.is_show);
      })
      .catch((e) => {
        message.error("设置失败 " + e.data?.message);
      });
  };

  const { tableProps, search } = useAntdTable(
    (paginatedParams: any, tableProps: any) => getHotelUserList({
      meetid: meetid,
      // page: paginatedParams.current,
      // paginate: paginatedParams.pageSize
    }),
    { form, formatResult: (response) => { return { list: response.data, total: response.total } } }
  );
  const { submit, reset } = search;

  // 新增、修改
  const onSetPlain = async () => {
    try {
      const values = await editForm.validateFields();
      // 是否是更改
      console.log('editData', editData);
      const isEdit = !!editData.id;
      // 请求url
      const fetchFunction = hotelUserUpdate;
      // 请求报文
      const payload: any = {
        meetid,
        is_need: values.is_need,
        room_id: values.room_id,
        hotel_id: values.hotel_id,
        remark: values.remark,
        choice_time: values.choice_time?.valueOf() / 1000,
        out_time: values.out_time?.valueOf() / 1000,
        ...(isEdit ? { id: editData.id } : {}),
      };
      fetchFunction(payload)
        .then(response => {
          message.success('设置成功');
          setEditData({});
          editForm.resetFields();
          setPlainVisible(false);
          submit();
        })
        .catch((e) => {
          message.error(e.data?.message);
        });
    } catch (error) {
      message.warn(error?.errorFields[0]?.errors[0] || ' ');
    }
  }

  const onDelete = (item: any) => {
    console.log('item', item);
    hotelUserDel({ meetid, id: item.id })
      .then((result: any) => {
        message.success('删除成功！');
        submit();
      })
      .catch((error: any) => {
        message.warn(error.message);
      })
  }

  const onEditOpen = (item: any) => {
    console.log('item', item);
    hotelUserInfo({ meetid, id: item.room_user_id })
      .then((result: any) => {
        setEditData(result);
        setSelectedHotelId(result.hotel_id);
        setSelectedRoomId(result.room_id);

        const currentHotel = hotelList.find((h: any) => h.hotel_id === result.hotel_id);


        editForm.setFieldsValue({
            name: result.name, // "g112222", //姓名
            identity: result.identity, // "11123213213", //身份证
            mobile: result.mobile, // "111111", //手机号码
            is_need: result.is_need, // 1, //是否需要 1需要0不需要
            hotel_id: result.hotel_id, // 3, //酒店id
            choice_time: moment(result.choice_time), // "2020-09-28 20:17" //提交时间
            out_time: moment(result.out_time), // "2020-09-28 20:17" //提交时间
            remark: result.remark,
        });

        getHotelRoomList({ meetid, hotel_id: result.hotel_id })
          .then((res) => {
            setRoomList(res);
            editForm.setFieldsValue({ hotel_remark: currentHotel?.notice || '', room_id: result.room_id });

          })
          .finally(() => {
            setInit(true);
          })
        setPlainVisible(true);
      })
      .catch((error: any) => {
        message.warn(error.message);
      })
  }

  const columns: any[] = [
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '酒店名称',
      dataIndex: 'hotel_name',
    },
    {
      title: '房型',
      dataIndex: 'room_type',
    },
    {
      title: '入住日期',
      dataIndex: 'choice_time',
    },
    {
      title: '退房日期',
      dataIndex: 'out_time',
    },
    {
      title: '提交时间',
      dataIndex: 'submit_time',
    },
    {
      title: '操作',
      render: (item: any) => {
        return (
          <div>
            <Button
              type={'link'}
              disabled={item.is_publish}
              style={{ paddingLeft: 0 }}
              onClick={() => onEditOpen(item)}
            >
              编辑
            </Button>
            {`  `}
            <Popconfirm
                title="删除后无法恢复，确认删除该信息吗?"
                onConfirm={() => {
                  onDelete(item);
                }}
                okText="确定"
                cancelText="取消"
            >
              <Button
                type={'link'}
                style={{ paddingLeft: 0 }}
              >
                删除
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ].map((item, index) => {
    return {
      ...item,
      key: item.dataIndex || `${index}`
    }
  });

  // console.log('userResult', userResult);
  return (
    <div>
      <FlexJustifyBetween style={{ marginBottom: 12 }}>
        <Flex style={{ alignItems: 'center' }}>
          <Form form={form}>
            <Form.Item name='word' style={{ marginBottom: 0 }}>
              <Search onSearch={() => submit()} enterButton='搜索' placeholder='请输入搜索内容' />
            </Form.Item>
          </Form>
        </Flex>
      </FlexJustifyBetween>
      <Spin
        indicator={<div />}
        tip="尚未开启酒店"
        spinning={!tableEnable}
      >
        <Table rowKey="id" columns={columns} {...tableProps} pagination={false} />
      </Spin>

      <CostomModal
        visible={plainVisible}
        onCancel={() => {
          editForm.resetFields();
          setPlainVisible(false);
        }}
        onOk={() => onSetPlain()}
        title={'修改酒店信息'}
        form={editForm}
        formItems={[{
          render: () => {
            return (
              <Form.Item
                label='姓名'
                name='name'
                rules={[{ required: true, message: '请输入姓名' }]}
              >
                <Input disabled={true} />
              </Form.Item>
            )
          }
        }, {
          label: '手机号',
          name: 'mobile',
          disabled: true,
          rules: [{ required: true, message: '请输入手机号' }],
        }, {
          label: '身份证号',
          name: 'identity',
          disabled: true,
          rules: [{ required: true, message: '请输入身份证号' }],
        }, {
          render: () => {
            return (
              <Form.Item
                label='是否需要酒店'
                name='is_need'
                rules={[{ required: true, message: '请选择是否需要酒店' }]}
              >
                <Select onChange={(e: number) => setNeedSelected(e)}>
                  <Select.Option value={0}>否</Select.Option>
                  <Select.Option value={1}>是</Select.Option>
                </Select>
              </Form.Item>
            )
          }
        }, {
          render: () => {
            return (
              <Form.Item
                label='酒店'
                name='hotel_id'
                rules={[{ required: true, message: '请选择酒店' }]}
              >
                <Select onChange={(e: number) => setSelectedHotelId(e)}>
                  {hotelList.map((item: any) => {
                    return <Select.Option value={item.hotel_id}>{item.title}</Select.Option>
                  })}
                </Select>
              </Form.Item>
            )
          }
        }, {
          render: () => {
            return (
              <Form.Item
                label='房型'
                name='room_id'
                rules={[{ required: true, message: '请选择房型' }]}
              >
                <Select>
                  {roomList.map((item: any) => {
                    return <Select.Option value={item.room_id}>{item.room_type}</Select.Option>
                  })}
                </Select>
              </Form.Item>
            )
          }
        }, {
          render: () => {
            return (
              <Form.Item
                label='入住时间'
                name='choice_time'
                rules={[{ required: true, message: '请选择日期' }]}
              >
                <DatePicker mode='date' disabled={formCommonProps.disabled} style={{ width: '100%' }} locale={locale} placeholder={"设置日期"} />
              </Form.Item>
            )
          }
        }, {
          render: () => {
            return (
              <Form.Item
                label='退房时间'
                name='out_time'
                rules={[{ required: true, message: '请选择日期' }]}
              >
                <DatePicker mode='date' disabled={formCommonProps.disabled} style={{ width: '100%' }} locale={locale} placeholder={"设置日期"} />
              </Form.Item>
            )
          }
        },{
          label: '酒店备注信息',
          name: 'hotel_remark',
          disabled: true,
        }, {
          label: '备注',
          name: 'remark',
        },]}
      />
    </div>
  );
};


export { Manage }