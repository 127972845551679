/**
 *
 * Create by liaokai on 2020-05-25 19:24:24
 */
import * as React from 'react';
import {Component} from "react";
import {FlexJustifyEnd} from "../../../../../../../components/style/flex/flex-justify-end";
import {Flex} from "../../../../../../../components/style/flex/flex";
import {Button, message, Modal, Space, Table, Checkbox} from "antd";
import {Air} from "../../../../../../../components/air/Air";
import {IliveRecordIndexItem, liveRecordIndex,delVideo} from "../../../../../../../common/apis/apis/live/liveRecordIndex";
import {liveStore} from "../../live.store";
import {common} from "../../../../../../../common/common";
import {IQuestionIndexItem} from "../../../../../../../common/apis/apis/live/questionIndex";
import {publishRecord} from "../../../../../../../common/apis/apis/live/publishRecord";
import {CloudDownloadOutlined, EditOutlined,DeleteOutlined, CloudSyncOutlined, ExclamationCircleOutlined, DownloadOutlined} from "@ant-design/icons/lib";
import styled from "styled-components";
import {LiveEditRecordVideo} from "./live-edit-record-video";
import {liveShow} from "../../../../../../../common/apis/apis/live/liveShow";
import {LivePublishRecordVideo} from "./live-publish-record-video";
import LivePlayer from './live_player';
import PlayerHoc from './live_player';
import { downLiveRecordUrl } from '../../../../../../../common/apis/apis/live/downLiveRecordUrl';
import { createLiveTask } from '../../../../../../../common/apis/apis/live/createLiveTask';
import {LiveDownVideo} from "./live-down-video";
import {LiveTaskVideo} from "./live-task-video";
const { confirm } = Modal;
interface IProps{

}

interface IState{
    columns : any[]
    dataSource : IliveRecordIndexItem[],
    currentPage : number,
    total : number,
    pageSize: number,
    loading : boolean,
    showSet : boolean, //转码设置
    editInfoVisible : boolean,
    showVisible : boolean, //默认回放
    editItem? : IliveRecordIndexItem,
    showDownButton : boolean
}

export class LiveContentList extends Component<IProps,IState>{
    readonly state : IState = {
        showVisible:false,
        showDownButton:false,
        showSet:false,
        columns : [
            {
                title: '封面（点击图片进行预览）',
                dataIndex: 'poster',
                key: 'poster',
                render: (text : string, record : IliveRecordIndexItem) =>{
                        const Player=  PlayerHoc((props:any)=>{
                            return (

                                <img {...props} src={record.tanscode_status === 0 ? "https://file-production.huibaifen.com/huibaifen-file/videoToCodeing.jpg"
                                     : record.tanscode_status === 1 ? 'https://file-production.huibaifen.com/huibaifen-file/videoToCodeing.jpg'
                                     :record.tanscode_status === 3 ? 'https://file-production.huibaifen.com/huibaifen-file/videToErr.jpg'
                                            :text
                                } style={{width : "128px"}} alt=""/>
                            )
                        })
                    return( <Flex>
                        {
                            
                        }
                        <Button/>
                    {record.tanscode_status === 0? <img
                        onClick={()=>{
                            this.setState({showDownButton : true, editItem : record})
                        }}
                        src={require('../../../../../../../assets/noVideoToCodeing.jpg')} style={{width : 100}} alt=""/> :null}
                    {record.tanscode_status === 1? <img
                        onClick={()=>{
                            this.setState({showDownButton : true, editItem : record})
                        }}
                        src={require('../../../../../../../assets/videoToCodeing.jpg')} style={{width : 100}} alt=""/> :null}
                    {record.tanscode_status === 3? <img
                        onClick={()=>{
                            this.setState({showDownButton : true, editItem : record})
                        }}
                        src={require('../../../../../../../assets/videToErr.jpg')} style={{width : 100}} alt=""/> :null}
                    {record.tanscode_status === 2 ?  <img
                        onClick={()=>{
                            this.setState({showDownButton : true, editItem : record})
                        }}
                        src={text} style={{width : 100}} alt=""/> :null}
                        {/*<Player live_record_id={record.id}></Player>*/}
                    <Air horizontal={5}/>
                    <div style={{fontSize: "0.7rem"}}>
                        <div>{record.title}</div>
                        <Air vertical={3}/>
                        <div>{record.create_time}</div>
                        <Air vertical={3}/>
                        <Checkbox onClick={()=>{
                            this.setState({showVisible : true, editItem : record})
                            // if(record.publish_status === "已发布"){
                            //     record.publish_status = "未发布"
                            // }else{
                            //     this.state.dataSource.forEach(value => {
                            //         value.publish_status = "未发布"
                            //     })
                            //     record.publish_status = "已发布"
                            //     message.success(`已设置"${record.title}"为回看视频`)
                            // }
                            // this.setState({dataSource : this.state.dataSource},()=>{
                            //     publishRecord({
                            //         id : record.id.toString()
                            //     }).then(
                            //         ()=>{
                            //             liveShow({id:liveStore.live_id}).then((res)=>{
                            //                 liveStore.setLiveInfo(res);
                            //             })
                            //         }
                            //     ).catch(e => {
                            //         message.error("保存失败 "+ e.data?.message)
                            //     })
                            // });
                        }} checked={record.publish_status === "已发布"}>
                            默认回放
                        </Checkbox>
                    </div>
                </Flex>)},
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text: string) => text,
            },
            {
                title: '转码状态',
                dataIndex: 'tanscode_status',
                key: 'tanscode_status',
                render: (text: any) => {
                    if(text === 0){
                        return "未转码";
                    }else if(text === 1){
                        return "转码中";
                    }else if(text === 2){
                        return "转码成功";
                    }else if(text === 3){
                        return "转码失败";
                    }else{
                        return "其他";
                    }
                },
            },
            {
                title: '时长',
                dataIndex: 'run_time',
                key: 'run_time',
                render: (text: string) => text,
            },
            {
                title: '过期时间',
                dataIndex: 'expiration_time',
                key: 'expiration_time',
                render: (text: string) => text ? text : "-",
            },
            {
                title: '操作（转码后可下载）',
                dataIndex: 'id',
                key: 'id',
                render: (text: string, record : IliveRecordIndexItem) => (
                    <Space>
                        {/*{record.tanscode_status!==3? <Button  type={"primary"} size={"small"} */}
                        {/*                                      onClick={()=>{*/}
                        {/*    switch (record.tanscode_status) {*/}
                        {/*        case 1:*/}
                        {/*            return message.error('视频转码中，请稍后再试！')*/}
                        {/*        case 2:*/}
                        {/*            downLiveRecordUrl({live_record_id:record.id}).then(res=>{*/}
                        {/*                // this.setState({url:res.url,isDow:true})*/}
                        {/*                window.location.href = res.url*/}
                        {/*            }).catch(res=>{*/}
                        {/*                message.error(res.data.message)*/}
                        {/*            })*/}
                        {/*            break;*/}
                        {/*        case 3:*/}
                        {/*            return message.error('视频转码失败 ，请稍后再试！')*/}
                        {/*        default:*/}
                        {/*            break;*/}
                        {/*    }*/}
                        {/*    this.setState({editInfoVisible : true, editItem : record})*/}
                        {/*}} disabled={record.tanscode_status !== 2} icon={<DownloadOutlined />}>下载</Button>:null}*/}
                        {/*{record.tanscode_status===3?<Button onClick={()=>{*/}
                        {/*    // /merchant/live/createLiveTask*/}
                        {/*    createLiveTask({live_record_id:record.id}).then(res=>{*/}
                        {/*        message.success('操作成功!')*/}
                        {/*        this.refreshPage()*/}
                        {/*    }).catch(res=>{*/}
                        {/*        message.success('操作失败!')*/}
                        {/*        this.refreshPage()*/}
                        {/*    })*/}
                        {/*}} type={"primary"} size={"small"} icon={<CloudSyncOutlined />}>再次转码*/}
                        {/*</Button>:null}*/}
                        <Button type={"primary"}  size={"small"} disabled={record.tanscode_status === 2 ? false: record.tanscode_status === 0 ? false: true}   onClick={()=>{
                            if(record.tanscode_status === 0){ //需要调用 转码接口
                                // createLiveTask({live_record_id:record.id}).then(res=>{
                                //     message.success('操作成功!')
                                // })
                                this.setState({showSet:true,editItem:record})
                                // message.success('设置 水印  弹窗 !  2 点我转码`转码需要一段时间，请耐心等候')
                            }else if(record.tanscode_status === 1){ //转码中 不用管

                            }else if(record.tanscode_status === 2){ //转码成功 不用管
                                downLiveRecordUrl({live_record_id:record.id}).then(res=>{
                                    window.location.href = res.url
                                }).catch(res=>{
                                    message.error(res.data.message)
                                })
                                // this.setState({showDownButton : true, editItem : record})
                            }else if(record.tanscode_status === 3){//转码失败 不用管
                            }

                        }}>{record.tanscode_status === 0 ? "转码":record.tanscode_status === 2 ?"下载": record.tanscode_status === 1 ? "转码中":"转码失败"}</Button>

                        <Button danger={true} type={"primary"} size={"small"} onClick={()=>this.delAlert(record)}
                                icon={<DeleteOutlined />}
                        >删除</Button>
                             {/*<Button size={"small"} icon={<CloudDownloadOutlined/>} onClick={()=>{*/}
                        {/*    message.info("暂未开放") //先关闭掉*/}
                        {/*}}>下载</Button>*/}
                        {/*<Button size={"small"} icon={<ScissorOutlined />} onClick={()=>{*/}
                        {/*    message.info("暂未开放")*/}
                        {/*}}>剪辑</Button>*/}
                    </Space>
                ),
            }
        ],
        dataSource : [],
        pageSize : common.defaultPaginate,
        total : 0,
        currentPage : 1,
        loading : false,
        editItem : undefined,
        editInfoVisible : false
    };
    delAlert(data:IliveRecordIndexItem){
        if(data.is_default){
           return message.error('不能删除默认回放！')
        }
        confirm({
            title: '确认删除?',
            icon: <ExclamationCircleOutlined />,
            content: '删除后无法找回，是否确认删除？',
            okText:'确认',
            cancelText:'取消',
            onOk:()=> {
                delVideo({id:data.id}).then(res=>{
                    message.success('删除回放成功!')
                    this.refreshPage()
                }).catch(err=>{
                    message.error('删除回放失败!')
                    this.refreshPage()
                })
            },
            onCancel() {
            },
          });
    }
    reqTableData(page : number){
        liveRecordIndex({live_id : liveStore.live_id, page : page, paginate : this.state.pageSize}).then(res => {
            this.setState({
                pageSize : common.defaultPaginate,
                total : res.total,
                dataSource : res.data,
                currentPage : page
            })
        })
    }

    refreshPage = ()=>{
        this.reqTableData(this.state.currentPage)
    }

    componentDidMount(): void {
        this.reqTableData(1)
        message.info("非默认回放暂存15天")
    }

    render() {
        let {} = this.props;
        let {columns,showDownButton, showSet,dataSource, showVisible,pageSize, total, currentPage, loading, editInfoVisible, editItem} = this.state;
        return (
            <div>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={dataSource}
                    // emptyText={"暂无内容"}
                    pagination={{
                        defaultCurrent : 1,
                        current : currentPage,
                        pageSize : pageSize,
                        total : total,
                        onChange : page => {
                            this.reqTableData(page);
                        }
                    }}
                    key="id"
                />
                <LiveEditRecordVideo visible={editInfoVisible} editItem={editItem} onCancel={()=>{
                    this.setState({editInfoVisible : false})
                }} onSubmit={()=>{
                    this.refreshPage();
                }}/>
                <LiveDownVideo visible={showDownButton} editItem={editItem} onCancel={()=>{
                    this.setState({showDownButton : false})
                    liveStore.reload();
                    this.refreshPage();
                }} onSubmit={()=>{
                    this.setState({showDownButton : false})
                    liveStore.reload();
                    this.refreshPage();
                }}  />

                <LiveTaskVideo visible={showSet} editItem={editItem} onCancel={()=>{
                    this.setState({showSet : false})
                    liveStore.reload();
                    this.refreshPage();
                }} onSubmit={()=>{
                    this.setState({showSet : false})
                    liveStore.reload();
                    this.refreshPage();
                }}  />
                <LivePublishRecordVideo visible={showVisible} currentItem={editItem} onCancel={()=>{
                    this.setState({showVisible : false})
                }} onSubmit={()=>{
                    liveStore.reload();
                    this.refreshPage();
                }}/>
            </div>
        );
    }
}

const Relative = styled.div`
  position: relative;
`

const Playback = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  font-size: 10px;
  background: rgba(0,0,0,0.5);
  color: #fff;
  padding: 2px;
`
