/**
 * 会议 - 菜单树
 * Create by liaokai on 2020-04-13 18:41:32
 */
import * as React from 'react';
import { Component, createContext } from "react";
import { Responsive } from "../../../../../components/common/responsive";
import { RouteComponentProps } from "react-router";
import { LecturerRouter } from "./lecturer/router";
import { tMenuVerticalTreeWithComp } from "../../../user/UserIndexView";
import { MenuContent } from "../../../../../components/menu-content/menu-content";
import { GuestRouter } from "./guest/router";
import { SigninRouter } from "./signin/router";
import { OverviewRouter } from "./overview/overview-router";
import { MeetingInfoRouter } from "./base/meeting-info-router";
import { RegisterFormRouter } from "./form/register-form-router";
import { SetChargeRouter } from "./charge-set/set-charge-router";
import { ChargeManageRouter } from "./charge-manage/charge-manage-router";
import { DataCountRouter } from "./datacount/data-count-router";
import { PlusCircleOutlined } from "@ant-design/icons/lib";
import { common } from "../../../../../common/common";
import { AddModuleRouter } from "./add-module/router";
import { SMSMultiSendRouter } from "./sms-multi-send/router";
import { apis } from "../../../../../common/apis/apis";
import { observer } from "mobx-react";
import { storeMenu } from "../../../../../common/menu.store";
import { MeetingMenuPrefix } from "./compoonent/meeting-menu-prefix/meeting-menu-prefix";
import { EmailMultiSendRouter } from "./email-multi-send/router";
import { AdvertisementRouter } from "./advertisement/advertisement-router";
import { HotelRouter } from "./hotel/hotel-router";
import { HotelManageRouter } from './hotel/manage-router'
import { PlainRouter } from "./plain/plain-router";
import { TripRouter } from './trip/trip-router';

interface IProps extends RouteComponentProps<{ meeting_id: string }> {

}

interface IState {
    menuData: tMenuVerticalTreeWithComp
}

const menuItemsRaw: tMenuVerticalTreeWithComp = [
    {
        title: "总览",
        items: [
            {
                title: "会议总览",
                routerName: "overview",
                plusField: {
                    component: OverviewRouter,
                },
            },
        ],
    },
    {
        title: "基础设置",
        items: [
            {
                title: "基本信息",
                routerName: "base",
                plusField: {
                    component: MeetingInfoRouter,
                },
            },
            {
                title: "会议信息",
                routerName: "lecturer",
                plusField: {
                    component: LecturerRouter,
                },
            },
            {
                title: "注册表单",
                routerName: "form",
                plusField: {
                    component: RegisterFormRouter,
                },
            },
            {
                title: "广告位",
                routerName: "advertisement",
                plusField: {
                    component: AdvertisementRouter,
                },
            },
        ],
    },
    {
        title: "会议管理",
        items: [
            {
                title: "嘉宾管理",
                routerName: "guest",
                plusField: {
                    component: GuestRouter,
                },
            },

            {
                title: "签到管理",
                routerName: "signin",
                plusField: {
                    component: SigninRouter,
                },
            },

            {
                title: "行程信息",
                routerName: "trip",
                plusField: {
                    component: TripRouter,
                },
            },
            {
                title: "酒店管理",
                routerName: "h_manage",
                plusField: {
                    component: HotelManageRouter,
                },
            },
            {
                title: "酒店信息",
                routerName: "hotel",
                plusField: {
                    component: HotelRouter,
                },
            },
            {
                title: "接送机信息",
                routerName: "plain",
                plusField: {
                    component: PlainRouter,
                },
            },
        ],
    },

    {
        title: "收费设置",
        items: [
            {
                title: "收费设置",
                routerName: "charge_set",
                plusField: {
                    component: SetChargeRouter,
                },
            },

            {
                title: "收费管理",
                routerName: "charge_manage",
                plusField: {
                    component: ChargeManageRouter,
                },
            },
            // {
            //     title : "短信群发",
            //     routerName : "sms_multi",
            //     plusField : {
            //         component :SMSMultiSendRouter
            //     },
            //     hidden : true
            // },
            // {
            //     title : "邮件群发",
            //     routerName : "email_multi",
            //     plusField : {
            //         component : EmailMultiSendRouter
            //     },
            //     hidden : true
            // },
            // {
            //     title : <div style={{color : "#999"}}>添加功能 <PlusCircleOutlined /></div>,
            //     routerName : "add_module",
            //     plusField : {
            //         component :AddModuleRouter
            //     }
            // },
        ],
    },
    // {
    //     title : "工具",
    //     items : [
    //         {
    //             title : "酒店信息",
    //             routerName : "Hotel",
    //             plusField : {
    //                 component :DataCountRouter
    //             }
    //         },{
    //             title : "接送机信息",
    //             routerName : "datacount",
    //             plusField : {
    //                 component :DataCountRouter
    //             }
    //         },{
    //             title : "分房工具",
    //             routerName : "datacount",
    //             plusField : {
    //                 component :DataCountRouter
    //             }
    //         },
    //     ]
    // },
    {
        title: "数据信息",
        items: [
            {
                title: "数据统计",
                routerName: "datacount",
                plusField: {
                    component: DataCountRouter,
                },
            },
        ],
    },
];

@observer
export class MeetingMenu extends Component<IProps, IState>{

    //从菜单树过滤 hidden === true，且不在 openRouterNames 白名单上的节点
    static openMenu(tree: tMenuVerticalTreeWithComp, openRouterNames: string[]): tMenuVerticalTreeWithComp {
        return common.deepClone(tree).map(value => {
            // 直接剔除隐藏的菜单项（刷新页面会跳转到默认页，因为被隐藏的路径无法访问）
            // value.items = value.items.filter(value1 => {
            //     if(value1.hidden){
            //         return openRouterNames.filter(value2 => value2 === value1.routerName).length > 0
            //     }else{
            //         return tree
            //     }
            // });

            // 仅隐藏隐藏的菜单项（刷新页面会仍在原页面）
            value.items.forEach(value1 => {
                if (openRouterNames.filter(value2 => value2 === value1.routerName).length > 0) {
                    value1.hidden = false
                }
            })
            return value;
        })
    }

    readonly state: IState = {
        menuData: MeetingMenu.openMenu(menuItemsRaw, [])
    };

    componentDidMount(): void {
        apis.getActiveModule({}).then(res => {
            this.setState({ menuData: MeetingMenu.openMenu(menuItemsRaw, res.map(value => value.routerName)) });
        })
    }

    render() {
        const { match: { params } } = this.props
        return (
            <Responsive>
                <MeetingMenuPrefix meeting_id={params.meeting_id} />
                <MenuContent
                    ref={ref => storeMenu.setMeetingMenuRef(ref)}
                    {...this.props}
                    menuData={this.state.menuData}
                    defaultRouterName={"overview"}
                />
            </Responsive>
        );
    }
}
