/**
 * 人员管理
 * Create by liaokai on 2020-07-03 13:51:02
 */
import * as React from 'react';
import {Component} from "react";
import { Texts } from '../../../../../components/text/Texts';
import {Air} from "../../../../../components/air/Air";
import {RouteComponentProps} from "react-router";
import {SendRecordTable} from "../../../meeting-list/meeting/menu/email-multi-send/index/send-record-table/send-record-table";
import  {AddressBookTable} from "./person-components/address-book-table"
interface IProps extends RouteComponentProps<any>{

}

interface IState{

}

export class Personnel extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <div>

                {/*<AddressBookTable {...this.props} />*/}
                <AddressBookTable {...this.props}/>
            </div>
        );
    }
}
