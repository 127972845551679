/**
 * 二维码组件
 * Create by liaokai on 2020-03-11 10:49:20
 */
import * as React from 'react';
import {Component} from "react";
import QRCode, {BaseQRCodeProps} from "qrcode.react";
import {Ghost} from "../ghost/ghost";

const defaultSize = 128;

interface IProps extends BaseQRCodeProps{

}

interface IState{
    size : number
}

export class Qrcode extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {
        size : defaultSize
    };
    constructor(props: IProps){
        super(props);
    }

    aid = Math.random().toString();
    qrid = Math.random().toString();

    public download(size : number = 128){
        this.setState({size : size / 2},()=>{
            let Qr = document.getElementById(this.qrid);
            let image = new Image();
            image.src = (Qr as HTMLCanvasElement).toDataURL?.("image/png");
            // alert(image.src)
            let a: any = document.getElementById(this.aid);
            a.href = image.src;
            a.click();
            let x = 1
        });
    }

    render() {
        let {} = this.props;
        let {size} = this.state;
        return (
            <>
                <QRCode
                    {...this.props}
                />
                <Ghost>
                    <a download id={this.aid}>
                        <QRCode
                            {...this.props}
                            id={this.qrid}
                            size={size}
                        />
                    </a>
                </Ghost>
            </>
        );
    }
}
