import {toPost} from "../../http-utils";

export const autoMessageSet = toPost<
    {
        'live_id':any,
        'seconds':any,
        'status':any,
        'type':any,
    },
    {

    }
    >("/merchant/live/autoMessageSet")