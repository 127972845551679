/**
 * 邮件模版发送记录
 * Create by liaokai on 2020-04-23 16:52:05
 */
import {toPost} from "../../http-utils";

export interface IsmsTemplateLogListItem {
    "id": number,
    "title": string,
    "isTiming": boolean,
    "send_time": string,
    "status": number, //发送状态 1已经发送2取消发送3发送失败4等待发送5发送中
    "all": number, //所有发送邮件数量
    "success": number,//发送成功邮件对象数量
    "fail": number,//发送失败邮件数量
    "being": number//发送中发送邮件数量
    balance_num : number         // 消耗的余额发送短息数量
    package_num : number         // 消耗的邮件包发送短息数量
}

export const emailTemplateLogList = toPost<
    {
        page : number
        paginate : number
    },
    {
        "total": number,
        "per_page": number,
        "current_page": number,
        "last_page": number,
        "data": IsmsTemplateLogListItem[],
    }
    >("/merchant/Email/emailTemplateLogList");
