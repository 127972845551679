/**
 * 会议收益总览
 * Create by liaokai on 2020-05-11 16:10:59
 */
import {toPost} from "../http-utils";

export interface IMeetingProfitItem {
    "id": number,
    "title":string,
    "city":string,
    "start_time":string,
    "end_time":string,
    "cover":string,
    "meeting_status":number,
    "count_aumont": string
}

export const getMeetingProfit = toPost<
    {
        start_time : string
        end_time : string
        title : string
        page :number
        pageNum : number
    },
    {
        "data": IMeetingProfitItem[]
        "balance": number,
        "count_num": number,
        "count_page": number
    }
>("/merchant/merchant/meetingFeePandect");

