/**
 * 直播列表信息总览
 * Create by liaokai on 2020-05-25 09:46:13
 */
import * as React from 'react';
import {Component} from "react";
import {Texts} from "../../../../../components/text/Texts";
import {Divider, Statistic} from "antd";
import {Margin} from "../../../../../components/style/space/padding";
import {FlexJustifyCenter} from "../../../../../components/style/flex/flex-justify-center";
import {Chart, Interval} from "bizcharts";
import {Flex1} from "../../../../../components/style/flex/flex-1";
import {FlexJustifyBetween} from "../../../../../components/style/flex/flex-justify-between";
import {Flex} from "../../../../../components/style/flex/flex";
import {Air} from "../../../../../components/air/Air";
import {FlexJustifyAround} from "../../../../../components/style/flex/flex-justify-around";
import {PlusCircleOutlined} from "@ant-design/icons/lib";
import {FlexColHorCenter} from "../../../../../components/style/flex/flex-col-hor-center";
import {FlexJustifyAlignCenter} from "../../../../../components/style/flex/flex-justify-align-center";
import {HoverText} from "../../../../../components/style/hover-text";
import {Link} from "react-router-dom";
import {common} from "../../../../../common/common";
import styled from "styled-components";
import {IpandectIndex, pandectIndex} from "../../../../../common/apis/apis/live/pandectIndex";

interface IProps{

}

interface IState{
    data : IpandectIndex
}

const omit = function (str : string) {
    const num = 8;
    if(str.length > num){
        return str.slice(0,4) + "..."
    }else{
        return str
    }
}

export class LiveListInfo extends Component<IProps,IState>{
    readonly state : IState = {
        data:{
            "accumulatedCount": 0,//正在直播
            "reservedCount": 0, //预告
            "accumulatedAllPeople": 0, //累计总人数
            "accumulatedPeople": 0, //总人数
            list : []
        }
    };

    componentDidMount() {
        pandectIndex({}).then(res => {
            this.setState({data: res})
        })
    }

    render() {
        let {} = this.props;
        let {data} = this.state;
        return (
            <Margin top={30}>
                <Texts.ChapterTitle>
                    直播信息
                </Texts.ChapterTitle>
                <Divider style={{margin : "15px 0px 30px"}}/>
                <FlexJustifyAround>
                    {/*数据统计*/}
                    <div>
                        <Statistic title="累计直播" value={data.accumulatedCount} suffix={"个"}/>
                        <Air vertical={10}/>
                        <Statistic title="预告播" value={data.reservedCount}  suffix={"个"}/>
                    </div>
                    {/*图表统计*/}
                    <Statistic
                        title="最近直播观众人数"
                        prefix={
                            <Chart
                                height={200}
                                width={400}
                                data={data.list.map(value => {
                                    return {
                                        number : value.number,
                                        title : omit(value.title)
                                    }
                                })}
                                autoFit
                                scale={{
                                    number: {
                                        formatter: (val:any) => {
                                            val = val+"人";
                                            return val;
                                        },
                                    },
                                }}
                            >
                                <Interval position="title*number" color="title" />
                            </Chart>
                        }
                        value={" "}
                    />
                    {/*创建专题*/}
                    <FlexJustifyAlignCenter>
                        <HoverLink to={"prokect/edit/new"}>
                            <FlexColHorCenter>
                                <PlusCircleOutlined style={{fontSize: 30}}/>
                                <Air vertical={3}/>
                                <div style={{fontSize: 20}}>创建专题</div>
                            </FlexColHorCenter>
                        </HoverLink>
                    </FlexJustifyAlignCenter>
                    {/*创建直播*/}
                    <FlexJustifyAlignCenter>
                        <HoverLink to={"new_live"}>
                            <FlexColHorCenter>
                                <PlusCircleOutlined style={{fontSize: 30}}/>
                                <Air vertical={3}/>
                                <div style={{fontSize: 20}}>创建直播</div>
                            </FlexColHorCenter>
                        </HoverLink>
                    </FlexJustifyAlignCenter>
                </FlexJustifyAround>
            </Margin>
        );
    }
}

const HoverLink = styled(Link)`
    color: ${common.color.normalText};
    &:hover{
      color: ${common.color.primary};
    }
`
