/**
 * 删除会场
 * Create by liaokai on 2020-04-27 15:47:03
 */
import {toPost} from "../http-utils";

export const deleteMeetingHall = toPost<
    {
        id : number
    },
    {

    }
>("/merchant/meeting_place/delete");

