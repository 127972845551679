import React, { Component } from 'react';
import style from './Login.scss';
import { api } from 'common/app';
import HellView from 'components/AlertBox/BaseAlert';
import phoneicon from 'assets/phoneicon.png';
import lockicon from 'assets/lockicon.png';
import bg from 'assets/sign/bg.png';
import FormRow from 'components/Form';
import LoginHeader from 'components/LoginBox/LoginHeader';
import LoginFooter from 'components/LoginBox/LoginFooter';
import { LoginCorner } from '../../components/LoginBox/LoginCorner';

const prefix = 'sign';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      password: '',
    };
    this.refreshProps = this.refreshProps.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.LoginAction = this.LoginAction.bind(this);
    this.getKeyDown = this.getKeyDown.bind(this);
  }
  componentWillReceiveProps(nextprops) {
    this.refreshProps(nextprops);
  }
  componentDidMount() {
    this.refreshProps(this.props);
    window.document.title = '会佰分会议管理系统';
    window.addEventListener('keydown', this.getKeyDown);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.getKeyDown);
  }
  refreshProps(props) {}
  onInputChange(type, e) {
    this.state[type] = e.target.value;

    this.setState(this.state);
  }
  LoginAction() {
    api.Login(this.state.phone, this.state.password).then(
      (res) => {
        if (res.code === 200) {
          window.location.hash = '#/index/meeting_list';
        } else {
          HellView.error({
            message: res.message,
          });
        }
      },
      (err) => {
        console.log(err);
        HellView.error({
          message: '服务器错误！请稍候再试！',
        });
      }
    );
  }
  getKeyDown(e) {
    switch (e.code) {
      case 'Enter':
      case 'NumpadEnter':
        this.LoginAction();
        break;
    }
  }
  render() {
    const forms = [
      {
        icon: phoneicon,
        value: this.state.phone,
        onChange: this.onInputChange.bind(this, 'phone'),
        title: '账号',
        placeHolder: '请输入手机号码 或 注册邮箱',
      },
      {
        icon: lockicon,
        value: this.state.password,
        onChange: this.onInputChange.bind(this, 'password'),
        title: '密码',
        type: 'password',
        placeHolder: '8-16位，必须包含数字和字母',
      },
    ];

    return (
      <div className={style.sign}>
        <LoginHeader />
        <div className={style[`${prefix}-layout`]}>
          <div
            className={style[`${prefix}-bg`]}
            style={{ backgroundImage: `url(${bg})` }}
          />

          <div className={style[`${prefix}-content`]}>
            <div className={style[`${prefix}-content-title`]}>即刻登录</div>
            {forms.map((item) => {
              return <FormRow key={item.title} {...item} />;
            })}
            <div className={style[`${prefix}-stat`]}>
              点击登录帐户，表示同意
              <span
                className={style[`${prefix}-stat-under`]}
                onClick={() => {
                  window.location.href = 'http://www.huibaifen.com/clause.html';
                }}
              >
                服务条款
              </span>
              和
              <span
                className={style[`${prefix}-stat-under`]}
                onClick={() => {
                  window.location.href = 'http://www.huibaifen.com/policy.html';
                }}
              >
                隐私条款
              </span>
            </div>

            <div
              className={style[`${prefix}-button`]}
              onClick={() => this.LoginAction()}
            >
              登录
            </div>

            {/*<div className={style[`${prefix}-stat`]} style={{justifyContent: 'space-between', marginTop: '13px'}}>*/}
            {/*  <span*/}
            {/*    className={style[`${prefix}-stat-under`]}*/}
            {/*    onClick={() => {*/}
            {/*      window.location.hash = '#/password_reset'*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    忘记密码？*/}
            {/*  </span>*/}
            {/*  <span>*/}
            {/*    <span*/}
            {/*      onClick={() => {*/}
            {/*        window.location.hash = '#/register'*/}
            {/*      }}*/}
            {/*      className={style[`${prefix}-stat-under`]}*/}
            {/*    >*/}
            {/*      去注册*/}
            {/*    </span>*/}
            {/*  </span>*/}
            {/*</div>*/}
          </div>
        </div>
        <LoginCorner />
        <LoginFooter />
      </div>
    );
  }
}
export default Login;
