/**
 * 邮件模板删除
 * Create by liaokai on 2020-04-24 11:49:17
 */
import {toPost} from "../../http-utils";

export const emailTemplateDestroy = toPost<
    {
        id : number
    },
    {

    }
    >("/merchant/Email/templateDestroy");
