// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".header-icon_imgBox__296N_{width:56px;height:56px;padding:10px;border-radius:28px;overflow:hidden;display:flex;justify-content:center;align-items:center;background-size:cover;background-position:center center;background-repeat:no-repeat}.header-icon_imgBox__296N_ .header-icon_img__2f7bO{max-width:100%;max-height:100%}\n", ""]);
// Exports
exports.locals = {
	"imgBox": "header-icon_imgBox__296N_",
	"img": "header-icon_img__2f7bO"
};
module.exports = exports;
