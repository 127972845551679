/**
 * 课件下载
 * Create by liaokai on 2020-05-23 18:14:01
 */
import * as React from 'react';
import {Component} from "react";
import {LiveDocDownloadList} from "./component/table";
import {RouteComponentProps} from "react-router";

interface IProps extends RouteComponentProps<{live_id? : string}>{

}

interface IState{

}

export class LiveDocDownloadView extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <div>
                <LiveDocDownloadList {...this.props}/>
            </div>
        );
    }
}
