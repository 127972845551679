/**
 * 讲师排序
 * Create by liaokai on 2020-03-12 16:25:10
 */

import React, { Component } from 'react';
import style from './sort.scss';
import { api } from '../../../../../../../common/app';
import { Avatar, Button, Card, message, Switch } from 'antd';
import { DragList } from './drag-list';
import { FormOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { Air } from '../../../../../../../components/air/Air';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { HeaderIcon } from './header-icon/header-icon';
import toolsImg from '../../../../../../../assets/tools.png';

type tList = {
  id: number;
  title: string;
  icon: string;
  is_register: boolean;
  status: string;
}[];

interface IProps extends RouteComponentProps<any> { }

interface IState {
  list: tList;
}

export class Sort extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  readonly state: IState = {
    list: [],
  };

  fetchData = () => {
    (api as any)
      .meetingModuleList(this.props.match.params.meeting_id)
      .then((res: { result: tList }) => {
        this.setState({ list: res.result ?? [] });
      });
  };

  componentDidMount() {
    this.fetchData();
  }

  onCheck = (item: any, value: boolean) => {
    (api as any)
      .setModel(item.id, value === true ? '2' : '1')
      .then((response: any) => {
        this.fetchData();
      })
      .catch((error: any) => {
        message.warn(error?.data.message);
      });
  };

  onDelete = (item: any) => {
    (api as any)
      .delModel(item.id)
      .then((response: any) => {
        message.success('删除成功');
        this.fetchData();
      })
      .catch((error: any) => {
        message.warn(error?.data?.message);
      });
  };

  render() {
    let {
      history,
      location: { pathname },
    } = this.props;
    let { list } = this.state;
    return (
      <div className={style.container}>
        <div className={style.sortBox}>
          <div className={style.ope}>
            <div className={style.text}>
              <img style={{ width: 40, height: 40 }} src={toolsImg} alt="" />
              <div style={{ marginLeft: 20 }}>
                <div style={{ fontWeight: 'bold', fontSize: 18 }}>会议编辑</div>
                <div>
                  通常被称为“九宫格”，展示在参会者的手机端，并通过九宫格了解会议相关信息
                </div>
              </div>
            </div>
            <div className={style.btns}>
              <Button
                type={'primary'}
                shape={'round'}
              >
                <Link to={`${pathname}/new`}>增加模块</Link>
              </Button>
              <Air horizontal={5} />
              {/* <Button shape={'round'}>
                <Link to={`${pathname}/preview`}>分享设置</Link>
              </Button> */}
            </div>
          </div>
          <div className={style.dragListBox}>
            <DragList
              data={list}
              extractKey={(item) => item.id.toString()}
              renderItem={(item) => (
                <div className={style.drapItem}>
                  <Card bordered={true} className={style.card}>
                    <div className={style.container}>
                      <div className={style.info}>
                        <HeaderIcon src={item.icon} />
                        <span className={style.title}>{item.title}</span>
                      </div>
                      <div className={style.box}>
                        <div>
                          {!item.is_register ? '无需注册' : '需要注册'}
                          <Switch
                            style={{ marginLeft: 5 }}
                            checked={item.is_register}
                            onChange={(value) => this.onCheck(item, value)}
                          />
                        </div>
                        <FormOutlined
                          className={style.icon}
                          onClick={() => {
                            history.push(`${pathname}/edit/${item.id}`);
                          }}
                        />
                        <MenuOutlined
                          className={[style.icon, style.move].join(' ')}
                        />
                        <CloseOutlined
                          className={style.icon}
                          onClick={() => this.onDelete(item)}
                        />
                        <Button type="link">{item.status}</Button>
                      </div>
                    </div>
                  </Card>
                </div>
              )}
              onDrapEnd={(data, srcIndex, distIndex) => {
                (api as any).setModelSort(data).then((res: any) => {
                  if (res.code === 200) {
                  } else {
                    message.error('排序错误');
                    window.location.reload();
                  }
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
