/**
 * 发布互动问答
 * Create by liaokai on 2020-06-10 15:40:42
 */
import {toPost} from "../../http-utils";

export const questionPublish = toPost<
    {
        id : string
    },
    {

    }
>("/merchant/live/questionPublish");

