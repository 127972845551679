/**
 * 徽标
 * Create by liaokai on 2020-05-08 11:11:59
 */
import * as React from 'react';
import {Component} from "react";

interface IProps{
    location? : "left-top" | "right-top",
    comp? : JSX.Element
}

interface IState{

}

export class Badge1 extends Component<IProps,IState>{
    readonly state : IState = {

    };

    static defaultProps = {
        location : "right-top",
        comp : <div/>
    }

    render() {
        let {comp,location,children} = this.props;
        let {} = this.state;
        const posi = location === "left-top" ? {left : 0, top : 0} :
                        location === "right-top" ? {right : 0, top : 0} :
                            {}
        return (
            <div style={{position: "relative"}}>
                <div style={{position: "absolute", ...posi}}>
                    {comp}
                </div>
                {children}
            </div>
        );
    }
}
