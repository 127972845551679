/**
 * 自定义网格布局
 *  2*N 网格，左侧宽度取决于最大宽度的子项，右侧宽度默认占满
 *  所有子项按照从左至右，从上至下排列
 * Create by liaokai on 2020-03-18 13:07:22
 */
import * as React from 'react';
import {Component} from "react";
import style from "./title-content-table.scss"

interface IProps{
    rowGap? : number,
    colGap? : number
}

interface IState{

}

export class TitleContentTable extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {

    };
    constructor(props: IProps){
        super(props);
    }
    render() {
        let {children, rowGap, colGap} = this.props;
        let {} = this.state;
        return (
            <div style={{rowGap: rowGap ?? 0, columnGap : colGap ?? 0}} className={style.table}>
                {children}
            </div>
        );
    }
}
