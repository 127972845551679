/**
 * 商家短信模板列表
 * Create by liaokai on 2020-04-23 17:52:00
 */
import {toPost} from "../../http-utils";

interface IsmsTemplateListItem {
    "id": number,
    "title": string,
    "create_time": string
}

export const smsTemplateList = toPost<
    {
        meetid : number,
        page: number
    },
    {
        "total": number,
        "per_page": number,
        "current_page": number,
        "last_page": number,
        "data": IsmsTemplateListItem[]
    }
>("/merchant/Lcm/smsTemplateList");

