/**
 * 手机端表单预览
 * Create by liaokai on 2020-03-24 11:40:17
 */
import * as React from 'react';
import {Component} from "react";
import {Dropdown, Input, Menu, Typography, Radio, Checkbox, Button, Empty, Select, Upload} from "antd";
import {DownOutlined} from "@ant-design/icons/lib";
import style from "./form-preview.scss"
import { UploadOutlined } from '@ant-design/icons';

const { Text } = Typography;
const {TextArea} = Input;

//公共参数
interface IFormParams<T>{
    title : string
    mandatory? : boolean,
    children? : JSX.Element | undefined,
    rule? : {
        title : string,
        content : T
    }
    type : tFormTypes
}

function PreFormItem(param: IFormParams<any>){
    return <div style={{marginTop: 12}}>
        <div style={{marginBottom: 3}}>
            <span style={{fontWeight: "bold"}}>{param.title}</span>
            <Text type="danger">{param.mandatory ? "*" : undefined}</Text>
        </div>
        <div>
            {param.children}
        </div>
    </div>
}

//医院
function PreHospital(param: IFormParams<RegExp>){
    return <>
        <div style={{marginTop: 12}}>
            <div style={{marginBottom: 3}}>
                <span style={{fontWeight: "bold"}}>{param.title}</span>
                <Text type="danger">{param.mandatory ? "*" : undefined}</Text>
            </div>
            <div>
                <Select defaultValue="1" style={{ width: "100%" }}>
                    <option value="1">请选择省/市/区县</option>
                </Select>
                {/*<Select defaultValue="1" style={{ width: "100%" }}>*/}
                {/*    <option value="1">请选择市</option>*/}
                {/*</Select>*/}
                {/*<Select defaultValue="1" style={{ width: "100%" }}>*/}
                {/*    <option value="1">请选择区/县</option>*/}
                {/*</Select>*/}
                <Select defaultValue="1" style={{ width: "100%" }}>
                    <option value="1">请选择医院</option>
                </Select>
            </div>
        </div>
    </>
}

//学科
function PreSubject(param: IFormParams<RegExp>){
    return <>
        <div style={{marginTop: 12}}>
            <div style={{marginBottom: 3}}>
                <span style={{fontWeight: "bold"}}>{param.title}</span>
                <Text type="danger">{param.mandatory ? "*" : undefined}</Text>
            </div>
            <div>
                <Select defaultValue="1" style={{ width: "100%" }}>
                    <option value="1">请选择学科/专业</option>
                </Select>
                {/*<Select defaultValue="1" style={{ width: "100%" }}>*/}
                {/*    <option value="1">请选择专业</option>*/}
                {/*</Select>*/}
            </div>
        </div>
    </>
}

//省市区/区域
function PreArea(param: IFormParams<RegExp>){
    return <>
        <div style={{marginTop: 12}}>
            <div style={{marginBottom: 3}}>
                <span style={{fontWeight: "bold"}}>{param.title}</span>
                <Text type="danger">{param.mandatory ? "*" : undefined}</Text>
            </div>
            <div>
                <Select defaultValue="1" style={{ width: "100%" }}>
                    <option value="1">请选择省/市/区</option>
                </Select>
            </div>
        </div>
    </>
}
//职称
function PreTechnical(param: IFormParams<RegExp>){
    return <>
        <div style={{marginTop: 12}}>
            <div style={{marginBottom: 3}}>
                <span style={{fontWeight: "bold"}}>{param.title}</span>
                <Text type="danger">{param.mandatory ? "*" : undefined}</Text>
            </div>
            <div>
                <Select defaultValue="1" style={{ width: "100%" }}>
                    <option value="1">请选择职称</option>
                </Select>
            </div>
        </div>
    </>
}
//學歷
function PreEducation(param: IFormParams<RegExp>){
    return <>
        <div style={{marginTop: 12}}>
            <div style={{marginBottom: 3}}>
                <span style={{fontWeight: "bold"}}>{param.title}</span>
                <Text type="danger">{param.mandatory ? "*" : undefined}</Text>
            </div>
            <div>
                <Select defaultValue="1" style={{ width: "100%" }}>
                    <option value="1">请选择学历</option>
                </Select>
            </div>
        </div>
    </>
}
//输入框
function PreInput(param: IFormParams<RegExp>){
    return <PreFormItem {...param}>
        <Input placeholder={`输入${param.title}`}/>
    </PreFormItem>
}

//下拉框
function PreDropdown(param: IFormParams<string[]>){
    return <PreFormItem {...param}>
        <Dropdown
            overlay={
                <Menu>
                    {
                        param.rule?.content?.map((value, index) => (
                            <Menu.Item key={index}>
                                {value}
                            </Menu.Item>
                        ))
                    }
                </Menu>
            }
            trigger={["click"]}
        >
            <Input placeholder={`选择${param.title}`} suffix={<DownOutlined style={{color: "#ccc"}}/>} allowClear onChange={()=>{}} />
        </Dropdown>
    </PreFormItem>
}

//单选
function PreRadio(param: IFormParams<string[]>){
    return <PreFormItem {...param}>
        <Radio.Group>
            {param.rule?.content?.map((value,index) =>
                <Radio key={index} value={value}>{value}</Radio>
            )}
        </Radio.Group>
    </PreFormItem>
}
//单选
function PreImg(param: IFormParams<string[]>){
    return <PreFormItem {...param}>
        <Button>
            <UploadOutlined /> 图片上传
        </Button>
    </PreFormItem>
}

//复选
function PreCheckBox(param: IFormParams<string[]>){
    return <PreFormItem {...param}>
        <Checkbox.Group options={param.rule?.content?.map(value => value.toString())}/>
    </PreFormItem>
}

//多行文本框
function PreTextArea(param: IFormParams<any>){
    return <PreFormItem {...param}>
        <TextArea placeholder={`输入${param.title}`} autoSize={{minRows: 4, maxRows : 4}}/>
    </PreFormItem>
}

function Hospital(param: IFormParams<any>) {
    return <PreHospital {...param}>
        <TextArea placeholder={`输入${param.title}`} autoSize={{minRows: 4, maxRows : 4}}/>
    </PreHospital>
}
function Subject(param: IFormParams<any>) {
    return <PreSubject {...param}>
        <TextArea placeholder={`输入${param.title}`} autoSize={{minRows: 4, maxRows : 4}}/>
    </PreSubject>
}


//表单类型（通用）
export type tFormTypes = "input" | "select" | "radio" | "checkBox" | "textArea"|"hospital"|"subject"|"area"|"technical"|"education"|"img"

function FormItem(item: IFormParams<any>){
    switch (item.type) {
        case "select":
            return <PreDropdown {...item}/>;
        case "input":
            return <PreInput {...item}/>;
        case "radio":
            return <PreRadio {...item}/>;
        case "checkBox":
            return <PreCheckBox {...item}/>;
        case "textArea":
            return <PreTextArea {...item}/>;
        case "hospital":
            return <Hospital {...item}/>;
        case "subject":
            return <Subject {...item}/>;
        case "area":
            return <PreArea {...item}/>;
        case "technical":
            return <PreTechnical {...item}/>;
        case "education":
            return <PreEducation {...item}/>;
        case "img":
            return <PreImg {...item}/>;
        default:
            return null;
    }
}

interface IProps{
    data : IFormParams<any>[]
}

interface IState{

}

export class FormPreview extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {

    };
    constructor(props: IProps){
        super(props);
    }
    render() {
        let {data} = this.props;
        let {} = this.state;
        let isEmpty = !data.length;
        return (
            <div className={style.container}>
                <div className={style.tips}>请务必填写有效信息，以保证确认函和其它会议信息的有效传达，感谢您的耐心填写</div>
                <div className={style.BaseTitleBox}>
                    <div className={style.BaseTitle}>基本信息</div>
                </div>
                <div>
                    {data.map(value => <FormItem key={Math.random()} {...value}/>)}
                </div>
                <div className={style.submitBox}>
                    {
                        !isEmpty ?
                            <Button size={"small"} type={"primary"} shape={"round"} onClick={()=>{}}>提交</Button>
                            :
                            <Empty description={"暂无数据"}/>
                    }
                </div>
            </div>
        );
    }
}
