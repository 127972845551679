/**
 * 设置直播的分享
 * Create by liaokai on 2020-06-10 17:32:21
 */
import {toPost} from "../../http-utils";

export const liveSetSubscribe = toPost<
    {
        live_id:any
    },
    {

    }
>("/merchant/live/setSubscribe");

