/**
 * 直播总览 - 路由
 * Create by liaokai on 2020-05-23 17:33:38
 */
import * as React from 'react';
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import {SurfaceRouter, tSurfaceRouteItem} from "../../../../../../components/surface-router/surface-router";
import {MeetingOverview} from "../../../../meeting-list/meeting/menu/overview/overview";
import {LiveOverviewView} from "./overview";

interface IProps extends RouteComponentProps{

}

interface IState{

}

const routerData : tSurfaceRouteItem = {
    breadTitle : "总览",
    routeName : "",
    component : LiveOverviewView,
    children : []
}

export class LiveOverviewRouter extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <SurfaceRouter {...this.props} entryRoute={routerData}/>
        );
    }
}
