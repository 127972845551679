/**
 * 调研问卷详情
 * Create by liaokai on 2020-06-16 18:29:01
 */
import {toPost} from "../../http-utils";
import {IQuestionStoreQuestion} from "./questionStore";

export const liveSurveyQuestionnaireShow = toPost<
    {
        id : string
    },
    {
        id : string,
        title: string,
        data: IQuestionStoreQuestion[]
    }
>("/merchant/live/liveSurveyQuestionnaireShow");
