/**
 * 上传图片提示
 * Create by liaokai on 2020-05-25 16:33:16
 */
import * as React from 'react';
import {Component} from "react";
import {PlusCircleOutlined} from "@ant-design/icons/lib";
import {FlexColVerCenter} from "../../../../../../../components/style/flex/flex-col-ver-center";
import {FlexJustifyCenter} from "../../../../../../../components/style/flex/flex-justify-center";
import styled from "styled-components";
import {Air} from "../../../../../../../components/air/Air";

type tTheme = "dark" | "light"

interface IProps{
    theme : tTheme
    title : string
    descript? : string
}

interface IState{

}

export class UploadImgTipStateless extends Component<IProps,IState>{
    readonly state : IState = {

    };
    static defaultProps = {
        theme : "light",
        descript : "",
        title : "上传海报"
    }

    render() {
        let {theme, descript,title} = this.props;
        let {} = this.state;
        return (
            <Container theme={theme}>
                <PlusCircleOutlined style={{fontSize: 50}}/>
                <Air vertical={5}/>
                <div>{title}</div>
                {
                    descript ?? <div>尺寸1280x720px（支持jpg、gif、png、bmp）</div>
                }
            </Container>
        );
    }
}

const Container = styled.div<{theme: tTheme}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme === "light" ? "#999" : "#eee"};
  cursor: pointer;
  margin: 0px 0px;
`
