import React, {Component} from 'react'
import style from './ImageCropper.scss'
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {Button, Modal, message} from "antd";
import {FileImageOutlined} from "@ant-design/icons";


const CROPWIDTH = 658;
const CROPHEIGHT = 334;

export class ImageCropper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            database: null,
            modalVisiable: false,
            modalConfirmLoading: false
        };
        this.onInputChange = this.onInputChange.bind(this);
        this.InitCanva = this.InitCanva.bind(this);
    }

    componentDidMount() {
        // this.refs && this.refs.cropfile.click();
    }

    onInputChange(e) {
        let file = e.target.files[0];
        let self = this;
        if (typeof (window.FileReader) === 'undefined') {
            message.error("抱歉，你的浏览器不支持 FileReader，不能将图片转换为Base64，请使用现代浏览器操作！");
            // HellView.error('抱歉，你的浏览器不支持 FileReader，不能将图片转换为Base64，请使用现代浏览器操作！')
        }else if( file.size / 1024 / 1024 > 2 ){
            message.error("请确保图片不大于 2MB");
        }else {
            try {
                /*图片转Base64 核心代码*/
                //这里我们判断下类型如果不是图片就返回 去掉就可以上传任意文件
                if (!/image\/\w+/.test(file.type)) {
                    alert("请确保文件为图像类型");
                    return false;
                }
                let reader = new FileReader();
                reader.onload = function () {
                    self.setState({
                        database: this.result
                    })
                };
                reader.readAsDataURL(file);
            } catch (e) {
                alert('图片转Base64出错啦！' + e.toString())
            }
        }
    }

    InitCanva(initimage) {
        let img = new Image();
        img.src = initimage;
        let ctx = this.refs.canvas.getContext('2d');
        ctx.clearRect(0, 0, CROPWIDTH, CROPHEIGHT);
        img.onload = () => {
            let imgwidth = img.width;
            let imgheight = img.height;
            let imgscale = imgwidth / imgheight;
            let cropscale = CROPWIDTH / CROPHEIGHT;
            console.log(imgwidth, imgheight, imgscale, CROPWIDTH, CROPHEIGHT, cropscale);
            if (imgscale <= cropscale) {
                let _imagewidth = imgscale * CROPHEIGHT;
                ctx.drawImage(img, CROPWIDTH / 2 - _imagewidth / 2, 0, _imagewidth, CROPHEIGHT);
            } else {
                let _imageheight = CROPWIDTH / imgscale;
                ctx.drawImage(img, 0, CROPHEIGHT / 2 - _imageheight / 2, CROPWIDTH, _imageheight);
            }
        }
    }

    _crop() {
        // console.log(this.refs.cropper.getCroppedCanvas().toDataURL());
    }

    modalOk = async () => {
        this.setState({modalConfirmLoading : true});
        let res = await this.props.onUpdate(this.refs.cropper.getCroppedCanvas().toDataURL());
        this.setState({modalConfirmLoading : false});
        if(res){
            this.setState({modalVisiable : false, database : null});
        }else{
            message.warning("上传失败");
        }
    };

    modalCancel = () => {
        this.setState({modalVisiable : false, database : null})
    };

    render() {
        let {text,width,height,desc,isProportion } = this.props;
        width = width??16;
        height = height??9;
        isProportion = isProportion ? true : false
        desc = desc ? desc : ""
        let {modalVisiable, modalConfirmLoading} = this.state;
        const aspectRatio = width&&height?width/height:16/9
        return (
            <div>
                <div onClick={()=>{
                    this.setState({modalVisiable:true})
                }}>
                    {text}
                </div>
                <Modal
                    title="上传图片"
                    visible={modalVisiable}
                    okText={"上传"}
                    onOk={this.modalOk}
                    cancelText={"取消"}
                    onCancel={this.modalCancel}
                    confirmLoading={modalConfirmLoading}
                    destroyOnClose={true}
                    maskClosable={false}
                >
                    <div className={style.Modal}>
                        <div className={style.cropInfo}>
                            <div className={style.text}>
                                裁剪图片*
                            </div>
                            <Button onClick={()=>{
                                this.refs.cropfile.click();
                            }} type={"primary"} shape={"round"} icon={<FileImageOutlined/>}>
                                选择图片
                                <input type="file" accept={".jpg,.png,.bmp,.gif"} ref='cropfile' onChange={this.onInputChange} style={{display: 'none'}}/>
                            </Button>
                        </div>
                        <div className={style.ratioText}>
                            {/*{*/}

                            {/*    ()=>{*/}
                            {/*        let w = width ?? 16*/}
                            {/*        let h = height ?? 9*/}
                            {/*        return (isProportion) ? "图片比例为"+w+":"+h+"，可通过鼠标滚轮放大缩小图片，图片不大于2M。"*/}
                            {/*            :*/}
                            {/*            "12123123213213123213213123123123123"*/}
                            {/*    }*/}

                            {/*}*/}
                            {

                                !isProportion ? "图片比例为"+width+":"+height+"，可通过鼠标滚轮放大缩小图片，图片不大于2M。":
                                desc

                            }
                        </div>
                        <div className={style.Cropper}>
                            {

                                     (!isProportion) ? <Cropper
                                         toggleDragModeOnDblclick={false}
                                         ref='cropper'
                                         src={this.state.database}
                                         style={{height: 344, width: '100%'}}
                                         dragMode={'move'}
                                         initialAspectRatio={16 / 9}
                                         aspectRatio={aspectRatio}
                                         guides={false}
                                         viewMode={2}
                                         crop={this._crop.bind(this)} />

                                         :
                                        <Cropper
                                         toggleDragModeOnDblclick={false}
                                         ref='cropper'
                                         src={this.state.database}
                                         style={{height: 344, width: '100%'}}
                                         dragMode={'move'}
                                         // initialAspectRatio={16 / 9}
                                         // aspectRatio={aspectRatio}
                                         guides={false}
                                         viewMode={2}
                                         crop={this._crop.bind(this)} />

                            }

                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default ImageCropper
