import React, { Component } from 'react'
import style from './ImportTemplateView.scss'
import excelicon from '../../../../../../../../assets/excelicon.png'
import {api} from '../../../../../../../../common/app'
import {message} from "antd";

export class ImportTemplateView extends Component {
constructor(props) {
  super(props);
  this.state = {
    onAjax:false,
    loadprogress:0,
  };
  this.refreshProps = this.refreshProps.bind(this);
  this.FileUpload = this.FileUpload.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
}
refreshProps(props) {
  this.state.id = props.id!=undefined?props.id:this.state.id;
  this.setState(this.state);
}
FileUpload(e){
  let file = e.target.files[0];
  let formdata = new FormData();
  formdata.append('file',file);
  formdata.append('email_address_book_id',this.state.id);
  this.setState({
    onAjax:true,
  })
  if (!e.target.value) return;
  e.target.value = '';
  api.uploadEmailUserList(formdata,(progressEvent)=>{
    var complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
    this.state.loadprogress = complete;
    this.setState(this.state);
  }).then(res=>{
    if (res.code === 200) {
      this.props.onGetList(res.result);
      this.props.HandleState(2);
    }else{
      message.error(res.message);
    }
    this.setState({
      onAjax:false,
    })
  },err=>{
    console.log(err);
    this.setState({
      onAjax:false,
    })
  })
}
render() {
  return (
    <div className={style.ImportTemplateView}>
        <div className={[style.TextTips,'childcenter childcolumn childalignstart'].join(' ')}>
            <span>* 已根据前面名单模版进行编辑</span>
            {/*<span>这里的人员名单模版和嘉宾注册表单设置一致噢，注册表单详情请到 <span style={{cursor: "pointer"}} onClick={()=>{*/}
            {/*  window.open("#/meeting/setforminfo/"+ this.state.id)*/}
            {/*}}>注册表单</span> 中查看</span>*/}

        </div>
        {this.state.id?<div className={[style.DownloadButton,'childcenter'].join(' ')} onClick={(()=>{this.refs.uploadfile.click()}).bind(this)}>
            <img src={excelicon} alt="" />
            <span >点我导入人员名单</span>
            {/* <div className={style.LoadedBar} style={{width:this.state.onAjax?this.state.loadprogress + '%':'100%'}}></div> */}
            <input type="file" ref={'uploadfile'} style={{display:'none'}} onChange={this.FileUpload}/>
        </div>:''}
    </div>
   )
   }
}
export default ImportTemplateView
