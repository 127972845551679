//克隆对象
export const deepClone = function<T> (obj : T) : T {
    let newObj : any = {};
    if (obj instanceof Array) {
        newObj = [];
    }
    for (let key in obj) {
        let val = obj[key];
        newObj[key] = typeof val === 'object' ? deepClone(val): val;
    }
    return newObj;
};
