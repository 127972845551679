/**
 * 直播数据统计组件
 * Create by liaokai on 2020-05-26 15:31:07
 */
import * as React from 'react';
import {Component} from "react";
import {Texts} from "../../../../../../../components/text/Texts";
import {Statistic} from "antd";
import styled from "styled-components";
import {IliveData, liveData} from "../../../../../../../common/apis/apis/live/liveData";
import {liveStore} from "../../live.store";

interface IProps{

}

interface IState{
    data : IliveData
}

export class LiveDataCountViewStateful extends Component<IProps,IState>{
    readonly state : IState = {
        data : {
            concurrence : 0,
            accumulatedAllPeople : 0,
            playbackCount : 0,
            income : 0
        }
    };

    componentDidMount() {
        liveData({
            live_id : liveStore.live_id
        }).then(res =>{
            this.setState({data : res})
        })
    }

    render() {
        let {} = this.props;
        let {data} = this.state;
        return (
            <Sta>
                <Statistic title="最高并发" value={data.concurrence} suffix={"人"}/>
                <Statistic title="累计观看直播" value={data.accumulatedAllPeople} suffix={"次"}/>
                <Statistic title="累计观看回放" value={data.playbackCount} suffix={"次"}/>
                <Statistic title="累计收费金额" value={data.income} suffix={"元"}/>
            </Sta>
        );
    }
}

const Sta = styled.div`
  display: flex;
  &>*{
    margin-right: 40px;
  }
`
