/**
 * 编辑回看视频信息
 * Create by liaokai on 2020-06-19 16:08:17
 */
import * as React from 'react';
import {Component} from "react";
import {Input, message, Modal, Space} from "antd";
import {TitleContentTable} from "../../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import {IliveRecordIndexItem} from "../../../../../../../common/apis/apis/live/liveRecordIndex";
import {liveRecordUpdate} from "../../../../../../../common/apis/apis/live/liveRecordUpdate";
import {AliLivePlayer} from "../../../../../../../components/Live/ali-live-player";
import {downLiveRecordUrl} from "../../../../../../../common/apis/apis/live/downLiveRecordUrl";
import {createLiveTask} from "../../../../../../../common/apis/apis/live/createLiveTask";

const defaultItem : IliveRecordIndexItem = {
    "id": 0,
    "title": "",
    "publish_status": "",
    "poster": "",
    "run_time": "",
    "expiration_time": "", //过期时间
    "status": "", //状态 1 代表回放
    "is_default": false, //是否默认
    tanscode_status:1,
    "create_time": "", // 创建时间
}

interface IProps{
    visible : boolean
    onCancel? : () => void
    onSubmit? : () => void
    editItem?: IliveRecordIndexItem
}

interface IState{
    editItem : IliveRecordIndexItem,
    url : string
}
export class LiveDownVideo extends Component<IProps,IState>{
    readonly state : IState = {
        editItem : defaultItem,
        url : ""
    };

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        console.log(prevProps.editItem)
        if(JSON.stringify(this.props.editItem) !== JSON.stringify(prevProps.editItem) ){
            this.setState({editItem : this.props.editItem ?? defaultItem},()=>{
                downLiveRecordUrl({live_record_id:this.state.editItem.id}).then(res=>{
                    this.setState({url:res.url})
                    console.log(res.url)
                }).catch(res=>{

                    message.error(res.data.message)
                })
            })
        }


    }

    render() {
        const {visible,onCancel,onSubmit} = this.props
        const {editItem,url} = this.state
        return (
            <>
                <Modal width="690px"
                       cancelText="关闭"
                       onOk={()=>{
                           // if(url.substr(-3).toLowerCase() === "mp4"){
                           //     window.location.href = url
                           // }else{
                           //     createLiveTask({live_record_id:editItem.id}).then(res=>{
                           //            message.success('操作成功!')
                           // })
                           //     message.error('正在进行转码...')
                           // };
                           onSubmit?.();



                       }} okText={'确定'}
                       title="回放"
                       onCancel={()=>{
                           onCancel?.();
                       }} visible={visible}>
                    <AliLivePlayer src={url}/>
                </Modal>
            </>
        )
    }
}
