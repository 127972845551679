import { toPost } from "../../../../../../../common/apis/http-utils";
import { FetchField, QuestionItem } from "../types";

export const formatListResult = (result: any): any => {
    const mergeResult = result;
    return {
        list: mergeResult.data,
        total: mergeResult.total,
    };
};

export const askQuestionList = toPost<FetchField.List, QuestionItem[]>(
    `/merchant/live/askQuestionList`
);



export const exportAskQuestionListUrl = toPost<FetchField.List, QuestionItem[]>(
    `/merchant/live/exportAskQuestionListUrl`
);

export const askQuestionCheck = toPost<{ ask_question_id: any }, any>(
    `/merchant/live/askQuestionCheck`
);

export const askQuestionReplyList = toPost<{ ask_question_id: any }, any>(
    `/merchant/live/askQuestionReplyList`
);

export const askQuestionReply = toPost<{ ask_question_id: any, reply: any }, any>(
    `/merchant/live/askQuestionReply`
);
