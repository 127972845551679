/**
 * 地址搜索
 * Create by liaokai on 2020-04-02 13:12:43
 */
import * as React from 'react';
import {Component, CSSProperties} from "react";
import {AutoComplete, Dropdown, Input, Menu} from "antd";
import style from "../../MeetingInfo.scss";
import {aMap, tCompleteTips} from "../../../../../../../../common/utils/amap";
import {tCoo} from "../../../../../../../../components/Maps/Maps";
import {Conveyor} from "../../../../../../../../common/utils/conveyor";
import {CheckCircleTwoTone, ExclamationCircleTwoTone} from "@ant-design/icons/lib";
import {common} from "../../../../../../../../common/common";
import {Tip} from "../../../../../../../../components/tip/tip.stateless";

export type tLocationItem = tCompleteTips & {coo : tCoo}

interface IProps{
    onChange? : (item : tLocationItem | undefined) => void,
    city?: string,      //搜索城市
    style?: CSSProperties,
    value? : string
}

interface IState{
    autoCompleteData : tCompleteTips[] | undefined,     //待选地址列表
    selectedLoca: tLocationItem | undefined,       //已选择的地址
    inputType : "inputting" | "unSupportCustomLocation" | "ok" | "default"  //输入状态
    inputValue : string,
}

export class LocationSearchInput extends Component<IProps,IState>{
    conveyor: Conveyor = new Conveyor(500);

    //state初始化
    readonly state : IState = {
        autoCompleteData : [],
        selectedLoca : undefined,
        inputType : "default",
        inputValue : ""
    };

    componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any): void {
        if(nextProps.value){
            this.setState({inputValue : nextProps.value})
        }
    }

    autoComplete = (keyword: string) =>{
        const {city} = this.props;
        this.conveyor.push(()=>{
            ((keyword)=>{
                aMap.autoComplete(keyword, city).then(res => {
                    this.setState({autoCompleteData : res.tips});
                }).catch(event=>{
                    common.sentry.captureException(event)
                    console.log("地点联想 Catch："+JSON.stringify(event))
                });
            })(keyword);
        });
    };

    refershInputType = ()=>{
        let {inputValue, selectedLoca} = this.state;
        if(inputValue){
            if(selectedLoca){
                this.setState({inputType : "ok" })
            }else{
                this.setState({inputType : "unSupportCustomLocation" })
            }
        }else{
            this.setState({inputType : "default" })
        }
    };

    render() {
        let {onChange} = this.props;
        let {autoCompleteData, inputType, inputValue} = this.state;
        let cssStyle = this.props.style;
        return (
            <AutoComplete
                options={
                    autoCompleteData?.filter(value => !!(value?.location?.toJSON?.())).map(value => {
                        return {
                            value : `${value.name} (${value.district})`,
                            label : <div style={{display : "flex",justifyContent: 'space-between'}}>
                                <div>{value.name}</div>
                                <div className={style.district}>{value.district}</div>
                            </div>,
                            key : Math.random()
                        }
                    }) ?? []
                }
                style={cssStyle}
                onSelect={(value,option)=>{
                    let selectItem =  autoCompleteData?.filter(v => `${v.name} (${v.district})` === value)?.[0];
                    if(selectItem){
                        let loca = selectItem.location?.toJSON?.();
                        if(loca){
                            let locaCoo = {
                                longitude : loca[0],
                                latitude : loca[1]
                            };
                            this.setState({selectedLoca : {...selectItem, coo : locaCoo}},()=>{
                                this.refershInputType();
                                onChange?.(this.state.selectedLoca)
                            });
                        }
                    }
                }}
                onSearch={(value)=>{
                    this.autoComplete(value);
                }}
                value={inputValue}
                onChange={value => {
                    this.setState({inputValue : value})
                }}
                onBlur={event=>{
                    this.refershInputType();
                }}
            >
                <Input
                    placeholder={"搜索地名"}
                    suffix={((inputType)=>{
                        switch (inputType) {
                            case "default":
                                return <div/>;
                            case "inputting":
                                return <div/>;
                            case "ok":
                                return <CheckCircleTwoTone twoToneColor={common.color.success}/>
                            case "unSupportCustomLocation":
                                return <Tip
                                    icon={
                                        <ExclamationCircleTwoTone twoToneColor={common.color.danger}/>
                                    }>
                                    暂不支持自定义地址，请选择一项下拉项
                                </Tip>
                        }
                    })(inputType)}
                    onChange={event => {
                        this.autoComplete(event?.target?.value);
                        onChange?.(undefined);
                        this.setState({selectedLoca : undefined})
                    }}
                />
            </AutoComplete>
        );
    }
}
