/**
 * 视频内容
 * Create by liaokai on 2020-05-23 18:17:21
 */
import * as React from 'react';
import {Component} from "react";
import {LiveContentList} from "./comonent/table";

interface IProps{

}

interface IState{

}

export class LiveContentView extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <div>
                <LiveContentList/>
            </div>
        );
    }
}
