/**
 * 0929
 * 修改选择icon原有页面不动，新增一个组件修改
 */
import React, { useState, useEffect } from 'react';
import { Space, Button, Modal, message, Tabs, Badge } from 'antd';
import { getIcon } from './constants';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { HeaderIcon } from '../../sort/header-icon/header-icon';
import invariant from 'invariant';
import ImageCropper from "../../../../../../meeting-list/new-meeting/components/ImageCropper";
import { Mask } from '../../../../../../live-list/live/menu/edit/component/mask';
import { FlexJustifyCenter } from '../../../../../../../../components/style/flex/flex-justify-center';
import { uploadFile } from '../../../../../../../../common/apis/apis/upload-file';

const { TabPane } = Tabs;

type IconDatabase = {
  color: string;
  icons: { imgurl: string }[];
}

type Props = {
  icon?: string;
  costomIcon?: string;
  setIcon: (icon: string) => void;
  setCostomIcon: (icon: string) => void;
}

const config = {
  width: 55,
  height: 55,
}

export default (props: Props) => {
  const { setIcon, icon, setCostomIcon, costomIcon } = props;
  // 自有iconmodal显示
  const [visible, setVisible] = useState(false);
  // 自有icon库
  const [iconList, setIconList] = useState([] as IconDatabase[]);
  // 选中的icon库的index
  const [iconSelectIndex, setIconSelectIndex] = useState(-1);
  // tabs选中的key
  const [activeKey, setActiveKey] = useState('');

  useEffect(() => {
    getIcon({ version: 2 })
      .then((response: IconDatabase[]) => {
        setIconList(response);
        response[0] && response[0].color && setActiveKey(response[0].color);
      })
      .catch((e) => {
        message.error(e.data?.message);
      });
  }, []);

  /**
   * 切换tab取消选择icon
   */
  const onChange = (key: any) => {
    setActiveKey(key);
    setIconSelectIndex(-1);
  }

  /**
   * 从自有icon库中设置icon
   * 设置icon 从外部props进行修改
   */
  const onChoiceIcon = () => {
    try {
      const currentIconList = iconList.find(l => l.color === activeKey);
      invariant(!!currentIconList, '数据错误');

      const currentIcon = currentIconList.icons[iconSelectIndex];
      invariant(currentIcon && currentIcon.imgurl, '请先选择icon');

      setIcon && setIcon(currentIcon.imgurl);
      setVisible(false);
    } catch (error) {
      message.warn(error.message);
    }
  }

  const onCropedImage = async (result: any) => {
    let formdata = new FormData();
    formdata.append("file", result);
    formdata.append("type", "base64");
    try {
      const response = await uploadFile(formdata);
      setCostomIcon && setCostomIcon(response);
      return !!response;
    } catch (err) {
      message.error(err?.data?.message);
      return false;
    }
  };

  /**
   * 重新选择
   */
  const onReset = () => {
    setIcon('');
    setCostomIcon('');
  }

  const renderFloatView = () => (
    <Button type='primary' >自定义上传</Button>
  )
  const renderCostomSelectIcon = () => {
    return (
      <div style={{ position: "relative" }} >
        <ImageCropper
          width={1}
          height={1}
          onUpdate={(result: any) => onCropedImage(result)}
          text={
            <Mask
              floatView={
                icon && renderFloatView() as any
              }
            >
              {icon ? (
                <FlexJustifyCenter style={{ ...config }}>
                  <img
                    style={{
                      maxWidth: `${config.width}px`,
                      maxHeight: `${config.height}px`,
                      width: config.width,
                      height: config.height,
                    }}
                    src={icon}
                    alt=""
                  />
                </FlexJustifyCenter>
              ) : renderFloatView()}
            </Mask>
          }
        />
      </div>
    );
  }
  const selected = !!icon || !!costomIcon;
  return (
    <div>
      {!selected ? (
        <Space>
          <Button
            type='primary'
            onClick={() => setVisible(true)}
          >
            选择自有icon
          </Button>
          {renderCostomSelectIcon()}
        </Space>
      ) : (
          <Space>
            {!!icon && (
              <div style={{ cursor: "pointer", marginRight: 8 }}>
                <HeaderIcon src={icon} />
              </div>
            )}
            {!!costomIcon && (
              <div style={{ cursor: "pointer", marginRight: 8 }}>
                <div
                  style={{
                    ...config,
                    borderRadius: config.height / 2,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundImage: `url(${costomIcon})`
                  }}
                />
              </div>
            )}
            <Button type='primary' onClick={onReset} >
              重新选择
          </Button>
          </Space>
        )}


      <Modal
        okText='确定'
        cancelText='取消'
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onChoiceIcon}
        width={(64 + 9) * 9 - 8 + 24 * 2}
        bodyStyle={{paddingTop: 60}}
      >
        {iconList && iconList.length > 0 && (
          <Tabs
            type='card'
            onChange={onChange}
            activeKey={activeKey}
          >
            {iconList.map((colorList) => {
              const { icons } = colorList;
              return (
                <TabPane tab={colorList.color} key={colorList.color}>
                  <Space style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                    {icons.map((icon, index) => {
                      const selected = index === iconSelectIndex;
                      if (!!selected) {
                        return (
                          <div
                            onClick={() => setIconSelectIndex(index)}
                            style={{ cursor: "pointer", marginRight: 8, marginBottom: 8 }}
                          >
                            <Badge
                              count={<CheckCircleTwoTone twoToneColor={"#52c41a"} style={{ fontSize: 20 }} />}
                              offset={[-7, 7]}
                            >
                              <HeaderIcon src={icon.imgurl} />
                            </Badge>
                          </div>
                        );
                      }
                      return (
                        <div
                          onClick={() => setIconSelectIndex(index)}
                          style={{ cursor: "pointer", marginRight: 8, marginBottom: 8 }}
                        >
                          <HeaderIcon src={icon.imgurl} />
                        </div>
                      )
                    })}
                  </Space>
                </TabPane>
              )
            })}
          </Tabs>
        )}
      </Modal>
    </div>
  )
}