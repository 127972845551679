import React, { Component } from 'react'
import style from './TemplateView.scss'
import excelicon from '../../../../../../../../../assets/excelicon.png'
import {api} from '../../../../../../../../../common/app'

export class TemplateView extends Component {
constructor(props) {
  super(props);
  this.state = {
    id:null,
    downloadurl:null
  };
     this.refreshProps = this.refreshProps.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
}
refreshProps(props) {
  this.state.id = props.id!=undefined?props.id:this.state.id;
  this.getTemplate(this.state.id);
  this.setState(this.state);
}
getTemplate(id){
  if (!id) return;
  api.getImportGuestTemplate(id).then(res=>{
    if (res.code === 200) {
      this.state.downloadurl = res.result;
    }else{
      this.state.downloadurl = null;
    }
    this.setState(this.state);
  },err=>{
    console.log(err);
  })
}
render() {
  return (
    <div className={style.TemplateView}>
        <div className={[style.TextTips,'childcenter childcolumn childalignstart'].join(' ')}>
            <span>* 由于数据需要一一对应，建议您下载本模板再进行人员导入</span>
            <span>这里的人员名单模版和嘉宾注册表单设置一致噢，注册表单详情请到 注册表单详情请到 <span style={{cursor: "pointer"}} onClick={()=>{
                window.open("#/meeting/setforminfo/"+ this.state.id)
            }}>注册表单</span> 中查看</span>
        </div>
        {this.state.downloadurl ? <div className={[style.DownloadButton,'childcenter'].join(' ')} onClick={()=>{
          window.location.href = this.state.downloadurl;
        }}>
            <img src={excelicon} alt=""/>
            <span>点我下载名单模版</span>
        </div>:''}
    </div>
   )
   }
}
export default TemplateView
