// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title-content-table_table__2xcQ4{display:grid;grid-template-columns:auto 1fr;align-items:center}\n", ""]);
// Exports
exports.locals = {
	"table": "title-content-table_table__2xcQ4"
};
module.exports = exports;
