import React, { Component } from 'react'
import style from './CheckBox.scss'
  
export class CheckBox extends Component {
constructor(props) {
  super(props);
  this.state = {
      checked:false,
  };
  this.refreshProps = this.refreshProps.bind(this);
}
componentWillReceiveProps(nextprops) {
  this.refreshProps(nextprops);
}
componentDidMount() {
  this.refreshProps(this.props);
}
refreshProps(props) {
  this.state.checked = props.checked!=undefined?props.checked:this.state.checked;
  this.setState(this.state);
}
render() {
  return (
    <div className={[style.CheckBox,this.state.checked?style.Checked:''].join(' ')} onClick={this.props.onClick}>

    </div>
   )
   }
}
export default CheckBox