import { toPost } from "../../../../../../../common/apis/http-utils";


export const liveInfoList = toPost<any, any>(`/merchant/live/liveInfoList`);

export const liveInfoAdd = toPost<any, any>(`/merchant/live/liveInfoAdd`);

export const liveInfoShow = toPost<any, any>(`/merchant/live/liveInfoShow`);

export const liveInfoDel = toPost<any, any>(`/merchant/live/liveInfoDel`);

export const liveInfoUpdate = toPost<any, any>(`/merchant/live/liveInfoUpdate`);

export const liveInfoChange = toPost<any, any>(`/merchant/live/liveInfoChange`);
export const liveTabStatus = toPost<any, any>(`/merchant/live/liveTabStatus`);