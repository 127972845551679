/**
 * 隐藏组件
 *  将子组件渲染到页面上，但不作展示
 * Create by liaokai on 2020-03-11 11:26:45
 */
import * as React from 'react';
import {Component} from "react";

interface IProps{

}

interface IState{

}

export class Ghost extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {

    };
    constructor(props: IProps){
        super(props);
    }
    render() {
        let {children} = this.props;
        let {} = this.state;
        return (
            <div style={{display: "none"}} >
                {children}
            </div>
        );
    }
}
