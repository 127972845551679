/**
 * 增减器
 * Create by liaokai on 2020-03-18 13:32:30
 */
import * as React from 'react';
import {Component} from "react";
import {CloseCircleTwoTone, LoadingOutlined, PlusOutlined} from "@ant-design/icons/lib";
import {Avatar, Badge, message, Upload} from "antd";
import {api} from "../../../../../../../../common/app";

interface IProps{
    value : string,
    onChange : (imgUrl : string) => void
}

interface IState{
    loading : boolean,
    imageUrl : string
}

function beforeUpload(file : any) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('您只能上传 JPG/PNG 图片');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('图片文件超过 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

function getBase64(img : any, callback : (result : any) => void) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export class ImageUpdater extends Component<IProps,IState>{
    //state初始化
    readonly state : IState = {
        loading : false,
        imageUrl : this.props.value
    };
    constructor(props: IProps){
        super(props);
    }

    componentWillReceiveProps(nextProps: Readonly<IProps>, nextContext: any): void {
        this.setState({imageUrl : nextProps.value})
    }

    handleChange = (info : any) => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );
        }
    };

    render() {
        let {onChange} = this.props;
        let {imageUrl} = this.state;
        return (
            <div>
                <Badge
                    offset={[-10, 0]}
                    count={
                        imageUrl
                        ?
                        <CloseCircleTwoTone
                            style={{fontSize: 20}}
                            twoToneColor={"#ff4d4f"}
                            onClick={()=>{
                                this.setState({imageUrl : ""});
                                onChange("");
                            }}
                        />
                        :
                        undefined
                    }
                >
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        customRequest={options => {
                            let formdata = new FormData();
                            formdata.append('file',options.file);
                            formdata.append('type','img');
                            (api as any).UploadFile(formdata,(progressEvent : any)=>{
                                let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                                options.onProgress({
                                    percent : complete,
                                }, options.file);
                            }).then((res : any)=>{
                                if (res.code === 200) {
                                    options.onSuccess({},options.file);
                                    onChange(res.result);
                                }else{
                                    message.error(res.message)
                                }
                            },(err:any)=>{
                                message.error("上传失败");
                                console.log(err);
                            })
                        }}
                        beforeUpload={beforeUpload}
                        onChange={this.handleChange}
                    >
                        {
                            imageUrl
                                ?
                                <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                                :
                                <div>
                                    {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                                    <div className="ant-upload-text">Upload</div>
                                </div>
                        }
                    </Upload>
                </Badge>
            </div>
        );
    }
}
