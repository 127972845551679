/**
 * 上传文件
 * Create by liaokai on 2020-05-27 10:15:46
 */
import {ajaxInstance} from "../http";
import {common} from "../../common";

export const uploadFile = (file: any, url: string = "/api/qiniu/upload", progress?: (progressEvent: any)=> void) : Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        ajaxInstance.post(url,file,{
            onUploadProgress: progressEvent => {
                progress?.(progressEvent)
            }
        }).then(res => {
            const result = res.data?.result
            if(res.data?.code === 200 && result){
                resolve(result);
            }
            reject(res);
        }).catch(e => {
            reject(e);
            common.sentry.captureException(e);
        });
    })
}
