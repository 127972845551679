/**
 * 文档详情（ppt所有页）
 * Create by liaokai on 2020-05-29 18:12:32
 */
import {toPost} from "../../http-utils";

export interface IDocShowItem {
    "id": number,
    "live_doc_id": number,
    "img_url": string, //图片地址
    "thurmb":string,//缩略图地址值
    "create_time": string,
    "update_time": string
}

export const docShow = toPost<
    {
        live_doc_id : string
    },
    {
        "id": number, // 文档的id
        "origin_name": string, //原名字
        "create_time": string, //创建时间
        "page": number, //总页数
        "hash": string, //hash
        "status": number, //状态
        "doc_son": IDocShowItem[]
    }
>("/merchant/live/docShow");
