import { toPost } from '../../../../../../../common/apis/http-utils';
import { FetchField } from '../types';

export const formatListResult = (result: any): any => {
  const mergeResult = result;
  return {
    list: mergeResult.data,
    total: mergeResult.total,
  };
};

export const liveVenueCateIndex = toPost<FetchField.CateList, any>(
  `/merchant/live/liveVenueCateIndex`
);

export const liveVenueIndex = toPost<FetchField.List, any>(
  `/merchant/live/liveVenueIndex`
);

export const liveVenueShow = toPost<FetchField.Remove, any>(
  `/merchant/live/liveVenueShow`
);

export const liveVenueStore = toPost<FetchField.Add, any>(
  `/merchant/live/liveVenueStore`
);

export const liveVenueUpdate = toPost<FetchField.Update, any>(
  `/merchant/live/liveVenueUpdate`
);

export const liveVenueDestroy = toPost<FetchField.Remove, any>(
  `/merchant/live/liveVenueDestroy`
);

export const liveVenueCateStore = toPost<FetchField.CateAdd, any>(
  `/merchant/live/liveVenueCateStore`
);

export const liveVenueLiveList = toPost<any, any>(
  `/merchant/live/liveVenueLiveList`
);

export const liveVenueCateUpdate = toPost<any, any>(
  `/merchant/live/liveVenueCateUpdate`
);

export const liveVenueCateDestroy = toPost<any, any>(
  `/merchant/live/liveVenueCateDestroy`
);
