import { Component } from "react";



import * as React from 'react';
import { Modal, Button, message } from "antd";
import { downLiveRecordUrl } from "../../../../../../../common/apis/apis/live/downLiveRecordUrl";
import {AliLivePlayer} from "../../../../../../../components/Live/ali-live-player";
interface IProps {
    live_record_id:number
}
interface IState{
    visible:boolean
    url:string
    isDow:Boolean
}
function PlayerHoc(Btn:any) {
    return class LivePlayer extends Component<IProps,IState>{
        constructor(props:IProps){
            super(props)
            this.state ={
                visible:false,
                url:'',
                isDow:false
            }
        }
        show(){
            const { live_record_id } = this.props
            downLiveRecordUrl({live_record_id}).then(res=>{
                this.setState({url:res.url,isDow:true})
            }).catch(res=>{

                message.error(res.data.message)
            })
            this.setState({
                visible:true
            })
        }
        hide(){
            this.setState({visible:false,url:''})
        }
        
        render (){
            const {visible,url,isDow} = this.state
            return (
                <>
                {/* <Btn onClick={()=>this.setState({visible:true})}></Btn> */}
                {Btn({onClick:this.show.bind(this)})}
                <Modal width="690px"
                       cancelText="关闭" okButtonProps={{disabled:Boolean(!isDow)}}
                       okText="确定"
                       title="回放"
                       onOk={this.hide.bind(this)}
                       onCancel={this.hide.bind(this)}
                       visible={visible}>
                    <AliLivePlayer src={url}/>
                </Modal>
                </>
            )
        }
    }
}

export default PlayerHoc;

