/**
 * 短信模板删除
 * Create by liaokai on 2020-04-24 11:49:17
 */
import {toPost} from "../../http-utils";

export const smsTemplateDestroy = toPost<
    {
        id : number
    },
    {

    }
>("/merchant/Lcm/smsTemplateDestroy");
