// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BaseAlert_BaseBox__1QKC3{position:fixed;z-index:999;top:30px;left:50%;transform:translateX(-50%);pointer-events:none}.BaseAlert_BaseBox__1QKC3 .BaseAlert_AnmiBox__36zPv{max-width:600px;min-width:300px;padding:10px;border-radius:5px;opacity:0;font-size:15px}.BaseAlert_message__2IkzA{background:rgba(255,255,255,0.9);box-shadow:rgba(0,0,0,0.2) 0 5px 10px}.BaseAlert_error__2bXKJ{color:#fff;background:#d95755;box-shadow:rgba(206,41,45,0.2) 0 5px 10px}.BaseAlert_success__3qVkm{color:#fff;background:#97da57;box-shadow:rgba(122,204,41,0.2) 0 5px 10px}.BaseAlert_SlideIn__3k04B{transform:translateY(0%);-webkit-animation:BaseAlert_slideIn__2Ybjw 0.5s forwards;animation:BaseAlert_slideIn__2Ybjw 0.5s forwards}.BaseAlert_SlideOut__2mdNc{-webkit-animation:BaseAlert_slideOut__o3GAm 0.5s;animation:BaseAlert_slideOut__o3GAm 0.5s}@-webkit-keyframes BaseAlert_slideIn__2Ybjw{0%{transform:translateY(-100%);opacity:0}100%{transform:translateY(0%);opacity:1}}@keyframes BaseAlert_slideIn__2Ybjw{0%{transform:translateY(-100%);opacity:0}100%{transform:translateY(0%);opacity:1}}@-webkit-keyframes BaseAlert_slideOut__o3GAm{0%{transform:translateY(0%);opacity:1}100%{transform:translateY(100%);opacity:0}}@keyframes BaseAlert_slideOut__o3GAm{0%{transform:translateY(0%);opacity:1}100%{transform:translateY(100%);opacity:0}}\n", ""]);
// Exports
exports.locals = {
	"BaseBox": "BaseAlert_BaseBox__1QKC3",
	"AnmiBox": "BaseAlert_AnmiBox__36zPv",
	"message": "BaseAlert_message__2IkzA",
	"error": "BaseAlert_error__2bXKJ",
	"success": "BaseAlert_success__3qVkm",
	"SlideIn": "BaseAlert_SlideIn__3k04B",
	"slideIn": "BaseAlert_slideIn__2Ybjw",
	"SlideOut": "BaseAlert_SlideOut__2mdNc",
	"slideOut": "BaseAlert_slideOut__o3GAm"
};
module.exports = exports;
