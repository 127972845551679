/**
 * 提交详情
 * Create by liaokai on 2020-06-17 16:13:54
 */
import React, { useEffect, useState } from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Texts } from "../../../../../../../components/text/Texts";
import { Air } from "../../../../../../../components/air/Air";
import { TitleContentTable } from "../../../../../meeting-list/meeting/menu/lecturer/preview/title-content-table/title-content-table";
import { Affix, Button, Card, Input, message, Radio, Space } from "antd";
import { FlexJustifyBetween } from "../../../../../../../components/style/flex/flex-justify-between";
import { PlusOutlined } from "@ant-design/icons/lib";
import { Flex } from "../../../../../../../components/style/flex/flex";
import { FlexJustifyCenter } from "../../../../../../../components/style/flex/flex-justify-center";
import { liveSurveyQuestionnaireUpdate } from "../../../../../../../common/apis/apis/live/liveSurveyQuestionnaireUpdate";
import { liveStore } from "../../live.store";
import { liveSurveyQuestionnaireStore } from "../../../../../../../common/apis/apis/live/liveSurveyQuestionnaireStore";
import {
    IliveSurveyQuestionnaireByUserAnswerInfo,
    liveSurveyQuestionnaireByUserAnswerInfo,
} from "../../../../../../../common/apis/apis/live/liveSurveyQuestionnaireByUserAnswerInfo";
import { questionShow } from "../../../../../../../common/apis/apis/live/questionIndex";

export default function Detail(props: any) {
    const [question, setQuestion] = useState({} as any);
    const [option, setOption] = useState([] as any);
    useEffect(() => {
        questionShow({ id: props.match.params.qa_id })
            .then((response) => {
                setQuestion(response.question);
                setOption(response.option);
            })
            .catch((error) => {
                message.warn(error?.data?.message);
            });
    }, [props.match.params.qa_id]);
    return (
        <div>
            <Air vertical={15} />
            <FlexJustifyBetween>
                <Texts.ChapterTitle>问答题目</Texts.ChapterTitle>
                <div></div>
            </FlexJustifyBetween>
            <Air vertical={5} />
            <div>
                <Card title={question.title}>
                    <TitleContentTable rowGap={10} colGap={20}>
                        <Texts.TableTitle>类型:</Texts.TableTitle>
                        <div>
                            {question.type === "single"
                                ? "单选"
                                : question.type === "multi"
                                ? "多选"
                                : "问答"}
                        </div>
                        {question.type !== "qa" && (
                            <>
                                <Texts.TableTitle
                                    style={{
                                        alignSelf: "flex-start",
                                    }}
                                >
                                    选项:
                                </Texts.TableTitle>
                                <div>
                                    {option.map((value1: any) => {
                                        return (
                                            <Flex>
                                                <Radio
                                                    checked={value1.isAnswer}
                                                />
                                                <div>{value1.option}</div>
                                            </Flex>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </TitleContentTable>
                </Card>
            </div>
        </div>
    );
}
