/**
 * 聊天列表
 * Create by liaokai on 2020-05-28 10:43:13
 */
import {toPost} from "../../http-utils";

export interface IMessageIndexItem {
    "id": number,
    "content": string,
    "stick_type": number,
    "name": string,
    "create_time": string
}

export const messageIndex = toPost<
    {
        live_id : string,
        page : number,
        paginate? : number,
        content? : string,
        dateStart?: string,
        dateEnd?: string
    },
    {
        "current_page": number,
        "total": number,
        "last_page": number,
        "data": IMessageIndexItem[]
    }
>("/merchant/live/messageIndex");

