// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".preview_container__3homq .preview_title__3ElYW{font-size:21px;font-weight:400;color:#000;padding:20px 0px}.preview_container__3homq .preview_content__3QhUJ{background-color:white;min-height:600px;margin-bottom:30px}.preview_container__3homq .preview_content__3QhUJ .preview_ope__2YdMC{display:flex;justify-content:space-between;align-items:center}.preview_container__3homq .preview_content__3QhUJ .preview_ope__2YdMC .preview_text__3NB25{margin-right:10px}.preview_container__3homq .preview_content__3QhUJ .preview_ope__2YdMC .preview_btns__eNY-4{display:flex}.preview_container__3homq .preview_content__3QhUJ .preview_show__1auro{display:flex;margin-top:20px}.preview_container__3homq .preview_content__3QhUJ .preview_show__1auro .preview_forms__V8zmQ{flex:1 1;margin-right:30px}.preview_container__3homq .preview_content__3QhUJ .preview_show__1auro .preview_forms__V8zmQ .preview_title__3ElYW{font-size:15px;margin-right:20px;color:unset}.preview_container__3homq .preview_content__3QhUJ .preview_show__1auro .preview_forms__V8zmQ .preview_body__z5xWL{flex:1 1}.preview_container__3homq .preview_content__3QhUJ .preview_show__1auro .preview_forms__V8zmQ .preview_body__z5xWL .preview_updater__FLI25{margin-top:15px}.preview_container__3homq .preview_content__3QhUJ .preview_show__1auro .preview_forms__V8zmQ .preview_btns__eNY-4{display:flex;margin-top:10px}.preview_container__3homq .preview_content__3QhUJ .preview_show__1auro .preview_qr__eGope{display:flex;flex-direction:column;align-items:center}.preview_container__3homq .preview_content__3QhUJ .preview_show__1auro .preview_qr__eGope .preview_text__3NB25{margin-top:18px}\n", ""]);
// Exports
exports.locals = {
	"container": "preview_container__3homq",
	"title": "preview_title__3ElYW",
	"content": "preview_content__3QhUJ",
	"ope": "preview_ope__2YdMC",
	"text": "preview_text__3NB25",
	"btns": "preview_btns__eNY-4",
	"show": "preview_show__1auro",
	"forms": "preview_forms__V8zmQ",
	"body": "preview_body__z5xWL",
	"updater": "preview_updater__FLI25",
	"qr": "preview_qr__eGope"
};
module.exports = exports;
