/**
 * 菜单-内容
 * Create by liaokai on 2020-04-14 19:03:17
 */
import * as React from 'react';
import {Component} from "react";
import {MenuVertical, tMenuVerticalTree} from "../menu-vertical/menu-vertical";
import {common} from "../../common/common";
import {Redirect, Route, Switch} from "react-router-dom";
import {LayoutSiderContent} from "../../routes/index/meeting-list/meeting/menu/layout-sider-content/layout-sider-content";
import {RouteComponentProps} from "react-router";
import {Affix} from "antd";

interface IProps extends RouteComponentProps{
    menuData : tMenuVerticalTree<{
        component : React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
    }>,
    defaultRouterName? : string
    menuPrefix? : JSX.Element
}

interface IState{
    baseUrl : string,
    routerName : string
}

export class MenuContent extends Component<IProps,IState>{
    readonly state : IState = {
        baseUrl : this.props.match.url,
        routerName : this.props.defaultRouterName ?? ""
    };

    public go(path : string){
        window.location.hash = `${this.state.baseUrl}/${path}`;
    }

    render() {
        const {match : {path}, location : {pathname}, menuData,defaultRouterName, menuPrefix} = this.props;
        const {baseUrl} = this.state;
        return (
            <LayoutSiderContent
                sider={
                    <div>
                        {menuPrefix}
                        <MenuVertical
                            basePath={baseUrl}
                            tree={menuData}
                            selectedKey={
                                menuData
                                    .map(value => value.items)
                                    .reduce((a,b)=> a.concat(b))
                                    .map(value => value.routerName)
                                    .filter(value => pathname.indexOf(`${baseUrl}/${value}`) >= 0)
                                    ?.[0]
                            }
                        />
                    </div>
                }
                content={
                    <Switch>
                        {
                            menuData
                                .map(value => value.items)
                                .reduce((a,b) => a.concat(b))
                                .map(value =>
                                    <Route key={value.routerName} path={`${path}/${value.routerName}`} component={value.plusField?.component}/>
                                )
                        }
                        <Redirect path={path} to={`${path}/${defaultRouterName ?? "null"}`}  />
                    </Switch>
                }
            />
        );
    }
}
