/**
 * 短信模板详情
 * Create by liaokai on 2020-04-23 18:21:10
 */
import {toPost} from "../../http-utils";
import {ITagItem} from "../../../../routes/index/meeting-list/meeting/menu/sms-multi-send/sms-template/editor/sms-template-editor";

export const emailTemplateShow = toPost<
    {
        id : number
    },
    {
        "id": number,
        "title": string,
        "meetid": number,
        // "text": ITagItem[],
        "text": string
    }
    >("/merchant/Email/templateShow");
