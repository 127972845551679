import {toPost} from "../../http-utils";
import {ITagItem} from "../../../../routes/index/meeting-list/meeting/menu/sms-multi-send/sms-template/editor/sms-template-editor";

export interface IEmailSendAffirm {
        "template_title": string, //标题
        "template_content": ITagItem[],
        "send_obj": string, //发送对象
        "send_sum": number, //发送数量
        "sms_balance": number, //剩余余额
        "type": string, //类型
        "residue": number, //剩余短信包数量
        "deductNum": number, //消耗短信包数量
        "deductPrice": number, //消耗金额 （单位元）
        "able_send": boolean //是否可以发送
}

export const emailSendAffirm = toPost<
    {
        id : number,
        meetid : number,
        template_id : number,
        type : number,
        email_nick : string
        send_time : string
    },
    IEmailSendAffirm
    >("/merchant/Email/sendAffirm");
