// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CheckBox_CheckBox__277kG{display:block;width:20px;height:20px;box-shadow:rgba(112,112,112,0.3) 0 0 0 1px inset;margin-right:8px;position:relative;cursor:pointer}.CheckBox_Checked__3lOsg::after{position:absolute;content:'';width:2px;height:15px;background:#0089FF;left:50%;top:50%;transform:translateX(calc(-50% + 2px)) translateY(calc(-50% + 0px)) rotate(45deg);-webkit-animation:CheckBox_selectAnim__2GVDg 0.3s forwards;animation:CheckBox_selectAnim__2GVDg 0.3s forwards}.CheckBox_Checked__3lOsg::before{position:absolute;content:'';width:2px;height:6px;background:#0089FF;left:50%;top:50%;transform:translateX(calc(-50% - 5px)) translateY(calc(-50% + 2px)) rotate(-45deg);-webkit-animation:CheckBox_selectAnim__2GVDg 0.3s forwards;animation:CheckBox_selectAnim__2GVDg 0.3s forwards}@-webkit-keyframes CheckBox_selectAnim__2GVDg{from{height:0%}}@keyframes CheckBox_selectAnim__2GVDg{from{height:0%}}\n", ""]);
// Exports
exports.locals = {
	"CheckBox": "CheckBox_CheckBox__277kG",
	"Checked": "CheckBox_Checked__3lOsg",
	"selectAnim": "CheckBox_selectAnim__2GVDg"
};
module.exports = exports;
