/**
 * 绑定直播间详情
 * Create by liaokai on 2020-05-29 18:58:53
 */
import {toPost} from "../../http-utils";

export const liveTabList = toPost<
    {
        live_id : any
    },
    {
       data:{ id:any,
        live_id:any,
        type:any,
        atitle:any,
        type_id:any,
        title:any,
        sort:any,
        status:any}[]
    }
>("/merchant/live/tabList");
