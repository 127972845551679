/**
 * 页底 网站信息
 * Create by liaokai on 2020-04-20 17:41:12
 */
import * as React from 'react';
import {Component} from "react";
import {Responsive} from "../common/responsive";
import {Divider} from "antd";

interface IProps{

}

interface IState{

}

export class Footer extends Component<IProps,IState>{
    readonly state : IState = {

    };
    render() {
        let {} = this.props;
        let {} = this.state;
        return (
            <div style={{padding: "20px 0px"}}>
                <Responsive>
                    <Divider style={{margin: "20px 0px"}}/>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        Copyright © huibaifen.com 2013-2020  沪ICP备17050365号
                    </div>
                </Responsive>
            </div>

        );
    }
}
