/**
 * 充值运营账户余额
 * Create by liaokai on 2020-05-14 18:18:59
 */
import {toPost} from "../http-utils";

export interface IOperatingAccountBalanceResult {
    "money": number,
    "orderNum": string,
    "code_url": string,
    "pay_type": number,//1微信2支付宝3后台人员手动添加4不收费自己添加5为短信发送扣款6短信取消回款7为余额购买
    "create_time": string,
    "title": string
}

export const operatingAccountBalance = toPost<
    {
        price : number,
        type : number       // 1微信 2支付宝
    },
    IOperatingAccountBalanceResult
>("/merchant/Lcm/operatingAccountBalance");
