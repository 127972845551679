// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".buy-email_container__2HxEt .buy-email_smsPackageTypes__2gEem{display:grid;grid-gap:10px;grid-template-columns:repeat(auto-fill, 255px)}.buy-email_container__2HxEt .buy-email_submitBtn__17q-Z{background:#ff784b;border:#ff784b}.buy-email_container__2HxEt .buy-email_submitBtn__17q-Z:hover{background:#ff6e4b;border:#ff6e4b}\n", ""]);
// Exports
exports.locals = {
	"container": "buy-email_container__2HxEt",
	"smsPackageTypes": "buy-email_smsPackageTypes__2gEem",
	"submitBtn": "buy-email_submitBtn__17q-Z"
};
module.exports = exports;
