/**
 * 绑定直播间详情
 * Create by liaokai on 2020-05-29 18:58:53
 */
import {toPost} from "../../http-utils";

export const liveTabUpdate = toPost<
    {
        live_id : any
        title : any
        sort : any
    },
    {

    }
>("/merchant/live/liveTabUpdate");
