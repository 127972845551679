import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { FilterMessage, isMessage, MessageUser } from "../types";
import "./index.css";
import { checkMessage } from "../constants";
import { liveStore } from "../../live.store";

const prefix = "component-filter";

type Props = {
    data: FilterMessage | MessageUser;
    onClick: (params: any) => void;
};

function RenderHelper(props: any) {
    const { buttons, messages, data } = props;
    return (
        <div className={`${prefix}-item`}>
            <div className={`${prefix}-item-left`} style={{maxWidth: '80%'}}>
                {messages.map((item: any) => {
                    return typeof item === "string" ? (
                        <span>{item}</span>
                    ) : (
                        item
                    );
                })}
            </div>
            <div className={`${prefix}-item-right`}>
                {buttons.map((button: any) => {
                    const { title, onClick, ...rest } = button;
                    return (
                        <Button
                            {...rest}
                            onClick={onClick}
                            style={{ marginLeft: 8 }}
                        >
                            {title}
                        </Button>
                    );
                })}
            </div>
        </div>
    );
}

export default (props: Props) => {
    const { data, onClick } = props;
    const [buttons, setButtons] = useState([] as any[]);
    useEffect(() => {
        if (isMessage(data)) {
            const buttons =
                Number(data.status) === 1
                    ? [
                          {
                              title: "通过",
                              type: "primary",
                              onClick: () =>
                                  onClick({
                                      itemType: "filter",
                                      payload: { type: 2 },
                                  }),
                          },
                          {
                              title: "阻止",
                              onClick: () =>
                                  onClick({
                                      itemType: "filter",
                                      payload: { type: 3 },
                                  }),
                          },
                      ]
                    : Number(data.status) === 3
                    ? [
                          {
                              title: "通过",
                              type: "primary",
                              onClick: () =>
                                  onClick({
                                      itemType: "filter",
                                      payload: { type: 2 },
                                  }),
                          },
                      ]
                    : [];

            setButtons(buttons);
        } else {
            const buttons =
                data.forbid_message === 1
                    ? [
                          {
                              title: "禁言",
                              onClick: () =>
                                  onClick({
                                      itemType: "user",
                                      payload: { type: 2 },
                                  }),
                          },
                      ]
                    : [
                          {
                              title: "取消禁言",
                              danger: true,
                              onClick: () =>
                                  onClick({
                                      itemType: "user",
                                      payload: { type: 1 },
                                  }),
                          },
                      ];
            setButtons(buttons);
        }
    }, [data]);

    if (isMessage(data)) {
        return (
            <RenderHelper
                data={data}
                messages={[
                    data.name,
                    data.message,
                    <span className={`${prefix}-item-time`}>
                        {data.create_time}
                    </span>,
                ]}
                buttons={buttons}
            />
        );
    }

    return (
        <RenderHelper data={data} messages={[data.name]} buttons={buttons} />
    );
};
