/**
 * 群发详情-表格
 * Create by liaokai on 2020-04-23 17:40:53
 */
import * as React from 'react';
import {Component} from "react";
import {Button, message, Table} from "antd";
import {Texts} from "../../../../../../../../components/text/Texts";
import {Air} from "../../../../../../../../components/air/Air";
import {apis} from "../../../../../../../../common/apis/apis";
import {emailSendEmailUsersAgain} from "../../../../../../../../common/apis/apis/email/emailSendEmailUsersAgain";
import {sendEmailUsersList} from "../../../../../../../../common/apis/apis/email/emailSendEmailUsersList";

const columns = [
    {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',

    },
    {
        title: '邮箱',
        dataIndex: 'email',
        key: 'email',

    },
    {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',

    },
    // {
    //     title: '性别',
    //     dataIndex: 'sex',
    //     key: 'sex',
    //     render: (text: string) => text,
    // },
    // {
    //     title: '手机号码',
    //     dataIndex: 'phoneNumber',
    //     key: 'phoneNumber',
    //     render: (text: string) => text,
    // },
    {
        title: '送达时间',
        dataIndex: 'time',
        key: 'time',
        render: (text: string) => text,
    },
    {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        render: (text: string) => text,
    },
]

interface ITableItem {
    name : string,          //姓名
    email : string,          //邮箱
    remark : string,          //备注
    // sex: string,            //性别
    // phoneNumber: string,    //手机号码
    time : string,          //送达时间
    status: string,         //发送状态
    id : number
}

interface IProps{
    id : number
}

interface IState{
    listData : ITableItem[],
    resending : boolean
}

export class MultiSendDetailTable extends Component<IProps,IState>{
    readonly state : IState = {
        listData : [],
        resending : false
    };

    reqListData(){
        apis.sendEmailUsersList({id : this.props.id}).then(res => {
            this.setState({
                listData : res.map(value => {
                    return {
                        name : value.username,
                        email : value.email,
                        remark : value.remark,
                        // sex : value.sex,
                        // phoneNumber : value.phone,
                        time : value.send_time,
                        status : value.status === 1 ? "发送中" :
                            value.status === 2 ? "成功" :
                                value.status === 3 ? "失败" :
                                    value.status === 4 ? "发送中" :  //等待定时发送
                                        value.status === 5 ? "成功" :  //定时发送完成
                                            value.status === 6 ? "失败" : "未知",  //定时发送失败
                        id : value.id
                    }
                })
            })
        })
    }

    componentDidMount() {
        this.reqListData();
    }

    render() {
        let {} = this.props;
        let {listData, resending} = this.state;
        return (
            <>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    {/*<Texts.ItemTitle>*/}
                    {/*    发送明细*/}
                    {/*</Texts.ItemTitle>*/}
                    <Button disabled={resending} type={"primary"} shape={"round"} onClick={()=>{
                        const failIdAry = listData.filter(value => value.status === "失败").map(value => value.id)
                        if(failIdAry?.length){
                            this.setState({resending : true})
                            apis.emailSendEmailUsersAgain({id : failIdAry}).then(value => {
                                this.reqListData();
                                setTimeout(()=>{
                                    this.reqListData();
                                },5000)
                                message.success("已重发")
                            }).catch(e => {
                                message.error("重发失败")
                            }).finally(()=>{
                                this.setState({resending: false})
                            })
                        }else{
                            message.error("没有可重发的短信")
                        }
                    }}>失败重发</Button>
                </div>
                <Air vertical={5}/>
                <Table columns={columns} dataSource={listData} pagination={false}/>
            </>
        );
    }
}
