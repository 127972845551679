/**
 * 获取邮件包购买列表
 * Create by liaokai on 2020-05-09 17:46:34
 */
import {toPost} from "../../http-utils";
import {ISmsPackageItem} from "../../../../routes/index/meeting-list/meeting/menu/sms-multi-send/buy-sms/sms-package-item/sms-package-item";

export const getEmailPackageList = toPost<
    {

    },
    {
        "id": number,
        "corner": string, //左上角标
        "num": number,//数量
        "describe": string, //描述
        "price": number //价格单位分
        "origin_price" : number
    }[]
    >("/merchant/Email/emailList");
