/**
 * 短信购买-余额支付
 * Create by liaokai on 2020-05-18 13:58:54
 */
import {toPost} from "../http-utils";

export const balancePaySmsAffirm = toPost<
    {
        order_num : string,
        password : string
    },
    {

    }
>("/merchant/Lcm/balancePaySmsAffirm");
